/* eslint-disable */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom'; //useLocation
import {
  RACButton,
  Grid,
  RACModalCard,
  Typography,
} from '@rentacenter/racstrap';
import AddCocustomer from '../AddCoCustomer/AddCocustomer';
import { AgreementContext } from '../../../context/AgreementContext';
import { AppRoute } from '../../../config/route-config';

import {
  AccountManagementURLParam,
  ADD_ON_PACKAGE,
  AMOrigin,
} from '../../../constants/constants';
import { OriginContext } from '../../../context/OriginContext';
import { Usercontext } from './searchResult';

interface Params {
  customerId: string;
  agreementId: string;
}

const FooterContent = (props: any) => {
  const {
    agreementVoidStatus,
    agreementCreateStatus,
    itemArrayData,
    setinventoryMapping,
    setPackageRules,
    storeSelectedPackagePriceTag,
  } = useContext(AgreementContext);
  const { redirectOrigin } = useContext(OriginContext);
  const classes = props.throwStyle;
  const location = useLocation();
  const search = useLocation().search;
  const salesLeadId = new URLSearchParams(search).get('salesLeadId');
  const shareData: any = useContext(Usercontext);
  const history = useHistory();
  const [footerControl, setfooterControl] = useState();
  const [packageCriteriaFooter, setpackageCriteriaFooter]: any = useState();
  const [copyData, setcopyData]: any = useState();
  // const { customerId } = useParams<Params>();
  let { agreementId } = useParams<Params>();
  const { customerId } = useParams<Params>();
  if (redirectOrigin === AMOrigin) {
    agreementId = agreementId.replace(AccountManagementURLParam, '');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newcustomer, setnewcustomer] = useState(true);
  const [openPackageSelection, setopenPackageSelection] = useState(false);
  const [packageSelection, setpackageSelection] = useState(false);
  const [itemArrayReceived, setitemArrayReceived] = useState([]);
  useEffect(() => {
    setfooterControl(props.controlData);
    setpackageCriteriaFooter(props.throwpackageCriteria);
    setcopyData(props.throwCopy);
    setpackageSelection(props.throwPackageState);
    console.log('props.throwPackageState in footer', props.throwPackageState);

    setitemArrayReceived(props.throwItemArray);
    console.log('props.throwItemArray', props.throwItemArray);
  }, [props]);

  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    // eslint-disable-next-line no-console
    console.log('Footer contex', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };

  const CancelClick = () => {
    if (redirectOrigin === AMOrigin) {
      history.push({
        pathname: `${AppRoute.AMCustomer}/${customerId}`,
        state: location?.state,
      });
      return;
    }
    history.push({
      pathname: `/customer/searchcustomer`,
    });
  };

  const invQuantityFormation = (payload: any) => {
    const ResponseArr: any = [];
    for (let i = 0; i < payload.length; i++) {
      const indexOfSameObj = ResponseArr.findIndex(
        (checkObj: any) =>
          checkObj.rmsDepartment == payload[i].rmsDepartment &&
          checkObj.rmsSubDepartment == payload[i].rmsSubDepartment &&
          checkObj.rmsBracket == payload[i].rmsBracket &&
          checkObj.rmsItemMasterId == payload[i].rmsItemMasterId
      );
      if (ResponseArr.length == 0 || indexOfSameObj == -1) {
        ResponseArr.push(payload[i]);
      } else {
        ResponseArr[indexOfSameObj].quantity =
          ResponseArr[indexOfSameObj].quantity + payload[i].quantity;
      }
    }
    return ResponseArr;
  };
  const validateReqWithMaxCriteriaFn = (req: any, Criteria: any) => {
    const criteriaCheck: any = Criteria.map((obj: any) => {
      obj.validateMaxQuantity = obj.maxQuantity;
      return obj
    });

    for (let i = 0; i < criteriaCheck.length; i++) {
      for (let j = 0; j < req.length; j++) {
        if (
          (((criteriaCheck[i].rmsDepartment == req[j].rmsDepartment)) || !criteriaCheck[i].rmsDepartment) &&
          ((criteriaCheck[i].rmsSubDepartment == req[j].rmsSubDepartment) || !criteriaCheck[i].rmsSubDepartment) &&
          ((criteriaCheck[i].rmsBracket == req[j].rmsBracket) || !criteriaCheck[i].rmsBracket) &&
          ((criteriaCheck[i].rmsItemMasterId == req[j].rmsItemMasterId) || !criteriaCheck[i].rmsItemMasterId)
        ) {
          criteriaCheck[i].validateMaxQuantity = criteriaCheck[i].validateMaxQuantity - req[j].quantity;
        }

      }

    }

    console.log("Max validation failed", criteriaCheck.filter((obj: any) => obj.validateMaxQuantity < 0))
    return criteriaCheck.filter((obj: any) => obj.validateMaxQuantity < 0)

  }
  const validateReqWithCriteriaFn = (req: any, Criteria?: any, maxCriteria?: any) => {

    const validateReqWithMinCriteriaFn = (req: any, Criteria: any) => {
      const criteriaCheck: any = Criteria.map((obj: any) => {
        obj.validateMinQuantity = obj.minQuantity;
        return obj
      });

      for (let i = 0; i < criteriaCheck.length; i++) {
        for (let j = 0; j < req.length; j++) {
          if (
            (((criteriaCheck[i].rmsDepartment == req[j].rmsDepartment)) || !criteriaCheck[i].rmsDepartment) &&
            ((criteriaCheck[i].rmsSubDepartment == req[j].rmsSubDepartment) || !criteriaCheck[i].rmsSubDepartment) &&
            ((criteriaCheck[i].rmsBracket == req[j].rmsBracket) || !criteriaCheck[i].rmsBracket) &&
            ((criteriaCheck[i].rmsItemMasterId == req[j].rmsItemMasterId) || !criteriaCheck[i].rmsItemMasterId)
          ) {
            criteriaCheck[i].validateMinQuantity = criteriaCheck[i].validateMinQuantity - req[j].quantity;
          }

        }

      }
      const minQuanityRes = criteriaCheck.filter((obj: any) => obj.validateMinQuantity > 0)
      if (minQuanityRes.length > 0) {
        console.log("Minqunaity failed", minQuanityRes)
        return minQuanityRes
      }
      else {
        console.log("Min qty passed", minQuanityRes)

        return validateReqWithMaxCriteriaFn(req, maxCriteria);

      }

    }
    return validateReqWithMinCriteriaFn(req, Criteria);
  }

  /* New Package Validation Logic Start */
  const validateItems = (cartItems: any, criteria: any) => {
    debugger
    criteria.map((x: any) => {
      x.originalMinQuantity = x.minQuantity;
      x.originalMaxQuantity = x.maxQuantity;
    })
    for (let cartItem of cartItems) {

      for (let criterion of criteria) {
        /* This nested if will assign a match level for the criteria, 1 being the best match and 
         * 4 being the least match
         * Note: Using an if-else won't make sure the sub department exists within that department */
        if (cartItem.rmsDepartment == criterion.rmsDepartment) {
          if (cartItem.rmsSubDepartment == criterion.rmsSubDepartment) {
            if (cartItem.rmsBracket == criterion.rmsBracket) {
              if (cartItem.rmsItemMasterId == criterion.rmsItemMasterId) {
                criterion.matchLevel = 1;
              }
              else if (criterion.rmsItemMasterId == null) {
                criterion.matchLevel = 2;
              }
              else {
                criterion.matchLevel = 6;
              }
            }
            else if (criterion.rmsBracket == null) {
              criterion.matchLevel = 3
            }
            else {
              criterion.matchLevel = 6;
            }
          }
          else if (criterion.rmsSubDepartment == null) {
            criterion.matchLevel = 4
          }
          else {
            criterion.matchLevel = 6;
          }
        }
        else {
          criterion.matchLevel = 6;
        }
      }

      criteria.sort((a: any, b: any) => a.matchLevel - b.matchLevel);

      // console.log("criteria",criteria)

      const matchedIndex: any = criteria.findIndex((x: { maxQuantity: any; matchLevel: any; }) => x.maxQuantity > 0 && x.matchLevel != 6);
      if (matchedIndex > -1) {
        criteria[matchedIndex].minQuantity -= cartItem.quantity; // min quantity should stay negative or 0
        criteria[matchedIndex].maxQuantity -= cartItem.quantity; // max quantity should stay positive or 0
      }
      else {
        criteria[0].minQuantity -= cartItem.quantity;
        criteria[0].maxQuantity -= cartItem.quantity;
      }
    }

    return criteria.filter((x: { minQuantity: any; maxQuantity: any; }) => x.minQuantity > 0 || x.maxQuantity < 0)
  }


  /* New Package Validation Logic  End*/

  const minMaxValidator = () => {
    if ((props.throwpackageCriteria  || props.givereceivedpackage ==ADD_ON_PACKAGE)&& copyData) {
      const invDetails: any = [];
      const bucketCopy = copyData;
      const packageCriteria = props.throwpackageCriteria;
      bucketCopy.forEach((obj: any) => {
        //checking items in filtered array
        //item array is empty so we are pushing that item
        invDetails.push({
          rmsDepartment: obj.departmentsEn,
          rmsSubDepartment: obj.subDepartmentsEn,
          rmsBracket: obj.bracketEn,
          rmsItemMasterId: obj.rmsItemMasterId,
          quantity: 1,
        });
      });

      const selectedInvArr = invQuantityFormation(invDetails);
      
      let maxQtyPackageCriteria: any;
     if(props.givereceivedpackage !==ADD_ON_PACKAGE){
      maxQtyPackageCriteria = JSON.parse(JSON.stringify(packageCriteria))
        const validPackageCriteria = packageCriteria.filter(
        (obj: any) => obj.minQuantity > 0
      );
}
      const invalidMinMaxObjArr: any = props.givereceivedpackage ==ADD_ON_PACKAGE ?[] :validateItems(selectedInvArr, maxQtyPackageCriteria);

      //console.log('invalidMinMaxObjArr', invalidMinMaxObjArr);
      if (invalidMinMaxObjArr?.length == 0) {
        props.setItemsearchCompleted(true);
        setPackageRules(props.throwpackageCriteria);
        if (salesLeadId == null && agreementId == undefined) {
          history.push({
            pathname: `/agreement/rental/details/${customerId}`,
          });
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
        } else if (salesLeadId == null && agreementId !== undefined) {
          // const Orgin = window.location.origin;
          // window.location.href = `${Orgin}/agreement/rental/details/${customerId}/${agreementId}`;
          history.push({
            pathname: `/agreement/rental/details/${customerId}/${agreementId}`,
          });
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
        } else {
          history.push({
            pathname: `/agreement/rental/details/${customerId}?salesLeadId=${salesLeadId}`,
          });
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
        }
        const store: any = [];
        console.log('itemArrayData from footer context', itemArrayData);
        if (itemArrayData !== undefined) {
          itemArrayData.map((value) => {
            store.push({
              inventoryId: value,
            });
          });
          setinventoryMapping(store);
          console.log('store populate', store);
        }

        // communicate to Routes that URL has changed
        // const navEvent = new PopStateEvent('popstate');
        // window.dispatchEvent(navEvent);
      } else {
        console.log('trigger error popup');
        props.triggerMinMaxPopup(invalidMinMaxObjArr);
      }
    } else if (!props.throwpackageCriteria && copyData.length == 1) {
      props.setItemsearchCompleted(true);
      if (salesLeadId == null && agreementId == undefined) {
        history.push({
          pathname: `/agreement/rental/details/${customerId}`,
        });
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
      } else if (salesLeadId == null && agreementId !== undefined) {
        // const Orgin = window.location.origin;
        // window.location.href = `${Orgin}/agreement/rental/details/${customerId}/${agreementId}`;
        history.push({
          pathname: `/agreement/rental/details/${customerId}/${agreementId}`,
        });
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
      } else {
        history.push({
          pathname: `/agreement/rental/details/${customerId}?salesLeadId=${salesLeadId}`,
        });
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
      }
      const store: any = [];
      console.log('itemArrayData from footer context', itemArrayData);
      if (itemArrayData !== undefined) {
        itemArrayData.map((value) => {
          store.push({
            inventoryId: value,
          });
        });
        setinventoryMapping(store);
        console.log('store populate', store);
      }

      // communicate to Routes that URL has changed
      // const navEvent = new PopStateEvent('popstate');
      // window.dispatchEvent(navEvent);
    }
    else {
      if (salesLeadId == null && agreementId !== undefined) {
        history.push({
          pathname: `/agreement/rental/details/${customerId}/${agreementId}`,
        });
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
      }
    }
  };

  const triggerME = () => {
    console.log('response in triggerME');

    console.log('fdgshjhshyyuuiiii');
    props.sometrigger();
  };

  const NextBtnClick = () => {
    // const captureObjectIndex: any = [];
    console.log('packageSelection in next click', packageSelection);
    console.log(
      'itemArrayReceived.length in next click',
      itemArrayReceived.length
    );

    if (packageSelection === false && itemArrayReceived.length > 1) {
      setopenPackageSelection(true);
    } else {
      // history.push({
      //   pathname: `/agreement/rental/details/${customerId}/${agreementId}`,
      // });
      // const navEvent = new PopStateEvent('popstate');
      minMaxValidator();
    }
    // let packageMapper = props.throwpackageCriteria;
    // if (packageMapper !== undefined && packageMapper !== null) {
    //   const result = packageMapper.map(function (obj) {
    //     return Object.keys(obj).reduce(function (r, key) {
    //       const k = key.includes('minQuantity') ? 'minPackageQuantity' : key;
    //       const l = key.includes('maxQuantity') ? 'maxPackageQuantity' : key;

    //       r[k] = obj[key];
    //       r[l] = obj[key];

    //       return r;
    //     }, {});
    //   });

    //   packageMapper = result;

    //   console.log('renamed object', result);
    // }

    // // console.log('props.throwpackageCriteria', props.throwpackageCriteria);
    // if (
    //   packageMapper !== undefined &&
    //   packageMapper !== null &&
    //   copyData !== undefined
    // ) {
    //   console.log('packageMapper in footer', packageMapper);
    //   console.log('props.throwCopy in footer', copyData);
    //   copyData.map((cpyObj: any) => {
    //     console.log('cpyObj.departmentsEn', cpyObj.departmentsEn);
    //     packageMapper.map((pkgObj: any) => {
    //       console.log('pkgObj.rmsDepartment', pkgObj.rmsDepartment);
    //       console.log('pkgObj.rmsSubDepartment', pkgObj.rmsSubDepartment);
    //       console.log('pkgObj.rmsBracket', pkgObj.rmsBracket);
    //       if (
    //         (pkgObj.rmsDepartment == null ||
    //           cpyObj.departmentsEn == pkgObj.rmsDepartment) &&
    //         (pkgObj.rmsSubDepartment == null ||
    //           cpyObj.subDepartmentsEn == pkgObj.rmsSubDepartment) &&
    //         (pkgObj.rmsBracket == null ||
    //           cpyObj.bracketEn == pkgObj.rmsBracket) &&
    //         (pkgObj.rmsItemMasterId == null ||
    //           cpyObj.rmsItemMasterId == pkgObj.rmsItemMasterId)
    //       ) {
    //         console.log('reached decrement');
    //         console.log('cpyObj.departmentsEn', cpyObj.departmentsEn);
    //         console.log('pkgObj.rmsBracket', pkgObj.rmsBracket);
    //         console.log('pkgObj.rmsItemMasterId', pkgObj.rmsBracket);
    //         pkgObj.minPackageQuantity = pkgObj.minPackageQuantity - 1;
    //         pkgObj.maxPackageQuantity = pkgObj.maxPackageQuantity - 1;
    //       }
    //     });
    //   });
    //   console.log('data inside package mapper', packageMapper);
    // }

    // if (packageMapper !== undefined && packageMapper !== null) {
    //   console.log('packageMapper', packageMapper);
    //   packageMapper.map((pkgObj: any, pkgIndex: any) => {
    //     if (pkgObj.minPackageQuantity > 0 || pkgObj.maxPackageQuantity < 0) {
    //       captureObjectIndex.push(pkgIndex);
    //     }
    //   });
    // }

    // console.log('captureObjectIndex.data', captureObjectIndex);
    // if (captureObjectIndex.length == 0) {
    //   props.setItemsearchCompleted(true);
    //   if (salesLeadId == null && agreementId == undefined) {
    //     history.push({ pathname: `/agreement/rental/details/${customerId}` });
    //     const navEvent = new PopStateEvent('popstate');
    //     window.dispatchEvent(navEvent);
    //   } else if (salesLeadId == null && agreementId !== undefined) {
    //     // const Orgin = window.location.origin;
    //     // window.location.href = `${Orgin}/agreement/rental/details/${customerId}/${agreementId}`;
    //     history.push({
    //       pathname: `/agreement/rental/details/${customerId}/${agreementId}`,
    //     });
    //     const navEvent = new PopStateEvent('popstate');
    //     window.dispatchEvent(navEvent);
    //   } else {
    //     history.push({
    //       pathname: `/agreement/rental/details/${customerId}?salesLeadId=${salesLeadId}`,
    //     });
    //     const navEvent = new PopStateEvent('popstate');
    //     window.dispatchEvent(navEvent);
    //   }

    //   // communicate to Routes that URL has changed
    //   // const navEvent = new PopStateEvent('popstate');
    //   // window.dispatchEvent(navEvent);
    // } else {
    //   props.triggerMinMaxPopup(captureObjectIndex);
    // }
  };

  const buildPackageSelection = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography>
            Please confirm you have selected the correct package type{' '}
            <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              {props.givereceivedpackage}
            </Typography>
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => setopenPackageSelection(false)}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setopenPackageSelection(false);
              minMaxValidator();
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className={classes.fixedBottom}>
      <Grid className={classes.floatLeft}>
        <RACButton
          color="primary"
          variant="outlined"
          className={classes.ms2}
          onClick={() => CancelClick()}
        >
          Cancel
        </RACButton>
      </Grid>

      <Grid className={classes.floatRight}>
        <RACButton
          color="primary"
          variant="contained"
          onClick={() => NextBtnClick()}
          disabled={footerControl}
          style={{ marginRight: '4px' }}
        >
          Next
        </RACButton>
      </Grid>
      {agreementVoidStatus.agreementVoidedStatus === false &&
        agreementCreateStatus.agreementCreatedStatus === false ? (
        <Grid className={classes.floatRight}>
          <AddCocustomer
            coCustomerProps={customerId}
            newCoCustomer={newcustomer}
            func={pull_data}
            somethingTrigger={triggerME}
          />
        </Grid>
      ) : null}
      <RACModalCard
        isOpen={openPackageSelection}
        maxWidth="xs"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={buildPackageSelection()}
      />
    </Grid>
  );
};
export default FooterContent;
