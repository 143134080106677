/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import LoanerWizard from '../LoanerAgreementWizard';
import { AppRouteKey } from '../../../config/route-config';
import LoanerSearchResult from '../ItemSearch/searchResult';
import LoanerAgrDetails from '../AgreementDetails/LoanerAgrDetails';
import LoanerDeliverymethod from '../LoanerAgreementDelivery/DeliveryMethod';
import { Route as CustomRoute } from '../../../router/Route';
import { LoanerContext } from '../../../context/LoanerAgreementContext';

export default function LoanerAgreementRoutes() {
  const {
    ItemSearchCompleted,
    setItemSearchCompleted,
    AgrDetailsCompleted,
    setAgrDetailsCompleted,
    DeliveryMethodCompleted,
    setDeliveryMethodCompleted,
  } = useContext(LoanerContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  // const [coCustomerDetails, setcoCustomerDetails] = useState('');
  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    //setcoCustomerDetails()
    // eslint-disable-next-line no-console
    console.log('Footer contex', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };
  // const [ItemSearchCompleted, setItemSearchCompleted] = useState(false);
  // const [AgrDetailsCompleted, setAgrDetailsCompleted] = useState(false);
  const [changeDEStatus, setchangeDEStatus] = useState(false);
  // const [DeliveryMethodCompleted, setDeliveryMethodCompleted] = useState(false);
  const param = useParams();
  console.log('param in create', param);
  console.log('in switchout agreement route comp');

  const throwerfunc = () => {
    console.log('response in throwerfunc');

    console.log('fdgshjhshyuuiii');
    setchangeDEStatus(true);
    // setthrowerObject(response);
  };
  return (
    <>
      <div>
        <div className="tab-content container-pb-custom">
          <div className="">
            <div className="">
              <div
                id="smartwizard"
                className="p-0 sw sw-theme-default sw-justified"
              >
                {/* Fixed Issues */}
                <LoanerWizard
                  ItemSearchCompleted={ItemSearchCompleted}
                  AgreementDetailsCompleted={AgrDetailsCompleted}
                  DeliveryMethodCompleted={DeliveryMethodCompleted}
                  CoCustomerDetails={{
                    coCustomerId: coCustomerId,
                    coCustomerName: coCustomerName,
                  }}
                  dethrower={changeDEStatus}
                />
                <CustomRoute
                  path="/agreement/loaner/itemsearch"
                  key={AppRouteKey.LoanerItemSearch}
                >
                  <LoanerSearchResult
                    setItemSearchCompleted={setItemSearchCompleted}
                    func={pull_data}
                    somedeeptrigger={throwerfunc}
                  />
                </CustomRoute>
                <CustomRoute
                  path="/agreement/loaner/details"
                  key={AppRouteKey.LoanerAgreementDetails}
                >
                  <LoanerAgrDetails
                    CoCustomerDetails={{
                      coCustomerId: coCustomerId,
                    }}
                    setItemSearchCompleted={setItemSearchCompleted}
                  />
                </CustomRoute>
                <CustomRoute
                  path="/agreement/loaner/delivery"
                  key={AppRouteKey.LoanerDeliveryMethod}
                >
                  <LoanerDeliverymethod
                    setItemSearchCompleted={setItemSearchCompleted}
                    setAgrDetailsCompleted={setAgrDetailsCompleted}
                    setDeliveryMethodCompleted={setDeliveryMethodCompleted}
                    func={pull_data}
                  />
                </CustomRoute>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
