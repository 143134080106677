import React from 'react';
import { Typography, Grid } from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { viewDelivery } from '../../../JSstyles/viewDelivery';
import { fieldDeviceStyles } from '../../../JSstyles/fieldDeviceStyles';

interface InstallerDetailsProps {
  installerName: string;
  phoneNumber: string;
  emailId: string;
  address: string;
}

export default function InstallerDetails(props: InstallerDetailsProps) {
  const classes = agreementGlobalStyles();
  const deliveryViewStyle = viewDelivery();
  const classes1 = fieldDeviceStyles();
  const { installerName, phoneNumber, emailId, address } = props;

  const convertPhoneNumberFormat = (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        '($1) $2-$3'
      );
  };
  return (
    <Grid
      container
      className={`${classes.mb1} ${classes.mt2} ${classes.subTitle}`}
    >
      <Grid item md={12}>
        <Typography
          variant="h5"
          className={`${classes.mt2} ${classes.subTitle} ${classes1.tabFont16}`}
        >
          Installer Details
        </Typography>
      </Grid>
      <Grid item lg={3} md={8} sm={6}>
        <Typography
          variant="caption"
          className={`${classes.w100} ${deliveryViewStyle.LabelPadding}`}
        >
          <Typography
            variant="caption"
            className={deliveryViewStyle.deliveryLabelStyle}
          >
            Installer Name :{' '}
          </Typography>

          <Typography
            variant="caption"
            className={deliveryViewStyle.appointmentstyles}
          >
            {installerName ? installerName : ' -'}
          </Typography>
        </Typography>
      </Grid>
      <Grid item lg={3} md={8} sm={6}>
        <Typography
          variant="caption"
          className={`${classes.w100} ${deliveryViewStyle.LabelPadding}`}
        >
          <Typography
            variant="caption"
            className={deliveryViewStyle.deliveryLabelStyle}
          >
            Phone # :{' '}
          </Typography>

          <Typography
            variant="caption"
            className={deliveryViewStyle.appointmentstyles}
          >
            {phoneNumber ? convertPhoneNumberFormat(phoneNumber) : ' -'}
          </Typography>
        </Typography>
      </Grid>
      <Grid item lg={3} md={8} sm={6}>
        <Typography
          variant="caption"
          className={`${classes.w100} ${deliveryViewStyle.LabelPadding}`}
        >
          <Typography
            variant="caption"
            className={deliveryViewStyle.deliveryLabelStyle}
          >
            Email ID :{' '}
          </Typography>

          <Typography
            variant="caption"
            className={deliveryViewStyle.appointmentstyles}
          >
            {emailId ? emailId : ' -'}
          </Typography>
        </Typography>
      </Grid>
      <Grid item lg={3} md={8} sm={6}>
        <Typography
          variant="caption"
          className={`${classes.w100} ${deliveryViewStyle.LabelPadding}`}
        >
          <Typography
            variant="caption"
            className={deliveryViewStyle.deliveryLabelStyle}
          >
            Address :{' '}
          </Typography>

          <Typography
            variant="caption"
            className={deliveryViewStyle.appointmentstyles}
          >
            {address ? address : ' -'}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
}
