/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles, RACCOLOR } from "@rentacenter/racstrap";

/* eslint-disable */

export const pendingAgreementStyles = () => {

  const useClasses = makeStyles((theme) => ({

    'fontbold': {

        fontWeight : "bolder"

    },

    ml1 : {

        "marginLeft" : "1%",

    },
    'tableRow' :{
      "backgroundColor" : "white"
    } ,
    
  fixTableHeadAgrTrsRec: {
    maxHeight: '900px',
    '& th': {
      position: 'sticky',
      top: 0,
      backgroundColor: '#ffffff',
      fontFamily: 'OpenSans-bold',
      zIndex: 1,
      padding: '0.9375rem 0.2rem',
      whiteSpace: 'nowrap',
      fontSize: '15px',
      fontWeight: '400',
      color: '#111111',
    },
    '& tbody': {
      '& tr': {
        position: 'sticky',
        top: 10,
        backgroundColor: '#ffffff',
      },
      '& td': {
        fontFamily: 'OpenSans-semibold',
        padding: '0.9375rem 0.2rem',
      },
    },
    disableme: {
      pointerEvents: 'none',
      opacity: 0.9,
    },
  },


  })); 

  const classes = useClasses();

  return classes;

};