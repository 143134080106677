import React from 'react';
// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';
import { RACButton, makeStyles, Typography, Grid } from '@rentacenter/racstrap';
import { ReactComponent as Erroricon } from '../../../assets/images/no-records-found.svg';

const useClasses = makeStyles((theme: any) => ({
  mx1: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
  },
  me3: {
    marginRight: theme.typography.pxToRem(16),
  },
  py4: {
    paddingTop: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
  },
  font16: {
    fontSize: theme.typography.pxToRem(16),
  },

  semiBold: {
    fontFamily: 'OpenSans-semibold',
  },

  bold: {
    fontFamily: 'OpenSans-Bold',
  },
  mt2: {
    marginTop: '8px',
  },
  textCenter: {
    textAlign: 'center',
  },
}));
export default function ModalBuilder(props: any) {
  const classes = useClasses();
  const packagevalue = props.methodController;
  const buttonBuilder = () => {
    if (packagevalue == 'package data') {
      return (
        <>
          <RACButton
            className={classes.me3}
            size="large"
            variant="outlined"
            color="secondary"
            onClick={props.triggerdeclineAddOther}
          >
            No
          </RACButton>

          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
            onClick={props.triggeraddAllItem}
          >
            Yes
          </RACButton>
        </>
      );
    } else if (packagevalue == 'break package') {
      return (
        <>
          <RACButton
            className={classes.me3}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={props.triggerdeclineAdding}
          >
            No
          </RACButton>

          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            size="large"
            onClick={props.triggercloseGroupPop}
          >
            Yes
          </RACButton>
        </>
      );
    } else if (packagevalue == 'delete package') {
      return (
        <>
          <RACButton
            className={classes.me3}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={props.triggerdeclineBreakPackage}
          >
            No
          </RACButton>

          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            size="large"
            onClick={props.triggeracceptBreakPackage}
          >
            Yes
          </RACButton>
        </>
      );
    } else if (packagevalue == 'price exception') {
      return (
        <RACButton
          className={classes.mx1}
          variant="contained"
          color="primary"
          size="large"
          onClick={props.triggercloseRateTermPopUp}
        >
          OK
        </RACButton>
      );
    } else if (packagevalue == 'package exception') {
      return (
        <RACButton
          className={classes.mx1}
          variant="contained"
          color="primary"
          size="large"
          onClick={props.triggerclosepackageExceptionController}
        >
          OK
        </RACButton>
      );
    } else if (packagevalue == 'package criteria') {
      return (
        <RACButton
          className={classes.mx1}
          variant="contained"
          color="primary"
          size="large"
          onClick={props.triggeracceptPackageCriteria}
        >
          OK
        </RACButton>
      );
    } else if (packagevalue == 'item pricing fail') {
      return (
        <RACButton
          className={classes.mx1}
          variant="contained"
          color="primary"
          size="large"
          onClick={props.triggeracceptPricingFail}
        >
          OK
        </RACButton>
      );
    } else if (packagevalue == 'reserved controlling') {
      return (
        <RACButton
          className={classes.mx1}
          variant="contained"
          color="primary"
          size="large"
          onClick={props.triggercloseReservedPopup}
        >
          OK
        </RACButton>
      );
    }
  };
  return (
    <div
      id="decisionengine"
      data-testid="breakyesBtn"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="decisionengine"
      aria-hidden="true"
    >
      <Grid item md={12} className={classes.textCenter}>
        {packagevalue == 'item pricing fail' ||
        packagevalue == 'reserved controlling' ? (
          <Erroricon style={{ width: '50px' }} />
        ) : null}
        <Typography
          className={`${classes.semiBold} ${classes.font16} ${classes.mt2}`}
        >
          {props.displayData == 'Price Exception'
            ? props.pricingExceptionData
            : props.displayData}
        </Typography>
      </Grid>
      <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
        {buttonBuilder()}
      </Grid>
    </div>
  );
}
