/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, createContext, useState } from 'react';
export const LoanerContext = createContext<any>('');
export function LoanerContextProvider(props: any) {
  const [loanerPayload, setLoanerpayload] = useState<any>([]);
  const [getAgrInfoResponse, setgetAgrInfoResponse] = useState('');
  const [AgreementId, setAgreementId] = useState('');
  const [parentinventoryNumber, setparentinventoryNumber] = useState('');
  const [getPricingInfoRes, setgetPricingInfoRes] = useState([]);
  // eslint-disable-next-line prettier/prettier
  const [CustomerSignComplete, setCustomerSignComplete] = useState(false);
  const [CustomerSignCancel, setCustomerSignCancel] = useState(false);
  const [ItemSearchCompleted, setItemSearchCompleted] = useState(false);
  const [AgrDetailsCompleted, setAgrDetailsCompleted] = useState(false);
  const [DeliveryMethodCompleted, setDeliveryMethodCompleted] = useState(false);
  const [AgreementNumber,setAgreementNumber]=useState('');

  return (
    <div>
      <LoanerContext.Provider
        value={{
          loanerPayload,
          setLoanerpayload,
          getAgrInfoResponse,
          setgetAgrInfoResponse,
          AgreementId,
          setAgreementId,
          parentinventoryNumber,
          setparentinventoryNumber,
          getPricingInfoRes,
          setgetPricingInfoRes,
          CustomerSignComplete,
          setCustomerSignComplete,
          CustomerSignCancel,
          setCustomerSignCancel,
          ItemSearchCompleted,
          setItemSearchCompleted,
          AgrDetailsCompleted,
          setAgrDetailsCompleted,
          DeliveryMethodCompleted,
          setDeliveryMethodCompleted,
          AgreementNumber,
          setAgreementNumber,
        }}
      >
        {props.children}
      </LoanerContext.Provider>
    </div>
  );
}
