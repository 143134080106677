/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const paymentSchedule = () => {
  const useClasses = makeStyles((theme) => ({
    paymentterm: {
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(16),
      marginLeft: theme.typography.pxToRem(3),
    },
  }));
  const classes = useClasses();

  return classes;
};
