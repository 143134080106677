/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useState,
  useEffect,
  useContext,
  useImperativeHandle,
} from 'react';
import { ReactComponent as Uploading } from '../../../assets/images/cloud-uploading.svg';
import BlueCard from './blueCard';
import WhiteCard from './whiteCard';
import { Usercontext } from './searchResult';
import { getCompetitors, GetEmployeeID, GetRole } from '../../../api/user';
import CurrencyInput from 'react-currency-input-field';
import { AgreementContext } from '../../../context/AgreementContext';
import { addedItemStyles } from '../../../JSstyles/addedItemStyles';
// import {agreementStyle} from './Test';
import SecondFactor from '../../Rental/TwoFactorPopup/TwoFactorPopup';
// import Erroricon from '../../../assets/images/erroricon.png';
import { ReactComponent as Erroricon } from '../../../assets/images/error-icon.svg';

import {
  RACCheckBox,
  RACTextbox,
  RACSelect,
  RACModalCard,
  RACButton,
  Grid,
  Card,
  CardContent,
  Typography,
  RACChip,
} from '@rentacenter/racstrap';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
const AddedItem = (props: any) => {
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const classes = props.throwStyle;
  const addedItemClassName = addedItemStyles();
  // const classes = useClasses();
  const shareData: any = useContext(Usercontext);
  const {
    // competitorDataContext,
    setproofFileName,
    proofFileName,
    isCompCheck,
    setisCompCheck,
    agreementCreateStatus,
    initialAgrData,
    // containCompetitor,
    // setselectedCompetitor,
    // selectedCompetitor,
    isInReinstateMode,
    isSacDaysCompleted,
    coworkerCheck,
    // setcompetitorProofData,
    // setinitialAgrData,
  } = useContext(AgreementContext);

  // const compeFileNameObj = {
  //   fileNameCompetitor: '',
  // };
  // const [competitorData, setcompetitorData] = competitorDataContext;
  // const [compeFileName, setcompeFileName] = useState('');
  // const [compeFileName, setcompeFileName] = useState(compeFileNameObj);
  const [SecondFactorEnabled, setSecondFactorEnabled] = useState(false);
  const SLAObj = {
    TwoFactorCancelClick: false,
    TwoFactorCompleted: false,
  };
  const needSLAObj = {
    needSLAController: false,
  };
  const [percentCalculation, setpercentCalculation] = useState(0);
  const [SLAController, setSLAController] = useState(SLAObj);
  const [needSLA, setneedSLA] = React.useState(needSLAObj);
  const [thresHoldExceptionMessage, setthresHoldExceptionMessage] =
    useState('');
  const [initialAgreementRate, setinitialAgreementRate] = useState<any>();
  const [agrRate, setagrRate]: any = useState('0.00');
  const [agrTerm, setagrTerm] = useState('0.00');
  const [openRateAlertModal, setopenRateAlertModal] = useState(false);

  const [agrTotal, setAgrTotal] = useState('0.00');
  const [cashPrice, setCashPrice] = useState('0.00');
  const [epoAmount, setepoAmount] = useState('0.00');
  const [slaOverrideInvalid, setslaOverrideInvalid] = useState('');
  const [conditionLoader, setconditionLoader] = useState<boolean>(true);

  const [fullCompeFileName, setfullCompeFileName] = useState<any>('');
  // const [competitorAry, setcompetitorAry] = useState<any>();
  // const [competitorSelect, setcompetitorSelect] = useState<any>('');
  const [enableZeroValidate, setenableZeroValidate] = React.useState(false);
  const [invalidTerm, setinvalidTerm] = React.useState(false);
  // const [controlCurrencyInput, setcontrolCurrencyInput] = React.useState(false);
  const [SLAMinRole, setSLAMinRole] = useState('');
  const ModuleName = 'Edit AgmtRt W/O Competitor';
  const [CurrentRole, setCurrentRole] = useState('');

  // const [controlCompetitor, setControlCompetitor] = useState(true);
  const [roleFromSLA, setroleFromSLA] = useState('');
  //const [pricedata, setpricedata] = React.useState([]);
  const [proof, setproof] = useState('');
  const [correctProof, setcorrectProof] = useState(false);
  const [wrongProof, setwrongProof] = useState(false);
  const [bigFile, setbigFile] = useState(false);
  const [checkedState, setcheckedState] = useState(false);
  // const [throwPackageValue, setthrowPackageValue] = useState<any>();
  // const [classes, setclasses] = useState<any>();

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);

  const configDataObj = {
    CAR: 0,
    FSCCAR: 0,
    AM: 10,
    FSCAM: 10,
    LAM: 10,
    FSCLAM: 10,
    SM: 10,
    FSCSM: 10,
    DMT: 50,
    FSCDMT: 50,
    DM: 50,
    FSCDM: 50,
    RD: 50,
    FSCRD: 50,
  };

  const [configData, setConfigData] = useState(configDataObj);


  useEffect(() => {
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
          console.log('currentRole', currentRole);
          setCurrentRole(currentRole);
        }
      }
    };
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      console.log('containerDataRole', containerData.GetRole());
      setCurrentRole(containerData.GetRole());
    } else {
      GettingRole();
    }
  }, []);

  useEffect(() => {
    console.log('before if condition', props.priceexceptionMessage);
    // setthrowPackageValue(props.throwPackage);
    if (
      props.priceexceptionMessage !== null &&
      props.priceexceptionMessage !== undefined
    ) {
      console.log(
        'inside props data of price exception',
        props.priceexceptionMessage
      );
      handleExceptionRevert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priceexceptionMessage]);
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (shareData !== undefined) {
      console.log('Added Item Shared Data', shareData);

      setagrRate(shareData.agreementPricing.agreementRate);
      setagrTerm(shareData.agreementPricing.agreementTerm);
      setAgrTotal(shareData.agreementPricing.agreementTotal);
      setCashPrice(shareData.agreementPricing.cashPrice);
      if (shareData.agreementPricing?.epoAmount) {
        setepoAmount(shareData.agreementPricing.epoAmount);
      }
      // if (initialAgrData === undefined) {
      //   setinitialAgrData(shareData.agreementPricing.agreementRate);
      //   setinitialAgreementRate(shareData.agreementPricing.agreementRate);
      // } else {
      //   setinitialAgreementRate(initialAgrData);
      // }
      if (initialAgrData !== undefined) {
        setinitialAgreementRate(initialAgrData);
      }
    } else {
      setagrRate('');
      setagrTerm('');
      setAgrTotal('');
      setCashPrice('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proof, shareData]); // this method is used to validate the proof //PS-8

  const agrRatechange = (AgrRate: any) => {
    setagrRate(AgrRate);
  };
  // this method will store the new agr Rate value

  const agrTermchange = (AgrTerm: any) => {
    setagrTerm(AgrTerm);
  }; // this method will store the new agr Term value

  const handleExceptionRevert = () => {
    if (shareData !== undefined) {
      setagrRate(shareData.agreementPricing.agreementRate);
      setagrTerm(shareData.agreementPricing.agreementTerm);
    }
    //setcheckedState(false);
  };

  const setDefault = () => {
    console.log('gbfvdcsdkdshbvsdb reach std');
    if (shareData !== undefined) {
      setagrRate(shareData.agreementPricing.agreementRate);
      setagrTerm(shareData.agreementPricing.agreementTerm);
    }
    // props.setCompetitorValue(false);
    setcheckedState(false);
    // setcontrolCurrencyInput(false);
    setisCompCheck(false);
    // setcompetitorSelect('');
    // setselectedCompetitor(undefined);
    // props.setCompetitorDropValue('0');
    props.triggerSetToDefault();
    // setControlCompetitor(true);
    setcorrectProof(false);
  }; // this method will store the default  agr Term and Rate value //PS-7
  const closeInvalidTermAndRate = () => {
    // setDefault();
    if (shareData !== undefined) {
      setagrRate(shareData.agreementPricing.agreementRate);
      setagrTerm(shareData.agreementPricing.agreementTerm);
    }

    setinvalidTerm(false);
    setenableZeroValidate(false);
  };

  // const checkAgrRateChange = (e: any) => {
  //   if (e.key == "Enter") {
  //     (document.getElementById("AgrRate") as HTMLInputElement).blur();
  //     if (parseInt(agrRate) <= 0) {
  //       setenableZeroValidate(true);
  //     } else {
  //       props.setreceiveAgreementRate(agrRate);
  //     }
  //   }
  // };

  // const checkAgrTermChange = (e: any) => {
  //   if (e.key == "Enter") {
  //     if (parseInt(agrTerm) < 1) {
  //       setinvalidTerm(true);
  //     } else {
  //       props.setreceiveAgreementTerm(agrTerm);
  //     }
  //   }
  // };

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  const blurFuction = (functionController: any): any => {
    console.log('checking agrrate', agrRate);
    console.log('initialAgreementRate', initialAgreementRate);
    console.log(
      'checking shareData.agreementPricing.agreementRate',
      shareData.agreementPricing.agreementRate
    );

    //console.log('checking shareData.agreementPricing.agreementRate', agrRate);
    if (
      functionController == 'RateChange' &&
      Number(agrRate) !== Number(shareData.agreementPricing.agreementRate)
    ) {
      const percentCalculationInBlur = Math.abs(
        ((initialAgreementRate - agrRate) * 100) / initialAgreementRate
      );
      setpercentCalculation(Math.round(percentCalculationInBlur));
      console.log(
        'master calculation of percentCalculationInBlur',
        percentCalculationInBlur
      );

      console.log('bvdscsdvsdf chech checkedState', checkedState);
      console.log('bvdscsdvsdf chech !checkedState', !checkedState);
      console.log('jdhsbvjhsdbv check me', agrRate <= initialAgreementRate);

      if (!checkedState && Number(agrRate) <= Number(initialAgreementRate)) {
        console.log('success reach of if in rate chage');

        let minRole = '';
        let enteredIf = false;
        for (const item in configDataObj) {
          console.log('item in configDataObj', configData[item]);
          console.log(
            'percentCalculation',
            Math.round(percentCalculationInBlur)
          );
          if (
            configData[item] == Math.round(percentCalculationInBlur) &&
            Math.round(percentCalculationInBlur) !== 0
          ) {
            // enteredIf = false;
            console.log('correct item in configDataObj', configData[item]);
            console.log(
              'correct percentCalculation',
              Math.round(percentCalculationInBlur)
            );
            minRole = item;
            const isFSC_Contain = minRole.includes('FSC');
            let output;
            if (isFSC_Contain) {
              output = [minRole.slice(0, 3), '-', minRole.slice(3)].join('');
              setSLAMinRole(output);
            } else {
              output = item;
              setSLAMinRole(minRole);
            }
            const Roles = [
              'CAR',
              'FSC-CAR',
              'AM',
              'FSC-AM',
              'LAM',
              'FSC-LAM',
              'SM',
              'FSC-SM',
              'DMT',
              'FSC-DMT',
              'DM',
              'FSC-DM',
              'RD',
              'FSC-RD',
            ];
            console.log('CurrentRole in Variable', CurrentRole);
            console.log(
              'Roles.indexOf(String(CurrentRole))',
              Roles.indexOf(String(CurrentRole))
            );
            console.log('Roles.indexOf(output)', Roles.indexOf(output));
            console.log('the output role', output);
            if (Roles.indexOf(String(CurrentRole)) < Roles.indexOf(output)) {
              setneedSLA({ ...needSLA, needSLAController: true });
              enteredIf = true;
            }
            // break;
            break;
          } else if (configData[item] > Math.round(percentCalculationInBlur)) {
            console.log('correct item in configDataObj', configData[item]);
            console.log(
              'correct percentCalculation',
              Math.round(percentCalculationInBlur)
            );
            minRole = item;
            const isFSC_Contain = minRole.includes('FSC');
            let output;
            if (isFSC_Contain) {
              output = [minRole.slice(0, 3), '-', minRole.slice(3)].join('');
              setSLAMinRole(output);
            } else {
              output = item;
              setSLAMinRole(minRole);
            }
            const Roles = [
              'CAR',
              'FSC-CAR',
              'AM',
              'FSC-AM',
              'LAM',
              'FSC-LAM',
              'SM',
              'FSC-SM',
              'DMT',
              'FSC-DMT',
              'DM',
              'FSC-DM',
              'RD',
              'FSC-RD',
            ];
            console.log('CurrentRole in Variable', CurrentRole);
            console.log(
              'Roles.indexOf(String(CurrentRole))',
              Roles.indexOf(String(CurrentRole))
            );
            console.log('Roles.indexOf(output)', Roles.indexOf(output));
            console.log('the output role', output);
            if (Roles.indexOf(String(CurrentRole)) < Roles.indexOf(output)) {
              setneedSLA({ ...needSLA, needSLAController: true });
              enteredIf = true;
            }
            break;
          }
        }

        console.log('CurrentRole logger', CurrentRole);

        if (
          Math.round(percentCalculationInBlur) > 50 &&
          (CurrentRole == 'DMT' ||
            CurrentRole == 'FSC-DMT' ||
            CurrentRole == 'DM' ||
            CurrentRole == 'FSC-DM' ||
            CurrentRole == 'RD' ||
            CurrentRole == 'FSCRD')
        ) {
          console.log('reached jhfbvhsdbjvhbs');

          setneedSLA({ ...needSLA, needSLAController: true });
          enteredIf = true;
          setSLAMinRole(CurrentRole);
        }

        let allowedRate;
        let minAmount;
        if (CurrentRole == 'CAR' || CurrentRole == 'FSC-CAR') {
          setthresHoldExceptionMessage(
            `Coworker can only change rate upto $${initialAgreementRate} second level approval is required for this change.`
          );
        } else if (
          CurrentRole == 'AM' ||
          CurrentRole == 'LAM' ||
          CurrentRole == 'SM' ||
          CurrentRole == 'FSC-AM' ||
          CurrentRole == 'FSC-LAM' ||
          CurrentRole == 'FSC-SM'
        ) {
          // allowedRate = Math.round(initialAgreementRate * 0.1);
          // minAmount = Math.round(initialAgreementRate - allowedRate);
          allowedRate = initialAgreementRate * 0.1;
          minAmount = initialAgreementRate - allowedRate;
          setthresHoldExceptionMessage(
            `Coworker can only change rate upto $${Number(
              minAmount.toString()
            ).toFixed(2)} second level approval is required for this change.`
          );
        } else if (
          CurrentRole == 'DMT' ||
          CurrentRole == 'DM' ||
          CurrentRole == 'RD' ||
          CurrentRole == 'FSC-DMT' ||
          CurrentRole == 'FSC-DM' ||
          CurrentRole == 'FSC-RD'
        ) {
          //allowedRate = Math.round(initialAgreementRate * 0.5);
          //minAmount = Math.round(initialAgreementRate - allowedRate);
          minAmount = initialAgreementRate / 2;
          setthresHoldExceptionMessage(
            `Coworker can only change rate upto $${Number(
              minAmount.toString()
            ).toFixed(2)} second level approval is required for this change.`
          );
        }

        if (enteredIf === false) {
          console.log('entered if pricing call', enteredIf);

          parseInt(agrRate) < 1
            ? setenableZeroValidate(true)
            : props.setreceiveAgreementRate(agrRate);
        }
        console.log('minRole', minRole);
      } else {
        console.log('jsdgvhjsdbhvb log else');

        parseInt(agrRate) < 1
          ? setenableZeroValidate(true)
          : props.setreceiveAgreementRate(agrRate);
      }
      // {
      //   parseInt(agrRate) < 1
      //     ? setinvalidTerm(true)
      //     : props.setreceiveAgreementRate(agrRate);
      // }
    } else if (
      functionController == 'TermChange' &&
      agrTerm !== shareData.agreementPricing.agreementTerm
    ) {
      {
        parseInt(agrTerm) < 1
          ? setinvalidTerm(true)
          : props.setreceiveAgreementTerm(agrTerm);
      }
      // if (checkedState) {
      //   setcontrolCurrencyInput(true);
      // }
    }
  };


  const temp = (itemID: any) => {
    props.triggeroneDelete(itemID);
  };

  // const closeZeroValidate = () => {
  //   setDefault();
  //   setenableZeroValidate(false);
  // };

  const handleClick = () => {
    setproof('');
  };

  const onUploadFileChange = ({ target }) => {
    console.log('target value check in proof', target.value);

    if (target.value == '') {
      setproof('');
      return;
    }

    // if (proof !== '') {
    //   setproof(proof);
    // } else {
    setproof(target.value);
    // }
    // let finalData: any = '';
    // if (target.value) {
    //   //setproof(target.value);
    //   finalData = truncString(
    //     target.value.replace('C:\\fakepath\\', ''),
    //     10,
    //     '...'
    //   );
    //   console.log('finalData', finalData);
    //   setcompeFileName({ ...compeFileName, fileNameCompetitor: finalData });
    // } else {
    //   //setproof(proof);
    //   setcompeFileName({ ...compeFileName, fileNameCompetitor: proofFileName });
    // }

    //setproof(target.value);
    const finalData = truncString(
      target.value.replace('C:\\fakepath\\', ''),
      10,
      '...'
    );
    setfullCompeFileName(target.value.replace('C:\\fakepath\\', ''));
    console.log('finalData', finalData);
    // setcompeFileName({ ...compeFileName, fileNameCompetitor: finalData });

    let proofController: any = 1;
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    if (target.value) {
      fileToBase64(target.files[0], (err, result) => {
        if (result) {
          setFile(result);
          setFileName(target.files[0]);
        }
        const fileSize = Math.round(target.files[0].size / 1024);
        if (fileSize >= 1024) {
          proofController = 0;
        }

        const fileType = target.files[0].type;
        const extensionType = target.files[0].type.split('/').pop();
        console.log('required file', target.files[0].name);
        if (
          (extensionType === 'pdf' ||
            extensionType === 'img' ||
            extensionType === 'png' ||
            extensionType === 'jpeg' ||
            extensionType === 'jpg' ||
            extensionType === 'svg') &&
          proofController == 1
        ) {
          setwrongProof(false);
          setcorrectProof(true);
          setbigFile(false);
          setproofFileName(finalData);
        } else if (proofController == 0) {
          setwrongProof(false);
          setcorrectProof(false);
          setbigFile(true);
        } else {
          setwrongProof(true);
          setcorrectProof(false);
          setbigFile(false);
        }
        const proofData = {
          fileType: fileType,
          base64Data: result.slice(result.indexOf(',') + 1),
          extensionType: extensionType,
        };
        // setcompetitorProofData(proofData);
        // setcompetitorData(proofData);
      });
    }
  };

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const invalidTermDiv = (displayData: any) => {
    return (
      <div
        id="decisionengine"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="decisionengine"
        aria-hidden="true"
      >
        {/* <div className="modal-dialog">
  
              <div className="modal-content"> */}

        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.semiBold} ${classes.font16}`}>
            {displayData}
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => closeInvalidTermAndRate()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const SLACancel = () => {
    setSLAController({ ...SLAController, TwoFactorCancelClick: false });
    setSecondFactorEnabled(false);
    setagrRate(shareData.agreementPricing.agreementRate);
  };
  const SLACompleted = (value: any) => {
    setSLAController({ ...SLAController, TwoFactorCompleted: value });
    setSecondFactorEnabled(false);
  };

  const acceptSLA = () => {
    setneedSLA({ ...needSLA, needSLAController: false });
    setSecondFactorEnabled(true);
  };

  const SLAModal = (displayData: any) => {
    return (
      <div id="decisionengine">
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.semiBold} ${classes.font16}`}>
            {displayData}
          </Typography>
          <Typography className={`${classes.semiBold} ${classes.font16}`}>
            Do you want to proceed ?
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.me3}
            size="large"
            variant="outlined"
            color="secondary"
            onClick={() => {
              setneedSLA({ ...needSLA, needSLAController: false });
              setagrRate(shareData.agreementPricing.agreementRate);
            }}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => acceptSLA()}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };

  // useEffect(() => {
  //   if (SLAController.TwoFactorCompleted) {
  //     parseInt(agrRate) < 1
  //       ? setinvalidTerm(true)
  //       : props.setreceiveAgreementRate(agrRate);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [SLAController.TwoFactorCompleted]);

  useEffect(() => {
    console.log(
      'SLAController.TwoFactorCompleted',
      SLAController.TwoFactorCompleted
    );
    console.log('percentCalculation in use efect', percentCalculation);

    if (SLAController.TwoFactorCompleted) {
      // && percentCalculation <= 50
      // parseInt(agrRate) < 1
      //   ? setinvalidTerm(true)
      //   :
      props.setreceiveAgreementRate(agrRate);
    }
    // else if (SLAController.TwoFactorCompleted && roleFromSLA == '008') {
    //   // parseInt(agrRate) < 1
    //   //   ? setinvalidTerm(true)
    //   //   : props.setreceiveAgreementRate(agrRate);
    //   console.log('expected point reached');

    //   props.setreceiveAgreementRate(agrRate);
    // }
    // else if (SLAController.TwoFactorCompleted && percentCalculation > 50) {
    //   console.log('reached the alert log');
    //   const allowedRate = Math.round(initialAgreementRate * 0.5);
    //   const minAmount = initialAgreementRate - allowedRate;
    //   setslaOverrideInvalid(
    //     `Override is invalid. SLA user can override from $${parseFloat(
    //       minAmount.toString()
    //     ).toFixed(2)} to $${initialAgreementRate}`
    //   );
    //   setopenRateAlertModal(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SLAController.TwoFactorCompleted]);

  const openRateAlert = () => {
    return (
      <div>
        <Grid className="text-center">
          <Erroricon className={classes.racErrorIcon} />
          <Typography className="racErrorFunction">
            {slaOverrideInvalid}
          </Typography>
        </Grid>
        <Grid className="text-center">
          <RACButton
            className="racSearch racSearchPrimary me-2"
            onClick={() => setopenRateAlertModal(false)}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const provideSLAData = (value: any) => {
    console.log('received provideSLA', value);
    const roleCode = value.substr(value.length - 3);
    console.log('received provideSLA after trim', roleCode);

    setroleFromSLA(roleCode);
  };

  return (
    <Grid item md={3}>
      {SecondFactorEnabled === true ? (
        <SecondFactor
          // setTwoFactorCancelClick={setTwoFactorCancelClick}
          setTwoFactorCancelClick={SLACancel}
          // setTwoFactorCompleted={setTwoFactorCompleted}
          setTwoFactorCompleted={SLACompleted}
          setRoleFromSLAModule={provideSLAData}
          moduleName={ModuleName}
          currentRole={CurrentRole}
          minRole={SLAMinRole}
          throwCurrentPage="addedItem"
          agrRate={initialAgreementRate}
        />
      ) : null}
      <Typography className={`${classes.title} ${classes.mt2}`} variant="h6">
        Added Items
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.p1}>
          <Card
            className={`${classes.racBlue} ${classes.card} ${classes.p3} ${classes.pb0}`}
          >
            <BlueCard
              //throwPackage={throwPackageValue}
              triggerTemp={temp}
              throwStyle={props.throwStyle}
            />
          </Card>

          <div className={`${classes.mt3}`}>
            <div className={classes.row}>
              <Typography className={classes.title} variant="h6">
                Agreement Total
              </Typography>
              <WhiteCard throwStyle={props.throwStyle} />
              <Grid md={12} className={`${classes.mb3} ${classes.flexCenter}`}>

                <Typography variant="caption" className={classes.formLabel}>
                  Agreement Rate
                </Typography>

                <Grid
                  className={`${addedItemClassName.inputGroup1} ${classes.w50} ${classes.floatRight}`}
                >
                  <Typography
                    variant="caption"
                    className={`${classes.formLabel} ${classes.floatRight} ${classes.semiBold}`}
                  >
                    {agrRate}
                  </Typography>

                </Grid>
              </Grid>
              <Grid md={12} className={`${classes.mb3} ${classes.flexCenter}`}>

                <Typography variant="caption" className={classes.formLabel}>
                  Agreement Term
                </Typography>

                <Grid
                  className={`${addedItemClassName.inputGroup1} ${classes.w50} ${classes.floatRight}`}
                >
                  <Typography
                    variant="caption"
                    className={`${classes.formLabel} ${classes.floatRight} ${classes.semiBold}`}
                  >
                    {agrTerm}
                  </Typography>
                </Grid>
              </Grid>

            </div>
          </div>
          <Card
            className={`${classes.card} ${classes.racTotalBlue} ${classes.p3}`}
          >

            <Grid
              md={12}
              className={`${addedItemClassName.agrAddedTotal} ${classes.mb2}`}
            >
              <Typography
                variant="caption"
                className={`${classes.textBlue} ${classes.font14} ${classes.semiBold}`}
              >
                Agreement Total
              </Typography>
              {/* )} */}

              <Typography
                variant="caption"
                className={`${classes.textBlue} ${classes.font18} ${classes.bold}`}
              >
                ${agrTotal.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
            </Grid>
          </Card>
        </CardContent>
      </Card>

      <RACModalCard
        isOpen={invalidTerm}
        maxWidth="xs"
        borderRadius={'26px !important'}

      // children={invalidTermDiv()}
      >
        {invalidTermDiv('Invalid term. Rate should be greater than 0')}
      </RACModalCard>

      <RACModalCard
        isOpen={enableZeroValidate}
        maxWidth="xs"
        borderRadius={'25px !important'}

      //children={enableZeroValidatediv()}
      >
        {invalidTermDiv('Invalid rate. Rate should be greater than $0.00')}
      </RACModalCard>

      <RACModalCard
        isOpen={needSLA.needSLAController}
        maxWidth="xs"
        borderRadius={'25px !important'}
      //children={enableZeroValidatediv()}
      >
        {SLAModal(thresHoldExceptionMessage)}
      </RACModalCard>
      <RACModalCard
        isOpen={openRateAlertModal}
        maxWidth={'sm'}
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={openRateAlert()}
      ></RACModalCard>
    </Grid>
  );
};

export default AddedItem;
