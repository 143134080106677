/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { createContext, useState, useEffect } from 'react';
export const FeatureFlagContext = createContext<any>('');
export function FeatureFlagContextProvider(props: any) {
  const [featureFlagDetails, setFeatureFlagDetails] = useState({});
  const [sacRolesLimitDetails, setSacRolesLimitDetails] = useState({});
  const [priceOverrideLimitDetails, setPriceOverrideLimitDetails] = useState({})
  const [termOverrideLimitDetails, setTermOverrideLimitDetails] = useState({})


  return (
    <div>
      <FeatureFlagContext.Provider
        value={{
          featureFlagDetails,
          setFeatureFlagDetails,
          sacRolesLimitDetails,
          setSacRolesLimitDetails,
          priceOverrideLimitDetails,
          setPriceOverrideLimitDetails,
          termOverrideLimitDetails,
          setTermOverrideLimitDetails
        }}
      >
        {props.children}
      </FeatureFlagContext.Provider>
    </div>
  );
}
export function MakeFeatureFlagObjFn(storeValue: any) {
  const tempFeatureFlagObj = {};
  for (let i = 0; i < storeValue.length; i++) {
    tempFeatureFlagObj[storeValue[i].paramKeyName] = storeValue[i].paramValue;
  }
  console.log('tempFeatureFlagObj', tempFeatureFlagObj);
  return tempFeatureFlagObj;
}

export function makeSACrolesAllocationFn(req: any) {
  const paramGroupName = 'SACOverrideLimit';
  const paramCategoryName = 'PriceOverride';
  const sacRoleLimitObj = {};

  for (let i = 0; i < req.length; i++) {
    if (
      req[i].paramCategoryName == paramCategoryName &&
      req[i].paramGroupName == paramGroupName
    ) {
      sacRoleLimitObj[req[i].paramKeyName] = Number(req[i].paramValue);
    }
  }


  const SACOverrideRoles = [
    'FSC-CAR',
    'CAR',
    'FSC-AM',
    'AM',
    'FSC-LAM',
    'LAM',
    'FSC-SMT',
    'SMT',
    'FSC-SM',
    'SM',
    'FSC-SSM',
    'SSM',
    'FSC-DMT',
    'DMT',
    'FSC-DM',
    'DM',
    'FSC-RDT',
    'RDT',
    'FSC-RD',
    'RD'
  ];

  const sortedData: any = {};
  for (let i = 0; i < SACOverrideRoles.length; i++) {
    sortedData[SACOverrideRoles[i]] = sacRoleLimitObj[SACOverrideRoles[i]];
  }

  return sortedData;
}




export function makePriceOverRiderolesAllocationFn(req: any) {
  const paramGroupName = 'PriceOverrideLimit';
  const paramCategoryName = 'PriceOverride';
  const priceOverRideRoleLimitObj = {};

  for (let i = 0; i < req.length; i++) {
    if (
      req[i]?.paramCategoryName == paramCategoryName &&
      req[i]?.paramGroupName == paramGroupName
    ) {
      priceOverRideRoleLimitObj[req[i]?.paramKeyName] = Number(req[i]?.paramValue) * 100;
    }
  }
  const PriceOverrideRoles = [
    'FSC-CAR',
    'CAR',
    'RPMT',
    'FSC-AM',
    'AM',
    'FSC-LAM',
    'LAM',
    'FSC-SMT',
    'SMT',
    'FSC-SM',
    'SM',
    'FSC-SSM',
    'SSM',
    'CAM',
    'FSC-DMT',
    'DMT',
    'FSC-DM',
    'DM',
    'FSC-RDT',
    'RDT',
    'FSC-RD',
    'RD'
  ];
  const sortedData: any = {};
  for (let i = 0; i < PriceOverrideRoles.length; i++) {
    sortedData[PriceOverrideRoles[i]] = priceOverRideRoleLimitObj[PriceOverrideRoles[i]];
  }

  return sortedData;
}
export function makeTermOverRiderolesAllocationFn(req: any) {
  const paramGroupName = 'TermOverrideLimit';
  const paramCategoryName = 'PriceOverride';
  const priceOverRideRoleLimitObj: any = {};

  for (let i = 0; i < req.length; i++) {
    if (
      req[i]?.paramCategoryName == paramCategoryName &&
      req[i]?.paramGroupName == paramGroupName
    ) {
      priceOverRideRoleLimitObj[req[i]?.paramKeyName] = Number(req[i]?.paramValue) * 100;
    }
  }
  const PriceOverrideRoles = [
    'FSC-CAR',
    'CAR',
    'RPMT',
    'FSC-AM',
    'AM',
    'FSC-LAM',
    'LAM',
    'FSC-SMT',
    'SMT',
    'FSC-SM',
    'SM',
    'FSC-SSM',
    'SSM',
    'CAM',
    'FSC-DMT',
    'DMT',
    'FSC-DM',
    'DM',
    'FSC-RDT',
    'RDT',
    'FSC-RD',
    'RD'
  ];
  const sortedData: any = {};
  for (let i = 0; i < PriceOverrideRoles.length; i++) {
    sortedData[PriceOverrideRoles[i]] = priceOverRideRoleLimitObj[PriceOverrideRoles[i]];
  }

  return sortedData;
}
