/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useState, useContext, useEffect } from 'react';
import {
  Grid,
  RACButton,
  RACModalCard,
  RACTextbox,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { AgreementContext } from '../../../context/AgreementContext';

export default function InstallmentNote({ InstallmentNoteStable }) {
  const classes = agreementGlobalStyles();
  const currentURL = window.location.href;
  const [installmentNotePopup, setInstallmentNotePopup] = useState(false);
  const [agreementInfoValues, setAgreementInfoValues] = useState({
    weeklyRate: '',
    noteCost: '',
    weeklyTerm: '',
    remainingNote: '',
    remainingWeeks: '',
    notePaidtoDate: '',
    openDate: '',
    closeDate: '',
    closeReason: '',
  });
  const [showInstallmentNote, setShowInstallmentNote] = useState(false);
  const { agreementInfoData } = useContext(AgreementContext);
  useEffect(() => {
    setInstallmentNotePopup(true);
    console.log('agreementInfoData in installment note', agreementInfoData);
    if (agreementInfoData?.installmentNoteInfo) {
      //setShowInstallmentNote(true);
      setAgreementInfoValues({
        weeklyRate: agreementInfoData?.installmentNoteInfo?.weeklyRate
          ? agreementInfoData?.installmentNoteInfo?.weeklyRate
          : '',
        noteCost: agreementInfoData?.installmentNoteInfo?.noteCost
          ? agreementInfoData?.installmentNoteInfo?.noteCost
          : '',
        weeklyTerm: agreementInfoData?.installmentNoteInfo?.totalTerm
          ? agreementInfoData?.installmentNoteInfo?.totalTerm
          : '',
        remainingNote: agreementInfoData?.installmentNoteInfo?.remainingNoteCost
          ? agreementInfoData?.installmentNoteInfo?.remainingNoteCost
          : '',
        remainingWeeks:
          agreementInfoData?.installmentNoteInfo?.remainingTerm &&
          agreementInfoData?.installmentNoteInfo?.openDate
            ? agreementInfoData?.installmentNoteInfo?.remainingTerm
            : '0',
        notePaidtoDate: agreementInfoData?.installmentNoteInfo?.noteCostPaid
          ? agreementInfoData?.installmentNoteInfo?.noteCostPaid
          : '',
        openDate: agreementInfoData?.installmentNoteInfo?.openDate
          ? ConvertDate(agreementInfoData?.installmentNoteInfo?.openDate)
          : '',
        closeDate:
          agreementInfoData?.agreementInformation?.agreementStatusRefCode ==
            'ARTS' ||
          agreementInfoData?.agreementInformation?.agreementStatusRefCode ==
            'VOID'
            ? ConvertDate(agreementInfoData?.agreementInformation?.closeDate)
            : agreementInfoData?.installmentNoteInfo?.closeDate
            ? ConvertDate(agreementInfoData?.installmentNoteInfo?.closeDate)
            : '',
          closeReason:
            agreementInfoData?.installmentNoteInfo?.closeReason
            // agreementInfoData?.installmentNoteInfo?.noteStatus == 'PIF'
            //   ? 'Paid in Full'
            //   : '',
  
      });
    }
  }, []);
  const ConvertDate = (date: string) => {
    if (date) {
      const dateArr = date.split('-');
      const revDateArr = dateArr.reverse();
      return revDateArr.join('/');
    } else {
      return '';
    }
  };
  const InstallmentNotePopup = () => {
    return (
      <>
        <Grid>
          <Grid container className={classes.textCenter} md={12}>
            <Grid item md={6} className={classes.installmntBox1}>
              <div className={classes.installCurrencyTxtBox}>
                <RACTextbox
                  inputlabel="Weekly Rate"
                  className={'text'}
                  value={agreementInfoValues.weeklyRate}
                  inputLabelClassname={classes.installmntTxtTitle}
                  isCurrency={true}
                  disabled={true}
                  dollarTextClassName={classes.dollarClass}
                  inputTextBoxClassname={classes.txtClass}
                />
              </div>
            </Grid>
            <Grid item md={6} className={classes.installmntBox2}>
              <div className={classes.installCurrencyTxtBox}>
                <RACTextbox
                  inputlabel="Note Cost"
                  className={'text'}
                  value={agreementInfoValues.noteCost}
                  inputTextBoxClassname={classes.width10}
                  inputLabelClassname={classes.installmntTxtTitle}
                  isCurrency={true}
                  disabled={true}
                  dollarTextClassName={classes.dollarClass}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.textCenter}
            md={12}
            style={{ marginTop: '10px' }}
          >
            <Grid item md={6} className={classes.installmntBox1}>
              <div className={classes.installTxtBox}>
                <RACTextbox
                  inputlabel="Weekly Term"
                  className={'text'}
                  value={agreementInfoValues.weeklyTerm}
                  inputTextBoxClassname={classes.width10}
                  inputLabelClassname={classes.installmntTxtTitle}
                  disabled={true}
                />
              </div>
            </Grid>
            <Grid item md={6} className={classes.installmntBox2}>
              <div className={classes.installCurrencyTxtBox}>
                <RACTextbox
                  inputlabel="Remaining Note"
                  className={'text'}
                  value={agreementInfoValues.remainingNote}
                  inputTextBoxClassname={classes.width10}
                  inputLabelClassname={classes.installmntTxtTitle}
                  isCurrency={true}
                  disabled={true}
                  dollarTextClassName={classes.dollarClass}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.textCenter}
            md={12}
            style={{ marginTop: '10px' }}
          >
            <Grid item md={6} className={classes.installmntBox1}>
              <div className={classes.installTxtBox}>
                <RACTextbox
                  inputlabel="Remaining Weeks"
                  className={'text'}
                  value={agreementInfoValues.remainingWeeks}
                  inputTextBoxClassname={classes.width10}
                  inputLabelClassname={classes.installmntTxtTitle}
                  disabled={true}
                />
              </div>
            </Grid>
            <Grid item md={6} className={classes.installmntBox2}>
              <div className={classes.installCurrencyTxtBox}>
                <RACTextbox
                  inputlabel="Note Paid to Date"
                  className={'text'}
                  value={agreementInfoValues.notePaidtoDate}
                  inputTextBoxClassname={classes.width10}
                  inputLabelClassname={classes.installmntTxtTitle}
                  isCurrency={true}
                  disabled={true}
                  dollarTextClassName={classes.dollarClass}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.textCenter}
            md={12}
            style={{ marginTop: '10px' }}
          >
            <Grid item md={6} className={classes.installmntBox1}>
              <div className={classes.installTxtBox}>
                <RACTextbox
                  inputlabel="Open Date"
                  className={'text'}
                  value={agreementInfoValues.openDate}
                  inputTextBoxClassname={classes.width10}
                  inputLabelClassname={classes.installmntTxtTitle}
                  disabled={true}
                />
              </div>
            </Grid>
            <Grid item md={6} className={classes.installmntBox2}>
              <div className={classes.installTxtBox}>
                <RACTextbox
                  inputlabel="Close Date"
                  className={'text'}
                  value={agreementInfoValues.closeDate}
                  inputTextBoxClassname={classes.width10}
                  inputLabelClassname={classes.installmntTxtTitle}
                  disabled={true}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.textCenter}
            style={{ marginTop: '10px' }}
          >
            <Grid item className={classes.installmntBox1}>
              <div className={classes.installTxtBox}>
                <RACTextbox
                  inputlabel="Close Reason"
                  className={'text'}
                  value={agreementInfoValues.closeReason}
                  inputLabelClassname={classes.installmntTxtTitle}
                  disabled={true}
                />
              </div>
            </Grid>
          </Grid>
          <Grid style={{ float: 'right', marginTop: '50px' }} md={12}>
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              onClick={() => {
                setInstallmentNotePopup(false);
                InstallmentNoteStable();
              }}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <>
      <RACModalCard
        isOpen={installmentNotePopup}
        maxWidth={'sm'}
        title="Agreement Information"
        closeIcon={true}
        onClose={() => {
          setInstallmentNotePopup(false);
          InstallmentNoteStable();
        }}
        className={classes.installmntPopup}
        borderRadius={'20px !important'}
      >
        {InstallmentNotePopup()}
      </RACModalCard>
      {/* {showInstallmentNote && currentURL.includes('agreement/info/details/') ? (
        <RACButton
          className={`${classes.floatLeft} ${classes.me2}`}
          variant="contained"
          color="primary"
          onClick={() => setInstallmentNotePopup(true)}
        >
          Installment Note
        </RACButton>
      ) : null} */}
    </>
  );
}
