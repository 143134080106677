/* eslint-disable no-irregular-whitespace */
/* eslint-disable prettier/prettier */
import React from "react";
import { Grid, Typography, makeStyles } from "@rentacenter/racstrap";
export default function ComingSoonPage() {
    const useClasses = makeStyles((theme: any) => ({
        w100: { width: "100%", },
        infoTextStyle: {
            color: "#A5A6A6",
            fontSize: theme.typography.pxToRem(13),
            textAlign: "center",
        },
        my5: {
            marginTop: theme.typography.pxToRem(48),
            marginBottom: theme.typography.pxToRem(48),
        },
        mb5: {
            marginBottom: theme.typography.pxToRem(48),
        },
        px2: {
            paddingRight: theme.typography.pxToRem(8),
            paddingLeft: theme.typography.pxToRem(8),
        },
        transformGrid: {
            transform: "translate(0%,240px)",
        },
    }));
    const classes = useClasses();
    return (
        <Grid item
            className={`${classes.px2} ${classes.w100} ${classes.transformGrid}`}
        >
            <Grid className={`${classes.infoTextStyle} ${classes.my5}`}>
                <Typography>This feature is not currently assigned to you</Typography>
            </Grid>
        </Grid>
    );
}