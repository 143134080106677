/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect } from 'react';
import {
  RACTable,
  RACTableCell,
  RACTableRow,
  RACRadio,
} from '@rentacenter/racstrap';
import { agreementInfoStyles } from '../../../JSstyles/agreementInfoStyles';
import { useHistory } from 'react-router-dom';

const GridPage = (props: any) => {
  const agrGridClassName = agreementInfoStyles();
  const history = useHistory();
  const classes = props.throwStyles;
  console.log('thrown Style', classes);

  const [tablevalue, settablevalue]: any = useState([]);
  const [switchInvNumber, setInventoryNumber]: any = useState();
  const [customeStyle, setcustomeStyle]: any = useState('');
  function apiItemCall() {
    console.log('props.GridValues in grid', props.GridValues);

    settablevalue(props.GridValues);
  }
  function apiAgrTotalCall() {
    console.log('gfdbfvd0', props.SecondGridValues);

    settablevalue(props.SecondGridValues);
  }
  function apiAgrInfoCall() {
    settablevalue(props.ThirdGridValues);
  }
  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';

    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  useEffect(() => {
    settablevalue([]);

    // eslint-disable-next-line no-console
    console.log('In grid inventory values', props.GridValues);
    if (
      props.mapper[props.mapper.length - 1].gridInfo ===
      'inventoryItemDetailsAFObject'
    ) {
      apiItemCall();
    } else if (
      props.mapper[props.mapper.length - 1].gridInfo ===
      'agreementTotalAFObject'
    ) {
      apiAgrTotalCall();
    } else if (
      props.mapper[props.mapper.length - 1].gridInfo ===
      'agreementDetailsAFObject'
    ) {
      apiAgrInfoCall();
    }
    if (
      props.mapper[props.mapper.length - 1].gridInfo ===
      'agreementTotalAFObject'
    ) {
      //console.log('Custome Class Receive',props.mapper[7].customiseTable);
      setcustomeStyle(props.mapper[7].customiseTable);
      console.log(
        'props.mapper[7].customiseTable',
        props.mapper[7].customiseTable
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.mapper,
    props.GridValues,
    props.SecondGridValues,
    props.ThirdGridValues,
  ]);

  // useEffect(()=>{
  //   console.log('switchInvNumber',switchInvNumber);

  // },[switchInvNumber])

  return (
    <div>
      <RACTable
        className={
          customeStyle == ''
            ? `${agrGridClassName.racGrid} ${agrGridClassName.racAgrViewGrid}`
            : `${agrGridClassName.racAgrTotalGrid} ${agrGridClassName.racGrid} ${agrGridClassName.racAgrViewGrid}`
        }
        renderTableHead={headerBuild}
        renderTableContent={bodyBuild}
      />
    </div>
  );

  function headerBuild() {
    return props.mapper.map((obj: any) => {
      if (obj.displayname !== undefined) {
        if (
          obj.displayname === 'Tag Rate' ||
          // obj.displayname === 'Tag Term' ||
          obj.displayname === 'Tag Total' ||
          obj.displayname === 'Agr Rate' ||
          // obj.displayname === 'Agr Term' ||
          obj.displayname === 'Agr Total' ||
          obj.displayname === 'Inv Depr%' ||
          obj.displayname === 'Delivery Charge'
        ) {
          return (
            <RACTableCell className={classes.textRight}>
              {obj.displayname}
            </RACTableCell>
          );
        } else if (
          obj.displayname === 'Tag Term' ||
          obj.displayname === 'Agr Term'
        ) {
          return (
            <RACTableCell className={classes.textCenter}>
              {obj.displayname}
            </RACTableCell>
          );
        } else if (obj.displayname === 'RadioButton') {
          return <RACTableCell></RACTableCell>;
        } else {
          return <RACTableCell>{obj.displayname}</RACTableCell>;
        }
      }
    });
  }
  function bodyBuild() {
    if (tablevalue !== undefined) {
      console.log('expecyed taval', tablevalue);

      return tablevalue.map((tabval: any, index: any) => {
        return (
          <RACTableRow key={index} backgroundColor="white">
            {subBuild(tabval)}
          </RACTableRow>
        );
      });
    }

    function subBuild(tabval: any) {
      return props.mapper.map((obj: any, index: any) => {
        for (const key in tabval) {
          console.log('check key', key);
          if (obj.key == 'radioRequire') {
            console.log('tabval[obj.key]', tabval['inventoryNumber']);

            return (
              <RACTableCell key={index}>
                <RACRadio
                  size="small"
                  disabled={false}
                  value={tabval['inventoryNumber']}
                  onClick={() => setInventoryNumber(tabval['inventoryNumber'])}
                  checked={
                    switchInvNumber == tabval['inventoryNumber'] ? true : false
                  }
                  name="invNumber"
                  // backgroundColor={text("backgroundColor", "green")}
                />
              </RACTableCell>
            );
          }
          if (key === obj.key) {
            if (
              key === 'tagRate' ||
              key === 'tagTotal' ||
              key === 'agreementRate' ||
              key === 'agreementTotal' ||
              key === 'deliveryCharge'
            ) {
              //const x = Number(tabval[obj.key]);
              //console.log("tabval[obj.key]", tabval[obj.key]);
              // const currencyValue = tabval[obj.key];
              const currencyValue: any = tabval[obj.key].replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              );
              console.log('currency value', currencyValue);

              //const x = parseInt(tabval[obj.key]);
              return (
                <RACTableCell key={index} className={classes.textRight}>
                  $ {currencyValue}
                </RACTableCell>
              );
            } else if (key === 'tagTerm' || key === 'agreementTerm') {
              return (
                <RACTableCell key={index} className={classes.textCenter}>
                  {tabval[obj.key]}
                </RACTableCell>
              );
            } else if (
              key === 'agreementInventoryDepreciation' ||
              key === 'inventoryDepreciation'
            ) {
              return (
                <RACTableCell key={index} className={classes.textRight}>
                  {tabval[obj.key]}%
                </RACTableCell>
              );
            } else if (key === 'tagTerm' || key == 'agreementTerm') {
              return <RACTableCell key={index}>{tabval[obj.key]}</RACTableCell>;
            } else if (key === 'dueDate') {
              return <RACTableCell key={index}>{tabval[obj.key]}</RACTableCell>;
            } else if (key === 'inventoryNumber') {
              return (
                <RACTableCell key={index}>
                  <a
                    onClick={() =>
                      history.push({
                        pathname: `/inventory/${tabval[obj.key]}/inventoryinformation`,
                      })
                    }
                    className={`${classes.racpadLink} ${agrGridClassName.agrInfoLink}`}
                  >
                    {tabval[obj.key]}
                  </a>
                </RACTableCell>
              );
            } else if (key === 'policy') {
              return <RACTableCell key={index}>{tabval[obj.key]}</RACTableCell>;
            } else {
              if (tabval[obj.key] === null) {
                return (
                  <RACTableCell key={index} title={tabval[obj.key]}>
                    -
                  </RACTableCell>
                );
              } else {
                return (
                  <RACTableCell key={index} title={tabval[obj.key]}>
                    {truncString(tabval[obj.key], 30, '...')}
                  </RACTableCell>
                );
              }
            }
          }
        }
      });
    }
  }
};
export default GridPage;
