/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import {
    Grid,
    Card,
    CardContent,
    RACTextbox,
    RACSelect,
    RACToggle,
    RACButton,
    Typography,
    RACModalCard
} from '@rentacenter/racstrap';
import { ReactComponent as SearchIcon } from '../../../../assets/images/search-btn.svg'
import { ReactComponent as Alerticon } from '../../../../assets/images/no-records-found.svg';
import { agreementGlobalStyles } from '../../../../JSstyles/agreementGlobalStyles';
import { CustomerOrderAgreementContext } from '../../../../context/CustomerOrderAgreementContext';
import { getFitmentApi, getProductAPI } from '../../../../api/user';
import { useLocation } from 'react-router-dom';
export default function SearchCriteriaComponent(props?: any) {
    const { searchItem, setsearchItem, optionsArrayTB, setOptionsArrayTB, setToggleTB, toggleTB, searchItemObj, clearSearchCriteria, modelNumberArray, setModelNumberArray, addedItems, setAddedItems, yearsArray, setYearsArray,
        makeArray, setMakeArray,
        styleArray, setStyleArray,
        modelArray, setModelArray,
        searchResultEnable, setsearchResultEnable,
        sizeArray, setSizeArray,
        searchResultLoder, setsearchResultLoder,
        setsearchResultNoRecords, searchResultNoRecords,
        year, setYear,
        make, setMake,
        model, setModel,
        style, setStyle,
        setsearchResult,
        size, setSize, errorMessage, setErrorMessage,
        is500PopupOpened, setIs500PopupOpened,
        is400PopupOpened, setIs400PopupOpened,
        isToggleDisableAlert, setIsToggleDisableAlert,
        modelAndImageArray, setModelAndImageArray, setItemSearchTableContent,
        modelNumber, setModelNumber, salesleadFitemnetid, setsalesleadFitemnetid,
        salesinstallerName, setsalesinstallerName,
        salesinstalldate, setsalesinstalldate,
        salesTimeSlot, setSalesTimeSlot, salesLeadYear, setsalesLeadYear,
        salesLeadMake, setsalesLeadMake,
        salesLeadModel, setsalesLeadModel,
        salesLeadstyle, setsalesLeadstyle } = useContext(CustomerOrderAgreementContext);

    const [makeLoader, setMakeLoader] = useState(false);
    const [yearLoader, setYearLoader] = useState(true)
    const [modelLoader, setModelLoader] = useState(false);
    const [styleLoader, setStyleLoader] = useState(false);
    const [sizeLoader, setSizeLoader] = useState(false);
    const [searchLoader, setsearchLoader] = useState(false)
    const [searchButtonEnable, setSearchButtonEnable] = useState(false)
    const [somethingWentWrongOpen, setSomethingWentWrongOpen] = useState(false);
    const [clearmodelArray, setclearmodelArray] = useState([]);
    const [onclickclear, setonclickclear] = useState(false)
    const search = useLocation().search;
    const salesLeadId = new URLSearchParams(search).get('salesLeadId');
    console.log('salesLeadId', { salesLeadId })

    const { SalesInfo } = props; // sales Info from the parent container;
    console.log('salesleadInfo in the searchcriteria ', { SalesInfo })

    const [invokeApicall, setinvokeApicall] = useState(false);

    useEffect(() => {
        console.log('Inside the INVOKEapI CALL', invokeApicall)
        if (invokeApicall) {
            (async () => {

                getFitmentData('clearclick');
                setinvokeApicall(false);
            })();
        }
    }, [invokeApicall])


    //state variables to store the dropdown arrays;
    // const [yearsArray, setYearsArray] = useState([
    //     {
    //         'value': '0',
    //         'label': 'Select'
    //     }
    // ]);
    // const [makeArray, setMakeArray] = useState([
    //     {
    //         'value': '0',
    //         'label': 'Select'
    //     }
    // ]);
    // const [modelArray, setModelArray] = useState([
    //     {
    //         'value': '0',
    //         'label': 'Select'
    //     }
    // ]);
    // const [styleArray, setStyleArray] = useState([
    //     {
    //         'value': '0',
    //         'label': 'Select'
    //     }
    // ]);
    // const [sizeArray, setSizeArray] = useState([
    //     {
    //         'value': '0',
    //         'label': 'Select'
    //     }
    // ]);
    // //state variable to store the selected values by the user in the dropdowns;
    // const [year, setYear] = useState("");
    // const [make, setMake] = useState("");
    // const [model, setModel] = useState("");
    // const [style, setStyle] = useState("");
    // const [size, setSize] = useState("");
    //the following use 

    if (SalesInfo?.orderInfo?.fitmentId && SalesInfo?.vehicleInfo != ''
        && SalesInfo?.vehicleInfo != null && SalesInfo?.inventories[0]?.modelNumber != "null"
        && SalesInfo?.inventories[0]?.modelNumber != '')
       {
        console.log('Inside the searchCriteria file and saleslead function')
        useEffect(() => {
            debugger;

            setsalesleadFitemnetid(SalesInfo?.orderInfo?.fitmentId)
            setsalesinstallerName(SalesInfo?.installerInfo?.installerName)
            setsalesinstalldate(SalesInfo?.installerInfo?.installationDate)
            setSalesTimeSlot(SalesInfo?.installerInfo?.installationTime)

            console.log('changes of the saleslead');
            setModelNumber(SalesInfo?.inventories[0]?.modelNumber ? 
                SalesInfo?.inventories[0]?.modelNumber 
                : (SalesInfo?.inventories[0]?.modelNumber == "null") ? '' : '')

            setYearsArray([{
                label: SalesInfo?.vehicleInfo?.year ? SalesInfo?.vehicleInfo?.year : '',
                value: SalesInfo?.vehicleInfo?.year ? SalesInfo?.vehicleInfo?.year : ''
            }
            ]),

            setYear(SalesInfo?.vehicleInfo?.year ? SalesInfo?.vehicleInfo?.year : '')


            setYearLoader(false)

            setMakeArray([{
                label: SalesInfo?.vehicleInfo?.make ? SalesInfo?.vehicleInfo?.make : '', // value to be displayed in the dd
                value: SalesInfo?.vehicleInfo?.make ? SalesInfo?.vehicleInfo?.make : '' //Id of the value
            }])

            setMake(SalesInfo?.vehicleInfo?.make ? SalesInfo?.vehicleInfo?.make : '')

            setModelArray([{
                label: SalesInfo?.vehicleInfo?.model ? SalesInfo?.vehicleInfo?.model : '', // value to be displayed in the dd
                value: SalesInfo?.vehicleInfo?.model ? SalesInfo?.vehicleInfo?.model : '' //Id of the value
            }])

            setModel(SalesInfo?.vehicleInfo?.model ? SalesInfo?.vehicleInfo?.model : '')

            setStyleArray([{
                label: SalesInfo?.vehicleInfo?.style ? SalesInfo?.vehicleInfo?.style : '', // value to be displayed in the dd
                value: SalesInfo?.vehicleInfo?.style ? SalesInfo?.vehicleInfo?.style : ''
            }])


            setStyle(SalesInfo?.vehicleInfo?.style ? SalesInfo?.vehicleInfo?.style : '')


            setSizeArray([{
                label: SalesInfo?.vehicleInfo?.size ? SalesInfo?.vehicleInfo?.size : '', // value to be displayed in the dd
                value: SalesInfo?.vehicleInfo?.size ? SalesInfo?.vehicleInfo?.size : ''
            }])
            setSize(SalesInfo?.vehicleInfo?.size ? SalesInfo?.vehicleInfo?.size : '')

            debugger;


            // const fetchData = async () => {
            //     debugger
            //     let response = await salesLeadfitmentId()
            // }


            // if (SalesInfo?.vehicleInfo != null && SalesInfo?.vehicleInfo != '' && SalesInfo?.vehicleInfo != undefined) {
            //     fetchData();
            // }


            getProductInformation()


        }, [])

    }
    else {
        useEffect(() => {
            // setModelNumber(SalesInfo?.inventories[0]?.modelNumber ? SalesInfo?.inventories[0]?.modelNumber : '')
            getFitmentData()
        }, [year, make, model, style, size]);
    }

    async function onclick() {
        if (salesLeadId) {
            debugger
            setYear("");
            setMake("");
            setModel("");
            setStyle("");
            setSize("");
            setModelNumber('');
            setYearLoader(true)
            // setModelNumberArray([])
            setYearsArray([]);
            setMakeArray([]);
            setModelArray([]);
            setStyleArray([]);
            setSizeArray([]);
            setinvokeApicall(true);
            setonclickclear(true);
            setSearchButtonEnable(false)
        }
        else {
            setYear("");
            setMake("");
            setModel("");
            setStyle("");
            setSize("");
            setModelNumber('');
            setSearchButtonEnable(false)
        }
    }

    // async function salesLeadfitmentId() {
    //     debugger

    //     const queryParam = `year=${SalesInfo?.vehicleInfo?.year}&make=${SalesInfo?.vehicleInfo?.make}&model=${SalesInfo?.vehicleInfo?.model}&trim= ${SalesInfo?.vehicleInfo?.style} &size= ${SalesInfo?.vehicleInfo?.size}`

    //     console.log('queryParams from the salesleadId', { queryParam })
    //     const fitMentPayload = {
    //         "isTierBuyer": true,
    //         "tierBuyerQueryParam": queryParam,
    //         "modelNumber": [SalesInfo?.inventories[0]?.modelNumber],
    //         "storeNumber": window.sessionStorage.getItem('storeNumber')
    //     }
    //     const fitmentApiResponse = await getFitmentApi(fitMentPayload);
    //     setsearchLoader(false)
    //     console.log(fitmentApiResponse, "this is the api response for the fitmentApiResponse");

    //     if (fitmentApiResponse.status == 500) {
    //         setIs500PopupOpened(true);
    //         return;
    //     } else if (fitmentApiResponse.status == 400) {
    //         setIs400PopupOpened(true);
    //         setErrorMessage(fitmentApiResponse.data.errors[0].error)
    //         return;
    //     }

    //     let modelAndImage: any = [];
    //     for (let values of fitmentApiResponse.data) {
    //         for (let value of values.modelAndImage) {
    //             let modelArray = {
    //                 fitmentId: values.fitmentId,
    //                 supplierNumber: values.supplierNumber,
    //                 modelNumber: value.modelNumber,
    //                 imageSource: value.imageSource,
    //                 minimumQuantity: value.minimumQuantity
    //             }
    //             modelAndImage.push(modelArray);
    //         }
    //     }

    //     setModelNumberArray(modelAndImage);
    // }
    //the function will be called when the user loads the page or changes the year,make,model,style and size;
    async function getFitmentData(src?) {
        try {
            debugger;
            let queryParam = "";
            if (year && !make) {
                queryParam = `year=${year == '0' ? '' : year}`
                setMakeLoader(true)
            }
            if (make && !model) {
                setModelLoader(true);
                queryParam = `year=${year}&make=${make}`
            }
            if (model && !style) {
                setStyleLoader(true)
                queryParam = `year=${year}&make=${make}&model=${model}`
            }
            if (style && !size) {
                setSizeLoader(true)
                queryParam = `year=${year}&make=${make}&model=${model}&trim=${style}`
            }
            if (size) {
                queryParam = `year=${year}&make=${make}&model=${model}&trim=${style}&size=${size}`
                setsearchLoader(true)
            }
            const fitMentPayload = {
                "isTierBuyer": true,
                "tierBuyerQueryParam": queryParam,
                "modelNumber": ["SAMPLEMODELNUMBER"],
                "storeNumber": window.sessionStorage.getItem('storeNumber')
            }
            const fitmentApiResponse = await getFitmentApi(fitMentPayload);
            setsearchLoader(false)
            console.log(fitmentApiResponse, "this is the api response for the fitmentApiResponse");

            if (fitmentApiResponse.status == 500) {
                setIs500PopupOpened(true);
                return;
            } else if (fitmentApiResponse.status == 400) {
                setIs400PopupOpened(true);
                setErrorMessage(fitmentApiResponse.data.errors[0].error)
                return;
            }
            if (year && make && model && style && size) {
                debugger
                let modelAndImage: any = [];
                for (let values of fitmentApiResponse.data) {
                    for (let value of values.modelAndImage) {
                        let modelArray = {
                            fitmentId: values.fitmentId,
                            supplierNumber: values.supplierNumber,
                            modelNumber: value.modelNumber,
                            imageSource: value.imageSource,
                            minimumQuantity: value.minimumQuantity
                        }
                        modelAndImage.push(modelArray);
                    }
                }
                if (src == 'clearclick') {
                    setclearmodelArray(modelAndImage);
                    setSearchButtonEnable(true)
                }
                else {
                    setModelNumberArray(modelAndImage);
                    setSearchButtonEnable(true)
                }
            }
            else {
                const fitmentOptionsList = fitmentApiResponse.data?.fitment?.map((value: any) => {
                    return {
                        label: value,
                        value: value
                    }
                })
                fitmentOptionsList?.unshift({
                    label: 'Select',
                    value: '0'
                })
                if (!year) {
                    setYearsArray(fitmentOptionsList)
                    setYearLoader(false)
                }
                if (year && !make) {
                    setMakeArray(fitmentOptionsList);
                    setMakeLoader(false);
                }
                if (make && !model) {
                    setModelLoader(false);
                    setModelArray(fitmentOptionsList);
                }
                if (model && !style) {
                    setStyleLoader(false);
                    setStyleArray(fitmentOptionsList);
                }
                if (style && !size) {
                    setSizeLoader(false);
                    setSizeArray(fitmentOptionsList);
                }
            }
        } catch (error: any) {
            console.log(error.message);
            return;
        }
    }

    async function getProductInformation(src?) {
        try {
            debugger
            setsearchResultEnable(true)
            setsearchResultLoder(true)
            console.log("this is the api call for the data", modelNumberArray);
            console.log("this is the payload for model number", modelNumber)
            let payload
            if (src == 'FromClick') {
                console.log('changes in the product api')
                payload = {
                    "isTierBuyer": false,
                    "tierBuyerQueryParam": "",
                    "modelNumber": !modelNumber ? clearmodelArray.map((value: any) => {
                        return value.modelNumber
                    }) : [modelNumber],
                    "storeNumber": window.sessionStorage.getItem("storeNumber")
                }

                console.log(payload, "this is the payoad for the product api");
                const apiResponse = await getProductAPI(payload);
                console.log(apiResponse, "this is the api response for the product api");
                console.log('apirespose in the search criteria', apiResponse)
                setsearchResultLoder(false)
                if (apiResponse?.status == 200) {
                    debugger;
                    setItemSearchTableContent(apiResponse?.data?.rmsItemInformation);
                }
                else if (
                    apiResponse.status == 400
                ) {
                    setIs400PopupOpened(true);
                    const errorvalue = "Error in EA service call "
                    const errormessgae400 = String(apiResponse?.data?.errors[0]?.error)
                    if (apiResponse?.data?.errors[0]?.error.includes(errorvalue)) {
                        setErrorMessage('The product does not have availability')
                    }
                    else {
                        setErrorMessage(apiResponse?.data?.errors[0]?.error)
                    }
                }
                else {
                    setSomethingWentWrongOpen(true)
                }
            }

            else {
                payload = {
                    "isTierBuyer": false,
                    "tierBuyerQueryParam": "",
                    "modelNumber": !modelNumber ? SalesInfo?.inventories[0]?.modelNumber ? [SalesInfo?.inventories[0]?.modelNumber] : modelNumberArray.map((value: any) => {
                        return value.modelNumber
                    }) : [modelNumber],
                    "storeNumber": window.sessionStorage.getItem("storeNumber")
                }
                console.log(payload, "this is the payoad for the product api");
                const apiResponse = await getProductAPI(payload);
                console.log('apirespose in the search criteria', apiResponse)
                setsearchResultLoder(false)
                if (apiResponse?.status == 200) {
                    debugger;
                    setItemSearchTableContent(apiResponse?.data?.rmsItemInformation);
                }
                else if (
                    apiResponse.status == 400
                ) {
                    setIs400PopupOpened(true);
                    const errorvalue = "Error in EA service call "
                    const errormessgae400 = String(apiResponse?.data?.errors[0]?.error)
                    if (apiResponse?.data?.errors[0]?.error.includes(errorvalue)) {
                        setErrorMessage('The product does not have availability')
                    }
                    else {
                        setErrorMessage(apiResponse?.data?.errors[0]?.error)
                    }
                }
                else {
                    setSomethingWentWrongOpen(true)
                }
            }
        } catch (error: any) {
            console.log(error.message, "This is the error message in the product information");
        }
    }

    const classes = agreementGlobalStyles();
    const somethingWentWrongPopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    <Alerticon></Alerticon>

                    <Typography className={classes.formLabel}>
                        Something went wrong!
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSomethingWentWrongOpen(false);
                            setsearchResultNoRecords(true);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </div>
        );
    }
    return <>
        <Card className={classes.card}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={3} className={classes.formLabel}>
                        <RACTextbox
                            inputlabel="Model #"
                            // inputLabelClassname={classes.formLabel}
                            type="text"
                            id="a11y_Model"
                            name="model"
                            isCurrency={false}
                            OnChange={
                                (e) => {
                                    setModelNumber(e.target.value.trimStart())
                                }
                            }
                            value={modelNumber}
                        />
                    </Grid>
                    <Grid item md={3} className={classes.formLabel}>
                        <RACSelect
                            inputLabel="Year"
                            loading={yearLoader}
                            options={yearsArray}
                            name={"year"}



                            isDisabled={yearsArray?.length > 0
                                ? yearsArray?.length > 0 && onclickclear === true
                                    ? false
                                    :  yearsArray?.length > 0  && salesLeadId 
                                        ? true
                                        : false
                                : false}
                            onChange={(e) => {
                                setYear(e.target.value)
                                setMake('');
                                setMakeArray([{
                                    'value': '0',
                                    'label': 'Select'
                                }]);
                                setStyle('');
                                setStyleArray([{
                                    'value': '0',
                                    'label': 'Select'
                                }]);
                                setModel('');
                                setModelArray([{
                                    'value': '0',
                                    'label': 'Select'
                                }]);
                                setSize('');
                                setSizeArray(
                                    [
                                        {
                                            'value': '0',
                                            'label': 'Select'
                                        }
                                    ]
                                );
                                setinvokeApicall(true)
                            }}
                            defaultValue={year ? year : '0'}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <RACSelect
                            loading={
                                makeLoader
                            }
                            inputLabel="Make"

                            options={makeArray}
                            name={"Make"}

                            isDisabled={makeArray.length > 1 ? false : true}
                            onChange={(e) => {
                                setMake(e.target.value)
                                setStyle('');
                                setStyleArray([{
                                    'value': '0',
                                    'label': 'Select'
                                }]);
                                setModel('');
                                setModelArray([{
                                    'value': '0',
                                    'label': 'Select'
                                }]);
                                setSize('');
                                setSizeArray(
                                    [
                                        {
                                            'value': '0',
                                            'label': 'Select'
                                        }
                                    ]
                                );
                                setinvokeApicall(true)
                            }}
                            defaultValue={make ? make : '0'}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <RACSelect
                            inputLabel="Vehicle Model"
                            options={modelArray}
                            loading={modelLoader}
                            name={"Model"}
                            isDisabled={modelArray.length > 1 ? false : true}
                            onChange={(e) => {
                                setModel(e.target.value)
                                setStyle('');
                                setStyleArray([{
                                    'value': '0',
                                    'label': 'Select'
                                }]);
                                setSize('');
                                setSizeArray(
                                    [
                                        {
                                            'value': '0',
                                            'label': 'Select'
                                        }
                                    ]
                                );
                                setinvokeApicall(true)
                            }}
                            defaultValue={model ? model : '0'}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{
                    "marginTop": "20px"
                }}>
                    <Grid item md={3}>
                        <RACSelect
                            inputLabel="Style"
                            loading={styleLoader}
                            options={styleArray}
                            name={"state"}
                            isDisabled={styleArray.length > 1 ? false : true}
                            onChange={(e) => {
                                setStyle(e.target.value);
                                setSize('');
                                setSizeArray(
                                    [
                                        {
                                            'value': '0',
                                            'label': 'Select'
                                        }
                                    ]
                                );
                                setinvokeApicall(true)
                            }}
                            defaultValue={style ? style : '0'}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <RACSelect
                            inputLabel="Size"
                            options={sizeArray}
                            loading={
                                sizeLoader
                            }
                            name={"state"}
                            isDisabled={sizeArray.length > 1 ? false : true}
                            onChange={(e) => {
                                setSize(e.target.value);
                                setinvokeApicall(true)
                            }}
                            defaultValue={size ? size : '0'}
                        />
                    </Grid>
                    <Grid item md={3}  >
                    </Grid>
                    <Grid item md={3} container style={{ textAlign: 'center', marginTop: '20px' }} >
                        <Grid item md={6}>
                            <RACButton
                                variant="outlined"
                                color="primary"
                                style={{ paddingLeft: '45px', paddingRight: '45px' }}
                                onClick={(e) => {
                                    onclick();
                                    //setinvokeApicall(true);
                                }}>
                                Clear
                            </RACButton>
                        </Grid>
                        <Grid item md={6}>
                            <Grid
                                title={!searchButtonEnable ? 'Please choose all fields' : ""}>

                                <RACButton
                                    startIcon={<SearchIcon />}
                                    // disabled={( !(modelNumber !== "") || !(year && make && model && style && size))}
                                    variant="contained"
                                    color="primary"
                                    loading={searchLoader}
                                    // className={classes.px4 + ' ' + classes.py2}
                                    disabled={searchButtonEnable ? false : true}
                                    onClick={(e) => {
                                        getProductInformation('FromClick');
                                    }}
                                >
                                    Search
                                </RACButton>
                            </Grid>

                        </Grid>


                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ "marginTop": "20px" }}>
                    <Grid item md={3} style={{ display: 'flex' }}>
                        <RACToggle
                            OnChange={() => {
                                console.log("I am from toggle disabled button")
                                if (addedItems.length > 0 && toggleTB) {
                                    setIsToggleDisableAlert(true)
                                } else {
                                    setToggleTB(!toggleTB)
                                }
                            }}
                            checked={toggleTB}
                            toggleColor={"dodgerblue"}
                        />
                        <span style={{ marginLeft: '10px' }}><b>Tire Product</b></span>
                    </Grid>
                    <RACModalCard
                        isOpen={somethingWentWrongOpen}
                        closeIcon={false}
                        maxWidth="xs"
                        borderRadius={'25px !important'}
                    >
                        {somethingWentWrongPopup()}
                    </RACModalCard>
                </Grid>
            </CardContent>
        </Card>
    </>
}