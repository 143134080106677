/* eslint-disable */
/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { GetAgreementactivity, GetRole, GetEmployeeID } from '../../../api/user';
import { useParams, useHistory } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import RePrintReport from './RePrintReport';
// import Alerticon from '../../assets/images/no-records-found.svg';
import { ReactComponent as Alerticon } from '../../../assets/images/No-records.svg';
import { AgreementContext } from '../../../context/AgreementContext';
import { fieldDeviceStyles } from '../../../JSstyles/fieldDeviceStyles';
//import CircularProgress from '@mui/material/CircularProgress';
import {
  Grid,
  Card,
  CircularProgress,
  CardContent,
  Typography,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACButton,
} from '@rentacenter/racstrap';

// import '../../styles/agreement.css';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { ContainerContext } from "../../../app/App";
import { CustomPropInterface } from '../../../index';
// import AddCocustomer from '../../components/AddCoCustomer/AddCocustomer';
interface ParamType {
  agreementId: string;
  customerId: string;

}
const AgreementActivity = () => {
  const classes1 = fieldDeviceStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  // <<<<<<< HEAD
  // const pull_data = (data) => {
  //   setcocustomerId(data.coCustomerId);
  //   setcoCustomerName(data.coCustomerName);
  //   props.func({
  //     coCustomerId: data.coCustomerId,
  //     coCustomerName: data.coCustomerName,
  //   });
  //   // eslint-disable-next-line no-console
  //   console.log('Search Result Props', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  // };
  const { viewAgrData } = useContext(AgreementContext);
  const history = useHistory();
  const classes = agreementGlobalStyles();
  const { agreementId, customerId } = useParams<ParamType>();
  const containerData = useContext(ContainerContext) as CustomPropInterface;

  /* eslint-disable no-console */
  console.log('agreementid is peresent', agreementId);
  /* eslint-enable no-console */
  const [Agreementactivity, SetAgreementActivity] = useState([]);
  const [EnableLoaderButton, setEnbleLoaderButton] = useState(true);
  const [openRePrint, setopenRePrint] = useState(false);
  const storeNumber = window.sessionStorage.getItem('storeNumber');
  const [employeeId, setEmployeeId] = useState("");
  const [CurrentRole, setCurrentRole] = useState('');
  // const Agreementactivityvalues = [
  //   {
  //     activitydate: '10/20/2021',
  //     activitytype: 'Agreement Delivered',
  //     description: 'Agreement Delivered',
  //     coworker: 'Store2221 Coworker1',
  //   },
  //   {
  //     activitydate: '04/29/2021',
  //     activitytype: 'Agreement Approved',
  //     description: 'Agreement Approved',
  //     coworker: 'Store22771 Coworker2',
  //   },
  //   {
  //     activitydate: '04/10/2021',
  //     activitytype: 'Agreement Saved',
  //     description: 'Agreement Saved',
  //     coworker: 'Store22771 Coworker3',
  //   },
  //   {
  //     activitydate: '04/01/2021',
  //     activitytype: 'Rental Agreement Create',
  //     description: 'Rental Agreement Create',
  //     coworker: 'Store22771 Coworker4',
  //   },
  // ];
  //}
  useEffect(() => {
    let employeeIdGA:any
    let loggedRole:any
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      /* eslint-disable-next-line no-console */
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        /* eslint-disable-next-line no-console */
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
          setEmployeeId(GetMenuDetailsResponse?.coworkerProfile?.employeeId);
          /* eslint-disable-next-line no-console */
          console.log('currentRole', currentRole);
          setCurrentRole(currentRole);
          loggedRole = currentRole;
          employeeIdGA = GetMenuDetailsResponse?.coworkerProfile?.employeeId
        }
      }
    };
    if (containerData !== undefined) {
      /* eslint-disable-next-line no-console */
      console.log('containerData', containerData);
      setCurrentRole(containerData.GetRole());
      setEmployeeId(containerData?.GetEmployeeId());
      loggedRole = containerData?.GetRole()
      employeeIdGA = containerData?.GetEmployeeId()

    } else {
      GettingRole();
    }
    window.dataLayer.push({
      'event': 'Loaded the Agreement Activity Page',
      'customerId': customerId,
      'storeNo': storeNumber,
      'agreementNo': agreementId,
      'employeeId': employeeIdGA,
      'role': loggedRole,
      'currentScreen': 'Agreement Activity ',
      'previousScreen': 'Delivery Confirmation  ',
    });
  }, []);


  useEffect(() => {
    const PageLoad = async () => {
      // const pathArray = window.location.pathname.split('/');
      // /* eslint-disable no-console */
      // console.log('Path Array', pathArray);
      // /* eslint-enable no-console */
      const reg = new RegExp('^[0-9]+$');
      let Agreementactivityresponse;
      if (reg.test(agreementId)) {
        Agreementactivityresponse = await GetAgreementactivity(agreementId);
      }
      /* eslint-disable no-console */
      console.log('activity response', Agreementactivityresponse);
      /* eslint-enable no-console */
      setEnbleLoaderButton(false);
      if (
        Agreementactivityresponse !== undefined &&
        Agreementactivityresponse !== null &&
        Agreementactivityresponse.agreementActivities !== undefined &&
        Agreementactivityresponse.agreementActivities.length > 0
      ) {
        SetAgreementActivity(Agreementactivityresponse.agreementActivities);
      }
      /* eslint-enable no-console */
    };
    PageLoad();
    //console.log('Propssssssssssss', props.some);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // for building the grid after getting the datas from the api
  //VA_22
  // const BuildGrid = () => {
  //   //console.log('inside build',Agreementactivity)
  //   if (!!Agreementactivity && Agreementactivity.length > 0) {
  //     return Agreementactivity.map((value: any, index: any) => {
  //       return (
  //         <tr key={index}>
  //           <td>{value.activityDate}</td>
  //           <td title={value.activityType}>
  //             {value.activityType !== undefined &&
  //             value.activityType !== null &&
  //             value.activityType.length > 50
  //               ? value.activityType.substring(0, 50 - 4) + '....'
  //               : value.activityType}
  //           </td>
  //           <td title={value.description}>
  //             {value.description !== undefined &&
  //             value.description !== null &&
  //             value.description.length > 50
  //               ? value.description.substring(0, 50 - 4) + '....'
  //               : value.description}
  //           </td>
  //           <td>{value.coWorker}</td>
  //         </tr>
  //       );
  //     });
  //   }
  // };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const openPopup = () => {
  //   setopenRePrint(true);
  // };
  const closePopup = () => {
    setopenRePrint(false);
  };
  const AgreementActivityHead = [
    {
      displayName: 'Activity Date',
    },
    {
      displayName: 'Activity Type',
    },
    {
      displayName: 'Description',
    },
    {
      displayName: 'Coworker',
    },
  ];
  const RenderTable = () => (
    <>
      {AgreementActivityHead.map((col, index) => {
        return <RACTableCell key={index}>{col.displayName}</RACTableCell>;
      })}
    </>
  );
  const RenderTableBody = () => (
    <>
      {Agreementactivity.map((col: any) => {
        return (
          <RACTableRow hover backgroundColor="white">
            <RACTableCell>{col.activityDate}</RACTableCell>
            <RACTableCell title={col.activityType}>
              {col.activityType !== undefined &&
                col.activityType !== null &&
                col.activityType.length > 50
                ? col.activityType.substring(0, 50 - 4) + '....'
                : col.activityType}
            </RACTableCell>
            <RACTableCell title={col.description}>
              {col.description !== undefined &&
                col.description !== null &&
                col.description.length > 50
                ? col.description.substring(0, 50 - 4) + '....'
                : col.description}
            </RACTableCell>
            <RACTableCell>{col.coWorker}</RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  const Activitycancel = () => {
    history.push({
      pathname: `/customer/searchcustomer`,
    });
  };
  const Reprintclick = () => {
    setopenRePrint(true);
  };

  return (
    <div>
      <div
        className={classes.agreementContainerSpacing}
        id="agreementactivity"
        role="tabpanel"
        aria-labelledby="agreementactivity-tab"
      >
        <Grid container spacing={2}>
          <Grid item sm={12} md={12}>
            <Typography className={`${classes.title} ${classes.mb2}`}>
              Agreement Activity
            </Typography>
            <Card className={classes.card}>
              <CardContent>
                <RACTable
                  className={`${classes.racGrid} ${classes1.agrActivity}`}
                  renderTableHead={RenderTable}
                  renderTableContent={RenderTableBody}
                />
                {Agreementactivity.length == 0 &&
                  EnableLoaderButton === false ? (
                  <Grid
                    item
                    md={12}
                    className={`${classes.Norecords} ${classes.my3}`}
                  >
                    <Alerticon className={classes.alertIcon}></Alerticon>
                    No Records Found
                  </Grid>
                ) : null}
                {EnableLoaderButton === true ? (
                  <Grid
                    item
                    md={12}
                    className={`${classes.Norecords} ${classes.my3}`}
                  >
                    <CircularProgress />
                  </Grid>
                ) : null}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      {/* footer starts here */}
      <Grid className={classes.fixedBottom}>
        <Grid className={classes.floatLeft}>
          <RACButton
            variant="outlined"
            color="secondary"
            onClick={() => Activitycancel()}
          >
            Cancel
          </RACButton>
        </Grid>
        <Grid className={classes.floatRight}>
          {openRePrint ? <RePrintReport closeMethod={closePopup} /> : null}
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => Reprintclick()}
            disabled={
              viewAgrData?.data?.agreementWorkflow?.isTransferInitiated == 1
                ? true
                : false
            }
          >
            Reprint
          </RACButton>
        </Grid>
      </Grid>
    </div>
  );
};
export default AgreementActivity;
