/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/no-duplicate-string */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';

// import searchbtn from '../../src/assets/images/search-btn.svg';
export const addCoCustomerStyles = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const useClasses = makeStyles((theme) => ({
    searchBtn: {
      // backgroundImage: `url(${searchbtn}) !important`,
      // backgroundRepeat: 'no-repeat !important',
      // //   backgroundPosition: '4px important',
      // backgroundPositionX: '13px !important',
      // backgroundPositionY: '12px !important',
      // paddingLeft: '35px',
      fontWeight: 'bolder',
      fontFamily: 'OpenSans-semibold',
    },

    newCustomer: {
      fontWeight: 'bold',
      fontSize: '15px',
      margin: '30px 0px',
      justifyContent: 'center',
    },
    customerName: {
      fontWeight: 'bolder',
      fontSize: '16px',
    },
    fixTableHead: {
      overflowY: 'auto',
      maxHeight: '500px',
      '& tbody': {
        '& tr': {
          position: 'sticky',
          top: 10,
          backgroundColor: `${RACCOLOR.WHITE}`,
        },
      },
      '& th': {
        zIndex: 1,
        position: 'sticky',
        top: 0,
        backgroundColor: `${RACCOLOR.WHITE}`,
        fontFamily: 'OpenSans-semibold',
      },
      '& td': {
        fontFamily: 'OpenSans-regular',
      },
    },
    footerFixedButtons: {
      position: 'sticky',
      bottom: '-19px',
      right: '0px',
      backgroundColor: 'white',
      padding: '14px 0px',
    },
    racsearchBtn: {
      cursor: 'pointer',

      width: '25px',

      float: 'left',

      paddingTop: '0.2rem',
    },
    phoneinput: {
      display: 'block',

      width: '100%',

      padding: '0.158rem 0.66rem',

      fontSize: '1rem',

      lineHeight: '1.5',

      color: '#212529',

      backgroundColor: '#fff',

      border: '0.0625rem solid #c4c4c4',

      appearance: 'none',

      borderRadius: '0.35rem',

      whiteSpace: 'nowrap',

      '&:focus-visible': {
        boxShadow: '0 0 0 0.25rem rgb(0 123 255 / 25%)',

        borderColor: '#80bdff',

        borderRadius: '8px',

        outline: 'none',
      },
    },
    disablePhonenumberInput: {
      backgroundColor: '#d3d3d3 !important',
    },
    ssnpadding: {
      padding: ' 6px 8px 8px 8px !important',
    },
  }));

  return useClasses();
};
