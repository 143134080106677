/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as SortdownIcon } from '../../../assets/images/sort-down-icon.svg';
import { ReactComponent as SortupIcon } from '../../../assets/images/sort-up-icon.svg';
import { ReactComponent as ItemAddition } from '../../../assets/images/ItemAddition.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReinstateAgreementContext } from '../../../context/ReinstatementAgreementContext';
import {
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
  CircularProgress,
} from '@rentacenter/racstrap';
import { fixedGridStyles } from '../../../JSstyles/fixedGridStyles';
import { useHistory } from 'react-router-dom';
import { removeColumnHeader } from '../../Shared/removeColumnHeader';
import { FeatureFlagContext } from '../../../context/FeatureFlagContext';
export interface dynamicProps {
  items: any;
  orderBy: string;
  order: string;
  handleActionType: (value: any, inventoryPriceId: any) => void;
  throwStyle: any;
} //This object holds all the interface of props that are received in this component

export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link';
  handleColumnClick?: () => void;
  subColumns?: columns[];
} //This object will be holding the interface for all Column properties

const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
}; //This object will define the sort order

export interface IDynamicPageState {
  gridValues: [];
  gridColumn: columns[] | [];
} //This object will be holding the Grid Populating values of both head and the body.

const packagePriceColumns: columns[] = [
  {
    columnId: 'inventoryNumber',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Item #',
    IsSorted: true,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'departmentsEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Department',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'subDepartmentsEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Sub Department',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'brandName',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Brand',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'modelNumber',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Model #',
    IsSorted: false,
    IsSortable: false,
  },
  {
    columnId: 'weeklyRate',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Rate',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'weeklyTerm',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Term',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'weeklyRateAddOn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Add-On Rate',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'statusEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Status',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'conditionEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Condition',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'remainingValue',
    sortOrder: sortDirection.DESC,
    columnTitle: 'RV',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'daysIdle',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Days Idle',
    IsSorted: false,
    IsSortable: true,
  },
]; //This is the array that holds all the Property of each and every Column in the Search Result grid

const DynamicGridComponent = (props: any) => {
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  const history = useHistory();
  const classes = props.throwStyle; //This will hold the global styles
  const fixedGridClassName = fixedGridStyles(); //This will hold the component specific styles
  const [gridValues, setGridValues] = useState<any[]>([]);
  const [gridColumn, setGridColumn] = useState<columns[]>([]);
  const { searchCriteria } = useContext(ReinstateAgreementContext);
  useEffect(() => {
    const { order, orderBy, items } = props;
    console.log('In Grid Component', props.items);
    const sortedData =
      order == sortDirection.DESC
        ? items.sort((a: any, b: any) => (a[orderBy] < b[orderBy] ? -1 : 1))
        : items.sort((a: any, b: any) => (b[orderBy] < a[orderBy] ? -1 : 1));

    setGridValues(sortedData);
    setGridColumn(removeColumnHeader(packagePriceColumns,'weeklyRateAddOn',featureFlagDetails.AddOnPricing == '1' ? 0 : 1));
  }, [props]); //This method is triggered when Search Result Component is called. This will be having all those data that is need to be get populated in the Search Result Grid

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  }; //This method is used to Add Ellipse to those strings which are quite long in the Search Result Grid

  const sortArray = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) =>
            b[columnId] < a[columnId] ? -1 : 1
          )
        : dataValues.sort((a: any, b: any) =>
            a[columnId] < b[columnId] ? -1 : 1
          );
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
  };
  const sortArrayForDollar = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) => b[columnId] - a[columnId])
        : dataValues.sort((a: any, b: any) => a[columnId] - b[columnId]);
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
  }; //This method will be performing the sort operation based on the users input.

  const renderTableHead = (dataColumns: any, dataValues: any) => {
    return (
      <>
        {gridColumn.map((value, index) => {
          return (
            <RACTableCell key={index} className={classes.bold}>
              {value.columnTitle !== 'Model #' &&
              value.columnTitle !== 'Status' ? (
                <a
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  id={value.columnId}
                  onClick={() =>
                    value.columnTitle !== 'Rate' && value.columnTitle !== 'RV' && value.columnId !='weeklyRateAddOn'
                      ? sortArray(
                          value.columnId,
                          index,
                          dataColumns,
                          dataValues
                        )
                      : sortArrayForDollar(
                          value.columnId,
                          index,
                          dataColumns,
                          dataValues
                        )
                  }
                >
                  {value.columnTitle + '  '}
                  {value.IsSorted === true ? (
                    value.sortOrder == sortDirection.DESC ? (
                      <SortupIcon className={classes.ms0} />
                    ) : (
                      <SortdownIcon className={classes.ms0} />
                    )
                  ) : null}
                </a>
              ) : (
                <a
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                  }}
                  id={value.columnId}
                >
                  {value.columnTitle + '  '}
                </a>
              )}
            </RACTableCell>
          );
        })}
        <RACTableCell></RACTableCell>
      </>
    );
  }; //This method is used to populate the Table Head in the Search Result grid

  const renderTableContent = (gridBodyData: any, gridColumns: any) => {
    if (!!gridBodyData && gridBodyData.length > 0) {
      return gridBodyData.map((value: any, index1: any) => {
        const tagRate: any = value.weeklyRate.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        );
        const rv: any = value.remainingValue.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        );

        let condition: any = '';
        if (value.conditionEn == 'Based on DOR-system push') {
          condition = 'Auto';
        } else if (value.conditionEn == 'Term pushed up/down by Coworker') {
          condition = 'Exception';
        } else if (value.conditionEn == 'New - Full Term') {
          condition = 'New';
        } else {
          console.log('condition mistake', value.conditionEn);
          condition = value.conditionEn;
        }

        return (
          <React.Fragment key={index1}>
            <RACTableRow>
              <RACTableCell>
                <a
                  className={classes.racpadLink}
                  onClick={() =>
                    history.push({
                      pathname: `/inventory/${value.inventoryNumber}/inventoryinformation`,
                    })
                  }
                >
                  {value.inventoryNumber}
                </a>
              </RACTableCell>
              <RACTableCell
                title={
                  value.departmentsEn.length > 10 ? value.departmentsEn : null
                }
              >
                {truncString(value.departmentsEn, 20, '...')}
              </RACTableCell>
              <RACTableCell
                title={
                  value.subDepartmentsEn.length > 10
                    ? value.subDepartmentsEn
                    : null
                }
              >
                {truncString(value.subDepartmentsEn, 20, '...')}
              </RACTableCell>
              <RACTableCell
                title={value.brandName.length > 7 ? value.brandName : null}
              >
                {truncString(value.brandName, 7, '...')}
              </RACTableCell>
              {value.modelNumber ? (
                <RACTableCell
                  title={
                    value.modelNumber.length > 7 ? value.modelNumber : null
                  }
                >
                  {truncString(value.modelNumber, 7, '...')}
                </RACTableCell>
              ) : (
                <RACTableCell className={classes.textCenter}> - </RACTableCell>
              )}
              <RACTableCell style={{ whiteSpace: 'nowrap' }}>
                $ {tagRate}
              </RACTableCell>
              <RACTableCell>{value.weeklyTerm}</RACTableCell>
              { featureFlagDetails.AddOnPricing == '1' ?<RACTableCell>{value.weeklyRateAddOn ? `$ ${value.weeklyRateAddOn}` : '-'}</RACTableCell> : null}
              <RACTableCell style={{ whiteSpace: 'nowrap' }}>
                {truncString(value.statusEn, 15, '...')}
              </RACTableCell>
              <RACTableCell>{truncString(condition, 15, '...')}</RACTableCell>
              <RACTableCell style={{ whiteSpace: 'nowrap' }}>
                $ {rv}
              </RACTableCell>
              <RACTableCell className={classes.textCenter}>
                {value.daysIdle}
              </RACTableCell>
              <RACTableCell>
                <ItemAddition
                  className={classes.cursorPointer}
                  id={value.inventoryId}
                  onClick={() =>
                    props.handleActionType(
                      value.inventoryId,
                      value.inventoryPriceId
                    )
                  }
                  data-testid={index1 + 'plusId'}
                />
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        );
      });
    }
  }; //This method is used to populate the Table Body in the Search Result grid

  const fetchNextData = () => {
    props.triggerSearch(searchCriteria, 'searchButtonNotClick');
  };

  return (
    <Grid className={fixedGridClassName.fixTableHead}>
      {gridValues?.length > 7 ? (
        <InfiniteScroll
          next={fetchNextData}
          dataLength={gridValues?.length}
          hasMore={props.reveiveHasMore}
          height={500}
          loader={
            props.reveivescrollLoader ? (
              <Grid className={classes.GridLoader}>
                <CircularProgress></CircularProgress>
              </Grid>
            ) : null
          }
        >
          <RACTable
            renderTableHead={() => renderTableHead(gridColumn, gridValues)}
            renderTableContent={() =>
              renderTableContent(gridValues, gridColumn)
            }
          />
        </InfiniteScroll>
      ) : (
        <RACTable
          renderTableHead={() => renderTableHead(gridColumn, gridValues)}
          renderTableContent={() => renderTableContent(gridValues, gridColumn)}
        />
      )}
    </Grid>
  );
};

export default DynamicGridComponent;
