/* eslint-disable */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import BlueCard from './blueCard';
import WhiteCard from './whiteCard';
import { Usercontext } from './searchResult';
import { addedItemStyles } from '../../../JSstyles/addedItemStyles';
import {
  Grid,
  Card,
  CardContent,
  Typography,
} from '@rentacenter/racstrap';

const AddedItem = (props: any) => {
  const classes = props.throwStyle;
  const addedItemClassName = addedItemStyles();
  const shareData: any = useContext(Usercontext);
  const [agrTotal, setAgrTotal] = useState('0.00');
  const [cashPrice, setCashPrice] = useState('0.00');
  useEffect(() => {
    console.log('before if condition', props.priceexceptionMessage);
    if (
      props.priceexceptionMessage !== null &&
      props.priceexceptionMessage !== undefined
    ) {
      console.log(
        'inside props data of price exception',
        props.priceexceptionMessage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.priceexceptionMessage]);
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (shareData !== undefined) {
      console.log('Added Item Shared Data', shareData);
      setAgrTotal(shareData.agreementPricing.agreementTotal);
      setCashPrice(shareData.agreementPricing.cashPrice);

    } else {
      setAgrTotal('');
      setCashPrice('');

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareData]); // this method is used to validate the proof //PS-8

  const temp = (itemID: any) => {
    props.triggeroneDelete(itemID);
  };



  return (
    <Grid item md={3}>
      <Typography className={`${classes.title} ${classes.mt2}`} variant="h6">
        Added Items
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.p1}>
          <Card
            className={`${classes.racBlue} ${classes.card} ${classes.p3} ${classes.pb0}`}
          >
            <BlueCard
              //throwPackage={throwPackageValue}
              triggerTemp={temp}
              throwStyle={props.throwStyle}
            />
          </Card>

          <div className={`${classes.mt3}`}>
            <div className={classes.row}>
              <Typography className={classes.title} variant="h6">
                Agreement Total
              </Typography>
              <WhiteCard throwStyle={props.throwStyle} />
              <Grid md={12} className={`${classes.mb3} ${classes.flexCenter}`}>
                <Typography variant="caption" className={classes.formLabel}>
                  Agreement Rate
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.formLabel} ${classes.floatRight} ${classes.semiBold}`}
                >
                  $ 0.00
                </Typography>
              </Grid>
              <Grid md={12} className={`${classes.mb3} ${classes.flexCenter}`}>
                <Typography variant="caption" className={classes.formLabel}>
                  Agreement Term
                </Typography>

                <Typography
                  variant="caption"
                  className={`${classes.formLabel} ${classes.floatRight} ${classes.semiBold}`}
                >0
                </Typography>
              </Grid>
            </div>
          </div>
          <Card
            className={`${classes.card} ${classes.racTotalBlue} ${classes.p3}`}
          >
            <Grid
              md={12}
              className={`${addedItemClassName.agrAddedTotal} ${classes.mb2}`}
            >
              <Typography
                variant="caption"
                className={`${classes.textBlue} ${classes.font14} ${classes.semiBold}`}
              >
                Same As Cash Total
              </Typography>

              <Typography
                variant="caption"
                className={`${classes.textBlue} ${classes.font18} ${classes.bold}`}
              >
                $ {cashPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
            </Grid>
            <Grid
              md={12}
              className={`${addedItemClassName.agrAddedTotal} ${classes.mb2}`}
            >
              <Typography
                variant="caption"
                className={`${classes.textBlue} ${classes.font14} ${classes.semiBold}`}
              >
                Agreement Total
              </Typography>
              <Typography
                variant="caption"
                className={`${classes.textBlue} ${classes.font18} ${classes.bold}`}
              >
                {/* $ {agrTotal.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} */}
                $ 0.00
              </Typography>
            </Grid>
          </Card>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AddedItem;
