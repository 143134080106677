/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState, useContext } from 'react';
import { ReactComponent as SortdownIcon } from '../../../assets/images/sort-down-icon.svg';
import { ReactComponent as SortupIcon } from '../../../assets/images/sort-up-icon.svg';
import { CustomerOrderAgreementContext } from '../../../context/CustomerOrderAgreementContext';
import {
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACRadio,
} from '@rentacenter/racstrap';
import { useParams } from 'react-router';
import { fixedGridStyles } from '../../../JSstyles/fixedGridStyles';

export interface dynamicProps {
  items: any;
  orderBy: string;
  order: string;
  handleActionType: () => void;
  throwStyle: any;
}
interface Params {
  agreementId: string;
}
export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link';
  handleColumnClick?: () => void;
  subColumns?: columns[];
}
const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export interface IDynamicPageState {
  gridValues: [];
  gridColumn: columns[] | [];
}

const packagePriceColumns: columns[] = [
  {
    columnId: 'brand',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Vendor',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'brand',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Brand',
    IsSorted: true,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'department',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Department',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'subdepartment',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Sub Department',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'modelNumber',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Model#',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'monthlyRate',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Monthly Rate',
    IsSorted: false,
    IsSortable: false,
  },
  {
    columnId: 'monthlyTerm',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Monthly Term',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'totalCost',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Total Cost',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'cashPrice',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Cash Price',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'availability',
    sortOrder: sortDirection.DESC,
    columnTitle: '#Available',
    IsSorted: false,
    IsSortable: true,
  },
]; //Build

const DynamicGridComponent = (props: any) => {
  const classes = props.throwStyle;
  const { agreementId } = useParams<Params>();
  const fixedGridClassName = fixedGridStyles();
  const [gridValues, setGridValues] = useState<any[]>([]);
  const [gridColumn, setGridColumn] = useState<columns[]>([]);
  const [selectedInvNumber, setInventoryNumber]: any = useState('');
  const { setselectedData, crossedItemSearch } = useContext(
    CustomerOrderAgreementContext
  );
  useEffect(() => {
    const { items } = props;
    /* eslint-disable no-console */
    console.log('In Grid Component', props.items);
    console.log('vcdxxcx ', props.items[0].modelNumber);

    // const sortedData =
    //   order == sortDirection.DESC
    //     ? items.sort((a: any, b: any) => (a[orderBy] < b[orderBy] ? -1 : 1))
    //     : items.sort((a: any, b: any) => (b[orderBy] < a[orderBy] ? -1 : 1));

    setGridValues(items);
    setGridColumn(packagePriceColumns);
    if (crossedItemSearch === true) {
      console.log('451dcx51dwsc ', props.items[0].modelNumber);
      setInventoryNumber(props.items[0].modelNumber);
    }
  }, [props]); // Build

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  const sortArray = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) =>
            b[columnId] < a[columnId] ? -1 : 1
          )
        : dataValues.sort((a: any, b: any) =>
            a[columnId] < b[columnId] ? -1 : 1
          );
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };
  const sortArrayForDollar = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) => b[columnId] - a[columnId])
        : dataValues.sort((a: any, b: any) => a[columnId] - b[columnId]);
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };

  const renderTableHead = (dataColumns: any, dataValues: any) => {
    return (
      <>
        <RACTableCell></RACTableCell>
        {gridColumn.map((value, index) => {
          return (
            <RACTableCell key={index} className={classes.bold}>
              {value.columnTitle !== 'Model #' &&
              value.columnTitle !== 'Status' ? (
                <a
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  id={value.columnId}
                  onClick={() =>
                    value.columnTitle !== 'Rate' && value.columnTitle !== 'RV'
                      ? sortArray(
                          value.columnId,
                          index,
                          dataColumns,
                          dataValues
                        )
                      : sortArrayForDollar(
                          value.columnId,
                          index,
                          dataColumns,
                          dataValues
                        )
                  }
                >
                  {value.columnTitle + '  '}
                  {value.IsSorted === true ? (
                    value.sortOrder == sortDirection.DESC ? (
                      <SortupIcon className={classes.ms0} />
                    ) : (
                      <SortdownIcon className={classes.ms0} />
                    )
                  ) : null}
                </a>
              ) : value.columnTitle == 'Model #' ? (
                <a
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                    textAlign: 'center',
                  }}
                  id={value.columnId}
                >
                  {value.columnTitle + '  '}
                </a>
              ) : (
                <a
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                  }}
                  id={value.columnId}
                >
                  {value.columnTitle + '  '}
                </a>
              )}
            </RACTableCell>
          );
        })}
        {/* <RACTableCell></RACTableCell> */}
      </>
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderTableContent = (gridBodyData: any) => {
    if (!!gridBodyData && gridBodyData.length > 0) {
      return gridBodyData.map((value: any, index1: any) => {
        const monthlyRate: any = value.monthlyRate.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        );
        const totalCost: any = value.totalCost.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        );
        const cashPrice: any = value.cashPrice.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        );
        return (
          <React.Fragment key={index1}>
            <RACTableRow>
              <RACTableCell>
                <RACRadio
                  size="small"
                  disabled={agreementId ? true : false}
                  value={value.modelNumber}
                  onClick={() => {
                    setInventoryNumber(value.modelNumber);
                    props.handleActionType();
                    setselectedData(value);
                    //store the value in context
                  }}
                  checked={
                    selectedInvNumber == value.modelNumber ? true : false
                  }
                  name="invNumber"
                />
              </RACTableCell>
              <RACTableCell>
                {/* <a className={classes.racpadLink}> */}
                {'-'}
                {/* </a> */}
              </RACTableCell>
              <RACTableCell>
                {/* <a className={classes.racpadLink}> */}
                {value.brand}
                {/* </a> */}
              </RACTableCell>
              <RACTableCell
                title={value.department.length > 10 ? value.department : null}
              >
                {truncString(value.department, 20, '...')}
              </RACTableCell>
              <RACTableCell
                title={
                  value.subdepartment.length > 10 ? value.subdepartment : null
                }
              >
                {truncString(value.subdepartment, 20, '...')}
              </RACTableCell>
              {value.modelNumber ? (
                <RACTableCell
                  title={
                    value?.modelNumber.length > 8 ? value?.modelNumber : null
                  }
                >
                  {truncString(value?.modelNumber, 8, '...')}
                </RACTableCell>
              ) : (
                <RACTableCell>-</RACTableCell>
              )}

              <RACTableCell>$ {monthlyRate}</RACTableCell>

              <RACTableCell>{value.monthlyTerm}</RACTableCell>
              <RACTableCell style={{ whiteSpace: 'nowrap' }}>
                $ {totalCost}
              </RACTableCell>
              <RACTableCell>$ {cashPrice}</RACTableCell>

              <RACTableCell className={classes.textCenter}>
                {value.availability !== null
                  ? value.availability.maxQuantity
                  : '-'}
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        );
      });
    }
  };

  return (
    <Grid className={fixedGridClassName.fixTableHead}>
      <RACTable
        renderTableHead={() => renderTableHead(gridColumn, gridValues)}
        renderTableContent={() => renderTableContent(gridValues)}
      />
    </Grid>
  );
};

export default DynamicGridComponent;
