/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable prettier/prettier */
//PS_RR- 01
import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  RACButton,
  RACModalCard,
  RACTextbox,
  CircularProgress,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { ReactComponent as Alerticon } from '../../../assets/images/no-records-found.svg';
import { UpdateAgreement, GetEmployeeID, GetRole } from '../../../api/user';
import { AgreementContext } from '../../../context/AgreementContext';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import SecondFactor from '../../Rental/TwoFactorPopup/TwoFactorPopup';

interface Params {
  customerId: string;
  agreementId: string;
}
export default function RateReduction(props: any) {
  //PS_RR- 02
  const classes = agreementGlobalStyles();
  //PS_RR- 03
  const { agreementId } = useParams<Params>();
  const currentURL = window.location.href;
  //PS_RR- 04
  const { agreementInfoData } = useContext(AgreementContext);
  //PS_RR- 05
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  //PS_RR- 06
  const [showRateReduction, setShowRateReduction] = useState({
    rateReductionBtn: false,
    cancelRateReductionBtn: false,
  });
  //PS_RR- 07
  const [rateReductionValue, setRateReductionValue] = useState({
    minLimit: '',
    maxLimit: '',
    textBoxValue: '',
  });
  //PS_RR- 08
  const [rateReductionPopups, setRateReductionPopups] = useState({
    rateReductionPopup: false,
    cancelRateReductionPopup: false,
  });
  //PS_RR- 09
  const [showTxtBoxErrorLbl, setShowTxtBoxErrorLbl] = useState(false);
  const [txtBoxErrorLblMsg, setTxtBoxErrorLblMsg] = useState('');
  //PS_RR- 10
  const [hiddenLoader, setHiddenLoader] = useState(false);
  //PS_RR- 11
  const [somethingWentWrongOpen, setSomethingWentWrongOpen] = useState(false);
  //PS_RR- 12
  const [errorMessagePopupOpen, setErrorMessagePopupOpen] = useState(false);
  //PS_RR- 13
  const [manageAgrErrMessage, setManageAgrErrMessage] = useState('');
  //PS_RR- 13
  const [secondFactorUsingField, setSecondFactorUsingField] = useState('');
  //PS_RR- 14
  const [secondFactorEnabled, setsecondFactorEnabled] = useState(false);
  //PS_RR- 15
  const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState(false);
  //PS_RR- 16
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState(false);
  //PS_RR- 17
  const moduleName = 'rate reduction';
  //PS_RR- 18
  const [currentRole, setcurrentRole] = useState('');
  //PS_RR- 20
  useEffect(() => {
    console.log('agreementInfoData in rate reduction', agreementInfoData);
    //PS_RR- 21
    if (agreementInfoData?.agreementBusinessRules) {
      setShowRateReduction({
        ...showRateReduction,
        rateReductionBtn:
          agreementInfoData?.agreementBusinessRules
            ?.isRateReductionApplicable == '1' &&
          agreementInfoData?.agreementWorkflow?.isRateReductionApplied == '0',
        cancelRateReductionBtn:
          agreementInfoData?.agreementWorkflow?.isRateReductionApplied == '1',
      });
      //PS_RR- 22
      if (
        agreementInfoData?.agreementBusinessRules?.minRateReductionPercent &&
        agreementInfoData?.agreementBusinessRules?.maxRateReductionPercent
      ) {
        const minPercentage =
          Number(
            agreementInfoData?.agreementBusinessRules?.minRateReductionPercent
          ) * 100;
        const maxPercenTage =
          Number(
            agreementInfoData?.agreementBusinessRules?.maxRateReductionPercent
          ) * 100;
        0;
        setRateReductionValue({
          ...rateReductionValue,
          minLimit: String(minPercentage),
          maxLimit: String(maxPercenTage),
        });
      }
    }
    //PS_RR- 24
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRoleRes = GetMenuDetailsResponse.coworkerProfile.role;
          console.log('currentRole', currentRoleRes);
          setcurrentRole(currentRoleRes);
        }
      }
    };
    //PS_RR- 23
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      console.log('containerDataRole', containerData.GetRole());
      setcurrentRole(containerData.GetRole());
    }
    //PS_RR- 24
    else {
      GettingRole();
    }
  }, []);
  useEffect(() => {
    //PS_RR- 25
    if (TwoFactorCompleted && !TwoFactorCancelClick) {
      TwoFactorCompletedFn();
      setTwoFactorCancelClick(false);
      setTwoFactorCompleted(false);
    }
    //PS_RR- 26
    if (TwoFactorCancelClick && !TwoFactorCompleted) {
      setsecondFactorEnabled(false);
      setTwoFactorCancelClick(false);
      setTwoFactorCompleted(false);
    }
  }, [TwoFactorCompleted, TwoFactorCancelClick]);
  //PS_RR- 27
  const RateReductionBtnClick = () => {
    setSecondFactorUsingField('RateReduction');
    setsecondFactorEnabled(true);
  };
  //PS_RR- 28
  const CancelRateReductionBtnClick = () => {
    setSecondFactorUsingField('CancelRateReduction');
    setsecondFactorEnabled(true);
  };
  //PS_RR- 29
  const RateReductionOnchange = (value: string) => {
    setShowTxtBoxErrorLbl(false);
    if (Number(value) <= 100) {
      setRateReductionValue({ ...rateReductionValue, textBoxValue: value });
    }
  };
  const RateReductionOkClick = async () => {
    console.log('rateReductionValue', rateReductionValue);
    //PS_RR- 30
    if (
      Number(rateReductionValue.textBoxValue) >=
        Number(rateReductionValue.minLimit) &&
      Number(rateReductionValue.textBoxValue) <=
        Number(rateReductionValue.maxLimit)
    ) {
      const payloadObj = {
        action: 'ARD',
        agreementId: Number(agreementId),
        rateReductionPercent: Number(rateReductionValue.textBoxValue),
      };
      console.log('activate rate reduction payloadObj', payloadObj);
      setRateReductionPopups({
        ...rateReductionPopups,
        rateReductionPopup: false,
      });
      setHiddenLoader(true);
      const rateReductionResponse = await UpdateAgreement(payloadObj);
      console.log('rateReductionResponse in activate', rateReductionResponse);
      setHiddenLoader(false);
      if (rateReductionResponse?.status == 200) {
        window.location.reload();
        setShowRateReduction({
          ...showRateReduction,
          rateReductionBtn: false,
          cancelRateReductionBtn: true,
        });
      }
      //PS_RR- 31
      else if (rateReductionResponse?.status == 400) {
        setManageAgrErrMessage(rateReductionResponse?.data?.errors[0]?.error);
        setErrorMessagePopupOpen(true);
      }
      //PS_RR- 32
      else {
        setSomethingWentWrongOpen(true);
      }
    }
    //PS_RR- 33
    else {
      if (
        Number(rateReductionValue.textBoxValue) <
        Number(rateReductionValue.minLimit)
      ) {
        setTxtBoxErrorLblMsg(
          `Please enter the minimum percentage of ${rateReductionValue.minLimit}`
        );
      } else if (
        Number(rateReductionValue.textBoxValue) >
        Number(rateReductionValue.maxLimit)
      ) {
        setTxtBoxErrorLblMsg(
          `Please enter the maximum percentage limit of ${rateReductionValue.maxLimit}`
        );
      }
      setShowTxtBoxErrorLbl(true);
    }
  };
  const CancelRateReduction = async () => {
    //PS_RR- 34
    const payloadObj = {
      action: 'CRD',
      agreementId: Number(agreementId),
    };
    console.log('Cancel rate reduction payloadObj', payloadObj);
    setRateReductionPopups({
      ...rateReductionPopups,
      cancelRateReductionPopup: false,
    });
    setHiddenLoader(true);
    const rateReductionResponse = await UpdateAgreement(payloadObj);
    console.log('rateReductionResponse in cancel', rateReductionResponse);
    setHiddenLoader(false);
    if (rateReductionResponse?.status == 200) {
      window.location.reload();
      setShowRateReduction({
        ...showRateReduction,
        rateReductionBtn: true,
        cancelRateReductionBtn: false,
      });
    }
    //PS_RR- 35
    else if (rateReductionResponse?.status == 400) {
      setManageAgrErrMessage(rateReductionResponse?.data?.errors[0]?.error);
      setErrorMessagePopupOpen(true);
    }
    //PS_RR- 36
    else {
      setSomethingWentWrongOpen(true);
    }
  };
  //PS_RR- 37
  const TwoFactorCompletedFn = () => {
    setsecondFactorEnabled(false);
    if (secondFactorUsingField == 'RateReduction') {
      setRateReductionValue({ ...rateReductionValue, textBoxValue: '' });
      setShowTxtBoxErrorLbl(false);
      setRateReductionPopups({
        ...rateReductionPopups,
        rateReductionPopup: true,
      });
    } else if (secondFactorUsingField == 'CancelRateReduction') {
      setRateReductionPopups({
        ...rateReductionPopups,
        cancelRateReductionPopup: true,
      });
    }
  };
  const RateReductionPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div style={{ marginLeft: '2%' }}>
          <Grid className={classes.textCenter}>
            <div className={classes.rateReductionTxtBox}>
              <RACTextbox
                type="number"
                inputlabel="Enter the percentage of lost income due to unemployment. "
                className={'text'}
                infoIcon={true}
                infoTitle={`The minimum percentage limit is ${rateReductionValue.minLimit}% and the maximum limit is ${rateReductionValue.maxLimit}%`}
                infoIconClassname={{ color: '#000000' }}
                maxlength={3}
                value={rateReductionValue.textBoxValue}
                OnChange={(e: any) => RateReductionOnchange(e.target.value)}
                inputTextBoxClassname={classes.width10}
              />
            </div>
            <label
              style={{
                color: 'red',
                float: 'left',
                fontSize: 'small',
                visibility: showTxtBoxErrorLbl ? 'visible' : 'hidden',
                marginTop: '1%',
              }}
            >
              {txtBoxErrorLblMsg}
            </label>
          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.mt3} ${classes.pb4} ${classes.floatRight}`}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.mx1}
              onClick={() => {
                setRateReductionPopups({
                  ...rateReductionPopups,
                  rateReductionPopup: false,
                });
              }}
            >
              Cancel
            </RACButton>
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              onClick={() => {
                RateReductionOkClick();
              }}
              disabled={
                Number(rateReductionValue.textBoxValue) > 0 ? false : true
              }
            >
              Ok
            </RACButton>
          </Grid>
        </div>
      </div>
    );
  };
  const CancelRateReductionPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={`${classes.textCenter} `}>
          <Typography className={classes.fontBold}>
            This will remove the rate reduction applied to this agreement.
            <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              Are you sure you want to continue?
            </Typography>
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.mx1}
              onClick={() => {
                setRateReductionPopups({
                  ...rateReductionPopups,
                  cancelRateReductionPopup: false,
                });
              }}
            >
              No
            </RACButton>
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              onClick={() => {
                CancelRateReduction();
              }}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </div>
    );
  };
  const somethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSomethingWentWrongOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const errorMessagePopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.formLabel}>
            {manageAgrErrMessage}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setErrorMessagePopupOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  return (
    <>
      {hiddenLoader === true ? (
        <Grid
          style={{
            position: 'fixed',
            background: '#f7f5f5',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 2000,
            textAlign: 'center',
            margin: '0px 0px',
          }}
        >
          <Grid
            style={{
              display: 'block',
              position: 'fixed',
              zIndex: 9999999,
              top: '40%',
              right: '50%',
            }}
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      {secondFactorEnabled === true ? (
        <SecondFactor
          setTwoFactorCancelClick={setTwoFactorCancelClick}
          setTwoFactorCompleted={setTwoFactorCompleted}
          moduleName={moduleName}
          currentRole={currentRole}
        />
      ) : null}
      <RACModalCard
        isOpen={rateReductionPopups.rateReductionPopup}
        closeIcon={true}
        maxWidth="sm"
        borderRadius={'20px !important'}
        className={classes.rateReductionPopup}
        onClose={() => {
          setRateReductionPopups({
            ...rateReductionPopups,
            rateReductionPopup: false,
          });
        }}
      >
        {RateReductionPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={rateReductionPopups.cancelRateReductionPopup}
        closeIcon={true}
        maxWidth="sm"
        borderRadius={'20px !important'}
        onClose={() => {
          setRateReductionPopups({
            ...rateReductionPopups,
            cancelRateReductionPopup: false,
          });
        }}
      >
        {CancelRateReductionPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={somethingWentWrongOpen}
        closeIcon={false}
        maxWidth="xs"
        borderRadius={'20px !important'}
      >
        {somethingWentWrongPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={errorMessagePopupOpen}
        closeIcon={false}
        maxWidth="xs"
        borderRadius={'20px !important'}
      >
        {errorMessagePopup()}
      </RACModalCard>
      <>
        {agreementInfoData?.agreementInformation?.storeNumber ==
          window.sessionStorage.getItem('storeNumber') &&
        currentURL.includes('agreement/info/details/') &&
        agreementInfoData?.agreementInformation?.agreementStatusRefCode ==
          'ACTIVE' ? (
          <>
            {showRateReduction.rateReductionBtn ? (
              <RACButton
                // className={classes.mx1}
                className={`${classes.floatLeft} ${classes.me2}`}
                variant="contained"
                color="primary"
                disabled={
                  agreementInfoData?.agreementInformation?.isExchanged == 1 
                  ? true :
                  agreementInfoData?.agreementWorkflow?.isTransferInitiated == 1
                    ? true
                    : false
                }
                onClick={() => {
                  RateReductionBtnClick();
                }}
              >
                Rate Reduction
              </RACButton>
            ) : null}
            {showRateReduction.cancelRateReductionBtn ? (
              <RACButton
                // className={classes.mx1}
                disabled={
                  agreementInfoData?.agreementWorkflow?.isTransferInitiated == 1
                    ? true
                    : false
                }
                className={`${classes.floatLeft} ${classes.me2}`}
                variant="contained"
                color="primary"
                onClick={() => {
                  CancelRateReductionBtnClick();
                }}
              >
                Cancel Rate Reduction
              </RACButton>
            ) : null}
          </>
        ) : null}
      </>
    </>
  );
}
