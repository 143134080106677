/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
//PS_SO-GC-1
import React from 'react';
import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';
import { Link, useHistory } from 'react-router-dom';

export default function GridComponent(props: any) {
  const history = useHistory();
  //PS_SO-GC-2
  const classes = props.throwStyle;
  //PS_SO-GC-3
  const { itemGridHeadValues, itemGridBodyValues, gridName } = props;
  //PS_SO-GC-4
  const [tableHeadArr, settableHeadArr] = useState([]);
  //PS_SO-GC-5
  const [tableBodyArr, settableBodyArr] = useState([]);
  //PS_SO-GC-6
  useEffect(() => {
    console.log('itemGridHeadValues', itemGridHeadValues);
    console.log('itemGridBodyValues', itemGridBodyValues);

    settableHeadArr(itemGridHeadValues);
    settableBodyArr(itemGridBodyValues);
  }, [itemGridHeadValues, itemGridBodyValues]);

  const hideLengthyText = (Text: any, MaxLength: number, Mask: any) => {
    return Text.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
  };
  //PS_SO-GC-7
  const tableHeadBind = () => (
    <>
      {tableHeadArr.map((value: any, index) => {
        if (tableHeadArr.length > 0) {
          return <RACTableCell key={index}>{value}</RACTableCell>;
        }
      })}
    </>
  );
  //PS_SO-GC-8
  const tableBodyBind = () => (
    <>
      {tableBodyArr.map((col: any, index) => {
        if (tableBodyArr.length > 0) {
          return (
            <RACTableRow key={index} hover backgroundColor="white">
              <RACTableCell
                key={index}
                className={classes.racpadLink}
                onClick={() => {
                  history.push({
                    pathname: `/inventory/${col.itemNo}/inventoryInformation`,
                  });
                }}
              >
                {col.itemNo}
              </RACTableCell>
              <RACTableCell
                className={classes.agrTableBody}
                style={{ width: '400px' }}
                title={col.itemDesc}
              >
                {hideLengthyText(col.itemDesc, 50, '...')}
              </RACTableCell>
              <RACTableCell className={classes.agrTableBody}>
                ${col.tagRate}
              </RACTableCell>
              <RACTableCell className={classes.agrTableBody}>
                {col.tagTerm}
              </RACTableCell>
              <RACTableCell className={classes.agrTableBody}>
                ${col.tagTotal}
              </RACTableCell>
              <RACTableCell className={classes.agrTableBody}>
                ${col.agrRate}
              </RACTableCell>
              <RACTableCell className={classes.agrTableBody}>
                {col.agrTerm}
              </RACTableCell>
              <RACTableCell className={classes.agrTableBody}>
                ${col.agrTotal}
              </RACTableCell>
              <RACTableCell className={classes.agrTableBody}>
                {col.invDepr}%
              </RACTableCell>
            </RACTableRow>
          );
        }
      })}
    </>
  );
  return (
    <Grid>
      <Grid container>
        <Grid container className={classes.mt1}>
          <Typography
            variant="h6"
            className={`${classes.title} ${classes.mb1}`}
          >
            {gridName}
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.mb4}>
          <Card className={classes.card}>
            <CardContent>
              <RACTable
                className={`${classes.racGrid}`}
                renderTableHead={tableHeadBind}
                renderTableContent={tableBodyBind}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
