/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-unused-collection */
import React, { useEffect, useState, useContext } from 'react';
//import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { UpdateVoidStatus, getVoidOptions } from '../../../api/user';
import { GetVoidReasons } from '../../../api/user';
import { ReactComponent as Successimage } from '../../../assets/images/success-icon.svg';
import { ReactComponent as Alertimage } from '../../../assets/images/no-records-found.svg';
import { ReactComponent as RacLogo } from '../../../assets/images/RAC-Logo.svg';
import Erroricon from '../../../assets/images/erroricon.png';
import PinField from 'react-pin-input';
import {
  Grid,
  CircularProgress,
  Typography,
  RACButton,
  RACSelect,
  RACModalCard,
  RACTextbox,
} from '@rentacenter/racstrap';
import {
  getCurrentInfo,
  getPinDetails,
  getprofileaccess,
  getsecondfactor,
  GetRole,
  tireBuyerEmailNotification,
  getAgreementInfo
} from '../../../api/user';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { twoFactorStyles } from '../../../JSstyles/twoFactorStyles';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import { AgreementContext } from '../../../context/AgreementContext';
interface Reasons {
  id: string;
  active: string;
  referenceCode: string;
  displaySeq: string;
  defaultValue: string;
  description: string;
  descSpanish: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
}
export type PropsInterface = {
  setTwoFactorCancelClick?: any; //
  setTwoFactorCompleted?: any;
  setEmployeeDetails?: any;
  moduleName?: string;
  currentRole?: string;
};

export default function VoidAgreement(props: any) {
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  // eslint-disable-next-line no-console
  console.log('voidagredementprops', props);
  const history = useHistory();
  const classes = agreementGlobalStyles();
  const twoFactorClassName = twoFactorStyles();
  //VA_06
  const [CustomerId, SetCustomerId] = useState('');
  const { AgrStoreNum, agreementInfoData } = useContext(AgreementContext);
  const [showpopup, Setshowpopup] = useState(false); //for displaying the void reason popup
  const [successpopup, Setsuccesspopup] = useState(false); //for disaplaying the void success popup
  const [errorpopup, SetErrorPop] = useState(false);
  const [dropdownvalue, Setdropdownvalue] = useState('0');
  const [dropdownreason, Setdropdownreason] = useState<Reasons[]>([]);
  const [hidevoid, sethidevoid] = useState(true);
  //const [unhidevoid, Setunhidevoid] = useState(false) //for making the void button initially disabled
  const [agreementStatusReasonId, setagreementStatusReasonId] = useState('');
  //to show success or failed popup
  //const [IsReponseValid, setIsReponseValid] = useState('');
  const [IsResponseLoader, setIsResponseLoader] = useState<boolean>(false);
  const [disablevoidbutton, setdisablevoidbutton] = useState(false);
  const [AgreementId, SetAgreementId] = useState('');
  const [AgreementNumber, SetAgreementNumber] = useState('');
  const [conditionLoader, SetconditionLoader] = useState(true);
  const { setTwoFactorCancelClick, setTwoFactorCompleted, setEmployeeDetails } =
    props;
  const [openModal, setOpenModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [errorline, setErrorLine] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);
  const [role, setRole] = useState('');
  const [openPinModal, setOpenPinModal] = useState(false);
  // eslint-disable-next-line prefer-const
  let [otp, setotp] = useState('');
  // const [loader, setloader] = useState(false);
  const [masterLoader, setmasterLoader]: any = React.useState(false);

  const [somethingone, setsomethingone] = React.useState(
    'modal fade show expandPopup'
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [somethingtwo, setsomethingtwo] = React.useState('modal fade');

  const [InvalidPin, setInvalidPin] = React.useState<any>();
  //   const [InvalidPin1, setInvalidPin1] = React.useState(false);
  const [Continue, setContinue] = React.useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentUserRole, setCurrentUserRole] = React.useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [agrStatus, setagrStatus] = useState('');
  const [reversepaymentmodal, setreversepaymentmodal] = useState(false);
  const storeNo = window.sessionStorage.getItem('storeNumber');
  // to make the void button enable only after selecting any of the reasons for void

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('void agreemet props', props);

    if (
      props.AgreementId !== undefined &&
      props.AgreementId !== null &&
      props.AgreementId !== ''
    ) {
      SetAgreementId(props.AgreementId);
      SetCustomerId(props.customerId);
    }
    if (props?.COVoidFlow) {
      setagrStatus(props.COVoidFlow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    //VA_07
    if (dropdownvalue != '' && dropdownvalue != '0') {
      sethidevoid(false);
    } else {
      sethidevoid(true);
    }
  }, [dropdownvalue]);

  /* eslint-disable sonarjs/cognitive-complexity */
  const ReferenceDetails = () => {
    if (
      dropdownreason !== undefined &&
      dropdownreason !== null &&
      dropdownreason.length > 0
    ) {
      return dropdownreason.map((value: any, index: any) => {
        return { label: value.description, value: value.referenceCode };
      });
    } else {
      return [{ label: 'Select', value: 0 }];
    }
  };

  //to bind the following fields after clicking on the voidalert
  //VA_08
  const dropdownbing = async () => {
    Setdropdownvalue('0');
    Setshowpopup(true);
    const displayhide = document.getElementById('hidevoid');
    displayhide?.classList.add('hidevoidbutton');
    // const voidreasons = {
    //   agreementId: parseInt(AgreementId),
    //   isDropDown: true,
    // };
    const voidreasonresponse = await getVoidOptions(CustomerId, storeNo);
    if (
      voidreasonresponse !== undefined &&
      voidreasonresponse?.data?.voidReasons !== undefined
    ) {
      const buildVoid = voidreasonresponse.data.voidReasons;
      const setterVoid = [
        { referenceCode: '0', description: 'Select' },
        ...buildVoid,
      ];
      Setdropdownreason(setterVoid);
      //Setdropdownreason(voidreasonresponse.data.voidReasons);
      // eslint-disable-next-line no-console
      console.log('void reasons', AgrStoreNum, voidreasonresponse.data.voidReasons);
      SetconditionLoader(false);
    }

    // eslint-disable-next-line no-console
    console.log('void response', voidreasonresponse);
  };
  //onchange Function for binding
  const Handledropdown = (e: any) => {
    const value = e.target.value;
    Setdropdownvalue(value);

    const id = e.target.value;
    setagreementStatusReasonId(id);
  };
  const authenticationOpen = async (payload: any) => {
    const result = await getprofileaccess(payload);
    setIsResponseLoader(false);
    // eslint-disable-next-line no-console
    console.log('getprofileaccess result', result);
    setRole(result.data.role);
    popupSelect(result);
  };
  const currentusercall = () => {
    const payload = {
      module: 'Return Void Agreement',
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      currentRole:
        window.localStorage.getItem('currentRole') ||
        props.currentRole ||
        currentUserRole ||
        containerData.GetRole(),
    };
    console.log('getprofileaccess payload', payload);
    authenticationOpen(payload);
  };

  const GettingRole = async () => {
    const userResponse = await getCurrentInfo();
    // eslint-disable-next-line no-console
    console.log('getCurrentUser Response', userResponse);
    const empId = userResponse.data.employeeId;
    const mailId = userResponse.data.email.toLowerCase();
    const id = userResponse.data.employeeId ? empId : mailId;
    setEmployeeDetails !== undefined
      ? setEmployeeDetails(userResponse.data)
      : null;
    // const coworkerPayload = {
    //   coworkerId: id
    // }
    let storesPayload: any;
    // eslint-disable-next-line prefer-const
    storesPayload = { coworkerId: id };
    // eslint-disable-next-line no-console
    console.log('coworkerId', storesPayload);
    const userRole = await GetRole(storesPayload);
    const coworkerRole = userRole.data.coworkerProfile.role;
    // eslint-disable-next-line no-console
    console.log('Logged In user Role : ', coworkerRole);

    setCurrentUserRole(coworkerRole);
  };
  const Void_Onclick = async () => {
    setIsResponseLoader(true);
    if (containerData !== undefined) {
      console.log('containerData', containerData);

      console.log('containerDataRole', containerData.GetRole());

      setCurrentUserRole(containerData.GetRole());
    } else {
      GettingRole();
    }
    console.log('view role', currentUserRole);
    currentusercall();
    //eslint-disable-next-line no-console
  };
  const Void_OnclickPo = async () => {
    if (!['PRELIMPAID', 'PRELIMINV'].includes(agrStatus)) {
      setIsResponseLoader(true);
      if (containerData !== undefined) {
        console.log('containerData', containerData);

        console.log('containerDataRole', containerData.GetRole());

        setCurrentUserRole(containerData.GetRole());
      } else {
        GettingRole();
      }
      console.log('view role', currentUserRole);
      currentusercall();
    } else {
      history.push(`/payment1/reversepayment/${CustomerId}`);

      // communicate to Routes that URL has changed//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
  };

  //to update the agreement status to inactive  after clicking on the void button
  //VA_16
  const getTbsendEmailPayloadFn = async (agreementInfo: any) => {
    return {
      installerDetails: {
        installerAddress: {
          zipCode: agreementInfo?.installerInformation?.addressInfo?.postalCode || "",
          city: agreementInfo?.installerInformation?.addressInfo?.city || "",
          phone: agreementInfo?.installerInformation?.phoneNumber || "",
          state: agreementInfo?.installerInformation?.addressInfo?.stateAbbreviation || "",
          line2: agreementInfo?.installerInformation?.addressInfo?.addressLine2 || "",
          line1: agreementInfo?.installerInformation?.addressInfo?.addressLine1 || "",
          email: agreementInfo?.installerInformation?.emailAddress || "",
        },
        installerName: agreementInfo?.installerInformation?.installerName || "",
        appointmentSlot: agreementInfo?.installerInformation?.installationTime || "",
        appointmentDate: agreementInfo?.installerInformation?.installationDate || "",
      },
      cancellationReason: "ordercancelled",
      supplierNumber: agreementInfo.agreementInventories[0].supplierNumber || "",
      ponumber: agreementInfo?.installerInformation?.externalPOnumber || "",
      customerName: agreementInfo?.agreementCustomer[0]?.firstName || "",
      products: [{
        brandName: agreementInfo.agreementInventories[0].brand || "",
        qty: 0,
        productNumber: agreementInfo.agreementInventories[0].modelNumber || "MODELNUMBER"
      }]
    }
  }
  const void_Status = async () => {
    Setshowpopup(false);

    setIsResponseLoader(true);

    //const agreementIdValue: number = parseInt(AgreementId);
    // const agreementStatusReasonIdValue: number = parseInt(
    //   agreementStatusReasonId
    // );

    // const voidobj = {
    //   agreementId: agreementIdValue,
    //   agreementStatusReasonId: agreementStatusReasonIdValue,
    //   isDropDown: false,
    // };
    const voidobj = {
      agreementId: AgreementId,
      storeNumber: storeNo,
      type: 'return',
      statusReason: agreementStatusReasonId,
    };

    const voidupdateresponse = await UpdateVoidStatus(voidobj);
    // eslint-disable-next-line no-console
    console.log('view agreementid', voidupdateresponse);

    /* To get the installer details if it's TB */
    const getAgreementInfoResponse = await getAgreementInfo(AgreementId);
    const getAgreementInfoData = getAgreementInfoResponse?.data;
    
    if(getAgreementInfoData.installerInformation != null){
      const getTbsendEmailPayload = await getTbsendEmailPayloadFn(getAgreementInfoData);
      const sendEmailNotifyTBPayload = { ...voidobj, ...getTbsendEmailPayload, type: 'tb' }
      console.log('sendEmailNotifyTBPayload', sendEmailNotifyTBPayload);

      const sendEmailNotifyTB = tireBuyerEmailNotification(sendEmailNotifyTBPayload);
      console.log('sendEmailNotifyTB', sendEmailNotifyTB);
    }

    setIsResponseLoader(false);
    if (voidupdateresponse.status == 200) {
      SetAgreementNumber(voidupdateresponse.data.agreementNumber);
      // eslint-disable-next-line no-console
      console.log('Inside true');
      SetErrorPop(false);
      Setsuccesspopup(true);
      setdisablevoidbutton(true);
    } else if (
      voidupdateresponse.status == 400 ||
      voidupdateresponse.status == 500
    ) {
      // eslint-disable-next-line no-console
      console.log('Inside false');
      SetErrorPop(true);
      Setsuccesspopup(false);
    }
  };
  const removevoidbutton = () => {
    Setshowpopup(false);
    const displayhide = document.getElementById('hidevoid');
    displayhide?.classList.remove('hidevoidbutton');
  };

  //VA_11
  //For making the void button to be hidden after clicking on the ok and cancel button
  const Removevoid = () => {
    Setshowpopup(false);
    Setsuccesspopup(false);
    console.log('agrStatus1', agrStatus);
    if (agrStatus) {
      console.log('agrStatus2s', agrStatus);
      history.push({ pathname: '/dashboard' });
    }
    props.func(true);

    // const displayhide = document.getElementById('hidevoid');
    // displayhide?.classList.add('hidevoidbutton');
  };

  const secondfactorEnter = (event: any, username: any, Password: any) => {
    /* eslint-enable no-console */
    if (event.charCode === 13 && userID !== '' && password !== '') {
      event.preventDefault();
      loginClick(username, Password);
    }
  };

  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };

  const pinValue = (event: any) => {
    otp = event;
  };
  const handleUserID = (event: any) => {
    // setErrorLine(false);
    setUserID(event.target.value);
  };

  const handlePassword = (event: any) => {
    // setErrorLine(false);
    setPassword(event.target.value);
  };

  const loginClick = async (username: any, Password: any) => {
    setOpenModal(false);
    setIsResponseLoader(true);
    const payload = {
      userName: username,
      role: role,
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      password: Password,
    };
    setErrorLine(false);
    const result = await getsecondfactor(payload);
    setIsResponseLoader(false);
    UserValidate(result);
  };

  const UserValidate = (result: any) => {
    if (result.status == 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === '200' &&
        result.data.CoworkerAuthenticationResult.value.statusCode === 200
      ) {
        setOpenModal(false);
        if (['PRELIMPAID', 'PRELIMINV'].includes(agrStatus)) {
          setreversepaymentmodal(true);
        } else {
          dropdownbing();
        }

        setTwoFactorCompleted !== undefined
          ? setTwoFactorCompleted(true)
          : null;

        // setPopupOpen({ ...PopupOpen, PrintPopupOpen: true });
      } else {
        setOpenModal(false);
        setOpenSecondModal(true);
      }
    } else {
      setOpenModal(false);
      setOpenSecondModal(true);
    }
  };

  const okClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
    // setUserID('');
    // setPassword('');
  };

  const popupSelect = (result: any) => {
    if (result.data.pin === 1 && result.data.secondFactor === 1) {
      setOpenPinModal(true);
      setpopupFlag(true);
    } else if (result.data.pin === 1) {
      setOpenPinModal(true);
      setpopupFlag(false);
    } else if (result.data.secondFactor === 1) {
      setOpenModal(true);
      setpopupFlag(false);
    }
  };

  const openModelCancelClick = () => {
    setErrorLine(false);
    setOpenModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };

  useEffect(() => {
    if (otp.length == 4) {
      setContinue(false);
    } else {
      setContinue(true);
    }
  }, [otp]);

  const noClick = () => {
    setOpenPinModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };
  const continueClick = async () => {
    setOpenPinModal(false);
    setIsResponseLoader(true);
    const userResponse = await getCurrentInfo();
    // eslint-disable-next-line no-console
    console.log('getCurrentUser Response', userResponse);
    const id = userResponse.data.employeeId
      ? userResponse.data.employeeId
      : userResponse.data.email.toLowerCase();
    setEmployeeDetails !== undefined
      ? setEmployeeDetails(userResponse.data)
      : null;

    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    // eslint-disable-next-line no-console
    console.log('getPin Payload', payload);
    // eslint-disable-next-line no-console
    console.log('getPin Payload', payload);
    const result = await getPinDetails(payload);
    // eslint-disable-next-line no-console
    console.log('Total Result', result);
    setIsResponseLoader(false);
    setOpenPinModal(true);
    setInvalidPin(result.status);
    if (result.data.statusCode == 200 && popupFlag) {
      setOpenPinModal(false);
      setOpenModal(true);
    }
    if (result.data.statusCode == 200 && !popupFlag) {
      setOpenPinModal(false);
      if (['PRELIMPAID', 'PRELIMINV'].includes(agrStatus)) {
        setreversepaymentmodal(true);
      } else {
        dropdownbing();
      }
      // await documentSign()
    }
  };

  const pinEnter = (event: any) => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && Continue == false) {
      event.preventDefault();
      continueClick();
    }
  };

  const voidpopup = () => {
    return (
      <div
        data-testid="showhidepopup"
        id="voidagreement"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.p3}>
          <label className={classes.formLabel}>Void Reason</label>

          <RACSelect
            options={ReferenceDetails()}
            loading={conditionLoader}
            defaultValue={dropdownvalue !== '' ? dropdownvalue : 'Select'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              Handledropdown(e);
            }}
          ></RACSelect>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt4} ${classes.agrVoidAgreementPopupBtn}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.me2}
            isRounded={false}
            onClick={() => removevoidbutton()}
          >
            Cancel
          </RACButton>

          <RACButton
            disabled={hidevoid}
            isRounded={false}
            variant="contained"
            color="primary"
            onClick={() => void_Status()}
          >
            Void
          </RACButton>
        </Grid>
      </div>
    );
  };
  const successpop = () => {
    return (
      <div
        id="success"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={`${classes.justifyCenter} ${classes.textCenter}`}>
          <Grid md={12}>
            <Successimage></Successimage>
          </Grid>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.textCenter} ${classes.mt3}`}
          >
            Agreement# {AgreementNumber} has been voided Successfully
          </Typography>
        </Grid>

        <Grid md={12} className={`${classes.py4} ${classes.textCenter}`}>
          {/* <button type="button" className="btn btn-primary mx-1" data-testid="btnok" data-bs-dismiss="modal" onClick={(e) => Removevoid()} >OK</button> */}
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => Removevoid()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const Openerrorpopup = () => {
    return (
      <div
        id="success"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textCenter}>
          <Alertimage></Alertimage>
          <Typography className={classes.my2}>Something went wrong.</Typography>
          {/* <Typography className={classes.my2}>
            Please enter valid Agreement ID
          </Typography> */}
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => Removevoid()}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const openAlertModalContent = () => {
    return (
      <div>
        <Grid className={twoFactorClassName.textCenter}>
          <img
            className={twoFactorClassName.racErrorIcon}
            src={Erroricon}
            alt="Logo"
          />
          <Typography className={twoFactorClassName.racErrorFunction}>
            The user you entered can not authorize the use of this function.
          </Typography>
        </Grid>
        <Grid className={twoFactorClassName.textCenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={twoFactorClassName.spacerME2}
            onClick={okClick}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const openPinModalContent = () => {
    return (
      <div>
        <Grid container className={twoFactorClassName.racPinContainer}>
          <Grid item md={12}>
            <Typography className={twoFactorClassName.racPinLabel}>
              PIN
            </Typography>
          </Grid>
          <Grid item md={12} onKeyPress={pinEnter}>
            <PinField
              length={4}
              type="numeric"
              focus={true}
              secret={true}
              autoSelect={false}
              style={{ marginLeft: '0.3rem' }}
              inputStyle={{
                border: '1px solid #adb5bd',
                borderRadius: '0.6rem',
                marginRight: '2rem',
              }}
              onComplete={(event) => pinValue(event)}
              onChange={(event) => otpchange(event)}
            />
          </Grid>
          {InvalidPin == 400 ? (
            <Grid>
              <Typography
                id="InvalidPin"
                className={twoFactorClassName.racPinError}
              >
                Invalid PIN{' '}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          className={`${twoFactorClassName.floatRight} ${twoFactorClassName.spacerMT5} ${twoFactorClassName.spacerMB3} ${twoFactorClassName.paddingButtons}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={twoFactorClassName.spacerME2}
            onClick={noClick}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            disabled={Continue}
            onClick={continueClick}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };
  const openModalContent = () => {
    return (
      <div>
        <Grid className={twoFactorClassName.textCenter}>
          <RacLogo className={twoFactorClassName.logoResponsive}></RacLogo>
        </Grid>
        {errorline === true ? (
          <Grid className={twoFactorClassName.spacerMargin}>
            <Typography className={twoFactorClassName.racLoginAlert}>
              The user ID and/or password you entered are not valid. Please try
              again.
            </Typography>
          </Grid>
        ) : null}
        <Grid container spacing={2} className={twoFactorClassName.spacerMT1}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={twoFactorClassName.formLabel}
            >
              User ID
            </Typography>
            <RACTextbox
              maxlength={30}
              isCurrency={false}
              type="text"
              value={userID}
              OnChange={(event) => handleUserID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={twoFactorClassName.formLabel}
            >
              Password
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event) => secondfactorEnter(event, userID, password)}
              isCurrency={false}
              type="password"
              value={password}
              OnChange={(event) => handlePassword(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid className={`${classes.mt2} ${twoFactorClassName.floatRight} `}>
          <RACButton
            variant="outlined"
            color="primary"
            className={twoFactorClassName.spacerME2}
            onClick={openModelCancelClick}
          >
            Cancel
          </RACButton>
          {userID !== '' && password !== '' ? (
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => loginClick(userID, password)}
            >
              Login
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              className={twoFactorClassName.disabled}
            >
              Login
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };
  const reversepayment = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.bold} ${classes.font17}`}>
            Please reverse the payment to void the agreement.
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => setreversepaymentmodal(false)}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setreversepaymentmodal(false);
              history.push(`/payment1/reversepayment/${CustomerId}`);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  function voidDisableValidation() {
    if (AgrStoreNum) {
      if (AgrStoreNum != window.sessionStorage.getItem('storeNumber')) {
        return true;
      }
    }
    else if (agrStatus == 'ACTIVE') {
      return true;
    }
    else if (props?.installationStatus == 'Installation Completed') {
      return true;
    }
    else {
      return disablevoidbutton
    }
  }
  return (
    // <div style={{ float: 'right', marginRight: agrStatus ? '1px' : '20px' }}>
    <div style={{ float: 'right', marginRight: agrStatus ? '1px' : '6px' }}>
      <RACModalCard
        isOpen={showpopup}
        maxWidth="xs"
        title="Void Agreement"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => Setshowpopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={voidpopup()}
      />

      <RACButton
        size="small"
        variant="contained"
        color="primary"
        style={{ float: 'right' }}
        isRounded={false}
        disabled={voidDisableValidation()}
        //disabled={agrStatus == 'ACTIVE' ? true : disablevoidbutton}
        //onClick={() => (agrStatus ? Void_OnclickPo() : Void_Onclick())}
        onClick={() => Void_Onclick()}
      >
        Void
      </RACButton>

      <>
        <RACModalCard
          isOpen={successpopup}
          maxWidth="xs"
          borderRadius="20px"
          closeIcon={true}
          onClose={() => {
            Setsuccesspopup(false);
            props.func(true);
          }}
          // eslint-disable-next-line react/no-children-prop
          children={successpop()}
        />
      </>

      <>
        <RACModalCard
          isOpen={errorpopup}
          maxWidth="xs"
          // title="Modal title"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => SetErrorPop(false)}
          // eslint-disable-next-line react/no-children-prop
          children={Openerrorpopup()}
        />

        <RACModalCard
          isOpen={openModal}
          borderRadius="20px"
          onClose={() => setOpenModal(false)}
          maxWidth={'xs'}
          className="racSecondFacPopup"
          title={''}
          //eslint-disable-next-line react/no-children-prop
          children={openModalContent()}
        ></RACModalCard>
        <RACModalCard
          isOpen={openSecondModal}
          borderRadius="20px"
          closeIcon={true}
          onClose={() => setOpenSecondModal(false)}
          maxWidth={'sm'}
          title={''}
          //eslint-disable-next-line react/no-children-prop
          children={openAlertModalContent()}
        ></RACModalCard>
        <RACModalCard
          isOpen={openPinModal}
          borderRadius="20px"
          className="racPinPopup"
          closeIcon={true}
          onClose={() => setOpenPinModal(false)}
          maxWidth={'xs'}
          title={'Enter PIN to continue'}
          //eslint-disable-next-line react/no-children-prop
          children={openPinModalContent()}
        ></RACModalCard>
        <RACModalCard
          isOpen={reversepaymentmodal}
          borderRadius="20px"
          closeIcon={true}
          onClose={() => setreversepaymentmodal(false)}
          maxWidth={'xs'}
          //eslint-disable-next-line react/no-children-prop
          children={reversepayment()}
        ></RACModalCard>
      </>

      {IsResponseLoader === true ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
}
