/* eslint-disable */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom'; //useLocation
import {
  RACButton,
  Grid,
  RACModalCard,
  Typography,
} from '@rentacenter/racstrap';
import AddCocustomer from '../../Rental/AddCoCustomer/AddCocustomer';
import { AgreementContext } from '../../../context/AgreementContext';
import { AppRoute } from '../../../config/route-config';
import { LoanerContext } from '../../../context/LoanerAgreementContext';
import {
  AccountManagementURLParam,
  ADD_ON_PACKAGE,
  AMOrigin,
} from '../../../constants/constants';
import { OriginContext } from '../../../context/OriginContext';

interface Params {
  customerId: string;
  agreementId: string;
}

const FooterContent = (props: any) => {
  const { parentinventoryNumber } = useContext(LoanerContext);
  const {
    agreementVoidStatus,
    agreementCreateStatus,
    itemArrayData,
    setinventoryMapping,
  } = useContext(AgreementContext);
  const { redirectOrigin } = useContext(OriginContext);
  const classes = props.throwStyle;
  const location = useLocation();
  const search = useLocation().search;
  const salesLeadId = new URLSearchParams(search).get('salesLeadId');

  const history = useHistory();
  const [footerControl, setfooterControl] = useState();
  const [packageCriteriaFooter, setpackageCriteriaFooter]: any = useState();
  const [copyData, setcopyData]: any = useState();
  // const { customerId } = useParams<Params>();
  let { agreementId } = useParams<Params>();
  const { customerId } = useParams<Params>();
  const inventoryNumber = new URLSearchParams(search).get('inventoryNumber');

  if (redirectOrigin === AMOrigin) {
    agreementId = agreementId.replace(AccountManagementURLParam, '');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newcustomer, setnewcustomer] = useState(true);
  const [openPackageSelection, setopenPackageSelection] = useState(false);
  const [packageSelection, setpackageSelection] = useState(false);
  const [itemArrayReceived, setitemArrayReceived] = useState([]);
  useEffect(() => {
    setfooterControl(props.controlData);
    setpackageCriteriaFooter(props.throwpackageCriteria);
    setcopyData(props.throwCopy);
    setpackageSelection(props.throwPackageState);
    console.log('props.throwPackageState in footer', props.throwPackageState);

    setitemArrayReceived(props.throwItemArray);
    console.log('props.throwItemArray', props.throwItemArray);
  }, [props]);

  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    // eslint-disable-next-line no-console
    console.log('Footer contex', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };

  const CancelClick = () => {
    if (redirectOrigin === AMOrigin) {
      history.push({
        pathname: `${AppRoute.AMCustomer}/${customerId}`,
        state: location?.state,
      });
      return;
    }
    history.push({
      pathname: `/customer/searchcustomer`,
    });
  };

  const invQuantityFormation = (payload: any) => {
    const ResponseArr: any = [];
    for (let i = 0; i < payload.length; i++) {
      const indexOfSameObj = ResponseArr.findIndex(
        (checkObj: any) =>
          checkObj.rmsDepartment == payload[i].rmsDepartment &&
          checkObj.rmsSubDepartment == payload[i].rmsSubDepartment &&
          checkObj.rmsBracket == payload[i].rmsBracket &&
          checkObj.rmsItemMasterId == payload[i].rmsItemMasterId
      );
      if (ResponseArr.length == 0 || indexOfSameObj == -1) {
        ResponseArr.push(payload[i]);
      } else {
        ResponseArr[indexOfSameObj].quantity =
          ResponseArr[indexOfSameObj].quantity + payload[i].quantity;
      }
    }
    return ResponseArr;
  };
  const validateReqWithCriteriaFn = (req: any, Criteria: any) => {
    const criteriaCheck: any = Criteria.map((obj: any) => {
      obj.validation = false;
      return obj;
    });
    for (let i = 0; i < req.length; i++) {
      for (let j = 0; j < Criteria.length; j++) {
        if (
          !(
            (criteriaCheck[j].rmsDepartment == req[i].rmsDepartment ||
              !criteriaCheck[j].rmsDepartment) &&
            (criteriaCheck[j].rmsSubDepartment == req[i].rmsSubDepartment ||
              !criteriaCheck[j].rmsSubDepartment) &&
            (criteriaCheck[j].rmsBracket == req[i].rmsBracket ||
              !criteriaCheck[j].rmsBracket) &&
            (criteriaCheck[j].rmsItemMasterId == req[i].rmsItemMasterId ||
              !criteriaCheck[j].rmsItemMasterId) &&
            req[i].quantity >= criteriaCheck[j].minQuantity
          ) &&
          !criteriaCheck[j].validation
        ) {
          criteriaCheck[j].validation = false;
        } else {
          criteriaCheck[j].validation = true;
        }
      }
    }

    return criteriaCheck.filter((obj: any) => !obj.validation);
  };

  const minMaxValidator = () => {
    if ((props.throwpackageCriteria  || props.givereceivedpackage ==ADD_ON_PACKAGE) && copyData) {
      const invDetails: any = [];
      const bucketCopy = copyData;
      const packageCriteria = props.throwpackageCriteria;
      bucketCopy.forEach((obj: any) => {
        //checking items in filtered array
        //item array is empty so we are pushing that item
        invDetails.push({
          rmsDepartment: obj.departmentsEn,
          rmsSubDepartment: obj.subDepartmentsEn,
          rmsBracket: obj.bracketEn,
          rmsItemMasterId: obj.rmsItemMasterId,
          quantity: 1,
        });
      });
      const selectedInvArr = invQuantityFormation(invDetails);
      let validPackageCriteria: any;
      if(props.givereceivedpackage !==ADD_ON_PACKAGE){
       validPackageCriteria = packageCriteria.filter(
        (obj: any) => obj.minQuantity > 0
      );
    }
      const invalidMinMaxObjArr =  props.givereceivedpackage ==ADD_ON_PACKAGE ? [] :validateReqWithCriteriaFn(
        selectedInvArr,
        validPackageCriteria
      );
      console.log('invalidMinMaxObjArr', invalidMinMaxObjArr);
      if (invalidMinMaxObjArr.length == 0) {
        props.setItemsearchCompleted(true);
        if (salesLeadId == null && agreementId == undefined) {
          history.push({
            pathname: `/agreement/loaner/details/${customerId}`,
          });
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
        } else if (salesLeadId == null && agreementId !== undefined) {
          history.push({
            pathname: `/agreement/loaner/details/${customerId}/${agreementId}/${parentinventoryNumber}`,
          });
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
        }
        const store: any = [];
        console.log('itemArrayData from footer context', itemArrayData);
        if (itemArrayData !== undefined) {
          itemArrayData.map((value) => {
            store.push({
              inventoryId: value,
            });
          });
          setinventoryMapping(store);
          console.log('store populate', store);
        }
      } else {
        console.log('trigger error popup');
        props.triggerMinMaxPopup(invalidMinMaxObjArr);
      }
    } else if (!props.throwpackageCriteria || copyData.length !== 1) {
      props.setItemsearchCompleted(true);
      if (agreementId !== undefined) {
        history.push({
          pathname: `/agreement/loaner/details/${customerId}/${agreementId}/${parentinventoryNumber}`,
        });
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
      }
      const store: any = [];
      console.log('itemArrayData from footer context', itemArrayData);
      if (itemArrayData !== undefined) {
        itemArrayData.map((value) => {
          store.push({
            inventoryId: value,
          });
        });
        setinventoryMapping(store);
        console.log('store populate', store);
      }
    }
  };

  const triggerME = () => {
    console.log('response in triggerME');

    console.log('fdgshjhshyyuuiiii');
    props.sometrigger();
  };

  const NextBtnClick = () => {
    // const captureObjectIndex: any = [];
    console.log('packageSelection in next click', packageSelection);
    console.log(
      'itemArrayReceived.length in next click',
      itemArrayReceived.length
    );

    if (packageSelection === false && itemArrayReceived.length > 1) {
      setopenPackageSelection(true);
    } else {
      minMaxValidator();
    }
  };

  const buildPackageSelection = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography>
            Please confirm you have selected the correct package type{' '}
            <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              {props.givereceivedpackage}
            </Typography>
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => setopenPackageSelection(false)}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setopenPackageSelection(false);
              minMaxValidator();
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className={classes.fixedBottom}>
      <Grid className={classes.floatLeft}>
        <RACButton
          color="primary"
          variant="outlined"
          className={classes.ms2}
          onClick={() => CancelClick()}
        >
          Cancel
        </RACButton>
      </Grid>

      <Grid className={classes.floatRight}>
        <RACButton
          color="primary"
          variant="contained"
          onClick={() => NextBtnClick()}
          disabled={footerControl}
          style={{ marginRight: '4px' }}
        >
          Next
        </RACButton>
      </Grid>
      {/* {agreementVoidStatus.agreementVoidedStatus === false &&
      agreementCreateStatus.agreementCreatedStatus === false ? (
        <Grid className={classes.floatRight}>
          <AddCocustomer
            coCustomerProps={customerId}
            newCoCustomer={newcustomer}
            func={pull_data}
            somethingTrigger={triggerME}
          />
        </Grid>
      ) : null} */}
      <RACModalCard
        isOpen={openPackageSelection}
        maxWidth="xs"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={buildPackageSelection()}
      />
    </Grid>
  );
};
export default FooterContent;
