/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import {
    RACButton,
    Grid,
    Typography,
    RACModalCard,
    makeStyles
} from "@rentacenter/racstrap";
import { ReactComponent as TickIcon } from '../../../assets/images/Done.svg';
import { useHistory } from "react-router-dom";
import { insertAgreementLog } from "../../../api/user";

const useClasses = makeStyles((theme: any) => ({
    textCenter: {
        textAlign: 'center',
      },
      spacerMT3: {
        marginTop: '2rem',
      },
      customerRacpopup1: {
        fontFamily: 'OpenSans-bold',
        fontSize: '17px',
        letterSpacing: '0px',
      },
      buttonsPadding: {
        display: 'flex',
        justifyContent: 'space-around'
      },
      floatLeft: {
        float: 'left',
      }  ,
      floatRight: {
        float: 'right',
      },
      customModal: {
        '& div': {
          '& div': {
            maxWidth: '450px',
            maxHeight: '700px',
          },
        },
      },
      customModal1: {
        '& div': {
          '& div': {
            maxWidth: '700px',
            maxHeight: '700px',
          },
        },
      },
}));

export default function RACExchange(props: any) {
    const classes = useClasses();
    console.log(props, 'Agr Info');
    const rentPaid = props?.value?.agreementInformation?.[0]?.data?.agreementInformation?.agrRentPaid;
    // const deliveryStatus = props?.value?.agreementInformation?.[0]?.data?.agreementWorkflow?.isDeliveryCompleted == '1';
    const suspenseAmount = props?.value?.agreementInformation?.[0]?.data?.agreementInformation?.suspenseAmount;
    const agreementNumber = props?.value?.agreementInformation?.[0]?.data?.agreementInformation?.agreementNumber;
    const [exchangePopup, setExchangePopup] = useState<any>(false);
    const [suspensePopup, setSuspensePopup] = useState<any>(false);

    useEffect(() => {
        validateAndOpenPopup()
    }, [])

    const history = useHistory();
    function showExchangePopup() {
        return (
            <Grid className={`${classes.textCenter}`}>
                {
                    props?.value?.isRacExchangeFuncEnabled ?
                        <>
                            <Typography className={`${classes.customerRacpopup1}`}>
                                Customer is eligible for ${rentPaid ? parseFloat(rentPaid)?.toFixed(2) : 0.00} for Exchange.
                            </Typography>
                            <Typography className={`${classes.customerRacpopup1}`}>
                                Do you want to proceed with agreement creation?
                            </Typography>
                        </> :
                        <Typography className={`${classes.customerRacpopup1} ${classes.spacerMT3}`}>
                            Please follow the new agreement creation workflow to complete the RAC Exchange Program.
                        </Typography>
                }
                <Grid className={`${classes.spacerMT3} ${classes.buttonsPadding}`}>
                    <RACButton
                        variant="outlined"
                        color="primary"
                        className={`${classes.floatLeft}`}
                        onClick={() => {
                            props.value.setExchangeNavigationPopup(false);
                        }}
                    >
                        {props?.value?.isRacExchangeFuncEnabled ? 'No' : 'Cancel'}
                    </RACButton>
                    {props?.value?.isRacExchangeFuncEnabled ?
                        <RACButton
                            variant="contained"
                            color="primary"
                            className={`${classes.floatRight}`}
                            onClick={() => {
                                insertAgreementLog({
                                    agreementActivities: [{
                                        agreementId: props?.value?.agreementId,
                                        activityDescription: `Agreement Exchange Selected`,
                                        agreementActivityType: "AGMXNGS",
                                    }]
                                });
                                const url = `/agreement/rental/itemsearch/${props?.value?.customerId}?isExchange=1&agreementId=${props?.value?.agreementId}`;
                                window.location.href = url;
                            }}
                        >
                            Create Agreement
                        </RACButton>
                        : null
                    }
                </Grid>
            </Grid>
        )
    }

    function validateAndOpenPopup(){
        if(suspenseAmount && suspenseAmount != '0.00'){
            setSuspensePopup(true);
        }
        else {
            setExchangePopup(true);
        }
    }


    function showSuspensePopup() {
        return (
            <Grid className={`${classes.textCenter}`}>
                <>
                    <Typography className={`${classes.customerRacpopup1}`}>
                        The suspense amount of ${suspenseAmount ? suspenseAmount : 0.00} on Agreement # {agreementNumber ? agreementNumber : 0} will be
                    </Typography>
                    <Typography className={`${classes.customerRacpopup1}`}>
                        transferred to the Exchange Agreement after making the initial payment.
                    </Typography>
                </>
                <Grid className={`${classes.spacerMT3} ${classes.buttonsPadding}`}>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={`${classes.floatLeft}`}
                        onClick={() => {
                            setExchangePopup(true);
                            setSuspensePopup(false);
                            // props.value.setExchangeNavigationPopup(false);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <RACModalCard
                isOpen={exchangePopup}
                closeIcon={true}
                maxWidth={'xs'}
                borderRadius={'25px !important'}
                onClose={() => {
                    props.value.setExchangeNavigationPopup(false);
                    setExchangePopup(false);
                }}
                className={classes.customModal}
            >
                {showExchangePopup()}
            </RACModalCard>
            <RACModalCard
                isOpen={suspensePopup}
                closeIcon={true}
                maxWidth={'xs'}
                borderRadius={'25px !important'}
                onClose={() => {
                    props.value.setExchangeNavigationPopup(false);
                    setSuspensePopup(false);
                }}
                className={classes.customModal1}
            >
                {showSuspensePopup()}
            </RACModalCard>
        </>
    );
}
