import React, { useState, useEffect } from 'react';
import { Typography, Grid, Card, CardContent } from '@rentacenter/racstrap';
import { agrInfoWhiteCardStyles } from '../../../JSstyles/agrInfoWhiteCardStyles';
import CurrencyInput from 'react-currency-input-field';
import { paymentCost } from '../../../JSstyles/paymentCost';
import { fieldDeviceStyles } from '../../../JSstyles/fieldDeviceStyles';
const WhiteCard = (props: any) => {
  const classes1 = fieldDeviceStyles();
  const classes = props.throwStyle;
  const agrInfoWhiteCardClassName = agrInfoWhiteCardStyles();
  const paymentCostClassName = paymentCost();
  // White Card
  // const [requiredDataObj, setrequiredDataObj]: any = useState({});
  // const [requiredDataObjDirect, setrequiredDataObjDirect]: any = useState(
  //   props.propsData
  // );

  const InitialWhiteValues = {
    discount: '',
    extensionAmount: '',
    initialPayment: '',
    ldw: '',
    other: '',
    policy: '',
    rate: '',
    tax: '',
  };
  const [WhiteValues, setWhiteValues] = useState(InitialWhiteValues);
  useEffect(() => {
    /* eslint-disable no-console */
    console.log('Received From Main:', props.other);
    const Whitecarddetails = props.propsData;
    if (Whitecarddetails !== undefined) {
      setWhiteValues(Whitecarddetails);
    } else {
      setWhiteValues(InitialWhiteValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.propsData]);

  return (
    <Grid item md={6} className={classes1.widthtab50}>
      <Typography className={`${classes.title} ${classes1.title}`}>
        Initial Payment
      </Typography>

      <Card className={classes.card}>
        <CardContent className={`${classes.p32} ${classes1.ptab16}`}>
          <Grid container className={`${classes.mb2} ${classes.mt2}`}>
            <Grid item md={6} sm={6} xs={6} className={classes.floatLeft}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoWhiteCardClassName.cardlabel} ${classes1.tabFont12}`}
              >
                Other
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              {/* <Typography variant="subtitle1" className={classes.floatRight}>
                $ {WhiteValues.other}
              </Typography> */}
              <Grid className={`${classes.floatRight} ${classes.dFlex}`}>
                <Typography
                  className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
                >
                  $
                </Typography>
                <CurrencyInput
                  id="input-example"
                  name=""
                  style={{ zIndex: 0, textAlign: 'right' }}
                  className={`${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${agrInfoWhiteCardClassName.disabledColour}`}
                  decimalsLimit={2}
                  value={WhiteValues.other}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            className={`${classes.mb2} ${classes.mt4} ${classes1.mtMd0}`}
          >
            <Grid item sm={7} xs={7} md={7}>
              <Typography
                variant="subtitle1"
                className={`${paymentCostClassName.cardLabel} ${classes1.tabFont12}`}
              >
                Rate
              </Typography>
            </Grid>
            <Grid item sm={5} xs={5} md={5}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoWhiteCardClassName.widgetalign}  ${classes1.tabFont12}`}
              >
                $ {WhiteValues.rate}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            className={`${classes.mb2} ${classes.mt4} ${classes1.mtMd0}`}
          >
            <Grid item sm={7} xs={7} md={7}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoWhiteCardClassName.cardlabel}  ${classes1.tabFont12}`}
              >
                Discount
              </Typography>
            </Grid>
            <Grid item sm={5} xs={5} md={5}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoWhiteCardClassName.widgetalign}  ${classes1.tabFont12}`}
              >
                $ {WhiteValues.discount}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            className={`${classes.mb2} ${classes.mt4} ${classes1.mtMd0}`}
          >
            <Grid item sm={7} xs={7} md={7}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoWhiteCardClassName.cardlabel}  ${classes1.tabFont12}`}
              >
                Extension Amount
              </Typography>
            </Grid>
            <Grid item sm={5} xs={5} md={5}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoWhiteCardClassName.widgetalign}  ${classes1.tabFont12}`}
              >
                ($ {WhiteValues.extensionAmount})
              </Typography>
            </Grid>
          </Grid>

          {props.throwIsTire === true ? (
            <Grid container className={`${classes.mb2} ${classes.mt4}`}>
              <Grid item md={7}>
                <Typography
                  variant="subtitle1"
                  className={`${agrInfoWhiteCardClassName.cardlabel}  ${classes1.tabFont12}`}
                >
                  Policy
                </Typography>
              </Grid>
              <Grid item md={5}>
                <Typography
                  variant="subtitle1"
                  className={`${agrInfoWhiteCardClassName.widgetalign}  ${classes1.tabFont12}`}
                >
                  $ {WhiteValues.policy}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          <Grid
            container
            className={`${classes.mb2} ${classes.mt4} ${classes1.mtMd0}`}
          >
            <Grid item sm={7} xs={7} md={7}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoWhiteCardClassName.cardlabel}  ${classes1.tabFont12}`}
              >
                LDW
              </Typography>
            </Grid>
            <Grid item sm={5} xs={5} md={5}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoWhiteCardClassName.widgetalign}  ${classes1.tabFont12}`}
              >
                $ {WhiteValues.ldw}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            className={`${classes.mb2} ${classes.mt4} ${classes1.mtMd0}`}
          >
            <Grid item sm={7} xs={7} md={7}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoWhiteCardClassName.cardlabel}  ${classes1.tabFont12}`}
              >
                Tax
              </Typography>
            </Grid>
            <Grid item sm={5} xs={5} md={5}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoWhiteCardClassName.widgetalign}  ${classes1.tabFont12}`}
              >
                $ {WhiteValues.tax}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            className={`${classes.mb2} ${classes.pt2} ${paymentCostClassName.payscheduleBorder} ${paymentCostClassName.alignCenter} ${classes.mt4}`}
          >
            <Grid item sm={7} xs={7} md={7}>
              <Typography
                variant="subtitle1"
                className={`${classes.font18} ${classes.semiBold}  ${classes1.tabFont14}`}
              >
                Initial Payment{' '}
              </Typography>
            </Grid>
            <Grid item sm={5} xs={5} md={5}>
              <Typography
                variant="subtitle1"
                className={`${classes.font24} ${classes.bold} ${classes.textRight}  ${classes1.tabFont18}`}
              >
                $ {WhiteValues.initialPayment}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
    // </Grid>
    // </Grid>
    // </Grid>
  );
};
export default WhiteCard;
