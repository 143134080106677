/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/no-duplicate-string */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const agrInfoBlueCardStyles = () => {
  const useClasses = makeStyles((theme) => ({
    bluecard: {
      borderRadius: theme.typography.pxToRem(16),
      backgroundColor: `#2179FE`,
    },
    widgetalign: {
      textAlign: 'right',
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(14),
    },
    cardlabel: {
      color: '#111111',
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(14),
    },
    bluecardlabel: {
      color: `${RACCOLOR.WHITE}`,
      fontFamily: 'OpenSans-semibold',
      fontSize: '16px',
    },
    bluewidgetalign: {
      color: `${RACCOLOR.WHITE}`,
      fontFamily: 'OpenSans-bold',
      fontSize: '16px',
      textAlign: 'right',
    },
    totalvalue: {
      color: `${RACCOLOR.NORMAL_BLUE}`,
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(16),
    },
    agreementtotal: {
      color: `${RACCOLOR.NORMAL_BLUE}`,
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(16),
      textAlign: 'right',
    },
    alignCenter: {
      alignItems: 'center',
    },
    disabledColour: {
      backgroundColor: '#e9ecef',
    },
  }));
  return useClasses();
};
