/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import { AppRoute, AppRouteKey } from '../../../config/route-config';
import AgreementDetails from './AgreementDetails';
import Deliverymethod from './Deliverymethod';
import SearchResult from '../ItemSearch/searchResult';
import Wizard from './Wizard';

import { Route as CustomRoute } from '../../../router/Route';

export default function CreateRentalAgreementRoute() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  // const [coCustomerDetails, setcoCustomerDetails] = useState('');
  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    //setcoCustomerDetails()
    // eslint-disable-next-line no-console
    console.log('Footer contex', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };

  const throwerfunc = () => {
    console.log('response in throwerfunc');

    console.log('fdgshjhshyuuiii');
    setchangeDEStatus(true);
    // setthrowerObject(response);
  };
  const [ItemSearchCompleted, setItemSearchCompleted] = useState(false);
  const [AgrDetailsCompleted, setAgrDetailsCompleted] = useState(false);
  const [changeDEStatus, setchangeDEStatus] = useState(false);
  const [InitialPaymentCompleted, setInitialPaymentCompleted] = useState(false);
  const [DeliveryMethodCompleted, setDeliveryMethodCompleted] = useState(false);
  const [WizardAgrId, setWizardAgrId] = useState('');
  // const [throwerObject, setthrowerObject] = useState();
  const param = useParams();
  console.log('param in create', param);
  console.log('in create rental agreement route comp');
  return (
    <>
      <div>
        <div className="tab-content container-pb-custom">
          <div className="">
            <div className="">
              <div
                id="smartwizard"
                className="p-0 sw sw-theme-default sw-justified"
              >
                {/* Fixed Issues */}
                <Wizard
                  ItemSearchCompleted={ItemSearchCompleted}
                  AgreementDetailsCompleted={AgrDetailsCompleted}
                  InitialPaymentCompleted={InitialPaymentCompleted}
                  DeliveryMethodCompleted={DeliveryMethodCompleted}
                  CoCustomerDetails={{
                    coCustomerId: coCustomerId,
                    coCustomerName: coCustomerName,
                  }}
                  dethrower={changeDEStatus}
                  // throwAmount={throwerObject}
                  WizardAgrId={WizardAgrId}
                  setWizardAgrId={setWizardAgrId}
                />

                <CustomRoute
                  path="/agreement/rental/itemsearch"
                  key={AppRouteKey.ItemSearch}
                >
                  <SearchResult
                    setItemSearchCompleted={setItemSearchCompleted}
                    func={pull_data}
                    somedeeptrigger={throwerfunc}
                  />
                </CustomRoute>
                <CustomRoute
                  path="/agreement/rental/details"
                  key={AppRouteKey.AgreementDetails}
                >
                  <AgreementDetails
                    setItemSearchCompleted={setItemSearchCompleted}
                    setAgrDetailsCompleted={setAgrDetailsCompleted}
                    setWizardAgrId={setWizardAgrId}
                    func={pull_data}
                  />
                </CustomRoute>
                <CustomRoute
                  path="/agreement/rental/initialpayment"
                  key={AppRouteKey.InitialPayment}
                >
                  <></>
                </CustomRoute>
                <CustomRoute
                  path="/agreement/rental/delivery"
                  key={AppRouteKey.DeliveryMethod}
                >
                  <Deliverymethod
                    setItemSearchCompleted={setItemSearchCompleted}
                    setAgrDetailsCompleted={setAgrDetailsCompleted}
                    setInitialPaymentCompleted={setInitialPaymentCompleted}
                    setDeliveryMethodCompleted={setDeliveryMethodCompleted}
                    func={pull_data}
                  />
                </CustomRoute>
                {/* <Route
                    path='/agreement/rental/delivery/:customerId/:agreementId'
                    key={AppRouteKey.DeliveryMethod}
                    component={Deliverymethod}
                  /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
