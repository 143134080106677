/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
//PS_SO-TP-1
import React, { useDebugValue } from 'react';
import { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography } from '@rentacenter/racstrap';
import { paymentCost } from '../../../JSstyles/paymentCost';
import CurrencyInput from 'react-currency-input-field';

export default function TotalPaymentCard(props: any) {
  //PS_SO-TP-2
  const classes = props.throwStyle;
  //PS_SO-TP-3
  const paymentCostClassName = paymentCost();
  //PS_SO-TP-4
  const { totalPaymentCardValue } = props;
  //PS_SO-TP-5
  const totalpaymentObj = {
    totalInitialPayment: '',
    regularPaymentsCount: '',
    regularPaymentsAmount: '',
    finalPayment: '',
    totalPaymentsCount: '',
    sacTotal: '',
    agreementTotal: '',
  };
  //PS_SO-TP-6
  const [totalPayment, settotalPayment] = useState(totalpaymentObj);
  //PS_SO-TP-7
  useEffect(() => {
    if (totalPaymentCardValue !== undefined) {
      console.log('totalPaymentCardValue', totalPaymentCardValue);
      settotalPayment({
        ...totalPayment,
        totalInitialPayment: totalPaymentCardValue.initialPaymentValue,
        regularPaymentsCount: totalPaymentCardValue.regularPaymentTerm,
        regularPaymentsAmount: totalPaymentCardValue.regularPaymentValue,
        finalPayment: totalPaymentCardValue.finalPaymentValue,
        totalPaymentsCount: totalPaymentCardValue.totalPaymentCount,
        sacTotal: totalPaymentCardValue.sacAmountValue,
        agreementTotal: totalPaymentCardValue.agreementTotal,
      });
    }
  }, [totalPaymentCardValue]);
  return (
    <Grid>
      <Grid item md={6} style={{ marginLeft: '538px' }}>
        <Typography className={classes.title}>
          Total Payment/Total Cost
        </Typography>
        <Grid container className={classes.mb6}>
          <Card
            className={paymentCostClassName.bluecard}
            style={{ width: '420px' }}
          >
            <CardContent className={classes.p4}>
              <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                <Grid item md={7}>
                  <Typography
                    variant="subtitle1"
                    className={paymentCostClassName.blueCardLabel}
                  >
                    Initial Payment
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <Typography
                    variant="subtitle1"
                    className={paymentCostClassName.blueWidgetAlign}
                  >
                    $ {totalPayment.totalInitialPayment}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                <Grid item md={7}>
                  <Typography
                    variant="subtitle1"
                    className={paymentCostClassName.blueCardLabel}
                  >
                    Regular Payments ({totalPayment.regularPaymentsCount})
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <Typography
                    variant="subtitle1"
                    className={paymentCostClassName.blueWidgetAlign}
                  >
                    $ {totalPayment.regularPaymentsAmount}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                <Grid item md={7}>
                  <Typography
                    variant="subtitle1"
                    className={paymentCostClassName.blueCardLabel}
                  >
                    Final Payment
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <Typography
                    variant="subtitle1"
                    className={paymentCostClassName.blueWidgetAlign}
                  >
                    $ {totalPayment.finalPayment}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                <Grid item md={12}>
                  <Typography
                    variant="subtitle1"
                    className={paymentCostClassName.blueCardLabel}
                  >
                    {' '}
                    Total Payments ({totalPayment.totalPaymentsCount})
                  </Typography>
                </Grid>
              </Grid>

              <Card
                className={`${classes.card} ${classes.p3} ${paymentCostClassName.mtcustom}`}
              >
                <Grid container className={classes.mb2}>
                  <Grid item md={7} className={classes.floatLeft}>
                    <Typography
                      variant="subtitle1"
                      className={paymentCostClassName.totalValue}
                    >
                      Same As Cash Total
                    </Typography>
                  </Grid>

                  <Grid item md={5}>
                    <Grid className={classes.floatRight}>
                      <Typography
                        className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
                      >
                        $
                      </Typography>
                      <CurrencyInput
                        id="input-example"
                        name=""
                        style={{ zIndex: 0, textAlign: 'right' }}
                        className={`${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`}
                        decimalsLimit={2}
                        value={totalPayment.sacTotal}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container className={paymentCostClassName.alignCenter}>
                  <Grid item md={7}>
                    <Typography
                      variant="subtitle1"
                      className={paymentCostClassName.totalValue}
                    >
                      Agreement Total
                    </Typography>
                  </Grid>
                  <Grid item md={5}>
                    <Typography
                      variant="subtitle1"
                      className={paymentCostClassName.agreementTotal}
                    >
                      $ {totalPayment.agreementTotal}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
