/* eslint-disable */
/* eslint-disable no-debugger */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useContext, useEffect, useState } from 'react';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { AppRoute /* AppRouteKey */ } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
import { UserFetcher } from '../components/Rental/UserFetcher/UserFetcher';
export const routesTestId = 'routesTestId';
import ComingSoonPage from '../components/Shared/ComingSoonPage';
import { Route as CustomRoute } from '../router/Route';
import { CustomerOrderAgreementContext } from '../context/CustomerOrderAgreementContext';
import ViewAgreementRoute from '../components/Rental/View Agreement/ViewAgreementRoute';
import CreateRentalAgreementRoute from '../components/Rental/CreateAgreement/CreateAgreementRoute';
import { LoanerContext } from '../context/LoanerAgreementContext';
import {
  getAgreementInfo,
  CoworkerRequired,
  getAgreementReturnOptions,
  getnextpaymentamount,
  Confirmdelivery,
  Recieptsign,
  CheckSign,
  GetCustomer
} from '../api/user';
import { AgreementContext } from '../context/AgreementContext';
import { SwitchOutAgreementContext } from '../context/SwitchOutAgreementContext';
import { ReinstateAgreementContext } from '../context/ReinstatementAgreementContext';
import { ContainerContext } from './App';
import { CustomPropInterface } from '../../src/index';
import { agreementGlobalStyles } from '../JSstyles/agreementGlobalStyles';
import {
  CircularProgress,
  RACModalCard,
  RACButton,
  Grid,
  Typography,
} from '@rentacenter/racstrap';
import CreateSwitchOutAgreementRoute from '../components/Switch Out/SwitchOutRoute/SwitchOutAgreementRoute';
import CreateReinstateAgreementRoute from '../components/Reinstatement/ReinstateRoute/ReinstateAgreementRoute';
import CreateCustomerOrderAgreementRoute from '../components/CustomerOrder/CustomerOrderRoute/CustomerOrderRoute';
interface Params {
  customerId: string;
  agreementId: string;
}

import { ReactComponent as AlertIcon } from '../assets/images/no-records-found.svg';
import { AccountManagementURLParam, ADD_ON_PRICING, AMOrigin, SACrolesArray } from '../constants/constants';
import { OriginContext } from '../context/OriginContext';
import {
  FeatureFlagContext,
  MakeFeatureFlagObjFn,
  makeSACrolesAllocationFn,
  makePriceOverRiderolesAllocationFn,
  makeTermOverRiderolesAllocationFn
} from '../context/FeatureFlagContext';
import SplitAgreement from '../components/Split/SplitAgreement';
import AgreementTransferRequest from '../components/AgreementTransfer/AgreementTransferRequest';
import AgreementTransferReceive from '../components/AgreementTransfer/AgreementTransferReceive';
import LoanerAgreementRoutes from '../components/LoanerAgreement/LoanerAgreementRoute/LoanerAgreementRoutes';
import { set } from 'date-fns';
import PromoAgreementRoute from '../components/PromoAgreement/PromoAgrRoute/PromoAgrRoute'
import { PromoAgreementContext } from '../context/PromoAgreementContext';
import AutoDeliveryConfirmProgress from '../components/CustomerOrder/Shared/AutoDeliveryConfirmProgress';
import PendingAgreement from '../components/PendingAgreementPage/pendingAgreement';

export const SetPendingAgreementValue = (agreementInfo: any) => {
  const paymentSchedule: any = {
    weeklyRate: agreementInfo.agreementInformation.agreementWeeklyRate, //
    weeklyTerm: parseInt(
      agreementInfo.agreementInformation.agreementWeeklyTerm
    ), //
    biweeklyRate: agreementInfo.agreementInformation.agreementBiWeeklyRate, //
    biweeklyTerm: parseInt(
      agreementInfo.agreementInformation.agreementBiWeeklyTerm
    ), //
    semimonthlyRate:
      agreementInfo.agreementInformation.agreementSemiMonthlyRate, //
    semimonthlyTerm: parseInt(
      agreementInfo.agreementInformation.agreementSemiMonthlyTerm
    ), //
    monthlyRate: agreementInfo.agreementInformation.agreementMonthlyRate, //
    monthlyTerm: parseInt(
      agreementInfo.agreementInformation.agreementMonthlyTerm
    ), //
  };

  const agreementSchedule: any = {
    schedule: agreementInfo.agreementInformation.scheduleRefCode, //
    dueDate: agreementInfo.agreementInformation.dueDate, //
  };

  const agreementInitialPayment: any = {
    rate: agreementInfo.initialPayment.rate, //
    initialRateTax: '0.00', //
    ldw: agreementInfo.initialPayment.ldw, //
    ldwTax: '0.00', //
    policy: agreementInfo.initialPayment.policy, //
    policyTax: '0.00', //
    extensionAmount: agreementInfo.initialPayment.extensionAmount, //
    extensionAmountTax: '0.00', //
    otherFees: agreementInfo.initialPayment.other, //
    otherFeesTax: '0.00', //
    totalTax: agreementInfo.initialPayment.tax, //
    initialPayment: agreementInfo.initialPayment.initialPayment, //
  };

  const agreementTotalPayment: any = {
    initialPaymentTotal: agreementInfo.totalPayment.initialPayment, //
    regularPaymentsCount: agreementInfo.totalPayment.regularTerm, //
    regularPaymentsAmount: agreementInfo.totalPayment.regularPayment, //
    finalPayment: agreementInfo.totalPayment.finalPayment, //
    totalPaymentsCount: agreementInfo.totalPayment.totalTerm, //
    sacTotal: agreementInfo.totalPayment.cashPrice, //
    epoAmount: agreementInfo.agreementInformation.epoAmount,
  };

  const employerPayschedule: any = {
    schedule: agreementInfo.agreementInformation.paySchedule, //
    daysPaid: agreementInfo.agreementInformation.payDay, //
  };

  const fees: any[] = []; //

  const fee: any = {
    feeAmount: agreementInfo.initialPayment.other,
    feeType: 'OTHER',
  };

  fees.push(fee);

  const popupCompleted: any = {
    vehicleInfoComplete: true, //
    confirmDueDateComplete: true, //
    customerSignComplete:
      agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
    printPopupComplete: agreementInfo.agreementWorkflow.isPrinted == '1', //
    sendAgreementComplete: agreementInfo.agreementWorkflow.isPrinted == '1', //
    autoPayComplete: true, //
    SMSAutheticationComplete:
      agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
    initialPaymentComplete:
      agreementInfo.agreementWorkflow.isInitialPaymentPaid == '1',
  };

  const customerInfo = agreementInfo.agreementCustomer.filter(
    (element) => element.priority == '1'
  );
  const coCustomerInfo = agreementInfo.agreementCustomer.filter(
    (element) => element.priority == '2'
  );
  function getActivePhoneNumber(phoneNumbers: any) {
    for (const phoneNumber of phoneNumbers) {
      switch (phoneNumber.phoneType) {
        case 'CELL':
          return phoneNumber.PhoneNumber;
        case 'HOME':
          return phoneNumber.PhoneNumber;
        case 'REF':
          return phoneNumber.PhoneNumber;
        case 'WORK':
          return phoneNumber.PhoneNumber;
        default:
          return phoneNumber.PhoneNumber

      }
    }
    return undefined;
  }
  const customers: any = {
    customer: customerInfo.map((ele) => {
      const customer: any = {
        priority: ele.priority,
        customerName: ele.firstName + ' ' + ele.lastName,
        firstName: ele.firstName,
        lastName: ele.lastName,
        customerId: ele.customerId,
        emailAddress: ele.emailId,
        ssn: null,
        gcdid: null,
        phoneNumber: ele.phoneNumber,
        phoneNumbers: getActivePhoneNumber(ele.phoneNumbers),
        isDocumentGenerated: ele.isDocumentGenerated,
      };

      return customer;
    }), //
    coCustomer: coCustomerInfo.map((ele) => {
      const coCustomer: any = {
        priority: ele.priority,
        customerName: ele.firstName + ' ' + ele.lastName,
        firstName: ele.firstName,
        lastName: ele.lastName,
        customerId: ele.customerId,
        emailAddress: ele.emailId,
        ssn: null,
        gcdid: null,
        phoneNumber: ele.phoneNumber,
        phoneNumbers: getActivePhoneNumber(ele.phoneNumbers),
        isDocumentGenerated: ele.isDocumentGenerated,
      };

      return coCustomer;
    }), //
  };

  const promoCode = agreementInfo.agreementInformation.promoCode
    ? agreementInfo.agreementInformation.promoCode
    : '';
  const promoName = agreementInfo.agreementInformation.promoName
    ? agreementInfo.agreementInformation.promoName
    : '';

  const agreementOption: any = {
    agreementSource: [
      {
        value: agreementInfo.agreementInformation.agreementSourceRefCode, //
        label: agreementInfo.agreementInformation.agreementSource, //
      },
    ],
    optServices: [
      {
        value: agreementInfo.agreementInformation.coverageTypeRefCode || '', //
        label: agreementInfo.agreementInformation.optServices || 'None', //
      },
    ],
    salesPerson: [
      {
        value: agreementInfo.agreementInformation.salesPersonId.toString(), //
        label: agreementInfo.agreementInformation.salesPerson, //
      },
    ],
    schedule: [
      {
        value: agreementInfo.agreementInformation.scheduleRefCode, //
        label: agreementInfo.agreementInformation.paymentSchedule, //
      },
    ],
    deliveryCharge: [
      {
        value: agreementInfo.agreementInformation.deliveryCharge,
        label: agreementInfo.agreementInformation.deliveryCharge,
      },
    ],
    promoCode: [
      {
        value: promoCode, //
        label: promoName, //
      },
    ],
  };

  const generalInformation: any = {
    agreementType: agreementInfo.agreementInformation.agreementType, //
    agreementSourceValue:
      agreementInfo.agreementInformation.agreementSourceRefCode, //
    agreementStatus: agreementInfo.agreementInformation.agreementStatus, //
    deliveryChargeValue: parseInt(
      agreementInfo.agreementInformation.deliveryCharge
    ).toString(), //
    daysExtensionValue: agreementInfo.agreementInformation.daysExtension, //
    optServicesValue:
      agreementInfo.agreementInformation.coverageTypeRefCode || '', //
    promoCodeValue: promoCode, //
    policyValue:
      agreementInfo.agreementInformation.policy !== 'N/A'
        ? 'RAC Tire Plus'
        : 'None',
    salesPersonValue:
      agreementInfo.agreementInformation.salesPersonId.toString(), //
    isAutopayEnabled: agreementInfo.agreementWorkflow.isAutopayEnabled == '1', //
    isLdwWaived: agreementInfo?.agreementInformation?.isLdwWaived == '1',
  };

  const agreementDetailsContext: any = {
    paymentSchedule,
    agreementSchedule,
    agreementInitialPayment,
    agreementTotalPayment,
    employerPayschedule,
    fees,
    policyApplicable:
      agreementInfo.agreementInformation.policy !== 'N/A' ? 1 : 0,
    popupCompleted,
    agreementId: agreementInfo.agreementInformation.agreementId,
    agreementNumber: agreementInfo.agreementInformation.agreementNumber,
    customers,
    agreementOption,
    generalInformation,
    agreementTotal: agreementInfo.totalPayment.totalCost,
    agreementWorkflow: agreementInfo.agreementWorkflow,
  };
  console.log(
    'Custom Route - agreementDetailsContext',
    agreementDetailsContext
  );
  return agreementDetailsContext;
};
export const SetPendingCOAgreementValue = (agreementInfo: any) => {
  const paymentSchedule: any = {
    weeklyRate: agreementInfo.agreementInformation.agreementWeeklyRate, //
    weeklyTerm: parseInt(
      agreementInfo.agreementInformation.agreementWeeklyTerm
    ), //
    biweeklyRate: agreementInfo.agreementInformation.agreementBiWeeklyRate, //
    biweeklyTerm: parseInt(
      agreementInfo.agreementInformation.agreementBiWeeklyTerm
    ), //
    semimonthlyRate:
      agreementInfo.agreementInformation.agreementSemiMonthlyRate, //
    semimonthlyTerm: parseInt(
      agreementInfo.agreementInformation.agreementSemiMonthlyTerm
    ), //
    monthlyRate: agreementInfo.agreementInformation.agreementMonthlyRate, //
    monthlyTerm: parseInt(
      agreementInfo.agreementInformation.agreementMonthlyTerm
    ), //
  };

  const agreementSchedule: any = {
    schedule: agreementInfo.agreementInformation.scheduleRefCode, //
    dueDate: agreementInfo.agreementInformation.dueDate, //
  };

  const agreementInitialPayment: any = {
    initialRate: agreementInfo.initialPayment.rate, //
    initialRateTax: '0.00', //
    ldw: agreementInfo.initialPayment.ldw, //
    ldwTax: '0.00', //
    policy: agreementInfo.initialPayment.policy, //
    policyTax: '0.00', //
    extensionAmount: agreementInfo.initialPayment.extensionAmount, //
    extensionAmountTax: '0.00', //
    totalFeesAmount: agreementInfo.initialPayment.other, //
    totalFeesAmountTax: '0.00', //
    clubAmount: agreementInfo.initialPayment.clubAmount,
    totalTax: agreementInfo.initialPayment.tax, //
    initialPayment: agreementInfo.initialPayment.initialPayment, //
  };

  const agreementTotalPayment: any = {
    initialPaymentTotal: agreementInfo.totalPayment.initialPayment, //
    regularPaymentsCount: agreementInfo.totalPayment.regularTerm, //
    regularPaymentsAmount: agreementInfo.totalPayment.regularPayment, //
    finalPayment: agreementInfo.totalPayment.finalPayment, //
    totalPayments: agreementInfo.totalPayment.totalTerm, //
    sacTotal: agreementInfo.totalPayment.cashPrice, //
    epoAmount: agreementInfo.agreementInformation.epoAmount,
  };

  const employerPayschedule: any = {
    schedule: agreementInfo.agreementInformation.paySchedule, //
    daysPaid: agreementInfo.agreementInformation.payDay, //
  };

  const fees: any[] = []; //

  const fee: any = {
    feeAmount: agreementInfo.initialPayment.other,
    feeType: 'OTHER',
  };

  fees.push(fee);

  const popupCompleted: any = {
    vehicleInfoComplete: true, //
    confirmDueDateComplete: true, //
    customerSignComplete:
      agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
    printPopupComplete: agreementInfo.agreementWorkflow.isPrinted == '1', //
    sendAgreementComplete: agreementInfo.agreementWorkflow.isPrinted == '1', //
    autoPayComplete: true, //
    SMSAutheticationComplete:
      agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
    initialPaymentComplete:
      agreementInfo.agreementWorkflow.isInitialPaymentPaid == '1',
  };

  const customerInfo = agreementInfo.agreementCustomer.filter(
    (element) => element.priority == '1'
  );
  const coCustomerInfo = agreementInfo.agreementCustomer.filter(
    (element) => element.priority == '2'
  );
  function getActivePhoneNumber(phoneNumbers: any) {
    for (const phoneNumber of phoneNumbers) {
      switch (phoneNumber.phoneType) {
        case 'CELL':
          return phoneNumber.PhoneNumber;
        case 'HOME':
          return phoneNumber.PhoneNumber;
        case 'REF':
          return phoneNumber.PhoneNumber;
        case 'WORK':
          return phoneNumber.PhoneNumber;
        default:
          return phoneNumber.PhoneNumber

      }
    }
    return undefined;
  }
  const customers: any = {
    customer: customerInfo.map((ele) => {
      const customer: any = {
        priority: ele.priority,
        customerName: ele.firstName + ' ' + ele.lastName,
        firstName: ele.firstName,
        lastName: ele.lastName,
        customerId: ele.customerId,
        emailAddress: ele.emailId,
        ssn: null,
        gcdid: null,
        phoneNumber: ele.phoneNumber,
        phoneNumbers: getActivePhoneNumber(ele.phoneNumbers),
        isDocumentGenerated: ele.isDocumentGenerated,
      };

      return customer;
    }), //
    coCustomer: coCustomerInfo.map((ele) => {
      const coCustomer: any = {
        priority: ele.priority,
        customerName: ele.firstName + ' ' + ele.lastName,
        firstName: ele.firstName,
        lastName: ele.lastName,
        customerId: ele.customerId,
        emailAddress: ele.emailId,
        ssn: null,
        gcdid: null,
        phoneNumber: ele.phoneNumber,
        phoneNumbers: getActivePhoneNumber(ele.phoneNumbers),
        isDocumentGenerated: ele.isDocumentGenerated,
      };

      return coCustomer;
    }), //
  };

  const promoCode = agreementInfo.agreementInformation.promoCode
    ? agreementInfo.agreementInformation.promoCode
    : '';
  const promoName = agreementInfo.agreementInformation.promoName
    ? agreementInfo.agreementInformation.promoName
    : '';

  const agreementOption: any = {
    // agreementSource: [
    //   {
    //     value: agreementInfo.agreementInformation.agreementSourceRefCode, //
    //     label: agreementInfo.agreementInformation.agreementSource, //
    //   },
    // ],
    agreementSource: [
      {
        value: 'SPOR', //
        label: 'Customer Order', //
      },
    ],
    optServices: [
      {
        value: agreementInfo.agreementInformation.coverageTypeRefCode || '', //
        label: agreementInfo.agreementInformation.optServices || 'None', //
      },
    ],
    salesPerson: [
      {
        value: agreementInfo.agreementInformation.salesPersonId.toString(), //
        label: agreementInfo.agreementInformation.salesPerson, //
      },
    ],
    schedule: [
      {
        value: agreementInfo.agreementInformation.scheduleRefCode, //
        label: agreementInfo.agreementInformation.paymentSchedule, //
      },
    ],
    deliveryCharge: [
      {
        value: agreementInfo.agreementInformation.deliveryCharge,
        label: agreementInfo.agreementInformation.deliveryCharge,
      },
    ],
    promoCode: [
      {
        value: promoCode, //
        label: promoName, //
      },
    ],
  };

  const generalInformation: any = {
    agreementType: agreementInfo.agreementInformation.agreementType, //
    agreementSourceValue:
      agreementInfo.agreementInformation.agreementSourceRefCode, //
    agreementStatus: agreementInfo.agreementInformation.agreementStatus,
    agreementStatusRefCode: agreementInfo.agreementInformation.agreementStatusRefCode,
    deliveryChargeValue: parseInt(
      agreementInfo.agreementInformation.deliveryCharge
    ).toString(), //
    daysExtensionValue: agreementInfo.agreementInformation.daysExtension, //
    optServicesValue:
      agreementInfo.agreementInformation.coverageTypeRefCode || '', //
    promoCodeValue: promoCode, //
    policyValue:
      agreementInfo.agreementInformation.policy !== 'N/A'
        ? 'RAC Tire Plus'
        : 'None',
    salesPersonValue:
      agreementInfo.agreementInformation.salesPersonId.toString(), //
    isAutopayEnabled: agreementInfo.agreementWorkflow.isAutopayEnabled == '1', //
  };

  const agreementDetailsContext: any = {
    paymentSchedule,
    agreementSchedule,
    agreementInitialPayment,
    agreementTotalPayment,
    employerPayschedule,
    fees,
    policyApplicable:
      agreementInfo.agreementInformation.policy !== 'N/A' ? 1 : 0,
    popupCompleted,
    agreementId: agreementInfo.agreementInformation.agreementId,
    agreementNumber: agreementInfo.agreementInformation.agreementNumber,
    customers,
    agreementOption,
    generalInformation,
    agreementTotal: agreementInfo.totalPayment.totalCost,
    agreementWorkflow: agreementInfo.agreementWorkflow,
  };
  console.log(
    'Custom Route - agreementDetailsContext',
    agreementDetailsContext
  );
  return agreementDetailsContext;
};
const AgreementCustomRoute = (props: any) => {
  const { featureFlagDetails, setFeatureFlagDetails, setSacRolesLimitDetails, setPriceOverrideLimitDetails, setTermOverrideLimitDetails } =
    useContext(FeatureFlagContext);
  const { AgreementInfoRes, setAgreementInfoRes } = props;
  const { setwholeAgrInfoResponse } = useContext(CustomerOrderAgreementContext);
  const { setgetAgrInfoResponse } = useContext(LoanerContext);
  const { redirectOrigin } = useContext(OriginContext);
  let { agreementId } = useParams<Params>();
  const { customerId } = useParams<Params>();
  const StoreId = window.sessionStorage.getItem('storeNumber');
  const location = useLocation();
  if (redirectOrigin === AMOrigin) {
    agreementId = agreementId.replace(AccountManagementURLParam, '');
  }
  const history = useHistory();
  console.log('useParam route', customerId, 'agr', agreementId);
  console.log(location.pathname);
  const classes = agreementGlobalStyles();
  const [routeUpdated, setRouteUpdated] = useState(false);
  const [isNoRecord, setisNoRecord] = useState(false);
  const [agrInfoRawResponse, setagrInfoRawResponse]: any = useState({});
  const [isInternalServerError, setIsInternalServerError] = useState(false);

  const {
    addedItemContext,
    viewAgrDataContext,
    PendingAgreementDetails,
    AgreementCreateStatusContext,
    ItemArrayContext,
    CopyArrayContext,
    searchResultContext,
    setviewAgreementData,
    setviewAgrData,
    setIsInReinstateMode,
    setIsSacDaysCompleted,
    isReinstated,
    setisReinstated,
    setepoAmount,
    setisSOEnabledbyconfig,
    setisChoosePriceConfig,
    gotSOConfigResponse,
    setgotSOConfigResponse,
    setshowProgress,
    setIsAllDone,
    setReturnReasons,
    setamountDueResponse,
    setAgreementInfoData,
    setresumebtnFeatureFlag,
    setitemArrayData,
    setAgrStoreNum,
    setCappedFreeTimeValues
  } = useContext(AgreementContext);
  const {
    selectedInvNumber,
    setselectedInvNumber,
    searchCriteria,
    itemArrayDataSwitchOut,
    numberOfInventory,
    setsearchCriteria,
    setnumberOfInventory,
    switchOutData,
    setSwitchOutData,
    agreementDetailsData,
    setAgreementDetailsData,
    setoldInvRV,
    setentireAgrInfoResponse,
    setsearchResult,
    setagreementCreateStatus,
    setpendingSOAgr,
    setaddedItem,
    setitemArray,
    setSwitchoutcopyArrayData,
    setpendingSOpreInvData,
    setswitchoutagreementnum,
    setautoPayStatus,
  } = useContext(SwitchOutAgreementContext);
  const search = useLocation().search;
  const type = new URLSearchParams(search).get('type');
  const apid = new URLSearchParams(search).get('apid');
  const exchangeAgrId = new URLSearchParams(search).get('exchangeagreementid');
  const returnToExchange = new URLSearchParams(search).get('return');
  const isExchange = new URLSearchParams(search).get('isExchange');
  const [addedItemContextData, setaddedItemContext] = addedItemContext;
  const [getViewAgrData, setgetViewAgrData] = viewAgrDataContext;
  const [PendingAgreementInputs, setPendingAgreementInputs] =
    PendingAgreementDetails;
  const [agreementCreateStatus, setrentalagreementCreateStatus] =
    AgreementCreateStatusContext;
  const [itemArrayData, setrentalitemArrayData] = ItemArrayContext;
  const [copyArrayData, setcopyArrayData] = CopyArrayContext;
  const [searchResult, setrentalsearchResult] = searchResultContext;
  const { setPromoAgrWholeResponse } = useContext(PromoAgreementContext);
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  useEffect(() => {
    const reg = /^\d+$/;
    let agreementInfo: any = undefined;
    console.log('isExchange', isExchange, 'Exchagne Agreement', exchangeAgrId, 'Return To Exchange', returnToExchange);

    const hasCustomerId =
      customerId !== undefined && customerId.match(reg) ? true : false;
    const hasAgreementId =
      agreementId !== undefined && agreementId.match(reg) ? true : false;
    const changeRoutes = async (agrInfo) => {
      if (['VOID', 'ARTS'].includes(agrInfo.agreementInformation.agreementStatusRefCode)) {
        // TODO : VIEW VOID AGREEMENT
        console.log('in change route - TODO : VIEW VOID AGREEMENT');
        if (returnToExchange) {
          return `/agreement/info/details/${customerId}/${agreementId}?return=${returnToExchange}`
        }
        return `/agreement/info/details/${customerId}/${agreementId}`;
      } else if (
        ['PRELIMPAID', 'PRELIMRFND', 'PRELIMINV'].includes(
          agrInfo.agreementInformation.agreementStatusRefCode
        ) &&
        agrInfo.agreementWorkflow.isCOAgreement == '1'
      ) {
        // TODO : Customer Order PoStatus PENDING
        console.log('in change route - TODO : CUSTOMER ORDER PENDING');
        // history.push(`/agreement/info/deliverymethod/${customerId}/${agreementId}`);
        return `/agreement/customerorder/postatus/${customerId}/${agreementId}`;
      }
      else if (
        agrInfo.agreementWorkflow.isCOAgreement == '1' &&
        agrInfo.agreementWorkflow.isDeliveryScheduled == '1'
        // eslint-disable-next-line sonarjs/no-duplicated-branches
      ) {
        // TODO : CustomerOrder info AGREEMENT
        console.log('in change route - TODO : VIEW VOID AGREEMENT');
        if (!agrInfo?.installerInformation) {
          if (returnToExchange) {
            return `/agreement/info/details/${customerId}/${agreementId}?return=${returnToExchange}`;
          }
          return `/agreement/info/details/${customerId}/${agreementId}`;
        }
        else {
          const tbSignedUrl = await finishAgreementDeliveryStatus();
          return `/agreement/info/delivery/${customerId}/${agreementId}?tb=1${tbSignedUrl}`;
        }

      }
      else if (
        ['PRELIM', 'PRELIMCANC'].includes(
          agrInfo.agreementInformation.agreementStatusRefCode
        ) &&
        agrInfo.agreementWorkflow.isPreliminaryPaymentPaid == '0' &&
        agrInfo.agreementWorkflow.isCOAgreement == '1'
      ) {
        // TODO : Customer Order POStatus PENDING
        console.log('in change route - TODO : CUSTOMER ORDER PENDING');
        return `/agreement/customerorder/details/${customerId}/${agreementId}`;
      } else if (
        agrInfo.agreementWorkflow.isCOAgreement == '1' &&
        agrInfo.agreementWorkflow.isInitialPaymentPaid == '1'
      ) {
        // TODO : Customer Order POStatus PENDING
        if (!agrInfo?.installerInformation) {
          return `/agreement/customerorder/delivery/${customerId}/${agreementId}`;
        }
        else {
          const tbSignedUrl = await finishAgreementDeliveryStatus();
          return `/agreement/info/delivery/${customerId}/${agreementId}?tb=1${tbSignedUrl}`;
        }
      } else if (
        agrInfo.agreementWorkflow.isSwitchoutEnabled === '1' &&
        agrInfo.agreementWorkflow.isDeliveryScheduled === '0'
      ) {
        return `/agreement/switchout/delivery/${customerId}/${agreementId}`;
      }
      else if (
        agrInfo.agreementInformation.agreementType == 'Promo' &&
        agrInfo.agreementWorkflow.isPromoInitialPaymentPaid === '0'
      ) {
        return `/agreement/promo/details/${customerId}/${agreementId}`;
      }
      else if (
        agrInfo.agreementInformation.agreementType == 'Promo' &&
        agrInfo.agreementWorkflow.isDeliveryScheduled === '0' &&
        agrInfo.agreementWorkflow.isPromoInitialPaymentPaid === '1'

      ) {
        return `/agreement/promo/delivery/${customerId}/${agreementId}`;
      }
      else if (
        agrInfo.agreementInformation.agreementType == 'Promo' &&
        agrInfo.agreementWorkflow.isDeliveryScheduled === '1' ||
        agrInfo.agreementWorkflow.isDeliveryCompleted === '1'

      ) {
        if (apid) {
          return `/agreement/info/delivery/${customerId}/${agreementId}?apid=${apid}`;
        }
        return `/agreement/info/details/${customerId}/${agreementId}`;
      }

      else if (
        agrInfo.agreementWorkflow.isAgreementSigned === '0' &&
        agrInfo.agreementWorkflow.isInitialPaymentPaid === '0' &&
        agrInfo.agreementWorkflow.isDeliveryScheduled === '0'
      ) {
        // TODO : DOCUMENT CREATION PENDING
        console.log('in change route - TODO : DOCUMENT CREATION PENDING');
        if (exchangeAgrId && isExchange) {
          return `/agreement/rental/details/${customerId}/${agreementId}?isExchange=1&exchangeagreementid=${exchangeAgrId}`
        }
        return `/agreement/rental/details/${customerId}/${agreementId}`;
      }
      else if (
        agrInfo.agreementWorkflow.isInitialPaymentPaid === '0' &&
        ['0', '1'].includes(agrInfo.agreementWorkflow.isDeliveryScheduled)
      ) {
        // TODO : INITIAL PAYMENT PENDING
        console.log('in change route - TODO : INITIAL PAYMENT PENDING');
        if (exchangeAgrId && isExchange) {
          return `/agreement/rental/details/${customerId}/${agreementId}?isExchange=1&exchangeagreementid=${exchangeAgrId}`
        }
        return `/agreement/rental/details/${customerId}/${agreementId}`;
      } else if (agrInfo.agreementWorkflow.isDeliveryScheduled === '0') {
        // TODO : DELIVERY SCHEDULE PENDING
        console.log('in change route - TODO : DELIVERY SCHEDULE PENDING');
        if (returnToExchange) {
          return `/agreement/rental/details/${customerId}/${agreementId}?return=${exchangeAgrId}`
        }
        if (exchangeAgrId && isExchange) {
          return `/agreement/rental/delivery/${customerId}/${agreementId}?isExchange=1&exagreementid=${exchangeAgrId}`
        }
        return `/agreement/rental/delivery/${customerId}/${agreementId}`;
      }

      else {
        // TODO : VIEW AGREEMENT PAGES
        console.log('in change route - TODO : VIEW AGREEMENT');
        console.log(location.pathname);

        if (location.pathname.includes('/agreement/info/deliverymethod')) {
          return `/agreement/info/deliverymethod/${customerId}/${agreementId}`;
        } else if (location.pathname.includes('/agreement/info/activity')) {
          return `/agreement/info/activity/${customerId}/${agreementId}`;
        }
        if (exchangeAgrId && isExchange) {
          return `/agreement/info/details/${customerId}/${agreementId}?isExchange=1&exchangeagreementid=${exchangeAgrId}`
        }
        if (returnToExchange) {
          return `/agreement/info/details/${customerId}/${agreementId}?return=${returnToExchange}`
        }
        return `/agreement/info/details/${customerId}/${agreementId}`;
      }
    };
    const loanerChangeRoutes = (agrInfo) => {
      console.log('agrInfo for loaner', agrInfo);
      if (
        agrInfo.agreementInformation.agreementType !== 'Rent to Rent - Loaner'
      ) {
        return `/agreement/loaner/itemsearch/${customerId}/${agreementId}`;
      }
      //  else if (
      //   agrInfo.agreementInformation.agreementType == 'Rent to Rent - Loaner' &&
      //   agrInfo.agreementWorkflow.isDeliveryScheduled == '0' &&
      //   agrInfo.agreementWorkflow.isDeliveryCompleted == '0' 
      //   // agrInfo.agreementWorkflow.isAgreementSigned == '0'
      // ) {
      //   // TODO : Customer Order POStatus PENDING
      //   console.log('in change route - TODO : CUSTOMER ORDER PENDING');
      //   return `/agreement/loaner/details/${customerId}/${agreementId}`;
      // } 
      else if (
        agrInfo.agreementInformation.agreementType == 'Rent to Rent - Loaner' &&
        agrInfo.agreementWorkflow.isDeliveryScheduled == '0'
        // agrInfo.agreementWorkflow.isAgreementSigned == '1'
      ) {
        return `/agreement/loaner/delivery/${customerId}/${agreementId}`;
      } else if (
        agrInfo.agreementInformation.agreementType == 'Rent to Rent - Loaner' &&
        agrInfo.agreementWorkflow.isDeliveryScheduled == '1'
      ) {
        // TODO : DOCUMENT CREATION PENDING
        console.log('in change route - TODO : DOCUMENT CREATION PENDING');
        return `/agreement/info/details/${customerId}/${agreementId}`;
      } else {
        return `/agreement/loaner/itemsearch/${customerId}/${agreementId}`;
      }
    };
    const LoanerPendingItemSearch = (agreementInfo) => {
      if (agreementInfo) {
        const filteredData = agreementInfo?.agreementInventories
        console.log('filteredData', filteredData);

        const itemPricing: any = filteredData?.map((value: any) => {
          const individualArray: any = {
            inventoryName: value?.description, //
            inventoryId: value?.inventoryId, //
            inventoryNumber: value?.inventoryNumber, //
            rmsItemNumber: value?.rmsItemNumber, //
            weeklyRate: value?.tagRate, //
            weeklyTerm: value?.tagTerm, //
            cashPrice: value?.cashPrice, //
            remainingValue: value?.remainingValue, //
            tagTotal: value?.tagTotal, //
            inventoryDepreciation: value?.inventoryDepreciation, //
            agreementRate: value?.agreementRate, //
            agreementTotal: value?.agreementTotal, //
            packageName:
              agreementInfo?.agreementInformation?.agreementDescription, //
          };

          return individualArray;
        });
        console.log('itemPricing check value', itemPricing);
        const ItemPricingContext: any = {
          itemPricing: itemPricing,
          agreementPricing: {
            tagRate: agreementInfo?.agreementTotal?.tagRate, //
            tagTerm: agreementInfo?.agreementTotal?.tagTerm, //
            tagTotal: agreementInfo?.agreementTotal.tagTotal, //
            agreementRate: agreementInfo?.agreementTotal?.agreementRate, //
            agreementTerm: agreementInfo?.agreementTotal?.agreementTerm, //
            cashPrice: agreementInfo?.totalPayment?.cashPrice, //
            agreementTotal: agreementInfo?.agreementTotal?.agreementTotal, //
            agreementInventoryDepreciation:
              agreementInfo?.agreementTotal?.agreementInventoryDepreciation, //
          },
          isPackage: false, //
          packageInformation: null, //
        };
        setaddedItemContext(ItemPricingContext)
        const rmsItemInformation =
          agreementInfo?.agreementInventories?.map((el: any) => {
            return {
              modelNumber: el.modelNumber,
              brand: el?.brand == undefined ? '-' : el?.brand,
              cashPrice: agreementInfo.totalPayment.cashPrice,
              department: el.department,
              subdepartment: el.subDepartment,
              monthlyRate: el.agreementRate,
              monthlyTerm: el.agreementTerm,
              totalCost: el.agreementTotal,
              availability: null,
            };
          });
        const rmsItemObj = {
          rmsItemInformation: rmsItemInformation,
        };
        setrentalsearchResult(rmsItemObj);
        // const location: any = useLocation();
        // let selectedInvFromHistory = '';
        // if (location?.state?.selectedInventoryNo !== undefined) {
        //   selectedInvFromHistory = location?.state?.selectedInventoryNo;
        // }
        // const getItemArray = agreementInfo.agreementInventories.filter(
        //   (obj: any) => {
        //     return (
        //       parseInt(obj.inventoryNumber) !==
        //       parseInt(
        //         selectedInvNumber !== undefined
        //           ? selectedInvNumber
        //           : null
        //       )
        //     );
        //   }
        // );
        // console.log('getItemArray', getItemArray);
        const tempItemArray: any = agreementInfo?.agreementInventories?.map((obj: any) => {
          return {
            inventoryId: Number(obj.inventoryId),
            inventoryPriceId: Number(obj.inventoryPriceId),
          };
        });
        setitemArrayData(tempItemArray);
        setcopyArrayData([])
        setrentalagreementCreateStatus({ agreementCreatedStatus: true });
      }

    }

    const pageLoad = async () => {
      const agrInfoResponse = await getAgreementInfo(agreementId);
      setagrInfoRawResponse(agrInfoResponse);
      console.log('agreement info call ', agrInfoResponse);
      if (agrInfoResponse.status === 200) {
        agreementInfo = agrInfoResponse.data;
        setAgrStoreNum(agrInfoResponse?.data?.agreementInformation?.storeNumber)
        console.log('agreement info data', agreementInfo);
        setAgreementInfoData(agreementInfo);
        setgetAgrInfoResponse(agreementInfo);
        let updatedRoute: any = '';
        if (
          agreementInfo.agreementInformation.agreementType !==
          'Rent to Rent - Loaner'
        ) {
          updatedRoute = await changeRoutes(agreementInfo);

          if (updatedRoute.includes('/agreement/rental/')) {
            // Set Context
            // Start of item search context set
            setIsSacDaysCompleted(
              agreementInfo.agreementWorkflow.isSacDaysCompleted
            );
            setisReinstated(agreementInfo.agreementWorkflow.isReinstated);
            setIsInReinstateMode(agreementInfo.agreementWorkflow.isReinstated);
            const itemPricing: any = agreementInfo.agreementInventories.map(
              (value: any) => {
                console.log('response.itemPricing something', value);

                const individualArray: any = {
                  inventoryName: value.description, //
                  inventoryId: value.inventoryId, //
                  inventoryNumber: value.inventoryNumber, //
                  rmsItemNumber: value.rmsItemNumber, //
                  weeklyRate: value.tagRate, //
                  weeklyTerm: value.tagTerm, //
                  cashPrice: value.cashPrice, //
                  remainingValue: value.remainingValue, //
                  tagTotal: value.tagTotal, //
                  inventoryDepreciation: value.inventoryDepreciation, //
                  agreementRate: value.agreementRate, //
                  agreementTotal: value.agreementTotal, //
                  packageName:
                    agreementInfo.agreementInformation.packageName == null
                      ? ''
                      : agreementInfo.agreementInformation.packageName, //
                };

                return individualArray;
              }
            );

            const ItemPricingContext: any = {
              itemPricing: itemPricing,
              agreementPricing: {
                tagRate: agreementInfo.agreementTotal.tagRate, //
                tagTerm: agreementInfo.agreementTotal.tagTerm, //
                tagTotal: agreementInfo.agreementTotal.tagTotal, //
                agreementRate: agreementInfo.agreementTotal.agreementRate, //
                agreementTerm: agreementInfo.agreementTotal.agreementTerm, //
                cashPrice: agreementInfo.totalPayment.cashPrice, //
                epoAmount: agreementInfo.agreementInformation.epoAmount, //
                agreementTotal: agreementInfo.agreementTotal.agreementTotal, //
                agreementInventoryDepreciation:
                  agreementInfo.agreementTotal.agreementInventoryDepreciation, //
              },
              isPackage: false, //
              packageInformation: null, //
            };

            const agreementCreateStatus: any = {
              agreementCreatedStatus: true,
            };

            console.log(
              'Custom Route - ItemPricingContext',
              ItemPricingContext
            );
            console.log(
              'Custom Route - agreementCreateStatus',
              agreementCreateStatus
            );

            setaddedItemContext(ItemPricingContext);
            setshowProgress(true);
            setIsAllDone(true);
            setrentalagreementCreateStatus(agreementCreateStatus);
            setrentalitemArrayData([]);
            setcopyArrayData([]);
            setrentalsearchResult([]);

            // End of item search context set

            // Start of Agreement Details context set
            const FormatResponse = SetPendingAgreementValue(agreementInfo);
            console.log('FormatResponse', FormatResponse);
            setPendingAgreementInputs(FormatResponse);
            setgetViewAgrData(agrInfoResponse);
            setviewAgrData(agrInfoResponse);
            // End on Agreement Details context set
            // End of context set
          } else if (updatedRoute.includes('/agreement/info')) {
            setgetViewAgrData(agrInfoResponse);
            setviewAgrData(agrInfoResponse);
            setviewAgreementData(agrInfoResponse.data);
            setepoAmount(agreementInfo.agreementInformation.epoAmount);
            setIsSacDaysCompleted(
              agreementInfo.agreementWorkflow.isSacDaysCompleted
            );
            setisReinstated(agreementInfo.agreementWorkflow.isReinstated);
            setIsInReinstateMode(agreementInfo.agreementWorkflow.isReinstated);
          } else if (updatedRoute.includes('/agreement/switchout')) {
            {
              const agreementInfo = agrInfoResponse.data;
              setpendingSOAgr(true);
              setshowProgress(true);
              setIsAllDone(true);
              setagreementCreateStatus({ agreementCreatedStatus: true });
              setentireAgrInfoResponse(agreementInfo);
              setswitchoutagreementnum(
                agreementInfo.agreementWorkflow.agreementNumber
              );
              setviewAgrData(agrInfoResponse);
              console.log('switch out agreement info data', agreementInfo);
              // Set Context
              // Start of item search context set
              const filteredData = agreementInfo.agreementInventories.filter(
                function (element) {
                  console.log('filterer object', element);

                  return element.isItemSwitchedIn == '1';
                }
              );
              console.log('filteredData', filteredData);

              const itemPricing: any = filteredData.map((value: any) => {
                console.log('response.itemPricing something', value);

                const individualArray: any = {
                  inventoryName: value.description, //
                  inventoryId: value.inventoryId, //
                  inventoryNumber: value.inventoryNumber, //
                  rmsItemNumber: value.rmsItemNumber, //
                  weeklyRate: value.tagRate, //
                  weeklyTerm: value.tagTerm, //
                  cashPrice: value.cashPrice, //
                  remainingValue: value.remainingValue, //
                  tagTotal: value.tagTotal, //
                  inventoryDepreciation: value.inventoryDepreciation, //
                  agreementRate: value.agreementRate, //
                  agreementTotal: value.agreementTotal, //
                  packageName:
                    agreementInfo.agreementInformation.agreementDescription, //
                };

                return individualArray;
              });
              console.log('itemPricing check value', itemPricing);
              const ItemPricingContext: any = {
                itemPricing: itemPricing,
                agreementPricing: {
                  tagRate: agreementInfo.agreementTotal.tagRate, //
                  tagTerm: agreementInfo.agreementTotal.tagTerm, //
                  tagTotal: agreementInfo.agreementTotal.tagTotal, //
                  agreementRate: agreementInfo.agreementTotal.agreementRate, //
                  agreementTerm: agreementInfo.agreementTotal.agreementTerm, //
                  cashPrice: agreementInfo.totalPayment.cashPrice, //
                  agreementTotal: agreementInfo.agreementTotal.agreementTotal, //
                  agreementInventoryDepreciation:
                    agreementInfo.agreementTotal.agreementInventoryDepreciation, //
                },
                isPackage: false, //
                packageInformation: null, //
              };
              const prevInvValues = agreementInfo.agreementInventories.filter(
                function (element) {
                  console.log('filterer object', element);

                  return element.inventoryStatusRefcode == 'PSO';
                }
              );
              console.log('prevInvValues', prevInvValues);
              setpendingSOpreInvData(prevInvValues);

              const agreementCreateStatus: any = {
                agreementCreatedStatus: true,
              };
              setaddedItem(ItemPricingContext);
              setsearchResult([]);
              setitemArray([]);
              setSwitchoutcopyArrayData([]);
              console.log(
                'switch out Custom Route - ItemPricingContext',
                ItemPricingContext
              );
              console.log(
                'switch out Custom Route - agreementCreateStatus',
                agreementCreateStatus
              );
              setautoPayStatus(
                agreementInfo.agreementWorkflow.isAutopayEnabled
              );
              setSwitchOutData(ItemPricingContext);
              // End of item search context set

              // Start of Agreement Details context set

              const paymentSchedule: any = {
                weeklyRate:
                  agreementInfo.agreementInformation.agreementWeeklyRate, //
                weeklyTerm: parseInt(
                  agreementInfo.agreementInformation.agreementWeeklyTerm
                ), //
                biweeklyRate:
                  agreementInfo.agreementInformation.agreementBiWeeklyRate, //
                biweeklyTerm: parseInt(
                  agreementInfo.agreementInformation.agreementBiWeeklyTerm
                ), //
                semimonthlyRate:
                  agreementInfo.agreementInformation.agreementSemiMonthlyRate, //
                semimonthlyTerm: parseInt(
                  agreementInfo.agreementInformation.agreementSemiMonthlyTerm
                ), //
                monthlyRate:
                  agreementInfo.agreementInformation.agreementMonthlyRate, //
                monthlyTerm: parseInt(
                  agreementInfo.agreementInformation.agreementMonthlyTerm
                ), //
              };

              const agreementSchedule: any = {
                schedule: agreementInfo.agreementInformation.scheduleRefCode, //
                dueDate: agreementInfo.agreementInformation.dueDate, //
              };

              const agreementInitialPayment: any = {
                rate: agreementInfo.initialPayment.rate, //
                initialRateTax: '0.00', //
                ldw: agreementInfo.initialPayment.ldw, //
                ldwTax: '0.00', //
                policy: agreementInfo.initialPayment.policy, //
                policyTax: '0.00', //
                extensionAmount: agreementInfo.initialPayment.extensionAmount, //
                extensionAmountTax: '0.00', //
                otherFees: agreementInfo.initialPayment.other, //
                otherFeesTax: '0.00', //
                totalTax: agreementInfo.initialPayment.tax, //
                initialPayment: agreementInfo.initialPayment.initialPayment, //
              };

              const agreementTotalPayment: any = {
                initialPaymentTotal: agreementInfo.totalPayment.initialPayment, //
                regularPaymentsCount: agreementInfo.totalPayment.regularTerm, //
                regularPaymentsAmount:
                  agreementInfo.totalPayment.regularPayment, //
                finalPayment: agreementInfo.totalPayment.finalPayment, //
                totalPaymentsCount: agreementInfo.totalPayment.totalTerm, //
                sacTotal: agreementInfo.totalPayment.cashPrice, //
                epoAmount: agreementInfo.agreementInformation.epoAmount,
                agreementTotal: agreementInfo.totalPayment.totalCost,
              };

              const employerPayschedule: any = {
                schedule: agreementInfo.agreementInformation.paySchedule, //
                daysPaid: agreementInfo.agreementInformation.payDay, //
              };

              const fees: any[] = []; //

              const fee: any = {
                feeAmount: agreementInfo.initialPayment.other,
                feeType: 'OTHER',
              };

              fees.push(fee);

              const popupCompleted: any = {
                vehicleInfoComplete: true, //
                confirmDueDateComplete: true, //
                customerSignComplete:
                  agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
                printPopupComplete:
                  agreementInfo.agreementWorkflow.isPrinted == '1', //
                sendAgreementComplete:
                  agreementInfo.agreementWorkflow.isPrinted == '1', //
                autoPayComplete: true, //
                SMSAutheticationComplete:
                  agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
                initialPaymentComplete:
                  agreementInfo.agreementWorkflow.isInitialPaymentPaid == '1',
              };

              const customerInfo = agreementInfo.agreementCustomer.filter(
                (element) => element.priority == '1'
              );
              const coCustomerInfo = agreementInfo.agreementCustomer.filter(
                (element) => element.priority == '2'
              );

              const customers: any = {
                customer: customerInfo.map((ele) => {
                  const customer: any = {
                    priority: ele.priority,
                    customerName: ele.firstName + ' ' + ele.lastName,
                    firstName: ele.firstName,
                    lastName: ele.lastName,
                    customerId: ele.customerId,
                    emailAddress: ele.emailId,
                    ssn: null,
                    gcdid: null,
                    phoneNumber: ele.phoneNumber,
                    isDocumentGenerated: ele.isDocumentGenerated,
                  };

                  return customer;
                }), //
                coCustomer: coCustomerInfo.map((ele) => {
                  const coCustomer: any = {
                    priority: ele.priority,
                    customerName: ele.firstName + ' ' + ele.lastName,
                    firstName: ele.firstName,
                    lastName: ele.lastName,
                    customerId: ele.customerId,
                    emailAddress: ele.emailId,
                    ssn: null,
                    gcdid: null,
                    phoneNumber: ele.phoneNumber,
                    isDocumentGenerated: ele.isDocumentGenerated,
                  };

                  return coCustomer;
                }), //
              };

              const promoCode = agreementInfo.agreementInformation.promoCode
                ? agreementInfo.agreementInformation.promoCode
                : '';
              const promoName = agreementInfo.agreementInformation.promoName
                ? agreementInfo.agreementInformation.promoName
                : '';

              const agreementOption: any = {
                agreementSource: [
                  {
                    value:
                      agreementInfo.agreementInformation.agreementSourceRefCode, //
                    label: agreementInfo.agreementInformation.agreementSource, //
                  },
                ],
                optServices: [
                  {
                    value:
                      agreementInfo.agreementInformation.coverageTypeRefCode ||
                      '', //
                    label:
                      agreementInfo.agreementInformation.optServices || 'None', //
                  },
                ],
                salesPerson: [
                  {
                    value:
                      agreementInfo.agreementInformation.salesPersonId.toString(), //
                    label: agreementInfo.agreementInformation.salesPerson, //
                  },
                ],
                schedule: [
                  {
                    value: agreementInfo.agreementInformation.scheduleRefCode, //
                    label: agreementInfo.agreementInformation.paymentSchedule, //
                  },
                ],
                deliveryCharge: [
                  {
                    value: agreementInfo.agreementInformation.deliveryCharge,
                    label: agreementInfo.agreementInformation.deliveryCharge,
                  },
                ],
                promoCode: [
                  {
                    value: promoCode, //
                    label: promoName != '' ? promoName : 'Select', //
                  },
                ],
                daysExtension: [
                  {
                    value: agreementInfo.agreementInformation.daysExtension,
                    label: agreementInfo.agreementInformation.daysExtension,
                  },
                ],
              };

              const generalInformation: any = {
                agreementType: agreementInfo.agreementInformation.agreementType, //
                agreementSourceValue:
                  agreementInfo.agreementInformation.agreementSourceRefCode, //
                agreementStatus:
                  agreementInfo.agreementInformation.agreementStatus,
                agreementStatusRefCode:
                  agreementInfo.agreementInformation.agreementStatusRefCode, //
                deliveryChargeValue:
                  agreementInfo.agreementInformation.deliveryCharge, //
                daysExtensionValue:
                  agreementInfo.agreementInformation.daysExtension, //
                optServicesValue:
                  agreementInfo.agreementInformation.coverageTypeRefCode || '', //
                promoCodeValue: promoCode, //
                policyValue:
                  agreementInfo.agreementInformation.policy !== 'N/A'
                    ? 'RAC Tire Plus'
                    : 'None',
                salesPersonValue:
                  agreementInfo.agreementInformation.salesPersonId.toString(), //
                isAutopayEnabled:
                  agreementInfo.agreementWorkflow.isAutopayEnabled == '1', //
                isLdwWaived:
                  agreementInfo?.agreementInformation?.isLdwWaived == '1',
              };

              const agreementDetailsContext: any = {
                paymentSchedule,
                agreementSchedule,
                agreementInitialPayment,
                agreementTotalPayment,
                employerPayschedule,
                fees,
                policyApplicable:
                  agreementInfo.agreementInformation.policy !== 'N/A' ? 1 : 0,
                popupCompleted,
                agreementId: agreementInfo.agreementInformation.agreementId,
                agreementNumber:
                  agreementInfo.agreementInformation.agreementNumber,
                customers,
                agreementOption,
                generalInformation,
                agreementTotal: agreementInfo.totalPayment.totalCost,
                agreementWorkflow: agreementInfo.agreementWorkflow,
              };

              console.log(
                'Custom Route - agreementDetailsContext',
                agreementDetailsContext
              );

              setAgreementDetailsData(agreementDetailsContext);
            }
            setAgreementInfoRes(agreementInfo);
          } else if (updatedRoute.includes('/agreement/customerorder')) {
            setgetViewAgrData(agrInfoResponse);
            setviewAgrData(agrInfoResponse);
            setviewAgreementData(agrInfoResponse.data);
            const FormatResponse = SetPendingCOAgreementValue(agreementInfo);
            console.log('FormatResponse', FormatResponse);
            const rmsItemInformation =
              agrInfoResponse.data.agreementInventories.map((el: any) => {
                return {
                  modelNumber: el.modelNumber,
                  brand: el?.brand == undefined ? '-' : el?.brand,
                  cashPrice: agrInfoResponse.data.totalPayment.cashPrice,
                  department: el.department,
                  subdepartment: el.subDepartment,
                  monthlyRate: el.agreementRate,
                  monthlyTerm: el.agreementTerm,
                  totalCost: el.agreementTotal,
                  availability: null,
                };
              });
            const rmsItemObj = {
              rmsItemInformation: rmsItemInformation,
            };
            setagreementCreateStatus({ agreementCreatedStatus: true });
            setsearchResult(rmsItemObj);
            setIsAllDone(true);
            setwholeAgrInfoResponse(FormatResponse);
          }
          else if (updatedRoute.includes('/agreement/promo')) {
            setPromoAgrWholeResponse(agreementInfo)
            const itemPricing: any = agreementInfo.agreementInventories.map(
              (value: any) => {
                console.log('response.itemPricing something', value);

                const individualArray: any = {
                  inventoryName: value.description, //
                  inventoryId: value.inventoryId, //
                  inventoryNumber: value.inventoryNumber, //
                  rmsItemNumber: value.rmsItemNumber, //
                  weeklyRate: value.tagRate, //
                  weeklyTerm: value.tagTerm, //
                  cashPrice: value.cashPrice, //
                  remainingValue: value.remainingValue, //
                  tagTotal: value.tagTotal, //
                  inventoryDepreciation: value.inventoryDepreciation, //
                  agreementRate: value.agreementRate, //
                  agreementTotal: value.agreementTotal, //
                  packageName:
                    agreementInfo.agreementInformation.packageName == null
                      ? ''
                      : agreementInfo.agreementInformation.packageName, //
                };

                return individualArray;
              }
            );

            const ItemPricingContext: any = {
              itemPricing: itemPricing,
              agreementPricing: {
                tagRate: agreementInfo.agreementTotal.tagRate, //
                tagTerm: agreementInfo.agreementTotal.tagTerm, //
                tagTotal: agreementInfo.agreementTotal.tagTotal, //
                agreementRate: agreementInfo.agreementTotal.agreementRate, //
                agreementTerm: agreementInfo.agreementTotal.agreementTerm, //
                cashPrice: agreementInfo.totalPayment.cashPrice, //
                epoAmount: agreementInfo.agreementInformation.epoAmount, //
                agreementTotal: agreementInfo.agreementTotal.agreementTotal, //
                agreementInventoryDepreciation:
                  agreementInfo.agreementTotal.agreementInventoryDepreciation, //
              },
              isPackage: false, //
              packageInformation: null, //
            };

            const agreementCreateStatus: any = {
              agreementCreatedStatus: true,
            };

            console.log(
              'Custom Route - ItemPricingContext',
              ItemPricingContext
            );
            console.log(
              'Custom Route - agreementCreateStatus',
              agreementCreateStatus
            );

            setaddedItemContext(ItemPricingContext);
            setshowProgress(true);
            setIsAllDone(true);
            setrentalagreementCreateStatus(agreementCreateStatus);
            setrentalitemArrayData([]);
            setcopyArrayData([]);
            setrentalsearchResult([]);

            // End of item search context set

            // Start of Agreement Details context set
            const FormatResponse = SetPendingAgreementValue(agreementInfo);
            console.log('FormatResponse', FormatResponse);
            setPendingAgreementInputs(FormatResponse);
            setgetViewAgrData(agrInfoResponse);
            setviewAgrData(agrInfoResponse);
            // End on Agreement Details context set
            // End of context set
          }
        } else {
          const LoanerPendingItemSearch1 = await LoanerPendingItemSearch(agreementInfo);
          updatedRoute = await loanerChangeRoutes(agreementInfo);
        }

        console.log('location.pathname -> ', location.pathname);
        console.log('updatedRoute -> ', updatedRoute);

        if (location.pathname !== updatedRoute) {
          history.push(updatedRoute);

          // communicate to Routes that URL has changed
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
        }
        debugger;
        setRouteUpdated(true);
      } else if (agrInfoResponse.status === 400) {
        setisNoRecord(true);
      } else if (agrInfoResponse.status === 500) {
        setIsInternalServerError(true);
      } else {
        // TODO : FALLBACK
        console.log('route error');
      }
    };
    const finishAgreementDeliveryStatus = async () => {
      const apiResponse: any = await CheckSign(agreementId);
      console.log('check sign api response', apiResponse);
      if (apiResponse?.isSigned == '1') {
        const updatedeliverystatus = {
          agreementId: agreementId,
        };
        const updatesign = {
          agreementId: agreementId,
          value: 1
        };

        await Confirmdelivery(updatedeliverystatus);
        await Recieptsign(updatesign);
        return '&tbsigned=1';
      }
      else {
        return '&tbsigned=0'
      }

    }
    const PageReload = async () => {
      console.log('PageReload triggered');
      const AgreementInfo = new Promise(() => {
        const GetResponse = async () => {
          const agrInfoResponse = await getAgreementInfo(agreementId);
          setagrInfoRawResponse(agrInfoResponse);
          console.log('agreement info call ', agrInfoResponse);
          if (agrInfoResponse.status === 200) {
            agreementInfo = agrInfoResponse.data;
            setAgreementInfoData(agreementInfo);
            console.log('agreement info data', agreementInfo);

            const updatedRoute: any = await changeRoutes(agreementInfo);

            if (updatedRoute.includes('/agreement/rental/')) {
              // Set Context
              // Start of item search context set
              // isSacDaysCompleted
              setIsSacDaysCompleted(
                agreementInfo.agreementWorkflow.isSacDaysCompleted
              );
              setisReinstated(agreementInfo.agreementWorkflow.isReinstated);
              setIsInReinstateMode(
                agreementInfo.agreementWorkflow.isReinstated
              );
              const itemPricing: any = agreementInfo.agreementInventories.map(
                (value: any) => {
                  console.log('response.itemPricing something', value);

                  const individualArray: any = {
                    inventoryName: value.description, //
                    inventoryId: value.inventoryId, //
                    inventoryNumber: value.inventoryNumber, //
                    rmsItemNumber: value.rmsItemNumber, /////////////////////
                    weeklyRate: value.tagRate, //
                    weeklyTerm: value.tagTerm, //
                    cashPrice: value.cashPrice, //
                    remainingValue: value.remainingValue, //
                    tagTotal: value.tagTotal, //
                    inventoryDepreciation: value.inventoryDepreciation, //
                    agreementRate: value.agreementRate, //
                    agreementTotal: value.agreementTotal, //
                    packageName:
                      agreementInfo.agreementInformation.agreementDescription, //
                  };

                  return individualArray;
                }
              );

              const ItemPricingContext: any = {
                itemPricing: itemPricing,
                agreementPricing: {
                  tagRate: agreementInfo.agreementTotal.tagRate, //
                  tagTerm: agreementInfo.agreementTotal.tagTerm, //
                  tagTotal: agreementInfo.agreementTotal.tagTotal, //
                  agreementRate: agreementInfo.agreementTotal.agreementRate, //
                  agreementTerm: agreementInfo.agreementTotal.agreementTerm, //
                  cashPrice: agreementInfo.totalPayment.cashPrice, //
                  epoAmount: agreementInfo.agreementInformation.epoAmount, //
                  agreementTotal: agreementInfo.agreementTotal.agreementTotal, //
                  agreementInventoryDepreciation:
                    agreementInfo.agreementTotal.agreementInventoryDepreciation, //
                },
                isPackage: false, //
                packageInformation: null, //
              };

              const agreementCreateStatus: any = {
                agreementCreatedStatus: true,
              };

              console.log(
                'Custom Route - ItemPricingContext',
                ItemPricingContext
              );
              console.log(
                'Custom Route - agreementCreateStatus',
                agreementCreateStatus
              );

              setaddedItemContext(ItemPricingContext);
              setshowProgress(true);
              setIsAllDone(true);
              setrentalagreementCreateStatus(agreementCreateStatus);
              setrentalitemArrayData([]);
              setcopyArrayData([]);
              setrentalsearchResult([]);

              // End of item search context set

              // Start of Agreement Details context set
              const FormatResponse = SetPendingAgreementValue(agreementInfo);
              console.log('FormatResponse', FormatResponse);
              setPendingAgreementInputs(FormatResponse);
              setgetViewAgrData(agrInfoResponse);
              setviewAgrData(agrInfoResponse);
              // setviewAgreementData(agrInfoResponse.data);
              // End on Agreement Details context set
              // End of context set
            } else if (updatedRoute.includes('/agreement/info')) {
              setgetViewAgrData(agrInfoResponse);
              setviewAgrData(agrInfoResponse);
              setviewAgreementData(agrInfoResponse.data);
              setepoAmount(agreementInfo.agreementInformation.epoAmount);
              setIsSacDaysCompleted(
                agreementInfo.agreementWorkflow.isSacDaysCompleted
              );
              setisReinstated(agreementInfo.agreementWorkflow.isReinstated);
              setIsInReinstateMode(
                agreementInfo.agreementWorkflow.isReinstated
              );
            } else if (updatedRoute.includes('/agreement/switchout')) {
              {
                const agreementInfo = agrInfoResponse.data;
                setpendingSOAgr(true);
                setshowProgress(true);
                setIsAllDone(true);
                setagreementCreateStatus({ agreementCreatedStatus: true });
                setentireAgrInfoResponse(agreementInfo);
                setswitchoutagreementnum(
                  agreementInfo.agreementWorkflow.agreementNumber
                );
                setviewAgrData(agrInfoResponse);
                console.log('switch out agreement info data', agreementInfo);
                // Set Context
                // Start of item search context set
                // setnumberOfInventory(agreementInfo.agreementInventories.length);
                //const switchedItem: any = window.sessionStorage.getItem('SOinvNumber');
                //console.log('switchedItem data', switchedItem);
                // const itemPricing: any = [];
                const filteredData = agreementInfo.agreementInventories.filter(
                  function (element) {
                    console.log('filterer object', element);

                    return element.isItemSwitchedIn == '1';
                  }
                );
                console.log('filteredData', filteredData);

                const itemPricing: any = filteredData.map((value: any) => {
                  console.log('response.itemPricing something', value);

                  const individualArray: any = {
                    inventoryName: value.description, //
                    inventoryId: value.inventoryId, //
                    inventoryNumber: value.inventoryNumber, //
                    rmsItemNumber: value.rmsItemNumber, /////////////////////
                    weeklyRate: value.tagRate, //
                    weeklyTerm: value.tagTerm, //
                    cashPrice: value.cashPrice, //
                    remainingValue: value.remainingValue, //
                    tagTotal: value.tagTotal, //
                    inventoryDepreciation: value.inventoryDepreciation, //
                    agreementRate: value.agreementRate, //
                    agreementTotal: value.agreementTotal, //
                    packageName:
                      agreementInfo.agreementInformation.agreementDescription, //
                  };

                  return individualArray;
                });
                // filteredData.map((value: any) => {
                //   console.log('response.itemPricing something', value);
                //   // if (
                //   //   parseInt(value.inventoryNumber) == parseInt(selectedInvNumber)
                //   // ) {
                //   console.log('reached the if of switch');
                //   // console.log(
                //   //   'parseInt(value.inventoryNumber)',
                //   //   parseInt(value.inventoryNumber)
                //   // );
                //   // console.log(
                //   //   'parseInt(switchedItem)',
                //   //   parseInt(selectedInvNumber)
                //   // );

                //   const individualArray: any = {
                //     inventoryName: value.description, //
                //     inventoryId: value.inventoryId, //
                //     inventoryNumber: value.inventoryNumber, //
                //     rmsItemNumber: value.rmsItemNumber,
                //     weeklyRate: value.tagRate, //
                //     weeklyTerm: value.tagTerm, //
                //     cashPrice: value.cashPrice, //
                //     remainingValue: value.remainingValue, //
                //     tagTotal: value.tagTotal, //
                //     inventoryDepreciation: value.inventoryDepreciation, //
                //     agreementRate: value.agreementRate, //
                //     agreementTotal: value.agreementTotal, //
                //     packageName:
                //       agreementInfo.agreementInformation.agreementDescription, //
                //   };
                //   itemPricing.push(individualArray);
                //   // }
                // });
                // let calculatedRV = 0;
                // agreementInfo.agreementInventories.map((value: any) => {
                //   console.log(
                //     'parseInt(value.inventoryNumber) in RV map',
                //     parseInt(value.inventoryNumber)
                //   );
                //   console.log(
                //     'parseInt(switchedItem) in Rv map',
                //     parseInt(selectedInvNumber)
                //   );

                //   if (
                //     parseInt(value.inventoryNumber) !== parseInt(selectedInvNumber)
                //   ) {
                //     calculatedRV = calculatedRV + Number(value.remainingValue);
                //   }
                // });
                // console.log(
                //   'calculatedRV in the route for the reinstate infinity',
                //   calculatedRV
                // );

                // setoldInvRV(calculatedRV);
                // console.log('final rv calculated value', calculatedRV);
                console.log('itemPricing check value', itemPricing);

                // console.log('calculatedRV in route', calculatedRV);

                const ItemPricingContext: any = {
                  itemPricing: itemPricing,
                  agreementPricing: {
                    tagRate: agreementInfo.agreementTotal.tagRate, //
                    tagTerm: agreementInfo.agreementTotal.tagTerm, //
                    tagTotal: agreementInfo.agreementTotal.tagTotal, //
                    agreementRate: agreementInfo.agreementTotal.agreementRate, //
                    agreementTerm: agreementInfo.agreementTotal.agreementTerm, //
                    cashPrice: agreementInfo.totalPayment.cashPrice, //
                    agreementTotal: agreementInfo.agreementTotal.agreementTotal, //
                    agreementInventoryDepreciation:
                      agreementInfo.agreementTotal
                        .agreementInventoryDepreciation, //
                  },
                  isPackage: false, //
                  packageInformation: null, //
                };
                const prevInvValues = agreementInfo.agreementInventories.filter(
                  function (element) {
                    console.log('filterer object', element);

                    return element.inventoryStatusRefcode == 'PSO';
                  }
                );
                console.log('prevInvValues', prevInvValues);
                setpendingSOpreInvData(prevInvValues);
                // const getSearchCriteria = agreementInfo.agreementInventories.filter(
                //   (obj: any) => {
                //     return (
                //       parseInt(obj.inventoryNumber) == parseInt(selectedInvNumber)
                //     );
                //   }
                // );
                // console.log('getSearchCriteria', getSearchCriteria);
                // console.log(
                //   ' getSearchCriteria[0].department',
                //   getSearchCriteria[0].department
                // );
                // const getItemArray = agreementInfo.agreementInventories.filter(
                //   (obj: any) => {
                //     return (
                //       parseInt(obj.inventoryNumber) !== parseInt(selectedInvNumber)
                //     );
                //   }
                // );
                // console.log('getItemArray', getItemArray);
                // const tempItemArray = getItemArray.map((obj: any) => {
                //   return parseInt(obj.inventoryId);
                // });
                // console.log('tempItemArray', tempItemArray);
                // setitemArrayData(tempItemArray);
                // setsearchCriteria({
                //   ...searchCriteria,
                //   departments: getSearchCriteria[0].department,
                //   packageTypeval:
                //     agreementInfo.agreementInventories.length > 1
                //       ? agreementInfo.agreementInformation.agreementDescription
                //       : '',
                // });

                const agreementCreateStatus: any = {
                  agreementCreatedStatus: true,
                };
                setaddedItem(ItemPricingContext);
                setsearchResult([]);
                setitemArray([]);
                setSwitchoutcopyArrayData([]);
                console.log(
                  'switch out Custom Route - ItemPricingContext',
                  ItemPricingContext
                );
                console.log(
                  'switch out Custom Route - agreementCreateStatus',
                  agreementCreateStatus
                );
                setautoPayStatus(
                  agreementInfo.agreementWorkflow.isAutopayEnabled
                );
                setSwitchOutData(ItemPricingContext);
                // setnumberOfInventory(agreementInfo.agreementInventories.length);
                // End of item search context set

                // Start of Agreement Details context set

                const paymentSchedule: any = {
                  weeklyRate:
                    agreementInfo.agreementInformation.agreementWeeklyRate, //
                  weeklyTerm: parseInt(
                    agreementInfo.agreementInformation.agreementWeeklyTerm
                  ), //
                  biweeklyRate:
                    agreementInfo.agreementInformation.agreementBiWeeklyRate, //
                  biweeklyTerm: parseInt(
                    agreementInfo.agreementInformation.agreementBiWeeklyTerm
                  ), //
                  semimonthlyRate:
                    agreementInfo.agreementInformation.agreementSemiMonthlyRate, //
                  semimonthlyTerm: parseInt(
                    agreementInfo.agreementInformation.agreementSemiMonthlyTerm
                  ), //
                  monthlyRate:
                    agreementInfo.agreementInformation.agreementMonthlyRate, //
                  monthlyTerm: parseInt(
                    agreementInfo.agreementInformation.agreementMonthlyTerm
                  ), //
                };

                const agreementSchedule: any = {
                  schedule: agreementInfo.agreementInformation.scheduleRefCode, //
                  dueDate: agreementInfo.agreementInformation.dueDate, //
                };

                const agreementInitialPayment: any = {
                  rate: agreementInfo.initialPayment.rate, //
                  initialRateTax: '0.00', //
                  ldw: agreementInfo.initialPayment.ldw, //
                  ldwTax: '0.00', //
                  policy: agreementInfo.initialPayment.policy, //
                  policyTax: '0.00', //
                  extensionAmount: agreementInfo.initialPayment.extensionAmount, //
                  extensionAmountTax: '0.00', //
                  otherFees: agreementInfo.initialPayment.other, //
                  otherFeesTax: '0.00', //
                  totalTax: agreementInfo.initialPayment.tax, //
                  initialPayment: agreementInfo.initialPayment.initialPayment, //
                };

                const agreementTotalPayment: any = {
                  initialPaymentTotal:
                    agreementInfo.totalPayment.initialPayment, //
                  regularPaymentsCount: agreementInfo.totalPayment.regularTerm, //
                  regularPaymentsAmount:
                    agreementInfo.totalPayment.regularPayment, //
                  finalPayment: agreementInfo.totalPayment.finalPayment, //
                  totalPaymentsCount: agreementInfo.totalPayment.totalTerm, //
                  sacTotal: agreementInfo.totalPayment.cashPrice, //
                  epoAmount: agreementInfo.agreementInformation.epoAmount,
                  agreementTotal: agreementInfo.totalPayment.totalCost,
                };

                const employerPayschedule: any = {
                  schedule: agreementInfo.agreementInformation.paySchedule, //
                  daysPaid: agreementInfo.agreementInformation.payDay, //
                };

                const fees: any[] = []; //

                const fee: any = {
                  feeAmount: agreementInfo.initialPayment.other,
                  feeType: 'OTHER',
                };

                fees.push(fee);

                const popupCompleted: any = {
                  vehicleInfoComplete: true, //
                  confirmDueDateComplete: true, //
                  customerSignComplete:
                    agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
                  printPopupComplete:
                    agreementInfo.agreementWorkflow.isPrinted == '1', //
                  sendAgreementComplete:
                    agreementInfo.agreementWorkflow.isPrinted == '1', //
                  autoPayComplete: true, //
                  SMSAutheticationComplete:
                    agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
                  initialPaymentComplete:
                    agreementInfo.agreementWorkflow.isInitialPaymentPaid == '1',
                };

                const customerInfo = agreementInfo.agreementCustomer.filter(
                  (element) => element.priority == '1'
                );
                const coCustomerInfo = agreementInfo.agreementCustomer.filter(
                  (element) => element.priority == '2'
                );

                const customers: any = {
                  customer: customerInfo.map((ele) => {
                    const customer: any = {
                      priority: ele.priority,
                      customerName: ele.firstName + ' ' + ele.lastName,
                      firstName: ele.firstName,
                      lastName: ele.lastName,
                      customerId: ele.customerId,
                      emailAddress: ele.emailId,
                      ssn: null,
                      gcdid: null,
                      phoneNumber: ele.phoneNumber,
                      isDocumentGenerated: ele.isDocumentGenerated,
                    };

                    return customer;
                  }), //
                  coCustomer: coCustomerInfo.map((ele) => {
                    const coCustomer: any = {
                      priority: ele.priority,
                      customerName: ele.firstName + ' ' + ele.lastName,
                      firstName: ele.firstName,
                      lastName: ele.lastName,
                      customerId: ele.customerId,
                      emailAddress: ele.emailId,
                      ssn: null,
                      gcdid: null,
                      phoneNumber: ele.phoneNumber,
                      isDocumentGenerated: ele.isDocumentGenerated,
                    };

                    return coCustomer;
                  }), //
                };

                const promoCode = agreementInfo.agreementInformation.promoCode
                  ? agreementInfo.agreementInformation.promoCode
                  : '';
                const promoName = agreementInfo.agreementInformation.promoName
                  ? agreementInfo.agreementInformation.promoName
                  : '';

                const agreementOption: any = {
                  agreementSource: [
                    {
                      value:
                        agreementInfo.agreementInformation
                          .agreementSourceRefCode, //
                      label: agreementInfo.agreementInformation.agreementSource, //
                    },
                  ],
                  optServices: [
                    {
                      value:
                        agreementInfo.agreementInformation
                          .coverageTypeRefCode || '', //
                      label:
                        agreementInfo.agreementInformation.optServices ||
                        'None', //
                    },
                  ],
                  salesPerson: [
                    {
                      value:
                        agreementInfo.agreementInformation.salesPersonId.toString(), //
                      label: agreementInfo.agreementInformation.salesPerson, //
                    },
                  ],
                  schedule: [
                    {
                      value: agreementInfo.agreementInformation.scheduleRefCode, //
                      label: agreementInfo.agreementInformation.paymentSchedule, //
                    },
                  ],
                  deliveryCharge: [
                    {
                      value: agreementInfo.agreementInformation.deliveryCharge,
                      label: agreementInfo.agreementInformation.deliveryCharge,
                    },
                  ],
                  promoCode: [
                    {
                      value: promoCode, //
                      label: promoName != '' ? promoName : 'Select', //
                    },
                  ],
                  daysExtension: [
                    {
                      value: agreementInfo.agreementInformation.daysExtension,
                      label: agreementInfo.agreementInformation.daysExtension,
                    },
                  ],
                };

                const generalInformation: any = {
                  agreementType:
                    agreementInfo.agreementInformation.agreementType, //
                  agreementSourceValue:
                    agreementInfo.agreementInformation.agreementSourceRefCode, //
                  agreementStatus:
                    agreementInfo.agreementInformation.agreementStatus, //
                  agreementStatusRefCode:
                    agreementInfo.agreementInformation.agreementStatusRefCode,
                  deliveryChargeValue:
                    agreementInfo.agreementInformation.deliveryCharge, //
                  daysExtensionValue:
                    agreementInfo.agreementInformation.daysExtension, //
                  optServicesValue:
                    agreementInfo.agreementInformation.coverageTypeRefCode ||
                    '', //
                  promoCodeValue: promoCode, //
                  policyValue:
                    agreementInfo.agreementInformation.policy !== 'N/A'
                      ? 'RAC Tire Plus'
                      : 'None',
                  salesPersonValue:
                    agreementInfo.agreementInformation.salesPersonId.toString(), //
                  isAutopayEnabled:
                    agreementInfo.agreementWorkflow.isAutopayEnabled == '1', //
                };

                const agreementDetailsContext: any = {
                  paymentSchedule,
                  agreementSchedule,
                  agreementInitialPayment,
                  agreementTotalPayment,
                  employerPayschedule,
                  fees,
                  policyApplicable:
                    agreementInfo.agreementInformation.policy !== 'N/A' ? 1 : 0,
                  popupCompleted,
                  agreementId: agreementInfo.agreementInformation.agreementId,
                  agreementNumber:
                    agreementInfo.agreementInformation.agreementNumber,
                  customers,
                  agreementOption,
                  generalInformation,
                  agreementTotal: agreementInfo.totalPayment.totalCost,
                  agreementWorkflow: agreementInfo.agreementWorkflow,
                };

                console.log(
                  'Custom Route - agreementDetailsContext',
                  agreementDetailsContext
                );

                setAgreementDetailsData(agreementDetailsContext);
              }
              setAgreementInfoRes(agreementInfo);
            } else if (updatedRoute.includes('/agreement/customerorder')) {
              setgetViewAgrData(agrInfoResponse);
              setviewAgrData(agrInfoResponse);
              setviewAgreementData(agrInfoResponse.data);
              const FormatResponse = SetPendingCOAgreementValue(agreementInfo);
              console.log('FormatResponse', FormatResponse);
              const rmsItemInformation =
                agrInfoResponse.data.agreementInventories.map((el: any) => {
                  return {
                    modelNumber: el.modelNumber,
                    brand: el?.brand == undefined ? '-' : el?.brand,
                    cashPrice: agrInfoResponse.data.totalPayment.cashPrice,
                    department: el.department,
                    subdepartment: el.subDepartment,
                    monthlyRate: el.agreementRate,
                    monthlyTerm: el.agreementTerm,
                    totalCost: el.agreementTotal,
                    availability: null,
                  };
                });
              const rmsItemObj = {
                rmsItemInformation: rmsItemInformation,
              };
              setagreementCreateStatus({ agreementCreatedStatus: true });
              setsearchResult(rmsItemObj);
              setIsAllDone(true);
              setwholeAgrInfoResponse(FormatResponse);
            }

            console.log('location.pathname -> ', location.pathname);
            console.log('updatedRoute -> ', updatedRoute);

            if (location.pathname !== updatedRoute) {
              history.push(updatedRoute);

              // communicate to Routes that URL has changed
              const navEvent = new PopStateEvent('popstate');
              window.dispatchEvent(navEvent);
            }

            setRouteUpdated(true);
          } else if (agrInfoResponse.status === 400) {
            setisNoRecord(true);
          } else if (agrInfoResponse.status === 500) {
            setIsInternalServerError(true);
          } else {
            // TODO : FALLBACK
            // history.push('/error');
            console.log('route error');
          }
        };
        GetResponse();
      });
      const AgreementOptions = new Promise(() => {
        const GetResponse = async () => {
          const AgrReturnResponse = await getAgreementReturnOptions(
            customerId,
            '00429'
          );
          if (AgrReturnResponse.status == 200) {
            setReturnReasons(AgrReturnResponse);
          }
          //setInventoryDetailsData(InventoryDetailsResponse);
          console.log('AgrReturnResponse', AgrReturnResponse);
        };
        GetResponse();
      });
      const GetAmountDue = new Promise(() => {
        const GetResponse = async () => {
          const paymentobject = {
            agreementIdCustomerId: [
              {
                agreementId: parseInt(agreementId),
                customerId: parseInt(customerId)
              },
            ],
          };
          const AmountDueResponse = await getnextpaymentamount(paymentobject);
          if (AmountDueResponse.status == 200) {
            setamountDueResponse(AmountDueResponse);
          }
          //setActivitiesData(ActivitiesResponse);
          console.log('AmountDueResponse', AmountDueResponse);
        };
        GetResponse();
      });

      Promise.race([AgreementInfo, AgreementOptions, GetAmountDue]);
    };

    const initialTrigger = async () => {
      const bodyobj = {
        storeNumbers: [StoreId],

        paramKeyNames: [
          'Switchout',
          'ChoosingPricingTag',
          'waiveLdw',
          'AgreementReturn',
          'RateReduction',
          'InstallmentNotes',
          'AgreementChargeOff',
          'EnableAccess',
          'SundaySky',
          'loanerAgreement',
          'FieldDevice',
          'CompetitorMatch',
          'CappedFreeTimePercentage',
          'CappedFreeTimeRegular',
          'CappedFreeTimeService',
          'CappedFreeTimeRetention',
          'CappedFreeTimeInitPayment',
          'CappedFreeTimePromo',
          'CappedFreeTimeEnabled',
          'RewardsEligibilityAmount',
          'CustomerRewards',
          'CustomerVerificationOptimization',
          ADD_ON_PRICING,
          'EnableDEDeclineApproval',
          ...SACrolesArray
        ],
      };
      if (gotSOConfigResponse === false) {
        let switchoutresponse: any;
        if (StoreId && StoreId != null && StoreId != undefined && StoreId != '' && StoreId != 'null' && StoreId != 'undefined') {
          switchoutresponse = await CoworkerRequired(bodyobj);
        }

        if (switchoutresponse?.status === 200) {
          console.log('switchoutresponse', switchoutresponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails);
          debugger;
          // addCappedFreeTimeFeatureFlag(switchoutresponse);
          const value =
            switchoutresponse.data !== null &&
              switchoutresponse.data.storeProfileResponse !== undefined &&
              switchoutresponse.data.storeProfileResponse &&
              switchoutresponse.data.storeProfileResponse.configDetails !==
              undefined &&
              switchoutresponse.data.storeProfileResponse.configDetails.length >
              0 &&
              switchoutresponse.data.storeProfileResponse.configDetails[0]
                .configDetails !== undefined &&
              switchoutresponse.data.storeProfileResponse.configDetails[0]
                .configDetails.length > 0
              ? switchoutresponse.data.storeProfileResponse.configDetails[0]
                .configDetails[0].paramValue
              : 0;
          const filteredCPT =
            switchoutresponse.data.storeProfileResponse.configDetails[0].configDetails.filter(
              (x) => x.paramKeyName == 'ChoosingPricingTag'
            );
          const CappedFreeTimePercentage = switchoutresponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.find(
            (el) => el.paramKeyName == 'CappedFreeTimePercentage'
          )
          if(CappedFreeTimePercentage){
            updateCappedFreeTimeResponseValues(
              'cappedFreeTimePercentage', Number(CappedFreeTimePercentage.paramValue), setCappedFreeTimeValues
            )
          }

          const CappedFreeTimeRegular = switchoutresponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.find(
            (el) => el.paramKeyName == 'CappedFreeTimeRegular'
          )
          if(CappedFreeTimeRegular){
            updateCappedFreeTimeResponseValues(
              'cappedFreeTimeRegularFlag', Number(CappedFreeTimeRegular.paramValue) > 0 ? true : false, setCappedFreeTimeValues
            )
          }
          const CappedFreeTimeService = switchoutresponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.find(
            (el) => el.paramKeyName == 'CappedFreeTimeService'
          )
          if(CappedFreeTimeService){
            updateCappedFreeTimeResponseValues(
              'cappedFreeTimeServiceFlag', Number(CappedFreeTimeService.paramValue) > 0 ? true : false, setCappedFreeTimeValues
            )
          }
          const CappedFreeTimeRetention = switchoutresponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.find(
            (el) => el.paramKeyName == 'CappedFreeTimeRetention'
          )
          if(CappedFreeTimeRetention){
            updateCappedFreeTimeResponseValues(
              'cappedFreeTimeRetentionFlag', Number(CappedFreeTimeRetention.paramValue) > 0 ? true : false, setCappedFreeTimeValues
            )
          }
          const CappedFreeTimeInitPayment = switchoutresponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.find(
            (el) => el.paramKeyName == 'CappedFreeTimeInitPayment'
          )
          if(CappedFreeTimeInitPayment){
            updateCappedFreeTimeResponseValues(
              'cappedFreeTimeInitPaymentFlag', Number(CappedFreeTimeInitPayment.paramValue) > 0 ? true : false, setCappedFreeTimeValues
            )
          }
          const CappedFreeTimePromo = switchoutresponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.find(
            (el) => el.paramKeyName == 'CappedFreeTimePromo'
          )
          if(CappedFreeTimePromo){
            updateCappedFreeTimeResponseValues(
              'cappedFreeTimePromoFlag', Number(CappedFreeTimePromo.paramValue) > 0 ? true : false, setCappedFreeTimeValues
            )
          }
          const CappedFreeTimeEnabled = switchoutresponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.find(
            (el) => el.paramKeyName == 'CappedFreeTimeEnabled'
          )
          if(CappedFreeTimeEnabled){
            updateCappedFreeTimeResponseValues(
              'enableCappedFreeTimeFlag', Number(CappedFreeTimeEnabled.paramValue) > 0 ? true : false, setCappedFreeTimeValues
            )
          }
          const FeatureFlagObj = MakeFeatureFlagObjFn(
            switchoutresponse.data.storeProfileResponse.configDetails[0]
              .configDetails
          );
          const sacRolesLimitObj = makeSACrolesAllocationFn(
            switchoutresponse.data.storeProfileResponse.configDetails[0].configDetails)
          const priceOverRideRolesLimitObject = makePriceOverRiderolesAllocationFn(switchoutresponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails)
          const termOverRideRolesLimitObject = makeTermOverRiderolesAllocationFn(switchoutresponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails)
          const resumeV =
            switchoutresponse?.data?.storeProfileResponse?.configDetails[0]
              ?.configDetails;
          const resumebtnEnable = resumeV.filter((element: any) => {
            return (
              element.paramKeyName == 'EnableAccess' &&
              element.paramGroupName == 'sendMAMVideo'
            );
          });
          console.log('resumebtnEnable', resumebtnEnable);
          setresumebtnFeatureFlag(resumebtnEnable[0]?.paramValue);
          console.log('FeatureFlagObj after obj create', FeatureFlagObj);
          console.log('sacRolesLimitObj', sacRolesLimitObj);
          setSacRolesLimitDetails(sacRolesLimitObj);
          setPriceOverrideLimitDetails(priceOverRideRolesLimitObject);
          setTermOverrideLimitDetails(termOverRideRolesLimitObject)
          setFeatureFlagDetails(FeatureFlagObj);
          console.log('value', value);
          setisSOEnabledbyconfig(value);
          setisChoosePriceConfig(filteredCPT[0].paramValue);
          setgotSOConfigResponse(true);
        }
      }
      console.log('type', type);
      console.log('hasCustomerId', hasCustomerId);
      console.log('hasAgreementId', hasAgreementId);

      if (hasCustomerId && hasAgreementId && type == null) {
        // TODO : VIEW AGREEMENT
        console.log('TODO : VIEW AGREEMENT');
        pageLoad();
      } else if (type?.toUpperCase() == 'RETURN' || type == 'CHRGOFF') {
        PageReload();
      } else if (hasCustomerId && !hasAgreementId) {
        // TODO : CREATE AGREEMENT
        console.log('TODO : CREATE AGREEMENT');
        let finalRoute = '';
        if (location.pathname.includes('/agreement/rental/details')) {
          console.log('TODO : CREATE AGREEMENT rental/details');
          finalRoute = `/agreement/rental/details/${customerId}`;
        }
        else if (apid) {
          finalRoute = `/agreement/info/delivery/${customerId}/${agreementId}?apid=${apid}`;
          history.push(finalRoute);
          // communicate to Routes that URL has changed
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
        }
        else {
          console.log('TODO : CREATE AGREEMENT rental/itemsearch');
          finalRoute = `/agreement/rental/itemsearch/${customerId}`;
        }

        if (location.pathname !== finalRoute) {
          history.push(finalRoute);
          // communicate to Routes that URL has changed
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
        }

        setRouteUpdated(true);
      } else {
        // TODO : FALLBACK
        setRouteUpdated(true);
        console.log('route error in customer/agreement');
      }
    };
    initialTrigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCappedFreeTimeResponseValues = (key: any, value: any, setFunction: any) => {
    setFunction(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const redirectDashBoard: any = () => {
    if (redirectOrigin === AMOrigin) {
      history.push({
        pathname: `${AppRoute.AMCustomer}/${customerId}`,
        state: location?.state,
      });
      return;
    }
    history.push({
      pathname: `/dashboard/homepage`,
    });
  };

  const buildAgrInfoException = () => {
    return (
      <Grid>
        <Grid className={classes.textCenter}>
          <AlertIcon />
          <Typography className={`${classes.mt2} ${classes.fontBold}`}>
            {agrInfoRawResponse.data !== undefined
              ? agrInfoRawResponse.data.errors[0].error
              : 'No Records Found'}
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={redirectDashBoard}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const buildServerException = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <AlertIcon />
          <Typography className={`${classes.subTitle} ${classes.mb3}`}>
            Something went wrong
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={redirectDashBoard}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {routeUpdated ? (
        <>
          <CustomRoute path={AppRoute.CreateRentalAgreement}>
            <CreateRentalAgreementRoute />
          </CustomRoute>
          <CustomRoute path={AppRoute.CreateSwitchOutAgreement}>
            <CreateSwitchOutAgreementRoute />
          </CustomRoute>
          <CustomRoute path={AppRoute.AgreementView}>
            <ViewAgreementRoute />
          </CustomRoute>
          {featureFlagDetails?.loanerAgreement == '1' ? (
            <>
              <CustomRoute path={AppRoute.CreateLoanerAgreement}>
                <LoanerAgreementRoutes />
              </CustomRoute>
            </>
          ) : (
            <>
              <ComingSoonPage />
            </>
          )}
        </>
      ) : isNoRecord ? (
        <RACModalCard
          isOpen={true}
          maxWidth="xs"
          borderRadius={'25px !important'}
          //eslint-disable-next-line react/no-children-prop
          children={buildAgrInfoException()}
        />
      ) : isInternalServerError ? (
        <RACModalCard
          isOpen={true}
          maxWidth="xs"
          borderRadius={'25px !important'}
          //eslint-disable-next-line react/no-children-prop
          children={buildServerException()}
        />
      ) : (
        <>
          <div style={{ margin: '20% 20% 20% 48%' }}>
            <CircularProgress size={30} />
          </div>
        </>
      )}
    </>
  );
};
const SwitchOutCustomRoute = (props: any) => {
  const { setFeatureFlagDetails } = useContext(FeatureFlagContext);
  const { AgreementInfoRes, setAgreementInfoRes } = props;
  const {
    selectedInvNumber,
    setselectedInvNumber,
    searchCriteria,
    itemArrayDataSwitchOut,
    numberOfInventory,
    setsearchCriteria,
    setitemArrayData,
    setnumberOfInventory,
    switchOutData,
    setSwitchOutData,
    agreementDetailsData,
    setAgreementDetailsData,
    setoldInvRV,
    setentireAgrInfoResponse,
    setagreementCreateStatus,
    agreementCreateStatus,
    setswitchoutagreementnum,
    setaddedItem,
    setsearchResult,
    setSwitchoutcopyArrayData,
    setdepPercentage,
    setitemArray,
    isswitchoutCancelled,
    setautoPayStatus,
    setisSOEnabledbyconfig,
    setisChoosePriceConfig,
    gotSOConfigResponse,
    setgotSOConfigResponse,
  } = useContext(SwitchOutAgreementContext);
  const { redirectOrigin } = useContext(OriginContext);
  const history = useHistory();
  const reg = /^\d+$/;
  let { agreementId } = useParams<Params>();
  const { customerId } = useParams<Params>();
  const location: any = useLocation();
  console.log('props dvjb jhbd55', props);
  console.log('locationkcjs jknb', location);
  let selectedInvFromHistory = '';
  if (location?.state?.selectedInventoryNo !== undefined) {
    selectedInvFromHistory = location.state.selectedInventoryNo;
    console.log('selectedInvFromHistory', selectedInvFromHistory);

    console.log('location logging', location);
    console.log('location state logging', location.state);
    console.log('location state deep logging', location.state.selectedInvNo);
  }
  if (redirectOrigin === AMOrigin) {
    agreementId = agreementId.replace(AccountManagementURLParam, '');
  }
  const [gotSwitchOutInfo, setgotSwitchOutInfo] = useState(false);
  const hasCustomerId =
    customerId !== undefined && customerId.match(reg) ? true : false;
  const hasAgreementId =
    agreementId !== undefined && agreementId.match(reg) ? true : false;

  useEffect(() => {
    const AgreementInfoCall = async () => {
      const agrInfoResponse = await getAgreementInfo(agreementId);
      console.log('switch out agreement info call ', agrInfoResponse);
      if (agrInfoResponse.status === 200) {
        const agreementInfo = agrInfoResponse.data;
        setentireAgrInfoResponse(agreementInfo);
        console.log('switch out agreement info data', agreementInfo);
        // Set Context
        // Start of item search context set
        setswitchoutagreementnum(
          agreementInfo.agreementWorkflow.agreementNumber
        );
        setnumberOfInventory(agreementInfo.agreementInventories.length);
        setaddedItem(undefined);
        setsearchResult(undefined);
        setSwitchoutcopyArrayData(undefined);
        setitemArray(undefined);
        const itemPricing: any = [];
        agreementInfo.agreementInventories.map((value: any) => {
          console.log('response.itemPricing something', value);
          if (
            parseInt(value.inventoryNumber) ==
            parseInt(
              selectedInvNumber !== undefined
                ? selectedInvNumber
                : selectedInvFromHistory
            )
          ) {
            console.log('reached the if of switch');
            console.log(
              'parseInt(value.inventoryNumber)',
              parseInt(value.inventoryNumber)
            );
            console.log(
              'parseInt(switchedItem)',
              parseInt(
                selectedInvNumber !== undefined
                  ? selectedInvNumber
                  : selectedInvFromHistory
              )
            );

            const individualArray: any = {
              inventoryName: value.description, //
              inventoryId: value.inventoryId, //
              inventoryNumber: value.inventoryNumber, //
              rmsItemNumber: value.rmsItemNumber,
              weeklyRate: value.tagRate, //
              weeklyTerm: value.tagTerm, //
              cashPrice: value.cashPrice, //
              remainingValue: value.remainingValue, //
              tagTotal: value.tagTotal, //
              inventoryDepreciation: value.inventoryDepreciation, //
              agreementRate: value.agreementRate, //
              agreementTotal: value.agreementTotal, //
              packageName:
                agreementInfo.agreementInformation.packageName == null
                  ? ''
                  : agreementInfo.agreementInformation.packageName, //
            };
            itemPricing.push(individualArray);
          }
        });
        let calculatedRV = 0;
        agreementInfo.agreementInventories.map((value: any) => {
          console.log(
            'parseInt(value.inventoryNumber) in RV map',
            parseInt(value.inventoryNumber)
          );
          console.log(
            'parseInt(switchedItem) in Rv map',
            parseInt(
              selectedInvNumber !== undefined
                ? selectedInvNumber
                : selectedInvFromHistory
            )
          );

          if (
            parseInt(value.inventoryNumber) !==
            parseInt(
              selectedInvNumber !== undefined
                ? selectedInvNumber
                : selectedInvFromHistory
            )
          ) {
            calculatedRV = calculatedRV + Number(value.remainingValue);
          }
        });
        console.log(
          'calculatedRV in the route for the reinstate infinity',
          calculatedRV
        );

        setoldInvRV(calculatedRV);
        console.log('final rv calculated value', calculatedRV);
        console.log('itemPricing check value', itemPricing);

        // console.log('calculatedRV in route', calculatedRV);

        const ItemPricingContext: any = {
          itemPricing: itemPricing,
          agreementPricing: {
            tagRate: agreementInfo.agreementTotal.tagRate, //
            tagTerm: agreementInfo.agreementTotal.tagTerm, //
            tagTotal: agreementInfo.agreementTotal.tagTotal, //
            agreementRate: agreementInfo.agreementTotal.agreementRate, //
            agreementTerm: agreementInfo.agreementTotal.agreementTerm, //
            cashPrice: agreementInfo.totalPayment.cashPrice, //
            agreementTotal: agreementInfo.agreementTotal.agreementTotal, //
            agreementInventoryDepreciation:
              agreementInfo.agreementTotal.agreementInventoryDepreciation, //
          },
          isPackage: false, //
          packageInformation: null, //
        };
        setdepPercentage(
          agreementInfo.agreementTotal.agreementInventoryDepreciation
        );
        const getSearchCriteria = agreementInfo.agreementInventories.filter(
          (obj: any) => {
            return (
              parseInt(obj.inventoryNumber) ==
              parseInt(
                selectedInvNumber !== undefined
                  ? selectedInvNumber
                  : selectedInvFromHistory
              )
            );
          }
        );
        console.log('getSearchCriteria', getSearchCriteria);
        console.log(
          ' getSearchCriteria[0].department',
          getSearchCriteria[0].department
        );
        const getItemArray = agreementInfo.agreementInventories.filter(
          (obj: any) => {
            return (
              parseInt(obj.inventoryNumber) !==
              parseInt(
                selectedInvNumber !== undefined
                  ? selectedInvNumber
                  : selectedInvFromHistory
              )
            );
          }
        );
        console.log('getItemArray', getItemArray);
        const tempItemArray: any = getItemArray.map((obj: any) => {
          return {
            inventoryId: Number(obj.inventoryId),
            inventoryPriceId: Number(obj.inventoryPriceId),
          };
        });
        console.log('tempItemArray', tempItemArray);
        setitemArrayData(tempItemArray);
        setsearchCriteria({
          ...searchCriteria,
          departments: getSearchCriteria[0].department,
          packageTypeval:
            agreementInfo.agreementInventories.length > 1
              ? agreementInfo.agreementInformation.packageName
              : '',
        });

        const agreementCreateStatus: any = {
          agreementCreatedStatus: true,
        };

        console.log(
          'switch out Custom Route - ItemPricingContext',
          ItemPricingContext
        );
        console.log(
          'switch out Custom Route - agreementCreateStatus',
          agreementCreateStatus
        );
        setautoPayStatus(agreementInfo.agreementWorkflow.isAutopayEnabled);
        setSwitchOutData(ItemPricingContext);
        setnumberOfInventory(agreementInfo.agreementInventories.length);
        // End of item search context set

        // Start of Agreement Details context set

        const paymentSchedule: any = {
          weeklyRate: agreementInfo.agreementInformation.agreementWeeklyRate, //
          weeklyTerm: parseInt(
            agreementInfo.agreementInformation.agreementWeeklyTerm
          ), //
          biweeklyRate:
            agreementInfo.agreementInformation.agreementBiWeeklyRate, //
          biweeklyTerm: parseInt(
            agreementInfo.agreementInformation.agreementBiWeeklyTerm
          ), //
          semimonthlyRate:
            agreementInfo.agreementInformation.agreementSemiMonthlyRate, //
          semimonthlyTerm: parseInt(
            agreementInfo.agreementInformation.agreementSemiMonthlyTerm
          ), //
          monthlyRate: agreementInfo.agreementInformation.agreementMonthlyRate, //
          monthlyTerm: parseInt(
            agreementInfo.agreementInformation.agreementMonthlyTerm
          ), //
        };

        const agreementSchedule: any = {
          schedule: agreementInfo.agreementInformation.scheduleRefCode, //
          dueDate: agreementInfo.agreementInformation.dueDate, //
        };

        const agreementInitialPayment: any = {
          rate: agreementInfo.initialPayment.rate, //
          initialRateTax: '0.00', //
          ldw: agreementInfo.initialPayment.ldw, //
          ldwTax: '0.00', //
          policy: agreementInfo.initialPayment.policy, //
          policyTax: '0.00', //
          extensionAmount: agreementInfo.initialPayment.extensionAmount, //
          extensionAmountTax: '0.00', //
          otherFees: agreementInfo.initialPayment.other, //
          otherFeesTax: '0.00', //
          clubAmount: agreementInfo.initialPayment.clubAmount,
          totalTax: agreementInfo.initialPayment.tax, //
          initialPayment: agreementInfo.initialPayment.initialPayment, //
        };

        const agreementTotalPayment: any = {
          initialPaymentTotal: agreementInfo.totalPayment.initialPayment, //
          regularPaymentsCount: agreementInfo.totalPayment.regularTerm, //
          regularPaymentsAmount: agreementInfo.totalPayment.regularPayment, //
          finalPayment: agreementInfo.totalPayment.finalPayment, //
          totalPaymentsCount: agreementInfo.totalPayment.totalTerm, //
          sacTotal: agreementInfo.totalPayment.cashPrice, //
          epoAmount: agreementInfo.agreementInformation.epoAmount,
          agreementTotal: agreementInfo.totalPayment.totalCost,
        };

        const employerPayschedule: any = {
          schedule: agreementInfo.agreementInformation.paySchedule, //
          daysPaid: agreementInfo.agreementInformation.payDay, //
        };

        const fees: any[] = []; //

        const fee: any = {
          feeAmount: agreementInfo.initialPayment.other,
          feeType: 'OTHER',
        };

        fees.push(fee);

        const popupCompleted: any = {
          vehicleInfoComplete: true, //
          confirmDueDateComplete: true, //
          customerSignComplete:
            agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
          printPopupComplete: agreementInfo.agreementWorkflow.isPrinted == '1', //
          sendAgreementComplete:
            agreementInfo.agreementWorkflow.isPrinted == '1', //
          autoPayComplete: true, //
          SMSAutheticationComplete:
            agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
          initialPaymentComplete:
            agreementInfo.agreementWorkflow.isInitialPaymentPaid == '1',
        };

        const customerInfo = agreementInfo.agreementCustomer.filter(
          (element) => element.priority == '1'
        );
        const coCustomerInfo = agreementInfo.agreementCustomer.filter(
          (element) => element.priority == '2'
        );

        const customers: any = {
          customer: customerInfo.map((ele) => {
            const customer: any = {
              priority: ele.priority,
              customerName: ele.firstName + ' ' + ele.lastName,
              firstName: ele.firstName,
              lastName: ele.lastName,
              customerId: ele.customerId,
              emailAddress: ele.emailId,
              ssn: null,
              gcdid: null,
              phoneNumber: ele.phoneNumber,
              isDocumentGenerated: ele.isDocumentGenerated,
            };

            return customer;
          }), //
          coCustomer: coCustomerInfo.map((ele) => {
            const coCustomer: any = {
              priority: ele.priority,
              customerName: ele.firstName + ' ' + ele.lastName,
              firstName: ele.firstName,
              lastName: ele.lastName,
              customerId: ele.customerId,
              emailAddress: ele.emailId,
              ssn: null,
              gcdid: null,
              phoneNumber: ele.phoneNumber,
              isDocumentGenerated: ele.isDocumentGenerated,
            };

            return coCustomer;
          }), //
        };

        const promoCode = agreementInfo.agreementInformation.promoCode
          ? agreementInfo.agreementInformation.promoCode
          : '';
        const promoName = agreementInfo.agreementInformation.promoName
          ? agreementInfo.agreementInformation.promoName
          : '';

        const agreementOption: any = {
          agreementSource: [
            {
              value: agreementInfo.agreementInformation.agreementSourceRefCode, //
              label: agreementInfo.agreementInformation.agreementSource, //
            },
          ],
          optServices: [
            {
              value:
                agreementInfo.agreementInformation.coverageTypeRefCode || '', //
              label: agreementInfo.agreementInformation.optServices || 'None', //
            },
          ],
          salesPerson: [
            {
              value:
                agreementInfo.agreementInformation.salesPersonId.toString(), //
              label: agreementInfo.agreementInformation.salesPerson, //
            },
          ],
          schedule: [
            {
              value: agreementInfo.agreementInformation.scheduleRefCode, //
              label: agreementInfo.agreementInformation.paymentSchedule, //
            },
          ],
          deliveryCharge: [
            {
              value: agreementInfo.agreementInformation.deliveryCharge,
              label: agreementInfo.agreementInformation.deliveryCharge,
            },
          ],
          promoCode: [
            {
              value: promoCode, //
              label: promoName != '' ? promoName : 'Select', //
            },
          ],
          daysExtension: [
            {
              value: agreementInfo.agreementInformation.daysExtension,
              label: agreementInfo.agreementInformation.daysExtension,
            },
          ],
        };

        const generalInformation: any = {
          agreementType: agreementInfo.agreementInformation.agreementType, //
          agreementSourceValue:
            agreementInfo.agreementInformation.agreementSourceRefCode, //
          agreementStatus: agreementInfo.agreementInformation.agreementStatus, //
          agreementStatusRefCode:
            agreementInfo.agreementInformation.agreementStatusRefCode,
          deliveryChargeValue:
            agreementInfo.agreementInformation.deliveryCharge, //
          daysExtensionValue: agreementInfo.agreementInformation.daysExtension, //
          optServicesValue:
            agreementInfo.agreementInformation.coverageTypeRefCode || '', //
          promoCodeValue: promoCode, //
          policyValue:
            agreementInfo.agreementInformation.policy !== 'N/A'
              ? 'RAC Tire Plus'
              : 'None',
          salesPersonValue:
            agreementInfo.agreementInformation.salesPersonId.toString(), //
          isAutopayEnabled:
            agreementInfo.agreementWorkflow.isAutopayEnabled == '1', //
          isLdwWaived: agreementInfo?.agreementInformation?.isLdwWaived == '1',
        };

        const agreementDetailsContext: any = {
          paymentSchedule,
          agreementSchedule,
          agreementInitialPayment,
          agreementTotalPayment,
          employerPayschedule,
          fees,
          policyApplicable:
            agreementInfo.agreementInformation.policy !== 'N/A' ? 1 : 0,
          popupCompleted,
          agreementId: agreementInfo.agreementInformation.agreementId,
          agreementNumber: agreementInfo.agreementInformation.agreementNumber,
          customers,
          agreementOption,
          generalInformation,
          agreementTotal: agreementInfo.totalPayment.totalCost,
          agreementWorkflow: agreementInfo.agreementWorkflow,
        };

        console.log(
          'Custom Route - agreementDetailsContext',
          agreementDetailsContext
        );

        setAgreementDetailsData(agreementDetailsContext);
      }
      // End on Agreement Details context set
      // End of context set

      // eslint-disable-next-line react-hooks/exhaustive-deps
      setgotSwitchOutInfo(true);
    };
    console.log('props dvjb jhbd55', props);
    console.log('locationkcjs jknb', location);
    console.log(
      'agreementCreateStatus check in so inv route',
      agreementCreateStatus
    );
    const initialTrigger = async () => {
      const StoreId = window.sessionStorage.getItem('storeNumber');
      const bodyobj = {
        storeNumbers: [StoreId],

        paramKeyNames: [
          'Switchout',
          'ChoosingPricingTag',
          'waiveLdw',
          'AgreementReturn',
          'RateReduction',
          'InstallmentNotes',
          'CompetitorMatch',
          'CustomerVerificationOptimization',
          'EnableDEDeclineApproval',
          ADD_ON_PRICING
        ],
      };
      if (gotSOConfigResponse === false) {
        let switchoutresponse
        if (StoreId && StoreId != null && StoreId != undefined && StoreId != '' && StoreId != 'null' && StoreId != 'undefined') {
          switchoutresponse = await CoworkerRequired(bodyobj);
        }
        if (switchoutresponse?.status === 200) {
          const value =
            switchoutresponse.data !== null &&
              switchoutresponse.data.storeProfileResponse !== undefined &&
              switchoutresponse.data.storeProfileResponse &&
              switchoutresponse.data.storeProfileResponse.configDetails !==
              undefined &&
              switchoutresponse.data.storeProfileResponse.configDetails.length >
              0 &&
              switchoutresponse.data.storeProfileResponse.configDetails[0]
                .configDetails !== undefined &&
              switchoutresponse.data.storeProfileResponse.configDetails[0]
                .configDetails.length > 0
              ? switchoutresponse.data.storeProfileResponse.configDetails[0]
                .configDetails[0].paramValue
              : 0;
          const priceTagValue =
            switchoutresponse.data.storeProfileResponse.configDetails[0]
              .configDetails[1].paramValue;
          const filteredCPT =
            switchoutresponse.data.storeProfileResponse.configDetails[0].configDetails.filter(
              (x) => x.paramKeyName == 'ChoosingPricingTag'
            );
          console.log('value', value);
          const FeatureFlagObj = MakeFeatureFlagObjFn(
            switchoutresponse.data.storeProfileResponse.configDetails[0]
              .configDetails
          );
          setFeatureFlagDetails(FeatureFlagObj);
          setisSOEnabledbyconfig(value);
          setisChoosePriceConfig(filteredCPT[0].paramValue);
          setgotSOConfigResponse(true);
        }
      }
      if (isswitchoutCancelled) {
        console.log('selectedInvNumber routing update check two');

        history.push({
          pathname: `/agreement/info/details/${customerId}/${agreementId}`,
        });
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
      } else if (
        hasCustomerId &&
        hasAgreementId &&
        selectedInvNumber !== undefined
      ) {
        // To get Switch out data
        AgreementInfoCall();
      } else if (
        hasCustomerId &&
        hasAgreementId &&
        selectedInvFromHistory !== undefined &&
        selectedInvFromHistory !== ''
      ) {
        setselectedInvNumber(selectedInvFromHistory);
        AgreementInfoCall();
      } else if (
        hasCustomerId &&
        hasAgreementId &&
        selectedInvNumber == undefined &&
        agreementCreateStatus.agreementCreatedStatus === true
      ) {
        console.log('selectedInvNumber routing update check one');

        history.push({
          pathname: `/agreement/switchout/delivery/${customerId}/${agreementId}`,
        });
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
        setgotSwitchOutInfo(true);
      } else if (
        hasCustomerId &&
        hasAgreementId &&
        selectedInvNumber == undefined &&
        agreementCreateStatus.agreementCreatedStatus === false
      ) {
        console.log('selectedInvNumber routing update check two');

        history.push({
          pathname: `/agreement/info/details/${customerId}/${agreementId}`,
        });
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
        setgotSwitchOutInfo(true);
      } else {
        setgotSwitchOutInfo(true);
        console.log('Error in switch agreement route');
      }
    };
    initialTrigger();
  }, []);

  return (
    <>
      {gotSwitchOutInfo ? (
        <>
          <CustomRoute path={AppRoute.CreateSwitchOutAgreement}>
            <CreateSwitchOutAgreementRoute />
          </CustomRoute>
        </>
      ) : (
        <>
          <div style={{ margin: '20% 20% 20% 48%' }}>
            <CircularProgress size={30} />
          </div>
        </>
      )}
    </>
  );
};

const CustomerOrderRoute = () => {
  const { setFeatureFlagDetails } = useContext(FeatureFlagContext);
  const [gotCustomerOrderInfo, setgotCustomerOrderInfo] = useState(false); // Need to make this false
  const { agreementId } = useParams<Params>();
  const {
    wholeAgrInfoResponse,
    setsearchResult,
    setIsAllDone,
    setagreementCreateStatus,
  } = useContext(CustomerOrderAgreementContext);
  console.log('COwholeAgrInfoResponse', wholeAgrInfoResponse);
  const { viewAgreementData } = useContext(AgreementContext);
  useEffect(() => {
    console.log('viewAgreementDatahvhh', viewAgreementData);
    if (viewAgreementData != undefined) {
      // setIsAllDone(true);
      setagreementCreateStatus({ agreementCreatedStatus: true });
      const rmsItemInformation = viewAgreementData?.agreementInventories.map(
        (el: any) => {
          return {
            modelNumber: el.modelNumber,
            brand: el?.brand == undefined ? '-' : el?.brand,
            cashPrice: viewAgreementData.totalPayment.cashPrice,
            department: el.department,
            subdepartment: el.subDepartment,
            monthlyRate: el.agreementRate,
            monthlyTerm: el.agreementTerm,
            totalCost: el.agreementTotal,
            availability: null,
          };
        }
      );
      setsearchResult(rmsItemInformation);
    }
  }, [viewAgreementData]);
  useEffect(() => {
    const initialTrigger = async () => {
      const storeNumber = window.sessionStorage.getItem('storeNumber')
      const bodyobj = {
        storeNumbers: [window.sessionStorage.getItem('storeNumber')],
        paramKeyNames: ['CustomerOrder', 'PilotStore', 'TireBuyer', 'CustomerVerificationOptimization', 'EnableDEDeclineApproval'],
      };
      let switchoutresponse
      if (storeNumber && storeNumber != null && storeNumber != undefined && storeNumber != '' && storeNumber != 'null' && storeNumber != 'undefined') {
        switchoutresponse = await CoworkerRequired(bodyobj);
      }
      if (switchoutresponse?.status === 200) {
        const value =
          switchoutresponse.data !== null &&
            switchoutresponse.data.storeProfileResponse !== undefined &&
            switchoutresponse.data.storeProfileResponse &&
            switchoutresponse.data.storeProfileResponse.configDetails !==
            undefined &&
            switchoutresponse.data.storeProfileResponse.configDetails.length >
            0 &&
            switchoutresponse.data.storeProfileResponse.configDetails[0]
              .configDetails !== undefined &&
            switchoutresponse.data.storeProfileResponse.configDetails[0]
              .configDetails.length > 0
            ? switchoutresponse.data.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue
            : 0;
        console.log('value', value);
        const FeatureFlagObj = MakeFeatureFlagObjFn(
          switchoutresponse.data.storeProfileResponse.configDetails[0]
            .configDetails
        );
        console.log('resumeV', FeatureFlagObj);
        setFeatureFlagDetails(FeatureFlagObj);
        setgotCustomerOrderInfo(true);
      }
    };
    initialTrigger();
  }, []);
  return (
    <>
      {gotCustomerOrderInfo ? (
        <>
          <CustomRoute path={AppRoute.CreateCustomerOrderAgreement}>
            <CreateCustomerOrderAgreementRoute />
          </CustomRoute>
        </>
      ) : (
        <>
          <div style={{ margin: '20% 20% 20% 48%' }}>
            <CircularProgress size={30} />
          </div>
        </>
      )}
    </>
  );
};
const ReinstateCustomRoute = (props: any) => {
  const { setFeatureFlagDetails } = useContext(FeatureFlagContext);
  const { AgreementInfoRes, setAgreementInfoRes } = props;
  const {
    settransferRights,
    setPreviousItemData,
    searchCriteria,
    setsearchCriteria,
    setnumberOfInventory,
    setreinstatementGridData,
    setoldInvRV,
    setstoreReinstateInvID,
    setepoAmount,
    setisSacDaysCompleted,
    setisSOEnabledbyconfig,
    setisChoosePriceConfig,
    gotSOConfigResponse,
    setgotSOConfigResponse,
  } = useContext(ReinstateAgreementContext);
  const { redirectOrigin } = useContext(OriginContext);
  const reg = /^\d+$/;
  let { agreementId } = useParams<Params>();
  const { customerId } = useParams<Params>();
  const location = useLocation();
  if (redirectOrigin === AMOrigin) {
    agreementId = agreementId.replace(AccountManagementURLParam, '');
  }
  const [gotReinstateInfo, setgotReinstateInfo] = useState(false);
  const hasCustomerId =
    customerId !== undefined && customerId.match(reg) ? true : false;
  const hasAgreementId =
    agreementId !== undefined && agreementId.match(reg) ? true : false;

  useEffect(() => {
    const AgreementInfoCall = async () => {
      const agrInfoResponse = await getAgreementInfo(agreementId);
      console.log('Reinstate agreement info call ', agrInfoResponse);
      if (agrInfoResponse.status === 200) {
        const agreementInfo = agrInfoResponse.data;
        console.log('Reinstate agreement info data', agreementInfo);
        // Set Context
        // Start of item search context set
        const PreItemData = {
          PreItemAgreementRate: parseFloat(
            agreementInfo.agreementInformation.agreementWeeklyRate
          ),
          PreItemAgreementTerm: parseFloat(
            agreementInfo.agreementInformation.weeklyRemainingTerm
          ),
          PreItemSacPrice: parseFloat(
            agreementInfo.agreementInformation.epoAmount
          ),
        };
        setPreviousItemData(PreItemData);
        console.log(
          'agreementInfo.agreementInventories.length',
          agreementInfo.agreementInventories.length
        );

        if (agreementInfo.agreementWorkflow.isSacDaysCompleted == '0') {
          setisSacDaysCompleted(false);
        } else if (agreementInfo.agreementWorkflow.isSacDaysCompleted == '1') {
          setisSacDaysCompleted(true);
        }

        setepoAmount(agreementInfo.agreementInformation.epoAmount);

        setnumberOfInventory(agreementInfo.agreementInventories.length);
        console.log(
          'greementInfo.agreementInventories[0].inventoryNumber',
          agreementInfo.agreementInventories[0].inventoryNumber
        );

        let calculatedRV = 0;
        const itemPricing: any = agreementInfo.agreementInventories.map(
          (value: any) => {
            const individualArray: any = {
              inventoryName: value.description, //
              inventoryId: value.inventoryId, //
              inventoryNumber: value.inventoryNumber, //
              rmsItemNumber: value.rmsItemNumber, //
              weeklyRate: value.tagRate, //
              weeklyTerm: value.tagTerm, //
              cashPrice: value.cashPrice, //
              remainingValue: value.remainingValue, //
              tagTotal: value.tagTotal, //
              inventoryDepreciation: value.inventoryDepreciation, //
              agreementRate: value.weeklyAgreementRate, //
              agreementTotal: value.agreementTotal, //
              packageName:
                agreementInfo.agreementInformation.agreementDescription, //
              itemNumber: value.inventoryNumber,
              department: value.department,
              subdepartment: value.subDepartment,
              brand: value.brand,
              model: value.modelNumber !== null ? value.modelNumber : '-',
              rate: value.tagRate,
              term: agreementInfo.agreementInformation.weeklyRemainingTerm,
              originalAgreementTerm: agreementInfo.agreementTotal.agreementTerm,
              condition: value.itemCondition,
              remainingAgreementTotal: value.remainingAgreementTotal,
              inventoryStoreNumber: value.inventoryStoreNumber,
              inventoryStatus: value.inventoryStatus,
              currentTagTotal: value.currentTagTotal,
              currentTagRate: value.currentTagRate,
              currentTagTerm: value.currentTagTerm,
            };
            calculatedRV = calculatedRV + parseInt(value.remainingValue);
            setoldInvRV(calculatedRV);
            return individualArray;
          }
        );

        console.log('framed itemPricing in reinst', itemPricing);

        const ItemPricingContext: any = {
          itemPricing: itemPricing,
          agreementPricing: {
            tagRate: agreementInfo.agreementTotal.tagRate, //
            tagTerm: agreementInfo.agreementTotal.tagTerm, //
            tagTotal: agreementInfo.agreementTotal.tagTotal, //
            agreementRate:
              agreementInfo.agreementInformation.agreementWeeklyRate, //
            agreementTerm:
              agreementInfo.agreementInformation.weeklyRemainingTerm, //
            cashPrice: agreementInfo.agreementInformation.epoAmount, //
            agreementTotal: agreementInfo.agreementTotal.agreementTotal, //
            agreementInventoryDepreciation:
              agreementInfo.agreementTotal.agreementInventoryDepreciation, //
            remainingPaymentAmount:
              agreementInfo.agreementInformation.remainingPaymentAmount,
          },
          isPackage: false, //
          packageInformation: null, //
        };

        console.log(
          'ItemPricingContext in reinstate formation',
          ItemPricingContext
        );

        setreinstatementGridData(ItemPricingContext);
        console.log(
          ' agreementInfo.agreementInventories[0].department',
          agreementInfo.agreementInventories[0].department
        );
        if (agreementInfo.agreementInventories.length > 1) {
          console.log(
            'about to set package name',
            agreementInfo.agreementInformation.packageName
          );
        }
        setsearchCriteria({
          ...searchCriteria,
          departments: agreementInfo.agreementInventories[0].department,
          packageTypeval:
            agreementInfo.agreementInventories.length > 1
              ? agreementInfo.agreementInformation.packageName
              : '',
        });

        const agreementCreateStatus: any = {
          agreementCreatedStatus: true,
        };
        console.log(
          'switch out Custom Route - agreementCreateStatus',
          agreementCreateStatus
        );

        // Start of Agreement Details context set

        const paymentSchedule: any = {
          weeklyRate: agreementInfo.agreementInformation.agreementWeeklyRate, //
          weeklyTerm: parseInt(
            agreementInfo.agreementInformation.agreementWeeklyTerm
          ), //
          biweeklyRate:
            agreementInfo.agreementInformation.agreementBiWeeklyRate, //
          biweeklyTerm: parseInt(
            agreementInfo.agreementInformation.agreementBiWeeklyTerm
          ), //
          semimonthlyRate:
            agreementInfo.agreementInformation.agreementSemiMonthlyRate, //
          semimonthlyTerm: parseInt(
            agreementInfo.agreementInformation.agreementSemiMonthlyTerm
          ), //
          monthlyRate: agreementInfo.agreementInformation.agreementMonthlyRate, //
          monthlyTerm: parseInt(
            agreementInfo.agreementInformation.agreementMonthlyTerm
          ), //
        };

        const agreementSchedule: any = {
          schedule: agreementInfo.agreementInformation.scheduleRefCode, //
          dueDate: agreementInfo.agreementInformation.dueDate, //
        };

        const agreementInitialPayment: any = {
          rate: agreementInfo.initialPayment.rate, //
          initialRateTax: '0.00', //
          ldw: agreementInfo.initialPayment.ldw, //
          ldwTax: '0.00', //
          policy: agreementInfo.initialPayment.policy, //
          policyTax: '0.00', //
          extensionAmount: agreementInfo.initialPayment.extensionAmount, //
          extensionAmountTax: '0.00', //
          otherFees: agreementInfo.initialPayment.other, //
          otherFeesTax: '0.00', //
          totalTax: agreementInfo.initialPayment.tax, //
          initialPayment: agreementInfo.initialPayment.initialPayment, //
        };

        const agreementTotalPayment: any = {
          initialPaymentTotal: agreementInfo.totalPayment.initialPayment, //
          regularPaymentsCount: agreementInfo.totalPayment.regularTerm, //
          regularPaymentsAmount: agreementInfo.totalPayment.regularPayment, //
          finalPayment: agreementInfo.totalPayment.finalPayment, //
          totalPaymentsCount: agreementInfo.totalPayment.totalTerm, //
          sacTotal: agreementInfo.totalPayment.cashPrice, //
          epoAmount: agreementInfo.agreementInformation.epoAmount,
          agreementTotal: agreementInfo.totalPayment.totalCost,
        };

        const employerPayschedule: any = {
          schedule: agreementInfo.agreementInformation.paySchedule, //
          daysPaid: agreementInfo.agreementInformation.payDay, //
        };

        const fees: any[] = []; //

        const fee: any = {
          feeAmount: agreementInfo.initialPayment.other,
          feeType: 'OTHER',
        };

        fees.push(fee);

        const popupCompleted: any = {
          vehicleInfoComplete: true, //
          confirmDueDateComplete: true, //
          customerSignComplete:
            agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
          printPopupComplete: agreementInfo.agreementWorkflow.isPrinted == '1', //
          sendAgreementComplete:
            agreementInfo.agreementWorkflow.isPrinted == '1', //
          autoPayComplete: true, //
          SMSAutheticationComplete:
            agreementInfo.agreementWorkflow.isAgreementSigned == '1', //
          initialPaymentComplete:
            agreementInfo.agreementWorkflow.isInitialPaymentPaid == '1',
        };

        const customerInfo = agreementInfo.agreementCustomer.filter(
          (element) => element.priority == '1'
        );
        const coCustomerInfo = agreementInfo.agreementCustomer.filter(
          (element) => element.priority == '2'
        );
        if (customerInfo[0].customerId === customerId) {
          settransferRights(false);
        } else {
          settransferRights(true);
        }

        const customers: any = {
          customer: customerInfo.map((ele) => {
            const customer: any = {
              priority: ele.priority,
              customerName: ele.firstName + ' ' + ele.lastName,
              firstName: ele.firstName,
              lastName: ele.lastName,
              customerId: ele.customerId,
              emailAddress: ele.emailId,
              ssn: null,
              gcdid: null,
              phoneNumber: ele.phoneNumber,
              isDocumentGenerated: ele.isDocumentGenerated,
            };

            return customer;
          }), //
          coCustomer: coCustomerInfo.map((ele) => {
            const coCustomer: any = {
              priority: ele.priority,
              customerName: ele.firstName + ' ' + ele.lastName,
              firstName: ele.firstName,
              lastName: ele.lastName,
              customerId: ele.customerId,
              emailAddress: ele.emailId,
              ssn: null,
              gcdid: null,
              phoneNumber: ele.phoneNumber,
              isDocumentGenerated: ele.isDocumentGenerated,
            };

            return coCustomer;
          }), //
        };

        const promoCode = agreementInfo.agreementInformation.promoCode
          ? agreementInfo.agreementInformation.promoCode
          : '';

        const agreementOption: any = {
          agreementSource: [
            {
              value: agreementInfo.agreementInformation.agreementSourceRefCode, //
              label: agreementInfo.agreementInformation.agreementSource, //
            },
          ],
          optServices: [
            {
              value:
                agreementInfo.agreementInformation.coverageTypeRefCode || '', //
              label: agreementInfo.agreementInformation.optServices || 'None', //
            },
          ],
          salesPerson: [
            {
              value:
                agreementInfo.agreementInformation.salesPersonId.toString(), //
              label: agreementInfo.agreementInformation.salesPerson, //
            },
          ],
          schedule: [
            {
              value: agreementInfo.agreementInformation.scheduleRefCode, //
              label: agreementInfo.agreementInformation.paymentSchedule, //
            },
          ],
          deliveryCharge: [
            {
              value: agreementInfo.agreementInformation.deliveryCharge,
              label: agreementInfo.agreementInformation.deliveryCharge,
            },
          ],
          promoCode: [
            {
              value: promoCode, //
              label: promoCode, //
            },
          ],
          daysExtension: [
            {
              value: agreementInfo.agreementInformation.daysExtension,
              label: agreementInfo.agreementInformation.daysExtension,
            },
          ],
        };

        const generalInformation: any = {
          agreementType: agreementInfo.agreementInformation.agreementType, //
          agreementSourceValue:
            agreementInfo.agreementInformation.agreementSourceRefCode, //
          agreementStatus: agreementInfo.agreementInformation.agreementStatus, //
          deliveryChargeValue:
            agreementInfo.agreementInformation.deliveryCharge, //
          daysExtensionValue: agreementInfo.agreementInformation.daysExtension, //
          optServicesValue:
            agreementInfo.agreementInformation.coverageTypeRefCode || '', //
          promoCodeValue: promoCode, //
          policyValue:
            agreementInfo.agreementInformation.policy !== 'N/A'
              ? 'RAC Tire Plus'
              : 'None',
          salesPersonValue:
            agreementInfo.agreementInformation.salesPersonId.toString(), //
          isAutopayEnabled:
            agreementInfo.agreementWorkflow.isAutopayEnabled == '1', //
          isLdwWaived: agreementInfo?.agreementInformation?.isLdwWaived == '1',
        };

        const agreementDetailsContext: any = {
          paymentSchedule,
          agreementSchedule,
          agreementInitialPayment,
          agreementTotalPayment,
          employerPayschedule,
          fees,
          policyApplicable:
            agreementInfo.agreementInformation.policy !== 'N/A' ? 1 : 0,
          popupCompleted,
          agreementId: agreementInfo.agreementInformation.agreementId,
          agreementNumber: agreementInfo.agreementInformation.agreementNumber,
          customers,
          agreementOption,
          generalInformation,
          agreementTotal: agreementInfo.totalPayment.totalCost,
          agreementWorkflow: agreementInfo.agreementWorkflow,
        };

        console.log(
          'Custom Route - agreementDetailsContext',
          agreementDetailsContext
        );
        setgotReinstateInfo(true);
      }
      // End on Agreement Details context set
      // End of context set

      // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    const initialTrigger = async () => {
      const StoreId = window.sessionStorage.getItem('storeNumber');
      const bodyobj = {
        storeNumbers: [StoreId],

        paramKeyNames: [
          'Switchout',
          'ChoosingPricingTag',
          'waiveLdw',
          'AgreementReturn',
          'RateReduction',
          'InstallmentNotes',
          'CustomerOrder',
          'CompetitorMatch',
          'CustomerVerificationOptimization',
          'EnableDEDeclineApproval',
          ADD_ON_PRICING
        ],
      };
      if (gotSOConfigResponse === false) {
        let switchoutresponse
        if (StoreId && StoreId != null && StoreId != undefined && StoreId != '' && StoreId != 'null' && StoreId != 'undefined') {
          switchoutresponse = await CoworkerRequired(bodyobj);
        }
        if (switchoutresponse?.status === 200) {
          const value =
            switchoutresponse.data !== null &&
              switchoutresponse.data.storeProfileResponse !== undefined &&
              switchoutresponse.data.storeProfileResponse &&
              switchoutresponse.data.storeProfileResponse.configDetails !==
              undefined &&
              switchoutresponse.data.storeProfileResponse.configDetails.length >
              0 &&
              switchoutresponse.data.storeProfileResponse.configDetails[0]
                .configDetails !== undefined &&
              switchoutresponse.data.storeProfileResponse.configDetails[0]
                .configDetails.length > 0
              ? switchoutresponse.data.storeProfileResponse.configDetails[0]
                .configDetails[0].paramValue
              : 0;
          const filteredCPT =
            switchoutresponse.data.storeProfileResponse.configDetails[0].configDetails.filter(
              (x) => x.paramKeyName == 'ChoosingPricingTag'
            );
          const FeatureFlagObj = MakeFeatureFlagObjFn(
            switchoutresponse.data.storeProfileResponse.configDetails[0]
              .configDetails
          );
          setFeatureFlagDetails(FeatureFlagObj);
          // const priceTagValue =
          //   switchoutresponse.data.storeProfileResponse.configDetails[0]
          //     .configDetails[1].paramValue;
          console.log('value', value);
          setisSOEnabledbyconfig(value);
          setisChoosePriceConfig(filteredCPT[0].paramValue);
          setgotSOConfigResponse(true);
        }
      }
      if (hasCustomerId && hasAgreementId) {
        // To get Switch out data
        AgreementInfoCall();
      } else {
        setgotReinstateInfo(true);
        console.log('Error in switch agreement route');
      }
    };
    initialTrigger();
  }, []);

  return (
    <>
      {gotReinstateInfo ? (
        <>
          <CustomRoute path={AppRoute.CreateReinstateAgreement}>
            <CreateReinstateAgreementRoute />
          </CustomRoute>
        </>
      ) : (
        <>
          <div style={{ margin: '20% 20% 20% 48%' }}>
            <CircularProgress size={30} />
          </div>
        </>
      )}
    </>
  );
};
const AgreementTransferRoute = (props: any) => {
  const { setFeatureFlagDetails } = useContext(FeatureFlagContext);
  const [gotFeatureFlagResponse, setGotFeatureFlagResponse] = useState(false);
  useEffect(() => {
    const featureFlagCall = async () => {
      const StoreId = window.sessionStorage.getItem('storeNumber');
      const bodyobj = {
        storeNumbers: [StoreId],
        paramKeyNames: [
          'AgreementTransferReceive',
          'AgreementTransfer',
          'EnableClubTransfer',
          'Switchout',
          'ChoosingPricingTag',
          'waiveLdw',
          'AgreementReturn',
          'RateReduction',
          'InstallmentNotes',
          'AgreementChargeOff',
          'CompetitorMatch',
          'CustomerVerificationOptimization',
          'EnableDEDeclineApproval'
        ],
      };
      let featureFlagresponse
      if (StoreId && StoreId != null && StoreId != undefined && StoreId != '' && StoreId != 'null' && StoreId != 'undefined') {
        featureFlagresponse = await CoworkerRequired(bodyobj);
      }
      if (featureFlagresponse?.status == 200) {
        const FeatureFlagObj = MakeFeatureFlagObjFn(
          featureFlagresponse?.data?.storeProfileResponse?.configDetails[0]
            ?.configDetails
        );
        setFeatureFlagDetails(FeatureFlagObj);
        setGotFeatureFlagResponse(true);
      }
    };
    featureFlagCall();
  }, []);

  return (
    <>
      {gotFeatureFlagResponse ? (
        <>
          <Route path={AppRoute.AgreementTransfer}>
            <AgreementTransferRequest />
          </Route>
          <Route path={AppRoute.AgreementTransferReceive}>
            <AgreementTransferReceive />
          </Route>
        </>
      ) : (
        <>
          <div style={{ margin: '20% 20% 20% 48%' }}>
            <CircularProgress size={30} />
          </div>
        </>
      )}
    </>
  );
};
const LoanerAgreementRoute = (props: any) => {
  const { featureFlagDetails, setFeatureFlagDetails } =
    useContext(FeatureFlagContext);
  const [gotGetAgreementInfo, setgotGetAgreementInfo] = useState(false);
  const { setgetAgrInfoResponse } = useContext(LoanerContext);
  const { agreementId } = useParams<Params>();
  const { customerId } = useParams<Params>();
  const location = useLocation();
  const history = useHistory();
  const [routeUpdated, setRouteUpdated] = useState(false);
  useEffect(() => {
    const initialTrigger = async () => {
      const StoreNumber = window.sessionStorage.getItem('storeNumber');
      const bodyobj = {
        storeNumbers: [StoreNumber],
        paramKeyNames: [
          'Switchout',
          'ChoosingPricingTag',
          'waiveLdw',
          'AgreementReturn',
          'RateReduction',
          'InstallmentNotes',
          'AgreementChargeOff',
          'EnableAccess',
          'SundaySky',
          'loanerAgreement',
          'FieldDevice',
          'CompetitorMatch',
          'CustomerVerificationOptimization',
          'EnableDEDeclineApproval',
          ADD_ON_PRICING
        ],
      };
      let featureFlagResponse
      if (StoreNumber && StoreNumber != null && StoreNumber != undefined && StoreNumber != '' && StoreNumber != 'null' && StoreNumber != 'undefined') {
        featureFlagResponse = await CoworkerRequired(bodyobj);
      }
      if (featureFlagResponse.status === 200) {
        const featureFlagData = featureFlagResponse?.data;
        if (
          featureFlagData?.storeProfileResponse?.configDetails[0].configDetails
        ) {
          const FeatureFlagObj = MakeFeatureFlagObjFn(
            featureFlagData.storeProfileResponse.configDetails[0].configDetails
          );
          setFeatureFlagDetails(FeatureFlagObj);
        }
      }
    };
    initialTrigger();
    const changeRoutes = (agrInfo: any) => {
      console.log('agrInfo for loaner', agrInfo);
      if (
        agrInfo.agreementInformation.agreementType !== 'Rent to Rent - Loaner'
      ) {
        return `/agreement/loaner/itemsearch/${customerId}/${agreementId}`;
      }
      //  else if (
      //   agrInfo.agreementInformation.agreementType == 'Rent to Rent - Loaner' &&
      //   agrInfo.agreementWorkflow.isDeliveryScheduled == '0' &&
      //   agrInfo.agreementWorkflow.isDeliveryCompleted == '0' &&
      //   agrInfo.agreementWorkflow.isAgreementSigned == '0'
      // ) {
      //   // TODO : Customer Order POStatus PENDING
      //   console.log('in change route - TODO : CUSTOMER ORDER PENDING');
      //   return `/agreement/loaner/details/${customerId}/${agreementId}`;
      // } 
      else if (
        agrInfo.agreementInformation.agreementType == 'Rent to Rent - Loaner' &&
        agrInfo.agreementWorkflow.isDeliveryScheduled == '0'
        // agrInfo.agreementWorkflow.isAgreementSigned == '1'
      ) {
        return `/agreement/loaner/delivery/${customerId}/${agreementId}`;
      } else if (
        agrInfo.agreementInformation.agreementType == 'Rent to Rent - Loaner' &&
        agrInfo.agreementWorkflow.isDeliveryScheduled == '1'
        // agrInfo.agreementWorkflow.isAgreementSigned == '1'
      ) {
        // TODO : DOCUMENT CREATION PENDING
        console.log('in change route - TODO : DOCUMENT CREATION PENDING');
        return `/agreement/info/details/${customerId}/${agreementId}`;
      } else {
        return `/agreement/loaner/itemsearch/${customerId}/${agreementId}`;
      }
    };
    const getAgrInfoFn = async () => {
      const getAgrInfoRes = await getAgreementInfo(agreementId);
      if (getAgrInfoRes.status == 200) {
        const agrInfoData = getAgrInfoRes?.data;
        setgetAgrInfoResponse(agrInfoData);
        setgotGetAgreementInfo(true);
        const updatedRoute: string = changeRoutes(agrInfoData);
        if (location.pathname !== updatedRoute) {
          history.push(updatedRoute);
          // communicate to Routes that URL has changed
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
        }
        setRouteUpdated(true);
      }
    };
    getAgrInfoFn();
  }, []);

  return (
    <>
      {routeUpdated ? (
        <>
          {featureFlagDetails?.loanerAgreement == '1' ? (
            <>
              <CustomRoute path={AppRoute.CreateLoanerAgreement}>
                <LoanerAgreementRoutes />
              </CustomRoute>
              <CustomRoute path={AppRoute.AgreementView}>
                <ViewAgreementRoute />
              </CustomRoute>
            </>
          ) : (
            <>
              <ComingSoonPage />
            </>
          )}
        </>
      ) : (
        <>
          <div style={{ margin: '20% 20% 20% 48%' }}>
            <CircularProgress size={30} />
          </div>
        </>
      )}
    </>
  );
};

//PromoAgreementRoute
const PromoOrderRoute = () => {
  const { setFeatureFlagDetails, setSacRolesLimitDetails, setPriceOverrideLimitDetails, setTermOverrideLimitDetails } = useContext(FeatureFlagContext);
  const [gotPromoAgrInfo, setgotPromoAgrInfo] = useState(false); // Need to make this false
  const { agreementId } = useParams<Params>();
  const { customerId } = useParams<Params>();
  const location = useLocation();
  const history = useHistory();
  const {
    wholeAgrInfoResponse,
    setsearchResult,
    setIsAllDone,
    setagreementCreateStatus,
  } = useContext(CustomerOrderAgreementContext);
  console.log('COwholeAgrInfoResponse', wholeAgrInfoResponse);
  const { viewAgreementData, gotSOConfigResponse,
    setisSOEnabledbyconfig,
    setisChoosePriceConfig,
    setresumebtnFeatureFlag,
    setgotSOConfigResponse, } = useContext(AgreementContext);
  const { setPromoAgrWholeResponse } = useContext(PromoAgreementContext)
  useEffect(() => {
    console.log('viewAgreementDatahvhh', viewAgreementData);
    const changeRoutes = (agrInfo: any) => {
      console.log('agrInfo for promo', agrInfo);
      if (
        agrInfo.agreementInformation.agreementType == 'Promo' &&
        agrInfo.agreementWorkflow.isDeliveryScheduled == '1' &&
        agrInfo.agreementWorkflow.isPromoInitialPaymentPaid == '1'
      ) {
        return `/agreement/info/details/${customerId}/${agreementId}`;
      }
    };
    if (viewAgreementData != undefined) {
      // setIsAllDone(true);
      setPromoAgrWholeResponse(viewAgreementData);
      setagreementCreateStatus({ agreementCreatedStatus: true });
      const rmsItemInformation = viewAgreementData?.agreementInventories.map(
        (el: any) => {
          return {
            modelNumber: el.modelNumber,
            brand: el?.brand == undefined ? '-' : el?.brand,
            cashPrice: viewAgreementData.totalPayment.cashPrice,
            department: el.department,
            subdepartment: el.subDepartment,
            monthlyRate: el.agreementRate,
            monthlyTerm: el.agreementTerm,
            totalCost: el.agreementTotal,
            availability: null,
          };
        }
      );
      setsearchResult(rmsItemInformation);
    }
    else if (agreementId) {
      const getAgrInfoFn = async () => {
        const getAgrInfoRes = await getAgreementInfo(agreementId);
        if (getAgrInfoRes.status == 200) {
          const agrInfoData = getAgrInfoRes?.data;
          setPromoAgrWholeResponse(viewAgreementData);
          //setagreementCreateStatus({ agreementCreatedStatus: true });
          const rmsItemInformation = viewAgreementData?.agreementInventories.map(
            (el: any) => {
              return {
                modelNumber: el.modelNumber,
                brand: el?.brand == undefined ? '-' : el?.brand,
                cashPrice: viewAgreementData.totalPayment.cashPrice,
                department: el.department,
                subdepartment: el.subDepartment,
                monthlyRate: el.agreementRate,
                monthlyTerm: el.agreementTerm,
                totalCost: el.agreementTotal,
                availability: null,
              };
            }
          );
          setsearchResult(rmsItemInformation);
          const updatedRoute: string | undefined = changeRoutes(agrInfoData);
          if (updatedRoute != undefined) {
            if (location.pathname !== updatedRoute) {
              history.push(updatedRoute);
              // communicate to Routes that URL has changed
              const navEvent = new PopStateEvent('popstate');
              window.dispatchEvent(navEvent);
            }
            setgotPromoAgrInfo(true);
          }
        }
      };
      getAgrInfoFn();
    }
  }, [viewAgreementData]);
  useEffect(() => {
    const initialTrigger = async () => {

      const storeNumber = window.sessionStorage.getItem('storeNumber')
      const bodyobj = {
        storeNumbers: [window.sessionStorage.getItem('storeNumber')],

        paramKeyNames: [
          'Switchout',
          'ChoosingPricingTag',
          'waiveLdw',
          'AgreementReturn',
          'RateReduction',
          'InstallmentNotes',
          'AgreementChargeOff',
          'EnableAccess',
          'SundaySky',
          'loanerAgreement',
          'CompetitorMatch',
          'CustomerVerificationOptimization',
          'EnableDEDeclineApproval', 
          ADD_ON_PRICING
        ],
      };
      let switchoutresponse
      if (storeNumber && storeNumber != null && storeNumber != undefined && storeNumber != '' && storeNumber != 'null' && storeNumber != 'undefined') {
        switchoutresponse = await CoworkerRequired(bodyobj);
      }
      if (switchoutresponse?.status === 200) {
        const value =
          switchoutresponse.data !== null &&
            switchoutresponse.data.storeProfileResponse !== undefined &&
            switchoutresponse.data.storeProfileResponse &&
            switchoutresponse.data.storeProfileResponse.configDetails !==
            undefined &&
            switchoutresponse.data.storeProfileResponse.configDetails.length >
            0 &&
            switchoutresponse.data.storeProfileResponse.configDetails[0]
              .configDetails !== undefined &&
            switchoutresponse.data.storeProfileResponse.configDetails[0]
              .configDetails.length > 0
            ? switchoutresponse.data.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue
            : 0;
        const filteredCPT =
          switchoutresponse.data.storeProfileResponse.configDetails[0].configDetails.filter(
            (x) => x.paramKeyName == 'ChoosingPricingTag'
          );
        const FeatureFlagObj = MakeFeatureFlagObjFn(
          switchoutresponse.data.storeProfileResponse.configDetails[0]
            .configDetails
        );
        const sacRolesLimitObj = makeSACrolesAllocationFn(
          switchoutresponse.data.storeProfileResponse.configDetails[0].configDetails);
        const priceOverRideRolesLimitObject = makePriceOverRiderolesAllocationFn(switchoutresponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails)
        const termOverRideRolesLimitObject = makeTermOverRiderolesAllocationFn(switchoutresponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails)

        const resumeV =
          switchoutresponse?.data?.storeProfileResponse?.configDetails[0]
            ?.configDetails;
        const resumebtnEnable = resumeV.filter((element: any) => {
          return (
            element.paramKeyName == 'EnableAccess' &&
            element.paramGroupName == 'sendMAMVideo'
          );
        });
        console.log('resumebtnEnablePromo', resumebtnEnable);
        setresumebtnFeatureFlag(resumebtnEnable[0]?.paramValue);
        console.log('FeatureFlagObj after obj createPromo', FeatureFlagObj);
        console.log('sacRolesLimitObjPRomo', sacRolesLimitObj);
        setSacRolesLimitDetails(sacRolesLimitObj);
        setPriceOverrideLimitDetails(priceOverRideRolesLimitObject)
        setTermOverrideLimitDetails(termOverRideRolesLimitObject)
        setFeatureFlagDetails(FeatureFlagObj);
        console.log('valuePromo', value);
        setisSOEnabledbyconfig(value);
        setisChoosePriceConfig(filteredCPT[0].paramValue);
        setgotSOConfigResponse(true);
        setgotPromoAgrInfo(true);
      }

    };
    initialTrigger();

    console.log('gotPromoAgrInfo', gotPromoAgrInfo);
  }, []);
  return (
    <>
      {gotPromoAgrInfo ? (
        <>
          <CustomRoute path={AppRoute.PromoAgreement}>
            <PromoAgreementRoute />
          </CustomRoute>
        </>
      ) : (
        <>
          <div style={{ margin: '20% 20% 20% 48%' }}>
            <CircularProgress size={30} />
          </div>
        </>
      )}
    </>
  );
};

export const Routes = () => {
  console.log('in routes comp');
  const [AgreementInfoRes, setAgreementInfoRes] = useState();
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  const { setGetCustomerResponse } = useContext(AgreementContext)
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const { customerId } = useParams<Params>();
  return (
    <UserProvider>
      <UserFetcher />
      <div data-testid={routesTestId}>
        <Route
          path={[
            AppRoute.ItemSearch,
            AppRoute.AgreementDetails,
            AppRoute.InitialPayment,
            AppRoute.DeliveryMethod,
            AppRoute.DeliveryMethodView,
            AppRoute.AgreementActivity,
            AppRoute.AgreementInformation,
          ]}
        >
          {console.log('inside original react route')}
          <AgreementCustomRoute
            AgreementInfoRes={AgreementInfoRes}
            setAgreementInfoRes={setAgreementInfoRes}
          />
        </Route>
        <Route
          path={[
            AppRoute.SwitchOutItemSearch,
            AppRoute.SwitchOutAgreementDetails,
            AppRoute.SwitchOutDeliveryMethod,
          ]}
        >
          {console.log('inside original react route of switchout')}
          <SwitchOutCustomRoute
            AgreementInfoRes={AgreementInfoRes}
            setAgreementInfoRes={setAgreementInfoRes}
          />
        </Route>
        <Route
          path={[
            AppRoute.ReinstateItemSearch,
            AppRoute.ReinstateAgrDetails,
            AppRoute.ReinstateDeliverymethod,
          ]}
        >
          {console.log('inside original react route of reinstate')}
          <ReinstateCustomRoute
            AgreementInfoRes={AgreementInfoRes}
            setAgreementInfoRes={setAgreementInfoRes}
          />
        </Route>
        <Route
          path={[
            AppRoute.CustomerOrderItemSearch,
            AppRoute.CustomerOrderAgrDetails,
            AppRoute.POStatus,
            AppRoute.CustomerOrderDeliveryMethod,
          ]}
        >
          {console.log('inside original react route of customer order')}
          <CustomerOrderRoute />
        </Route>
        <Route
          path={[
            AppRoute.PromoItemSearch,
            AppRoute.PromoAgrDetails,
            AppRoute.PromoDeliveryMethod,
          ]}
        >
          {console.log('inside original react route of Promo Agreement')}
          <PromoOrderRoute />
        </Route>
        <Route path={AppRoute.splitAgreement}>
          <SplitAgreement />
        </Route>
        <Route
          path={[AppRoute.AgreementTransfer, AppRoute.AgreementTransferReceive]}
        >
          <AgreementTransferRoute />
        </Route>
        <Route
          path={[
            AppRoute.LoanerItemSearch,
            AppRoute.LoanerAgreementDetails,
            AppRoute.LoanerDeliveryMethod,
          ]}
        >
          <LoanerAgreementRoute />
        </Route>
        <Route path={[AppRoute.pendingAgreement]}>
          <PendingAgreement />
        </Route>
      </div>
    </UserProvider>
  );
};
