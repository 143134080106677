/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState, useContext } from 'react';
import CustomerInfo from './CustomerInfo';
//import { agreementGlobalStyles } from '../../JSstyles/agreementGlobalStyles';
import { wizardStyles } from '../../../JSstyles/wizardStyles';
import { Grid, List, ListItem, Box } from '@rentacenter/racstrap';
import { ReactComponent as CompletedIcon } from '../../../assets/images/wizard-complete.svg';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { CustomerOrderAgreementContext } from '../../../context/CustomerOrderAgreementContext';

export type CoCustomerDetails = {
  coCustomerId: string;
  coCustomerName: string;
};
export type WizardProps = {
  ItemSearchCompleted: boolean;
  AgreementDetailsCompleted: boolean;
  PreliminaryPaymentCompleted: boolean;
  POStatusCompleted: boolean;
  InitialPaymentCompleted: boolean;
  DeliveryMethodCompleted: boolean;
  CoCustomerDetails: CoCustomerDetails;
  dethrower: boolean;
  // throwAmount: any;
  WizardAgrId: string;
  setWizardAgrId: any;
};
interface Params {
  customerId: string;
  agreementId: string;
}
export default function Wizard(props: WizardProps) {
  // const classes = agreementGlobalStyles();
  const wizardClassName = wizardStyles();
  const { AgreementId } = useContext(CustomerOrderAgreementContext);
  const { customerId, agreementId } = useParams<Params>();
  const history = useHistory();
  const {
    ItemSearchCompleted,
    AgreementDetailsCompleted,
    PreliminaryPaymentCompleted,
    InitialPaymentCompleted,
    POStatusCompleted,
    DeliveryMethodCompleted,
    CoCustomerDetails,
    dethrower,
    // throwAmount,
    WizardAgrId,
  } = props;
  // const ActivePageCss = 'inactive active';
  // const WizardCssObj = {
  //   // eslint-disable-next-line sonarjs/no-duplicate-string
  //   ItemSearchCss: 'inactive done',
  //   AgreementDeatilsCss: ActivePageCss,
  //   InitialPaymentCss: 'inactive',
  //   DeliveryMethod: 'inactive',
  // };
  // const [WizardStatus, setWizardStatus] = useState(WizardCssObj);
  const [CocustomerDetailsValue, setCoCustomerDetails] =
    useState<CoCustomerDetails>(CoCustomerDetails);

  const dynamicClasses: any = {
    complete: {
      anchor: wizardClassName.smartWizardListLinkActive,
      spanNum: `${wizardClassName.borderDefault} ${wizardClassName.done}`,
      spanDetail: wizardClassName.stepDone,
    },
    inprogress: {
      anchor: wizardClassName.smartWizardListLinkInActive,
      spanNum: wizardClassName.borderActive,
      spanDetail: wizardClassName.stepActive,
    },
    pending: {
      anchor: wizardClassName.smartWizardListLinkInActive,
      spanNum: wizardClassName.borderDefault,
      spanDetail: wizardClassName.stepNotStarted,
    },
  };

  const [itemSearchStatus, setItemSearchStatus] = useState('pending');
  const [agreementDetailStatus, setAgreementDetailStatus] = useState('pending');
  const [preliminaryPaymentStatus, setpreliminaryPaymentStatus] =
    useState('pending');
  const [POStatus, setPOStatus] = useState('pending');
  const [initialPaymentStatus, setInitialPaymentStatus] = useState('pending');
  const [deliveryMethodStatus, setDeliveryMethodStatus] = useState('pending');

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      'Inside wizard outside',
      CoCustomerDetails,
      CocustomerDetailsValue
    );
    if (
      CoCustomerDetails !== undefined &&
      CoCustomerDetails.coCustomerId !== undefined &&
      CoCustomerDetails.coCustomerId !== null &&
      CoCustomerDetails.coCustomerId !== '' &&
      CoCustomerDetails.coCustomerName !== undefined &&
      CoCustomerDetails.coCustomerName !== null &&
      CoCustomerDetails.coCustomerName !== ''
    ) {
      // eslint-disable-next-line no-console
      console.log('Inside wizard in wizard component', CoCustomerDetails);
      setCoCustomerDetails(CoCustomerDetails);
    }

    if (ItemSearchCompleted === false && AgreementDetailsCompleted === false) {
      setItemSearchStatus('inprogress');
      setAgreementDetailStatus('pending');
    } else if (
      ItemSearchCompleted === true &&
      AgreementDetailsCompleted === false
    ) {
      setItemSearchStatus('complete');
      setAgreementDetailStatus('inprogress');
    } else if (
      ItemSearchCompleted === true &&
      AgreementDetailsCompleted === true &&
      PreliminaryPaymentCompleted === false
    ) {
      setItemSearchStatus('complete');
      setAgreementDetailStatus('complete');
      setpreliminaryPaymentStatus('inprogress');
    } else if (
      ItemSearchCompleted === true &&
      AgreementDetailsCompleted === true &&
      PreliminaryPaymentCompleted === true &&
      POStatusCompleted === false
    ) {
      setItemSearchStatus('complete');
      setAgreementDetailStatus('complete');
      setpreliminaryPaymentStatus('complete');
      setPOStatus('inprogress');
    } else if (
      ItemSearchCompleted === true &&
      AgreementDetailsCompleted === true &&
      PreliminaryPaymentCompleted === true &&
      POStatusCompleted === true &&
      InitialPaymentCompleted === false
    ) {
      setItemSearchStatus('complete');
      setAgreementDetailStatus('complete');
      setpreliminaryPaymentStatus('complete');
      setPOStatus('complete');
      setInitialPaymentStatus('inprogress');
    } else if (
      ItemSearchCompleted === true &&
      AgreementDetailsCompleted === true &&
      PreliminaryPaymentCompleted === true &&
      POStatusCompleted === true &&
      InitialPaymentCompleted === true
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    ) {
      setItemSearchStatus('complete');
      setAgreementDetailStatus('complete');
      setpreliminaryPaymentStatus('complete');
      setPOStatus('complete');
      setInitialPaymentStatus('complete');
      setDeliveryMethodStatus('inprogress');
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ItemSearchCompleted,
    AgreementDetailsCompleted,
    InitialPaymentCompleted,
    DeliveryMethodCompleted,
    CoCustomerDetails,
  ]);
  const WizardFieldClick = (FieldType: any) => {
    if (FieldType == 'Itemsearch') {
      if (itemSearchStatus === 'complete') {
        if (AgreementId !== undefined && WizardAgrId == '') {
          if (agreementId) {
            history.push({
              pathname: `/agreement/customerorder/itemsearch/${customerId}/${agreementId}?coCustomerId=N`,
            });
          } else {
            history.push({
              pathname: `/agreement/customerorder/itemsearch/${customerId}/${AgreementId}?coCustomerId=N`,
            });
          }
        } else if (AgreementId === undefined && WizardAgrId == '') {
          history.push({
            pathname: `/agreement/customerorder/itemsearch/${customerId}/?coCustomerId=N`,
          });
        } else if (WizardAgrId !== '') {
          history.push({
            pathname: `/agreement/customerorder/itemsearch/${customerId}/${WizardAgrId}?coCustomerId=N`,
          });
        }

        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
      }
    } else if (FieldType == 'AgrDetails') {
      if (itemSearchStatus === 'complete') {
        if (AgreementId !== undefined) {
          if (agreementId) {
            history.push({
              pathname: `/agreement/customerorder/details/${customerId}/${agreementId}?coCustomerId=N`,
            });
          } else {
            history.push({
              pathname: `/agreement/customerorder/details/${customerId}/${AgreementId}?coCustomerId=N`,
            });
          }
        } else {
          history.push({
            pathname: `/agreement/customerorder/details/${customerId}/?coCustomerId=N`,
          });
        }
      }
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (FieldType == 'POStatus') {
      if (preliminaryPaymentStatus === 'complete') {
        if (AgreementId) {
          history.push({
            pathname: `/agreement/customerorder/postatus/${customerId}/${AgreementId}`,
          });
        } else {
          history.push({
            pathname: `/agreement/customerorder/postatus/${customerId}/${agreementId}`,
          });
        }
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
      } // Need to add this
    } else if (FieldType == 'DeliveryMethod') {
      if (initialPaymentStatus === 'complete') {
        if (AgreementId) {
          history.push({
            pathname: `/agreement/customerorder/delivery/${customerId}/${AgreementId}`,
          });
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
        } else {
          history.push({
            pathname: `/agreement/customerorder/delivery/${customerId}/${agreementId}`,
          });
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
        }
      }
    }
  };
  return (
    //Wizard starts from here
    <div data-testid="WizardTest">
      <Grid className={wizardClassName.swThemeDefault}>
        <Grid item className={wizardClassName.swThemeDefaultContainer}>
          <List className={wizardClassName.smartWizard}>
            <ListItem
              className={wizardClassName.smartWizardList}
              onClick={() => WizardFieldClick('Itemsearch')}
            >
              <a className={dynamicClasses[itemSearchStatus]['anchor']}>
                {itemSearchStatus === 'complete' ? (
                  <Box
                    component="span"
                    className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                  >
                    <CompletedIcon className={wizardClassName.paddingTop3} />
                  </Box>
                ) : (
                  <span className={dynamicClasses[itemSearchStatus]['spanNum']}>
                    1
                  </span>
                )}

                <span
                  className={dynamicClasses[itemSearchStatus]['spanDetail']}
                >
                  Item Search
                </span>
              </a>
            </ListItem>
            <ListItem
              className={wizardClassName.smartWizardList}
              onClick={() => WizardFieldClick('AgrDetails')}
            >
              <a className={dynamicClasses[agreementDetailStatus]['anchor']}>
                {agreementDetailStatus === 'complete' ? (
                  <Box
                    component="span"
                    className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                  >
                    <CompletedIcon className={wizardClassName.paddingTop3} />
                  </Box>
                ) : (
                  <span
                    className={dynamicClasses[agreementDetailStatus]['spanNum']}
                  >
                    2
                  </span>
                )}

                <span
                  className={
                    dynamicClasses[agreementDetailStatus]['spanDetail']
                  }
                >
                  Agreement Details
                </span>
              </a>
            </ListItem>
            <ListItem className={wizardClassName.smartWizardList}>
              <a
                className={dynamicClasses[preliminaryPaymentStatus]['anchor']}
                style={{ cursor: 'not-allowed' }}
              >
                {preliminaryPaymentStatus === 'complete' ? (
                  <Box
                    component="span"
                    className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                  >
                    <CompletedIcon className={wizardClassName.paddingTop3} />
                  </Box>
                ) : (
                  <span
                    className={
                      dynamicClasses[preliminaryPaymentStatus]['spanNum']
                    }
                  >
                    3
                  </span>
                )}

                <span
                  className={
                    dynamicClasses[preliminaryPaymentStatus]['spanDetail']
                  }
                >
                  Preliminary Payment
                </span>
              </a>
            </ListItem>
            <ListItem
              className={wizardClassName.smartWizardList}
              onClick={() => WizardFieldClick('POStatus')}
            >
              <a className={dynamicClasses[POStatus]['anchor']}>
                {POStatus === 'complete' ? (
                  <Box
                    component="span"
                    className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                  >
                    <CompletedIcon className={wizardClassName.paddingTop3} />
                  </Box>
                ) : (
                  <span className={dynamicClasses[POStatus]['spanNum']}>4</span>
                )}

                <span className={dynamicClasses[POStatus]['spanDetail']}>
                  PO Status
                </span>
              </a>
            </ListItem>
            <ListItem className={wizardClassName.smartWizardList}>
              <a
                className={dynamicClasses[initialPaymentStatus]['anchor']}
                style={{ cursor: 'not-allowed' }}
              >
                {initialPaymentStatus === 'complete' ? (
                  <Box
                    component="span"
                    className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                  >
                    <CompletedIcon className={wizardClassName.paddingTop3} />
                  </Box>
                ) : (
                  <span
                    className={dynamicClasses[initialPaymentStatus]['spanNum']}
                  >
                    5
                  </span>
                )}

                <span
                  className={dynamicClasses[initialPaymentStatus]['spanDetail']}
                >
                  Initial Payment
                </span>
              </a>
            </ListItem>
            <ListItem
              className={wizardClassName.smartWizardList}
              onClick={() => WizardFieldClick('DeliveryMethod')}
            >
              <a className={dynamicClasses[deliveryMethodStatus]['anchor']}>
                {deliveryMethodStatus === 'complete' ? (
                  <Box
                    component="span"
                    className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                  >
                    <CompletedIcon className={wizardClassName.paddingTop3} />
                  </Box>
                ) : (
                  <span
                    className={dynamicClasses[deliveryMethodStatus]['spanNum']}
                  >
                    6
                  </span>
                )}

                <span
                  className={dynamicClasses[deliveryMethodStatus]['spanDetail']}
                >
                  Delivery Method
                </span>
              </a>
            </ListItem>
          </List>
          <CustomerInfo
            CoCustomerDetails={CocustomerDetailsValue}
            dereceiver={dethrower}
            // amountThrower={throwAmount}
          />
        </Grid>
      </Grid>
    </div>
  );
}
