import React from 'react';
import { useHistory } from 'react-router-dom';

interface LinkProps {
  className?: string;
  href: string;
  children: any;
  style?: any;
  id: string;
  onClick?: any;
}

export const Link = ({
  className,
  href,
  children,
  style,
  id,
  onClick,
}: LinkProps) => {
  const history = useHistory();

  const onClickFn = (event: any) => {
    // prevent full page reload
    event.preventDefault();
    // update url
    history.push(href);
    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  return (
    <a
      className={className}
      href={href}
      onClick={(e) => {
        onClickFn(e);
        onClick();
      }}
      style={style}
      id={id}
    >
      {children}
    </a>
  );
};
