/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Route, useParams, useLocation } from 'react-router-dom';
import { AppRoute, AppRouteKey } from '../../../config/route-config';
import Deliverymethod from '../DeliveryMethod/DeliveryMethod';
import CustomerOrderSearchResult from '../ItemSearch/searchResult';
import POStatus from '../PO Status/POStatus';
import AgreementDetails from '../../CustomerOrder/AgreementDetails/CustomerOrderAgrDetails';
// import ReinstateDeliverymethod from '../../Reinstatement/DeliveryMethod/ReinstateDeliveryMethod';
import Wizard from '../Shared/Wizard';

import { Route as CustomRoute } from '../../../router/Route';
import ComingSoonPage from '../../Shared/ComingSoonPage';
import { FeatureFlagContext } from '../../../context/FeatureFlagContext';
export default function CreateCustomerOrderAgreementRoute() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  // const [coCustomerDetails, setcoCustomerDetails] = useState('');
  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    //setcoCustomerDetails()
    // eslint-disable-next-line no-console
    console.log('Footer contex', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };
  const [ItemSearchCompleted, setItemSearchCompleted] = useState(false);
  const [AgrDetailsCompleted, setAgrDetailsCompleted] = useState(false);
  const [changeDEStatus, setchangeDEStatus] = useState(false);
  const [PreliminaryPaymentCompleted, setPreliminaryPaymentCompleted] =
    useState(false);
  const [POStatusCompleted, setPOStatusCompleted] = useState(false);
  const [InitialPaymentCompleted, setInitialPaymentCompleted] = useState(false);
  const [DeliveryMethodCompleted, setDeliveryMethodCompleted] = useState(false);
  const [WizardAgrId, setWizardAgrId] = useState('');
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  const param = useParams();
  const location = useLocation();
  console.log('param in create', param);
  const throwerfunc = () => {
    console.log('response in throwerfunc');

    console.log('fdgshjhshyuuiii');
    setchangeDEStatus(true);
    // setthrowerObject(response);
  };
  useEffect(() => {
    if (location.pathname.includes('/agreement/customerorder/details')) {
      setItemSearchCompleted(true);
    }
  }, [location.pathname]);
  return featureFlagDetails?.CustomerOrder == '1' ? (
    <>
      <div>
        <div className="tab-content container-pb-custom">
          <div className="">
            <div className="">
              <div
                id="smartwizard"
                className="p-0 sw sw-theme-default sw-justified"
              >
                {/* Fixed Issues */}
                <Wizard
                  WizardAgrId={WizardAgrId}
                  ItemSearchCompleted={ItemSearchCompleted}
                  AgreementDetailsCompleted={AgrDetailsCompleted}
                  PreliminaryPaymentCompleted={PreliminaryPaymentCompleted}
                  POStatusCompleted={POStatusCompleted}
                  InitialPaymentCompleted={InitialPaymentCompleted}
                  DeliveryMethodCompleted={DeliveryMethodCompleted}
                  CoCustomerDetails={{
                    coCustomerId: coCustomerId,
                    coCustomerName: coCustomerName,
                  }}
                  dethrower={changeDEStatus}
                  setWizardAgrId={undefined}
                />

                <CustomRoute
                  path="/agreement/customerorder/itemsearch"
                  key={AppRouteKey.CustomerOrderItemSearch}
                >
                  <CustomerOrderSearchResult
                    setItemSearchCompleted={setItemSearchCompleted}
                    func={pull_data}
                    somedeeptrigger={throwerfunc}
                  />
                </CustomRoute>
                <CustomRoute
                  path="/agreement/customerorder/details"
                  key={AppRouteKey.CustomerOrderAgrDetails}
                >
                  <AgreementDetails
                    CoCustomerDetails={{
                      coCustomerId: coCustomerId,
                    }}
                    setItemSearchCompleted={setItemSearchCompleted}
                  />
                </CustomRoute>
                <CustomRoute
                  path="/agreement/customerorder/postatus"
                  key={AppRouteKey.POStatus}
                >
                  <POStatus
                    setItemSearchCompleted={setItemSearchCompleted}
                    setAgrDetailsCompleted={setAgrDetailsCompleted}
                    setPreliminaryPaymentCompleted={
                      setPreliminaryPaymentCompleted
                    }
                    func={pull_data}
                  />
                </CustomRoute>
                <CustomRoute
                  path="/agreement/customerorder/delivery"
                  key={AppRouteKey.CustomerOrderDeliveryMethod}
                >
                  <Deliverymethod
                    setItemSearchCompleted={setItemSearchCompleted}
                    setAgrDetailsCompleted={setAgrDetailsCompleted}
                    setPreliminaryPaymentCompleted={
                      setPreliminaryPaymentCompleted
                    }
                    setPOStatusCompleted={setPOStatusCompleted}
                    setInitialPaymentCompleted={setInitialPaymentCompleted}
                    setWizardAgrId={setWizardAgrId}
                    func={pull_data}
                  />
                </CustomRoute>
                {/* <CustomRoute
                  path="/agreement/reinstate/delivery"
                  key={AppRouteKey.ReinstateDeliverymethod}
                >
                  <ReinstateDeliverymethod
                    setItemSearchCompleted={setItemSearchCompleted}
                    setAgrDetailsCompleted={setAgrDetailsCompleted}
                    setInitialPaymentCompleted={setInitialPaymentCompleted}
                    setDeliveryMethodCompleted={setDeliveryMethodCompleted}
                    func={pull_data}
                  />
                </CustomRoute> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <ComingSoonPage />
  );
}
