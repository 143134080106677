/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-var */
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  RACButton,
  Grid,
  RACModalCard,
  RACTextbox,
  RACSelect,
  Typography,
  RACDatePicker,
  CircularProgress,
} from '@rentacenter/racstrap';
import { Getreorderreasons, itemReorder } from '../../../api/user';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { ReactComponent as Successimg } from '../../../assets/images/success-icon.svg';
import { ReactComponent as Alert } from '../../../assets/images/no-records-found.svg';
import VoidAgreement from '../../Rental/AddCoCustomer/voidagreement';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import { CustomerOrderAgreementContext } from '../../../context/CustomerOrderAgreementContext';
import DeliveryNotesPopup from './DeliveryNotesPopup';
import { FeatureFlagContext } from '../../../context/FeatureFlagContext';
interface Params {
  customerId: string;
  agreementId: string;
}
interface Reasons {
  id: string;
  active: string;
  referenceCode: string;
  displaySeq: string;
  defaultValue: string;
  description: string;
  descSpanish: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
}

const POFooter = (props?: any) => {
  //   const classes = props.throwStyle;
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const classes = agreementGlobalStyles();
  const history = useHistory();
  const StoreId = window.sessionStorage.getItem('storeNumber');
  const { wholeAgrInfoResponse } = useContext(CustomerOrderAgreementContext);
  const [reOrderController, setreOrderController] = useState(false);
  const [nextBtnController, setnextBtnController] = useState(false);
  const [InvDeprPopUp, setInvDeprPopUp] = useState(false);
  const { customerId, agreementId } = useParams<Params>();
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  const [nonPilotStore, setNonPilotSore] = useState(false)
  console.log('agreementId in footer', agreementId);
  const Initialvalues = {
    freightclaim: '',
    reorderby: '',
  };
  const [reorderitempopup, setreorderitempopup] = useState(false);
  const [dropdownvalue, Setdropdownvalue] = useState('SEL');
  const [conditionLoader, SetconditionLoader] = useState(true);
  const [initialreorder, setinitialreorder] = useState(Initialvalues);
  const [itemsuccesspopup, setitemsuccesspopup] = useState(false);
  const [disablefreight, setdisablefreight] = useState(true);
  const [disablereorderbutton, setdisablereorderbutton] = useState(true);
  const [currentdate, setcurrentdate] = useState(moment().format('YYYY-MM-DD'));
  const [dropdownreason, setdropdownreason] = useState<Reasons[]>([]);
  const [EmployeeId, setEmployeeId] = useState('000000');
  const [exceptionpopup, setexceptionpopup] = useState(false);
  const [masterloader, setmasterloader] = useState(false);
  const [agrStatus, setagrStatus] = useState(false);
  const [agrStatusRefCode, setagrStatusRefCode] = useState('');
  const [deliveryNotesEnabled, setDeliveryNotesEnabled] = useState(false);
  const reorderitemoptions = [
    { value: '0', label: 'Select' },
    {
      value: 'Item Received But missing parts',
      label: 'Item Received But missing parts',
    },
    { value: 'Item Received ', label: 'Item Received ' },
    {
      value: 'Item Not Received',
      label: 'Item Not Received',
    },
  ];

  useEffect(() => {
    console.log('AGRStatus', props);
    setagrStatusRefCode(props?.AGRStatus);
    if (props?.AGRStatus != 'PRELIMRFND') {
      setagrStatus(true);
    } else {
      setagrStatus(false);
    }
    if (props?.throwNextController) {
      setnextBtnController(false);
    } else if (props?.AGRStatus == 'ACTIVE') {
      setnextBtnController(false);
    } else {
      setnextBtnController(true);
    }
  }, [props]);
  useEffect(() => {
    // eslint-disable-next-line no-debugger
    debugger;
    console.log('wholeAgrInfoResponse', wholeAgrInfoResponse);

    if (wholeAgrInfoResponse?.agreementWorkflow?.isReorderRequested == '1') {
      setreOrderController(true);
    }
  }, [wholeAgrInfoResponse]);
  useEffect(() => {
    console.log('ssdxxddddddcvcv', dropdownvalue);

    if (dropdownvalue != 'SEL') {
      console.log('shc', initialreorder.freightclaim);
      if (dropdownvalue !== 'DOR') {
        setdisablereorderbutton(false);
      } else if (dropdownvalue == 'DOR' && initialreorder.freightclaim !== '') {
        setdisablereorderbutton(false);
      } else {
        setdisablereorderbutton(true);
      }
      if (dropdownvalue == 'DOR') {
        setdisablefreight(false);
      } else {
        setdisablefreight(true);
        setinitialreorder({ ...initialreorder, freightclaim: '' });
      }
      // setdisablefreight(false);
    } else {
      console.log('hsbchbchdbcd');
      setdisablefreight(true);
      setdisablereorderbutton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownvalue, initialreorder.freightclaim]);

  useEffect(() => {
    const dates = new Date();

    // const modifieddate =
    //   dates.getMonth() + 1 + '/' + dates.getDate() + '/' + dates.getFullYear();
    // console.log('shhduhsuds',modifieddate)
    // console.log('bbhuisiisoso',modifieddate)
    //const tempdate='03/23/2022'//
    setcurrentdate(moment(dates).format('YYYY-MM-DD'));
    if (containerData !== undefined) {
      console.log('containerData sdvfdv', containerData);
      console.log('containerDataRole xcvcscv', containerData.GetEmployeeId());
      setEmployeeId(containerData.GetEmployeeId());
    }
  }, []);

  const previousClick = () => {
    history.push({
      pathname: `/agreement/customerorder/details/${customerId}/${agreementId}`,
    });
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  const reorderfunction = async () => {
    setreorderitempopup(false);
    setmasterloader(true);
    const reorderpayload = {
      reorderReason: dropdownvalue,
      agreementId: agreementId,
      freightClaimNumber: initialreorder.freightclaim,
    };

    const itemreorderresponse = await itemReorder(reorderpayload);
    console.log('view response ----->', itemreorderresponse);
    setmasterloader(false);
    if (itemreorderresponse.status == 200) {
      setitemsuccesspopup(true);
      setreOrderController(true);
    } else {
      setexceptionpopup(true);
    }
  };

  const nextClick = () => {
    if (props?.tbCustomerOrderFlow) {
      setDeliveryNotesEnabled(true);
    } else {
      history.push({
        pathname: `/payment1/customerorder/initial/${customerId}/${agreementId}`,
      });
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
  };
  const handlessn1 = (e: any) => {
    console.log('onchange triggered', initialreorder.freightclaim);
    console.log('hsbhdbvd');
    setinitialreorder({ ...initialreorder, [e.target.name]: e.target.value });
  };

  const itemreorderfunction = async () => {
    setreorderitempopup(true);
    console.log('ejujneunhfeifjei', StoreId);
    Setdropdownvalue('SEL');
    setinitialreorder({ ...initialreorder, freightclaim: '' });
    const reorderreasonresponse = await Getreorderreasons();
    console.log('hffujfue', reorderreasonresponse.data);
    if (
      reorderreasonresponse.data !== undefined &&
      reorderreasonresponse.data.referenceDetails !== undefined
    ) {
      // eslint-disable-next-line sonarjs/no-unused-collection
      const ReasonValue = reorderreasonresponse.data.referenceDetails;
      const finalreasonvalue = [
        { description: 'Select', referenceCode: 'SEL' },
        ...ReasonValue,
      ];

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      reorderreasonresponse.data.referenceDetails.map(
        (value: any, index: any) => {
          {
            ReasonValue.push(value);
          }
        }
      );

      console.log('void reasons', ReasonValue);
      SetconditionLoader(false);
      setdropdownreason(finalreasonvalue);
    }
  };

  const Reorderreasons = () => {
    if (
      dropdownreason !== undefined &&
      dropdownreason !== null &&
      dropdownreason.length > 0
    ) {
      return dropdownreason.map((value: any, index: any) => {
        // if (index === 0) {
        //   return { label: 'Select', value: '0' };
        // } else {
        // if (value.id !== null &&value.id !== undefined &&value.id <= 67 && value.id >= 60)
        return { label: value.description, value: value.referenceCode };
        // }
      });
    } else {
      return [{ label: 'Select', value: 0 }];
    }
  };

  const deliveryNotesCompletedFn = () => {
    setDeliveryNotesEnabled(false);
    if (featureFlagDetails?.PilotStore == '0') {
      setNonPilotSore(true)
    }
    else {
      history.push({
        pathname: `/payment1/customerorder/initial/${customerId}/${agreementId}`,
      });
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }

  };
  const deliveryNotesCancelledFn = () => {
    setDeliveryNotesEnabled(false);
  };
  const itemsuccess = () => {
    return (
      <>
        <div
          id="success"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true"
        >
          <Grid className={`${classes.justifyCenter} ${classes.textCenter}`}>
            <Successimg></Successimg>
            <Typography
              className={`${classes.agrDeliveryReceiptText} ${classes.textCenter} ${classes.mt3}`}
            >
              Item has been reordered successfully
            </Typography>
          </Grid>

          <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
            {/* <button type="button" className="btn btn-primary mx-1" data-testid="btnok" data-bs-dismiss="modal" onClick={(e) => Removevoid()} >OK</button> */}

            <RACButton
              size="small"
              isRounded={false}
              variant="contained"
              color="primary"
              onClick={() => {
                setitemsuccesspopup(false);
                window.location.reload();
              }}
            >
              OK
            </RACButton>
          </Grid>
        </div>
      </>
    );
  };
  const itemreorder = () => {
    return (
      <>
        <Grid className={`${classes.w100} ${classes.floatLeft}`}>
          <RACSelect
            options={Reorderreasons()}
            loading={conditionLoader}
            inputLabel="Reason"
            defaultValue={dropdownvalue !== '' ? dropdownvalue : 'Select'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              Setdropdownvalue(e.target.value);
            }}
          ></RACSelect>
        </Grid>
        <Grid container spacing={2} className={classes.mb3}>
          <Grid item md={6}>
            <Grid>
              <RACTextbox
                type="text"
                isCurrency={false}
                inputlabel="Freight Claim #"
                maxlength={50}
                value={initialreorder.freightclaim}
                disabled={disablefreight}
                name="freightclaim"
                OnChange={(e) => handlessn1(e)}
              />
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid>
              <Grid className={classes.floatLeft}>
                <RACTextbox
                  type="text"
                  isCurrency={false}
                  inputlabel="Reorder By"
                  value={EmployeeId}
                  disabled={true}
                  name="reorderby"
                  OnChange={(e) => handlessn1(e)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={6}>
            <RACDatePicker
              value={currentdate}
              label={'Reorder Date'}
              disabled={true}
            />
          </Grid>
          <Grid item md={6}></Grid>
        </Grid>
        <Grid md={12} className={`${classes.px3} ${classes.mt3}`}>
          <Grid className={classes.textRight}>
            <RACButton
              name="CustomerSignCancel"
              variant="outlined"
              color="primary"
              className={classes.mx1}
              onClick={(e) => setreorderitempopup(false)}
            >
              Cancel
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              className={classes.mx1}
              disabled={
                props?.AGRStatus == 'ACTIVE' ? true : disablereorderbutton
              }
              onClick={() => reorderfunction()}
            >
              Reorder
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const exceptionhandle = () => {
    return (
      <div>
        {masterloader ? (
          <Grid className={classes.masterLoader}>
            <Grid className={classes.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Grid item md={12} className={classes.textCenter}>
          <Alert></Alert>
          <Typography className={`${classes.popupText} ${classes.mt3}`}>
            Something went wrong
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setexceptionpopup(false)}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const nonPiloStorePopup = () => {
    return (
      <div>
        <Typography style={{
          marginTop: '-10px', fontFamily: 'OpenSans-bold',
          fontSize: '17px', textAlign: 'center'
        }}>To proceed with the Initial Payment and other actions please use SIMS</Typography>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <RACButton
            color='primary'
            variant='contained'
            onClick={() => { setNonPilotSore(false) }}
          >OK</RACButton>
        </div>


      </div>
    )
  }
  return (
    <div>
      {masterloader ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <Grid className={classes.fixedBottom}>
        <Grid className={classes.floatLeft}>
          <RACButton
            color="primary"
            variant="outlined"
            className={classes.ms2}
            onClick={previousClick}
          >
            Previous
          </RACButton>
        </Grid>

        <Grid className={`${classes.floatRight} ${classes.mx1}`}>
          <RACButton
            color="primary"
            variant="contained"
            disabled={nextBtnController}
            onClick={nextClick}
          >
            Next
          </RACButton>
        </Grid>
        <VoidAgreement
          AgreementId={agreementId}
          customerId={customerId}
          COVoidFlow={agrStatusRefCode}
          installationStatus={props.installationDetailsObj?.status}
        />

        <Grid
          className={`${classes.floatRight} `}
          style={{ marginRight: '5px' }}
        >
          {agrStatus ? (
            <RACButton
              color="primary"
              variant="contained"
              disabled={
                props?.AGRStatus == 'ACTIVE' ||
                  props?.installationDetailsObj.installerName
                  ? true
                  : reOrderController
              }
              onClick={() => itemreorderfunction()}
            >
              Reorder
            </RACButton>
          ) : null}
        </Grid>

        <Grid
          className={`${classes.floatRight}`}
          style={{ marginRight: '5px' }}
        >
          <RACButton
            onClick={props.triggerOpenReprint}
            variant="contained"
            color="primary"
            disabled={props?.AGRStatus == 'ACTIVE' ? true : false}
          >
            Reprint
          </RACButton>
        </Grid>
        <RACModalCard
          isOpen={reorderitempopup}
          closeIcon={true}
          maxWidth="xs"
          borderRadius="20px"
          title={'Reorder Item'}
          onClose={() => setreorderitempopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={itemreorder()}
        />
        <RACModalCard
          isOpen={itemsuccesspopup}
          closeIcon={true}
          maxWidth="xs"
          borderRadius="20px"
          onClose={() => setitemsuccesspopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={itemsuccess()}
        />
        <RACModalCard
          isOpen={nonPilotStore}
          closeIcon={true}
          maxWidth="xs"
          borderRadius="20px"
          onClose={() => setNonPilotSore(false)}
          // eslint-disable-next-line react/no-children-prop
          children={nonPiloStorePopup()}
        />
        <RACModalCard
          isOpen={exceptionpopup}
          closeIcon={true}
          maxWidth="xs"
          borderRadius="20px"
          onClose={() => setexceptionpopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={exceptionhandle()}
        />
      </Grid>
      <DeliveryNotesPopup
        deliveryNotesPopupOpen={deliveryNotesEnabled}
        customerName={props.installationDetailsObj?.customerName}
        phoneNumber={props?.installationDetailsObj?.customerPhoneNumber ? props?.installationDetailsObj?.customerPhoneNumber : ''}
        customerId={customerId}
        installerDetails={props.installationDetailsObj}
        deliveryNotesCompletedFn={deliveryNotesCompletedFn}
        deliveryNotesCancelledFn={deliveryNotesCancelledFn}
      />
    </div>
  );
};
// if coworker clicks on the void  pin popup will be displayed and  please reverse the payment to proceed will be displayed ,
// and it is voided normal void flow will be done.
export default POFooter;
