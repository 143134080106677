import { Dispatch } from 'react';

import {
  getUserAction,
  getUserSuccessAction,
  getUserErrorAction,
  UserAction,
} from './user-actions';
import { getCurrentUser } from '../../api/user';
export interface UserThunks {
  getCurrentUser(): Promise<void>;
}

// const fetchPermissions = async (
//   dispatch: Dispatch<UserAction>
// ): Promise<ProductPermissions[]> => {
//   try {
//     return await getUserPermissions(ProductType);
//   } catch (error) {
//     dispatch(getPermissionsErrorAction(error));
//     return [];
//   }
// };

export const getCurrentUserThunk =
  (dispatch: Dispatch<UserAction>) => async () => {
    try {
      dispatch(getUserAction());

      const userData = await getCurrentUser();

      dispatch(getUserSuccessAction(userData));
    } catch (error) {
      dispatch(getUserErrorAction(error));
    }
  };
