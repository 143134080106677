/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  RACButton,
  Grid,
  // RACModalCard,
  // Typography,
} from '@rentacenter/racstrap';
import { ReinstateAgreementContext } from '../../../context/ReinstatementAgreementContext';
import AddCocustomer from '../../Rental/AddCoCustomer/AddCocustomer';
import { AgreementContext } from '../../../context/AgreementContext';
import { AppRoute } from '../../../config/route-config';
import {
  AccountManagementURLParam,
  AMOrigin,
} from '../../../constants/constants';
import { OriginContext } from '../../../context/OriginContext';

interface Params {
  customerId: string;
  agreementId: string;
}

const FooterContent = (props: any) => {
  const classes = props.throwStyle; //This will hold the global styles
  const { agreementVoidStatus } = useContext(AgreementContext);
  const { redirectOrigin } = useContext(OriginContext);
  const location = useLocation();
  const [coCustomerId, setcocustomerId] = useState('');
  const [coCustomerName, setcoCustomerName] = useState('');
  const [newcustomer, setnewcustomer] = useState(true);
  const search = useLocation().search;
  const transferRights = new URLSearchParams(search).get(
    'transferrightsapplied'
  );
  const history = useHistory(); //This will hold the history.push() which will be helping in redirection
  const [footerControl, setfooterControl]: any = useState();

  let { agreementId } = useParams<Params>();
  const { customerId } = useParams<Params>();

  if (redirectOrigin === AMOrigin) {
    agreementId = agreementId.replace(AccountManagementURLParam, '');
  }
  // const [InvDeprPopUp, setInvDeprPopUp] = useState(false);
  const {
    numberOfInventory,
    // setdepPercentage,
    // depPercentage,
    agreementCreateStatus,
    itemArrayData,
    reinstateCopyStore,
    setinventoryMapping,
  } = useContext(ReinstateAgreementContext);
  useEffect(() => {
    console.log('props.throwItemArray.length', props.throwItemArray.length);
    console.log('numberOfInventory', numberOfInventory);
    if (props.throwItemArray.length == numberOfInventory) {
      setfooterControl(false);
    } else {
      setfooterControl(true);
    }
    if (agreementCreateStatus.agreementCreatedStatus === true) {
      setfooterControl(false);
    }
    // if (props.throwDepr !== undefined) {
    //   setdepPercentage(
    //     props.throwDepr.agreementPricing.agreementInventoryDepreciation
    //   );
    // }
  }, [props]); //This method will be making the next button enable and disable.
  // useEffect(() => {
  //   setfooterControl(props.controlData);
  //   // setcopyData(props.throwCopy);
  // }, [props]);

  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    // eslint-disable-next-line no-console
    console.log('Footer contex', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };

  const CancelClick = () => {
    if (redirectOrigin === AMOrigin) {
      history.push({
        pathname: `${AppRoute.AMCustomer}/${customerId}`,
        state: location?.state,
      });
      return;
    }
    history.push({
      pathname: `/customer/searchcustomer`,
    });
  }; //This method is triggered when cancel button is clicked

  // const AcceptDeprPopupdiv = () => {
  //   return (
  //     <div
  //       data-testid="breakpopupcheck"
  //       id="decisionengine"
  //       data-bs-backdrop="static"
  //       data-bs-keyboard="false"
  //       tabIndex={-1}
  //       aria-labelledby="decisionengine"
  //       aria-hidden="true"
  //     >
  //       <Grid item md={12} className={classes.textCenter}>
  //         <Typography className={`${classes.semiBold} ${classes.font16}`}>
  //           Inventory Depreciation is greater than{' '}
  //           <Typography
  //             variant="caption"
  //             className={`${classes.bold} ${classes.font17}`}
  //           >
  //             {' '}
  //             33.00%.{' '}
  //           </Typography>
  //           <Typography className={`${classes.semiBold} ${classes.font16}`}>
  //             {' '}
  //             Do you want to continue ?
  //           </Typography>
  //         </Typography>
  //       </Grid>
  //       <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
  //         <RACButton
  //           className={classes.me3}
  //           variant="outlined"
  //           color="secondary"
  //           size="large"
  //           onClick={() => setInvDeprPopUp(false)}
  //         >
  //           No
  //         </RACButton>
  //         <RACButton
  //           className={classes.mx1}
  //           variant="contained"
  //           color="primary"
  //           size="large"
  //           onClick={() => {
  //             history.push({
  //               pathname: `/agreement/reinstate/details/${customerId}/${agreementId}`,
  //             });
  //             const navEvent = new PopStateEvent('popstate');
  //             window.dispatchEvent(navEvent);
  //             setInvDeprPopUp(false);
  //           }}
  //         >
  //           Yes
  //         </RACButton>
  //       </Grid>
  //     </div>
  //   );
  // }; // PS - 31 This method will be rendering the inventory depriciation alert popup

  const NextBtnClick = () => {
    const store: any = [];
    console.log('reinstateCopyStore in footer', reinstateCopyStore);
    console.log('itemArrayData in footer', itemArrayData);

    reinstateCopyStore.map((obj, indexOne) => {
      itemArrayData.map((value, indexTwo) => {
        if (indexOne == indexTwo) {
          store.push({
            inventoryId: value.inventoryId,
            inventoryPriceId: value.inventoryPriceId,
            parentInventoryId: Number(obj.inventoryId),
          });
          console.log('Result', value, obj.itemId);
        }
      });
    });
    console.log('store value in footer', store);

    setinventoryMapping(store);
    // if (
    //   parseFloat(
    //     props.throwDepr.agreementPricing.agreementInventoryDepreciation
    //   ) > 33 &&
    //   depPercentage !==
    //     props.throwDepr.agreementPricing.agreementInventoryDepreciation
    // ) {
    //   setInvDeprPopUp(true);
    // } else {
    console.log('transferRights value', transferRights);

    if (transferRights == null) {
      history.push({
        pathname: `/agreement/reinstate/details/${customerId}/${agreementId}`,
      });
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (transferRights == 'Y') {
      history.push({
        pathname: `/agreement/reinstate/details/${customerId}/${agreementId}?transferrightsapplied=Y`,
      });
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
    // }
  }; //PS - 30 This method is triggered when Next button is clicked. Based on the condition it will be moving to agreement details page or it will be in item search page.
  const triggerME = () => {
    console.log('response in triggerME');

    console.log('fdgshjhshyyuuiiii');
    props.sometrigger();
  };
  return (
    <Grid className={classes.fixedBottom}>
      <Grid className={classes.floatLeft}>
        <RACButton
          color="primary"
          variant="outlined"
          className={classes.ms2}
          onClick={() => CancelClick()}
        >
          Cancel
        </RACButton>
      </Grid>

      <Grid className={classes.floatRight}>
        <RACButton
          color="primary"
          variant="contained"
          onClick={() => NextBtnClick()}
          disabled={footerControl}
          style={{ marginRight: '4px' }}
        >
          Next
        </RACButton>
      </Grid>
      {agreementVoidStatus.agreementVoidedStatus === false ? (
        <Grid className={classes.floatRight}>
          <AddCocustomer
            coCustomerProps={customerId}
            newCoCustomer={newcustomer}
            func={pull_data}
            somethingTrigger={triggerME}
          />
        </Grid>
      ) : null}
      {/* <RACModalCard
        isOpen={InvDeprPopUp}
        maxWidth="xs"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={AcceptDeprPopupdiv()}
      /> */}
    </Grid>
  );
};
export default FooterContent;
