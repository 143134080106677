/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect } from 'react';
import {
  RACTable,
  RACTableCell,
  RACTableRow,
  RACCheckBox,
} from '@rentacenter/racstrap';
import { agreementInfoStyles } from '../../../JSstyles/agreementInfoStyles';

const POGrid = (props: any) => {
  const agrGridClassName = agreementInfoStyles();
  const classes = props.throwStyles;
  console.log('thrown Style', classes);

  const [tablevalue, settablevalue]: any = useState([]);
  const [itemReceived, setitemReceived] = useState<boolean>(false);
  // const [customeStyle, setcustomeStyle]: any = useState('');
  function apiItemCall() {
    settablevalue(props.GridValues);
  }
  function apiAgrTotalCall() {
    settablevalue(props.SecondGridValues);
  }
  // function apiAgrInfoCall() {
  //   settablevalue(props.ThirdGridValues);
  // }
  // const truncString = (str: any, max: number, add: any) => {
  //   add = add || '...';

  //   return typeof str === 'string' && str.length > max
  //     ? str.substring(0, max) + add
  //     : str;
  // };

  useEffect(() => {
    settablevalue([]);

    // eslint-disable-next-line no-console
    console.log('In grid inventory values', props.GridValues);
    if (
      props.mapper[props.mapper.length - 1].gridInfo ===
      'inventoryItemDetailsBFObject'
    ) {
      apiItemCall();
    } else if (
      props.mapper[props.mapper.length - 1].gridInfo ===
      'agreementDetailsBFObject'
    ) {
      apiAgrTotalCall();
    }

    // if (props.mapper[props.mapper.length - 1].gridInfo === 'AgrTotal') {
    //   //console.log('Custome Class Receive',props.mapper[7].customiseTable);
    //   setcustomeStyle(props.mapper[7].customiseTable);
    //   console.log(
    //     'props.mapper[7].customiseTable',
    //     props.mapper[7].customiseTable
    //   );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.mapper,
    props.GridValues,
    props.SecondGridValues,
    props.ThirdGridValues,
  ]);
  useEffect(() => {
    console.log('props.POItemReceived', props.POItemReceived);
    if (props?.POItemReceived == '1') {
      setitemReceived(true);
    } else {
      setitemReceived(false);
    }
  }, [props.POItemReceived]);

  return (
    <div>
      <RACTable
        className={`${agrGridClassName.racGrid} ${agrGridClassName.racAgrViewGrid}`}
        renderTableHead={headerBuild}
        renderTableContent={bodyBuild}
      />
    </div>
  );

  function headerBuild() {
    return props.mapper.map((obj: any) => {
      if (obj.displayname !== undefined) {
        if (
          obj.displayname === 'Tag Rate' ||
          // obj.displayname === 'Tag Term' ||
          obj.displayname === 'Tag Total' ||
          obj.displayname === 'Agr Rate' ||
          // obj.displayname === 'Agr Term' ||
          obj.displayname === 'Agr Total' ||
          obj.displayname === 'Estimated Total' ||
          obj.displayname === 'Cash Price'
        ) {
          return (
            <RACTableCell className={classes.textRight}>
              {obj.displayname}
            </RACTableCell>
          );
        } else if (
          obj.displayname === 'Tag Term' ||
          obj.displayname === 'Agr Term' ||
          obj.displayname === 'Item PO Receive'
        ) {
          return (
            <RACTableCell className={classes.textCenter}>
              {obj.displayname}
            </RACTableCell>
          );
        } else {
          return <RACTableCell>{obj.displayname}</RACTableCell>;
        }
      }
    });
  }
  function bodyBuild() {
    if (tablevalue !== undefined) {
      return tablevalue.map((tabval: any, index: any) => {
        return (
          <RACTableRow key={index} backgroundColor="white">
            {subBuild(tabval)}
          </RACTableRow>
        );
      });
    }

    function subBuild(tabval: any) {
      return props.mapper.map((obj: any, index: any) => {
        for (const key in tabval) {
          if (obj.key == 'checkBoxRequired') {
            return (
              <RACTableCell className={classes.textCenter}>
                <RACCheckBox disabled={true} checked={itemReceived} />
              </RACTableCell>
            );
          }
          if (key === obj.key) {
            if (
              key === 'cashPrice' ||
              key === 'agrRate' ||
              key === 'estimatedTotal'
            ) {
              //const x = Number(tabval[obj.key]);
              console.log('tabval[obj.key]', tabval[obj.key]);
              // const currencyValue = tabval[obj.key];
              const currencyValue: any = tabval[obj.key].replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              );
              console.log('currency value', currencyValue);

              //const x = parseInt(tabval[obj.key]);
              return (
                <RACTableCell key={index} className={classes.textRight}>
                  $ {currencyValue}
                </RACTableCell>
              );
            } else if (key === 'agrTerm' || key === 'agreementTerm') {
              return (
                <RACTableCell key={index} className={classes.textCenter}>
                  {tabval[obj.key]}
                </RACTableCell>
              );
            } else if (
              key === 'agreementInventoryDepreciation' ||
              key === 'inventoryDepreciation'
            ) {
              return (
                <RACTableCell key={index} className={classes.textRight}>
                  {tabval[obj.key]} %
                </RACTableCell>
              );
            } else if (key === 'tagTerm' || key == 'agreementTerm') {
              return <RACTableCell key={index}>{tabval[obj.key]}</RACTableCell>;
            } else if (key === 'dueDate') {
              return <RACTableCell key={index}>{tabval[obj.key]}</RACTableCell>;
            } else if (key === 'inventoryNumber') {
              return (
                <RACTableCell key={index}>
                  <a
                    href=""
                    className={`${classes.racpadLink} ${agrGridClassName.agrInfoLink}`}
                  >
                    {tabval[obj.key]}
                  </a>
                </RACTableCell>
              );
            } else if (key === 'policy') {
              return <RACTableCell key={index}>{tabval[obj.key]}</RACTableCell>;
            } else {
              if (tabval[obj.key] === null || tabval[obj.key] === undefined) {
                return (
                  <RACTableCell key={index} title={tabval[obj.key]}>
                    -
                  </RACTableCell>
                );
              } else {
                return (
                  <RACTableCell key={index} title={tabval[obj.key]}>
                    {tabval[obj.key]}
                  </RACTableCell>
                );
              }
            }
          }
        }
      });
    }
  }
};
export default POGrid;
