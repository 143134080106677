/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-console */

import React, { useState, useEffect, useContext } from "react";
import {
  RACButton,
  RACModalCard,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Typography,
} from "@rentacenter/racstrap";
import WhiteCard from "./WhiteCard";
import GridPage from "./Grid";
import BlueCard from "./BlueCard";
import RePrintReport from "./RePrintReport";
import Footer from "./Footer";
import {
  getAgreementInfo,
  getCustomerPolicy,
  getPolicyInfo,
  getAgreementDelivery,
  GetEmployeeID,
  GetRole,
  CoworkerRequired
} from "../../../api/user";
import { useParams } from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';
import { AgreementContext } from "../../../context/AgreementContext";
import { agreementGlobalStyles } from "../../../JSstyles/agreementGlobalStyles";
import { agreementInfoStyles } from "../../../JSstyles/agreementInfoStyles";
import { ReactComponent as AlertIcon } from "../../../assets/images/no-records-found.svg";
import { ReactComponent as Erroricon } from "../../../assets/images/error-icon.svg";
import { fieldDeviceStyles } from "../../../JSstyles/fieldDeviceStyles";
import { paymentCost } from '../../../JSstyles/paymentCost';
import { ContainerContext } from "../../../app/App";
import { CustomPropInterface } from '../../../index';
import RACExchange from "./RACExchange";
import SecondFactor from "../TwoFactorPopup/TwoFactorPopup";

interface Params {
  agreementId: string;
  customerId: string;
};
const AgreementInfo = (props: any) => {
  //const classes = useClasses();
  const classes1 = fieldDeviceStyles();
  const classes = agreementGlobalStyles();
  const containerData = useContext(ContainerContext) as CustomPropInterface;

  const paymentCostClassName = paymentCost();
  const agrInfoClassName = agreementInfoStyles();
  const { agreementId, customerId } = useParams<Params>();

  const printPayLoad = {
    agreementId: agreementId,
    customerId: customerId,
  };

  // const controlDelivery = {
  //   count: 0, //Control delivery status whether got delivered or not
  // };
  // const [controlFooter, setcontrolFooter] = useState('1');
  // const [controlFootero, setcontrolFootero] = React.useState('');
  const {
    viewAgrDataContext,
    setdeliveryMethodData,
    deliveryMethodData,
    setviewAgrData,
    viewAgrData,
    setReturnAgrInformation,
    setIsExchangeEnabled,
    setExchangePeriod,
    setReturnPeriod,
    exchangeNavigationPopup, 
    setExchangeNavigationPopup,
    exchangeStarted,
    exchangeTwoFactorAuth,
    setExchangeTwoFactorAuth,
    setExchangeStarted,
    isRacExchangeFuncEnabled, 
    setRacExchangeFuncEnabled,
    showExchangeIcon, 
    setShowExchangeIcon,
    setDeliveryStatus
  } = useContext(AgreementContext);
  const {
    viewAgreementContext,
    setviewAgreementData,
    setgetCustomerCoverage,
    getCustomerCoverage,
    setIsSacDaysCompleted,
    setepoAmount,
    setIsInReinstateMode,
  } = useContext(AgreementContext);
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [getViewAgrData, setgetViewAgrData] = useState(viewAgrDataContext);
  const [EnableLoaderButton, setEnbleLoaderButton] = useState(true);
  // const [entireAgrInfoResponseToGrid, setentireAgrInfoResponseToGrid] =
  //   useState();
  const [controlLoader, setcontrolLoader] = useState(false);
  const storeNo = window.sessionStorage.getItem("storeNumber");
  const [isDataAvailable, setisDataAvailable]: any = useState(true);
  const [isException, setisException]: any = useState(false);
  const [selectedInvId, setselectedInvId] = useState();
  const [openRePrint, setopenRePrint] = useState(false);
  //const [openDeliveryPopup, setopenDeliveryPopup] = useState(false);
  const [openPolicyPopup, setopenPolicyPopup] = useState(false);
  const [openAgrInfo, setopenAgrInfo] = useState(true);
  //const [reprintData, setreprintData] = useState([]);
  // const [isNotAccept, setisNotAccept] = useState(true);
  const [WhitecardValues, setWhitecardValues] = useState();
  const [BluecardValues, setBluecardValues] = useState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [InfoFooter, setInfoFooter] = useState("agreementInfo");
  const [getCustomerPolicyResponse, setgetCustomerPolicyResponse] = useState();
  const [coverageData, setcoverageData] = useState();
  const [isPolicyException, setisPolicyException] = useState(false);
  const [receivedDepartment, setReceivedDepartment] = useState();

  const GridOneValues = [
    { key: "radioRequire" },
    { displayname: "RadioButton", key: "radioButton", id: "11" },
    { displayname: "Item #", key: "inventoryNumber" },
    { displayname: "Item Description", key: "description" },
    { displayname: "Tag Rate", key: "tagRate" },
    { displayname: "Tag Term", key: "tagTerm" },
    { displayname: "Tag Total", key: "tagTotal" },
    { displayname: "Agr Rate", key: "agreementRate" },
    { displayname: "Agr Term", key: "agreementTerm" },
    { displayname: "Agr Total", key: "agreementTotal" },
    { displayname: "RV", key: "currentTotalRv" },
    { displayname: "Inv Depr%", key: "inventoryDepreciation" },
    { gridInfo: "ItemInfo" },
  ];
  const GridSecondValues = [
    { displayname: "Tag Rate", key: "tagRate" },
    { displayname: "Tag Term", key: "tagTerm" },
    { displayname: "Tag Total", key: "tagTotal" },
    { displayname: "Agr Rate", key: "agreementRate" },
    { displayname: "Agr Term", key: "agreementTerm" },
    { displayname: "Agr Total", key: "agreementTotal" },
    { displayname: "Inv Depr %", key: "agreementInventoryDepreciation" },
    {
      customiseTable: `${agrInfoClassName.racAgrTotalGrid} ${agrInfoClassName.racGrid} ${agrInfoClassName.racAgrViewGrid}`,
    },
    { gridInfo: "AgrTotal" },
  ];
  const GridThirdValues = [
    { displayname: "Agreement Source", key: "agreementSource" },
    { displayname: "Schedule", key: "paymentSchedule" },
    { displayname: "Delivery Charge", key: "deliveryCharge" },
    { displayname: "Due Date", key: "dueDate" },
    { displayname: "Days Extension", key: "daysExtension" },
    { displayname: "Promo Code", key: "promoName" },
    { displayname: "Opt Services", key: "optServices" },
    { displayname: "Sales Person", key: "salesPerson" }, 
    { gridInfo: "AgrInfo" },
  ];
  const GridFourthValues = [
    { displayname: "Agreement Source", key: "agreementSource" },
    { displayname: "Schedule", key: "paymentSchedule" },
    { displayname: "Delivery Charge", key: "deliveryCharge" },
    { displayname: "Due Date", key: "dueDate" },
    { displayname: "Days Extension", key: "daysExtension" },
    { displayname: "Promo Code", key: "promoName" },
    { displayname: "Opt Services", key: "optServices" },
    { displayname: "Policy", key: "policy" },
    { displayname: "Sales Person", key: "salesPerson" },
    { gridInfo: "AgrInfo" },
  ];

  const GridOnePromoValues = [
    { key: 'radioRequire' },
    { displayname: 'RadioButton', key: 'radioButton' },
    { displayname: 'Item #', key: 'inventoryNumber' },
    { displayname: 'Item Description', key: 'description' },
    { displayname: 'Tag Rate', key: 'tagRate' },
    { displayname: 'Tag Term', key: 'tagTerm' },
    { displayname: 'Tag Total', key: 'tagTotal' },
    { displayname: 'Agr Rate', key: 'agreementRate' },
    { displayname: 'Agr Term', key: 'agreementTerm' },
    { displayname: 'Agr Total', key: 'agreementTotal' },
    { displayname: 'Inv Depr%', key: 'inventoryDepreciation' },
    { gridInfo: 'ItemInfo' },
  ];
  const [gridOne, setgridOne] = useState(GridOneValues);
  const [gridOnePromo, setgridOnePromo] = useState(GridOnePromoValues);
  const [gridTwo, setgridTwo] = useState(GridSecondValues);

  const [gridThree, setgridThree] = useState(GridThirdValues);

  const [gridFour, setgridFour] = useState(GridFourthValues);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [PolicyBindData, setPolicyBindData] = useState<any>();
  const [isTireAgreement, setisTireAgreement] = useState(false);

  const [ItemInformation, setItemInformation]: any = useState();
  const [AgreementTotal, setAgreementTotal]: any = useState();
  const [AgreementInformation, setAgreementInformation]: any = useState();
  const [AgreementInfoHead, setAgreementInfoHead]: any = useState();
  const [delievrydata, setdelievrydata] = useState(true);
  // const [gridFour, setgridFour] = useState(GridFourvalues);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchResultContextData, setViewAgrContext] = viewAgreementContext;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState("");
  const [IsagreementVoided, setIsagreementVoided] = useState(false);
  const storeNumber = window.sessionStorage.getItem('storeNumber');
  const [employeeId, setEmployeeId] = useState("");
  const [CurrentRole, setCurrentRole] = useState('');

  useEffect(() => {
    let employeeIdGA:any
    let loggedRole:any
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
          setEmployeeId(GetMenuDetailsResponse?.coworkerProfile?.employeeId);

          console.log('currentRole', currentRole);
          setCurrentRole(currentRole);
          loggedRole = currentRole;
          employeeIdGA = GetMenuDetailsResponse?.coworkerProfile?.employeeId

        }
      }
    };
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      setCurrentRole(containerData.GetRole());
      setEmployeeId(containerData?.GetEmployeeId());
      loggedRole = containerData.GetRole()
      employeeIdGA = containerData?.GetEmployeeId()

    } else {
      GettingRole();
    }
    window.dataLayer.push({
      'event': 'Loaded the Agreement Information Page',
      'customerId': customerId,
      'storeNo': storeNumber,
      'agreementNo': agreementId,
      'employeeId': employeeIdGA,
      'role': loggedRole,
      'currentScreen': 'Agreement Information Page',
      'previousScreen': 'Customer Search',
    });
  }, []);




  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    // eslint-disable-next-line no-console
    console.log("Search Result Props", data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };

  //Added
  const closePoliPopup = () => {
    setopenPolicyPopup(false);
  };

  const configPaymentFunctions = async () => {
    const StoreId = window.sessionStorage.getItem("storeNumber")
    const bodyobject = {
      storeNumbers: [String(window.sessionStorage.getItem("storeNumber"))],
      paramKeyNames: ["AgreementExchange", 'AgreementExchangePeriod', 'AgreementExchangeReturnPeriod', 'RACExchange'],
    };
    let CoworkerNumResp 
    if (StoreId && StoreId != null && StoreId != undefined && StoreId != '' &&  StoreId != 'null' && StoreId != 'undefined'  ){
      CoworkerNumResp = await CoworkerRequired(bodyobject);
    }
    const CoworkerNum = CoworkerNumResp?.data;
    debugger;
    console.log("configPaymentFunctions", CoworkerNum);
    if (
      CoworkerNum !== undefined &&
      CoworkerNum !== null &&
      CoworkerNum.storeProfileResponse !== undefined &&
      CoworkerNum.storeProfileResponse &&
      CoworkerNum.storeProfileResponse.configDetails !== undefined &&
      CoworkerNum.storeProfileResponse.configDetails.length > 0 &&
      CoworkerNum.storeProfileResponse.configDetails[0].configDetails !==
      undefined &&
      CoworkerNum.storeProfileResponse.configDetails[0].configDetails.length > 0
    ) {
      console.log(
        "CoworkerNum.storeProfileResponse.configDetails[0].configDetails",
        CoworkerNum.storeProfileResponse.configDetails[0].configDetails[0]
      );
      for (
        let i = 0;
        i <
        CoworkerNum.storeProfileResponse.configDetails[0].configDetails.length;
        i++
      ) {
        const configArray =
          CoworkerNum.storeProfileResponse.configDetails[0].configDetails;

        if (configArray[i].paramKeyName == "AgreementExchange") {
          const containsActionKeyGridThree = GridThirdValues.find(obj => obj.key == 'action');
          const containsActionKeyGridFour = GridFourthValues.find(obj => obj.key == 'action');
          if (!containsActionKeyGridThree) {
            setgridThree([
              { displayname: "Agreement Source", key: "agreementSource" },
              { displayname: "Schedule", key: "paymentSchedule" },
              { displayname: "Delivery Charge", key: "deliveryCharge" },
              { displayname: "Due Date", key: "dueDate" },
              { displayname: "Days Extension", key: "daysExtension" },
              { displayname: "Promo Code", key: "promoName" },
              { displayname: "Opt Services", key: "optServices" },
              { displayname: "Sales Person", key: "salesPerson" }, 
              { displayname: "Action", key: "action" },
              { gridInfo: "AgrInfo" },
            ]);
          }
          if (!containsActionKeyGridFour) {
            setgridFour([
              { displayname: "Agreement Source", key: "agreementSource" },
              { displayname: "Schedule", key: "paymentSchedule" },
              { displayname: "Delivery Charge", key: "deliveryCharge" },
              { displayname: "Due Date", key: "dueDate" },
              { displayname: "Days Extension", key: "daysExtension" },
              { displayname: "Promo Code", key: "promoName" },
              { displayname: "Opt Services", key: "optServices" },
              { displayname: "Policy", key: "policy" },
              { displayname: "Sales Person", key: "salesPerson" },
              { displayname: "Action", key: "action"},
              { gridInfo: "AgrInfo" }
            ]);
          }
          setIsExchangeEnabled(
            Number(configArray[i].paramValue) > 0 ? true : false
          );
        }
        if (configArray[i].paramKeyName == "AgreementExchangePeriod") {
          setExchangePeriod(
            configArray[i].paramValue ? configArray[i].paramValue : ''
          );
        }
        if (configArray[i].paramKeyName == "AgreementExchangeReturnPeriod") { 
          setReturnPeriod(
            configArray[i].paramValue ? configArray[i].paramValue : ''
          );
        }
        if (configArray[i].paramKeyName == "RACExchange") { 
          setRacExchangeFuncEnabled(
            configArray[i].paramValue === '1'
          );
        }
      }
    }
  };

  const accept = async () => {
    setopenPolicyPopup(false);
    setcontrolLoader(true);
    let action: any;
    if (PolicyBindData.policyStatus == "A") {
      action = 2;
    } else {
      action = 1;
    }
    const paylaod = {
      action: action,
      agreementId: parseInt(agreementId),
    };
    console.log("getPolicyInfopaylaod", paylaod);
    const resultPolicy = await getPolicyInfo(paylaod);
    if (resultPolicy.status == 200) {
      // setPolicyBindData(undefined);
      setcoverageData(undefined);
      console.log("getPolicyInforesult", resultPolicy.data);
    } else if (resultPolicy.status == 500) {
      setcontrolLoader(false);
      setisPolicyException(true);
      console.log("getPolicyInforesult", resultPolicy.data);
    }
    //New Code
    const agreementInfoResult = await getAgreementInfo(agreementId);
    const result = agreementInfoResult.data;
    if (result.errors !== undefined && result.errors[0].code == 400) {
      console.log("reacheda 400", result);

      setisDataAvailable(false);
    } else if (result.errors !== undefined && result.errors[0].code == 500) {
      setisException(true);
    }
    if (
      result !== undefined &&
      agreementInfoResult.status == 200 &&
      result.agreementInformation.policy !== "N/A"
    ) {
      setisTireAgreement(true);
    }
    let filteredData: any[]
    if (result?.agreementWorkflow?.isSwitchoutEnabled == 1) {
      console.log('isSwitchoutEnabled', result);
      filteredData = result.agreementInventories.filter(function (element) {
        console.log("filterer object", element);
        return element.inventoryStatusRefcode !== "PSO";
      });
    }
    else {
      console.log('notIsSO', result);
      filteredData = result.agreementInventories
    }
    const agrresult = result.agreementInventories.some(
      (value: any) => value.tagRate == undefined
    );
    console.log("agrresult", agrresult);
    if (agrresult) {
      const ResultInv = filteredData.map((value) => {
        return {
          agreementRate: value.agreementRate,
          agreementTerm: value.agreementTerm,
          agreementTotal: value.agreementTotal,
          brand: value.brand,
          department: value.department,
          description: value.description,
          inventoryId: value.inventoryId,
          inventoryNumber: value.inventoryNumber,
          modelNumber: value.modelNumber,
          subDepartment: value.subDepartment,
          tagRate: result.agreementTotal.tagRate,
          tagTerm: result.agreementTotal.tagTerm,
          tagTotal: result.agreementTotal.tagTotal,
          inventoryDepreciation:
            result.agreementTotal.agreementInventoryDepreciation,
        };
      });
      setItemInformation(ResultInv);
    } else {
      setItemInformation(filteredData);
    }
    setViewAgrContext(result);
    setviewAgreementData(result);
    // setItemInformation(result.agreementInventories);
    setEnbleLoaderButton(false);
    const SecondArr = [result.agreementTotal];
    setAgreementTotal(SecondArr);
    const ThirdArr = [result.agreementInformation];
    // eslint-disable-next-line no-console
    console.log("Third Grid Values", ThirdArr);
    //console.log('Third Grid Values', result.agreementInformation.policy);
    setAgreementInformation(ThirdArr);
    setWhitecardValues(result.initialPayment);
    setBluecardValues(result.totalPayment);
    setAgreementInfoHead(result.agreementInformation);
    if (
      result.agreementInformation.agreementStatus !== undefined &&
      result.agreementInformation.agreementStatus !== null &&
      result.agreementInformation.agreementStatus === "Pending"
    )
      setdelievrydata(result.agreementInformation.agreementStatus);
    if (
      result.agreementInformation.agreementStatus !== undefined &&
      result.agreementInformation.agreementStatus !== null &&
      result.agreementInformation.agreementStatus ===
      "Void or Bad/Unload record"
    ) {
      setIsagreementVoided(true);
    }
    // setgridOne(GridOneValues);
    // setgridTwo(GridSecondValues);
    // setgridThree(GridThirdValues);
    // setgridFour(GridFourthValues);
    setopenAgrInfo(true);

    const policyButtonApi = async () => {
      if (
        agreementId !== undefined &&
        customerId !== undefined &&
        storeNo !== undefined
      ) {
        console.log("this add call is happening");

        const policyResponse = await getCustomerPolicy(
          agreementId,
          customerId,
          storeNo
        );
        if (policyResponse.status == 200) {
          setgetCustomerPolicyResponse(policyResponse);
          setPolicyBindData(policyResponse.data.policyInfo);
          setEnbleLoaderButton(false);
          setgetCustomerCoverage(policyResponse);
          setcoverageData(policyResponse);
        }
        // else if (
        //   policyResponse.status == 400 ||
        //   policyResponse.status == 500
        // ) {
        //   setopenPolicyPopup(false);
        // }
      }
    };
    policyButtonApi();
    //New Code
    setcontrolLoader(false);
  };

  const openPopup = () => {
    setopenRePrint(true);
  };
  // const openDeliveryPop = () => {
  //   setopenDeliveryPopup(true);
  // };
  const openPolicyPop = () => {
    setopenPolicyPopup(true);
  };
  const closePopup = () => {
    // if (chkReport) {
    //   setreprintData(chkReport);
    // }
    setopenRePrint(false);
    //setopenDeliveryPopup(false);//
    setopenPolicyPopup(false);
  };
  // const openDeliveryMethod = () =>{
  //   setopenAgrInfo(false);
  // }
  // const openAgrInfoPAge = () =>{
  //   setopenAgrInfo(true);
  // }
  // const whiteMemo = useMemo(() => {
  //   return <WhiteCard propsData={requiredData} />;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // const blueMemo = useMemo(() => {
  //   return <BlueCard />;
  // }, []);
  //const history = useHistory();

  // const policyModalPopUp = () => {
  //   if (PolicyBindData !== undefined) {
  //     return (
  //       <div
  //         id="reprint"
  //         data-bs-backdrop="static"
  //         data-bs-keyboard="false"
  //         tabIndex={-1}
  //         aria-labelledby="reprint"
  //         aria-hidden="true"
  //       >
  //         <Grid item md={12} className={classes.textCenter}>
  //           <Typography className={`${classes.semiBold} ${classes.font16}`}>
  //             {PolicyBindData.policyStatus == 'A' ? (
  //               <Grid className={classes.textCenter}>
  //                 <Typography>
  //                   Would you like to remove <strong>RAC Tire Plus</strong> from
  //                   the agreement?
  //                 </Typography>
  //                 <Typography>
  //                   By selecting Yes the schedule payment amount will decrease
  //                   by <strong>$ {PolicyBindData.policyAmountWithTax}</strong>
  //                 </Typography>
  //               </Grid>
  //             ) : (
  //               <Grid className={classes.textCenter}>
  //                 <Typography>
  //                   Would you like to add <strong>RAC Tire Plus</strong> from
  //                   the agreement?
  //                 </Typography>
  //                 <Typography>
  //                   By selecting Yes the schedule payment amount will increase
  //                   by <strong>$ {PolicyBindData.policyAmountWithTax}</strong>
  //                 </Typography>
  //               </Grid>
  //             )}
  //           </Typography>
  //         </Grid>
  //         <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
  //           <RACButton
  //             color="primary"
  //             variant="outlined"
  //             className={classes.ms2}
  //             size="large"
  //             onClick={closePoliPopup}
  //           >
  //             No
  //           </RACButton>
  //           <RACButton
  //             color="primary"
  //             variant="contained"
  //             className={classes.mx1}
  //             size="large"
  //             onClick={accept}
  //           >
  //             Yes
  //           </RACButton>
  //         </Grid>
  //       </div>
  //     );
  //   }
  // };

  const policyRemovePopUp = () => {
    console.log("open popUp");
    console.log("PolicyBindData", PolicyBindData);
    return (
      <div
        id="reprint"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="reprint"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.semiBold} ${classes.font16}`}>
            <Grid className={classes.textCenter}>
              <Typography>
                Would you like to remove{" "}
                <Typography component="span" className={classes.bold}>
                  RAC Tire Plus
                </Typography>{" "}
                from the agreement?
              </Typography>
              <Typography>
                By selecting 'Yes' the scheduled payment amount will decrease by{" "}
                <Typography component="span" className={classes.bold}>
                  $ {PolicyBindData.policyAmountWithTax}
                </Typography>
              </Typography>
            </Grid>
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            color="primary"
            variant="outlined"
            className={classes.ms2}
            size="large"
            onClick={closePoliPopup}
          >
            No
          </RACButton>
          <RACButton
            color="primary"
            variant="contained"
            className={classes.mx1}
            size="large"
            onClick={accept}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };

  const confirmedCancelSwitchout = async () => {
    setcontrolLoader(true);
    const agreementInfoUpdatedResult = await getAgreementInfo(agreementId);
    const result = agreementInfoUpdatedResult.data;
    setcontrolLoader(false);
    if (result.errors !== undefined && result.errors[0].code == 400) {
      console.log("reached 400", result);

      setisDataAvailable(false);
    } else if (result.errors !== undefined && result.errors[0].code == 500) {
      setisException(true);
    }
    setViewAgrContext(result);
    setviewAgreementData(result);
    setviewAgrData(agreementInfoUpdatedResult);
    setIsSacDaysCompleted(result.agreementWorkflow.isSacDaysCompleted);
    setepoAmount(result.agreementInformation.epoAmount);
    setIsInReinstateMode(result.agreementWorkflow.isReinstated);
    let filteredData: any[]
    if (result?.agreementWorkflow?.isSwitchoutEnabled == 1) {
      console.log('isSwitchoutEnabled', result);
      filteredData = result.agreementInventories.filter(function (element) {
        console.log("filterer object", element);
        return element.inventoryStatusRefcode !== "PSO";
      });
    }
    else {
      console.log('notIsSO', result);
      filteredData = result.agreementInventories
    }
    const agrresult = result.agreementInventories.some(
      (value: any) => value.tagRate == undefined
    );
    console.log("agrresult", agrresult);
    if (agrresult) {
      const ResultInv = filteredData.map((value) => {
        return {
          agreementRate: value.agreementRate,
          agreementTerm: value.agreementTerm,
          agreementTotal: value.agreementTotal,
          brand: value.brand,
          department: value.department,
          description: value.description,
          inventoryId: value.inventoryId,
          inventoryNumber: value.inventoryNumber,
          modelNumber: value.modelNumber,
          subDepartment: value.subDepartment,
          tagRate: result.agreementTotal.tagRate,
          tagTerm: result.agreementTotal.tagTerm,
          tagTotal: result.agreementTotal.tagTotal,
          inventoryDepreciation:
            result.agreementTotal.agreementInventoryDepreciation,
        };
      });
      setItemInformation(ResultInv);
    } else {
      setItemInformation(filteredData);
    }
    //setItemInformation(filteredData);
    setEnbleLoaderButton(false);
    const SecondArr = [result.agreementTotal];
    setAgreementTotal(SecondArr);
    const ThirdArr = [result.agreementInformation];
    setAgreementInformation(ThirdArr);
    setWhitecardValues(result.initialPayment);
    setBluecardValues(result.totalPayment);
    setAgreementInfoHead(result.agreementInformation);
    // setgridOne(GridOneValues);
    // setgridTwo(GridSecondValues);
    // setgridThree(GridThirdValues);
    // setgridFour(GridFourthValues);
    setopenAgrInfo(true);
  };

  const policyAddPopUp = () => {
    console.log("open popUp");
    if (PolicyBindData !== undefined) {
      console.log("PolicyBindData", PolicyBindData);
      return (
        <div
          id="reprint"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="reprint"
          aria-hidden="true"
        >
          <Grid item md={12} className={classes.textCenter}>
            <Typography className={`${classes.semiBold} ${classes.font16}`}>
              <Grid className={classes.textCenter}>
                <Typography>
                  Would you like to add{" "}
                  <Typography component="span" className={classes.bold}>
                    RAC Tire Plus
                  </Typography>{" "}
                  from the agreement?
                </Typography>
                <Typography>
                  By selecting 'Yes' the scheduled payment amount will increase
                  by{" "}
                  <Typography component="span" className={classes.bold}>
                    $ {PolicyBindData.policyAmountWithTax}
                  </Typography>
                </Typography>
              </Grid>
            </Typography>
          </Grid>
          <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
            <RACButton
              color="primary"
              variant="outlined"
              className={classes.ms2}
              size="large"
              onClick={closePoliPopup}
            >
              No
            </RACButton>
            <RACButton
              color="primary"
              variant="contained"
              className={classes.mx1}
              size="large"
              onClick={accept}
            >
              Yes
            </RACButton>
          </Grid>
        </div>
      );
    }
  };

  useEffect(() => {
    /* eslint-disable no-console */
    console.log("Required Data in Agr Comp", props);
    console.log("Required Data Customer Id", customerId);
    const PageLoad = async () => {
      //const agreementInfoResult = await getAgreementInfo(agreementId);
      console.log("getViewAgrData[0] checker log", getViewAgrData[0]);
      configPaymentFunctions();

      let agreementInfoResult: any;
      if (getViewAgrData[0] === undefined) {
        agreementInfoResult = await getAgreementInfo(agreementId);
        setviewAgrData(agreementInfoResult);
      } else {
        agreementInfoResult = getViewAgrData[0];
        setviewAgrData(getViewAgrData[0]);
      }

      const result = agreementInfoResult.data;
      setIsSacDaysCompleted(result.agreementWorkflow.isSacDaysCompleted);
      setepoAmount(result.agreementInformation.epoAmount);
      setIsInReinstateMode(result.agreementWorkflow.isReinstated); // setentireAgrInfoResponseToGrid(result);
      setDeliveryStatus(result?.agreementWorkflow?.isDeliveryCompleted == '1');
      if (result.errors !== undefined && result.errors[0].code == 400) {
        console.log("reached 400", result);

        setisDataAvailable(false);
      } else if (result.errors !== undefined && result.errors[0].code == 500) {
        setisException(true);
      }

      if (
        result !== undefined &&
        agreementInfoResult.status == 200 &&
        result.agreementInformation.policy !== "N/A"
      ) {
        setisTireAgreement(true);
      }

      // if (result.errors[0].code == 400) {
      //   setisDataAvailable(false);
      // } else if (result.errors[0].code == 500) {
      //   setisException(true);
      // }
      // eslint-disable-next-line no-console
      console.log("Total Result", result);
      setViewAgrContext(result);
      setviewAgreementData(result);
      setReceivedDepartment(result.agreementInventories[0].department);
      let filteredData: any[]
      if (result?.agreementWorkflow?.isSwitchoutEnabled == 1) {
        console.log('isSwitchoutEnabled', result);
        filteredData = result.agreementInventories.filter(function (element) {
          console.log("filterer object", element);
          return element.inventoryStatusRefcode !== "PSO";
        });
      }
      else {
        console.log('notIsSO', result);
        filteredData = result.agreementInventories
      }
      const agrresult = result.agreementInventories.some(
        (value: any) => value.tagRate == undefined
      );
      console.log("agrresult", agrresult);
      if (agrresult) {
        const ResultInv = filteredData.map((value) => {
          return {
            agreementRate: value.agreementRate,
            agreementTerm: value.agreementTerm,
            agreementTotal: value.agreementTotal,
            brand: value.brand,
            department: value.department,
            description: value.description,
            inventoryId: value.inventoryId,
            inventoryNumber: value.inventoryNumber,
            modelNumber: value.modelNumber,
            subDepartment: value.subDepartment,
            tagRate: result.agreementTotal.tagRate,
            tagTerm: result.agreementTotal.tagTerm,
            tagTotal: result.agreementTotal.tagTotal,
            inventoryDepreciation:
              result.agreementTotal.agreementInventoryDepreciation,
          };
        });
        setItemInformation(ResultInv);
      } else {
        setItemInformation(filteredData);
      }
      //setItemInformation(filteredData); // first grid Value
      console.log(
        "result.agreementInventories in agr info",
        result.agreementInventories
      );
      // setItemInformation(result.agreementInventories);
      setEnbleLoaderButton(false);
      const SecondArr = [result.agreementTotal];
      setAgreementTotal(SecondArr);
      const ThirdArr = [result.agreementInformation];
      // eslint-disable-next-line no-console
      console.log("Third Grid Values", ThirdArr);
      //console.log('Third Grid Values', result.agreementInformation.policy);
      setAgreementInformation(ThirdArr);
      setWhitecardValues(result.initialPayment);
      setBluecardValues(result.totalPayment);
      setAgreementInfoHead(result.agreementInformation);
      if (
        result.agreementInformation.agreementStatus !== undefined &&
        result.agreementInformation.agreementStatus !== null &&
        result.agreementInformation.agreementStatus === "Pending"
      )
        setdelievrydata(result.agreementInformation.agreementStatus);
      if (
        result.agreementInformation.agreementStatus !== undefined &&
        result.agreementInformation.agreementStatus !== null &&
        result.agreementInformation.agreementStatus ===
        "Void or Bad/Unload record"
      ) {
        setIsagreementVoided(true);
      }
    };
    PageLoad();

    // setgridOne(GridOneValues);
    // setgridTwo(GridSecondValues);
    // setgridThree(GridThirdValues);
    // setgridFour(GridFourthValues);
    setopenAgrInfo(true);

    const policyButtonApi = async () => {
      if (
        agreementId !== undefined &&
        customerId !== undefined &&
        storeNo !== undefined
      ) {
        console.log("this normal call is happening");

        const policyResponse = await getCustomerPolicy(
          agreementId,
          customerId,
          storeNo
        );
        if (policyResponse.status == 200) {
          setgetCustomerPolicyResponse(policyResponse);
          setPolicyBindData(policyResponse.data.policyInfo);
          setEnbleLoaderButton(false);
          setgetCustomerCoverage(policyResponse);
          setcoverageData(policyResponse);
        }
        // else if (
        //   policyResponse.status == 400 ||
        //   policyResponse.status == 500
        // ) {
        //   setopenPolicyPopup(false);
        // }
      }
    };
    if (getCustomerCoverage == undefined) {
      if (getViewAgrData[0]?.data?.agreementInformation?.agreementStatus == 'Active') {
        policyButtonApi();
      }

    } else {
      setgetCustomerPolicyResponse(getCustomerCoverage);
      setPolicyBindData(getCustomerCoverage.data.policyInfo);
      setEnbleLoaderButton(false);
      setcoverageData(getCustomerCoverage);
    }
    const deliveryCall = async () => {
      console.log(
        "getViewAgrData[0]?.data",
        getViewAgrData[0]?.data?.agreementInformation?.appointmentId
      );
      const appointmentId = getViewAgrData[0]?.data?.agreementInformation?.appointmentId
      if (appointmentId) {
        const ApiResult = await getAgreementDelivery(
          agreementId,
          appointmentId
        );
        if (ApiResult.status == 200) {
          const result = await GetDeliveryInformationMapper(
            getViewAgrData[0]?.data,
            ApiResult.data
          );

          console.log("getAGResponse", result);
          setdeliveryMethodData(result);
          //setdeliveryMethodData(ApiResult.data);
        }
      }
    };
    if (deliveryMethodData == undefined) {
      deliveryCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementId]); //controlLoader

  // useEffect(() => {
  //   console.log('value in coverageData', coverageData);
  // }, [coverageData]);
  function GetDeliveryInformationMapper(
    agreementInformation: any,
    eventInformation: any
  ): any {
    if (agreementInformation != undefined && eventInformation != undefined) {
      const getDeliveryInformation: any = {};
      getDeliveryInformation.agreementId =
        agreementInformation.agreementInformation.agreementId;
      getDeliveryInformation.appiontmentType = eventInformation.appointmentInfo.appointmentType;
      getDeliveryInformation.deliveryMethodStatus = deliveryMethodStatusDefine(
        eventInformation.appointmentInfo.appointmentType,
        eventInformation.appointmentInfo.appointmentStatusDesc,
        agreementInformation.agreementWorkflow.isSwitchoutEnabled,
        agreementInformation.agreementInformation.agreementStatusGroupCode,
        agreementInformation.agreementWorkflow.isDeliveryCompleted
      );
      getDeliveryInformation.deliveryInformation = {
        eventId: agreementInformation.agreementInformation.appointmentId,
        deliveryType: deliveryTypeDefine(
          eventInformation.appointmentInfo.appointmentType,
          eventInformation.appointmentInfo.appointmentTypeDesc,
          eventInformation.appointmentInfo.appointmentStatusDesc,
          agreementInformation.agreementWorkflow.isSwitchoutEnabled
        ),
        appointmentSource: eventInformation.appointmentInfo.appointmentSource,
        deliveryStatus: eventInformation.appointmentInfo.appointmentStatusDesc,
        addressId: eventInformation.customerInfo.address?.addressId,
        addressLine1: eventInformation.customerInfo.address?.addressLine1,
        addressLine2: eventInformation.customerInfo.address?.addressLine2,
        zip: eventInformation.customerInfo.address?.postalCode,
        zipPlus4: eventInformation.customerInfo.address?.postalCode,
        city: eventInformation.customerInfo.address?.city,
        state: eventInformation.customerInfo.address?.state,
        timeSlotId: eventInformation.timeSlotInfo.timeSlot,
        appointmentDate: eventInformation.appointmentInfo.appointmentDate,
        appointmentStartTime: eventInformation.timeSlotInfo.startTime,
        appointmentEndTime: eventInformation.timeSlotInfo.endTime,
        coWorkersRequired: eventInformation.appointmentInfo.requiredCoworkers,
        instructions: eventInformation.appointmentInfo.instructions,
      };
      return getDeliveryInformation;
    }
    return undefined;
  }

  function deliveryTypeDefine(
    appointmentType: string,
    appointmentTypeDesc: string,
    appointmentStatusDesc: string,
    switchout: string
  ): string {
    let appointmentTypeDescription: any = appointmentTypeDesc;
    if (["P", "CI"].includes(appointmentType)) {
      appointmentTypeDescription = "Return";
    }
    return appointmentTypeDescription;
  }
  function deliveryMethodStatusDefine(
    appointmentType: string,
    appointmentStatusDesc: string,
    switchout: string,
    agreementStatusGroup: string,
    isDeliveryCompleted: string
  ): string {
    //View Delivery Method
    let deliveryMethodStatus = "0";
    console.log(
      appointmentType,
      appointmentStatusDesc,
      switchout,
      agreementStatusGroup,
      isDeliveryCompleted
    );
    if (
      appointmentStatusDesc === "Completed" ||
      (["D", "CO"].includes(appointmentType) &&
        ["Completed"].includes(appointmentStatusDesc) &&
        isDeliveryCompleted === "1") ||
      (agreementStatusGroup == "CLOSED" &&
        appointmentStatusDesc == "Cancelled") ||
      ["SCI", "SCO", "SD", "S", "SP"].includes(appointmentType) ||
      (["P", "CI", "D", "CO"].includes(appointmentType) &&
        ["Completed", "Cancelled"].includes(appointmentStatusDesc) &&
        isDeliveryCompleted === "1")
    ) {
      deliveryMethodStatus = "1";
    }
    //View Delivery Method with No Appointment Records
    else if (["VOID", "CLOSED"].includes(agreementStatusGroup)) {
      deliveryMethodStatus = "2";
    }
    return deliveryMethodStatus;
  }
  const PayScheduleFunction = () => {
    if (
      AgreementInfoHead !== undefined &&
      AgreementInfoHead.paySchedule !== null &&
      AgreementInfoHead.payDay !== null &&
      AgreementInfoHead.paymentSchedule !== null
    ) {
      return (
        <Grid className={`${classes.widthAuto} ${classes1.dSmNone}`}>
          <Typography
            variant="caption"
            className={agrInfoClassName.agrInfoGeneral}
          >
            Pay Schedule :
          </Typography>{" "}
          {AgreementInfoHead !== undefined &&
            AgreementInfoHead.paySchedule == "Monthly" &&
            AgreementInfoHead.payDay == null ? (
            <Typography
              variant="caption"
              className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
            >
              {" "}
              {AgreementInfoHead !== undefined
                ? AgreementInfoHead.paySchedule
                : null}{" "}
            </Typography>
          ) : (
            <Typography
              variant="caption"
              className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
            >
              {AgreementInfoHead.paySchedule} on {AgreementInfoHead.payDay}
            </Typography>
          )}
        </Grid>
      );
    } else {
      return (
        <Grid className={`${classes.widthAuto} ${classes1.dSmNone}`}>
          <Typography
            variant="caption"
            className={agrInfoClassName.agrInfoGeneral}
          >
            Pay Schedule :
          </Typography>{" "}
          {AgreementInfoHead !== undefined &&
            AgreementInfoHead.paymentSchedule !== null ? (
            <Typography
              variant="caption"
              className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
            >
              -
              {/* {' '}
              {AgreementInfoHead.paymentSchedule} */}
            </Typography>
          ) : null}
        </Grid>
      );
    }
  };

  const setMethodForInv = (invData: any) => {
    console.log("invData inn setMethodForInv", invData);

    setselectedInvId(invData);
  };

  const AgrInfo = async () => {
    setcontrolLoader(true);
    const agreementInfoUpdatedResult = await getAgreementInfo(agreementId);
    setviewAgrData(agreementInfoUpdatedResult);
    setcontrolLoader(false);
    setReturnAgrInformation(agreementInfoUpdatedResult);
    setviewAgreementData(agreementInfoUpdatedResult.data);
  };

  const isAgrVoided = () => {
    console.log("received void value");

    // if (value === true) {
    //   setIsDeliveryMethodFaild(true);
    // }
  };

  const buildPolicyException = () => {
    return (
      <div>
        <Grid className="text-center">
          <Erroricon />
          <Typography className="racErrorFunction">
            Something Went Wrong
          </Typography>
        </Grid>
        <Grid className="text-center">
          <RACButton
            className="racSearch racSearchPrimary me-2"
            onClick={() => setisPolicyException(false)}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const receiveThrownDepartment = (value: any) => {
    setReceivedDepartment(value);
  };

  return (
    <div>
      {controlLoader ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      {EnableLoaderButton === false && isDataAvailable ? (
        <div>
          {viewAgrData.data.agreementInformation.agreementType == 'Promo' ?
            <div className={classes.agreementContainerSpacing}>
              <Grid md={12} className={`${classes.mb2} ${classes1.mtMd4}`}>
                <Typography className={classes.title} variant="h6">
                  Item Information
                </Typography>
                <Card className={classes.card}>
                  <CardContent>
                    <GridPage
                      mapper={gridOnePromo}
                      GridValues={ItemInformation}
                      throwStyles={classes}
                      receiveSelectedInv={setMethodForInv}
                      reveiveDepartment={receiveThrownDepartment}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid md={12} className={`${classes.mb2} ${classes1.mtMd4}`}>
                <Typography className={classes.title} variant="h6">
                  Agreement Total
                </Typography>
                <Card className={classes.card}>
                  <CardContent>
                    <GridPage
                      mapper={gridTwo}
                      SecondGridValues={AgreementTotal}
                      throwStyles={classes}
                      receiveSelectedInv={setMethodForInv}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid md={12} className={`${classes.mb2} ${classes1.mtMd4}`}>
                <Typography className={classes.title} variant="h6">
                  Agreement Information
                </Typography>
                <Card className={classes.card}>
                  <CardContent>
                    <Grid md={12}>
                      <Grid
                        className={`${classes.widthAuto} ${classes.floatLeft}`}
                      >
                        <Grid
                          className={`${classes.widthAuto} ${classes.floatLeft} ${classes.racLightBlue} ${agrInfoClassName.agrBorderRadius} ${classes.px2} ${classes.py2}`}
                        >
                          <Grid
                            className={`${classes.widthAuto} ${classes.floatLeft}`}
                          >
                            <Typography
                              variant="caption"
                              className={agrInfoClassName.agrInfoGeneral}
                            >
                              Agreement# :{' '}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
                            >
                              {AgreementInfoHead !== undefined
                                ? AgreementInfoHead.agreementNumber
                                : null}
                            </Typography>
                          </Grid>

                          <Grid
                            className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms3}`}
                          >
                            <Typography
                              variant="caption"
                              className={agrInfoClassName.agrInfoGeneral}
                            >
                              Agreement Type :{' '}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
                            >
                              {AgreementInfoHead !== undefined
                                ? AgreementInfoHead.agreementType
                                : null}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {AgreementInformation !== undefined &&
                      AgreementInformation.policy !== 'N/A' ? (
                      <GridPage
                        mapper={gridFour}
                        ThirdGridValues={AgreementInformation}
                        throwStyles={classes}
                        receiveSelectedInv={setMethodForInv}
                      // throwentireAgrInfoResponseToGrid={
                      //   entireAgrInfoResponseToGrid
                      // }
                      />
                    ) : (
                      <GridPage
                        mapper={gridThree}
                        ThirdGridValues={AgreementInformation}
                        throwStyles={classes}
                        receiveSelectedInv={setMethodForInv}
                      // throwentireAgrInfoResponseToGrid={
                      //   entireAgrInfoResponseToGrid
                      // }
                      />
                    )}
                    {/* <Grid
                      mapper={gridThree}
                      ThirdGridValues={AgreementInformation}
                    /> */}
                  </CardContent>
                </Card>
              </Grid>

              <Grid container className={`${classes.mb2} ${classes1.mtMd4}`}>
                <Grid item className={agrInfoClassName.paymentViewWidget}>
                  <Grid container spacing={3}>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        <Typography className={classes.title}>
                          Initial Payment
                        </Typography>
                        <Card className={classes.card}>
                          <CardContent className={classes.p32}>
                            <Grid
                              container
                              className={`${classes.mb2} ${classes.mt2}`}
                            >
                              <Grid item md={6} className={classes.floatLeft}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.cardLabelPromo}
                                >
                                  Security Deposit
                                </Typography>
                              </Grid>
                              <Grid item md={6}>
                                <Grid className={classes.floatRight}>
                                  <Typography
                                    className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
                                  >
                                    $
                                  </Typography>
                                  <CurrencyInput
                                    id="input-example"
                                    name=""
                                    style={{ zIndex: 0, textAlign: 'right' }}
                                    // className={`${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`}
                                    className={`${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`
                                    }
                                    decimalsLimit={2}
                                    // onValueChange={(e: any) => {
                                    //   const value = e?.toFixed(2);
                                    //   setsecurityDeposit(value);
                                    // }}
                                    // onBlur={(e: any) =>
                                    //   SecurityDeposit(e.target.value)
                                    // }
                                    data-testid="ProcessingFeeTxtBoxTest"
                                    value={'0.00'}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={`${classes.mb2} ${classes.mt4}`}
                            >
                              <Grid item md={7}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.cardLabelPromo}
                                >
                                  Additional Transportation
                                </Typography>
                              </Grid>
                              <Grid item md={5}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.widgetAlign}
                                >
                                  $ 0.00
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={`${classes.mb2} ${classes.mt4}`}
                            >
                              <Grid item md={7}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.cardLabelPromo}
                                >
                                  Installation
                                </Typography>
                              </Grid>
                              <Grid item md={5}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.widgetAlign}
                                >
                                  $ 0.00
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={`${classes.mb2} ${classes.mt4}`}
                            >
                              <Grid item md={7}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.cardLabelPromo}
                                >
                                  Rush
                                </Typography>
                              </Grid>
                              <Grid item md={5}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.widgetAlign}
                                >
                                  $ 0.00
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={`${classes.mb2} ${classes.mt4}`}
                            >
                              <Grid item md={7}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.cardLabelPromo}
                                >
                                  Cancellation
                                </Typography>
                              </Grid>
                              <Grid item md={5}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.widgetAlign}
                                >
                                  $ 0.00
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={`${classes.mb2} ${classes.mt4}`}
                            >
                              <Grid item md={7}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.cardLabelPromo}
                                >
                                  Restocking
                                </Typography>
                              </Grid>
                              <Grid item md={5}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.widgetAlign}
                                >
                                  $ 0.00
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={`${classes.mb2} ${classes.mt4}`}
                            >
                              <Grid item md={7}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.cardLabelPromo}
                                >
                                  Cleaning
                                </Typography>
                              </Grid>
                              <Grid item md={5}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.widgetAlign}
                                >
                                  $ 0.00
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={`${classes.mb2} ${classes.mt4}`}
                            >
                              <Grid item md={7}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.cardLabelPromo}
                                >
                                  Restore
                                </Typography>
                              </Grid>
                              <Grid item md={5}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.widgetAlign}
                                >
                                  $ 0.00
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              className={`${classes.mb2} ${classes.mt4}`}
                            >
                              <Grid item md={7}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.cardLabelPromo}
                                >
                                  Product Based
                                </Typography>
                              </Grid>
                              <Grid item md={5}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.widgetAlign}
                                >
                                  $ 0.00
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              className={`${classes.mb2} ${classes.pt2} ${paymentCostClassName.payscheduleBorder} ${paymentCostClassName.alignCenter} ${classes.mt4}`}
                            >
                              <Grid item md={7}>
                                <Typography
                                  variant="subtitle1"
                                  className={`${classes.font18} ${classes.semiBold}`}
                                >
                                  Initial Payment{' '}
                                </Typography>
                              </Grid>
                              <Grid item md={5}>

                                <Typography
                                  variant="subtitle1"
                                  className={`${classes.font24} ${classes.bold} ${classes.textRight}`}
                                >
                                  $ 0.00
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item md={6}>
                        <Typography className={classes.title}>
                          Total Payment/Total Cost
                        </Typography>

                        <Card className={paymentCostClassName.bluecard}>
                          <CardContent className={classes.p4}>
                            <Grid
                              container
                              className={`${classes.mb2} ${classes.mt4}`}
                            >
                              <Grid item md={7}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.blueCardLabel}
                                >

                                  Rental Charge
                                </Typography>
                              </Grid>
                              <Grid item md={5}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.blueWidgetAlign}
                                >
                                  {/* $ {formatMoney(totalPaymentAmount.RentalCharge)} */}
                                  $ 0.00
                                </Typography>

                              </Grid>
                            </Grid>

                            <Grid
                              container
                              className={`${classes.mb2} ${classes.mt4}`}
                            >
                              <Grid item md={7}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.blueCardLabel}
                                >
                                  Total Fees
                                </Typography>
                              </Grid>
                              <Grid item md={5}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.blueWidgetAlign}
                                >
                                  {/* $ {formatMoney(totalPaymentAmount.TotalFees)} */}
                                  $ 0.00
                                </Typography>

                              </Grid>
                            </Grid>

                            <Grid
                              container
                              className={`${classes.mb2} ${classes.mt4}`}
                            >
                              <Grid item md={7}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.blueCardLabel}
                                >
                                  Tax
                                </Typography>
                              </Grid>
                              <Grid item md={5}>
                                <Typography
                                  variant="subtitle1"
                                  className={paymentCostClassName.blueWidgetAlign}
                                >
                                  {/* $ {formatMoney(totalPaymentAmount.Tax)} */}
                                  $ 0.00
                                </Typography>

                              </Grid>
                            </Grid>
                            <Card
                              className={`${classes.card} ${classes.p3} ${paymentCostClassName.mtcustom} ${paymentCostClassName.totalPaymentShade}`}
                            >
                              <Grid
                                container
                                className={paymentCostClassName.alignCenter}
                              >
                                <Grid item md={7}>
                                  <Typography
                                    variant="subtitle1"
                                    className={paymentCostClassName.totalValue}
                                  >
                                    Total Payments Due
                                  </Typography>
                                </Grid>
                                <Grid item md={5}>
                                  <Typography
                                    variant="subtitle1"
                                    className={
                                      paymentCostClassName.agreementTotal
                                    }
                                  >
                                    {/* ${' '}
                                  {formatMoney(
                                    totalPaymentAmount.TotalPaymentDue
                                  )} */}
                                    $ 0.00
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Card>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            :
            <div className={classes.agreementContainerSpacing}>
              <Grid md={12} className={`${classes.mb2} ${classes1.mtMd4}`}>
                <Typography className={`${classes.title} ${classes1.title}`} variant="h6">
                  Item Information
                </Typography>
                <Card className={classes.card}>
                  <CardContent>
                    <GridPage
                      mapper={gridOne}
                      GridValues={ItemInformation}
                      throwStyles={classes}
                      receiveSelectedInv={setMethodForInv}
                      reveiveDepartment={receiveThrownDepartment}
                      UsingField={"InvInfo"}
                    // throwentireAgrInfoResponseToGrid={
                    //   entireAgrInfoResponseToGrid
                    // }
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid md={12} className={`${classes.mb2} ${classes1.mtMd4}`}>
                <Typography className={`${classes.title} ${classes1.title}`} variant="h6">
                  Agreement Total
                </Typography>
                <Card className={classes.card}>
                  <CardContent>
                    <GridPage
                      mapper={gridTwo}
                      SecondGridValues={AgreementTotal}
                      throwStyles={classes}
                      receiveSelectedInv={setMethodForInv}
                      UsingField={"AgrTotal"}
                    // throwentireAgrInfoResponseToGrid={
                    //   entireAgrInfoResponseToGrid
                    // }
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid md={12} className={`${classes.mb2} ${classes1.mtMd4}`} >
                <Typography className={`${classes.title} ${classes1.title}`} variant="h6">
                  Agreement Information
                </Typography>
                <Card className={classes.card}>
                  <CardContent>
                    <Grid md={12}>
                      <Grid
                        className={`${classes.widthAuto} ${classes.floatLeft} ${classes1.wMd100}`}
                      >
                        <Grid
                          className={`${classes.widthAuto} ${classes.floatLeft} ${classes.racLightBlue} ${agrInfoClassName.agrBorderRadius} ${classes.px2} ${classes.py2} ${classes1.wMd100} ${classes1.flexMdCenter}`}
                        >
                          <Grid
                            className={`${classes.widthAuto} ${classes.floatLeft}`}
                          >
                            <Typography
                              variant="caption"
                              className={agrInfoClassName.agrInfoGeneral}
                            >
                              Agreement# :{" "}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
                            >
                              {AgreementInfoHead !== undefined
                                ? AgreementInfoHead.agreementNumber
                                : null}
                            </Typography>
                          </Grid>

                          <Grid
                            className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms3}`}
                          >
                            <Typography
                              variant="caption"
                              className={agrInfoClassName.agrInfoGeneral}
                            >
                              Agreement Type :{" "}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
                            >
                              {AgreementInfoHead !== undefined
                                ? AgreementInfoHead.agreementType
                                : null}
                            </Typography>
                          </Grid>

                          <Grid
                            className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms3}`}
                          >
                            {PayScheduleFunction()}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms2} ${classes1.dMdNone}`}
                      >
                        <Grid
                          className={`${classes.widthAuto} ${classes.floatLeft} ${classes.racLightBlue} ${agrInfoClassName.agrBorderRadius} ${classes.px2} ${classes.py2}`}
                        >
                          <Grid
                            className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms2} ${classes.me2}`}
                          >
                            <Typography
                              variant="caption"
                              className={agrInfoClassName.agrInfoGeneral}
                            >
                              Original Term :
                            </Typography>{" "}
                            <Typography
                              variant="caption"
                              className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
                            >
                              {AgreementInfoHead !== undefined
                                ? AgreementInfoHead.orginalTerm
                                : null}
                            </Typography>
                          </Grid>

                          <Grid
                            className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms3}`}
                          >
                            <Typography
                              variant="caption"
                              className={agrInfoClassName.agrInfoGeneral}
                            >
                              Remaining Term :
                            </Typography>{" "}
                            <Typography
                              variant="caption"
                              className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
                            >
                              {AgreementInfoHead !== undefined
                                ? AgreementInfoHead.remainingTerm
                                : null}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {AgreementInformation !== undefined &&
                      AgreementInformation.policy !== "N/A" ? (
                      <GridPage
                        mapper={gridFour}
                        ThirdGridValues={AgreementInformation}
                        throwStyles={classes}
                        receiveSelectedInv={setMethodForInv}
                        UsingField={"AgrInfo"}
                      // throwentireAgrInfoResponseToGrid={
                      //   entireAgrInfoResponseToGrid
                      // }
                      />
                    ) : (
                      <GridPage
                        mapper={gridThree}
                        ThirdGridValues={AgreementInformation}
                        throwStyles={classes}
                        receiveSelectedInv={setMethodForInv}
                        UsingField={"AgrInfo"}
                      // throwentireAgrInfoResponseToGrid={
                      //   entireAgrInfoResponseToGrid
                      // }
                      />
                    )}
                    {/* <Grid
                      mapper={gridThree}
                      ThirdGridValues={AgreementInformation}
                    /> */}
                  </CardContent>
                </Card>
              </Grid>

              <Grid container className={`${classes.mb6} ${classes1.mtMd4}`}>
                <Grid item className={agrInfoClassName.paymentViewWidget}>
                  <Grid container spacing={3}>
                    <WhiteCard
                      propsData={WhitecardValues}
                      throwStyle={classes}
                      throwIsTire={isTireAgreement}
                    />
                    <BlueCard propsData={BluecardValues} throwStyle={classes} />
                  </Grid>
                </Grid>
              </Grid>
            </div>}
        </div>
      ) : EnableLoaderButton === true ? (
        <div className={classes.Norecords}>
          <CircularProgress />
        </div>
      ) : isDataAvailable === false ? (
        <div className={`${classes.textCenter} ${classes.mt5}`}>
          <AlertIcon />
          <Typography className={`${classes.subTitle} ${classes.mb3}`}>
            No Documents Found
          </Typography>
        </div>
      ) : isException ? (
        <div className={`${classes.textCenter} ${classes.mt5}`}>
          <AlertIcon />
          <Typography className={`${classes.subTitle} ${classes.mb3}`}>
            Unable to fetch Data
          </Typography>
        </div>
      ) : null}
      <Footer
        triggeropenPopup={openPopup}
        AgrInfo={AgrInfo}
        showInfofooter={InfoFooter}
        //triggeropenDeliveryPop={openDeliveryPop}
        triggeropenPolicyPop={openPolicyPop}
        // controlPage={controlDelivery}
        controlSubmitButton={openAgrInfo}
        controldeliveryschedule={delievrydata}
        func={pull_data}
        agreementvoided={IsagreementVoided}
        currentPage="AgrInfo"
        receiveIsVoid={isAgrVoided}
        throwselectedInvId={selectedInvId}
        throwgetCustomerPolicyResponse={getCustomerPolicyResponse}
        throwcoverageData={coverageData}
        triggerconfirmedCancelSwitchout={confirmedCancelSwitchout}
        throwreceivedDepartment={receivedDepartment}
      />
      {openRePrint ? (
        <RePrintReport closeMethod={closePopup} throwPayload={printPayLoad} />
      ) : null}

      {openPolicyPopup ? (
        <RACModalCard
          isOpen={true}
          maxWidth="xs"
          borderRadius={"25px !important"}
        >
          {/* {PolicyBindData == undefined ? (
            <Grid className={classes.masterLoader}>
              <Grid className={classes.Loader}>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : PolicyBindData.policyStatus == 'A' ? (
            policyRemovePopUp()
          ) : (
            policyAddPopUp()
          )} */}
          {PolicyBindData.policyStatus == "A"
            ? policyRemovePopUp()
            : policyAddPopUp()}

        </RACModalCard>
      ) : null}
      <RACModalCard
        isOpen={isPolicyException}
        maxWidth="xs"
        borderRadius={"25px !important"}
        children={buildPolicyException()}
      />
      {
        exchangeNavigationPopup ? (
          <RACExchange value={{
            customerId: customerId,
            agreementId: agreementId,
            agreementInformation: getViewAgrData,
            setExchangeNavigationPopup: setExchangeNavigationPopup,
            exchangeNavigationPopup: exchangeNavigationPopup,
            isRacExchangeFuncEnabled: isRacExchangeFuncEnabled
          }} />
        ) : null
      }
      {exchangeTwoFactorAuth ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setExchangeTwoFactorAuth(false);
            setExchangeStarted(false);
          }}
          setTwoFactorCompleted={(value) => {
            if (value) {
              setExchangeTwoFactorAuth(false);
              setExchangeNavigationPopup(true);
            }
          }}
          moduleName={'RACExchange'}
          currentRole={CurrentRole}
        ></SecondFactor>
      ) : null}
    </div>
  );
};

export default AgreementInfo;