/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useState, useContext } from 'react';
import {
  Grid,
  Typography,
  RACModalCard,
  RACButton,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { ContainerContext } from '../../../app/App';
import { amActivityLogInsertion } from '../../../api/user';
import { useHistory } from 'react-router-dom';
import moment from "moment";

export interface DeliveryNotesPopupInterface {
  deliveryNotesPopupOpen: boolean;
  customerId: string;
  customerName: string;
  phoneNumber: string;
  deliveryNotesCompletedFn: any;
  deliveryNotesCancelledFn: any;
  installerDetails: any;
}
interface CustomPropInterface {
  GetRole: () => string;
  GetEmployeeId: () => string;
  GetName: () => string;
}
export default function DeliveryNotesPopup(props: DeliveryNotesPopupInterface) {
  const {
    deliveryNotesPopupOpen,
    customerId,
    customerName,
    phoneNumber,
    deliveryNotesCompletedFn,
    deliveryNotesCancelledFn,
    installerDetails,
  } = props;

  const classes = agreementGlobalStyles();
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const history = useHistory();
  const [callNotesTxtValue, setCallNotesTxtValue] = useState('');

  const convertPhoneNumberFormat = (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        '($1) $2-$3'
      );
  };
  const saveClick = () => {
    try {
      const LogActivityPayload = {
        storeNumber: window.sessionStorage.getItem('storeNumber'),
        customerId: Number(customerId),
        coWorkerId: containerData?.GetEmployeeId(),
        acctActivityRefCode: 'CALLC',
        activityDate: new Date(),
        phoneNumberDialed: phoneNumber,
        notes: callNotesTxtValue,
      };
      amActivityLogInsertion(LogActivityPayload);
      deliveryNotesCompletedFn();
    } catch {
      deliveryNotesCancelledFn();
    }
  };

  const deliveryNotesPopup = () => {
    return (
      <>
        <Grid
          style={{
            color: '#4a5174',
            fontFamily: 'OpenSans-semibold',
            fontSize: '11px',
            lineHeight: '16px',
            marginTop: '-10px',
          }}
        >
          Contact the customer whose tire installation has just completed.
          verify they are satisfied with their install and note any response in
          the field below. confirm their install is completed and inventory has
          been delivered by selecting the delivery confirmation button.
        </Grid>
        <Grid style={{ marginTop: '10px' }}>
          <Typography style={{ fontFamily: 'OpenSans-bold' }}>Customer Information</Typography>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '5px' }}>

          <Grid item md={4} >
            <Typography
              style={{ fontSize: '13px', fontFamily: 'OpenSans-bold' }}
            >
              Customer Name
            </Typography>
            <Typography
              style={{
                fontSize: '11px',
                fontFamily: 'OpenSans-semibold',
                color: 'rgb(13, 110, 253)',
                cursor: 'pointer',
                marginTop: '5px'
              }}
            >
              <div
                onClick={() => {
                  history.push(`/customer/update/${customerId}/customer`);
                }}
              >
                {customerName ? customerName : '-'}
              </div>
            </Typography>
          </Grid>
          <Grid item md={4} >
            <Typography
              style={{ fontSize: '13px', fontFamily: 'OpenSans-bold' }}
            >
              Phone #
            </Typography>
            <Typography
              style={{
                fontSize: '11px',
                fontFamily: 'OpenSans-semibold',
                color: 'rgb(13, 110, 253)',
                marginTop: '5px'
              }}
            >
              {phoneNumber ? convertPhoneNumberFormat(phoneNumber) : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Typography style={{ marginTop: '22px', marginBottom: '10px', fontFamily: 'OpenSans-bold' }}>
            Installer Information
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={4}>
            <Typography style={{ fontSize: '13px', fontFamily: 'OpenSans-bold' }}>Installer Name</Typography>
            <Typography style={{
              fontFamily: 'OpenSans-semibold', color: '#4a5174', fontSize: '11px', marginTop: '5px'
            }}>{installerDetails?.installerName ? installerDetails?.installerName : '-'}</Typography>
          </Grid>
          <Grid item md={4}>
            <Typography style={{ fontSize: '13px', fontFamily: 'OpenSans-bold' }}>Installed Date</Typography>
            <Typography style={{ fontFamily: 'OpenSans-semibold', color: '#4a5174', fontSize: '11px', marginTop: '5px' }}>{installerDetails?.scheduledDate ? moment(installerDetails?.scheduledDate).format('MM/DD/YYYY') : '-'}</Typography>
          </Grid>
          <Grid item md={4}>
            <Typography style={{ fontSize: '13px', fontFamily: 'OpenSans-bold' }}>Time</Typography>
            <Typography style={{ fontFamily: 'OpenSans-semibold', color: '#4a5174', fontSize: '11px', marginTop: '5px' }}>{installerDetails.time ? installerDetails.time == 'AM' ? '11.00 AM' : installerDetails.time == 'PM' ? '1.00 PM' : installerDetails.time : '-'}</Typography>
          </Grid>
        </Grid>
        <Grid md={10} style={{ width: '100%', marginTop: '5%' }}>
          <Typography
            style={{ fontSize: '12px', fontFamily: 'OpenSans-semibold' }}
          >
            Call Notes
            <span
              style={{ marginLeft: '2px' }}
              className={classes.mandatoryfield}
            >
              *
            </span>
          </Typography>
          <textarea
            style={{ padding: '4px !important' }}
            maxLength={255}
            className={classes.callNotes}
            placeholder="Enter the call notes here"
            value={callNotesTxtValue}
            onChange={(e) => {
              setCallNotesTxtValue(e.target.value);
            }}
          ></textarea>
          <Grid style={{ float: 'right', marginTop: '4%' }}>
            <RACButton
              variant="outlined"
              color="default"
              onClick={() => {
                deliveryNotesCancelledFn();
              }}
            >
              Cancel
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              style={{ marginLeft: '15px' }}
              disabled={callNotesTxtValue.trim() == ''}
              onClick={() => saveClick()}
            >
              Save
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <RACModalCard
      title="Tire Delivery Notes"
      isOpen={deliveryNotesPopupOpen}
      borderRadius={'25px !important'}
      closeIcon={true}
      maxWidth="md"
      className={classes.deliveryNotesModal}
      onClose={() => {
        deliveryNotesCancelledFn();
      }}
    >
      {deliveryNotesPopup()}
    </RACModalCard>
  );
}
