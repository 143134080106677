/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { createContext, useState } from 'react';
export const ReinstateAgreementContext = createContext<any>('');
export function ReinstateAgreementContextProvider(props: any) {
  const [PreviousItemData, setPreviousItemData] = useState();
  const [ItemSearchData, setItemSearchData] = useState();
  const [CustomerInfo, setCustomerInfo] = useState();
  const [addedItem, setaddedItem] = useState();
  const [isSOEnabledbyconfig, setisSOEnabledbyconfig] = useState('0');
  const [isChoosePriceConfig, setisChoosePriceConfig] = useState('0');
  const [gotSOConfigResponse, setgotSOConfigResponse] = useState(false);

  const [searchResult, setsearchResult] = useState();
  const [itemArrayData, setitemArrayData] = useState([]);
  const [copyArrayData, setcopyArrayData] = useState();
  const [offSetValue, setOffSetValue] = useState(1);
  const [searchCriteria, setsearchCriteria] = useState({
    itemNo: '',
    serialNo: '',
    bracket: '',
    brand: '',
    model: '',
    conditionId: null,
    departments: '',
    subDepartments: '',
    packageTypeval: '',
  });
  const [oldInvRV, setoldInvRV] = useState();

  const [numberOfInventory, setnumberOfInventory] = useState();
  const [reinstatementGridData, setreinstatementGridData]: any = useState();
  const [isSacDaysCompleted, setisSacDaysCompleted] = useState();
  const [epoAmount, setepoAmount] = useState();
  const [agreementCreateStatus, setagreementCreateStatus] = useState({
    agreementCreatedStatus: false,
  });
  const deleteIndexObject = {
    replacedInvId: null,
  };
  const [depPercentage, setdepPercentage] = useState('0.00');
  const [containSubDepartment, setcontainSubDepartment] = useState();
  const [containCondition, setcontainCondition] = useState();
  const [containDepartment, setcontainDepartment] = useState();
  const [deletedIndex, setdeletedIndex] = useState(deleteIndexObject);
  const [storeReinstateInvID, setstoreReinstateInvID] = useState();
  const [copyOfReinstateGrid, setcopyOfReinstateGrid] = useState([]);
  const [agreementVoidStatus, setagreementVoidStatus] = useState({
    agreementVoidedStatus: false,
  });
  const [bluePackageName, setbluePackageName] = useState();
  const [reinstateDone, setreinstateDone] = useState(false);
  const [inventoryMapping, setinventoryMapping] = useState();

  useEffect(() => {
    if (reinstatementGridData !== undefined) {
      setstoreReinstateInvID(reinstatementGridData.itemPricing[0].itemNumber);
    }
  }, [reinstatementGridData]);
  useEffect(() => {
    console.log(
      'reinstatementGridData in context direct value',
      reinstatementGridData
    );
    console.log(
      'searchCriteria in context direct in reinstate',
      searchCriteria
    );
    console.log('to check the oldInv data', oldInvRV);
    console.log('monitor deletedIndex value', deletedIndex);
    console.log('vgfdsv inventoryMapping', inventoryMapping);
  }, [
    reinstatementGridData,
    searchCriteria,
    oldInvRV,
    deletedIndex,
    inventoryMapping,
  ]);
  const [storeReinstateGridData, setstoreReinstateGridData] = useState();
  const [reinstateCopyStore, setreinstateCopyStore] = useState();
  const [transferRights, settransferRights] = useState(false);
  const [collectedRVValue, setcollectedRVValue] = useState(0);
  return (
    <div>
      <ReinstateAgreementContext.Provider
        value={{
          setinventoryMapping,
          inventoryMapping,
          PreviousItemData,
          setPreviousItemData,
          transferRights,
          settransferRights,
          ItemSearchData,
          setItemSearchData,
          CustomerInfo,
          setCustomerInfo,
          searchCriteria,
          numberOfInventory,
          addedItem,
          setaddedItem,
          searchResult,
          setsearchResult,
          itemArrayData,
          setitemArrayData,
          copyArrayData,
          setcopyArrayData,
          setsearchCriteria,
          reinstatementGridData,
          setreinstatementGridData,
          setnumberOfInventory,
          agreementCreateStatus,
          setagreementCreateStatus,
          agreementVoidStatus,
          setagreementVoidStatus,
          deletedIndex,
          setdeletedIndex,
          copyOfReinstateGrid,
          setcopyOfReinstateGrid,
          storeReinstateInvID,
          setstoreReinstateInvID,
          oldInvRV,
          setoldInvRV,
          setbluePackageName,
          bluePackageName,
          storeReinstateGridData,
          setstoreReinstateGridData,
          reinstateCopyStore,
          setreinstateCopyStore,
          isSacDaysCompleted,
          setisSacDaysCompleted,
          epoAmount,
          setepoAmount,
          setcollectedRVValue,
          collectedRVValue,
          depPercentage,
          setdepPercentage,
          containSubDepartment,
          setcontainSubDepartment,
          containCondition,
          setcontainCondition,
          setcontainDepartment,
          containDepartment,
          reinstateDone,
          setreinstateDone,
          offSetValue,
          setOffSetValue,
          setisSOEnabledbyconfig,
          isSOEnabledbyconfig,
          gotSOConfigResponse,
          setgotSOConfigResponse,
          setisChoosePriceConfig,
          isChoosePriceConfig,
        }}
      >
        {props.children}
      </ReinstateAgreementContext.Provider>
    </div>
  );
}
