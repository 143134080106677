import React, { useContext, useEffect } from 'react';
import { Typography, Grid, Card, CardContent } from '@rentacenter/racstrap';
import { agrInfoBlueCardStyles } from '../../../JSstyles/agrInfoBlueCardStyles';
import CurrencyInput from 'react-currency-input-field';
import { paymentCost } from '../../../JSstyles/paymentCost';
import { AgreementContext } from '../../../context/AgreementContext';
import { fieldDeviceStyles } from '../../../JSstyles/fieldDeviceStyles';
const BlueCard = (props: any) => {
  const classes1 = fieldDeviceStyles();
  const paymentCostClassName = paymentCost();
  const { isSacDaysCompleted, epoAmount, isInReinstateMode } =
    useContext(AgreementContext);
  const InitialBlueValues = {
    initialPayment: '',
    regularPayment: '',
    finalPayment: '',
    cashPrice: '',
    totalCost: '',
    regularTerm: '',
    totalTerm: '',
  };

  const classes = props.throwStyle;
  const agrInfoBlueCardClassName = agrInfoBlueCardStyles();

  const [BlueValues, setBlueValues] = React.useState(InitialBlueValues);

  useEffect(() => {
    const BluecardDetails = props.propsData;
    if (BluecardDetails !== undefined) {
      setBlueValues(BluecardDetails);
    } else {
      setBlueValues(InitialBlueValues);
    }

    /* eslint-disable no-console */
    console.log(props);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.propsData]);

  const agreementTotal = BlueValues.totalCost.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );

  const regularPayment = BlueValues.regularPayment.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
  // console.log('BlueValues.cashPrice', BlueValues.cashPrice);

  // const SAC = BlueValues.cashPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // console.log('SAC in view agr', SAC);

  return (
    <Grid item md={6} className={classes1.widthtab50}>
      <Typography className={`${classes.title} ${classes1.title}`}>
        Total Payment / Total Cost
      </Typography>

      <Card className={agrInfoBlueCardClassName.bluecard}>
        <CardContent className={`${classes.p4} ${classes1.ptab16}`}>
          <Grid
            container
            className={`${classes.mb2} ${classes.mt4} ${classes1.mtTab0}`}
          >
            <Grid item md={7} sm={7}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoBlueCardClassName.bluecardlabel} ${classes1.tabFont12}`}
              >
                Initial Payment
              </Typography>
            </Grid>
            <Grid item md={5} sm={5}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoBlueCardClassName.bluewidgetalign} ${classes1.tabFont12}`}
              >
                $ {BlueValues.initialPayment}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            className={`${classes.mb2} ${classes.mt4} ${classes1.mtMd0}`}
          >
            <Grid item md={7} sm={7}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoBlueCardClassName.bluecardlabel} ${classes1.tabFont12}`}
              >
                Regular Payments ({BlueValues.regularTerm})
              </Typography>
            </Grid>
            <Grid item md={5} sm={5}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoBlueCardClassName.bluewidgetalign} ${classes1.tabFont12}`}
              >
                $ {regularPayment}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            className={`${classes.mb2} ${classes.mt4} ${classes1.mtMd0}`}
          >
            <Grid item md={7} sm={7}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoBlueCardClassName.bluecardlabel} ${classes1.tabFont12}`}
              >
                Final Payment
              </Typography>
            </Grid>
            <Grid item md={5} sm={5}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoBlueCardClassName.bluewidgetalign} ${classes1.tabFont12}`}
              >
                $ {BlueValues.finalPayment}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            className={`${classes.mb2} ${classes.mt4} ${classes1.mtMd0}`}
          >
            <Grid item md={12}>
              <Typography
                variant="subtitle1"
                className={`${agrInfoBlueCardClassName.bluecardlabel} ${classes1.tabFont12}`}
              >
                {' '}
                Total Payments ({BlueValues.totalTerm})
              </Typography>
            </Grid>
          </Grid>
          <Card
            className={`${classes.card} ${classes.p3} ${paymentCostClassName.mtcustom} ${paymentCostClassName.totalPaymentShade} ${classes1.mtMd5}`}
          >
            <Grid container className={classes.mb2}>
              {isSacDaysCompleted == '1' && isInReinstateMode == '1' ? (
                <Grid item md={7} className={classes.floatLeft}>
                  <Typography
                    variant="subtitle1"
                    className={agrInfoBlueCardClassName.totalvalue}
                  >
                    EPO Amount
                  </Typography>
                </Grid>
              ) : (
                <Grid item md={7} sm={4} className={classes.floatLeft}>
                  <Typography
                    variant="subtitle1"
                    className={`${agrInfoBlueCardClassName.totalvalue} ${classes1.tabFont12}`}
                  >
                    Same As Cash Total
                  </Typography>
                </Grid>
              )}

              <Grid item md={5} sm={8}>
                <Grid className={`${classes.floatRight} ${classes.flexCenter}`}>
                  <Typography
                    className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
                  >
                    $
                  </Typography>
                  {isSacDaysCompleted == '1' && isInReinstateMode == '1' ? (
                    <CurrencyInput
                      id="input-example"
                      name=""
                      style={{ zIndex: 0, textAlign: 'right' }}
                      className={`${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${agrInfoBlueCardClassName.disabledColour}`}
                      // decimalsLimit={2}
                      value={epoAmount}
                      disabled={true}
                    />
                  ) : (
                    <CurrencyInput
                      id="input-example"
                      name=""
                      style={{ zIndex: 0, textAlign: 'right' }}
                      className={`${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${agrInfoBlueCardClassName.disabledColour}`}
                      // decimalsLimit={2}

                      value={BlueValues.cashPrice}
                      disabled={true}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={paymentCostClassName.alignCenter}>
              <Grid item md={7} sm={7}>
                <Typography
                  variant="subtitle1"
                  className={`${agrInfoBlueCardClassName.totalvalue} ${classes1.tabFont12}`}
                >
                  Agreement Total
                </Typography>
              </Grid>
              <Grid item md={5} sm={5}>
                <Typography
                  variant="subtitle1"
                  className={agrInfoBlueCardClassName.agreementtotal}
                >
                  $ {agreementTotal}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </CardContent>
      </Card>
    </Grid>
    // </Grid>
    // </Grid>
    // </Grid>
  );
};
export default BlueCard;
