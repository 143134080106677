/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */

import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const paymentCost = () => {
  const useClasses = makeStyles((theme) => ({
    paymentWidget: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '830px',
    },
    mtcustom: {
      marginTop: theme.typography.pxToRem(61),
    },
    cardLabel: {
      color: '#313131',
      fontFamily: 'OpenSans-semibold',
      fontSize: '16px',
    },
    inputGroup: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      width: '100%',
    },
    cardLabelPromo: {
      color: '#313131',
      fontFamily: 'OpenSans-semibold',
      fontSize: '15px',
    },
    inputGroupText: {
      display: 'flex',
      alignItems: 'center',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      lineHeight: 1.5,
      color: '#212529',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: '#e9ecef',
      border: '1px solid #ced4da',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: '9px',
      borderBottomLeftRadius: '9px',
    },
    widgetAlign: {
      textAlign: 'right',
      fontSize: '16px',
      fontFamily: 'OpenSans-bold',
      color: '#313131',
    },
    payscheduleBorder: {
      borderTop: '1px solid #d9d9d9',
    },
    bluecard: {
      borderRadius: theme.typography.pxToRem(16),
      backgroundColor: `#2179FE`,
    },
    blueCardLabel: {
      color: `${RACCOLOR.WHITE}`,
      fontFamily: 'OpenSans-semibold',
      fontSize: '16px',
    },
    blueWidgetAlign: {
      color: `${RACCOLOR.WHITE}`,
      fontFamily: 'OpenSans-bold',
      fontSize: '16px',
      textAlign: 'right',
    },
    totalValue: {
      color: ' #266AFF',
      fontFamily: 'OpenSans-semibold',
      fontSize: '17px',
    },
    agreementTotal: {
      color: `#266AFF`,
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(24),
      textAlign: 'right',
    },
    formControl: {
      display: 'block',
      width: '100%',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#212529',
      backgroundColor: `${RACCOLOR.WHITE}`,
      border: '1px solid #ced4da',
      appearance: 'none',
      borderRadius: '0.25rem',
    },
    formLabel: {
      marginBottom: '0.2rem',
      color: '#111111',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    currencyInput: {
      float: 'left',
      width: '89px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    rateInput: {
      float: 'left',
      width: '150px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    rateAlign: {
      zIndex: 0,
      textAlign: 'right',
    },
    alignCenter: {
      alignItems: 'center',
    },
    disabledColour: {
      backgroundColor: '#e9ecef',
    },
    totalPaymentShade: {
      backgroundColor: '#EFF4FF',
    },
  }));
  const classes = useClasses();

  return classes;
};
