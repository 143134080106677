/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';

interface RouteProps {
  path: string;
  children: any;
}

export const Route = ({ path, children }: RouteProps) => {
  // state to track URL and force component to re-render on change
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  //let currentPath : string = '';
  console.log('Route component ', path, window.location.pathname);
  useEffect(() => {
    console.log('Route component useeffect', path, window.location.pathname);
    // define callback as separate function so it can be removed later with cleanup function
    const onLocationChange = () => {
      // update path state to current window URL
      setCurrentPath(window.location.pathname);
      //currentPath = window.location.pathname;
    };
    window.addEventListener('popstate', onLocationChange);
    // clean up event listener
    return () => {
      window.removeEventListener('popstate', onLocationChange);
    };
  }, []);

  return currentPath.includes(path) ? children : null;
};
