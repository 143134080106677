/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@rentacenter/racstrap';
export const searchResultStyles = () => {
  const useClasses = makeStyles((theme) => ({
    expandWigid: {
      width: '100%',
    },
    collapsedWigid: {
      width: '75%',
    },
    disableme: {
      pointerEvents: 'none',
      opacity: 0.9,
    },
    searchIconSizing: {
      height: '33px',
      marginRight: '10px',
    },
    paddingLeft: {
      paddingLeft: '2% !important',
    },
    showItemSearch: {
      display: 'block',
    },
    hideItemSearch: {
      display: 'none',
    },
  }));
  return useClasses();
};
