/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@rentacenter/racstrap';
export const fixedGridStyles = () => {
  const useClasses = makeStyles((theme) => ({
    fixTableHead: {
      overflowY: 'auto',
      maxHeight: '500px',
      '& th': {
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        fontFamily: 'OpenSans-semibold',
        zIndex: 1,
        padding: '0.9375rem 0.2rem',
        whiteSpace: 'nowrap',
      },
      '& tbody': {
        '& tr': {
          position: 'sticky',
          top: 10,
          backgroundColor: '#ffffff',
        },
        '& td': {
          fontFamily: 'OpenSans-semibold',
          padding: '0.9375rem 0.2rem',
          color: '#4A5174',
        },
      },
      disableme: {
        pointerEvents: 'none',
        opacity: 0.9,
      },
    },
    fixTableHeadAgrTrsRec: {
      overflowY: 'auto',
      maxHeight: '390px',
      '& th': {
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        fontFamily: 'OpenSans-bold',
        zIndex: 1,
        padding: '0.9375rem 0.2rem',
        whiteSpace: 'nowrap',
        fontSize: '15px',
        fontWeight: '400',
        color: '#111111',
      },
      '& tbody': {
        '& tr': {
          position: 'sticky',
          top: 10,
          backgroundColor: '#ffffff',
        },
        '& td': {
          fontFamily: 'OpenSans-semibold',
          padding: '0.9375rem 0.2rem',
        },
      },
      disableme: {
        pointerEvents: 'none',
        opacity: 0.9,
      },
    },
    AgrTrasnferRequestTable: {
      overflowY: 'auto',
      maxHeight: '300px',
      '& th': {
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        fontFamily: 'OpenSans-bold',
        zIndex: 1,
        padding: '0.9375rem 0.2rem',
        whiteSpace: 'nowrap',
      },
      '& tbody': {
        '& tr': {
          position: 'sticky',
          top: 10,
          backgroundColor: '#ffffff',
        },
        '& td': {
          fontFamily: 'OpenSans-semibold',
          padding: '0.9375rem 0.2rem',
        },
      },
      disableme: {
        pointerEvents: 'none',
        opacity: 0.9,
      },
    },
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useClasses();
};
