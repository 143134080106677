/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@rentacenter/racstrap';
export const agrInfoWhiteCardStyles = () => {
  const useClasses = makeStyles((theme) => ({
    widgetalign: {
      textAlign: 'right',
      fontSize: '16px',
      fontFamily: 'OpenSans-bold',
      color: '#313131',
    },
    cardlabel: {
      color: '#313131',
      fontFamily: 'OpenSans-semibold',
      fontSize: '16px',
    },

    borderTop: {
      borderTop: '1px solid #dee2e6',
    },
    disabledColour: {
      backgroundColor: '#e9ecef !important',
    },
  }));
  return useClasses();
};
