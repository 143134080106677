/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react';

export default function ContextSkeleton(
  responseDataValue,
  itemArrayValue,
  setterData,
  customerId,
  priceController,
  competitorDDValue,
  tireController?
): any {
  console.log('responseDataValue chedcdjvbw', responseDataValue);
  console.log('priceController jhfvbvhbf', priceController);
  const contextObject: any = {
    storeNumber: sessionStorage.getItem('storeNumber'),
    customerId: parseInt(customerId),
    agreementRate:
      parseFloat(priceController.AgrRateInp) ||
      parseFloat(responseDataValue.agreementPricing.agreementRate),
    agreementTerm:
      parseInt(priceController.AgrTermInp) ||
      parseInt(responseDataValue.agreementPricing.agreementTerm),
    sacTotal: parseFloat(responseDataValue.agreementPricing.cashPrice),
    inventoryIds: itemArrayValue,
    agreementType: 'RTO',
    actualAgreementRate: parseFloat(
      responseDataValue.agreementPricing.agreementRate
    ),
    actualAgreementTerm: parseInt(
      responseDataValue.agreementPricing.agreementTerm
    ),
    tagTotal: parseFloat(responseDataValue.agreementPricing.tagTotal),
    sacPrice: parseFloat(responseDataValue.agreementPricing.cashPrice),
    inventory: itemArrayValue,
    customer: [
      {
        customerId: parseInt(customerId),
        priority: 1,
      },
    ],
    AgreementTotal: responseDataValue.agreementPricing.agreementTotal,
    TireAgreementFlow: tireController,
    itemDescription:
      responseDataValue.itemPricing[0].packageName !== null
        ? responseDataValue.itemPricing[0].packageName
        : responseDataValue.itemPricing[0].inventoryName,
    competitorId: competitorDDValue == '0' ? null : competitorDDValue,
  };
  console.log('contextObject', contextObject);

  setterData(contextObject);
}
