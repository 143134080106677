/* eslint-disable */
// import { Switch, Route } from 'react-router-dom';
import AgreementInfo from './AgreementInfo';
import ViewAgreementHeader from './ViewAgreementHeader';
import DeliveryMethod from './DeliveryMethod';
import AgreementActivity from './AgreementActivity';
import { AppRouteKey } from '../../../config/route-config';
// import SecondFactor from './TwoFactorPopup';
import React, { useContext, useEffect, useState } from 'react';

// import { getAgreementInfo } from '../../api/user';

import { Route as CustomRoute } from '../../../router/Route';
import { AgreementContext } from '../../../context/AgreementContext';
import { useParams } from 'react-router-dom';
import { GetCustomer } from '../../../api/user';
interface Params {
  agreementId: string;
  customerId: string;
}

export default function ViewAgreementRoute() {
  const { customerId } = useParams<Params>();
  const { setGetCustomerResponse } = useContext(AgreementContext)

  // const [passingData, setpassingData] = useState();

  // useEffect(() => {
  //   const PageLoad = async () => {
  //     const result = await getAgreementInfo();
  //     setpassingData(result);
  //     /* eslint-disable no-console */
  //     console.log('Required Data', result);
  //     /* eslint-enable no-console */
  //   };
  //   PageLoad();
  // }, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  const pull_data = (data) => {
    if (data.coCustomerId !== '') {
      setcocustomerId(data.coCustomerId);
    }
    if (data.coCustomerName !== '') {
      setcoCustomerName(data.coCustomerName);
    }
    //setcoCustomerDetails()
    // eslint-disable-next-line no-console
    console.log('Footer contex', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };



  useEffect(() => {
    if (customerId) {
      getCustomerApi()
    }
  }, [])


  const getCustomerApi = async () => {
    const getCustomerRes = await GetCustomer(customerId, window.sessionStorage.getItem('storeNumber'))
    console.log("getCustomerResgetCustomerRes", getCustomerRes)
    if (getCustomerRes?.status == 200) {
      setGetCustomerResponse(getCustomerRes?.data)
    }
  }

  return (
    <div className="tab-content container-pb-custom">
      <div className="container-fluid">
        <ViewAgreementHeader
          CoCustomerDetails={{
            coCustomerId: coCustomerId,
            coCustomerName: coCustomerName,
          }}
        />

        <CustomRoute
          path="/agreement/info/details"
          key={AppRouteKey.AgreementInformation}
        >
          <AgreementInfo func={pull_data} />
        </CustomRoute>
        <CustomRoute
          path="/agreement/info/delivery"
          key={AppRouteKey.DeliveryMethodView}
        >
          <DeliveryMethod func={pull_data} />
        </CustomRoute>
        <CustomRoute
          path="/agreement/info/activity"
          key={AppRouteKey.AgreementActivity}
        >
          <AgreementActivity />
        </CustomRoute>
        {/* <Route
            exact
            path="/pinauth"
            key="/pinauth"
            component={SecondFactor}
          /> */}
      </div>
    </div>
  );
}
