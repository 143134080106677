/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useContext, useEffect } from 'react';
import {
  RACModalCard,
  RACButton,
  Grid,
  Typography,
} from '@rentacenter/racstrap';
import { Usercontext } from './searchResult';
import { AgreementContext } from '../../../context/AgreementContext';

const WhiteCard = (props: any): any => {
  const classes = props.throwStyle;
  const shareData: any = useContext(Usercontext);
  const { agreementCreateStatus, setdepPercentage, depPercentage } =
    useContext(AgreementContext);
  const whitecardobj: any = {
    tagRate: '0.00',
    tagTerm: '0.00',
    tagTotal: '0.00',
    agreementInventoryDepreciation: '0.00',
  };
  const [totalPrice, setcontectdata] = useState(whitecardobj);
  const [InvDeprPopUp, setInvDeprPopUp] = useState(false);

  useEffect(() => {
    if (shareData !== undefined) {
      console.log('Came inside white card if');
      console.log('Came inside white card if with sharedata', shareData);
      setcontectdata({
        ...totalPrice,
        tagRate: shareData.agreementPricing.tagRate,
        tagTerm: shareData.agreementPricing.tagTerm,
        tagTotal: shareData.agreementPricing.tagTotal,
        agreementInventoryDepreciation:
          shareData.agreementPricing.agreementInventoryDepreciation,
      });
      setdepPercentage(
        shareData.agreementPricing.agreementInventoryDepreciation
      );

      console.log(
        'totalPrice.agreementInventoryDepreciation',
        totalPrice.agreementInventoryDepreciation
      );
      console.log(
        'shareData.agreementPricing.agreementInventoryDepreciation',
        shareData.agreementPricing.agreementInventoryDepreciation
      );

      if (
        Number(shareData.agreementPricing.agreementInventoryDepreciation) >
          33 &&
        agreementCreateStatus.agreementCreatedStatus === false &&
        depPercentage !==
          shareData.agreementPricing.agreementInventoryDepreciation
      ) {
        console.log('reached inv check popup');
        setInvDeprPopUp(true);
      }
    } else {
      setcontectdata({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareData]);

  const AcceptDeprPopup = () => {
    setInvDeprPopUp(false);
  };
  const AcceptDeprPopupdiv = () => {
    return (
      <div
        data-testid="breakpopupcheck"
        id="decisionengine"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="decisionengine"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.semiBold} ${classes.font16}`}>
            Inventory Depreciation is greater than{' '}
            <Typography
              variant="caption"
              className={`${classes.bold} ${classes.font17}`}
            >
              {' '}
              33.00%.{' '}
            </Typography>
            <Typography className={`${classes.semiBold} ${classes.font16}`}>
              {' '}
              Do you want to continue ?
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            variant="contained"
            color="primary"
            size="large"
            onClick={() => AcceptDeprPopup()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <Grid md={12} className={classes.mb1}>
        <Typography
          variant="caption"
          className={`${classes.formLabel} ${classes.semiBold}`}
        >
          Tag Rate
        </Typography>

        <Typography
          variant="caption"
          className={`${classes.formLabel} ${classes.floatRight} ${classes.semiBold}`}
        >
          $ 0.00
        </Typography>
      </Grid>

      <Grid md={12} className={classes.mb1}>
        <Typography
          variant="caption"
          className={`${classes.formLabel} ${classes.semiBold}`}
        >
          Tag Term
        </Typography>

        <Typography
          variant="caption"
          className={`${classes.formLabel} ${classes.floatRight} ${classes.semiBold}`}
        >
          0
        </Typography>
      </Grid>
      <Grid md={12} className={classes.mb1}>
        <Typography
          variant="caption"
          className={`${classes.formLabel} ${classes.semiBold}`}
        >
          Tag Total
        </Typography>

        <Typography
          variant="caption"
          className={`${classes.formLabel} ${classes.floatRight} ${classes.semiBold}`}
        >
          $ 0.00
        </Typography>
      </Grid>
      <Grid md={12} className={classes.mb1}>
        <Typography
          variant="caption"
          className={`${classes.formLabel} ${classes.semiBold}`}
        >
          Inv Depr %
        </Typography>
        <Typography
          variant="caption"
          className={`${classes.formLabel} ${classes.floatRight} ${classes.semiBold}`}
        >
          0 %
        </Typography>
      </Grid>
      <RACModalCard
        isOpen={InvDeprPopUp}
        maxWidth="xs"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={AcceptDeprPopupdiv()}
      />
    </>
  );
};

export default WhiteCard;
