/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import {
  RACSelect,
  RACTextbox,
  RACButton,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@rentacenter/racstrap';
import { searchCriteriaStyles } from '../../../JSstyles/searchCriteriaStyles';
import { getSearchParams } from '../../../api/user';
import { SearchInventoryObj } from './itemSearchProps';
import { SwitchOutAgreementContext } from '../../../context/SwitchOutAgreementContext';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-btn.svg';

//PS - 1 Importing all required files from the corresponding folder
export type Componentprops = {
  inventoryId?: number;
  searchClick: (txtValue: SearchInventoryObj['itemObject']) => void;
  IsPackage?: boolean;
  throwStyle: any;
};
/* eslint-disable sonarjs/cognitive-complexity */
export default function SearchFilter(props: Componentprops) {
  const classes = props.throwStyle; //This will hold the global styles
  const searchCriteriaClassName = searchCriteriaStyles(); //This will hold the component specific styles
  const {
    searchCriteria,
    setsearchCriteria,
    agreementCreateStatus,
    containSubDepartment,
    setcontainSubDepartment,
    containCondition,
    setcontainCondition,
  } = useContext(SwitchOutAgreementContext);
  const [controlViewMode, setcontrolViewMode] = useState(false);
  const [conditionAry, setconditionAry] = useState<any>([]);
  const [departmentAry, setdepartmentAry] = useState<any>([]);
  const [subdepartmentAry, setsubdepartmentAry] = useState<any>([]);
  const [conditionLoader, setconditionLoader] = useState<boolean>(true);

  const initialTxtValues: SearchInventoryObj['itemObject'] = {
    itemNo: '',
    serialNo: '',
    bracket: '',
    brand: '',
    model: '',
    poNo: 0,
    locationTypeVal: 'Store',
    locationval: '986',
    statusId: 1,
    subStatusId: 2,
    conditionId: null,
    departments: searchCriteria.departments,
    subDepartments: '',
    packageTypeval: '',
    locationNumber: '',
    currentStoreNumber: '',
  }; //This is the initial object that holds the initial value of the Search Criteria

  const [TxtValues, setTxtValues] =
    useState<SearchInventoryObj['itemObject']>(initialTxtValues);
  const [searchbtndisable, setsearchbtndisable] = useState(true);
  const [clrbtndisable, setclrbtndisable] = useState(false);
  //PS - 2 Declaration of all required state variable for performing appropriate action
  useEffect(() => {
    console.log('session' + TxtValues);
    const currentStore = sessionStorage.getItem('storeNumber');
    setTxtValues({ ...TxtValues, locationNumber: currentStore });
    const getSearchParams_APIcall = async () => {
      setconditionLoader(true); //The loader for the Drop Down are enabled here
      const response: any = await getSearchParams();
      setconditionLoader(false); //The loader for the Drop Down are disabled here
      const buildConditionOption = response.data.conditionList;
      const setterCondition = [
        { id: '0', referenceCode: 'ALL' },
        ...buildConditionOption,
      ];
      setconditionAry(setterCondition); //Here the API response is destructured and Condition is extracted here
      setcontainCondition(setterCondition);
      if (searchCriteria !== undefined && searchCriteria.departments !== '') {
        console.log('reacgedjhfb kbvdhhjs');
        setdepartmentAry([
          { id: '0', description: searchCriteria.departments },
        ]);
      } else {
        setdepartmentAry([{ id: '0', description: 'Select' }]);
      }

      const buildSubDepartOption = response.data.subDepartmentList;
      const setterSubDepartment = [
        { id: '0', description: 'Select' },
        ...buildSubDepartOption,
      ];
      setsubdepartmentAry(setterSubDepartment); //Here the API response is destructured and Sub Department is extracted here
      setcontainSubDepartment(setterSubDepartment);
    };
    if (
      agreementCreateStatus.agreementCreatedStatus === false &&
      containSubDepartment == undefined &&
      containCondition == undefined
    ) {
      getSearchParams_APIcall();
    } else if (
      agreementCreateStatus.agreementCreatedStatus === false &&
      containSubDepartment !== undefined &&
      containCondition !== undefined
    ) {
      setconditionAry(containCondition);
      setsubdepartmentAry(containSubDepartment);
      setdepartmentAry([{ id: '0', description: searchCriteria.departments }]);
      setconditionLoader(false);
    } else if (
      agreementCreateStatus.agreementCreatedStatus === true &&
      searchCriteria !== undefined &&
      containSubDepartment !== undefined &&
      containCondition !== undefined
    ) {
      setconditionAry(containCondition);
      setsubdepartmentAry(containSubDepartment);
      setdepartmentAry([{ id: '0', description: searchCriteria.departments }]);
      setconditionLoader(false);
    } else {
      console.log('cdkhvbds6515sd');
      const setterCondition = [
        { id: '0', referenceCode: 'ALL' },
        { id: '1', referenceCode: 'None' },
      ];
      setconditionAry(setterCondition);
      const setterDepartment = [
        { id: '0', description: 'Select' },
        { id: '1', description: 'None' },
      ];
      setdepartmentAry(setterDepartment);
      const setterSubDepartment = [
        { id: '0', description: 'Select' },
        { id: '1', description: 'None' },
      ];
      setsubdepartmentAry(setterSubDepartment);
      setconditionLoader(false);
    }
    if (
      searchCriteria !== undefined &&
      agreementCreateStatus.agreementCreatedStatus === false
    ) {
      console.log('vdvbdhvdv1654654');
    }
    console.log('agreementCreateStatus in Criteria', agreementCreateStatus);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //PS - 3 This method is called at the page load. This will be calling the getSearchParams() API for populating Department, SubDepartment, Condition

  useEffect(() => {
    if (
      TxtValues.bracket.length > 2 ||
      TxtValues.brand.length > 0 ||
      TxtValues.model.length > 2 ||
      TxtValues.itemNo.length > 2 ||
      TxtValues.serialNo.length > 2 ||
      TxtValues.conditionId !== null ||
      TxtValues.subDepartments !== ''
    ) {
      setsearchbtndisable(false);
      setclrbtndisable(true);
    } else {
      setsearchbtndisable(true);
      setclrbtndisable(false);
    }
    console.log('TxtValues is updated as', TxtValues);
  }, [TxtValues]); //PS - 5 This method will be making the Clear button and Search button enable when a valid data is provided.

  useEffect(() => {
    console.log('searchCriteria in use effect data', searchCriteria);
    console.log('agreementCreateStatus in binding', agreementCreateStatus);

    if (searchCriteria !== undefined && agreementCreateStatus !== undefined) {
      if (agreementCreateStatus.agreementCreatedStatus === false) {
        console.log('vfskbkjf vdkhbvkjsd55525');

        setTxtValues({
          ...TxtValues,
          itemNo: searchCriteria.itemNo,
          bracket: searchCriteria.bracket,
          model: searchCriteria.model,
          brand: searchCriteria.brand,
          departments: searchCriteria.departments,
          subDepartments: searchCriteria.subDepartments,
          conditionId: searchCriteria.conditionId,
          packageTypeval: '',
        });
      } else {
        setcontrolViewMode(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // PS - 4 This method will be pre populating the Search Criteria values when the page is loaded.

  const dynamicViewClass = controlViewMode ? classes.disableme : 'enableme'; //This will be controlling the view mode and edit mode of the page.

  const EnterOnClick = (e: any) => {
    if (
      e.key == 'Enter' &&
      (TxtValues.bracket.length > 2 ||
        TxtValues.brand.length > 2 ||
        TxtValues.model.length > 2 ||
        TxtValues.itemNo.length > 2 ||
        TxtValues.serialNo.length > 2 ||
        TxtValues.conditionId !== null ||
        TxtValues.departments !== '' ||
        TxtValues.subDepartments !== '' ||
        TxtValues.packageTypeval !== '')
    ) {
      SearchOnclick();
      setsearchCriteria(TxtValues);
    }
  }; //PS - 6 This method is triggered when the user press enter button after entering a valid data in search criteria.

  const clearbtn = () => {
    // props.clearbtnClick(true);
    const currentStore = sessionStorage.getItem('storeNumber');
    setTxtValues({
      ...TxtValues,
      itemNo: '',
      serialNo: '',
      bracket: '',
      brand: '',
      model: '',
      poNo: 0,
      locationTypeVal: 'Store',
      locationval: '986',
      statusId: 1,
      subStatusId: 2,
      subDepartments: '',
      locationNumber: currentStore,
      currentStoreNumber: '',
      conditionId: null,
      packageTypeval: '',
      departments: searchCriteria.departments,
    });
    setclrbtndisable(false);
    setsearchbtndisable(true);
  }; //PS - 7 This method is triggered when the user click on clear button. This will make the txtValue variable to initial state and make the clear button hidded and make search button disable.

  const SearchOnclick = () => {
    (document.getElementById('a11y_Item') as HTMLInputElement).blur();
    (document.getElementById('a11y_bracket') as HTMLInputElement).blur();
    (document.getElementById('a11y_Model') as HTMLInputElement).blur();
    (document.getElementById('a11y_Brand') as HTMLInputElement).blur();
    (document.getElementById('a11y_Serial') as HTMLInputElement).blur();
    props.searchClick(TxtValues);
    console.log(TxtValues);
    setsearchCriteria(TxtValues);
  }; //PS - 8 This method is triggered when the user give an input in search criteria and click on Search Button

  const optionsArrayFunction = () => {
    if (departmentAry !== undefined) {
      return departmentAry.map((entry) => {
        return { label: entry.description, value: entry.description };
      });
    } else {
      return [{ label: 'Select', value: 'Select' }];
    }
  }; //This method is used to populate the Department Drop Down values

  const conditionArrayFunction = () => {
    if (conditionAry !== undefined) {
      return conditionAry.map((value: any, index: any) => {
        console.log('Counting log', index);
        let displayvalue: any;
        if (index >= 0) {
          if (value.referenceCode == 'EXCP') {
            displayvalue = 'Exception';
          } else if (value.referenceCode == 'ATO') {
            displayvalue = 'Auto';
          } else if (value.referenceCode == 'NEW') {
            displayvalue = 'New';
          } else if (value.referenceCode == 'ALL') {
            displayvalue = 'All';
          }
          return { label: displayvalue, value: value.id.toString() };
        }
      });
    } else {
      return [{ label: 'All', value: '0' }];
    }
  }; //PS - 9 This method is used to populate the Package Drop Down values

  const subDepartmentArrayFunction = () => {
    if (subdepartmentAry !== undefined) {
      return subdepartmentAry.map((entry: any) => {
        return { label: entry.description, value: entry.description };
      });
    } else {
      return [{ label: 'Select', value: 'Select' }];
    }
  }; //PS - 10 This method is used to populate the Condition Drop Down values

  const handleOnChangeTxtbox = (e: any) => {
    const FieldName = e.target.name;
    const InputValue = e.target.value;
    setTxtValues({ ...TxtValues, [FieldName]: InputValue });
  }; //PS - 11 This method is trigger when the give an input in the input fields of Search Criteria

  const handle_OnChangeTxtNumber = (e: any, number: any) => {
    if (e.target.value == '' || number.test(e.target.value)) {
      setTxtValues({ ...TxtValues, [e.target.name]: e.target.value });
    }
  }; //PS - 12 This method is triggered when the user give input as numbers

  const handleOnChangeItemTxtboxOnlyNumber = (e: any) => {
    if (e.target.id == 'a11y_Item') {
      const number = /^[0-9\b]+$/;
      handle_OnChangeTxtNumber(e, number);
    } else if (e.target.id == 'a11y_Serial') {
      setTxtValues({ ...TxtValues, [e.target.name]: e.target.value });
    }
  }; //PS - 13 This method is triggered when the user give input in Item# and Serial number in search criteria

  return (
    <div className={dynamicViewClass}>
      <Grid container className={classes.mt2}>
        <Grid item md={12}>
          {props.IsPackage !== false ? (
            <Typography className={classes.title} variant="h6">
              Search Criteria
            </Typography>
          ) : (
            <Typography className={classes.title} variant="h5">
              Package Item Search
            </Typography>
          )}
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Item #"
                    inputLabelClassname={classes.formLabel}
                    id="a11y_Item"
                    type="number"
                    name="itemNo"
                    isCurrency={false}
                    digitFormat="normal"
                    maxlength={13}
                    value={TxtValues.itemNo}
                    OnChange={(e) => handleOnChangeItemTxtboxOnlyNumber(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACSelect
                    inputLabel="Department"
                    inputLabelClassName={
                      searchCriteriaClassName.dropDownLabelAlign
                    }
                    loading={conditionLoader}
                    options={optionsArrayFunction()}
                    defaultValue={
                      TxtValues.departments !== ''
                        ? TxtValues.departments
                        : 'Select'
                    }
                    isDisabled={true}
                  />
                </Grid>
                <Grid item md={3}>
                  <RACSelect
                    inputLabel="Sub Department"
                    inputLabelClassName={
                      searchCriteriaClassName.dropDownLabelAlign
                    }
                    loading={conditionLoader}
                    options={subDepartmentArrayFunction()}
                    defaultValue={
                      TxtValues.subDepartments !== ''
                        ? TxtValues.subDepartments
                        : 'Select'
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      e.target.value == 'Select'
                        ? setTxtValues({
                            ...TxtValues,
                            subDepartments: '',
                          })
                        : setTxtValues({
                            ...TxtValues,
                            subDepartments: e.target.value,
                          });
                    }}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Bracket"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_bracket"
                    name="bracket"
                    isCurrency={false}
                    value={TxtValues.bracket}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Model #"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Model"
                    name="model"
                    isCurrency={false}
                    value={TxtValues.model}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Brand"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Brand"
                    name="brand"
                    isCurrency={false}
                    value={TxtValues.brand}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Serial #"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Serial"
                    name="serialNo"
                    maxlength={20}
                    isCurrency={false}
                    value={TxtValues.serialNo}
                    OnChange={(e) => handleOnChangeItemTxtboxOnlyNumber(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACSelect
                    inputLabel="Condition"
                    inputLabelClassName={
                      searchCriteriaClassName.dropDownLabelAlign
                    }
                    loading={conditionLoader}
                    options={conditionArrayFunction()}
                    defaultValue={
                      TxtValues.conditionId !== null
                        ? TxtValues.conditionId
                        : '0'
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setTxtValues({
                        ...TxtValues,
                        conditionId: e.target.value.toString(),
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.mt2}>
                {props.IsPackage === true ? (
                  <>
                    <Grid item md={3}>
                      <RACSelect
                        inputLabel="Package Type"
                        inputLabelClassName={
                          searchCriteriaClassName.dropDownLabelAlign
                        }
                        options={[{ label: 'Select', value: '' }]}
                        defaultValue={''}
                        isDisabled={true}
                        //need to set on change
                      />
                    </Grid>

                    <Grid item md={5}></Grid>

                    <Grid item md={4}>
                      <Grid className={`${classes.floatRight} ${classes.mt4}`}>
                        {/* {clrbtndisable ? ( */}
                        <a
                          className={`${classes.racpadLink} ${classes.mt2} ${classes.me4}`}
                          data-testid="ClearBtn"
                          onClick={clearbtn}
                        >
                          Clear
                        </a>
                        {/* ) : null}{' '} */}
                        <RACButton
                          startIcon={<SearchIcon />}
                          variant="contained"
                          color="primary"
                          className={searchCriteriaClassName.searchBtn}
                          size="large"
                          onClick={() => SearchOnclick()}
                        >
                          {/* <SearchIcon className={classes.me1} /> */}
                          Search
                        </RACButton>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
