export function getBlueInfo() {
  return [
    { initialpay: 26, regpay: 1649, finalpay: 4, sac: 1020, agrtotal: 1679 },
  ];
}
export function getItemInfo() {
  return [
    {
      agrrate: 21,
      agrterm: 56,
      agrtotal: 1500,
      invdepr: 25,
      itemdes: 'Samsung MX-T50/ZA',
      itemid: 954785642,
      tagrate: 20,
      tagterm: 58,
      tagtotal: 1150,
    },
  ];
}
export function getAgrTotalInfo() {
  return [
    {
      agrrate: 28,
      agrterm: 84,
      agrtotal: 12000,
      invdepr: 36,
      tagrate: 50,
      tagterm: 58,
      tagtotal: 6000,
    },
  ];
}
export function getAgrInfo() {
  return [
    {
      agrsrc: 'Walk In',
      daysext: 0,
      delcharge: 0,
      duedate: '2021-06-11T18:30:00.000Z',
      optservices: 'LDW',
      policy: 'RAC Tier Plus',
      promocode: 'WINTER100',
      salesperson: 'Griffin',
      sched: 'Bi-Weekly',
    },
  ];
}
export function getWhiteInfo() {
  return {
    discount: 0,
    extamt: 0,
    initialpay: 31,
    ldw: 3,
    other: 0,
    rate: 26,
    tax: 2,
  };
}

export function GetDropdownvalues() {
  return [
    {
      agreementsource: ['Walkin', 'CustomerOrder', 'Internet'],
      schedule: ['Weekly', 'Bi-Weekly', 'Semi-Monthly', 'Monthly'],
      deliverycharge: [
        { id: '', desc: 'Select' },
        { id: '0', desc: '0' },
        { id: '10', desc: '10' },
        { id: '20', desc: '20' },
        { id: '30', desc: '30' },
        { id: '40', desc: '40' },
        { id: '50', desc: '50' },
        { id: '100', desc: '100' },
        { id: '150', desc: '150' },
        { id: '250', desc: '250' },
      ],
      daysextension: ['1', '2', '3', '4'],
      optionalservices: ['LDW', 'LDW & Club'],
      salesperson: ['Habeeb', 'Joanna', 'Leyda'],
      range: [
        '1 & 15',
        '1 & 16',
        '1 & 17',
        '2 & 16',
        '2 & 17',
        '2 & 18',
        '3 & 17',
        '3 & 18',
        '3 & 19',
        '4 & 18',
        '4 & 19',
        '4 & 20',
        '5 & 19',
        '5 & 20',
        '5 & 21',
        '6 & 20',
        '6 & 21',
        '6 & 22',
        '7 & 21',
        '7 & 22',
        '7 & 23',
        '8 & 23',
        '8 & 24',
        '9 & 23',
        '9 & 24',
        '9 & 25',
        '10 & 24',
        '10 & 25',
        '10 & 26',
        '11 & 25',
        '11 & 27',
        '12 & 26',
        '12 & 27',
        '12 & 28',
        '13 & 27',
        '14 & 28',
        '15 & 29',
        '15 & 30',
      ],
    },
  ];
}
export function GetPriceQuote() {
  return [
    {
      agreementsource: ['Walkin', 'CustomerOrder', 'Internet'],
      schedule: ['Weekly', 'Bi-Weekly', 'SemiMonthly', 'Monthly'],
      deliverycharge: ['10', '20', '30', '40'],
      daysextension: ['1', '2', '3', '4'],
      optionalservices: ['LDW', 'LDW & Club'],
      salesperson: ['David', 'Jerold', 'James'],
    },
  ];
}
export function RangeDropValues() {
  return [
    { value: '1', label: '1 & 15' },
    { value: '2', label: '1 & 16' },
    { value: '3', label: '1 & 17' },
    { value: '4', label: '2 & 16' },
    { value: '5', label: '2 & 17' },
    { value: '6', label: '2 & 18' },
    { value: '7', label: '3 & 17' },
    { value: '8', label: '3 & 18' },
    { value: '9', label: '3 & 19' },
    { value: '10', label: '4 & 18' },
    { value: '11', label: '4 & 19' },
    { value: '12', label: '4 & 20' },
    { value: '13', label: '5 & 19' },
    { value: '14', label: '5 & 20' },
    { value: '15', label: '5 & 21' },
    { value: '16', label: '6 & 20' },
    { value: '17', label: '6 & 21' },
    { value: '18', label: '6 & 22' },
    { value: '19', label: '7 & 21' },
    { value: '20', label: '7 & 22' },
    { value: '21', label: '7 & 23' },
    { value: '22', label: '8 & 22' },
    { value: '23', label: '8 & 23' },
    { value: '24', label: '8 & 24' },
    { value: '25', label: '9 & 23' },
    { value: '26', label: '9 & 24' },
    { value: '27', label: '9 & 25' },
    { value: '28', label: '10 & 24' },
    { value: '29', label: '10 & 25' },
    { value: '30', label: '10 & 26' },
    { value: '31', label: '11 & 25' },
    { value: '33', label: '11 & 27' },
    { value: '34', label: '12 & 26' },
    { value: '35', label: '12 & 27' },
    { value: '36', label: '12 & 28' },
    { value: '37', label: '13 & 27' },
    { value: '38', label: '14 & 28' },
    { value: '39', label: '15 & 29' },
    { value: '40', label: '15 & 30' },
  ];
}
export function GetCustomerinfo() {
  return [
    {
      customervalue: 'David Jeffrey',
      customerid: '9999202352977',
      destatus: 'Approved',
    },
  ];
}
// export function GetPaymentinfo() {}
// export function GetVehicleDetails() {}
// export function GetAgreementInfo() {}
