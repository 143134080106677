/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { getPrintDocument } from '../../../api/user';
import { useParams } from 'react-router-dom';
import { ReactComponent as PDFImage } from '../../../assets/images/pdf-icon.svg';
// import { reprintStyle } from '../../JSstyles/reprintStyle';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';

import {
  RACModalCard,
  // RACButton,
  Grid,
  // RACCheckBox,
  Typography,
  // List,
  // ListItem,
  CircularProgress,
} from '@rentacenter/racstrap';

interface Params {
  agreementId: string;
  customerId: string;
}
const RePrintReport = (props: any) => {
  const classes = agreementGlobalStyles();
  // const reprintDocumentStyle = reprintStyle();

  // const [pdfURL, setpdfURL] = useState(
  //   'http://www.africau.edu/images/default/sample.pdf'
  // );
  const [controlPopup, setcontrolPopup] = useState(true);
  const [controlLoader, setcontrolLoader] = useState(true);
  const [DocumentData, setDocumentData] = useState<any>();
  const { agreementId } = useParams<Params>();
  const [isDataAvailable, setisDataAvailable] = useState(true);

  useEffect(() => {
    const payLoad: any = {
      identifier: agreementId,
      documentStatus: 'signed',
      actionType: 'print',
      identifierType: 'AGR',
    };
    const PageLoad = async () => {
      const printDocumentResult = await getPrintDocument(payLoad);
      if (printDocumentResult.status == 200) {
        console.log('getPrintDocument value', printDocumentResult);
        setDocumentData(printDocumentResult.data.documents);
        setcontrolLoader(false);
      } else if (printDocumentResult.status == 400) {
        setisDataAvailable(false);
        setcontrolLoader(false);
      }
    };
    PageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closePopup = () => {
    setcontrolPopup(false);
    props.closeMethod();
  };

  // const submitReport = () => {
  //   const chkReport: any = [];
  //   for (let i = 0; i < document.getElementsByName('report').length; i++) {
  //     if (
  //       (document.getElementsByName('report')[i] as HTMLInputElement)
  //         .checked === true
  //     ) {
  //       chkReport.push(
  //         (document.getElementsByName('report')[i] as HTMLInputElement).value
  //       );
  //     }
  //   }
  //   chkReport.map((selectedData: any) => {
  //     DocumentData.map((obj: any) => {
  //       if (selectedData == obj.documentName) {
  //         window.open(obj.documentUrl, '_blank');
  //       }
  //     });
  //   });
  //   props.closeMethod(chkReport);
  // };
  const b64toBlob = (b64Data: any, contentType: any, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: any = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };
  const viewPDFclick = (b64File: any, docuURL: any) => {
    console.log('viewPDFclick triggered');
    const urlArray = docuURL.split('.');
    const docuType = urlArray[urlArray.length - 1];
    let contentType = `application/${docuType}`;
    if (docuType === 'html') {
      contentType = 'text/html';
    }
    const blobResult = b64toBlob(b64File, contentType);
    const fileURL = URL.createObjectURL(blobResult);
    window.open(fileURL);
  };

  const RePrintDynamic = () => {
    return DocumentData.map((obj: any, index: any) => {
      return (
        <Grid item md={12} className={classes.mb3} key={index}>
          <PDFImage></PDFImage>
          <Typography
            variant="caption"
            className={`${classes.formLabel} ${classes.ms1}`}
          >
            {obj.documentName}
          </Typography>
          <span
            style={{ cursor: 'pointer' }}
            className={`${classes.racpadLink} ${classes.ms2} ${classes.textRight}`}
            onClick={() => {
              viewPDFclick(obj.file, obj.documentUrl);
            }}
          >
            View PDF
          </span>
        </Grid>
      );
    });
  };

  const ReprintModal = () => {
    if (isDataAvailable) {
      return (
        <Grid
          id="reprint"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="reprint"
          aria-hidden="true"
          className={classes.p3}
        >
          <Grid>
            {RePrintDynamic()}
            {/* <Grid
              item
              md={12}
              className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
            >
              <RACButton
                type="button"
                variant="outlined"
                color="secondary"
                size="large"
                className={`${classes.mx1} ${classes.floatRight}`}
                onClick={closePopup}
              >
                Cancel
              </RACButton>
            </Grid> */}
          </Grid>
        </Grid>
      );
    } else if (!isDataAvailable) {
      return (
        <div className={`${classes.textCenter}`}>
          <AlertIcon />
          <Typography className={`${classes.subTitle} ${classes.mb3}`}>
            No Data Found
          </Typography>
        </div>
      );
    }
  };

  return (
    <div>
      {controlLoader ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <RACModalCard
          maxWidth="xs"
          isOpen={controlPopup}
          closeIcon={true}
          onClose={closePopup}
          title="Reports"
          borderRadius={'25px !important'}
        >
          {ReprintModal()}
        </RACModalCard>
      )}
    </div>
  );
};

export default RePrintReport;
