/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { Grid, Typography, Card, CardContent, Button, RACButton, RACModalCard, RACTextbox } from '@rentacenter/racstrap'
import { agreementGlobalStyles } from '../../../../JSstyles/agreementGlobalStyles';
import SearchCriteriaComponent from './SearchCriteriaTB';
import SearchResultGrid from './searchResultGridTB';
import { ReactComponent as Plus } from '../../../../assets/images/plus.svg';
import { ReactComponent as Minus } from '../../../../assets/images/minus.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/images/info-icon.svg';

import Attachments from '../../../../assets/images/attachment.png'

import { CustomerOrderAgreementContext } from '../../../../context/CustomerOrderAgreementContext';
import { ReactComponent as Delete } from '../../../../assets/images/delete-item.svg';
import { ReactComponent as AlertIcon } from '../../../../assets/images/no-records-found.svg';
import { values } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
interface Params {
    customerId: string;
    agreementId: string;
}



export default function SearchContainer(props: any) {

    const { searchItem, setsearchItem, optionsArrayTB, setOptionsArrayTB, setToggleTB, toggleTB, searchItemObj, clearSearchCriteria, modelNumberArray, setModelNumberArray, addedItems, setAddedItems, itemSearchTableContent, setItemSearchTableContent, errorMessage, setErrorMessage, is500PopupOpened, setIs500PopupOpened, is400PopupOpened, setIs400PopupOpened, isToggleDisableAlert, setIsToggleDisableAlert, isNextPopupOpened, setIsNextPopupOpened, countOfTire, setCountOfTire, searchResultEnable,
        totalCashPrice, setTotalCashPrice,
        agreementTotal, setagreementTotal,
        setsearchResultEnable,
        year, setYear,
        make, setMake,
        model, setModel,
        style, setStyle,
        size, setSize,
        totalTires, setTotalTires,
        maxQtyReached, setmaxQtyReached,
        tagRate, setTagRate, tagTerm, setTagterm,
        agreementRate, setAgreementRate,
        agreementTerm, setAgreementTerm,
        differentModalPopup, setDifferentModalPopup
    } = useContext(CustomerOrderAgreementContext)
    const classes = agreementGlobalStyles();
    const { agreementId } = useParams<Params>();
    const { customerId } = useParams<Params>();
    const history = useHistory()

    const [tagTagTotal, setTagTotal] = useState("");


    const [imageSource, setImageSource] = useState('');
    const [isImagePopupOpened, setIsImagePopupOpened] = useState(false);

    const { salesInfo } = props;
    console.log('saleslead data', { salesInfo })

    useEffect(() => {
        let totalTires = 0
        for (let values of addedItems) {
            totalTires += values.quantity
        }
        setTotalTires(totalTires)
        console.log('totalTires', totalTires)
    }, [addedItems])

    useEffect(() => {
        console.log("addedItems1", addedItems);

    }, [addedItems])
    //! the follwing function will return the popup;
    const status500Popup = () => {
        return (
            <>
                <Grid className={classes.textCenter}>
                    <AlertIcon />
                    <Typography className={classes.formLabel}>
                        Something went Wrong!
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setIs500PopupOpened(false)
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid></>
        );
    };
    const status400Popup = () => {
        return (
            <>
                <Grid className={classes.textCenter}>
                    <AlertIcon />
                    <Typography className={classes.formLabel}>
                        {errorMessage}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setIs400PopupOpened(false)
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </>
        );
    };


    const maxQtyReachedpopupFn = () => {
        return (
            <>
                <Grid>

                    <Typography style={{ fontFamily: 'OpenSans-bold', textAlign: 'center', fontSize: '17px' }}>
                        Maximum  Quantity Reached: You can only add up to 6 quantity
                    </Typography>
                    <Grid style={{ textAlign: 'center', marginTop: '25px' }}>
                        <RACButton
                            color='primary'
                            variant='contained'
                            onClick={() => {
                                setmaxQtyReached(false)
                            }}
                        >
                            OK
                        </RACButton>
                    </Grid>

                </Grid>

            </>
        )
    }


    const differentModalPopupFn = () => {
        return (
            <>
                <Grid>

                    <Typography style={{ fontFamily: 'OpenSans-bold', textAlign: 'center', fontSize: '17px' }}>
                        Model# searched doesn't belong to the tire inventory
                    </Typography>
                    <Grid style={{ textAlign: 'center', marginTop: '25px' }}>
                        <RACButton
                            color='primary'
                            variant='contained'
                            onClick={() => {
                                setDifferentModalPopup(false)
                            }}
                        >
                            OK
                        </RACButton>
                    </Grid>

                </Grid>

            </>
        )
    }
    const toggleDisableAlert = () => {
        return (
            <>
                <Grid className={classes.textCenter}>
                    <Typography className={classes.formLabel} variant='h1'>
                        {"Clear the added tire products to turn off the toggle"}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setIsToggleDisableAlert(false)
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </>
        )
    };


    const tireSelectedOkClick = async () => {
        setIsNextPopupOpened(false)
        if (agreementId == undefined) {
            history.push({
                pathname: `/agreement/customerorder/details/${customerId}/?tbflow=1`,
            });
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
        } else {
            history.push({
                pathname: `/agreement/customerorder/details/${customerId}/${agreementId}/?tbflow=1`,
            });
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
        }
    }


    const nextButtonPopup = () => {
        return (
            <>
                <Grid className={classes.textCenter}>
                    <Typography className={classes.formLabel} style={{ fontFamily: 'OpenSans-bold', fontSize: '17px', marginBottom: '19px' }}>


                        {totalTires == 1 ? '1 tire selected' : totalTires == 2 ? '2 tires selected' : totalTires == 3 ? '3 tires selected' : totalTires == 4 ? '4 tires selected' : totalTires == 5 ? '5 tires selected' : totalTires == 6 ? '6 tires selected' : `${totalTires} tires selected`}

                    </Typography>
                    <Typography className={classes.formLabel} style={{ fontFamily: 'OpenSans-bold', fontSize: '17px' }}>

                        {totalTires >= 6 ? "Would you like to proceed further ?" : "Would you like to increase the quantity or proceed further ?"}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                            setIsNextPopupOpened(false)
                        }}
                    >
                        Edit Quantity
                    </RACButton>
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            tireSelectedOkClick()
                        }}
                    >
                        Next
                    </RACButton>
                </Grid>
            </>
        )
    };
    useEffect(() => {
        let agrTerm = 0;
        let agrRate: any = 0;
        let tagTotal: any = 0;
        let totalCash: any = 0;
        let agrTotal: any = 0;
        let tagRate: any = 0;
        let tagTerm: any = 0;
        for (let values of addedItems) {
            // agrTerm += Number(values.weeklyTerm) * Number(values.quantity)
            agrRate += Number(values.weeklyRate) * Number(values.quantity)
            tagTotal += Number(values.weeklyRate) * Number(values.weeklyTerm)
            totalCash += Number(values.cashPrice) * Number(values.quantity);
            tagRate += Number(values.weeklyRate);
            agrTerm = Number(values.weeklyTerm)
        }
        tagTerm = Number(tagTotal / tagRate).toFixed()
        agrTotal = Number(agrRate) * Number(addedItems[0]?.weeklyTerm)
        agrTotal = agrTotal.toFixed(2)
        tagTotal = tagTotal.toFixed(2)
        tagRate = tagRate.toFixed(2)
        agrRate = agrRate.toFixed(2)
        totalCash = totalCash.toFixed(2)
        setTagterm(String(tagTerm));
        setTagRate(String(tagRate));
        setTagTotal(String(tagTotal));
        setAgreementRate(String(agrRate));
        setAgreementTerm(String(agrTerm));
        setTotalCashPrice(String(totalCash));
        setagreementTotal(String(agrTotal));
    }, [addedItems]);

    function quanityFn(input, quanity) {
        debugger
        return quanity = input.target.value
    }


    function showImage(modelNumber: any) {
        try {
            setIsImagePopupOpened(true)
            const filteredImageSource = modelNumberArray.filter((value: any) => {
                return value.modelNumber == modelNumber
            })
            setImageSource(filteredImageSource[0]?.imageSource[0]);
        } catch (error: any) {
            console.log(error.message)
        }
    }
    function imageModel() {
        return (
            <Grid item>
                <img src={imageSource} style={{
                    "width": "100%",
                    "height": "250px"
                }} />
                <Grid container >
                    <Grid item md={4} style={{ padding: '10px' }}>
                        <span><b>Make :</b></span>
                        <span style={{ fontFamily: 'Opensans-semibold' }}>{make ? make : '-'}</span>
                    </Grid>
                    <Grid item md={4} style={{ padding: '10px' }}>
                        <span> <b>Year : </b></span>
                        <span style={{ fontFamily: 'Opensans-semibold' }}>{year ? year : '-'}</span>
                    </Grid>
                    <Grid item md={4} style={{ padding: '10px' }}>
                        <span><b>Style : </b></span>
                        <span style={{ fontFamily: 'Opensans-semibold' }}>{style ? style : '-'}</span>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={4} style={{ padding: '10px' }}>
                        <span><b>Model :</b></span>
                        <span style={{ fontFamily: 'Opensans-semibold' }}>{model ? model : '-'}</span>
                    </Grid>
                    <Grid item md={4} style={{ padding: '10px' }}>
                        <span><b>Size : </b></span>
                        <span style={{ fontFamily: 'Opensans-semibold' }}>{size ? size : '-'}</span>
                    </Grid>
                    <Grid item md={4}>
                    </Grid>
                </Grid>
            </Grid>
        )
    }


    return <>
        <Grid container>
            <Grid item md={(addedItems.length != 0) ? 9 : 12}>
                <Typography className={classes.title} variant="h5">
                    Search Criteria
                </Typography>
                <SearchCriteriaComponent SalesInfo={salesInfo} />
                {searchResultEnable ? <>  <Typography className={classes.title} variant="h5" style={{
                    "marginTop": "10px"
                }}>
                    Search Results
                </Typography>
                    <SearchResultGrid /></> : null}

            </Grid>
            {
                (addedItems.length != 0) ? <Grid item md={3} style={{
                    "paddingLeft": "10px"
                }}>
                    <Typography className={classes.title} variant="h5">
                        Added Items
                    </Typography>
                    <Card style={{ "padding": "8px", backgroundColor: "white", boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)', borderRadius: "16px" }}>
                        <CardContent style={{ "backgroundColor": "#2d7ffd", borderRadius: "16px" }}>
                            {
                                addedItems.map((value: any, index: any) => {
                                    return (
                                        <Grid container style={{ "color": "white", marginBottom: "15px" }} key={index}>
                                            <Grid item md={12}>
                                                <Grid container className={
                                                    classes.mb2
                                                }>
                                                    <Grid item md={9}>
                                                        <span>{value.brand}</span>
                                                        <span style={{ backgroundColor: 'white', marginLeft: '5px', borderRadius: '4px', padding: '0px 3px 1px 3px', cursor: 'pointer' }} onClick={() => { showImage(value.modelNumber) }} ><img style={{ width: '13px' }} src={Attachments} alt={"attachment image"} /></span>

                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <Typography>$ {(Number(value.weeklyRate) * Number(value.weeklyTerm) * Number(value.quantity)).toFixed(2)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Grid container className={
                                                    classes.mb2
                                                }>
                                                    <Grid item md={12}>
                                                        <Typography>Item # : {value.modelNumber}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Grid container>
                                                    <Grid item md={8} style={{ display: 'flex' }} className={
                                                        classes.mb2
                                                    }>
                                                        <span style={{ fontFamily: 'Opensans-bold' }}>Quantity</span>
                                                        <InfoIcon style={{ padding: '4px', cursor: 'pointer' }} title='The selected quantity of tires represents an individual item rather than a set.' />

                                                    </Grid>
                                                    <Grid item md={4}>
                                                        <Grid container style={{ backgroundColor: 'white', 'color': "#2d7ffd", padding: '5px 5px 5px 10px', borderRadius: '5px' }}>
                                                            <Grid item md={4}>
                                                                <Minus onClick={() => {
                                                                    let addedItemSpread = [...addedItems];
                                                                    for (let values of addedItemSpread) {
                                                                        let quaNum: any = Number(values.quantity);
                                                                        if (values.modelNumber == value.modelNumber && quaNum > 1) {
                                                                            values.quantity = quaNum - 1;
                                                                        }
                                                                    }
                                                                    setAddedItems(addedItemSpread);
                                                                }} style={{
                                                                    "cursor": "pointer"
                                                                }} />
                                                            </Grid>
                                                            <Grid item md={4} style={{ fontFamily: 'Opensans-bold' }}>

                                                                <Typography>{value.quantity}</Typography>

                                                            </Grid>
                                                            <Grid item md={4} style={{ marginTop: '4px' }}>
                                                                <Plus onClick={() => {

                                                                    let addedItemSpread = [...addedItems];
                                                                    for (let values of addedItemSpread) {
                                                                        let quaNum: any = Number(values.quantity);
                                                                        if (values.modelNumber == value.modelNumber && quaNum < 6) {
                                                                            values.quantity = quaNum + 1;
                                                                        }
                                                                        else if (values.modelNumber == value.modelNumber) {
                                                                            setmaxQtyReached(true)
                                                                        }
                                                                    }
                                                                    setAddedItems(addedItemSpread);
                                                                }} style={{
                                                                    "cursor": "pointer"
                                                                }} />
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Grid container className={
                                                    classes.mb2
                                                }>
                                                    {(!value.viewMore) ? <Grid item md={12}>
                                                        <Typography >
                                                            <span onClick={() => {
                                                                const updatedItems = addedItems.map(item => {
                                                                    if (item.modelNumber === value.modelNumber) {
                                                                        return { ...item, viewMore: true };
                                                                    } else {
                                                                        return { ...item, viewMore: false };
                                                                    }
                                                                });
                                                                setAddedItems(updatedItems);
                                                            }}>View More</span></Typography>
                                                    </Grid> : <>
                                                        <Grid item md={8}>
                                                            <Typography> <span onClick={() => {
                                                                const updatedItems = addedItems.map(item => {
                                                                    if (item.modelNumber === value.modelNumber) {
                                                                        return { ...item, viewMore: false };
                                                                    }
                                                                    return item;
                                                                });
                                                                setAddedItems(updatedItems);
                                                            }}>View Less</span></Typography>
                                                        </Grid>
                                                        <Grid item md={4} className={
                                                            classes.textRight
                                                        }>
                                                            <Typography>
                                                                <span onClick={() => {
                                                                    const updatedItems = addedItems.filter(item => item.modelNumber !== value.modelNumber);
                                                                    setAddedItems(updatedItems)
                                                                    const removedItems = addedItems.filter(item => item.modelNumber == value.modelNumber);
                                                                    setItemSearchTableContent([...itemSearchTableContent, ...removedItems])
                                                                }}>
                                                                    <Delete />
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    </>}
                                                </Grid>
                                            </Grid>
                                            {
                                                (value.viewMore) ? <Grid item md={12}>
                                                    <Grid container className={
                                                        classes.mb2
                                                    }>
                                                        <Grid item md={4}>
                                                            <Typography>Tag rate</Typography>
                                                            <Typography>$ {Number(value.weeklyRate).toFixed(2)}</Typography>
                                                        </Grid>
                                                        <Grid item md={4}>
                                                            <Typography>Tag term</Typography>
                                                            <Typography>{value.weeklyTerm}</Typography>
                                                        </Grid>
                                                        <Grid item md={4}>
                                                            <Typography>Tag total</Typography>
                                                            <Typography>$ {Number(Number(value.weeklyRate) * Number(value.weeklyTerm)).toFixed(2)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container className={
                                                        classes.mb2
                                                    }>
                                                        <Grid item md={4}>
                                                            <Typography>Agr Rate</Typography>
                                                            <Typography>$ {Number(Number(value.weeklyRate) * Number(value.quantity)).toFixed(2)}</Typography>
                                                        </Grid>
                                                        <Grid item md={4}>
                                                            <Typography>Agr Term</Typography>
                                                            <Typography>{Number(value.weeklyTerm)}</Typography>
                                                        </Grid>
                                                        <Grid item md={4}>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> : null
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </CardContent>
                        <Grid container className={
                            classes.p2
                        }>
                            <Grid item md={12} className={
                                classes.mb4
                            }>
                                <Typography variant='h5' >
                                    Agreement Total
                                </Typography>
                            </Grid>
                            <Grid item md={12}>
                                <Grid container className={
                                    classes.mb4
                                }>
                                    <Grid item md={6}>
                                        <Typography variant='h6'>Tag Rate</Typography>
                                    </Grid>
                                    <Grid item md={6} style={{
                                        "textAlign": "end"
                                    }}>
                                        <Typography variant='h6'>$ {tagRate}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container className={
                                    classes.mb4
                                }>
                                    <Grid item md={6}>
                                        <Typography variant='h6'>Tag Term</Typography>
                                    </Grid>
                                    <Grid item md={6} style={{
                                        "textAlign": "end"
                                    }}>
                                        <Typography variant='h6'>{tagTerm}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container className={
                                    classes.mb4
                                }>
                                    <Grid item md={6}>
                                        <Typography variant='h6'>Tag Total</Typography>
                                    </Grid>
                                    <Grid item md={6} style={{
                                        "textAlign": "end"
                                    }}>
                                        <Typography variant='h6'>$ {tagTagTotal}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container className={
                                    classes.mb4
                                }>
                                    <Grid item md={6}>
                                        <Typography variant='h6'>Agreement Rate</Typography>
                                    </Grid>
                                    <Grid item md={6} className={classes.inputTextField} >
                                        <RACTextbox
                                            isCurrency={true}
                                            disabled={true}
                                            value={agreementRate}

                                            // dollarTextClassName={classes.textbox}
                                            className={classes.textbox}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container className={
                                    classes.mb5
                                }>
                                    <Grid item md={6}>
                                        <Typography variant='h6'>Agreement Term</Typography>
                                    </Grid>
                                    <Grid item md={6} style={{
                                        "textAlign": "end"
                                    }}>
                                        <RACTextbox

                                            disabled={true}
                                            value={agreementTerm} />

                                    </Grid>
                                </Grid>
                                <Grid container style={{
                                    "backgroundColor": "#eff4ff",
                                    "color": "#3d89fd"
                                }} className={
                                    classes.py3 + ' ' + classes.px3 + ' ' + classes.mb5
                                }>
                                    <Grid item md={12}>
                                        <Grid container >
                                            <Grid item md={6}>
                                                <Typography variant='h6'>Same as cash Total</Typography>
                                            </Grid>
                                            <Grid item md={6} style={{
                                                "textAlign": "end"
                                            }}>
                                                <Typography variant='h6' className={
                                                    classes.fontBold
                                                }>$ {totalCashPrice}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container className={
                                            classes.mt3
                                        }>
                                            <Grid item md={6}>
                                                <Typography variant='h6'>Agreement Total</Typography>
                                            </Grid>
                                            <Grid item md={6} style={{
                                                "textAlign": "end"
                                            }}>
                                                <Typography variant='h6' className={
                                                    classes.fontBold
                                                }>${agreementTotal}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>
                    </Card>
                </Grid> : null
            }
        </Grid>
        {/*Something wentwrong popup*/}
        <RACModalCard
            isOpen={is500PopupOpened}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={"15px"}
            onClose={() => {
                setIs500PopupOpened(false)
            }}
        >
            {status500Popup()}
        </RACModalCard>
        {/*Something wenterong pop  */}
        <RACModalCard
            isOpen={isImagePopupOpened}
            closeIcon={true}
            maxWidth="sm"
            title='Product Image'
            borderRadius={"15px"}
            onClose={() => {
                setIsImagePopupOpened(false)
            }}
        >
            {imageModel()}
        </RACModalCard>
        {/*Something wentwrong popup*/}
        <RACModalCard
            isOpen={is400PopupOpened}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={"15px"}
            onClose={() => {
                setIs400PopupOpened(false)
            }}
        >
            {status400Popup()}
        </RACModalCard>
        {/*Something wenterong pop  */}

        {/*Something wentwrong popup*/}
        <RACModalCard
            isOpen={isToggleDisableAlert}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={"15px"}
            onClose={() => {
                setIsToggleDisableAlert(false)
            }}
        >
            {toggleDisableAlert()}
        </RACModalCard>
        {/*Something wenterong pop  */}
        {/*Something wentwrong popup*/}

        <RACModalCard
            isOpen={isNextPopupOpened}
            closeIcon={true}
            maxWidth="xs"
            borderRadius={"15px"}
            onClose={() => {
                setIsNextPopupOpened(false)
            }}
        >
            {nextButtonPopup()}
        </RACModalCard>
        {/*Something wenterong pop  */}



        <RACModalCard
            isOpen={maxQtyReached}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={"15px"}
            onClose={() => {
                setmaxQtyReached(false)
            }}
        >
            {maxQtyReachedpopupFn()}
        </RACModalCard>

        <RACModalCard
            isOpen={differentModalPopup}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={"15px"}
            onClose={() => {
                setDifferentModalPopup(false)
            }}
        >
            {differentModalPopupFn()}
        </RACModalCard>
    </>
}