/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/rules-of-hooks */
import { makeStyles } from '@rentacenter/racstrap';
export const viewDelivery = () => {
  const useClasses = makeStyles(() => ({
    racFormLabel: {
      marginBottom: '0.2rem',
      color: '#111111',
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
    },
    DetailsPadding: {
      marginBottom: '7px',
    },
    formcheckinline: {
      display: 'inline-block',
      marginRight: '1rem',
    },
    widthadjust16: {
      width: '16.66%',
      float: 'left',
    },
    HeaderPadding: {
      marginTop: '4px',
    },
    LabelPadding: {
      marginTop: '8px',
      fontFamily: 'OpenSans-regular',
      fontSize: '0.875rem',
    },
    appointmentstyles: {
      color: '#727272',
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
      marginTop: '8px',
    },
    deliveryLabelStyle: {
      fontFamily: 'OpenSans-semibold',
      color: '#000',
      fontSize: '14px',
    },
  }));
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = useClasses();
  return classes;
};
