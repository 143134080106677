/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable */


import React, { useState, useEffect, useContext } from 'react';
import {
  RACTextbox,
  RACButton,
  Grid,
  Card,
  CardContent,
  Typography,
  RACToggle
} from '@rentacenter/racstrap';
import { searchCriteriaStyles } from '../../../JSstyles/searchCriteriaStyles';
import { SearchInventoryObj } from './itemSearchProps';
import { CustomerOrderAgreementContext } from '../../../context/CustomerOrderAgreementContext';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-btn.svg';
import { useParams } from 'react-router';
import { FeatureFlagContext } from '../../../context/FeatureFlagContext';
export type Componentprops = {
  inventoryId?: number;
  searchClick: (txtValue: SearchInventoryObj['itemObject']) => void;
  IsPackage?: boolean;
  throwStyle: any;
  modelNumber?: any;
};
interface Params {
  agreementId: string;
}
export default function SearchFilter(props: Componentprops) {
  const classes = props.throwStyle; //This will hold the global styles
  const { featureFlagDetails } = useContext(FeatureFlagContext);

  const searchCriteriaClassName = searchCriteriaStyles(); //This will hold the component specific styles
  const {
    searchCriteria,
    setsearchCriteria,
    searchResult,
    agreementCreateStatus,
    toggleTB,

    setToggleTB
  } = useContext(CustomerOrderAgreementContext);
  const [controlViewMode, setcontrolViewMode] = useState(false);

  const initialTxtValues: SearchInventoryObj['itemObject'] = {
    model: '',
  };

  const [TxtValues, setTxtValues] =
    useState<SearchInventoryObj['itemObject']>(initialTxtValues);
  const [searchbtndisable, setsearchbtndisable] = useState(true);
  const { agreementId } = useParams<Params>();
  const [clrbtndisable, setclrbtndisable] = useState(false);

  useEffect(() => {
    if (TxtValues?.model?.length > 2) {
      setsearchbtndisable(false);
      setclrbtndisable(true);
    } else {
      setsearchbtndisable(true);
      setclrbtndisable(false);
    }
    console.log('TxtValues is updated as', TxtValues);
  }, [TxtValues]);

  useEffect(() => {
    console.log('searchCriteria in use effect data', searchCriteria);
    console.log('agreementCreateStatus in binding', agreementCreateStatus);

    if (searchCriteria !== undefined && agreementCreateStatus !== undefined) {
      if (agreementCreateStatus.agreementCreatedStatus === false) {
        console.log('fewkbv2554455');
        setTxtValues({
          ...TxtValues,
          model: searchCriteria.model,
        });
      } else {
        setcontrolViewMode(true);
      }
    }
  }, []);

  useEffect(() => {
    if (searchResult?.length > 0) {
      setTxtValues({
        ...TxtValues,
        model: searchResult[0].modelNumber,
      });
    }
  }, [searchResult]);
  useEffect(() => {
    if (props?.modelNumber) {
      setTxtValues({
        ...TxtValues,
        model: props.modelNumber,
      });
    }
  }, [props?.modelNumber]);

  const dynamicViewClass = controlViewMode ? classes.disableme : 'enableme';

  const EnterOnClick = (e: any) => {
    if (e.key == 'Enter' && TxtValues?.model?.length > 2) {
      SearchOnclick();
      setsearchCriteria(TxtValues);
    }
  };

  const SearchOnclick = () => {
    (document.getElementById('a11y_Model') as HTMLInputElement).blur();
    props.searchClick(TxtValues);
    console.log(TxtValues);
    setsearchCriteria(TxtValues);
  };

  const handleOnChangeTxtbox = (e: any) => {
    const FieldName = e.target.name;
    const InputValue = e.target.value.trim();
    setTxtValues({ ...TxtValues, [FieldName]: InputValue });
  };

  return (
    <div className={dynamicViewClass}>
      <Grid container className={classes.mt2}>
        <Grid item md={12}>
          <Typography className={classes.title} variant="h5">
            Search Criteria
          </Typography>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Model #"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Model"
                    name="model"
                    maxlength={50}
                    disabled={agreementId ? true : false}
                    isCurrency={false}
                    value={TxtValues.model}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={2}>
                  <Grid className={`${classes.floatRight} ${classes.mt3}`}>
                    <RACButton
                      startIcon={<SearchIcon />}
                      variant="contained"
                      color="primary"
                      className={searchCriteriaClassName.searchBtn}
                      size="large"
                      onClick={() => SearchOnclick()}
                      disabled={agreementId ? true : searchbtndisable}
                    >
                      {/* <SearchIcon className={classes.me1} /> */}
                      Search
                    </RACButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                {featureFlagDetails?.TireBuyer == '1' ? <div style={{ display: 'flex', marginTop: '10px' }}>
                  <RACToggle
                    OnChange={() => setToggleTB(!toggleTB)}
                    checked={toggleTB}
                    toggleColor={"dodgerblue"}
                  />
                  <span style={{ marginLeft: '10px', marginTop: '5px' }}><b>Tire Product</b></span>
                </div> : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
