/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */

import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  RACBadge,
  CircularProgress,
} from '@rentacenter/racstrap';
import { getAgreementInfo } from '../../../api/user';
import GridPage from './Grid';
import POGrid from './POGrid';
import POFooter from './POFooter';
import RePrintReport from '../../Rental/View Agreement/RePrintReport';
import { useParams } from 'react-router-dom';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { agreementInfoStyles } from '../../../JSstyles/agreementInfoStyles';
import { AgreementContext } from '../../../context/AgreementContext';
import { SetPendingCOAgreementValue } from '../../../app/Routes';
import { CustomerOrderAgreementContext } from '../../../context/CustomerOrderAgreementContext';
import InstallationDetails from './InstallationDetails';
interface Params {
  agreementId: string;
  customerId: string;
}
const POStatus = (props: any) => {
  const classes = agreementGlobalStyles();
  const agrInfoClassName = agreementInfoStyles();
  const [controlLoader, setcontrolLoader] = useState(false);
  const { agreementId, customerId } = useParams<Params>();
  const { viewAgrData, setIsAllDone, setAgrStoreNum } = useContext(AgreementContext);
  const [openRePrint, setopenRePrint] = useState(false);
  const { setwholeAgrInfoResponse, setsearchResult, setagreementCreateStatus } =
    useContext(CustomerOrderAgreementContext);

  const printPayLoad = {
    agreementId: agreementId,
    customerId: customerId,
  };
  const [itemReceived, setitemReceived] = useState(true);
  const [Status, setStatus] = useState('');
  const [isPrelimPaid, setisPrelimPaid] = useState(false);
  const [inventoryItemDetailsAF, setinventoryItemDetailsAF] = useState();
  const [agreementDetailsAF, setagreementDetailsAF] = useState<any>([]);
  const [agreementTotalAF, setagreementTotalAF] = useState<any>([]);
  const [ItemInformation, setItemInformation] = useState<any>([]);
  const [AgreementInformation, setAgreementInformation] = useState<any>([]);
  const [AgrStatusRefCode, setAgrStatusRefCode] = useState('');
  const [AgreementInfoHead, setAgreementInfoHead]: any = useState();
  const [POItemReceived, setPOItemReceived] = useState('');
  const inventoryItemDetailsBFObject = [
    { displayname: 'Model #', key: 'modelNumber' },
    { displayname: 'Item Description', key: 'description' },
    { displayname: 'Department', key: 'department' },
    { displayname: 'Sub Department', key: 'subDepartment' },
    { displayname: 'Quanity', key: 'quantity' },
    { displayname: 'Item PO Receive', key: 'itemPOReceive' },
    { key: 'checkBoxRequired' },
    { gridInfo: 'inventoryItemDetailsBFObject' },
  ];
  const agreementDetailsBFObject = [
    { displayname: 'Service Store', key: 'serviceStore' },
    { displayname: 'Promo Description', key: 'promoDescription' },
    { displayname: 'Cash Price', key: 'cashPrice' },
    { displayname: 'Agr Rate', key: 'agrRate' },
    { displayname: 'Agr Term', key: 'agrTerm' },
    { displayname: 'Estimated Total', key: 'estimatedTotal' },
    { gridInfo: 'agreementDetailsBFObject' },
  ];

  const inventoryItemDetailsAFObject = [
    { displayname: 'Item #', key: 'inventoryNumber' },
    { displayname: 'Item Description', key: 'description' },
    { displayname: 'Department', key: 'department' },
    { displayname: 'Sub Department', key: 'subDepartment' },
    { displayname: 'Tag Rate', key: 'tagRate' },
    { displayname: 'Tag Term', key: 'tagTerm' },
    { displayname: 'Tag Total', key: 'tagTotal' },
    { displayname: 'Inv Depr%', key: 'inventoryDepreciation' },
    { gridInfo: 'inventoryItemDetailsAFObject' },
  ];

  const agreementTotalAFObject = [
    { displayname: 'Tag Rate', key: 'tagRate' },
    { displayname: 'Tag Term', key: 'tagTerm' },
    { displayname: 'Tag Total', key: 'tagTotal' },
    { displayname: 'Agr Rate', key: 'agreementRate' },
    { displayname: 'Agr Term', key: 'agreementTerm' },
    { displayname: 'Agr Total', key: 'agreementTotal' },
    { displayname: 'Inv Depr%', key: 'agreementInventoryDepreciation' },
    {
      customiseTable: `${agrInfoClassName.racAgrTotalGrid} ${agrInfoClassName.racGrid} ${agrInfoClassName.racAgrViewGrid}`,
    },
    { gridInfo: 'agreementTotalAFObject' },
  ];

  const agreementDetailsAFObject = [
    { displayname: 'Agreement Source', key: 'agreementSource' },
    { displayname: 'Schedule', key: 'paymentSchedule' },
    { displayname: 'Delivery Charge', key: 'deliveryCharge' },
    { displayname: 'Due Date', key: 'dueDate' },
    { displayname: 'Days Extension', key: 'daysExtension' },
    { displayname: 'Promo Code', key: 'promoCode' },
    { displayname: 'Opt Services', key: 'optServices' },
    { displayname: 'Sales Person', key: 'salesPerson' },
    { gridInfo: 'agreementDetailsAFObject' },
  ];
  const [tbCustomerOrderFlow, setTBCustomerOrderFlow] = useState(false);
  const [installationDetailsObj, setInstallationDetailsObj] = useState({
    installerName: '',
    phoneNumber: '',
    emailId: '',
    address: '',
    scheduledDate: '',
    time: '',
    status: '',
    customerName: '',
    customerPhoneNumber: '',
    poNumber: '',
    tireOrderDescripton: ''
  });

  useEffect(() => {
    const PageLoad = async () => {
      setcontrolLoader(true);
      const agreementInfoResult = await getAgreementInfo(agreementId);
      console.log('agreementInfoResult', agreementInfoResult);

      setcontrolLoader(false);
      const result = agreementInfoResult.data;
      const FormatResponse = SetPendingCOAgreementValue(result);
      console.log('FormatResponsehbjbjb', FormatResponse);
      setAgrStoreNum(result?.agreementInformation?.storeNumber);
      const rmsItemInformation = result?.agreementInventories.map((el: any) => {
        return {
          modelNumber: el.modelNumber,
          brand: el.brand,
          cashPrice: result?.totalPayment.cashPrice,
          department: el.department,
          subdepartment: el.subDepartment,
          monthlyRate: el.agreementRate,
          monthlyTerm: el.agreementTerm,
          totalCost: el.agreementTotal,
          availability: null,
        };
      });
      const rmsItemObj = {
        rmsItemInformation: rmsItemInformation,
      };
      setagreementCreateStatus({ agreementCreatedStatus: true });
      setIsAllDone(true);
      setsearchResult(rmsItemObj.rmsItemInformation);
      setwholeAgrInfoResponse(FormatResponse);
      setisPrelimPaid(
        ['PRELIMPAID'].includes(
          result.agreementInformation.agreementStatusRefCode
        )
      );
      setitemReceived(
        ['PRELIMINV'].includes(
          result.agreementInformation.agreementStatusRefCode
        )
      );
      setStatus(result.agreementInformation.agreementStatus);
      setPOItemReceived(result.agreementWorkflow.isPurchaseOrderReceived);
      setAgrStatusRefCode(result.agreementInformation.agreementStatusRefCode);
      const agrresult = result.agreementInventories.some(
        (value: any) => value.tagRate == undefined
      );
      console.log('agrresult', agrresult);
      if (agrresult) {
        const ResultInv = result.agreementInventories.map((value) => {
          return {
            agreementRate: value.agreementRate,
            agreementTerm: value.agreementTerm,
            agreementTotal: value.agreementTotal,
            brand: value.brand,
            department: value.department,
            description: value.description,
            inventoryId: value.inventoryId,
            inventoryNumber: value.inventoryNumber,
            modelNumber: value.modelNumber,
            subDepartment: value.subDepartment,
            quantity: value?.quanity,
            tagRate: result.agreementTotal.tagRate,
            tagTerm: result.agreementTotal.tagTerm,
            tagTotal: result.agreementTotal.tagTotal,
            inventoryDepreciation:
              result.agreementTotal.agreementInventoryDepreciation,
          };
        });
        setinventoryItemDetailsAF(ResultInv);
        setItemInformation(ResultInv);
      } else {
        setinventoryItemDetailsAF(result.agreementInventories);
        setItemInformation(result.agreementInventories);
      }
      // setinventoryItemDetailsAF(result.agreementInventories);
      setagreementDetailsAF([result.agreementInformation]);
      setagreementTotalAF([result.agreementTotal]);
      const agreementDetails = [
        {
          serviceStore: result.agreementInformation.storeNumber,
          promoDescription: result.agreementInformation.promoName,
          cashPrice: result.totalPayment.cashPrice,
          agrRate: result.agreementTotal.agreementRate,
          agrTerm: result.agreementTotal.agreementTerm,
          estimatedTotal: result.agreementTotal.agreementTotal,
        },
      ];
      setAgreementInformation(agreementDetails);
      setAgreementInfoHead(result.agreementInformation);
      if (result?.installerInformation) {
        setTBCustomerOrderFlow(true);
        setInstallationDetailsObj({
          installerName: result?.installerInformation?.installerName,
          phoneNumber: result?.installerInformation?.phoneNumber,
          emailId: result?.installerInformation?.emailAddress,
          address: `${result?.installerInformation?.addressInfo?.addressLine1},${result?.installerInformation?.addressInfo?.postalCode},${result?.installerInformation?.addressInfo?.city}`,
          scheduledDate: result?.installerInformation?.installationDate,
          time: result?.installerInformation?.installationTime,
          status: result?.installerInformation?.installationStatusDesc,
          customerName: result?.agreementCustomer[0]?.firstName,
          customerPhoneNumber: result?.agreementCustomer[0]?.phoneNumbers ? getActivePhoneNumber(result?.agreementCustomer[0]?.phoneNumbers) ? getActivePhoneNumber(result?.agreementCustomer[0]?.phoneNumbers) : result?.agreementCustomer[0]?.phoneNumber : result?.agreementCustomer[0]?.phoneNumber,
          poNumber: result?.installerInformation?.purchaseOrderNumber,
          tireOrderDescripton: result?.installerInformation?.tireOrderStatusDesc
          ,
        })
      }
      //setwholeAgrInfoResponse(agreementInfoResult);
    };
    if (viewAgrData === undefined) {
      console.log('bvsdc451451ds');

      PageLoad();
    } else {
      console.log('iohkjbfbkfbakfjb', viewAgrData);
      const result = viewAgrData.data;
      setisPrelimPaid(
        ['PRELIMPAID'].includes(
          result.agreementInformation.agreementStatusRefCode
        )
      );
      setitemReceived(
        ['PRELIMINV'].includes(
          result.agreementInformation.agreementStatusRefCode
        )
      );
      setAgrStoreNum(result?.agreementInformation?.storeNumber);
      setStatus(result.agreementInformation.agreementStatus);
      setPOItemReceived(result.agreementWorkflow.isPurchaseOrderReceived);
      setAgrStatusRefCode(result.agreementInformation.agreementStatusRefCode);
      //setinventoryItemDetailsAF(result.agreementInventories);
      const agrresult = result.agreementInventories.some(
        (value: any) => value.tagRate == undefined
      );
      console.log('agrresult', agrresult);
      if (agrresult) {
        // eslint-disable-next-line sonarjs/no-identical-functions
        const ResultInv = result.agreementInventories.map((value) => {
          return {
            agreementRate: value.agreementRate,
            agreementTerm: value.agreementTerm,
            agreementTotal: value.agreementTotal,
            brand: value.brand,
            department: value.department,
            description: value.description,
            inventoryId: value.inventoryId,
            inventoryNumber: value.inventoryNumber,
            modelNumber: value.modelNumber,
            subDepartment: value.subDepartment,
            quantity: value?.quantity,
            tagRate: result.agreementTotal.tagRate,
            tagTerm: result.agreementTotal.tagTerm,
            tagTotal: result.agreementTotal.tagTotal,
            inventoryDepreciation:
              result.agreementTotal.agreementInventoryDepreciation,
          };
        });
        setinventoryItemDetailsAF(ResultInv);
        setItemInformation(ResultInv);
      } else {
        setinventoryItemDetailsAF(result.agreementInventories);
        setItemInformation(result.agreementInventories);
      }
      setagreementDetailsAF([result.agreementInformation]);
      setagreementTotalAF([result.agreementTotal]);
      const agreementDetails = [
        {
          serviceStore: result.agreementInformation.storeNumber,
          promoDescription: result.agreementInformation.promoName,
          cashPrice: result.totalPayment.cashPrice,
          agrRate: result.agreementTotal.agreementRate,
          agrTerm: result.agreementTotal.agreementTerm,
          estimatedTotal: result.agreementTotal.agreementTotal,
        },
      ];
      setAgreementInformation(agreementDetails);
      setAgreementInfoHead(result.agreementInformation);
      if (result?.installerInformation) {
        setTBCustomerOrderFlow(true);
        setInstallationDetailsObj({
          installerName: result?.installerInformation?.installerName,
          phoneNumber: result?.installerInformation?.phoneNumber,
          emailId: result?.installerInformation?.emailAddress,
          address: `${result?.installerInformation?.addressInfo?.addressLine1},${result?.installerInformation?.addressInfo?.postalCode},${result?.installerInformation?.addressInfo?.city}`,
          scheduledDate: result?.installerInformation?.installationDate,
          time: result?.installerInformation?.installationTime,
          status: result?.installerInformation?.installationStatusDesc,
          customerName: result?.agreementCustomer[0]?.firstName,
          customerPhoneNumber: result?.agreementCustomer[0]?.phoneNumbers ? getActivePhoneNumber(result?.agreementCustomer[0]?.phoneNumbers) ? getActivePhoneNumber(result?.agreementCustomer[0]?.phoneNumbers) : result?.agreementCustomer[0]?.phoneNumber : result?.agreementCustomer[0]?.phoneNumber,
          poNumber: result?.installerInformation?.purchaseOrderNumber,
          tireOrderDescripton: result?.installerInformation?.tireOrderStatusDesc

        })
      }
    }
  }, []);

  useEffect(
    () => {
      if (
        props.setItemSearchCompleted &&
        props.setAgrDetailsCompleted &&
        props.setPreliminaryPaymentCompleted
      ) {
        props.setItemSearchCompleted(true);
        props.setAgrDetailsCompleted(true);
        props.setPreliminaryPaymentCompleted(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  function getActivePhoneNumber(phoneNumbers: any) {
    for (const phoneNumber of phoneNumbers) {
      switch (phoneNumber.phoneType) {
        case 'CELL':
          return phoneNumber.PhoneNumber;
        case 'HOME':
          return phoneNumber.PhoneNumber;
        case 'REF':
          return phoneNumber.PhoneNumber;
        case 'WORK':
          return phoneNumber.PhoneNumber;
        default:
          return phoneNumber.PhoneNumber

      }
    }
    return undefined;
  }
  const PayScheduleFunction = () => {
    if (
      AgreementInfoHead !== undefined &&
      AgreementInfoHead.paySchedule !== null &&
      AgreementInfoHead.payDay !== null &&
      AgreementInfoHead.paymentSchedule !== null
    ) {
      return (
        <Grid className={classes.widthAuto}>
          <Typography
            variant="caption"
            className={agrInfoClassName.agrInfoGeneral}
          >
            Pay Schedule :
          </Typography>{' '}
          {AgreementInfoHead !== undefined &&
            AgreementInfoHead.paySchedule == 'Monthly' &&
            AgreementInfoHead.payDay == null ? (
            <Typography
              variant="caption"
              className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
            >
              {' '}
              {AgreementInfoHead !== undefined
                ? AgreementInfoHead.paySchedule
                : null}{' '}
            </Typography>
          ) : (
            <Typography
              variant="caption"
              className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
            >
              {AgreementInfoHead.paySchedule} on {AgreementInfoHead.payDay}
            </Typography>
          )}
        </Grid>
      );
    } else {
      return (
        <Grid className={classes.widthAuto}>
          <Typography
            variant="caption"
            className={agrInfoClassName.agrInfoGeneral}
          >
            Pay Schedule :
          </Typography>{' '}
          {AgreementInfoHead !== undefined &&
            AgreementInfoHead.paymentSchedule !== null ? (
            <Typography
              variant="caption"
              className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
            >
              -
              {/* {' '}
              {AgreementInfoHead.paymentSchedule} */}
            </Typography>
          ) : null}
        </Grid>
      );
    }
  };

  return (
    <div>
      {controlLoader ? (
        // <Grid className={classes.masterLoader}>
        //   <Grid className={classes.Loader}>
        //     <CircularProgress />
        //   </Grid>
        // </Grid>
        <div style={{ margin: '20% 20% 20% 48%' }}>
          <CircularProgress size={30} />
        </div>
      ) : (
        <>
          {/*Agreement info start here*/}
          {!itemReceived && Status != 'Active' ? (
            <div className={classes.agreementPOContainerSpacing}>
              <Grid md={12} className={classes.mb2}>
                <Typography className={classes.title} variant="h6">
                  Inventory Item Details
                </Typography>
                <Card className={classes.card}>
                  <CardContent>
                    <POGrid
                      mapper={inventoryItemDetailsBFObject}
                      GridValues={ItemInformation}
                      throwStyles={classes}
                      POItemReceived={POItemReceived}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid md={12} className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography className={classes.title} variant="h6">
                      Agreement Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    {isPrelimPaid ? (
                      <RACBadge
                        backgroundColor="#01A9DB"
                        className={classes.p6}
                        color="#fff"
                        OnclickNeed={false}
                        TextValue="Preliminary Paid"
                        handleOnClick={(e) => console.log('hello')}
                      />
                    ) : (
                      <RACBadge
                        backgroundColor="#01A9DB"
                        className={classes.p6}
                        color="#fff"
                        OnclickNeed={false}
                        TextValue="Preliminary Refunded"
                        handleOnClick={(e) => console.log('hello')}
                      />
                    )}
                  </Grid>
                </Grid>
                <Card className={classes.card}>
                  <CardContent>
                    {installationDetailsObj?.poNumber ?
                      <Grid className={`${classes.widthAuto} ${classes.floatLeft} ${classes.racLightBlue} ${agrInfoClassName.agrBorderRadius} ${classes.px2} ${classes.py2}`}
                      >
                        <Grid
                          className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms3}`}
                        >
                          <Typography
                            variant="caption"
                            className={agrInfoClassName.agrInfoGeneral}
                          >
                            PO #:
                          </Typography>{' '}
                          <Typography
                            variant="caption"
                            className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
                          >
                            {installationDetailsObj?.poNumber}
                          </Typography>
                        </Grid>
                      </Grid> : null}
                    <POGrid
                      mapper={agreementDetailsBFObject}
                      SecondGridValues={AgreementInformation}
                      throwStyles={classes}
                      POItemReceived={POItemReceived}
                    />
                  </CardContent>
                </Card>
                {tbCustomerOrderFlow ?
                  <Grid md={12} className={classes.mb2}>
                    <InstallationDetails
                      InstallerName={installationDetailsObj.installerName}
                      phoneNumber={installationDetailsObj.phoneNumber}
                      emailId={installationDetailsObj.emailId}
                      address={installationDetailsObj.address}
                      scheduledDate={installationDetailsObj.scheduledDate}
                      time={installationDetailsObj.time}
                      status={installationDetailsObj.status}
                      tireOrderDescripton={installationDetailsObj.tireOrderDescripton}

                    />
                  </Grid>
                  : null}
              </Grid>
            </div>
          ) : (
            <div className={classes.agreementPOContainerSpacing}>
              <Grid md={12} className={classes.mb2}>
                <Typography className={classes.title} variant="h6">
                  Inventory Item Details
                </Typography>
                <Card className={classes.card}>
                  <CardContent>
                    <GridPage
                      mapper={inventoryItemDetailsAFObject}
                      GridValues={inventoryItemDetailsAF}
                      throwStyles={classes}
                    />
                  </CardContent>
                </Card>
              </Grid>

              <Grid md={12} className={classes.mb2}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography className={classes.title} variant="h6">
                      Agreement Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <RACBadge
                      className={classes.p6}
                      backgroundColor={
                        Status == 'Active' ? 'rgb(90, 226, 134)' : '#FDC400'
                      }
                      color="#fff"
                      OnclickNeed={false}
                      // TextValue={
                      //   Status == 'Active' ? 'Active' : 'Preliminary Inventory'
                      // }
                      TextValue={Status}
                      handleOnClick={(e) => console.log(e)}
                    />
                  </Grid>
                </Grid>
                <Card className={classes.card}>
                  <CardContent>
                    {installationDetailsObj?.poNumber ?
                      <Grid className={`${classes.widthAuto} ${classes.floatLeft} ${classes.racLightBlue} ${agrInfoClassName.agrBorderRadius} ${classes.px2} ${classes.py2}`}
                      >
                        <Grid
                          className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms3}`}
                        >
                          <Typography
                            variant="caption"
                            className={agrInfoClassName.agrInfoGeneral}
                          >
                            PO #:
                          </Typography>{' '}
                          <Typography
                            variant="caption"
                            className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
                          >
                            {installationDetailsObj?.poNumber}
                          </Typography>
                        </Grid>
                      </Grid> : null}

                    <Grid
                      className={`${classes.widthAuto} ${classes.floatLeft}`}
                    >
                      <Grid
                        className={`${classes.widthAuto} ${classes.floatLeft} ${classes.racLightBlue} ${agrInfoClassName.agrBorderRadius} ${classes.px2} ${classes.py2}`}
                      >
                        <Grid
                          className={`${classes.widthAuto} ${classes.floatLeft}`}
                        >
                          <Typography
                            variant="caption"
                            className={agrInfoClassName.agrInfoGeneral}
                          >
                            Agreement# :{' '}
                          </Typography>
                          <Typography
                            variant="caption"
                            className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
                          >
                            {AgreementInfoHead !== undefined
                              ? AgreementInfoHead.agreementNumber
                              : null}
                          </Typography>
                        </Grid>

                        <Grid
                          className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms3}`}
                        >
                          <Typography
                            variant="caption"
                            className={agrInfoClassName.agrInfoGeneral}
                          >
                            Agreement Type :{' '}
                          </Typography>
                          <Typography
                            variant="caption"
                            className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
                          >
                            {AgreementInfoHead !== undefined
                              ? AgreementInfoHead.agreementType
                              : null}
                          </Typography>
                        </Grid>

                        <Grid
                          className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms3}`}
                        >
                          {PayScheduleFunction()}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms2}`}
                    >
                      <Grid
                        className={`${classes.widthAuto} ${classes.floatLeft} ${classes.racLightBlue} ${agrInfoClassName.agrBorderRadius} ${classes.px2} ${classes.py2}`}
                      >
                        <Grid
                          className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms2} ${classes.me2}`}
                        >
                          <Typography
                            variant="caption"
                            className={agrInfoClassName.agrInfoGeneral}
                          >
                            Original Term :
                          </Typography>{' '}
                          <Typography
                            variant="caption"
                            className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
                          >
                            {AgreementInfoHead !== undefined
                              ? AgreementInfoHead.orginalTerm
                              : null}
                          </Typography>
                        </Grid>

                        <Grid
                          className={`${classes.widthAuto} ${classes.floatLeft} ${classes.ms3}`}
                        >
                          <Typography
                            variant="caption"
                            className={agrInfoClassName.agrInfoGeneral}
                          >
                            Remaining Term :
                          </Typography>{' '}
                          <Typography
                            variant="caption"
                            className={`${agrInfoClassName.agrGenaralInfoColourLabel}`}
                          >
                            {AgreementInfoHead !== undefined
                              ? AgreementInfoHead.remainingTerm
                              : null}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <GridPage
                      mapper={agreementDetailsAFObject}
                      ThirdGridValues={agreementDetailsAF}
                      throwStyles={classes}
                    />
                  </CardContent>
                </Card>
              </Grid>

              <Grid md={12} className={classes.mb2}>
                <Typography className={classes.title} variant="h6">
                  Agreement Total
                </Typography>
                <Card className={classes.card}>
                  <CardContent>
                    <GridPage
                      mapper={agreementTotalAFObject}
                      SecondGridValues={agreementTotalAF}
                      throwStyles={classes}
                    />
                  </CardContent>
                </Card>
              </Grid>
              {tbCustomerOrderFlow ?
                <Grid md={12} className={classes.mb2}>
                  <InstallationDetails
                    InstallerName={installationDetailsObj.installerName}
                    phoneNumber={installationDetailsObj.phoneNumber}
                    emailId={installationDetailsObj.emailId}
                    address={installationDetailsObj.address}
                    scheduledDate={installationDetailsObj.scheduledDate}
                    time={installationDetailsObj.time}
                    status={installationDetailsObj.status}
                    tireOrderDescripton={installationDetailsObj.tireOrderDescripton}
                  />
                </Grid>
                : null}
            </div>
          )}
          <POFooter
            triggerOpenReprint={() => setopenRePrint(true)}
            throwNextController={itemReceived}
            AGRStatus={AgrStatusRefCode}
            tbCustomerOrderFlow={tbCustomerOrderFlow}
            installationDetailsObj={installationDetailsObj}
          />
          {openRePrint ? (
            <RePrintReport closeMethod={() => setopenRePrint(false)} />
          ) : null}
        </>
      )}
    </div>
  );
};

export default POStatus;
