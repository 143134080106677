/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { makeStyles } from "@rentacenter/racstrap";
export const fieldDeviceStyles = () => {
  const useClasses = makeStyles((theme) => ({
    [theme.breakpoints.down("md")]: {
      btnList: {
        "& button": {
          backgroundColor: "transparent !important",
          color: "#000 !important",
          padding: "0px !important",
          width: "100%",
          justifyContent: "left ! important",
          "&:focus": {
            boxShadow: "none !important",
          },
        },
      },
      flexMdCol: {
        flexDirection: "column",
      },
      breadCrumbMarg: {
        marginLeft: "0px",
      },
      breadcrumbItemActive: {
        paddingLeft: "0px",
      },

      agrTimeSlot: {
        width: "33.33% !important",
      },

      //margin styles starts
      mbMd3: {
        marginBottom: "16px",
      },
      mbMd4: {
        marginBottom: "24px",
      },
      mtMd4: {
        marginTop: "24px",
      },
      mtMd5: {
        marginTop: "48px !important",
      },
      mtMd0: {
        marginTop: "0px !important",
      },
      //margin styles ends

      //width properties starts
      wMd100: {
        width: "100% !important",
      },
      //width properties starts

      //flex properties starts
      flexMdCenter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      //flex properties ends

      //display styles starts
      dMdNone: {
        display: "none !important",
      },
      dMdBlock: {
        display: "block !important",
      },
      dMdFlex: {
        display: "flex !important",
      },
      //display styles ends
      breadCrumbContainer: {
        marginLeft: "16px",
        marginTop: "24px",
      },
      mbMd100: {
        marginBottom: "100px",
      },
    },
    [theme.breakpoints.between(800, 1281)]: {
      btnList: {
        "& button": {
          backgroundColor: "transparent !important",
          color: "#000 !important",
          padding: "0px !important",
          width: "100%",
          justifyContent: "left ! important",
          "&:focus": {
            boxShadow: "none !important",
          },
        },
      },
      //display styles starts
      dMdNone: {
        display: "none !important",
      },
      dMdBlock: {
        display: "block !important",
      },
      dMdFlex: {
        display: "flex !important",
      },
      //display styles ends
      widthadjust16: {
        width: "20% !important",
      },
      widthtab50: {
        width: "50% !important",
      },
      // table styles starts
      itemInfoTbl: {
        "& thead": {
          "& tr": {
            "& th": {
              "&:nth-last-child(-n+6)": {
                display: "none",
              },
            },
          },
        },
        "& tbody": {
          "& tr": {
            "& td": {
              "&:nth-last-child(-n+6)": {
                display: "none",
              },
            },
          },
        },
      },
      agrTotalTbl: {
        "& thead": {
          "& tr": {
            "& th": {
              "&:nth-last-child(-n+1)": {
                display: "none",
              },
              "&:nth-child(1)": {
                display: "none",
              },
              "&:nth-child(2)": {
                display: "none",
              },
              "&:nth-child(3)": {
                width: "100px",
              },
            },
          },
        },
        "& tbody": {
          "& tr": {
            "& td": {
              "&:nth-last-child(-n+1)": {
                display: "none",
              },
              "&:nth-child(1)": {
                display: "none",
              },
              "&:nth-child(2)": {
                display: "none",
              },
              "&:nth-child(3)": {
                width: "100px",
              },
            },
          },
        },
      },
      agrInfoTbl: {
        "& thead": {
          "& tr": {
            "& th": {
              "&:nth-child(3)": {
                display: "none",
              },
              "&:nth-child(9)": {
                display: "none",
              },
              "&:nth-child(8)": {
                display: "none",
              },
            },
          },
        },
        "& tbody": {
          "& tr": {
            "& td": {
              "&:nth-child(3)": {
                display: "none",
              },
              "&:nth-child(9)": {
                display: "none",
              },
              "&:nth-child(8)": {
                display: "none",
              },
            },
          },
        },
      },
      agrActivity: {
        "& thead": {
          "& tr": {
            "& th:nth-last-child(-n+1)": {
              display: "none",
            },
          },
        },
        "& tbody": {
          "& tr": {
            "& td:nth-last-child(-n+1)": {
              display: "none",
            },
          },
        },
      },
      // table styles ends
    },
    [theme.breakpoints.between(599, 801)]: {
      widthadjust16: {
        width: "33.33% !important",
      },
      widthtab50: {
        width: "50% !important",
      },
      itemInfoTbl: {
        "& thead": {
          "& tr": {
            "& th": {
              "&:nth-last-child(-n+8)": {
                display: "none",
              },
            },
          },
        },
        "& tbody": {
          "& tr": {
            "& td": {
              "&:nth-last-child(-n+8)": {
                display: "none",
              },
            },
          },
        },
      },
      agrTotalTbl: {
        "& thead": {
          "& tr": {
            "& th": {
              "&:nth-last-child(-n+1)": {
                display: "none",
              },
              "&:nth-child(1)": {
                display: "none",
              },
              "&:nth-child(2)": {
                display: "none",
              },
              "&:nth-child(3)": {
                display: "none",
              },
              "&:nth-child(4)": {
                width: "100px",
              },
            },
          },
        },
        "& tbody": {
          "& tr": {
            "& td": {
              "&:nth-last-child(-n+1)": {
                display: "none",
              },
              "&:nth-child(1)": {
                display: "none",
              },
              "&:nth-child(2)": {
                display: "none",
              },
              "&:nth-child(3)": {
                display: "none",
              },
              "&:nth-child(4)": {
                width: "100px",
              },
            },
          },
        },
      },
      agrInfoTbl: {
        "& thead": {
          "& tr": {
            "& th": {
              "&:nth-child(3)": {
                display: "none",
              },
              "&:nth-child(5)": {
                display: "none",
              },
              "&:nth-child(7)": {
                display: "none",
              },
              "&:nth-child(9)": {
                display: "none",
              },
              "&:nth-child(8)": {
                display: "none",
              },
            },
          },
        },
        "& tbody": {
          "& tr": {
            "& td": {
              "&:nth-child(3)": {
                display: "none",
              },
              "&:nth-child(5)": {
                display: "none",
              },
              "&:nth-child(7)": {
                display: "none",
              },
              "&:nth-child(9)": {
                display: "none",
              },
              "&:nth-child(8)": {
                display: "none",
              },
            },
          },
        },
      },
      agrActivity: {
        "& thead": {
          "& tr": {
            "& th": {
              "&:nth-last-child(-n+1)": {
                display: "none",
              },
              "&:nth-child(2)": {
                display: "none",
              },
            },
          },
        },
        "& tbody": {
          "& tr": {
            "& td": {
              "&:nth-last-child(-n+1)": {
                display: "none",
              },
              "&:nth-child(2)": {
                display: "none",
              },
            },
          },
        },
      },
      title: {
        fontSize: "14px !important",
      },

      tabFont12: {
        fontSize: "12px !important",
      },
      tabFont14: {
        fontSize: "14px !important",
      },
      tabFont16: {
        fontSize: "16px !important",
      },
      tabFont18: {
        fontSize: "18px !important",
      },
      ptab16: {
        padding: "16px !important",
      },
      mtTab0: {
        marginTop: `0px !important`,
      },
      dSmNone: {
        display: "none",
      },
    },
    positionRelative: {
      position: "relative",
    },
    positionAbsolute: {
      position: "absolute",
    },
    actionsList: {
      bottom: "60px",
      right: "0px",
      backgroundColor: `#fff`,
      width: "227px",
      borderRadius: "8px",
      boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
      border: "1px solid #EAF0F4",
      "& li:hover": {
        backgroundColor: `#DBE6FF`,
        "& a": {
          textDecoration: "none !important",
          display: "block",
          width: "100%",
          color: "#000",
        },
      },
    },
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useClasses();
};
