/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState, useContext } from 'react';
import CustomerInfo from '../Rental/CreateAgreement/CustomerInfo';
//import { agreementGlobalStyles } from '../../JSstyles/agreementGlobalStyles';
import { wizardStyles } from '../../JSstyles/wizardStyles';
import { Grid, List, ListItem, Box } from '@rentacenter/racstrap';
import { ReactComponent as CompletedIcon } from '../../assets/images/wizard-complete.svg';
// import { SwitchOutAgreementContext } from '../../../context/SwitchOutAgreementContext';
import { AgreementContext } from '../../context/AgreementContext';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { LoanerContext } from '../../context/LoanerAgreementContext';

export type CoCustomerDetails = {
  coCustomerId: string;
  coCustomerName: string;
};
export type WizardProps = {
  ItemSearchCompleted: boolean;
  AgreementDetailsCompleted: boolean;
  DeliveryMethodCompleted: boolean;
  CoCustomerDetails: CoCustomerDetails;
  dethrower: boolean;
};
interface Params {
  customerId: string;
  agreementId: string;
}
export default function LoanerWizard(props: WizardProps) {
  // const classes = agreementGlobalStyles();
  const wizardClassName = wizardStyles();
  const history = useHistory();
  const { customerId, agreementId } = useParams<Params>();
  // const { switchoutagreementnum } = useContext(SwitchOutAgreementContext);
  const { showProgress } = useContext(AgreementContext);
  const {
    ItemSearchCompleted,
    AgreementDetailsCompleted,
    DeliveryMethodCompleted,
    CoCustomerDetails,
    dethrower,
  } = props;
  const [CocustomerDetailsValue, setCoCustomerDetails] =
    useState<CoCustomerDetails>(CoCustomerDetails);

  const { parentinventoryNumber } = useContext(LoanerContext);

  const dynamicClasses: any = {
    complete: {
      anchor: wizardClassName.smartWizardListLinkActive,
      spanNum: `${wizardClassName.borderDefault} ${wizardClassName.done}`,
      spanDetail: wizardClassName.stepDone,
    },
    inprogress: {
      anchor: wizardClassName.smartWizardListLinkInActive,
      spanNum: wizardClassName.borderActive,
      spanDetail: wizardClassName.stepActive,
    },
    pending: {
      anchor: wizardClassName.smartWizardListLinkInActive,
      spanNum: wizardClassName.borderDefault,
      spanDetail: wizardClassName.stepNotStarted,
    },
  };

  const [itemSearchStatus, setItemSearchStatus] = useState('pending');
  const [agreementDetailStatus, setAgreementDetailStatus] = useState('pending');
  const [deliveryMethodStatus, setDeliveryMethodStatus] = useState('pending');

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      'Inside wizard outside',
      CoCustomerDetails,
      CocustomerDetailsValue
    );
    if (
      CoCustomerDetails !== undefined &&
      CoCustomerDetails.coCustomerId !== undefined &&
      CoCustomerDetails.coCustomerId !== null &&
      CoCustomerDetails.coCustomerId !== '' &&
      CoCustomerDetails.coCustomerName !== undefined &&
      CoCustomerDetails.coCustomerName !== null &&
      CoCustomerDetails.coCustomerName !== ''
    ) {
      setCoCustomerDetails(CoCustomerDetails);
    }

    if (ItemSearchCompleted === false && AgreementDetailsCompleted === false) {
      setItemSearchStatus('inprogress');
      setAgreementDetailStatus('pending');
    } else if (
      ItemSearchCompleted === true &&
      AgreementDetailsCompleted === false
    ) {
      setItemSearchStatus('complete');
      setAgreementDetailStatus('inprogress');
    } else if (
      ItemSearchCompleted === true &&
      AgreementDetailsCompleted === true
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    ) {
      setItemSearchStatus('complete');
      setAgreementDetailStatus('complete');
      setDeliveryMethodStatus('inprogress');
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ItemSearchCompleted,
    AgreementDetailsCompleted,
    DeliveryMethodCompleted,
    CoCustomerDetails,
  ]);
  const WizardFieldClick = (FieldType: any) => {
    if (FieldType == 'Itemsearch') {
      if (itemSearchStatus === 'complete') {
        history.push({
          pathname: `/agreement/loaner/itemsearch/${customerId}/${agreementId}`,
        });
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
      }
    } else if (FieldType == 'AgrDetails') {
      if (itemSearchStatus === 'complete') {
        history.push({
          pathname: `/agreement/loaner/details/${customerId}/${agreementId}/${parentinventoryNumber}`,
        });
      }
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (FieldType == 'DeliveryMethod') {
      if (agreementDetailStatus === 'complete') {
        history.push({
          pathname: `/agreement/loaner/delivery/${customerId}/${agreementId}`,
        });
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
      }
    }
  };
  return (
    //Wizard starts from here
    <div data-testid="WizardTest">
      <Grid className={wizardClassName.swThemeDefault}>
        <Grid item className={wizardClassName.swThemeDefaultContainer}>
          <List className={wizardClassName.smartWizard}>
            <ListItem
              className={wizardClassName.smartWizardList}
              onClick={() => WizardFieldClick('Itemsearch')}
            >
              <a className={dynamicClasses[itemSearchStatus]['anchor']}>
                {itemSearchStatus === 'complete' ? (
                  <Box
                    component="span"
                    className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                  >
                    <CompletedIcon className={wizardClassName.paddingTop3} />
                  </Box>
                ) : (
                  <span className={dynamicClasses[itemSearchStatus]['spanNum']}>
                    1
                  </span>
                )}

                <span
                  className={dynamicClasses[itemSearchStatus]['spanDetail']}
                >
                  Item Search
                </span>
              </a>
            </ListItem>
            <ListItem
              className={wizardClassName.smartWizardList}
              onClick={() => WizardFieldClick('AgrDetails')}
            >
              <a className={dynamicClasses[agreementDetailStatus]['anchor']}>
                {agreementDetailStatus === 'complete' ? (
                  <Box
                    component="span"
                    className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                  >
                    <CompletedIcon className={wizardClassName.paddingTop3} />
                  </Box>
                ) : (
                  <span
                    className={dynamicClasses[agreementDetailStatus]['spanNum']}
                  >
                    2
                  </span>
                )}

                <span
                  className={
                    dynamicClasses[agreementDetailStatus]['spanDetail']
                  }
                  style={{ width: '100%' }}
                >
                  Agreement Details
                </span>
              </a>
            </ListItem>
            <ListItem
              className={wizardClassName.smartWizardList}
              onClick={() => WizardFieldClick('DeliveryMethod')}
            >
              <a className={dynamicClasses[deliveryMethodStatus]['anchor']}>
                {deliveryMethodStatus === 'complete' ? (
                  <Box
                    component="span"
                    className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                  >
                    <CompletedIcon className={wizardClassName.paddingTop3} />
                  </Box>
                ) : (
                  <span
                    className={dynamicClasses[deliveryMethodStatus]['spanNum']}
                  >
                    3
                  </span>
                )}

                <span
                  className={dynamicClasses[deliveryMethodStatus]['spanDetail']}
                >
                  Delivery Method
                </span>
              </a>
            </ListItem>
          </List>
          <CustomerInfo
            CoCustomerDetails={CocustomerDetailsValue}
            dereceiver={dethrower}
          />
        </Grid>
      </Grid>
    </div>
  );
}
