/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useEffect, useState, useContext } from 'react';
//import { GetCustomerinfo } from '../../Stub/Stub';
import {
  GetCustomer,
  GetCustomerId,
  getAgreementCustomerInfo,
  GetLegalHold,
  RunDE,
} from '../../../api/user';
//import CustomerInfoProps from './CustomerInfoInterface';
import { Link, useParams, useHistory } from 'react-router-dom';
import { AgreementContext } from '../../../context/AgreementContext';
import { ReactComponent as Alertimage } from '../../../assets/images/no-records-found.svg';
import {
  RACBadge,
  Grid,
  Typography,
  RACModalCard,
  RACButton,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { wizardStyles } from '../../../JSstyles/wizardStyles';
import { fieldDeviceStyles } from '../../../JSstyles/fieldDeviceStyles';

export default function ViewAgreementBreadcrumbs(props: any) {
  const history = useHistory();
  const { CoCustomerDetails, dereceiver, amountThrower } = props;
  const classes = agreementGlobalStyles();
  const wizStyle = wizardStyles();
  const classes1 = fieldDeviceStyles();
  //PS_AD-1 A object declared to store the customer information from database
  const CustomerInfo = {
    CustomerName: '',
    CustomerID: '',
    PhoneNumber: '',
    DEstatus: '',
    ExpiryDate: '',
    CoCustomerName: '',
    CoCustomerId: '',
    Skip: '',
    Stolen: '',
    Hard: '',
  };
  interface Params {
    agreementId: string;
    customerId: string;
  }
  const {
    CustomerInfoContext,
    viewAgreementData,
    viewAgrData,
    setstoreExpiryDate,
    setentireGetCustomerData,
    approvalAmountHolder,
  } = useContext(AgreementContext);
  //PS_AD-1 A state variable declared to store the CustomerInfo object

  const [CustomerInformation, setCustomerInformation] = useState(CustomerInfo);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [CustomerValue, setCustomerValue] = CustomerInfoContext;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cocustomervalue, setcocustomervalue] = CustomerInfoContext;
  const { agreementId, customerId } = useParams<Params>();
  const [CoCustomerName, setCoCustomerName] = useState('');
  const [CoCustomerId, setCoCustomerId] = useState('');
  // const [CustomerDEstaus, setCustomerDEstaus] = useState('');
  // const [CustomerExpDate, setCustomerExpDate] = useState('');
  // const [ApprovalAmount, setApprovalAmount] = useState('');
  // const [WeeklyApprovalAmount, setWeeklyApprovalAmount] = useState('');
  // const [successpopup, Setsuccesspopup] = useState(false);
  // const [needDE, setneedDE] = useState(false);
  // const [IscustomerapiFailed, SetIscustomerapiFailed] = useState(false);
  // // const [entireGetCustomerData, setentireGetCustomerData] = useState<any>();
  // const [noDEReasonDD, setnoDEReasonDD] = useState<any>([]);
  // const [dropDownLoaded, setDropDownLoaded] = useState<boolean>(false);
  // const [postnodeReason, setpostnodeReason] = useState('');
  // const [reasonDEopen, setreasonDEopen] = React.useState(false);
  // const [isLegalHold, setIsLegalHold] = useState(false);
  // const [legalholderror, setlegalholderror] = useState(false);

  // const API_ERROR_MESSAGE = 'Unable to fetch the data';
  // const changeDEStatus = () => {
  //   console.log('Change De Statusfcsackjnsdkjv');

  // };
  // useEffect(() => {
  //   console.log('ngbfvdssreaafawesdvcdsx', dereceiver);
  //   //console.log('receibed cdhvbhd data amount---->', amountThrower);
  //   console.log(
  //     'approvalAmountHolder cdhvbhd data amount------>',
  //     approvalAmountHolder
  //   );

  //   if (dereceiver === true && approvalAmountHolder !== undefined) {
  //     if (approvalAmountHolder.data.approvalResponse == '1') {
  //       setCustomerDEstaus('A');
  //     } else if (approvalAmountHolder.data.approvalResponse == '2') {
  //       setCustomerDEstaus('D');
  //     } else if (
  //       approvalAmountHolder.data.approvalResponse == '4' ||
  //       approvalAmountHolder.data.approvalResponse == '5'
  //     ) {
  //       setCustomerDEstaus('M');
  //     } else if (approvalAmountHolder.data.approvalResponse == '') {
  //       setCustomerDEstaus('');
  //     }
  //     // setCustomerDEstaus('A');
  //     console.log(
  //       'approvalAmountHolder.data.originalApprovalAmount',
  //       approvalAmountHolder.data.approvalAmount
  //     );
  //     console.log(
  //       'approvalAmountHolder.data.weeklyOriginalApprovalAmount',
  //       approvalAmountHolder.data.weeklyApprovalAmount
  //     );

  //     const RawExpDateArr =
  //       approvalAmountHolder.data.approvalExpirationDate !== ''
  //         ? approvalAmountHolder.data.approvalExpirationDate.split('-')
  //         : '';
  //     console.log('RawExpDateArr checker log', RawExpDateArr);
  //     setstoreExpiryDate(approvalAmountHolder.data.approvalExpirationDate);
  //     if (RawExpDateArr !== '') {
  //       const ExpDate =
  //         RawExpDateArr[1] + '/' + RawExpDateArr[2] + '/' + RawExpDateArr[0];
  //       console.log('ExpDate logger update log', ExpDate);

  //       setCustomerExpDate(ExpDate);
  //     }
  //     setApprovalAmount(
  //       Number(approvalAmountHolder.data.approvalAmount).toFixed(2)
  //     );
  //     setWeeklyApprovalAmount(
  //       Number(approvalAmountHolder.data.weeklyApprovalAmount).toFixed(2)
  //     );
  //   }
  // }, [dereceiver]);

  useEffect(() => {
    if (
      CoCustomerDetails !== undefined &&
      CoCustomerDetails.coCustomerId !== undefined &&
      CoCustomerDetails.coCustomerId !== null &&
      CoCustomerDetails.coCustomerId !== '' &&
      CoCustomerDetails.coCustomerName !== undefined &&
      CoCustomerDetails.coCustomerName !== null &&
      CoCustomerDetails.coCustomerName !== ''
    ) {
      // eslint-disable-next-line no-console
      console.log(
        'Inside Bind Name useeffect cocustomer details Name',
        CustomerInformation
      );
      console.log(
        'Inside Bind cocustomer context details Name',
        CustomerValue,
        CustomerInformation
      );
      setCustomerInformation({
        ...CustomerInformation,
        CoCustomerName: CoCustomerDetails.coCustomerName,
        CoCustomerId: CoCustomerDetails.coCustomerId,
      });
    }
  }, [CoCustomerDetails]);

  // const RediectToCustomer = () => {
  //   history.push({
  //     pathname: `/customer/searchcustomer`,
  //   });
  // };
  // const RediectToDashBoard = () => {
  //   history.push({
  //     pathname: `/dashboard/homepage`,
  //   });
  // };

  /* eslint-disable sonarjs/cognitive-complexity */
  useEffect(() => {
    console.log('Customer id in customer info', customerId);
    let skip: '';
    let stolen: '';
    let hard: '';
    const getCustomer_APIcall = async () => {
      const currentStore = sessionStorage.getItem('storeNumber');
      const urlParams = new URLSearchParams(window.location.search);
      const coCustomerIdParams = urlParams.get('coCustomerId');
      if (
        agreementId !== undefined &&
        agreementId !== null &&
        agreementId !== ''
      ) {
        let response;
        console.log('raw viewAgrData log in custinfo', viewAgrData);

        //console.log('viewAgrData.data to check', viewAgrData.data);

        if (viewAgrData == undefined) {
          //.data removed
          console.log('viewAgreementData is  undefined', viewAgrData);

          response = await getAgreementCustomerInfo(agreementId);
          console.log('response to be checked', response);

          //response = response.data;
          console.log('response to be checked drilled', response);
          if (
            response !== undefined &&
            response.data !== undefined &&
            response.data.customer !== undefined &&
            response.data.customer.length > 0
          ) {
            response = response.data;
            const CustomerObj = {
              GlobalCustomerID: response.customer[0].gcdid,
              CustomerName: response.customer[0].customerName,
              CustomerId: response.customer[0].customerId,
              CusotmerPhoneNumber: response.customer[0].phoneNumber,
              CustomerEmail: response.customer[0].emailAddress,
              CoCustomerName: '',
              CoCustomerId: '', // Added
            };
            setCustomerInformation({
              ...CustomerInformation,
              CustomerName: response.customer[0].customerName,
              CustomerID: response.customer[0].customerId,
              PhoneNumber: response.customer[0].phoneNumber,
            });
            console.log('vdvf kjnjenv 644');
            setCustomerValue(CustomerObj);
            if (
              response !== undefined &&
              response.coCustomer !== undefined &&
              response.coCustomer.length > 0
            ) {
              setCustomerInformation({
                ...CustomerInformation,
                CustomerName: response.customer[0].customerName,
                CustomerID: response.customer[0].customerId,
                PhoneNumber: response.customer[0].phoneNumber,
                CoCustomerName: response.coCustomer[0].customerName,
                CoCustomerId: response.coCustomer[0].customerId,
              });
              console.log('Customer Object before', CustomerObj);
              const CustomerObject = {
                GlobalCustomerID: CustomerObj.GlobalCustomerID,
                CustomerName: CustomerObj.CustomerName,
                CustomerId: CustomerObj.CustomerId,
                CusotmerPhoneNumber: CustomerObj.CusotmerPhoneNumber,
                // CustomerVehicles: CustomerObj.CustomerVehicles,
                CustomerEmail: CustomerObj.CustomerEmail,
                CoCustomerName: response.coCustomer[0].customerName,
                CoCustomerId: response.coCustomer[0].customerId,
              };
              console.log(
                'Customer Object after',
                CustomerObj,
                CustomerObject,
                CustomerValue,
                CustomerInfoContext
              );
              setCoCustomerName(response.coCustomer[0].customerName);
              console.log('cdv5v45vdf5v');

              setcocustomervalue(CoCustomerId);
              setCustomerValue(CustomerObject);
            }

            // const getCustomerApprovalPayload = {
            //   firstName: response.customer[0].firstName,
            //   lastName: response.customer[0].lastName,
            //   ssn: response.customer[0].ssn,
            //   storeNumber: currentStore,
            // };

            // console.log(
            //   'Getcustomer Approval Object',
            //   getCustomerApprovalPayload
            // );

            // let customerDetails: any = await GetCustomer(
            //   customerId,
            //   getCustomerApprovalPayload
            // );

            // console.log(
            //   'Getcustomer Approval Response',
            //   getCustomerApprovalPayload,
            //   customerDetails
            // );

            // if (
            //   customerDetails !== undefined &&
            //   customerDetails.data !== undefined &&
            //   customerDetails.data.customerApprovalDetails !== undefined &&
            //   customerDetails.data.customerApprovalDetails !== null &&
            //   customerDetails.data.customerApprovalDetails !== ''
            // ) {
            //   customerDetails = customerDetails.data;
            //   const DEstatus =
            //     customerDetails.customerApprovalDetails.approvalStatus;
            //   const RawExpDate =
            //     customerDetails.customerApprovalDetails.approvalExpirationDate;
            //   if (RawExpDate !== undefined) {
            //     const RawExpDateArr = RawExpDate.split('-');
            //     const ExpDate =
            //       RawExpDateArr[1] +
            //       '/' +
            //       RawExpDateArr[2] +
            //       '/' +
            //       RawExpDateArr[0];
            //     setCustomerExpDate(ExpDate);
            //   }

            //   const ApprovalAmt =
            //     customerDetails.customerApprovalDetails.approvalAmount;
            //   const WeeklyApprovalAmt =
            //     customerDetails.customerApprovalDetails.weeklyApprovalAmount;
            //   if (DEstatus !== undefined) {
            //     setCustomerDEstaus(DEstatus);
            //   }
            //   setApprovalAmount(ApprovalAmt.toFixed(2));
            //   setWeeklyApprovalAmount(WeeklyApprovalAmt.toFixed(2));
            // }
          }
        } else {
          console.log('viewAgreementData is not undefined ', viewAgrData);

          response = viewAgrData.data;
          if (
            response !== undefined
            // response.customer !== undefined &&
            // response.customer.length > 0
          ) {
            //response = response.data;
            const CustomerObj = {
              GlobalCustomerID: response.agreementCustomer[0].globalCustomerId,
              CustomerName:
                response.agreementCustomer[0].firstName +
                ' ' +
                response.agreementCustomer[0].lastName,
              CustomerId: response.agreementCustomer[0].customerId,
              CusotmerPhoneNumber: response.agreementCustomer[0].phoneNumber,
              CustomerEmail: response.agreementCustomer[0].emailId,
              CoCustomerName: '',
              CoCustomerId: '', // Added
            };
            setCustomerInformation({
              ...CustomerInformation,
              CustomerName:
                response.agreementCustomer[0].firstName +
                ' ' +
                response.agreementCustomer[0].lastName,
              CustomerID: response.agreementCustomer[0].customerId,
              PhoneNumber: response.agreementCustomer[0].phoneNumber,
            });
            console.log('cdvjnk efw541ef');
            setCustomerValue(CustomerObj);
            if (
              response !== undefined &&
              response.agreementCustomer.length > 1
            ) {
              setCustomerInformation({
                ...CustomerInformation,
                CustomerName:
                  response.agreementCustomer[0].firstName +
                  ' ' +
                  response.agreementCustomer[0].lastName,
                CustomerID: response.agreementCustomer[0].customerId,
                PhoneNumber: response.agreementCustomer[1].phoneNumber,
                CoCustomerName:
                  response.agreementCustomer[1].firstName +
                  ' ' +
                  response.agreementCustomer[1].lastName,
                CoCustomerId: response.agreementCustomer[1].customerId,
              });
              console.log('Customer Object before', CustomerObj);
              const CustomerObject = {
                GlobalCustomerID: CustomerObj.GlobalCustomerID,
                CustomerName: CustomerObj.CustomerName,
                CustomerId: CustomerObj.CustomerId,
                CusotmerPhoneNumber: CustomerObj.CusotmerPhoneNumber,
                // CustomerVehicles: CustomerObj.CustomerVehicles,
                CustomerEmail: CustomerObj.CustomerEmail,
                CoCustomerName:
                  response.agreementCustomer[1].firstName +
                  ' ' +
                  response.agreementCustomer[1].lastName,
                CoCustomerId: response.agreementCustomer[1].customerId,
              };
              console.log(
                'Customer Object after',
                CustomerObj,
                CustomerObject,
                CustomerValue,
                CustomerInfoContext
              );
              setCoCustomerName(
                response.agreementCustomer[1].firstName +
                  ' ' +
                  response.agreementCustomer[1].lastName
              );
              console.log('vfvf evjnejr 46848');

              setcocustomervalue(CoCustomerId);
              setCustomerValue(CustomerObject);
            }

            // const getCustomerApprovalPayload = {
            //   firstName: response.agreementCustomer[0].firstName,
            //   lastName: response.agreementCustomer[0].lastName,
            //   ssn: response.agreementCustomer[0].ssn,
            //   storeNumber: currentStore,
            // };

            // console.log(
            //   'Getcustomer Approval Object',
            //   getCustomerApprovalPayload
            // );

            // let customerDetails: any = await GetCustomer(
            //   customerId,
            //   getCustomerApprovalPayload
            // );

            // console.log(
            //   'Getcustomer Approval Response',
            //   getCustomerApprovalPayload,
            //   customerDetails
            // );

            // if (
            //   customerDetails !== undefined &&
            //   customerDetails.data !== undefined &&
            //   customerDetails.data.customerApprovalDetails !== undefined &&
            //   customerDetails.data.customerApprovalDetails !== null &&
            //   customerDetails.data.customerApprovalDetails !== ''
            // ) {
            //   customerDetails = customerDetails.data;
            //   const DEstatus =
            //     customerDetails.customerApprovalDetails.approvalStatus;
            //   const RawExpDate =
            //     customerDetails.customerApprovalDetails.approvalExpirationDate;
            //   if (RawExpDate !== undefined) {
            //     const RawExpDateArr = RawExpDate.split('-');
            //     const ExpDate =
            //       RawExpDateArr[1] +
            //       '/' +
            //       RawExpDateArr[2] +
            //       '/' +
            //       RawExpDateArr[0];
            //     setCustomerExpDate(ExpDate);
            //   }

            //   const ApprovalAmt =
            //     customerDetails.customerApprovalDetails.approvalAmount;
            //   const WeeklyApprovalAmt =
            //     customerDetails.customerApprovalDetails.weeklyApprovalAmount;
            //   if (DEstatus !== undefined) {
            //     setCustomerDEstaus(DEstatus);
            //   }
            //   setApprovalAmount(ApprovalAmt.toFixed(2));
            //   setWeeklyApprovalAmount(WeeklyApprovalAmt.toFixed(2));
            // }
          }
        }
        // eslint-disable-next-line no-console
        console.log('getagreement customer response', response);
      }
    };
    //   else {
    //   let legalHoldResponse: any = {
    //     legalHoldRes: {},
    //   };
    //   let response: any = {
    //     getCustomerRes: {},
    //   };

    //   console.log('legal hold response before', legalHoldResponse, response);

    //   await Promise.all([
    //     GetLegalHold(customerId),
    //     GetCustomer(customerId, currentStore),
    //   ]).then((resp) => {
    //     [legalHoldResponse, response] = resp;
    //   });
    //   console.log('legal hold response', legalHoldResponse, response);
    //   if (
    //     legalHoldResponse !== undefined &&
    //     legalHoldResponse !== '' &&
    //     legalHoldResponse !== null &&
    //     legalHoldResponse.legalHoldStatusRes !== undefined &&
    //     legalHoldResponse.legalHoldStatusRes.length > 0 &&
    //     legalHoldResponse.legalHoldStatusRes[0].legalHoldStatus !== undefined &&
    //     legalHoldResponse.legalHoldStatusRes[0].legalHoldStatus === true
    //   ) {
    //     setlegalholderror(true);
    //     //setIsLegalHold(true);
    //   } else {
    //     if (response.status === 200) {
    //       if (
    //         response.data.GetApproval.value.customerApprovalDetails !==
    //         undefined
    //       ) {
    //         console.log('reached here idhvnhsdf check svc');
    //         console.log(
    //           'testing the requid date',
    //           response.data.GetApproval.value.customerApprovalDetails
    //             .approvalExpirationDate
    //         );

    //         setstoreExpiryDate(
    //           response.data.GetApproval.value.customerApprovalDetails
    //             .approvalExpirationDate
    //         );
    //       } else if (response.data.GetApproval.value == '') {
    //         console.log('as the 204 reesponse');

    //         setstoreExpiryDate(undefined);
    //       } else if (
    //         response.data.GetApproval.value.status == 500 ||
    //         response.data.GetApproval.value.code == 400 ||
    //         response.data.GetApproval.value.code == 500 ||
    //         response.data.GetApproval.value.status == 400
    //       ) {
    //         console.log('reached here blocskdvjds check svc');
    //         setstoreExpiryDate(undefined);
    //       }

    //       setentireGetCustomerData(response.data.GetCustomer.value);
    //       if (response.data.GetCustomer.value.coCustomer === 'N') {
    //         const customerDetails = response.data;
    //         console.log('GetCustomer Lambda response', customerDetails);
    //         if (customerDetails !== undefined) {
    //           const GlobalCustomerId =
    //             customerDetails.GetCustomer.value.globalCustomerId;
    //           const CusName = `${customerDetails.GetCustomer.value.firstName} ${customerDetails.GetCustomer.value.lastName}`;
    //           const CusId = customerDetails.GetCustomer.value.customerId;
    //           const CusPhoneNumArr = customerDetails.GetCustomer.value.phones;
    //           let CusPhoneNum = '';
    //           if (CusPhoneNumArr !== undefined && CusPhoneNumArr.length > 0) {
    //             const IndexofCell = CusPhoneNumArr.findIndex(
    //               (obj) => obj.phoneType === 'CELL' && obj.primary === 'Y'
    //             );
    //             if (IndexofCell >= 0) {
    //               CusPhoneNum =
    //                 customerDetails.GetCustomer.value.phones[IndexofCell]
    //                   .phoneNumber;
    //             }
    //           }

    //           const CustVehicles = customerDetails.GetCustomer.value.vehicles;
    //           const CusEmail = customerDetails.GetCustomer.value.emailAddress;
    //           const CoCustId =
    //             customerDetails.GetCustomer.value.coCustomerId !== null
    //               ? customerDetails.GetCustomer.value.coCustomerId
    //               : '';
    //           if (
    //             (response.data.GetCustomer.value.skip !== undefined &&
    //               response.data.GetCustomer.value.skip == 'Y') ||
    //             (response.data.GetCustomer.value.stolen !== undefined &&
    //               response.data.GetCustomer.value.stolen == 'Y') ||
    //             (response.data.GetCustomer.value.hard !== undefined &&
    //               response.data.GetCustomer.value.hard == 'Y')
    //           ) {
    //             skip = response.data.GetCustomer.value.skip;
    //             stolen = response.data.GetCustomer.value.stolen;
    //             hard = response.data.GetCustomer.value.stolen;
    //           }
    //           if (
    //             customerDetails.GetApproval !== undefined &&
    //             customerDetails.GetApproval.value !== '' &&
    //             customerDetails.GetApproval.value !== undefined &&
    //             customerDetails.GetApproval.value.customerApprovalDetails !==
    //               undefined &&
    //             customerDetails.GetApproval.value.code !== 400 &&
    //             customerDetails.GetApproval.value.code !== 500 &&
    //             customerDetails.GetApproval.value.code !== 401
    //           ) {
    //             const DEstatus =
    //               customerDetails.GetApproval.value.customerApprovalDetails
    //                 .approvalStatus;
    //             const RawExpDate =
    //               customerDetails.GetApproval.value.customerApprovalDetails
    //                 .approvalExpirationDate;
    //             if (RawExpDate !== undefined) {
    //               const RawExpDateArr =
    //                 RawExpDate !== '' ? RawExpDate.split('-') : '';
    //               const ExpDate =
    //                 RawExpDateArr[1] +
    //                 '/' +
    //                 RawExpDateArr[2] +
    //                 '/' +
    //                 RawExpDateArr[0];
    //               setCustomerExpDate(ExpDate);
    //             }

    //             const ApprovalAmt =
    //               customerDetails.GetApproval.value.customerApprovalDetails
    //                 .approvalAmount !== undefined &&
    //               customerDetails.GetApproval.value.customerApprovalDetails
    //                 .approvalAmount !== null
    //                 ? customerDetails.GetApproval.value.customerApprovalDetails
    //                     .approvalAmount
    //                 : '';
    //             const WeeklyApprovalAmt =
    //               customerDetails.GetApproval.value.customerApprovalDetails
    //                 .weeklyApprovalAmount !== undefined &&
    //               customerDetails.GetApproval.value.customerApprovalDetails
    //                 .weeklyApprovalAmount !== null
    //                 ? customerDetails.GetApproval.value.customerApprovalDetails
    //                     .weeklyApprovalAmount
    //                 : '';
    //             if (DEstatus !== undefined) {
    //               setCustomerDEstaus(DEstatus);
    //             }

    //             setApprovalAmount(
    //               ApprovalAmt !== '' ? ApprovalAmt.toFixed(2) : ''
    //             );
    //             setWeeklyApprovalAmount(
    //               WeeklyApprovalAmt !== '' ? WeeklyApprovalAmt.toFixed(2) : ''
    //             );
    //           }
    //           setCustomerInformation({
    //             ...CustomerInformation,
    //             CustomerName: CusName,
    //             CustomerID: CusId,
    //             PhoneNumber: CusPhoneNum,
    //           });
    //           console.log('CustomerInfo value inside weekly approaval amount');
    //           const CustomerObj = {
    //             GlobalCustomerID: GlobalCustomerId,
    //             CustomerName: CusName,
    //             CustomerId: CusId,
    //             CusotmerPhoneNumber: CusPhoneNum,
    //             CustomerVehicles: CustVehicles,
    //             CustomerEmail: CusEmail,
    //             CoCustomerName:
    //               CustomerValue !== undefined &&
    //               CustomerValue.CoCustomerName !== undefined
    //                 ? CustomerValue.CoCustomerName
    //                 : '',
    //             CoCustomerId: CoCustId,
    //             Skip: skip,
    //             Stolen: stolen,
    //             Hard: hard,
    //           };

    //           console.log(
    //             'customer obj with cocusyotmer Name and CocustomerId and skip stolen',
    //             CustomerObj
    //           );
    //           console.log('few5f4654v65d4v');

    //           setCustomerValue(CustomerObj);

    //           // if (
    //           //   agreementId !== undefined &&
    //           //   agreementId !== null &&
    //           //   agreementId !== ''
    //           // ) {
    //           //   console.log('add cocustomer blocker due to agreement ID');
    //           // } else {
    //           if (
    //             customerDetails.GetCustomer.value.coCustomerId !== undefined &&
    //             customerDetails.GetCustomer.value.coCustomerId !== null
    //           ) {
    //             const GetcoworkerResponse = await GetCustomerId(
    //               customerDetails.GetCustomer.value.coCustomerId
    //             );

    //             console.log(
    //               'passing customerid to customerdetail',
    //               GetcoworkerResponse
    //             );
    //             if (
    //               (response.data.GetCustomer.value.skip !== undefined &&
    //                 response.data.GetCustomer.value.skip == 'Y') ||
    //               (response.data.GetCustomer.value.stolen !== undefined &&
    //                 response.data.GetCustomer.value.stolen == 'Y') ||
    //               (response.data.GetCustomer.value.hard !== undefined &&
    //                 response.data.GetCustomer.value.hard == 'Y')
    //             ) {
    //               skip = response.data.GetCustomer.value.skip;
    //               stolen = response.data.GetCustomer.value.stolen;
    //               hard = response.data.GetCustomer.value.stolen;
    //             }

    //             if (
    //               GetcoworkerResponse !== undefined &&
    //               GetcoworkerResponse.data !== undefined &&
    //               GetcoworkerResponse.data !== null &&
    //               GetcoworkerResponse !== null
    //             ) {
    //               setCoCustomerId(
    //                 GetcoworkerResponse.data.customerId !== undefined &&
    //                   GetcoworkerResponse.data.customerId !== null
    //                   ? GetcoworkerResponse.data.customerId
    //                   : ''
    //               );
    //               const coCustomerFirstName =
    //                 GetcoworkerResponse.data.firstName !== undefined &&
    //                 GetcoworkerResponse.data.firstName !== null
    //                   ? GetcoworkerResponse.data.firstName
    //                   : '';
    //               const coCustomerLastName =
    //                 GetcoworkerResponse.data.lastName !== undefined &&
    //                 GetcoworkerResponse.data.lastName !== null
    //                   ? GetcoworkerResponse.data.lastName
    //                   : '';

    //               setCustomerInformation({
    //                 ...CustomerInformation,
    //                 CustomerName: CusName,
    //                 CustomerID: CusId,
    //                 PhoneNumber: CusPhoneNum,
    //                 CoCustomerName:
    //                   coCustomerFirstName + ' ' + coCustomerLastName,
    //                 CoCustomerId:
    //                   customerDetails.GetCustomer.value.coCustomerId,
    //               });
    //               console.log('Customer Object before', CustomerObj);
    //               const CustomerObject = {
    //                 GlobalCustomerID: GlobalCustomerId,
    //                 CustomerName: CustomerObj.CustomerName,
    //                 CustomerId: CustomerObj.CustomerId,
    //                 CusotmerPhoneNumber: CustomerObj.CusotmerPhoneNumber,
    //                 CustomerVehicles: CustomerObj.CustomerVehicles,
    //                 CustomerEmail: CustomerObj.CustomerEmail,
    //                 CoCustomerName:
    //                   coCustomerFirstName + ' ' + coCustomerLastName,
    //                 CoCustomerId:
    //                   customerDetails.GetCustomer.value.coCustomerId,
    //                 Skip: skip,
    //                 Stolen: stolen,
    //                 Hard: hard,
    //               };
    //               console.log(
    //                 'Customer Object after',
    //                 CustomerObj,
    //                 CustomerObject,
    //                 CustomerValue,
    //                 CustomerInfoContext
    //               );
    //               setCoCustomerName(
    //                 coCustomerFirstName + ' ' + coCustomerLastName
    //               );
    //               console.log('edv51v5fdv141');

    //               setcocustomervalue(CoCustomerId);

    //               setCustomerValue(CustomerObject);
    //             }

    //             // from the context adding the cocustomer name and cocustomerid in the wizard.
    //           } else if (
    //             coCustomerIdParams !== undefined &&
    //             coCustomerIdParams !== null &&
    //             coCustomerIdParams !== '' &&
    //             coCustomerIdParams !== 'N'
    //           ) {
    //             const GetcoworkerResponse = await GetCustomerId(
    //               coCustomerIdParams
    //             );

    //             console.log(
    //               'passing customerid to customerdetail',
    //               GetcoworkerResponse
    //             );
    //             if (
    //               (response.data.GetCustomer.value.skip !== undefined &&
    //                 response.data.GetCustomer.value.skip == 'Y') ||
    //               (response.data.GetCustomer.value.stolen !== undefined &&
    //                 response.data.GetCustomer.value.stolen == 'Y') ||
    //               (response.data.GetCustomer.value.hard !== undefined &&
    //                 response.data.GetCustomer.value.hard == 'Y')
    //             ) {
    //               skip = response.data.GetCustomer.value.skip;
    //               stolen = response.data.GetCustomer.value.stolen;
    //               hard = response.data.GetCustomer.value.stolen;
    //             }

    //             if (
    //               GetcoworkerResponse !== undefined &&
    //               GetcoworkerResponse.data !== undefined &&
    //               GetcoworkerResponse.data !== null &&
    //               GetcoworkerResponse !== null
    //             ) {
    //               setCoCustomerId(
    //                 GetcoworkerResponse.data.customerId !== undefined &&
    //                   GetcoworkerResponse.data.customerId !== null
    //                   ? GetcoworkerResponse.data.customerId
    //                   : ''
    //               );
    //               const coCustomerFirstName =
    //                 GetcoworkerResponse.data.firstName !== undefined &&
    //                 GetcoworkerResponse.data.firstName !== null
    //                   ? GetcoworkerResponse.data.firstName
    //                   : '';
    //               const coCustomerLastName =
    //                 GetcoworkerResponse.data.lastName !== undefined &&
    //                 GetcoworkerResponse.data.lastName !== null
    //                   ? GetcoworkerResponse.data.lastName
    //                   : '';
    //               const CustomerObjects = {
    //                 GlobalCustomerID: GlobalCustomerId,
    //                 CustomerName: CustomerObj.CustomerName,
    //                 CustomerId: CustomerObj.CustomerId,
    //                 CusotmerPhoneNumber: CustomerObj.CusotmerPhoneNumber,
    //                 CustomerVehicles: CustomerObj.CustomerVehicles,
    //                 CustomerEmail: CustomerObj.CustomerVehicles,
    //                 CoCustomerName:
    //                   coCustomerFirstName + ' ' + coCustomerLastName,
    //                 CoCustomerId: coCustomerIdParams,
    //                 Skip: skip,
    //                 Stolen: stolen,
    //                 Hard: hard,
    //               };

    //               setCustomerInformation({
    //                 ...CustomerInformation,
    //                 CustomerName: CusName,
    //                 CustomerID: CusId,
    //                 PhoneNumber: CusPhoneNum,
    //                 CoCustomerName:
    //                   coCustomerFirstName + ' ' + coCustomerLastName,
    //                 CoCustomerId: coCustomerIdParams,
    //               });
    //               setCoCustomerName(
    //                 coCustomerFirstName + ' ' + coCustomerLastName
    //               );
    //               console.log('gfgb 65f65b frb');

    //               setcocustomervalue(coCustomerIdParams);
    //               console.log(
    //                 'Customer Object',
    //                 CustomerObj,
    //                 CustomerObjects,
    //                 CustomerValue,
    //                 CustomerInfoContext
    //               );
    //               console.log('dv1vf1rf65v651f');

    //               setCustomerValue(CustomerObjects);
    //             }

    //             // from the context adding the cocustomer name and cocustomerid in the wizard.
    //           }
    //           // }
    //         } else {
    //           const getUrl = window.location.href;
    //           if (
    //             getUrl.includes('/rental/itemsearch') ||
    //             getUrl.includes('/rental/details') ||
    //             getUrl.includes('/rental/delivery')
    //           ) {
    //             console.log('hsbhdbcue');
    //             Setsuccesspopup(true);
    //           }
    //         }
    //       } else {
    //         SetIscustomerapiFailed(true);
    //         const getUrl = window.location.href;
    //         if (
    //           getUrl.includes('/rental/itemsearch') ||
    //           getUrl.includes('/rental/details') ||
    //           getUrl.includes('/rental/delivery')
    //         ) {
    //           console.log('hsbhdbcjkkkk');
    //           Setsuccesspopup(true);
    //         }
    //       }

    //       // setstoreExpiryDate(
    //       //   response.data.GetApproval.value.customerApprovalDetails
    //       //     .approvalExpirationDate
    //       // );
    //       console.log(
    //         'view response for get approval----->',
    //         response.data.GetApproval
    //       );
    //       // if (
    //       //   response.data.GetApproval.value.customerApprovalDetails !==
    //       //   undefined
    //       // ) {
    //       //   console.log('reached here idhvnhsdf check svc');
    //       //   console.log(
    //       //     'testing the requid date',
    //       //     response.data.GetApproval.value.customerApprovalDetails
    //       //       .approvalExpirationDate
    //       //   );

    //       //   setstoreExpiryDate(
    //       //     response.data.GetApproval.value.customerApprovalDetails
    //       //       .approvalExpirationDate
    //       //   );
    //       // } else if (response.data.GetApproval.value.status == 500) {
    //       //   console.log('reached here blocskdvjds check svc');
    //       //   setstoreExpiryDate(undefined);
    //       // }
    //       //setstoreExpiryDate('2021-02-15');
    //       console.log('before setting the entireGetCustomerData', response);

    //       setentireGetCustomerData(response.data.GetCustomer.value);
    //       // const currentData = new Date();
    //       // const mydate = new Date(
    //       //   response.data.GetApproval.value.customerApprovalDetails.approvalExpirationDate
    //       // );
    //       // console.log(
    //       //   'response.data.GetApproval.value.customerApprovalDetails.approvalExpirationDate',
    //       //   response.data.GetApproval.value.customerApprovalDetails
    //       //     .approvalExpirationDate
    //       // );
    //     }
    //   }
    //   // }
    //   // console.log('customerInforesponse', response);
    // };
    getCustomer_APIcall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const tempMethod = () => {
  //   console.log('Empty Console');
  // };

  // const errorpopup = () => {
  //   return (
  //     <div
  //       id="success"
  //       data-bs-backdrop="static"
  //       data-bs-keyboard="false"
  //       aria-hidden="true"
  //     >
  //       <Grid item md={12} className={classes.textCenter}>
  //         <Alertimage></Alertimage>
  //         <Typography className={classes.my2}>
  //           {IscustomerapiFailed === false
  //             ? 'This customer is already a cocustomer on another agreement.'
  //             : 'This Customer is a co-customer'}
  //         </Typography>
  //       </Grid>

  //       <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
  //         <RACButton
  //           variant="contained"
  //           color="primary"
  //           isRounded={false}
  //           onClick={() => RediectToCustomer()}
  //         >
  //           Ok
  //         </RACButton>
  //       </Grid>
  //     </div>
  //   );
  // };
  // const handleerror = () => {
  //   return (
  //     <div
  //       id="success"
  //       data-bs-backdrop="static"
  //       data-bs-keyboard="false"
  //       aria-hidden="true"
  //     >
  //       <Grid item md={12} className={classes.textCenter}>
  //         <Alertimage></Alertimage>
  //         <Typography className={classes.my2}>
  //           Unable to create agreement due to customer status
  //         </Typography>
  //       </Grid>

  //       <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
  //         <RACButton
  //           variant="contained"
  //           color="primary"
  //           isRounded={false}
  //           onClick={() => RediectToDashBoard()}
  //         >
  //           Ok
  //         </RACButton>
  //       </Grid>
  //     </div>
  //   );
  // };

  return (
    <>
      <Grid className={`${classes.floatRight}`} data-testid="CustomerInfo">
        {CustomerInformation !== undefined &&
        CustomerInformation.CoCustomerName !== undefined &&
        CustomerInformation.CoCustomerName !== '' ? (
          <div
            className={`${classes.floatLeft} ${classes.me3} ${classes.mt1} `}
          >
            <span className={wizStyle.coCustomerLabel}>
              {'Co-Customer : '}

              <Link
                to={`/customer/update/${CustomerInformation.CoCustomerId}/customer`}
                className={wizStyle.customerName}
              >
                {CustomerInformation.CoCustomerName}
              </Link>
            </span>

            <span className={classes.agrInfoPanel}>
              ID: {CustomerInformation.CoCustomerId}
            </span>
          </div>
        ) : null}
      </Grid>
      {/* <RACModalCard
        isOpen={successpopup}
        maxWidth="xs"
        // title="Modal title"
        onClose={() => RediectToCustomer()}
        // eslint-disable-next-line react/no-children-prop
        children={errorpopup()}
        borderRadius="20px"
      /> */}
      {/* <RACModalCard
        isOpen={legalholderror}
        maxWidth="xs"
        // title="Modal title"
        onClose={() => RediectToDashBoard()}
        // eslint-disable-next-line react/no-children-prop
        children={handleerror()}
        borderRadius="20px"
      /> */}
    </>
  );
}
