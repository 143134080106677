/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AddCocustomer from '../../Rental/AddCoCustomer/AddCocustomer';
// import { AgreementContext } from '../../../context/AgreementContext';
import { CustomerOrderAgreementContext } from '../../../context/CustomerOrderAgreementContext';
import { RACButton, Grid } from '@rentacenter/racstrap';
interface Params {
  customerId: string;
  agreementId: string;
}
const FooterContent = (props: any) => {
  const { setcrossedItemSearch, agreementVoidStatus, toggleTB, addedItems, isNextPopupOpened, setIsNextPopupOpened, countOfTire, setCountOfTire, totalTires, setTotalTires, setmaxQtyReached, agreementCreateStatus
  } = useContext(
    CustomerOrderAgreementContext
  );
  // const { agreementVoidStatus } = useContext(AgreementContext);
  const classes = props.throwStyle; //This will hold the global styles
  const history = useHistory(); //This will hold the history.push() which will be helping in redirection
  const [footerControl, setfooterControl] = useState();
  const { agreementId } = useParams<Params>();
  const { customerId } = useParams<Params>();
  const search = useLocation().search;
  const salesLeadId = new URLSearchParams(search).get('salesLeadId');
  const [newcustomer, setnewcustomer] = useState(true);
  const [coCustomerId, setcocustomerId] = useState('');
  const [coCustomerName, setcoCustomerName] = useState('');
  useEffect(() => {
    setfooterControl(props.controlData);
  }, [props]); //This method will be making the next button enable and disable.
  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    // eslint-disable-next-line no-console
    console.log('Footer contex', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };
  const triggerME = () => {
    console.log('response in triggerME');
    console.log('fdgshjhshyyuuiiii');
    props.sometrigger();
  };
  const CancelClick = () => {
    history.push({
      pathname: `/customer/searchcustomer`,
    });
  }; //This method is triggered when cancel button is clicked
  const NextBtnClick = () => {
    props.setItemsearchCompleted(true);
    if (agreementId == undefined && salesLeadId == undefined) {
      history.push({
        pathname: `/agreement/customerorder/details/${customerId}`,
      });
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (salesLeadId && agreementId) {
      history.push({
        pathname: `/agreement/customerorder/details/${customerId}/${agreementId}?salesLeadId=${salesLeadId}`,
      });
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (salesLeadId) {
      history.push({
        pathname: `/agreement/customerorder/details/${customerId}?salesLeadId=${salesLeadId}`,
      });
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else {
      history.push({
        pathname: `/agreement/customerorder/details/${customerId}/${agreementId}`,
      });
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
    setcrossedItemSearch(true);
  }; //PS - 30 This method is triggered when Next button is clicked. Based on the condition it will be moving to agreement details page or it will be in item search page.

  function nextButtonClickTB() {
    debugger
    try {
      setIsNextPopupOpened(true);
      setCountOfTire(addedItems.length);

      setcrossedItemSearch(true);
    } catch (error: any) {
      console.log(error.message);
      return;
    }
  }

  return (
    <Grid className={classes.fixedBottom}>
      <Grid className={classes.floatLeft}>
        <RACButton
          color="primary"
          variant="outlined"
          className={classes.ms2}
          onClick={() => CancelClick()}
        >
          Cancel
        </RACButton>
      </Grid>
      <Grid className={classes.floatRight}>
        <RACButton
          color="primary"
          variant="contained"
          onClick={() => {
            console.log(toggleTB, "This is called")
            if (toggleTB) {
              console.log("This is called inside")
              if (totalTires > 6) {
                setmaxQtyReached(true)
              }
              else {
                nextButtonClickTB();

              }
            } else {
              NextBtnClick()
            }
          }}
          disabled={(toggleTB && addedItems.length <= 0) || (!toggleTB && footerControl)}
          style={{ marginRight: '4px' }}
        >
          Next
        </RACButton>
      </Grid>
      {agreementCreateStatus.agreementCreatedStatus === false ? (
        <Grid className={classes.floatRight}>
          <AddCocustomer
            coCustomerProps={customerId}
            newCoCustomer={newcustomer}
            func={pull_data}
            somethingTrigger={triggerME}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
export default FooterContent;