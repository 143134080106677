/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography } from '@rentacenter/racstrap';
import { Usercontext } from './searchResult';
import { ReactComponent as Delete } from '../../../assets/images/delete-item.svg';
import { searchBlueCardStyles } from '../../../JSstyles/searchBlueCardStyles';
import { SwitchOutAgreementContext } from '../../../context/SwitchOutAgreementContext';

export type Componentprops = {
  triggerTemp: (itemId: any) => void;
  throwStyle: any;
};

const BlueCard = (props: Componentprops): any => {
  const { switchOutData, itemArrayData, searchCriteria } = useContext(
    SwitchOutAgreementContext
  );
  const classes = props.throwStyle; //This will hold the global styles
  const searchBlueCardClassName = searchBlueCardStyles(); //This will hold the component specific styles
  const racWhiteStyle = searchBlueCardClassName.agrSplitup; //This will hold the split style
  const shareData: any = useContext(Usercontext); //This will hold the API result
  console.log('Shared Data', shareData);
  const bluecardobj: any = [
    {
      weeklyRate: '0.00',
      weeklyTerm: '0.00',
      tagTotal: '0.00',
      inventoryDepreciation: '0.00',
      agreementRate: '0.00',
      cashPrice: '0.00',
      inventoryName: '',
      inventoryNumber: '0.00',
      remainingValue: '0.00',
    },
  ]; //This is the initial array structure of the response that is going to get populate
  const [pricedata, setpricedata] = useState<any>(bluecardobj);
  const [agreementterm, setagreementTerm] = useState<any>('0.00');
  const [Id, setId] = useState('');

  const DeleteItem = (index: any, itemID: any) => {
    console.log('req index', index);
    console.log('req price data', pricedata);
    console.log('req itemID', itemID);

    props.triggerTemp(itemID);
  }; //This method is triggered when delete icon is clicked. This will throw the delete id to addedItem component.

  useEffect(() => {
    console.log('inside blue card data');
    if (shareData !== undefined) {
      console.log('Came in if of blue card');
      console.log('Context Data', shareData);
      setpricedata(shareData.itemPricing);
      setagreementTerm(shareData.agreementPricing.agreementTerm);
    } else {
      console.log('Came in else of blue card');
      console.log('Came in else of blue card with share data', shareData);
      setpricedata([]);
      setagreementTerm('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareData]); //PS - 25 This method wil check the value of API response if it's undefined then the values are stored in state variable to get populate it

  const changeView = (index: any) => {
    if (index === Id) {
      setId('');
    } else {
      setId(index);
    }
  }; //PS - 26 This method will store the id of the current view mode div element. It is triggered when view more or less is clicked.
  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  }; //PS - 27 This method is used to add elipses to the item description

  return pricedata.map((obj: any, index: any) => {
    return (
      <div key={index}>
        <Grid container className={classes.mb3}>
          <Grid item md={12} className={classes.mb2}>
            {searchCriteria.packageTypeval !== '' && index == 0 ? (
              <label
                className={`${classes.font15} ${classes.mt1} ${classes.textWhite} ${classes.semiBold}`}
              >
                {searchCriteria.packageTypeval}
              </label>
            ) : null}
          </Grid>
          <Grid
            item
            md={7}
            className={`${classes.font14} ${classes.textWhite} ${searchBlueCardClassName.enableView}`}
          >
            <Typography title={obj.inventoryName}>
              {truncString(obj.inventoryName, 20, '...')}
            </Typography>
          </Grid>
          <Grid
            item
            md={5}
            className={`${classes.font18} ${classes.textRight} ${classes.semiBold} ${classes.textWhite}`}
          >
            $ {obj.agreementTotal?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </Grid>

          <Grid item md={12} className={classes.mb2}>
            <Typography
              className={`${classes.font13} ${classes.textWhite} ${classes.regular}`}
            >
              Item # : {obj.inventoryNumber}
            </Typography>
          </Grid>

          <Grid item md={12} className="">
            <Typography className="" id="headingOne">
              {Id === index ? (
                <span
                  className={`${searchBlueCardClassName.accordionButton} ${searchBlueCardClassName.accordionArrow} ${classes.w50} ${searchBlueCardClassName.enableView} ${classes.regular}`}
                  onClick={() => changeView(index)}
                >
                  View Less
                </span>
              ) : (
                <span
                  onClick={() => changeView(index)}
                  className={`${searchBlueCardClassName.accordionButton} ${searchBlueCardClassName.accordionArrow} ${classes.w50} ${classes.collapsed} ${searchBlueCardClassName.enableView} ${classes.regular}`}
                >
                  View More
                </span>
              )}
              <Typography className={`${classes.floatLeft} ${classes.w50}`}>
                <Typography
                  className={`${classes.floatRight} ${classes.cursorPointer}`}
                >
                  {Id === index &&
                  switchOutData !== undefined &&
                  pricedata[0].inventoryNumber !==
                    switchOutData.itemPricing[0].inventoryNumber ? (
                    <Delete
                      role="elite"
                      data-testid="deleteIcon"
                      onClick={() => DeleteItem(index, obj.inventoryId)}
                    />
                  ) : null}
                </Typography>
              </Typography>
            </Typography>
            {Id === index ? (
              <div
                id={searchBlueCardClassName.fullview}
                className={`${classes.show} ${searchBlueCardClassName.fullview}`}
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
                data-testid="accordionshow"
              >
                <div className={`${classes.mt3} ${classes.p0}`}>
                  <Grid container>
                    <Grid item md={5} className={classes.mb2}>
                      <Typography className={racWhiteStyle}>
                        Tag Rate
                      </Typography>
                      <Typography className={racWhiteStyle}>
                        $ {obj.weeklyRate}
                      </Typography>
                    </Grid>

                    <Grid item md={3} className={classes.mb2}>
                      <Typography className={racWhiteStyle}>
                        Tag Term
                      </Typography>
                      <Typography className={racWhiteStyle}>
                        {obj.weeklyTerm}
                      </Typography>
                    </Grid>

                    <Grid item md={4} className={classes.mb2}>
                      <Typography
                        className={`${classes.textWhite} ${classes.font13} ${classes.ms2}`}
                      >
                        Tag Total
                      </Typography>
                      <Typography
                        className={`${classes.textWhite} ${classes.font13} ${classes.ms2}`}
                      >
                        $ {obj.tagTotal?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item md={5} className={classes.mb2}>
                      <Typography className={racWhiteStyle}>
                        Agr Rate
                      </Typography>
                      <Typography className={racWhiteStyle}>
                        $ {obj.agreementRate}
                        {/* <Typography
                          variant="caption"
                          className={`${searchBlueCardClassName.racGreen} ${searchBlueCardClassName.racBadge} ${classes.font10} ${classes.semiBold}`}
                        >
                          <DownArrow className={classes.me1} />${' '}
                          {discountRate.toFixed(2)}
                        </Typography> */}
                      </Typography>
                    </Grid>
                    <Grid item md={3} className={classes.mb2}>
                      <Typography className={racWhiteStyle}>
                        Agr Term
                      </Typography>
                      <Typography className={racWhiteStyle}>
                        {agreementterm}
                      </Typography>
                    </Grid>
                    <Grid item md={4} className={classes.mb2}>
                      <Typography
                        className={`${classes.textWhite} ${classes.font13} ${classes.ms2}`}
                      >
                        Inv Depr %
                      </Typography>
                      <Typography
                        className={`${classes.textWhite} ${classes.font13} ${classes.ms2}`}
                      >
                        {obj.inventoryDepreciation}%
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </div>
    );
  });
};

export default BlueCard;
