import React from 'react';
import { createContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AMOrigin } from '../constants/constants';
import { isLandedFromAM } from '../utils/utils';
export const OriginContext = createContext<any>('');
export function OriginContextProvider(props: any) {
  const location = useLocation();
  let redirectOrigin = '';
  if (isLandedFromAM(location?.pathname) || isLandedFromAM(location?.search)) {
    redirectOrigin = AMOrigin;
  }

  return (
    <div>
      <OriginContext.Provider
        value={{
          redirectOrigin,
        }}
      >
        {props.children}
      </OriginContext.Provider>
    </div>
  );
}
