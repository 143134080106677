/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */

import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const autoPayStyles = () => {
  const useClasses = makeStyles((theme) => ({
    autoPayContainer: {
      margin: '0 auto',
      width: '75%',
    },
    autoPayTitle: {
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(20),
    },
    autoPayValue: {
      borderRadius: '50%',
      padding: '4px 10px',
      backgroundColor: '#EFF4FF',
      color: '#276bff',
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(14),
    },
    autoPaySubTitle: {
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      letterSpacing: 'initial',
    },
    autoPaySubTitleAccent: {
      color: '#7E7E7E',
      fontSize: theme.typography.pxToRem(14),
      fontFamily: 'OpenSans-semibold',
      letterSpacing: 'initial',
    },
    autoPayFormCheck: {
      display: 'block',
      minHeight: '1.5rem',
      marginBottom: '0.125rem',
    },
    autoPayPrimaryRenter: {
      fontSize: theme.typography.pxToRem(16),
      fontFamily: 'OpenSans-semibold',
    },
    autoPayTxtStyle: {
      fontFamily: 'OpenSans-regular',
      fontSize: theme.typography.pxToRem(14),
      letterSpacing: 'initial',
    },
    autoPayLabelTxt: {
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
      letterSpacing: 'initial',
      color: `${RACCOLOR.RICH_BLACK}`,
    },
    justifycenter: {
      justifyContent: 'center',
    },
    customModal: {
      '& div': {
        '& div': {
          maxWidth: '1200px',
        },
      },
    },
  }));
  const classes = useClasses();

  return classes;
};
