/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import { AppRoute, AppRouteKey } from '../../../config/route-config';
import Deliverymethod from '../DeliveryMethod/DeliveryMethod';
import SearchResult from '../ItemSearch/searchResult';
import PromoAgrDetails from '../../PromoAgreement/AgreementDetails/PromoAgrDetails';
import Wizard from '../Shared/Wizard';
import { Route as CustomRoute } from '../../../router/Route';

export default function PromoAgreementRoute() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    // eslint-disable-next-line no-console
    console.log('Footer contex', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };
  const [ItemSearchCompleted, setItemSearchCompleted] = useState(false);
  const [AgrDetailsCompleted, setAgrDetailsCompleted] = useState(false);
  const [changeDEStatus, setchangeDEStatus] = useState(false);
  const [InitialPaymentCompleted, setInitialPaymentCompleted] = useState(false);
  const [DeliveryMethodCompleted, setDeliveryMethodCompleted] = useState(false);
  const [WizardAgrId, setWizardAgrId] = useState('');
  const param = useParams();
  console.log('param in create', param);
  const throwerfunc = () => {
    console.log('response in throwerfunc');
    setchangeDEStatus(true);
  };
  return (
    <>
      <div>
        <div className="tab-content container-pb-custom">
          <div className="">
            <div className="">
              <div
                id="smartwizard"
                className="p-0 sw sw-theme-default sw-justified"
              >
                {/* Fixed Issues */}
                <Wizard
                  WizardAgrId={WizardAgrId}
                  ItemSearchCompleted={ItemSearchCompleted}
                  AgreementDetailsCompleted={AgrDetailsCompleted}
                  InitialPaymentCompleted={InitialPaymentCompleted}
                  DeliveryMethodCompleted={DeliveryMethodCompleted}
                  CoCustomerDetails={{
                    coCustomerId: coCustomerId,
                    coCustomerName: coCustomerName,
                  }}
                  dethrower={changeDEStatus}
                  setWizardAgrId={undefined}
                />

                <CustomRoute
                  path="/agreement/promo/itemsearch"
                  key={AppRouteKey.PromoItemSearch}
                >
                  <SearchResult
                    setItemSearchCompleted={setItemSearchCompleted}
                    func={pull_data}
                    somedeeptrigger={throwerfunc}
                  />
                </CustomRoute>
                <CustomRoute
                  path="/agreement/promo/details"
                  key={AppRouteKey.PromoAgrDetails}
                >
                  <PromoAgrDetails
                    CoCustomerDetails={{
                      coCustomerId: coCustomerId,
                    }}
                    setItemSearchCompleted={setItemSearchCompleted}
                  />
                </CustomRoute>
                <CustomRoute
                  path="/agreement/promo/delivery"
                  key={AppRouteKey.PromoDeliveryMethod}
                >
                  <Deliverymethod
                    setItemSearchCompleted={setItemSearchCompleted}
                    setAgrDetailsCompleted={setAgrDetailsCompleted}
                    setInitialPaymentCompleted={setInitialPaymentCompleted}
                    setWizardAgrId={setWizardAgrId}
                    func={pull_data}
                  />
                </CustomRoute>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
