/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */

import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const agrGeneralInfoStyles = () => {
  const useClasses = makeStyles((theme) => ({
    agrCardHighlighted: {
      borderRadius: theme.typography.pxToRem(16),
      backgroundColor: `${RACCOLOR.NORMAL_BLUE}`,
    },
    paymentGeneralInfo: {
      padding: '10px 13px',
      float: 'left',
    },
    paymentGeneralInfoColorlabel: {
      fontFamily: 'OpenSans-bold',
      color: `${RACCOLOR.NORMAL_BLUE}`,
      fontSize: '14px',
    },
    listitemstyles: {
      backgroundColor: '#eff4ff',
      borderRadius: theme.typography.pxToRem(8),
    },
    borderRightRadius0: {
      borderTopRightRadius: theme.typography.pxToRem(0),
      borderBottomRightRadius: theme.typography.pxToRem(0),
    },
    paymentGeneralInfoColor: {
      color: `${RACCOLOR.NORMAL_BLUE}`,
      fontFamily: 'OpenSans-semibold',
      letterSpacing: 'unset',
      fontSize: '14px',
    },
    cardLabel: {
      color: '#111111',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    //newly added styles
    dropDownLabel: {
      color: '#000',
    },
    disabledColour: {
      color: '#8E8E8E',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
  }));
  const classes = useClasses();

  return classes;
};
