/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/*eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Typography,
  RACModalCard,
  RACSelect,
  RACButton,
  CircularProgress,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { ReactComponent as Alerticon } from '../../../assets/images/no-records-found.svg';
import {
  UpdateAgreement,
  GetEmployeeID,
  GetRole,
  getnextpaymentamount,
  getCOAgreementOptions,
} from '../../../api/user';
import { AgreementContext } from '../../../context/AgreementContext';
import { ContainerContext } from '../../../app/App';
import SecondFactor from '../../Rental/TwoFactorPopup/TwoFactorPopup';
import { useParams, useLocation } from 'react-router-dom';
import { ReactComponent as Successicon } from '../../../assets/images/success-icon.svg';
import { useHistory } from 'react-router';
interface Params {
  customerId: string;
  agreementId: string;
}
// Global variable for RACModal card Border radius value

export default function ChargeOff({ paymentredirectChargeOff }) {
  const history = useHistory();
  const modalCardRadius = '25px !important';
  const classes = agreementGlobalStyles();
  const { customerId, agreementId } = useParams<Params>();
  const { agreementInfoData, viewAgrData, amountDueResponse } =
    useContext(AgreementContext);
  const containerData = useContext(ContainerContext);
  const [showChargeOffActions, setshowChargeOffActions] = useState({
    requestChargeOffBtn: false,
    cancelChargeOffBtn: false,
    approveChargeOff: false,
  });

  const [requestChargeOffValues, setrequestChargeOffValues] = useState({
    normalRent: '',
    daysLate: '',
    pastDueTotal: '',
    carriedRent: '',
    carriedLateFee: '',
    remainingRent: '',
    chargeOffReason: '',
    suspenseAmount: '',
  });

  const [chargeOffReasons, setchargeOffReasons] = useState([
    {
      value: '',
      label: '',
    },
  ]);
  const [chargeOffPopups, setchargeOffPopups] = useState({
    reqCOpopup: false,
    reqCOconfirmPopup: false,
    COcancelledPopup: false,
    COapprovedPopup: false,
    SuspensePopup: false,
  });
  const [hiddenLoader, sethiddenLoader] = useState(false);
  const search = useLocation().search;
  const type = new URLSearchParams(search).get('type');
  const [errorMessagePopupOpen, seterrorMessagePopupOpen] = useState(false);
  const [somethingWentWrongOpen, setSomethingWentWrongOpen] = useState(false);
  const [manageAgrErrMessage, setmanageAgrErrMessage] = useState('');
  const [secondFactorUsingField, setsecondFactorUsingField] = useState('');
  const [secondFactorEnabled, setsecondFactorEnabled] = useState(false);
  const [SOTwoFactoreEnabledPay, setSOTwoFactoreEnabledPay] =
    useState<boolean>(false);
  const [CustomerPresent, setCustomerPresent] = useState<boolean>(false);
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState(false);
  const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState(false);
  const [currentRole, setcurrentRole] = useState('');
  const [moduleName, setmoduleName] = useState('');
  //  // PS_CO- 19 & 20
  useEffect(() => {
    if (agreementInfoData?.agreementWorkflow) {
      if (agreementInfoData?.agreementWorkflow?.isChargeOffRequested == 0) {
        setshowChargeOffActions({
          ...showChargeOffActions,
          requestChargeOffBtn: true,
        });
      }
      GettingAgreementOptions();
      if (
        agreementInfoData?.agreementWorkflow?.isChargeOffRequested == 1 &&
        agreementInfoData?.agreementWorkflow?.isChargeOffApproved == 0
      ) {
        setshowChargeOffActions({
          ...showChargeOffActions,
          cancelChargeOffBtn: true,
          approveChargeOff: true,
        });
      }
    }
    // PS_CO- 21 & 22
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      if (GetcurrentUser.status == 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);

        if (GetMenuDetails.status == 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRoleRes = GetMenuDetailsResponse.coworkerProfile.role;

          setcurrentRole(currentRoleRes);
        }
      }
    };
    if (containerData) {
      setcurrentRole(containerData.GetRole());
    } else {
      GettingRole();
    }
  }, []);

  useEffect(() => {
    if (amountDueResponse && type == 'CHRGOFF') {
      const GetNextPaymentData = amountDueResponse?.data;
      setrequestChargeOffValues({
        ...requestChargeOffValues,
        normalRent: GetNextPaymentData?.amountDueResponse[0]?.amountDue,
        daysLate:
          GetNextPaymentData?.amountDueResponse[0]?.agreementRateDetails
            ?.daysLate,
        pastDueTotal: GetNextPaymentData?.amountDueResponse[0]?.pastDueRent,
        carriedRent:
          GetNextPaymentData?.amountDueResponse[0]?.agreementRateDetails
            ?.carryRent,
        carriedLateFee:
          GetNextPaymentData?.amountDueResponse[0]?.agreementRateDetails
            ?.carriedLate,
        remainingRent:
          GetNextPaymentData?.amountDueResponse[0]?.agreementRateDetails
            ?.remainingAgreementCost,
        suspenseAmount:
          GetNextPaymentData?.amountDueResponse[0]?.agreementRateDetails
            ?.suspenseAmount,
      });
      setchargeOffPopups({ ...chargeOffPopups, reqCOpopup: true });
    }
  }, [amountDueResponse, paymentredirectChargeOff]);
  useEffect(() => {
    // PS_CO- 23
    if (TwoFactorCompleted && !TwoFactorCancelClick) {
      setTwoFactorCancelClick(false);
      setTwoFactorCompleted(false);
      setsecondFactorEnabled(false);
      TwoFactorCompletedFn();
    }
    // PS_CO- 24
    if (TwoFactorCancelClick && !TwoFactorCompleted) {
      setsecondFactorEnabled(false);
      setTwoFactorCancelClick(false);
      setTwoFactorCompleted(false);
    }
  }, [TwoFactorCompleted, TwoFactorCancelClick]);

  // PS_CO- 25
  const ChargeOffActions = (action: string) => {
    // debugger;
    if (action == 'RequestChargeOff') {
      setsecondFactorUsingField('RequestChargeOff');
      setsecondFactorEnabled(true);
      setmoduleName('RequestChargeOff');
    } else if (action == 'CancelChargeOff') {
      setsecondFactorUsingField('CancelChargeOff');
      setsecondFactorEnabled(true);
      setmoduleName('CancelChargeOff');
    } else if (action == 'ApproveChargeOff') {
      setsecondFactorUsingField('ApproveChargeOff');
      setsecondFactorEnabled(true);
      setmoduleName('ApproveChargeOff');
    }
  };
  const GettingAgreementOptions = async () => {
    const getAgrOptionsRes: any = await getCOAgreementOptions(
      customerId,
      window.sessionStorage.getItem('storeNumber')
    );
    if (getAgrOptionsRes.status == 200) {
      const agreementOptions = getAgrOptionsRes.data;
      const COreasons = agreementOptions?.chargeOffReasons?.map((ele: any) => {
        return { value: ele.referenceCode, label: ele.description };
      });
      COreasons.splice(0, 0, {
        value: '',
        label: 'Select',
      });
      setchargeOffReasons(COreasons);
    }
  };

  const TwoFactorCompletedFn = async () => {
    // PS_CO- 26
    if (secondFactorUsingField == 'RequestChargeOff') {
      sethiddenLoader(true);
      const GetNextPaymentReq = {
        agreementIdCustomerId: [
          {
            agreementId: Number(agreementId),
            customerId: Number(customerId)
          }
        ],
      };
      const GetNextPaymentRes = await getnextpaymentamount(GetNextPaymentReq);
      sethiddenLoader(false);
      if (GetNextPaymentRes.status == 200) {
        setchargeOffPopups({ ...chargeOffPopups, reqCOpopup: true });
        const GetNextPaymentData = GetNextPaymentRes?.data;
        setrequestChargeOffValues({
          ...requestChargeOffValues,
          normalRent: GetNextPaymentData?.amountDueResponse[0]?.amountDue,
          daysLate:
            GetNextPaymentData?.amountDueResponse[0]?.agreementRateDetails
              ?.daysLate,
          pastDueTotal: GetNextPaymentData?.amountDueResponse[0]?.pastDueRent,
          carriedRent:
            GetNextPaymentData?.amountDueResponse[0]?.agreementRateDetails
              ?.carryRent,
          carriedLateFee:
            GetNextPaymentData?.amountDueResponse[0]?.agreementRateDetails
              ?.carriedLate,
          remainingRent:
            GetNextPaymentData?.amountDueResponse[0]?.agreementRateDetails
              ?.remainingAgreementCost,
          suspenseAmount:
            GetNextPaymentData?.amountDueResponse[0]?.agreementRateDetails
              ?.suspenseAmount,
        });
      } else {
        setSomethingWentWrongOpen(true);
      }
    }
    // PS_CO- 27
    else if (secondFactorUsingField == 'CancelChargeOff') {
      sethiddenLoader(true);
      const UpdateAgrReq = {
        action: 'CCO',
        agreementId: Number(agreementId),
        isChargeOff: '1',
      };
      const UpdateAgrRes = await UpdateAgreement(UpdateAgrReq);
      sethiddenLoader(false);
      if (UpdateAgrRes.status == 200) {
        setchargeOffPopups({ ...chargeOffPopups, COcancelledPopup: true });
      } else if (UpdateAgrRes.status == 400) {
        setmanageAgrErrMessage(UpdateAgrRes?.data?.errors[0]?.error);
        seterrorMessagePopupOpen(true);
      } else {
        setSomethingWentWrongOpen(true);
      }
    }
    // PS_CO- 28
    else if (secondFactorUsingField == 'ApproveChargeOff') {
      sethiddenLoader(true);
      const UpdateAgrReq = {
        action: 'ACO',
        agreementId: Number(agreementId),
        isChargeOff: '1',
      };
      const UpdateAgrRes = await UpdateAgreement(UpdateAgrReq);
      sethiddenLoader(false);
      if (UpdateAgrRes.status == 200) {
        setchargeOffPopups({ ...chargeOffPopups, COapprovedPopup: true });
      } else if (UpdateAgrRes.status == 400) {
        setmanageAgrErrMessage(UpdateAgrRes?.data?.errors[0]?.error);
        seterrorMessagePopupOpen(true);
      } else {
        setSomethingWentWrongOpen(true);
      }
    }
  };

  // PS_CO- 29

  const ChargeOffOkClick = async () => {
    setchargeOffPopups({ ...chargeOffPopups, reqCOconfirmPopup: false });
    sethiddenLoader(true);
    const UpdateAgrReq = {
      action: 'RCO',
      agreementId: Number(agreementId),
      chargeOffReason: requestChargeOffValues.chargeOffReason,
      isChargeOff: '1',
    };
    const UpdateAgrRes = await UpdateAgreement(UpdateAgrReq);
    sethiddenLoader(false);
    if (UpdateAgrRes.status == 200) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${agreementId}`,
      });
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
      window.location.reload();
    } else if (UpdateAgrRes.status == 400) {
      setmanageAgrErrMessage(UpdateAgrRes?.data?.errors[0]?.error);
      seterrorMessagePopupOpen(true);
    } else {
      setSomethingWentWrongOpen(true);
    }
  };
  const ChargeOffClick = () => {
    if (Number(requestChargeOffValues.suspenseAmount) > 0) {
      setchargeOffPopups({
        ...chargeOffPopups,
        reqCOpopup: false,
        SuspensePopup: true,
      });
    } else {
      setchargeOffPopups({
        ...chargeOffPopups,
        reqCOpopup: false,
        reqCOconfirmPopup: true,
      });
    }
  };

  const RequestChargeOff = () => {
    return (
      <>
        <Grid style={{ marginLeft: '20px' }}>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Typography>Normal Rent</Typography>
              <Typography style={{ color: 'gray' }}>
                $ {requestChargeOffValues.normalRent}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography>Days Late</Typography>
              <Typography style={{ color: 'gray' }}>
                {requestChargeOffValues.daysLate}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography>Past Due Total</Typography>
              <Typography style={{ color: 'gray' }}>
                $ {requestChargeOffValues.pastDueTotal}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            style={{ marginBottom: '2rem', marginTop: '2rem' }}
            spacing={3}
          >
            <Grid item md={4}>
              <Typography>Carried Rent</Typography>
              <Typography style={{ color: 'gray' }}>
                $ {requestChargeOffValues.carriedRent}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography>Carried Late Fee</Typography>
              <Typography style={{ color: 'gray' }}>
                $ {requestChargeOffValues.carriedLateFee}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography>Remaining Rent</Typography>
              <Typography style={{ color: 'gray' }}>
                $ {requestChargeOffValues.remainingRent}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h4">Action</Typography>
          <Grid container>
            <Grid md={4}>
              <RACSelect
                inputLabel="Charge Off Reason"
                required={true}
                options={chargeOffReasons}
                defaultValue={requestChargeOffValues.chargeOffReason}
                onChange={(e: any) =>
                  setrequestChargeOffValues({
                    ...requestChargeOffValues,
                    chargeOffReason: e.target.value,
                  })
                }
                loading={chargeOffReasons?.length > 0 ? false : true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <RACButton
            className={classes.ReqCancelBtn}
            variant="outlined"
            color="primary"
            onClick={() => {
              setchargeOffPopups({ ...chargeOffPopups, reqCOpopup: false }),
                setrequestChargeOffValues({
                  ...requestChargeOffValues,
                  chargeOffReason: '',
                });
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.ReqchargeOffBtn}
            variant="contained"
            color="primary"
            onClick={() => {
              ChargeOffClick();
            }}
            disabled={requestChargeOffValues.chargeOffReason ? false : true}
          >
            Charge Off
          </RACButton>
        </Grid>
      </>
    );
  };
  const somethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSomethingWentWrongOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const errorMessagePopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.formLabel}>
            {manageAgrErrMessage}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              seterrorMessagePopupOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const confirmChargeOffPopup = () => {
    return (
      <>
        <Typography className={classes.reqCoConfirmContent}>
          Agreement # {agreementInfoData?.agreementInformation?.agreementNumber}{' '}
          is not eligible for switch out operation as it has been requested for
          charge off.
        </Typography>
        <div style={{ textAlign: 'center' }}>
          <RACButton
            className={classes.reqChargeOffConfirm}
            variant="contained"
            color="primary"
            onClick={() => {
              ChargeOffOkClick();
            }}
          >
            Ok
          </RACButton>
        </div>
      </>
    );
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const suspensePaymentRedirection = () => {
    setchargeOffPopups({ ...chargeOffPopups, SuspensePopup: false });
    setSOTwoFactoreEnabledPay(true);
  };
  const PaymentRedirection = (value: any) => {
    if (value == 'Y') {
      history.push(
        `/payment1/paymentinformation/${customerId}/0?agreementId=${agreementId}&type=CHRGOFF&cps=1`
      );
    } else {
      history.push(
        `/payment1/paymentinformation/${customerId}/0?agreementId=${agreementId}&type=CHRGOFF&cps=0`
      );
    }
  };
  const SuspensePopup = () => {
    return (
      <>
        <Typography className={classes.reqCoConfirmContent}>
          Agreement with suspense is not eligible for a charge off. Please
          transfer suspense ($ {requestChargeOffValues.suspenseAmount}) to
          another agreements or to Credit-On-Account and try again.
        </Typography>
        <div style={{ textAlign: 'center' }}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => {
              setchargeOffPopups({ ...chargeOffPopups, SuspensePopup: false });
              setrequestChargeOffValues({
                ...requestChargeOffValues,
                chargeOffReason: '',
              });
            }}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            size="large"
            onClick={suspensePaymentRedirection}
          >
            Yes
          </RACButton>
          {/* <RACButton
            className={classes.reqChargeOffConfirm}
            variant="contained"
            color="primary"
            onClick={() => {
              history.push({
                pathname: `/payment1/paymentinformation/${customerId}/0`,
              });
              const navEvent = new PopStateEvent('popstate');
              window.dispatchEvent(navEvent);
            }}
          >
            Ok
          </RACButton> */}
        </div>
      </>
    );
  };
  const cancelChargeOffPopup = () => {
    return (
      <>
        <div id="testimage" className={classes.ChargeOffSuccessImage}>
          <Successicon></Successicon>
        </div>
        <Typography
          variant="h4"
          style={{ marginBottom: '1rem', textAlign: 'center' }}
        >
          Charge Off Cancelled
        </Typography>
        <RACButton
          className={classes.COFOkBtn}
          variant="contained"
          color="primary"
          onClick={() => {
            setchargeOffPopups({ ...chargeOffPopups, COcancelledPopup: false });
            history.push({
              pathname: `/agreement/info/details/${customerId}/${agreementId}`,
            });
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
            window.location.reload();
          }}
        >
          Ok
        </RACButton>
      </>
    );
  };
  const approveChargeOffPopup = () => {
    return (
      <>
        <div id="testimage" className={classes.ChargeOffSuccessImage}>
          <Successicon></Successicon>
        </div>

        <Typography
          variant="h4"
          style={{ marginBottom: '1rem', textAlign: 'center' }}
        >
          Charge Off Approved
        </Typography>

        <RACButton
          className={classes.AOFOkBtn}
          variant="contained"
          color="primary"
          onClick={() => {
            setchargeOffPopups({ ...chargeOffPopups, COapprovedPopup: false });
            history.push({
              pathname: `/agreement/info/details/${customerId}/${agreementId}`,
            });
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
            window.location.reload();
          }}
        >
          Ok
        </RACButton>
      </>
    );
  };
  const CustomerPresentContent = () => {
    return (
      <React.Fragment>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.semiBold} ${classes.font16} ${classes.mt2}`}
          >
            Is the customer present?
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => PaymentRedirection('N')}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => PaymentRedirection('Y')}
          >
            Yes
          </RACButton>
        </Grid>
      </React.Fragment>
    );
  };
  const SOTwoFactorCompletedPay = (e: any) => {
    if (e) {
      setCustomerPresent(true);
    }
  };
  return (
    <>
      <Grid>
        {hiddenLoader === true ? (
          <Grid
            style={{
              position: 'fixed',
              background: '#f7f5f5',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              opacity: 0.6,
              zIndex: 2000,
              textAlign: 'center',
              margin: '0px 0px',
            }}
          >
            <Grid
              style={{
                display: 'block',
                position: 'fixed',
                zIndex: 9999999,
                top: '40%',
                right: '50%',
              }}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        {secondFactorEnabled ? (
          <SecondFactor
            setTwoFactorCancelClick={setTwoFactorCancelClick}
            setTwoFactorCompleted={setTwoFactorCompleted}
            moduleName={moduleName}
            currentRole={currentRole}
          />
        ) : null}
        {SOTwoFactoreEnabledPay ? (
          <SecondFactor
            setTwoFactorCompleted={SOTwoFactorCompletedPay}
            setTwoFactorCancelClick={(e) => {
              if (e) {
                setSOTwoFactoreEnabledPay(false);
              }
            }}
            closeSLAClicked={() => {
              setSOTwoFactoreEnabledPay(false);
            }}
            currentRole={currentRole}
            moduleName={'Manual Sign In RentalAgmt'}
          />
        ) : null}
        <RACModalCard
          isOpen={somethingWentWrongOpen}
          closeIcon={false}
          maxWidth="xs"
          borderRadius={modalCardRadius}
        >
          {somethingWentWrongPopup()}
        </RACModalCard>
        <RACModalCard
          isOpen={errorMessagePopupOpen}
          closeIcon={false}
          maxWidth="xs"
          borderRadius={modalCardRadius}
        >
          {errorMessagePopup()}
        </RACModalCard>

        <RACModalCard
          isOpen={CustomerPresent}
          borderRadius="15px"
          maxWidth="xs"
          closeIcon={true}
          onClose={() => {
            setCustomerPresent(false);
            setSOTwoFactoreEnabledPay(false);
          }}
          // eslint-disable-next-line react/no-children-prop
          children={CustomerPresentContent()}
        />
        {/* // Request charge off popup */}
        <RACModalCard
          borderRadius={modalCardRadius}
          TitleVariantClassName={classes.font}
          closeIcon={true}
          title="Request Charge Off"
          isOpen={chargeOffPopups.reqCOpopup}
          maxWidth="sm"
          onClose={() => {
            setchargeOffPopups({ ...chargeOffPopups, reqCOpopup: false });
            setrequestChargeOffValues({
              ...requestChargeOffValues,
              chargeOffReason: '',
            });
          }}
        >
          {RequestChargeOff()}
        </RACModalCard>
        {/* //Request charge off confirmation popup */}
        <RACModalCard
          borderRadius={modalCardRadius}
          isOpen={chargeOffPopups.reqCOconfirmPopup}
          maxWidth="xs"
        >
          {confirmChargeOffPopup()}
        </RACModalCard>
        <RACModalCard
          borderRadius={modalCardRadius}
          isOpen={chargeOffPopups.SuspensePopup}
          maxWidth="xs"
          closeIcon={true}
          onClose={() => {
            setchargeOffPopups({
              ...chargeOffPopups,
              SuspensePopup: false,
            });
            setrequestChargeOffValues({
              ...requestChargeOffValues,
              chargeOffReason: '',
            });
          }}
        >
          {SuspensePopup()}
        </RACModalCard>

        {/* Charge Off Cancel Popup */}
        <RACModalCard
          borderRadius={modalCardRadius}
          isOpen={chargeOffPopups.COcancelledPopup}
          maxWidth="xs"
          closeIcon={true}
          onClose={() => {
            setchargeOffPopups({ ...chargeOffPopups, COcancelledPopup: false });
            history.push({
              pathname: `/agreement/info/details/${customerId}/${agreementId}`,
            });
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
            window.location.reload();
          }}
        >
          {cancelChargeOffPopup()}
        </RACModalCard>

        {/* Charge Off Approved popup */}
        <RACModalCard
          borderRadius={modalCardRadius}
          isOpen={chargeOffPopups.COapprovedPopup}
          maxWidth="xs"
          closeIcon={true}
          onClose={() => {
            setchargeOffPopups({ ...chargeOffPopups, COapprovedPopup: false });
            history.push({
              pathname: `/agreement/info/details/${customerId}/${agreementId}`,
            });
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
            window.location.reload();
          }}
        >
          {approveChargeOffPopup()}
        </RACModalCard>
      </Grid>
      
      {showChargeOffActions.requestChargeOffBtn ? (
        <>
          <RACButton
            className={classes.RequestChargeOffBtn}
            variant="contained"
            color="primary"
            disabled={
              viewAgrData?.data?.agreementInformation?.isExchanged == 1 ? true : 
              viewAgrData?.data?.agreementWorkflow?.isTransferInitiated == 1
                ? true
                : false
            }
            
            onClick={() => {
              ChargeOffActions('RequestChargeOff');
            }}
          >
            Request Charge Off
          </RACButton>
        </>
      ) : null}
      {showChargeOffActions.cancelChargeOffBtn ? (
        <>
          <RACButton
            className={classes.CancelChargeOffBtn}
            variant="contained"
            disabled={
              viewAgrData?.data?.agreementWorkflow?.isTransferInitiated == 1
                ? true
                : false
            }
            color="primary"
            onClick={() => {
              ChargeOffActions('CancelChargeOff');
            }}
          >
            Cancel Charge Off
          </RACButton>
        </>
      ) : null}
      {showChargeOffActions.approveChargeOff ? (
        <>
          <RACButton
            className={classes.AcceptChargeOffBtn}
            variant="contained"
            disabled={
              viewAgrData?.data?.agreementWorkflow?.isTransferInitiated == 1
                ? true
                : false
            }
            color="primary"
            onClick={() => {
              ChargeOffActions('ApproveChargeOff');
            }}
          >
            Approve Charge Off
          </RACButton>
        </>
      ) : null}
    </>
  );
}
