/* eslint-disable no-console */
/* eslint-disable no-useless-escape */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useState } from 'react';
import {
  Grid,
  Typography,
  RACModalCard,
  RACButton,
  RACRadio,
  RACTextbox,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../JSstyles/agreementGlobalStyles';
import { getPrintDocument } from '../../api/user';

interface SendDeliveryReceiptInterface {
  customerId: string;
  customerName: string;
  customerFirstName: string;
  customerLastName: string;
  email: string;
  phoneNumber: string;
  agreementId: string;
  sendDeliveryReceiptCompletedFn: any;
  sendDeliveryReceiptCancelledFn: any;
}
export default function SendDeliveryReceipt(
  props: SendDeliveryReceiptInterface
) {
  const {
    customerId,
    customerName,
    customerFirstName,
    customerLastName,
    email,
    phoneNumber,
    agreementId,
    sendDeliveryReceiptCompletedFn,
    sendDeliveryReceiptCancelledFn,
  } = props;

  const classes = agreementGlobalStyles();
  const [sendDeliveryReceiptInput, setSendDeliveryReceiptInput] = useState({
    isEmailSelected: true,
    isTextSelected: false,
    emailTextValue: email,
    phoneTextValue: phoneNumber,
  });
  const [isValidEmailError, setIsValidEmailError] = useState(false);
  const [isValidPhoneNumError, setIsValidPhoneNumError] = useState(false);

  const SendDeliveryReceiptTxtOnChange = (field: any, value: any) => {
    if (field == 'Email') {
      setIsValidEmailError(false);
      setSendDeliveryReceiptInput({
        ...sendDeliveryReceiptInput,
        emailTextValue: value,
      });
    } else if (field == 'Text') {
      setIsValidPhoneNumError(false);
      setSendDeliveryReceiptInput({
        ...sendDeliveryReceiptInput,
        phoneTextValue: value,
      });
    }
  };

  const SendDeliveryReceiptRadioOnChange = (field: any) => {
    if (field == 'Email') {
      setIsValidEmailError(false);
      if (!sendDeliveryReceiptInput.isEmailSelected) {
        setSendDeliveryReceiptInput({
          ...sendDeliveryReceiptInput,
          isEmailSelected: true,
          isTextSelected: false,
        });
      } else {
        setSendDeliveryReceiptInput({
          ...sendDeliveryReceiptInput,
          isEmailSelected: false,
          isTextSelected: false,
        });
      }
    } else if (field == 'Text') {
      setIsValidPhoneNumError(false);
      if (!sendDeliveryReceiptInput.isTextSelected) {
        setSendDeliveryReceiptInput({
          ...sendDeliveryReceiptInput,
          isTextSelected: true,
          isEmailSelected: false,
        });
      } else {
        setSendDeliveryReceiptInput({
          ...sendDeliveryReceiptInput,
          isTextSelected: false,
          isEmailSelected: false,
        });
      }
    }
  };

  const sendDeliveryReceiptApiCall = () => {
    const sendDeliveryReceiptPayload = {
      identifier: String(agreementId),
      identifierType: 'AGR',
      customerName: customerName,
      customerFirstName: customerFirstName || '',
      customerLastName: customerLastName || '',
      customerId: customerId,
      documentStatus: 'signed',
      emailId: sendDeliveryReceiptInput.emailTextValue,
      actionType: sendDeliveryReceiptInput.isEmailSelected ? 'email' : 'text',
      digitalDeliveryType: 'RTOdigiSign',
      customerPhoneNumber: !sendDeliveryReceiptInput.isEmailSelected
        ? sendDeliveryReceiptInput.phoneTextValue.replace(/\D/g, '')
        : null,
    };
    console.log('sendDeliveryReceiptPayload', sendDeliveryReceiptPayload);
    getPrintDocument(sendDeliveryReceiptPayload);
  };

  const sendClick = () => {
    if (sendDeliveryReceiptInput.isEmailSelected) {
      const emailRegex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (emailRegex.test(sendDeliveryReceiptInput.emailTextValue)) {
        sendDeliveryReceiptApiCall();
        sendDeliveryReceiptCompletedFn();
      } else {
        setIsValidEmailError(true);
      }
    } else if (sendDeliveryReceiptInput.isTextSelected) {
      sendDeliveryReceiptApiCall();
      sendDeliveryReceiptCompletedFn();
    }
  };
  function sendBtnDisableValidation() {
    if (sendDeliveryReceiptInput.isEmailSelected) {
      if (sendDeliveryReceiptInput.emailTextValue.trim() == '') return true;
      else false;
    } else {
      if (sendDeliveryReceiptInput.phoneTextValue.trim().length < 10)
        return true;
      else false;
    }
  }
  const phoneNumberFormat = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        '($1) $2-$3'
      );
  };
  const SendDeliveryReceiptPopup = () => {
    return (
      <Grid className={classes.p3}>
        <Grid container>
          <Grid item md={12} className={classes.mb3}>
            <Typography
              component="label"
              className={`${classes.formLabel} ${classes.w100} ${classes.fontBold}`}
            >
              How do you want the delivery receipt to be shared?
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.mb3}>
            <RACRadio
              value={sendDeliveryReceiptInput.isEmailSelected}
              checked={sendDeliveryReceiptInput.isEmailSelected}
              onChange={() => SendDeliveryReceiptRadioOnChange('Email')}
              label="Email"
            />
            <RACRadio
              value={sendDeliveryReceiptInput.isTextSelected}
              checked={sendDeliveryReceiptInput.isTextSelected}
              onChange={() => SendDeliveryReceiptRadioOnChange('Text')}
              label="Text"
              disabled={true}
            />
          </Grid>
          <Grid item md={6}>
            {sendDeliveryReceiptInput.isEmailSelected ? (
              <div>
                <RACTextbox
                  inputlabel="Email"
                  inputLabelClassname="form-label"
                  name="Email"
                  value={sendDeliveryReceiptInput.emailTextValue}
                  OnChange={(e) =>
                    SendDeliveryReceiptTxtOnChange('Email', e.target.value)
                  }
                  isCurrency={false}
                  type="email"
                />
                {isValidEmailError ? (
                  <label
                    style={{
                      color: 'red',
                      fontSize: 'small',
                      marginLeft: '3%',
                    }}
                  >
                    Please enter valid Email
                  </label>
                ) : null}
              </div>
            ) : (
              <div>
                <RACTextbox
                  inputlabel="Phone number"
                  inputLabelClassname="form-label"
                  name="Phone number"
                  value={phoneNumberFormat(
                    sendDeliveryReceiptInput.phoneTextValue
                  )}
                  OnChange={(e) =>
                    SendDeliveryReceiptTxtOnChange('Text', e.target.value)
                  }
                  isCurrency={false}
                  type="text"
                  maxlength={10}
                />
                {isValidPhoneNumError ? (
                  <label
                    style={{
                      color: 'red',
                      fontSize: 'small',
                      marginLeft: '3%',
                    }}
                  >
                    Please enter valid phone number
                  </label>
                ) : null}
              </div>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textRight}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.mx1}
            name="SendAgreementCancel"
            onClick={() => {
              sendDeliveryReceiptCancelledFn();
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
            name=""
            onClick={() => sendClick()}
            disabled={sendBtnDisableValidation()}
          >
            Send
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  return (
    <RACModalCard
      title="Send Delivery Receipt"
      isOpen={true}
      borderRadius={'25px !important'}
      closeIcon={true}
      maxWidth="sm"
      onClose={() => {
        sendDeliveryReceiptCancelledFn();
      }}
    >
      {SendDeliveryReceiptPopup()}
    </RACModalCard>
  );
}
