/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { createContext } from 'react';
import { Router } from 'react-router';
import {
  RACThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@rentacenter/racstrap';
import { ErrorBoundary } from '../components/Rental/ErrorBoundary/ErrorBoundary';
import { InContainerAuthProvider } from '../context/auth/InContainerAuthProvider';
import { StandaloneAuthProvider } from '../context/auth/StandaloneAuthProvider';
import { AuthGuard } from '../components/Rental/AuthGuard/AuthGuard';
import { AgreementContextProvider } from '../context/AgreementContext';
import { SwitchOutAgreementContextProvider } from '../context/SwitchOutAgreementContext';
import { ReinstateAgreementContextProvider } from '../context/ReinstatementAgreementContext';
import { CustomerOrderAgreementContextProvider } from '../context/CustomerOrderAgreementContext';
import { FeatureFlagContextProvider } from '../context/FeatureFlagContext';
import { CustomPropInterface } from '../../src/index';
import { OriginContextProvider } from '../context/OriginContext';
import { LoanerContextProvider } from '../context/LoanerAgreementContext';
import { PromoAgreementContextProvider } from '../context/PromoAgreementContext';

const History = require('history');
interface Props {
  history: typeof History;
  customProps?: CustomPropInterface;
}

export const appTestId = 'appTestId';

// added production prefix for jss
const generateClassName = createGenerateClassName({
  //disableGlobal: true,
  //productionPrefix: 'racprodprefix',
  seed: 'RAC-AGREEMENT-',
});

export const ContainerContext = createContext<CustomPropInterface | undefined>(
  undefined
);

export const App = ({ history, customProps }: Props) => {
  const { isRenderedByContainer } = window;

  return (
    <div data-testid={appTestId}>
      <StylesProvider generateClassName={generateClassName}>
        <RACThemeProvider>
          <ContainerContext.Provider value={customProps}>
            <LoanerContextProvider>
              <PromoAgreementContextProvider>
                <CustomerOrderAgreementContextProvider>
                  <ReinstateAgreementContextProvider>
                    <SwitchOutAgreementContextProvider>
                      <AgreementContextProvider>
                        <FeatureFlagContextProvider>
                          <div className="">
                            <ErrorBoundary>
                              <Router history={history}>
                                <OriginContextProvider>
                                  {isRenderedByContainer ? (
                                    <InContainerAuthProvider>
                                      <AuthGuard />
                                    </InContainerAuthProvider>
                                  ) : (
                                    <StandaloneAuthProvider>
                                      <AuthGuard />
                                    </StandaloneAuthProvider>
                                  )}
                                </OriginContextProvider>
                              </Router>
                            </ErrorBoundary>
                          </div>
                        </FeatureFlagContextProvider>
                      </AgreementContextProvider>
                    </SwitchOutAgreementContextProvider>
                  </ReinstateAgreementContextProvider>
                </CustomerOrderAgreementContextProvider>
              </PromoAgreementContextProvider>
            </LoanerContextProvider>
          </ContainerContext.Provider>
        </RACThemeProvider>
      </StylesProvider>
    </div>
  );
};
