/* eslint-disable prettier/prettier */
import React from 'react';
// import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
import { ReactComponent as AlertIcon } from '../../../assets/images/No-records.svg';

import { Typography, Grid, CircularProgress } from '@rentacenter/racstrap';
const ExceptionController = (props: any): any => {
  const exceptionDecider = props.throwExceptionDecider;
  const classes = props.throwStyle;
  if (exceptionDecider == 'No Records') {
    return (
      <Grid>
        <Typography className={`${classes.title} ${classes.mt3}`}>
          Search Results
        </Typography>
        <Grid className={`${classes.textCenter} ${classes.mt5}`}>
          <AlertIcon />
          <Typography className={`${classes.subTitle} ${classes.mb3}`}>
            No Customer Order Item Found
          </Typography>
        </Grid>
      </Grid>
    );
  } else if (exceptionDecider == 'No Item') {
    return (
      <Grid>
        <Typography className={`${classes.title} ${classes.mt3}`}>
          Search Results
        </Typography>
        <Grid className={`${classes.textCenter} ${classes.mt5}`}>
          <AlertIcon />
          <Typography className={`${classes.subTitle} ${classes.mb3}`}>
            No Items to Add
          </Typography>
        </Grid>
      </Grid>
    );
  } else if (exceptionDecider == 'Went Wrong') {
    return (
      <Grid>
        <Typography className={`${classes.title} ${classes.mt3}`}>
          Search Results
        </Typography>
        <Grid className={`${classes.textCenter} ${classes.mt5}`}>
          <AlertIcon />
          <Typography className={`${classes.subTitle} ${classes.mb3}`}>
            Something Went Wrong
          </Typography>
        </Grid>
      </Grid>
    );
  } else if (exceptionDecider == 'Data Delay') {
    return (
      <Grid>
        <Typography className={`${classes.title} ${classes.mt3}`}>
          Search Results
        </Typography>
        <Grid className={classes.GridLoader}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
};

export default ExceptionController;
