/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const twoFactorStyles = () => {
  const useClasses = makeStyles((theme) => ({
    textCenter: {
      textAlign: 'center',
    },
    logoResponsive: {},
    spacerMargin: {
      margin: theme.typography.pxToRem(8),
    },
    spacerMT1: {
      marginTop: theme.typography.pxToRem(4),
    },
    racLoginAlert: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    formLabel: {
      marginBottom: '0.2rem',
      color: '#111111',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    floatRight: {
      float: 'right',
    },
    spacerMT5: {
      marginTop: theme.typography.pxToRem(48),
    },
    spacerMB3: {
      marginBottom: theme.typography.pxToRem(24),
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    spacerME2: {
      marginRight: theme.typography.pxToRem(16),
    },
    racErrorIcon: {
      width: '50px',
    },
    racErrorFunction: {
      fontFamily: 'OpenSans-semibold',
      marginBottom: '20px',
      marginTop: '10px',
    },
    racPinContainer: {
      margin: '0 auto',
      width: '85%',
    },
    racPinLabel: {
      fontFamily: 'OpenSans-semibold',
      color: `${RACCOLOR.RICH_BLACK}`,
      marginLeft: '0.5rem',
      marginBottom: '3px',
    },
    racPinError: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
      marginTop: '5px',
      marginLeft: '0.5rem',
      fontFamily: 'OpenSans-semibold',
    },
    racLoader: {
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, 85%)',
    },
    paddingButtons: {
      paddingRight: '45px',
    },
  }));
  return useClasses();
};
