import React from 'react';
import { Grid, Card, CardContent, Typography } from '@rentacenter/racstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { agreementGlobalStyles } from '../../JSstyles/agreementGlobalStyles';

interface PaymentScheduleCardProps {
  Scheduloading: boolean;
  ScheduleValue: string;
  ScheduleName: string;
  TermValue: string;
  ScheduleSelected: boolean;
  ScheduleClickFn: any;
  EditDisableMode: boolean;
}
export default function PaymentScheduleCard(props: PaymentScheduleCardProps) {
  const {
    Scheduloading,
    ScheduleValue,
    ScheduleName,
    TermValue,
    ScheduleSelected,
    ScheduleClickFn,
    EditDisableMode,
  } = props;
  const classes = agreementGlobalStyles();

  return (
    <Grid
      item
      sm={6}
      md={3}
      className={classes.mb2}
      style={{ cursor: !EditDisableMode ? 'pointer' : 'wait' }}
    >
      <Card
        className={
          ScheduleSelected
            ? `${classes.card} ${classes.payScheduleHighlight}`
            : `${classes.card}`
        }
      >
        <CardContent>
          <Grid
            container
            alignItems="center"
            onClick={() => {
              !EditDisableMode ? ScheduleClickFn(ScheduleName) : null;
            }}
          >
            <Grid item md={6}>
              <Typography
                className={
                  ScheduleSelected
                    ? classes.paySchedulePeriodHighlight
                    : classes.paySchedulePeriodNotHighlight
                }
                variant="body1"
              >
                {ScheduleName}
              </Typography>
            </Grid>
            <Grid item md={6}>
              {!Scheduloading ? (
                <>
                  <Typography
                    className={
                      ScheduleSelected
                        ? `${classes.payScheduleRateHighlight} ${classes.payAmount}`
                        : `${classes.payAmount}`
                    }
                  >
                    $ {ScheduleValue}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={
                      ScheduleSelected
                        ? `${classes.payScheduleTermHighlight} ${classes.termAlign}`
                        : `${classes.termAlign}`
                    }
                  >
                    Term
                    <Typography
                      className={
                        ScheduleSelected
                          ? `${classes.payScheduleValueHighlight} ${classes.paymentTerm}`
                          : `${classes.paymentTerm}`
                      }
                      variant="caption"
                    >
                      {TermValue}
                    </Typography>
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className={`${classes.payAmount}`}>
                    <Skeleton
                      count={1}
                      baseColor={ScheduleSelected ? '#2279fd' : '#FFFFFF'}
                      highlightColor="#e7e7e7"
                    />
                  </Typography>
                  <Typography
                    variant="body1"
                    className={`${classes.termAlign}`}
                  >
                    <Skeleton
                      count={1}
                      baseColor={ScheduleSelected ? '#2279fd' : '#FFFFFF'}
                      highlightColor="#e7e7e7"
                    />
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
