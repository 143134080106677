import React, { createContext, useState } from 'react';

export const PromoAgreementContext = createContext<any>('');
export function PromoAgreementContextProvider(props: any) {
  const [PromoAgrWholeResponse, setPromoAgrWholeResponse] = useState('');
  return (
    <div>
      <PromoAgreementContext.Provider
        value={{ PromoAgrWholeResponse, setPromoAgrWholeResponse }}
      >
        {props.children}
      </PromoAgreementContext.Provider>
    </div>
  );
}
