/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { createContext, useState, useEffect } from 'react';
export const CustomerOrderAgreementContext = createContext<any>('');
export function CustomerOrderAgreementContextProvider(props: any) {
  const [CustomerInfo, setCustomerInfo] = useState();
  const [selectedInvNumber, setselectedInvNumber] = useState();
  const [selectedData, setselectedData] = useState();
  const [totalTires, setTotalTires] = useState<any>()
  const [differentModalPopup, setDifferentModalPopup] = useState(false)

  const [searchCriteria, setsearchCriteria] = useState({
    itemNo: '',
    serialNo: '',
    bracket: '',
    brand: '',
    model: '',
    conditionId: null,
    departments: '',
    subDepartments: '',
    packageTypeval: '',
  });
  const [agreementOptions, setagreementOptions] = useState();
  const [AgreementId, setAgreementId] = useState('');
  const [itemArrayDataSwitchOut, setitemArrayData] = useState([]);
  const [entireAgrInfoResponse, setentireAgrInfoResponse] = useState();
  const [numberOfInventory, setnumberOfInventory] = useState();
  const [switchOutData, setSwitchOutData] = useState();
  const [wholeAgrInfoResponse, setwholeAgrInfoResponse] = useState();
  const [searchResultLoder, setsearchResultLoder] = useState(false);
  const [searchResultNoRecords, setsearchResultNoRecords] = useState(false)
  const [searchResultEnable, setsearchResultEnable] = useState(false)
  const [customerInformation, setcustomerInformation] = useState('')

  const [agreementCreateStatus, setagreementCreateStatus] = useState({
    agreementCreatedStatus: false,
  });
  const [agreementVoidStatus, setagreementVoidStatus] = useState({
    agreementVoidedStatus: false,
  });
  const [agreementDetailsData, setAgreementDetailsData] = useState();
  const [searchResult, setsearchResult] = useState();
  const [newlyAddedItem, setnewlyAddedItem] = useState();
  const [addedItem, setaddedItem] = useState();
  const [itemArrayData, setitemArray] = useState();
  const [PromoCode, setPromoCode] = useState('');
  const [copyArrayData, setSwitchoutcopyArrayData] = useState();
  const [depPercentage, setdepPercentage] = useState('0.00');
  const [containDepartment, setcontainDepartment] = useState();
  const [containSubDepartment, setcontainSubDepartment] = useState();
  const [containCondition, setcontainCondition] = useState();
  const [pendingSOAgr, setpendingSOAgr] = useState(false);
  const [crossedItemSearch, setcrossedItemSearch] = useState(false);
  const [pendingSOpreInvData, setpendingSOpreInvData] = useState();
  const [deliveryMethodData, setdeliveryMethodData] = useState();
  const [customerSignData, setcustomerSignData] = useState();
  const [switchoutagreementnum, setswitchoutagreementnum] = useState();
  const [isswitchoutCancelled, setisswitchoutCancelled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [is500PopupOpened, setIs500PopupOpened] = useState(false);
  const [is400PopupOpened, setIs400PopupOpened] = useState(false);
  const [autoPayStatus, setautoPayStatus] = useState('0');
  //adding toggle state to handle the TB - search Nagaarjun starts here
  const [toggleTB, setToggleTB] = useState(false);
  const searchItemObj = {
    modelValue: '',
    year: '',
    make: '',
    model: '',
    style: '',
    size: ''
  }
  const defalutValue = {
    'value': '0',
    'label': 'select'
  }
  const [searchItem, setsearchItem] = useState(searchItemObj);
  const optionsArrayObject = {
    year: [defalutValue],
    make: [defalutValue],
    model: [defalutValue],
    style: [defalutValue],
    size: [defalutValue]
  }

  const [optionsArrayTB, setOptionsArrayTB] = useState(optionsArrayObject);
  const [modelNumberArray, setModelNumberArray] = useState([]);
  const [addedItems, setAddedItems] = useState([]);

  const [isNextPopupOpened, setIsNextPopupOpened] = useState(false);
  const [countOfTire, setCountOfTire] = useState("0");

  //Nagaarjun Ended here
  const [successDropdownValues, setsuccessDropdownValues]: any = useState({
    Schedule: '',
    Term: '',
    Range: '',
    PromoCode: '',
    OptionalService: '',
  });
  useEffect(() => {
    console.log('selected stored data', selectedData);
  }, [selectedData]);
  const clearSearchCriteria = () => {
    console.log("The function cleared");
    setsearchItem(searchItemObj)
  }

  const [salesleadFitemnetid, setsalesleadFitemnetid] = useState();
  const [salesinstallerName, setsalesinstallerName] = useState();
  const [salesinstalldate, setsalesinstalldate] = useState();
  const [salesTimeSlot, setSalesTimeSlot] = useState();


  const [itemSearchTableContent, setItemSearchTableContent] = useState([
  ]);

  const [yearsArray, setYearsArray] = useState<any>([
    {
      'value': '0',
      'label': 'Select'
    }
  ]);

  const [makeArray, setMakeArray] = useState([
    {
      'value': '0',
      'label': 'Select'
    }
  ]);
  const [modelArray, setModelArray] = useState([
    {
      'value': '0',
      'label': 'Select'
    }
  ]);
  const [styleArray, setStyleArray] = useState([
    {
      'value': '0',
      'label': 'Select'
    }
  ]);
  const [sizeArray, setSizeArray] = useState([
    {
      'value': '0',
      'label': 'Select'
    }
  ]);


  //state variable to store the selected values by the user in the dropdowns;
  const [year, setYear] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [style, setStyle] = useState("");
  const [size, setSize] = useState("");
  const [isToggleDisableAlert, setIsToggleDisableAlert] = useState(false);
  const [modelAndImageArray, setModelAndImageArray] = useState([]);
  const [modelNumber, setModelNumber] = useState("");
  const [maxQtyReached, setmaxQtyReached] = useState(false)
  const [tagRate, setTagRate] = useState("");
  const [tagTerm, setTagterm] = useState("");
  const [totalCashPrice, setTotalCashPrice] = useState("");
  const [agreementTotal, setagreementTotal] = useState('');
  const [agreementRate, setAgreementRate] = useState("");
  const [agreementTerm, setAgreementTerm] = useState("");


  //state varibale for sales Lead 

  const [salesLeadYear, setsalesLeadYear] = useState("");
  const [salesLeadMake, setsalesLeadMake] = useState("");
  const [salesLeadModel, setsalesLeadModel] = useState("");
  const [salesLeadstyle, setsalesLeadstyle] = useState("");
  const [fitmentId, setfitmentId] = useState("");
  const [saleleadID, setSalesLeadId] = useState("");
  const [saleLeadModelNumber, setsaleLeadModelNumber] = useState("")




  return (
    <div>
      <CustomerOrderAgreementContext.Provider
        value={{
          agreementRate, setAgreementRate,
          agreementTerm, setAgreementTerm,
          totalCashPrice, setTotalCashPrice,
          agreementTotal, setagreementTotal,
          tagTerm, setTagterm,
          tagRate, setTagRate,
          differentModalPopup, setDifferentModalPopup,
          AgreementId,
          setAgreementId,
          searchResultEnable,
          setsearchResultEnable,
          customerInformation,
          setcustomerInformation,
          agreementOptions,
          setagreementOptions,
          CustomerInfo,
          setCustomerInfo,
          selectedInvNumber,
          setselectedInvNumber,
          searchCriteria,
          itemArrayDataSwitchOut,
          numberOfInventory,
          setsearchCriteria,
          setitemArrayData,
          setnumberOfInventory,
          switchOutData,
          setSwitchOutData,
          agreementCreateStatus,
          setagreementCreateStatus,
          agreementDetailsData,
          setAgreementDetailsData,
          newlyAddedItem,
          setPromoCode,
          PromoCode,
          setnewlyAddedItem,
          setsearchResult,
          searchResult,
          setaddedItem,
          addedItem,
          setitemArray,
          itemArrayData,
          setSwitchoutcopyArrayData,
          copyArrayData,
          setentireAgrInfoResponse,
          entireAgrInfoResponse,
          depPercentage,
          setdepPercentage,
          containDepartment,
          setcontainDepartment,
          containSubDepartment,
          setcontainSubDepartment,
          containCondition,
          setcontainCondition,
          pendingSOAgr,
          setpendingSOAgr,
          pendingSOpreInvData,
          setpendingSOpreInvData,
          deliveryMethodData,
          setdeliveryMethodData,
          customerSignData,
          setcustomerSignData,
          setswitchoutagreementnum,
          switchoutagreementnum,
          isswitchoutCancelled,
          setisswitchoutCancelled,
          autoPayStatus,
          setautoPayStatus,
          setselectedData,
          selectedData,
          setwholeAgrInfoResponse,
          wholeAgrInfoResponse,
          setcrossedItemSearch,
          crossedItemSearch,
          agreementVoidStatus,
          setagreementVoidStatus,
          successDropdownValues,
          setsuccessDropdownValues,
          toggleTB, setToggleTB,
          searchItem, setsearchItem,
          optionsArrayTB, setOptionsArrayTB,
          searchItemObj,
          clearSearchCriteria,
          modelNumberArray, setModelNumberArray,
          addedItems, setAddedItems,
          itemSearchTableContent, setItemSearchTableContent,
          yearsArray, setYearsArray,
          makeArray, setMakeArray,
          styleArray, setStyleArray,
          modelArray, setModelArray,
          sizeArray, setSizeArray,
          year, setYear,
          make, setMake,
          model, setModel,
          style, setStyle,
          size, setSize,
          totalTires, setTotalTires,
          errorMessage, setErrorMessage,
          is500PopupOpened, setIs500PopupOpened,
          is400PopupOpened, setIs400PopupOpened,
          isToggleDisableAlert, setIsToggleDisableAlert,
          isNextPopupOpened, setIsNextPopupOpened,
          countOfTire, setCountOfTire,
          modelAndImageArray, setModelAndImageArray,
          modelNumber, setModelNumber,
          searchResultLoder, setsearchResultLoder,
          searchResultNoRecords, setsearchResultNoRecords,
          maxQtyReached, setmaxQtyReached,
          salesleadFitemnetid, setsalesleadFitemnetid,
          salesinstallerName, setsalesinstallerName,
          salesinstalldate, setsalesinstalldate,
          salesTimeSlot, setSalesTimeSlot
        }}
      >
        {props.children}
      </CustomerOrderAgreementContext.Provider>
    </div>
  );
}
