/* eslint-disable react/jsx-key */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import {
  Grid,
  Typography,
  RACTable,
  Card,
  CardContent,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { agreementInfoStyles } from '../../../JSstyles/agreementInfoStyles';

interface InstallationDetailsInterface {
  InstallerName: string;
  phoneNumber: string;
  emailId: string;
  address: string;
  scheduledDate: string;
  time: string;
  status: string;
  tireOrderDescripton?: string;
}

export default function InstallationDetails(
  props: InstallationDetailsInterface
) {
  const classes = agreementGlobalStyles();
  const agrGridClassName = agreementInfoStyles();
  const InstallationDetailsHeaderArray = [
    'Installer Name',
    'Phone #',
    'Email ID',
    'Address',
    'Scheduled Date',
    'Time',
    'Installation Status',
    'Order Status'
  ];
  const {
    InstallerName,
    phoneNumber,
    emailId,
    address,
    scheduledDate,
    time,
    status,
    tireOrderDescripton,
  } = props;
  const hideLengthyText = (Text: any, MaxLength: number, Mask: any) => {
    return Text.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
  };
  const InstallationDetailsBodyArray = [
    {
      InstallerName: InstallerName,
      phoneNumber: phoneNumber.replace(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        '($1) $2-$3'
      ),
      emailId: emailId,
      address: address,
      scheduledDate: formatDate(scheduledDate),
      time: time,
      status: status,
      tireOrderDescripton: tireOrderDescripton
    }
  ];
  const buildHeader = () => (
    <>
      {InstallationDetailsHeaderArray.map((value: any, index: any) => (
        <RACTableCell key={index}>{value}</RACTableCell>
      ))}
    </>
  );
  // const buildBody = () => (
  //     <>
  //         <RACTableRow style={{ color: 'white' }}>
  //             {InstallationDetailsBodyArray.map((value, index) => (
  //                 <RACTableCell key={index} title={value}>{value}</RACTableCell>
  //             ))}
  //         </RACTableRow>
  //     </>
  // );
  const buildBody = () => (
    <>
      {InstallationDetailsBodyArray.map((obj) => {
        return (
          <RACTableRow style={{ color: 'white' }}>
            <RACTableCell >{obj.InstallerName ? obj.InstallerName : '-'}</RACTableCell>
            <RACTableCell >{obj.phoneNumber ? obj.phoneNumber : '-'}</RACTableCell>
            <RACTableCell >{obj.emailId ? obj.emailId : '-'}</RACTableCell>
            <RACTableCell title={obj.address}>{hideLengthyText(obj.address, 20, '...')}</RACTableCell>
            <RACTableCell >{obj.scheduledDate ? obj.scheduledDate : '-'}</RACTableCell>
            <RACTableCell >{obj.time ? obj.time : '-'}</RACTableCell>
            <RACTableCell >{obj.status ? obj.status : '-'}</RACTableCell>
            <RACTableCell >{obj.tireOrderDescripton ? obj.tireOrderDescripton : '-'}</RACTableCell>
          </RACTableRow>
        )
      })}
    </>
  )


  function formatDate(inputDate) {
    try {
      const parts = inputDate?.split('-');

      if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];

        return `${month}/${day}/${year}`;
      }
      return inputDate;
    } catch {
      return '';
    }
  }
  return (
    <>
      <Grid md={12} className={classes.mb2}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography className={classes.title} variant="h6">
              Installation Details
            </Typography>
          </Grid>
        </Grid>
        <Card className={classes.card}>
          <CardContent>
            <RACTable
              className={`${agrGridClassName.racGrid} ${agrGridClassName.racAgrViewGrid}`}
              renderTableHead={buildHeader}
              renderTableContent={buildBody}
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
