export enum AppRoute {
  Index = '/',
  CreateRentalAgreement = '/agreement/rental',
  ItemSearch = '/agreement/rental/itemsearch/:customerId/:agreementId?',
  AgreementDetails = '/agreement/rental/details/:customerId/:agreementId?',
  InitialPayment = '/agreement/rental/initialpayment/:customerId/:agreementId?',
  DeliveryMethod = '/agreement/rental/delivery/:customerId/:agreementId',
  AgreementView = '/agreement/info',
  AgreementInformation = '/agreement/info/details/:customerId/:agreementId?',
  DeliveryMethodView = '/agreement/info/delivery/:customerId/:agreementId?',
  AgreementActivity = '/agreement/info/activity/:customerId/:agreementId',
  TwoFactor = '/pinauth',
  AddCocustomer = '/addcocustomer/:agreementId',
  VoidAgreement = '/voidagreement/:agreementId',
  EpoSchedule = '/eposchedule/:agreementId',
  pendingAgreement = '/agreement/pendingagreement',

  CreateSwitchOutAgreement = '/agreement/switchout',
  SwitchOutItemSearch = '/agreement/switchout/itemsearch/:customerId/:agreementId?',
  SwitchOutAgreementDetails = '/agreement/switchout/details/:customerId/:agreementId?',
  SwitchOutDeliveryMethod = '/agreement/switchout/delivery/:customerId/:agreementId?',
  ReinstateItemSearch = '/agreement/reinstate/itemsearch/:customerId/:agreementId?',
  ReinstateAgrDetails = '/agreement/reinstate/details/:customerId/:agreementId?',
  ReinstateDeliverymethod = '/agreement/reinstate/delivery/:customerId/:agreementId?',
  CreateReinstateAgreement = '/agreement/reinstate',
  AMCustomer = '/am/customer',
  CreateCustomerOrderAgreement = '/agreement/customerorder',
  CustomerOrderItemSearch = '/agreement/customerorder/itemsearch/:customerId/:agreementId?',
  CustomerOrderAgrDetails = '/agreement/customerorder/details/:customerId/:agreementId?',
  POStatus = '/agreement/customerorder/postatus/:customerId/:agreementId?',
  CustomerOrderDeliveryMethod = '/agreement/customerorder/delivery/:customerId/:agreementId?',
  splitAgreement = '/agreement/split/:customerId',
  AgreementTransfer = '/agreement/transfer/:customerId',
  AgreementTransferReceive = '/agreement/transferreceive',
  CreateLoanerAgreement = '/agreement/loaner',
  LoanerItemSearch = '/agreement/loaner/itemsearch/:customerId/:agreementId?',
  LoanerAgreementDetails = '/agreement/loaner/details/:customerId/:agreementId?/:parentinventoryNumber?',
  LoanerDeliveryMethod = '/agreement/loaner/delivery/:customerId/:agreementId?',
  PromoAgreement = '/agreement/promo',
  PromoItemSearch = '/agreement/promo/itemsearch/:customerId/:agreementId?',
  PromoAgrDetails = '/agreement/promo/details/:customerId/:agreementId?',
  PromoDeliveryMethod = '/agreement/promo/delivery/:customerId/:agreementId?',
}

export enum AppRouteKey {
  Index = '/',
  CreateRentalAgreement = '/agreement/rental',
  ItemSearch = '/agreement/rental/itemsearch',
  AgreementDetails = '/agreement/rental/details',
  InitialPayment = '/agreement/rental/initialpayment',
  DeliveryMethod = '/agreement/rental/delivery',
  AgreementView = '/agreement/info',
  AgreementInformation = '/agreement/information',
  DeliveryMethodView = '/agreement/info/delivery',
  AgreementActivity = '/agreement/info/activity',
  TwoFactor = '/pinauth',
  AddCocustomer = '/addcocustomer/:agreementId',
  VoidAgreement = '/voidagreement/:agreementId',
  EpoSchedule = '/eposchedule/:agreementId',
  POStatus = '/agreement/customerorder/postatus',
  CreateSwitchOutAgreement = '/agreement/switchout',
  SwitchOutItemSearch = '/agreement/switchout/itemsearch',
  SwitchOutAgreementDetails = '/agreement/switchout/details',
  SwitchOutDeliveryMethod = '/agreement/switchout/delivery',
  ReinstateItemSearch = '/agreement/reinstate/itemsearch',
  ReinstateAgrDetails = '/agreement/reinstate/itemsearch',
  ReinstateDeliverymethod = '/agreement/reinstate/delivery',
  CustomerOrderItemSearch = '/agreement/customerorder/itemsearch',
  CustomerOrderAgrDetails = '/agreement/customerorder/details',
  CustomerOrderDeliveryMethod = '/agreement/customerorder/delivery',
  CreateReinstateAgreement = '/agreement/reinstate',
  CreateLoanerAgreement = '/agreement/loaner',
  LoanerItemSearch = '/agreement/loaner/itemsearch',
  LoanerAgreementDetails = '/agreement/loaner/details',
  LoanerDeliveryMethod = '/agreement/loaner/delivery',
  PromoItemSearch = '/agreement/promo/itemsearch',
  PromoAgrDetails = '/agreement/promo/details',
  PromoDeliveryMethod = '/agreement/promo/delivery',
}
