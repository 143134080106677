/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, {
  useContext,
  useEffect,
  useState,
  useImperativeHandle,
} from 'react';
import {
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
  Typography,
  RACModalCard,
  RACButton,
} from '@rentacenter/racstrap';
import { fixedGridStyles } from '../../../JSstyles/fixedGridStyles';
import { ReinstateAgreementContext } from '../../../context/ReinstatementAgreementContext';
import { ReactComponent as AlertIcon } from '../../../assets/images/No-records.svg';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ItemAddition } from '../../../assets/images/ItemAddition.svg';
import { ReactComponent as SearchItem } from '../../../assets/images/SearchItem.svg';
import { searchResultStyles } from '../../../JSstyles/searchResultStyles';
import { agreementInfoStyles } from '../../../JSstyles/agreementInfoStyles';

const ReinstetementGrid = (props: any): any => {
  const agrInfoClassName = agreementInfoStyles();
  const history = useHistory();
  const agrComponent = searchResultStyles();
  const {
    reinstatementGridData,
    deletedIndex,
    setsearchCriteria,
    searchCriteria,
    setstoreReinstateInvID,
    storeReinstateGridData,
    setstoreReinstateGridData,
    reinstateCopyStore,
    setreinstateCopyStore,
    numberOfInventory,
    itemArrayData,
    setdeletedIndex,
  } = useContext(ReinstateAgreementContext);
  const betterPriceObject = {
    currentRate: '',
    currentTRTO: '',
    reinstateRate: '',
    reinstateTRTO: '',
  };
  const fixedGridClassName = fixedGridStyles();
  const classes = props.throwStyle;
  const [searchedInvID, setsearchedInvID]: any = useState(
    reinstatementGridData.itemPricing[0].itemNumber
  );
  const [isBetterRate, setisBetterRate] = useState(false);
  const [betterPriceData, setbetterPriceData] = useState(betterPriceObject);
  const [copyGridData, setcopyGridData]: any = useState([]);
  const [storeInvNumber, setStoredInvNumber]: any = useState('');
  const [storeInvID, setStoredInvID]: any = useState('');
  const [gridBindData, setgridBindData]: any = useState(
    reinstatementGridData.itemPricing
  );
  const currentStore = sessionStorage.getItem('storeNumber');
  console.log('currentStore in reinst grid', currentStore);
  useEffect(() => {
    console.log('setting the value of gridBindData', gridBindData);
  }, [gridBindData]);
  useEffect(() => {
    if (
      storeReinstateGridData !== undefined &&
      reinstateCopyStore !== undefined
    ) {
      console.log('reinstateCopyStore in useeffect', reinstateCopyStore);
      console.log(
        'storeReinstateGridData in useeffect',
        storeReinstateGridData
      );
      //setstoreReinstateInvID(reinstatementGridData.itemPricing[0].itemNumber); // need to code here
      setcopyGridData(reinstateCopyStore);
      setgridBindData([]);
    }

    if (numberOfInventory == itemArrayData.length) {
      console.log('empty the table log');
      setgridBindData([]);
    }
  }, []);

  useEffect(() => {
    console.log('useEffect 1 initial', copyGridData);
    console.log('useEffect 1 initail');
    if (
      deletedIndex.replacedInvId !== undefined &&
      deletedIndex.replacedInvId !== null
    ) {
      //console.log('copyGridData in ue', copyGridData[deletedIndex.deleteIndex]);
      console.log('full copyGridData in ue', copyGridData);
      console.log('gridBindData check in the delete use effect', gridBindData);

      console.log('deletedIndex in use effect', deletedIndex);
      // if (deletedIndex.deleteIndex < copyGridData.length) {
      const tempVariable = gridBindData;
      copyGridData.map((obj: any) => {
        console.log('obj.itemNumberrrr', obj.itemNumber);
        if (obj.itemNumber == deletedIndex.replacedInvId) {
          tempVariable.push(obj);
        }
      });
      //tempVariable.push(copyGridData[deletedIndex.deleteIndex]);
      // tempVariable.splice(deletedIndex.deleteIndex, 0, copyGridData[deletedIndex.deleteIndex])
      console.log('chnaged temp variable', tempVariable);
      console.log(
        'deletedIndex.replacedInvId in delete method',
        deletedIndex.replacedInvId
      );

      const newCopy: any = copyGridData.filter((item, filterIndex) => {
        console.log('copyGridData', copyGridData);
        console.log('item.itemNumber', item.itemNumber);
        // console.log(
        //   'parseInt(deletedIndex.deleteIndex)',
        //   parseInt(deletedIndex.deleteIndex)
        // );
        if (deletedIndex.replacedInvId !== item.itemNumber) {
          console.log('reached delete', filterIndex);
          return item;
        }
      });
      console.log('newCopy res', newCopy);
      setgridBindData(tempVariable);
      setstoreReinstateGridData(tempVariable);
      setcopyGridData(newCopy);
      setreinstateCopyStore(newCopy);
      // }
    }
    console.log('useEffect 1', copyGridData);
    console.log('useEffect 1');
  }, [deletedIndex]);

  React.useEffect(() => {
    props.childFunc.current = deleteFromGrid;
  }, [props.childFunc]);

  const deleteFromGrid = () => {
    console.log('useEffect 2 initial', copyGridData);
    console.log('useEffect 2 initail');
    console.log(
      'received data of props.triggerremoveReinstateGrid',
      props.triggerremoveReinstateGrid
    );
    // console.log('the data of searchedInvID',searchedInvID);
    console.log('the data of storeReinstateInvID', searchedInvID);
    const newTableData: any = [];
    gridBindData.map((itemObj) => {
      if (itemObj.inventoryNumber != searchedInvID) {
        newTableData.push(itemObj);
        console.log('push occoured');
      }
    });
    console.log('newTableData in reinst grid', newTableData);
    console.log('gridBindData value', gridBindData);
    console.log('copyGridData inside use effect', copyGridData);

    const newCopyTableData: any = copyGridData;
    gridBindData.map((itemObj) => {
      if (itemObj.inventoryNumber == searchedInvID) {
        newCopyTableData.push(itemObj);
      }
    });
    console.log('newCopyTableData in reinst grid', newCopyTableData);
    setgridBindData(newTableData);
    setstoreReinstateGridData(newTableData);
    setcopyGridData(newCopyTableData);
    setreinstateCopyStore(newCopyTableData);
    setdeletedIndex({ ...deletedIndex, replacedInvId: null });
    //setcopyOfReinstateGrid(newCopyTableData);

    console.log('useEffect 2', copyGridData);
    console.log('useEffect 2');
  };

  useImperativeHandle(props.childFunc, () => ({
    deleteFromGrid,
  }));

  // useEffect(() => {
  //   console.log('useEffect 2 initial', copyGridData);
  //   console.log('useEffect 2 initail');
  //   console.log(
  //     'received data of props.triggerremoveReinstateGrid',
  //     props.triggerremoveReinstateGrid
  //   );
  //   console.log('the data of searchedInvID', searchedInvID);
  //   if (
  //     props.triggerremoveReinstateGrid !== undefined &&
  //     props.triggerremoveReinstateGrid.length > 0
  //   ) {
  //     const newTableData: any = [];
  //     gridBindData.map((itemObj) => {
  //       if (itemObj.itemNumber != searchedInvID) {
  //         newTableData.push(itemObj);
  //         console.log('push occoured');
  //       }
  //     });
  //     console.log('newTableData in reinst grid', newTableData);
  //     console.log('gridBindData value', gridBindData);
  //     console.log('copyGridData inside use effect', copyGridData);

  //     const newCopyTableData: any = copyGridData;
  //     gridBindData.map((itemObj) => {
  //       if (itemObj.itemNumber == searchedInvID) {
  //         newCopyTableData.push(itemObj);
  //       }
  //     });
  //     console.log('newCopyTableData in reinst grid', newCopyTableData);
  //     setgridBindData(newTableData);
  //     setcopyGridData(newCopyTableData);
  //   }
  //   console.log('useEffect 2', copyGridData);
  //   console.log('useEffect 2');
  // }, [props.triggerremoveReinstateGrid]);
  const renderTableHead = () => {
    return (
      <>
        <RACTableCell className={classes.bold}>Item #</RACTableCell>
        <RACTableCell className={classes.bold}>Department</RACTableCell>
        <RACTableCell className={classes.bold}>Sub Department</RACTableCell>
        <RACTableCell className={classes.bold}>Brand</RACTableCell>
        <RACTableCell className={classes.bold}>Model #</RACTableCell>
        <RACTableCell className={classes.bold}>Rate</RACTableCell>
        <RACTableCell className={classes.bold}>Term</RACTableCell>
        <RACTableCell className={classes.bold}>Condition</RACTableCell>
        <RACTableCell className={`${classes.bold}`}>Action</RACTableCell>
      </>
    );
  };
  // ${agrComponent.paddingLeft}
  // const triggerAddClick = (value: any, inventoryId: any) => {
  //   console.log('value of value in trigger', value);
  //   console.log('value of inventoryId in trigger', inventoryId);

  //   props.triggeraddItem(inventoryId);
  //   setstoreReinstateInvID(value);
  //   setsearchedInvID(value);
  // };

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  }; //This method is used to Add Ellipse to those strings which are quite long in the Search Result Grid

  // const approveAdder = (invID: any, invNumber: any) =>{
  //   props.triggeraddItem(invID, invNumber);
  //   setsearchedInvID(invNumber);
  // }

  const betterRateModal = () => {
    return (
      <div>
        <Grid item md={12}>
          <Typography
            variant="caption"
            className={`${classes.bold} ${classes.font15}`}
          >
            Current pricing is a better value than reinstating.
          </Typography>

          <Grid
            className={`${classes.floatLeft} ${classes.racLightBlue} ${agrInfoClassName.agrBorderRadius} ${classes.px2} ${classes.py2} ${classes.mt3}`}
          >
            <Grid className={`${classes.floatLeft}`} md={3}>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrInfoGeneral} ${classes.width100} ${classes.floatLeft}`}
              >
                Current Rate{' '}
              </Typography>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrGenaralInfoColourLabel} ${classes.width100} ${classes.floatLeft}`}
              >
                $ {betterPriceData.currentRate}
              </Typography>
            </Grid>
            <Grid className={`${classes.floatLeft}`} md={3}>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrInfoGeneral} ${classes.width100} ${classes.floatLeft}`}
              >
                Current TRTO{' '}
              </Typography>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrGenaralInfoColourLabel} ${classes.width100} ${classes.floatLeft}`}
              >
                $ {betterPriceData.currentTRTO}
              </Typography>
            </Grid>
            <Grid className={`${classes.floatLeft}`} md={3}>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrInfoGeneral} ${classes.width100} ${classes.floatLeft}`}
              >
                Reinstate Rate{' '}
              </Typography>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrGenaralInfoColourLabel} ${classes.width100} ${classes.floatLeft}`}
              >
                $ {betterPriceData.reinstateRate}
              </Typography>
            </Grid>
            <Grid className={`${classes.floatLeft}`} md={3}>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrInfoGeneral} ${classes.width100} ${classes.floatLeft}`}
              >
                Reinstate TRTO{' '}
              </Typography>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrGenaralInfoColourLabel} ${classes.width100} ${classes.floatLeft}`}
              >
                $ {betterPriceData.reinstateTRTO}
                {'  '}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} style={{ paddingTop: '25%' }}>
          <RACButton
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              props.triggeraddItem(storeInvID, storeInvNumber);
              setsearchedInvID(storeInvNumber);
              setisBetterRate(false);
            }}
            style={{ float: 'right' }}
          >
            Continue
          </RACButton>
          <RACButton
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => setisBetterRate(false)}
            style={{ float: 'right', marginRight: '0.5rem' }}
          >
            Cancel
          </RACButton>
        </Grid>
      </div>
    );
  };

  const itemAddedInReinstateGrid = (invID: any, invNumber: any) => {
    console.log('invID in addinf reinst', invID);
    console.log('loffer of invber in addinf reinst', invNumber);
    let newTagTotal = 0;
    let existingTagTotal = 0;
    reinstatementGridData.itemPricing.map((object: any) => {
      if (Number(object.inventoryId) == Number(invID)) {
        setbetterPriceData({
          ...betterPriceData,
          currentRate: object.currentTagRate,
          currentTRTO: Number(object.currentTagTotal)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          reinstateRate: object.agreementRate,
          reinstateTRTO: Number(object.remainingAgreementTotal)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        });
        newTagTotal = Number(object.currentTagTotal);
        existingTagTotal = Number(object.remainingAgreementTotal);
      }
    });

    if (existingTagTotal > newTagTotal) {
      console.log('condition checking involved');
      setisBetterRate(true);
    } else {
      console.log('normal dding performed');

      props.triggeraddItem(invID, invNumber);
      setsearchedInvID(invNumber);
    }
  };

  const renderTableContent = () => {
    return (
      <>
        {gridBindData.map((value, index) => {
          console.log(
            'value.inventoryStoreNumber data',
            value.inventoryStoreNumber
          );
          console.log('compare currentStore', currentStore);

          console.log('value.inventoryStatus', value.inventoryStatus);

          let conditionValue = '';
          if (value.condition == 'ATO') {
            conditionValue = 'Auto';
          } else if (value.condition == 'NEW') {
            conditionValue = 'New';
          } else if (value.condition == 'EXCP') {
            conditionValue = 'Exception';
          } else if (value.condition == 'ALL') {
            conditionValue = 'All';
          }
          return (
            <RACTableRow key={index}>
              <RACTableCell
                key={index}
                className={classes.racpadLink}
                onClick={() =>
                  history.push({
                    pathname: `/inventory/${value.itemNumber}/inventoryinformation`,
                  })
                }
              >
                <span className={classes.racpadLink}>{value.itemNumber}</span>
              </RACTableCell>
              <RACTableCell
                title={value.department.length > 10 ? value.department : null}
              >
                {/* {value.department} */}
                {truncString(value.department, 20, '...')}
              </RACTableCell>
              <RACTableCell
                title={
                  value.subdepartment.length > 10 ? value.subdepartment : null
                }
              >
                {/* {value.subdepartment} */}
                {truncString(value.subdepartment, 20, '...')}
              </RACTableCell>
              {value.brand == null ? (
                <RACTableCell>-</RACTableCell>
              ) : (
                <RACTableCell
                  title={value.brand.length > 7 ? value.brand : null}
                >
                  {/* {value.brand} */}
                  {truncString(value.brand, 7, '...')}
                </RACTableCell>
              )}
              {/* <RACTableCell title={value.brand.length > 7 ? value.brand : null}>
                {truncString(value.brand, 7, '...')}
              </RACTableCell> */}
              <RACTableCell title={value.model.length > 7 ? value.model : null}>
                {/* {value.model} */}
                {truncString(value.model, 7, '...')}
              </RACTableCell>
              <RACTableCell>$ {value.agreementRate}</RACTableCell>
              <RACTableCell>{value.originalAgreementTerm}</RACTableCell>
              <RACTableCell>{conditionValue}</RACTableCell>
              <RACTableCell>
                <SearchItem
                  onClick={() => {
                    props.receiveReinstateSearch(value.department);
                    setsearchCriteria({
                      ...searchCriteria,
                      departments: value.department,
                    });
                    setsearchedInvID(value.itemNumber);
                    setstoreReinstateInvID(value.itemNumber);
                  }}
                  className={`${classes.cursorPointer} ${agrComponent.searchIconSizing}`}
                />
                {console.log('value.itemNumber deep value', value.itemNumber)}
                {value.inventoryStoreNumber == currentStore &&
                value.inventoryStatus == 'Rent Ready' &&
                value.itemCondition !== 'NEW' &&
                value.currentTagRate != null ? (
                  <ItemAddition
                    onClick={() => {
                      itemAddedInReinstateGrid(
                        value.inventoryId,
                        value.itemNumber
                      );
                      setStoredInvID(value.inventoryId);
                      setStoredInvNumber(value.itemNumber);
                    }}
                    className={classes.cursorPointer}
                  />
                ) : null}
              </RACTableCell>
            </RACTableRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Grid className={fixedGridClassName.fixTableHead}>
        {gridBindData.length > 0 && gridBindData !== undefined ? (
          <RACTable
            renderTableHead={renderTableHead}
            renderTableContent={renderTableContent}
          />
        ) : (
          <Grid className={`${classes.textCenter}`}>
            <AlertIcon />
            <Typography className={`${classes.subTitle} ${classes.mb3}`}>
              No eligible items to reinstate
            </Typography>
          </Grid>
        )}
      </Grid>
      <RACModalCard
        isOpen={isBetterRate}
        maxWidth="xs"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={betterRateModal()}
        closeIcon={true}
        onClose={() => setisBetterRate(false)}
      />
    </>
  );
};

export default ReinstetementGrid;
