/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  Grid,
  Typography,
  RACButton,
  RACModalCard,
  RACTextbox,
  CircularProgress,
} from '@rentacenter/racstrap';
import { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { paymentCost } from '../../../JSstyles/paymentCost';
import { ReactComponent as Alerticon } from '../../../assets/images/no-records-found.svg';
import { getRetentionOffers, UpdateAgreement } from '../../../api/user';
import CurrencyInput from 'react-currency-input-field';

interface RetentionRTreductionInterface {
  agreementNumber?: string;
  agreementId?: string;
}

export default function RetentionRTreduction(
  props: RetentionRTreductionInterface
) {
  const classes = agreementGlobalStyles();
  const paymentCostClassName = paymentCost();
  const { agreementNumber, agreementId } = props;
  const borderRadius = '20px !important';
  const search = useLocation().search;
  const history = useHistory();
  const retentionRTreductionType = new URLSearchParams(search).get('type');
  const retentionRTreductionSource = new URLSearchParams(search).get('source');

  const [saveDisable, setSaveDisable] = useState(true);
  const [retentionRTvalues, setRetentionRTvalues] = useState({
    remainingRate: '',
    remainingTerm: '',
    newRate: '',
    allowedNewRate: '',
    newTerm: '',
    allowedNewTerm: '',
    rateOfferDescription: '',
    termOfferDescription: '',
  });
  const [rateReductionOpen, setRateReductionOpen] = useState(false);
  const [termReductionOpen, setTermReductionOpen] = useState(false);
  const [rateExceptionErrorOpen, setRateExceptionErrorOpen] = useState(false);
  const [termExceptionErrorOpen, setTermExceptionErrorOpen] = useState(false);
  const [rateExceptionMsg, setRateExceptionMsg] = useState('');
  const [termExceptionMsg, setTermExceptionMsg] = useState('');
  const [hiddenLoader, setHiddenLoader] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [errorMessagePopupOpen, setErrorMessagePopupOpen] = useState(false);
  const [somethingWentWrongOpen, setSomethingWentWrongOpen] = useState(false);

  useEffect(() => {
    if (agreementNumber) {
      getRetentionOffersCall();
    }
  }, [agreementNumber]);
  const retentionRTdecider = () => {
    switch (retentionRTreductionType) {
      case 'RR':
        setRateReductionOpen(true);
        break;
      case 'TR':
        setTermReductionOpen(true);
        break;
    }
  };
  const removeDollarCheck = (value) => String(value).replace('$', '').trim();
  const getRetentionOffersCall = async () => {
    try {
      const retentionOfferReq = {
        agreementNumbers: [agreementNumber],
      };
      setHiddenLoader(true);
      const retentionOffersResponse = await getRetentionOffers(
        retentionOfferReq
      );
      setHiddenLoader(false);
      if (retentionOffersResponse.status == 200) {
        const retentionOffersData = retentionOffersResponse.data;
        let agreementOffersArray = retentionOffersData.agreementOffers;
        agreementOffersArray = agreementOffersArray?.sort(
          (a, b) => a.updateDateTime - b.updateDateTime
        );
        const agreementOffers = agreementOffersArray[0];
        setRetentionRTvalues({
          ...retentionRTvalues,
          remainingRate: removeDollarCheck(agreementOffers?.currentRate),
          newRate: removeDollarCheck(agreementOffers?.newRate),
          allowedNewRate: removeDollarCheck(agreementOffers?.newRate),
          rateOfferDescription: agreementOffers?.rateOfferDescription,
          remainingTerm: agreementOffers?.currentRemainingTerm,
          newTerm: agreementOffers?.newRemainingTerm,
          allowedNewTerm: agreementOffers?.newRemainingTerm,
          termOfferDescription: agreementOffers?.termOfferReduction,
        });
        retentionRTdecider();
      } else if (retentionOffersResponse.status == 400) {
        setErrMessage(retentionOffersResponse?.data?.errors[0]?.error);
        setErrorMessagePopupOpen(true);
      } else {
        setSomethingWentWrongOpen(true);
      }
    } catch {
      setSomethingWentWrongOpen(true);
    }
  };
  const saveBtnClick = async () => {
    const manageAgreementReq: any = {
      agreementId: Number(agreementId),
    };
    if (retentionRTreductionType == 'RR') {
      manageAgreementReq.action = 'RTRRD';
      manageAgreementReq.reductionValue =
        Number(retentionRTvalues.remainingRate) -
        Number(retentionRTvalues.newRate);
    } else if (retentionRTreductionType == 'TR') {
      manageAgreementReq.action = 'RTTRD';
      manageAgreementReq.reductionValue =
        Number(retentionRTvalues.remainingTerm) -
        Number(retentionRTvalues.newTerm);
    }
    setHiddenLoader(true);
    const UpdateAgreementResponse = await UpdateAgreement(manageAgreementReq);
    setHiddenLoader(false);
    if (UpdateAgreementResponse.status == 200) {
      history.push({
        pathname: `/dashboard`,
      });
      setSaveDisable(true);
    } else if (UpdateAgreementResponse.status == 400) {
      setErrMessage(UpdateAgreementResponse?.data?.errors[0]?.error);
      setErrorMessagePopupOpen(true);
    } else {
      setSomethingWentWrongOpen(true);
    }
  };
  const newRateOnChange = (value: any) => {
    setRetentionRTvalues({ ...retentionRTvalues, newRate: value });
  };
  const newTermOnChange = (value: any) => {
    setRetentionRTvalues({ ...retentionRTvalues, newTerm: value });
  };
  const termOkClick = () => {
    if (
      Number(retentionRTvalues.remainingTerm) <
      Number(retentionRTvalues.newTerm)
    ) {
      setTermExceptionMsg(
        `The entered term exceeds or matches the max term allowed of ${retentionRTvalues.remainingTerm}`
      );
      setTermReductionOpen(false);
      setTermExceptionErrorOpen(true);
    } else if (
      Number(retentionRTvalues.allowedNewTerm) >
      Number(retentionRTvalues.newTerm)
    ) {
      setTermExceptionMsg(
        `The entered term does not meet minimum term required of ${retentionRTvalues.allowedNewTerm}`
      );
      setTermReductionOpen(false);
      setTermExceptionErrorOpen(true);
    } else {
      setTermReductionOpen(false);
      setSaveDisable(false);
    }
  };
  const rateOkClick = () => {
    if (
      Number(retentionRTvalues.remainingRate) <
      Number(retentionRTvalues.newRate)
    ) {
      setRateExceptionMsg(
        `The entered amount exceeds the max amount allowed of $${retentionRTvalues.remainingRate}`
      );
      setRateReductionOpen(false);
      setRateExceptionErrorOpen(true);
    } else if (
      Number(retentionRTvalues.allowedNewRate) >
      Number(retentionRTvalues.newRate)
    ) {
      setRateExceptionMsg(
        `The entered amount does not meet minimum amount required of $${retentionRTvalues.allowedNewRate}`
      );
      setRateReductionOpen(false);
      setRateExceptionErrorOpen(true);
    } else {
      setRateReductionOpen(false);
      setSaveDisable(false);
    }
  };
  const rateReductionExceptionPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid>
          <div style={{ textAlign: 'center' }}>
            <Alerticon></Alerticon>
            <Typography
              className={classes.formLabel}
              style={{ fontSize: 'medium' }}
            >
              {rateExceptionMsg}
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setRateExceptionErrorOpen(false);
              setRateReductionOpen(true);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const termReductionExceptionPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid>
          <div style={{ textAlign: 'center' }}>
            <Alerticon></Alerticon>
            <Typography
              className={classes.formLabel}
              style={{ fontSize: 'medium' }}
            >
              {termExceptionMsg}
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setTermExceptionErrorOpen(false);
              setTermReductionOpen(true);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const RateReductionPopup = () => {
    return (
      <div>
        <Grid>
          <Typography
            style={{
              marginBottom: '5%',
              textAlign: 'center',
            }}
          >
            {`MAX offer - ${retentionRTvalues.rateOfferDescription}`}
          </Typography>
          <Grid container>
            <Grid item md={4}>
              <Typography>Current Rate :</Typography>
            </Grid>
            <Grid item md={6} style={{ marginLeft: '5%' }}>
              <Typography
                className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
              >
                $
              </Typography>
              <CurrencyInput
                style={{ zIndex: 0, textAlign: 'right' }}
                className={`${paymentCostClassName.formControl} ${paymentCostClassName.rateInput} ${paymentCostClassName.disabledColour}`}
                decimalsLimit={2}
                value={retentionRTvalues.remainingRate}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '13px' }}>
            <Grid item md={4}>
              <Typography>New Rate :</Typography>
            </Grid>
            <Grid item md={6} style={{ marginLeft: '5%' }}>
              <Typography
                className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
              >
                $
              </Typography>
              <CurrencyInput
                id="input-example"
                name=""
                style={{ zIndex: 0, textAlign: 'right' }}
                className={`${paymentCostClassName.formControl} ${paymentCostClassName.rateInput}`}
                decimalsLimit={2}
                data-testid="ProcessingFeeTxtBoxTest"
                value={retentionRTvalues.newRate}
                onValueChange={(value) => newRateOnChange(value)}
                disabled={false}
              />
              {/* <RACTextbox
                isCurrency={false}
                // style={{ zIndex: 0, textAlign: 'right' }}
                className={`${paymentCostClassName.rateInput} ${paymentCostClassName.rateAlign}`}
                value={retentionRTvalues.newRate}
                OnChange={(e: any) => newRateOnChange(e.target.value)}
                digitFormat="currency"
              /> */}
            </Grid>
          </Grid>
          <Grid style={{ display: 'flex', marginTop: '30px' }}>
            <RACButton
              variant="contained"
              color="primary"
              style={{ marginLeft: '100px', padding: '0px 40px' }}
              onClick={() => {
                rateOkClick();
              }}
              disabled={retentionRTvalues?.newRate?.length > 0 ? false : true}
            >
              Ok
            </RACButton>
            <RACButton
              variant="outlined"
              color="primary"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setRateReductionOpen(false);
              }}
            >
              Cancel
            </RACButton>
          </Grid>
        </Grid>
      </div>
    );
  };
  const TermReductionPopup = () => {
    return (
      <div>
        <Grid>
          <Typography
            style={{
              marginBottom: '5%',
              textAlign: 'center',
            }}
          >
            {`MAX offer - ${retentionRTvalues.termOfferDescription}`}
          </Typography>
          <Grid container>
            <Grid item md={4}>
              <Typography>Current Term :</Typography>
            </Grid>
            <Grid item md={6}>
              <RACTextbox
                isCurrency={false}
                disabled
                value={retentionRTvalues.remainingTerm}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '13px' }}>
            <Grid item md={4}>
              <Typography>New Term :</Typography>
            </Grid>
            <Grid item md={6}>
              <RACTextbox
                isCurrency={false}
                value={retentionRTvalues.newTerm}
                OnChange={(e: any) => newTermOnChange(e.target.value)}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid style={{ display: 'flex', marginTop: '30px' }}>
            <RACButton
              variant="contained"
              color="primary"
              style={{ marginLeft: '100px', padding: '0px 40px' }}
              onClick={() => {
                termOkClick();
              }}
              disabled={retentionRTvalues?.newTerm?.length > 0 ? false : true}
            >
              Ok
            </RACButton>
            <RACButton
              variant="outlined"
              color="primary"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setTermReductionOpen(false);
              }}
            >
              Cancel
            </RACButton>
          </Grid>
        </Grid>
      </div>
    );
  };
  const somethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSomethingWentWrongOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const errorMessagePopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.formLabel}>{errMessage}</Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setErrorMessagePopupOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  return (
    <>
      {hiddenLoader === true ? (
        <Grid
          style={{
            position: 'fixed',
            background: '#f7f5f5',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 2000,
            textAlign: 'center',
            margin: '0px 0px',
          }}
        >
          <Grid
            style={{
              display: 'block',
              position: 'fixed',
              zIndex: 9999999,
              top: '40%',
              right: '50%',
            }}
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <RACModalCard
        isOpen={rateExceptionErrorOpen}
        closeIcon={false}
        maxWidth="sm"
        borderRadius={borderRadius}
      >
        {rateReductionExceptionPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={termExceptionErrorOpen}
        closeIcon={false}
        maxWidth="xs"
        borderRadius={borderRadius}
      >
        {termReductionExceptionPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={rateReductionOpen}
        maxWidth="sm"
        borderRadius={borderRadius}
        className={classes.rateReductionPopup}
        title="Rate Retention"
      >
        {RateReductionPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={termReductionOpen}
        maxWidth="sm"
        borderRadius={borderRadius}
        className={classes.rateReductionPopup}
        title="Term Retention"
      >
        {TermReductionPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={somethingWentWrongOpen}
        closeIcon={false}
        maxWidth="xs"
        borderRadius={borderRadius}
      >
        {somethingWentWrongPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={errorMessagePopupOpen}
        closeIcon={false}
        maxWidth="md"
        borderRadius={borderRadius}
      >
        {errorMessagePopup()}
      </RACModalCard>
      <RACButton
        className={`${classes.floatLeft} ${classes.me2}`}
        variant="contained"
        color="primary"
        disabled={saveDisable}
        onClick={() => {
          saveBtnClick();
        }}
      >
        Save
      </RACButton>
    </>
  );
}
