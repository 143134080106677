/* eslint-disable */

export function cappedFreeTimeValidation(props: any) {
    let freeTimeConfigs = props.cappedFreeTimeValues;
    let freeTimeValues = props.cappedFreeTimeResponseValues;
    console.log(freeTimeValues, freeTimeConfigs, 'freeTimeValues');
    let usedFreeTimeAmount = 0;

    if (freeTimeConfigs.cappedFreeTimeRegularFlag) {
        usedFreeTimeAmount += freeTimeValues?.cappedFreeTimeRegular || 0;
    }

    if (freeTimeConfigs.cappedFreeTimeServiceFlag) {
        usedFreeTimeAmount += freeTimeValues?.cappedFreeTimeService || 0;
    }

    if (freeTimeConfigs.cappedFreeTimeRetentionFlag) {
        usedFreeTimeAmount += freeTimeValues?.cappedFreeTimeRetention || 0;
    }

    if (freeTimeConfigs.cappedFreeTimeInitPaymentFlag) {
        usedFreeTimeAmount += freeTimeValues?.cappedFreeTimeInitPayment || 0;
    }

    if (freeTimeConfigs.cappedFreeTimePromoFlag) {
        usedFreeTimeAmount += freeTimeValues?.cappedFreeTimePromo || 0;
    }

    console.log(
        usedFreeTimeAmount, 'Used Free Time AMount',  freeTimeValues?.cappedFreeTimeSMRRAmount - usedFreeTimeAmount
    );

    return {
        usedFreeTimeAmount: usedFreeTimeAmount,
        allowdedFreeAmount: freeTimeValues?.cappedFreeTimeSMRRAmount,//SMR amount
        remainingAmount : freeTimeValues?.cappedFreeTimeSMRRAmount - usedFreeTimeAmount,
    }
}