/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from "react";
// import { CircularProgress } from '@rentacenter/racstrap';
import { ReactComponent as NoRecordsFound } from "../../../assets/images/No-records.svg";
import { AgreementContext } from "../../../context/AgreementContext";
import { fieldDeviceStyles } from "../../../JSstyles/fieldDeviceStyles";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  RACRadio,
} from "@rentacenter/racstrap";
import Modal from "react-modal";
import RePrintReport from "./RePrintReport";
import { getAgreementDelivery, GetEmployeeID, GetRole } from "../../../api/user";
import Deliverymethod from "../CreateAgreement/Deliverymethod";
// import { ReactComponent as Alerticon } from "../../assets/images/no-records-found.svg";
import moment from "moment";
import { agreementGlobalStyles } from "../../../JSstyles/agreementGlobalStyles";
import { viewDelivery } from "../../../JSstyles/viewDelivery";
import InstallerDetails from "./InstallerDetails";
import { ContainerContext } from "../../../app/App";
import { CustomPropInterface } from '../../../index';
interface Params {
  agreementId: string;
  customerId: string;
}

const DeliveryMethod = (props: any) => {
  const containerData = useContext(ContainerContext) as CustomPropInterface;

  const classes1 = fieldDeviceStyles();
  const {
    viewAgreementContext,
    setdeliveryMethodData,
    deliveryMethodData,
    isReturn,
    setIsReturn,
    returnReschedule,
    SetReturnReschedule,
    SetisDeliveryComplete,
  } = useContext(AgreementContext);

  const classes = agreementGlobalStyles();
  const deliveryViewStyle = viewDelivery();
  const { agreementId, customerId } = useParams<Params>();
  const [reSchedule, setreSchedule] = useState(false);
  const [HiddenLoader, setHiddenLoader] = useState(true);
  const [openPolicyPopup, setopenPolicyPopup] = useState(false);
  const [openRePrint, setopenRePrint] = useState(false);
  const [isNotAccept, setisNotAccept] = useState(true);
  const [bindData, setbindData] = useState<any>();
  const [appiontmentType, setappiontmentType] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewagreementData, SetviewagreementData] =
    useState(viewAgreementContext);
  const [GlobalCID, SetGlobalCID] = useState();
  const [agreementNum, setagreementNum] = useState();
  const [newinventoryId, setnewinventoryId] = useState([]);
  const [footerFlag, setfooterFlag] = useState<any>();
  const [DeliveryDate, setDeliveryDate] = useState("");
  const [DeliveryTime, setDeliveryTime] = useState("");
  const [buttonController, setbuttonController] = useState(true);
  const [IsDeliveryMethodFaild, setIsDeliveryMethodFaild] = useState(false);
  const [agreementIsvoided, SetagreementIsvoided] = useState(false);
  const [returnSubmit, setreturnSubmit] = useState(true);
  const storeNumber = window.sessionStorage.getItem('storeNumber');
  const [employeeId, setEmployeeId] = useState("");
  const [CurrentRole, setCurrentRole] = useState('');
  const [appointmentId, setappointmentId] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [DeliveryMethodFooterFlag, SetDeliveryMethodFooterFlag] =
    useState("DeliveryMethod");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState("");
  const DeliveryData = {
    deliveryrescheduling: false,
  };
  const [isDeliveryReschedule, SetisDeliveryReschedule] =
    useState(DeliveryData);

  const RescheduleData = {
    deliveryreturn: false,
  };

  const [isRescheduleReturn, SetisRescheduleReturn] = useState(RescheduleData);
  const [installerDetailsData, setinstallerDetailsData] = useState({
    installationStatus: '',
    installerName: '',
    phoneNumber: '',
    emailId: '',
    address: '',
    inTireBuyerEnabled: ''
  });

  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    // eslint-disable-next-line no-console
    console.log("Search Reshult Props", data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };
  useEffect(() => {
    let employeeIdGA:any
    let loggedRole:any
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
          setEmployeeId(GetMenuDetailsResponse?.coworkerProfile?.employeeId);

          console.log('currentRole', currentRole);
          setCurrentRole(currentRole);
          loggedRole = currentRole;
          employeeIdGA = GetMenuDetailsResponse?.coworkerProfile?.employeeId


        }
      }
    };
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      setCurrentRole(containerData.GetRole());
      setEmployeeId(containerData?.GetEmployeeId());
      loggedRole = containerData.GetRole()
      employeeIdGA = containerData?.GetEmployeeId()

    } else {
      GettingRole();
    }
    window.dataLayer.push({
      'event': 'Loaded the Agreement Delivery Confirmation Page',
      'customerId': customerId,
      'storeNo': storeNumber,
      'agreementNo': agreementId,
      'employeeId': employeeIdGA,
      'role': loggedRole,
      'currentScreen': 'Agreement Delivery Confirmation',
      'previousScreen': 'Agreement Information',
    });
  }, []);


  useEffect(() => {
    if (deliveryMethodData?.appiontmentType == 'P' || deliveryMethodData?.appiontmentType == 'CI') {
      setIsReturn(true)
    }
  }, [])

  useEffect(() => {
    console.log("ViewAgreementData", viewagreementData);
    if (
      viewagreementData[0] !== undefined &&
      viewagreementData[0].agreementInformation.agreementNumber !== undefined &&
      viewagreementData[0].agreementInformation.agreementNumber !== null
    ) {
      setagreementNum(
        viewagreementData[0].agreementInformation.agreementNumber
      );
      setappointmentId(
        viewagreementData[0]?.agreementInformation?.appointmentId
      );
      if (
        viewagreementData[0] != undefined &&
        viewagreementData[0] != null &&
        viewagreementData[0].agreementCustomer[0] != undefined &&
        viewagreementData[0].agreementCustomer[0] != null &&
        viewagreementData[0].agreementCustomer[0].globalCustomerId !=
        undefined &&
        viewagreementData[0].agreementCustomer[0].globalCustomerId != null
      ) {
        SetGlobalCID(
          viewagreementData[0].agreementCustomer[0].globalCustomerId
        );
      }

      if (
        viewagreementData[0]?.agreementInventories[0]?.inventoryId !==
        undefined &&
        viewagreementData[0]?.agreementInventories[0]?.inventoryId !== null
      ) {
        const InventoryArray: any = [];
        InventoryArray.push(
          viewagreementData[0].agreementInventories[0].inventoryId
        );
        console.log("Inventories", InventoryArray);
        setnewinventoryId(InventoryArray);
        console.log(
          "INVVVVVV",
          viewagreementData[0].agreementInventories[0].inventoryId
        );
      }
      console.log(
        "Numberuuuuu",
        viewagreementData[0].agreementInformation.agreementNumber
      );
      console.log("view inventoryId", newinventoryId);

      // eslint-disable-next-line no-console
      console.log("props  is here", agreementNum);
      if (viewagreementData[0]?.installerInformation) {
        setinstallerDetailsData({
          installationStatus: viewagreementData[0]?.installerInformation?.installationStatusDesc,
          installerName: viewagreementData[0]?.installerInformation?.installerName,
          phoneNumber: viewagreementData[0]?.installerInformation?.phoneNumber,
          emailId: viewagreementData[0]?.installerInformation?.emailAddress,
          address: `${viewagreementData[0]?.installerInformation?.addressInfo?.addressLine1},${viewagreementData[0]?.installerInformation?.addressInfo?.postalCode},${viewagreementData[0]?.installerInformation?.addressInfo?.city}`,
          inTireBuyerEnabled: '1'
        })
      }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewagreementData]);

  const data_Reschedule = (data) => {
    SetisDeliveryReschedule({
      ...isDeliveryReschedule,
      deliveryrescheduling: true,
    });
    // eslint-disable-next-line no-console
    console.log("Rescheduling Data Here", data);
  };

  const rescheduleReturn = (data) => {
    SetisRescheduleReturn({
      ...isRescheduleReturn,
      deliveryreturn: true,
    });
    console.log("Rescheduling Return", data);
  };
  function GetDeliveryInformationMapper(
    agreementInformation: any,
    eventInformation: any
  ): any {
    if (agreementInformation != undefined && eventInformation != undefined) {
      const getDeliveryInformation: any = {};
      getDeliveryInformation.agreementId =
        agreementInformation.agreementInformation.agreementId;
      getDeliveryInformation.deliveryMethodStatus = deliveryMethodStatusDefine(
        eventInformation.appointmentInfo.appointmentType,
        eventInformation.appointmentInfo.appointmentStatusDesc,
        agreementInformation.agreementWorkflow.isSwitchoutEnabled,
        agreementInformation.agreementInformation.agreementStatusGroupCode,
        agreementInformation.agreementWorkflow.isDeliveryCompleted
      );
      getDeliveryInformation.deliveryInformation = {
        eventId: agreementInformation.agreementInformation.appointmentId,
        deliveryType: deliveryTypeDefine(
          eventInformation.appointmentInfo.appointmentType,
          eventInformation.appointmentInfo.appointmentTypeDesc,
          eventInformation.appointmentInfo.appointmentStatusDesc,
          agreementInformation.agreementWorkflow.isSwitchoutEnabled
        ),
        appointmentSource: eventInformation.appointmentInfo.appointmentSource,
        deliveryStatus: eventInformation.appointmentInfo.appointmentStatusDesc,
        addressId: eventInformation.customerInfo.address?.addressId,
        addressLine1: eventInformation.customerInfo.address?.addressLine1,
        addressLine2: eventInformation.customerInfo.address?.addressLine2,
        zip: eventInformation.customerInfo.address?.postalCode,
        zipPlus4: eventInformation.customerInfo.address?.postalCode,
        city: eventInformation.customerInfo.address?.city,
        state: eventInformation.customerInfo.address?.state,
        timeSlotId: eventInformation.timeSlotInfo.timeSlot,
        appointmentDate: eventInformation.appointmentInfo.appointmentDate,
        appointmentStartTime: eventInformation.timeSlotInfo.startTime,
        appointmentEndTime: eventInformation.timeSlotInfo.endTime,
        coWorkersRequired: eventInformation.appointmentInfo.requiredCoworkers,
        instructions: eventInformation.appointmentInfo.instructions,
      };
      return getDeliveryInformation;
    }
    return undefined;
  }

  function deliveryTypeDefine(
    appointmentType: string,
    appointmentTypeDesc: string,
    appointmentStatusDesc: string,
    switchout: string
  ): string {
    let appointmentTypeDescription: any = appointmentTypeDesc;
    if (["P", "CI"].includes(appointmentType)) {
      appointmentTypeDescription = "Return";
    }
    return appointmentTypeDescription;
  }
  function deliveryMethodStatusDefine(
    appointmentType: string,
    appointmentStatusDesc: string,
    switchout: string,
    agreementStatusGroup: string,
    isDeliveryCompleted: string
  ): string {
    //View Delivery Method
    let deliveryMethodStatus = "0";
    console.log(
      appointmentType,
      appointmentStatusDesc,
      switchout,
      agreementStatusGroup,
      isDeliveryCompleted
    );
    if (
      appointmentStatusDesc === "Completed" ||
      (["D", "CO"].includes(appointmentType) &&
        ["Completed"].includes(appointmentStatusDesc) &&
        isDeliveryCompleted === "1") ||
      (agreementStatusGroup == "CLOSED" &&
        appointmentStatusDesc == "Cancelled") ||
      ["SCI", "SCO", "SD", "S", "SP"].includes(appointmentType) ||
      (["P", "CI", "D", "CO"].includes(appointmentType) &&
        ["Completed", "Cancelled"].includes(appointmentStatusDesc) &&
        isDeliveryCompleted === "1")
    ) {
      deliveryMethodStatus = "1";
    }
    //View Delivery Method with No Appointment Records
    else if (["VOID", "CLOSED"].includes(agreementStatusGroup)) {
      deliveryMethodStatus = "2";
    }
    return deliveryMethodStatus;
  }
  const pageViewController = () => {
    console.log("bindData", bindData);
    setreSchedule(false);
    setfooterFlag("1");
  };

  const returnViewController = () => {
    setreSchedule(false);
    setfooterFlag("1");
    // CallApi();
  };
  useEffect(() => {
    const CallApi = async () => {
      // debugger;
      // const ApiResult = await getAgreementDelivery(agreementId);
      // const ApiResponse = ApiResult.data;
      console.log("enters the view effect")
      let ApiResponse: any;
      let ApiResult: any;
      if (deliveryMethodData == undefined) {
        const appointmentId =
          viewagreementData[0]?.agreementInformation?.appointmentId;
        console.log("deliveryMethod1", deliveryMethodData);
        console.log("viewagreementData", appointmentId);
        if (appointmentId) {
          console.log("enters the view")
          ApiResult = await getAgreementDelivery(agreementId, appointmentId);
          console.log("enters the apiresult",ApiResult)

          if (ApiResult.status == 200) {
            setappiontmentType(ApiResult?.data?.appointmentInfo?.appointmentType)
            const result = await GetDeliveryInformationMapper(
              viewagreementData[0],
              ApiResult.data
            );
            setdeliveryMethodData(result);
            //setdeliveryMethodData(ApiResult.data);
            ApiResponse = result;
            ApiResult = result;
            console.log("GetDeliveryInformationMapperresult", result);
          }
          if (
            ApiResult.deliveryInformation.deliveryType == "Return" &&
            ApiResult.deliveryInformation.deliveryStatus == "ToDo"
          ) {
            SetReturnReschedule(true);
          }
          if (
            ApiResult.deliveryMethodStatus == "1" &&
            ApiResult.deliveryInformation.deliveryType == "Return"
          ) {
            SetisDeliveryComplete(true);
          }
          setbindData(ApiResult.deliveryInformation);
        } else {
          setHiddenLoader(false);
          setIsDeliveryMethodFaild(true);
        }
      } else {
        console.log("enters the view else")
        ApiResponse = deliveryMethodData;
        if (
          deliveryMethodData.deliveryInformation.deliveryType == "Return" &&
          deliveryMethodData.deliveryInformation.deliveryStatus == "ToDo"
        ) {
          SetReturnReschedule(true);
        }
        if (
          deliveryMethodData.deliveryMethodStatus == "1" &&
          deliveryMethodData.deliveryInformation.deliveryType == "Return"
        ) {
          SetisDeliveryComplete(true);
        }
        setbindData(deliveryMethodData.deliveryInformation);
        setappiontmentType(deliveryMethodData.appiontmentType)
      }
      /* eslint-disable no-console */
      console.log("data from getAgreementDelivery call", ApiResponse);
      console.log("data in apiresp", ApiResponse.appiontmentType);

      if (ApiResponse !== undefined) {
        console.log("enter the second if condition")
        setbindData(ApiResponse.deliveryInformation);
        setappiontmentType(ApiResponse.appiontmentType)
        setfooterFlag(ApiResponse.deliveryMethodStatus);
        if (
          ApiResponse.deliveryInformation !== undefined &&
          ApiResponse.deliveryInformation !== null &&
          ApiResponse.deliveryInformation !== "" &&
          ApiResponse.deliveryInformation.appointmentDate !== undefined &&
          ApiResponse.deliveryInformation.appointmentDate !== null &&
          ApiResponse.deliveryInformation.appointmentDate !== ""
        ) {
          //This state making loader hide and show 
          setIsDeliveryMethodFaild(false);
          setDeliveryDate(ApiResponse.deliveryInformation.appointmentDate);
          const modifiedStarttime =
            ApiResponse.deliveryInformation.appointmentStartTime
              .replace(" PM", "")
              .replace(" AM", "");

          const modifiedEndtime =
            ApiResponse.deliveryInformation.appointmentEndTime;

          const slicedStartTimeslot =
            modifiedStarttime.slice(0, 5) +
            modifiedStarttime.slice(8, modifiedStarttime.length);
          const slotDuration =
            moment(
              ApiResponse.deliveryInformation.appointmentStartTime,
              "HH:mm"
            ).format("hh:mm") +
            " - " +
            moment(modifiedEndtime, "HH:mm").format("hh:mm A");

          setDeliveryTime(slotDuration);
          //setDeliveryTime(slicedStartTimeslot + '-' + modifiedEndtime);

          console.log(
            "set start time and endtime value",
            modifiedStarttime,
            modifiedEndtime,
            slicedStartTimeslot
          );
        }
        if (ApiResponse.deliveryMethodStatus == "0") {
          setreSchedule(true);
          setHiddenLoader(false);
        } else if (ApiResponse.deliveryMethodStatus == "1") {
          setreSchedule(false);
          setHiddenLoader(false);
        }
        else if (ApiResponse.deliveryMethodStatus == "2") {
          setHiddenLoader(false);
        }
      }
      if (ApiResult?.status == 400) {
        setHiddenLoader(false);
        setIsDeliveryMethodFaild(true);
        SetagreementIsvoided(true);
      }
    };
    CallApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRescheduleReturn]);
  console.log('appiontmentTypeCheck1', appiontmentType)
  const closePopup = () => {
    setopenRePrint(false);
    setopenPolicyPopup(false);
  };

  const openPopup = () => {
    setopenRePrint(true);
  };

  const closePoliPopup = () => {
    setopenPolicyPopup(false);
  };

  const accept = () => {
    if (isNotAccept === true) {
      setisNotAccept(false);
    } else {
      setisNotAccept(true);
    }
    setopenPolicyPopup(false);
  };
  const timeChanged = () => {
    // childFunc.current.deleteFromGrid();
    console.log("5555fcedsfve5f2we2vf");
    setbuttonController(false);
    setreturnSubmit(false);
  };

  const deliveryTypeChange = () => {
    setbuttonController(false);
  };

  const dateChaged = () => {
    // childFunc.current.deleteFromGrid();
    console.log("5555fcedsfve5f2we2vf");
    setbuttonController(true);
    setreturnSubmit(true);
  };

  // const isAgrVoided = (value: any) => {
  //   console.log('received void value', value);

  //   if (value === true) {
  //     setIsDeliveryMethodFaild(true);
  //     setHiddenLoader(false);
  //   }
  // };

  // const rescheduleReturn = (data) => {
  //   console.log('ReturnProps', data);
  // Again
  // };

  return (
    <div>
      {/* (
      <Grid item md={12} className={`${classes.Norecords} ${classes.my3}`}>
        <Alerticon className={classes.alertIcon}></Alerticon>
        No Records Found
      </Grid>
      ) */}
      {IsDeliveryMethodFaild === false ? (
        <div id="step-4" className={`${classes.px2} ${classes1.mbMd100}`} role="tabpanel">
          {HiddenLoader && IsDeliveryMethodFaild === false ? (
            <Grid className={classes.masterLoader}>
              <Grid className={classes.Loader}>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : isReturn && (bindData?.deliveryType != "Return" &&
          (bindData?.deliveryStatus != "Completed" ||
            bindData?.deliveryStatus != "ToDo"))? (
            <Deliverymethod
              reScheduleData={returnReschedule ? bindData : undefined}
              globalCustomerId={GlobalCID}
              AgrNum={agreementNum}
              InvId={newinventoryId}
              isReturn={isReturn}
              isDeliveryReschedule={isDeliveryReschedule}
              rescheduleDatas={rescheduleReturn}
              triggerdateChaged={dateChaged}
              triggerdeliveryChanged={deliveryTypeChange}
              triggertimeChanged={timeChanged}
              appiontmentType={appiontmentType}
            />
          ) : reSchedule ? (
            <Deliverymethod
              reScheduleData={bindData}
              globalCustomerId={GlobalCID}
              AgrNum={agreementNum}
              InvId={newinventoryId}
              isReturn={returnReschedule}
              isDeliveryReschedule={isDeliveryReschedule}
              rescheduleDatas={rescheduleReturn}
              triggerdeliveryChanged={deliveryTypeChange}
              triggerdateChaged={dateChaged}
              triggertimeChanged={timeChanged}
              appiontmentType={appiontmentType}
            />
          ) : bindData !== undefined ? (
            <>
              <Typography variant="h5" className={classes.title}>
                {bindData.deliveryType == "Return" &&
                  (bindData.deliveryStatus == "Completed" ||
                    bindData.deliveryStatus == "ToDo")
                  ? "Return Information"
                  : "Delivery Information"}
              </Typography>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={3}
                      className={deliveryViewStyle.DetailsPadding}
                    >
                      <Typography
                        variant="h5"
                        className={`${classes.mt2} ${classes.subTitle} ${classes1.tabFont16}`}
                      >
                        Delivery Type
                      </Typography>
                      <Grid
                        className={`${deliveryViewStyle.formcheckinline} ${classes.ps0}`}
                      >
                        <RACRadio
                          name="inlineRadioOptions"
                          id="delivery"
                          checked={
                            bindData.deliveryType == "Delivery" || appiontmentType == 'P' ? true : false
                          }
                          value={"delivery"}
                          size={"small"}
                          //label="Delivery"
                          // disableTouchRipple={true}
                          disabled={true}
                        />
                        <Typography
                          variant="caption"
                          className={classes.formLabel}
                        >
                          {bindData.deliveryType == "Return" &&
                            (bindData.deliveryStatus == "Completed" ||
                              bindData.deliveryStatus == "ToDo")
                            ? "Pickup"
                            : "Delivery"}
                        </Typography>
                      </Grid>
                      <Grid
                        className={`${deliveryViewStyle.formcheckinline} ${classes.ps0}`}
                      >
                        <RACRadio
                          name="inlineRadioOptions"
                          id="carryout"
                          checked={
                            bindData.deliveryType == "Delivery" || appiontmentType == 'P' ? false : true
                          }
                          value={"carryout"}
                          size={"small"}
                          //label="Carryout"
                          disabled={true}
                        />
                        <Typography
                          variant="caption"
                          className={classes.formLabel}
                        >
                          {bindData.deliveryType == "Return" &&
                            (bindData.deliveryStatus == "Completed" ||
                              bindData.deliveryStatus == "ToDo")
                            ? "CarryIn"
                            : "Carryout"}
                        </Typography>
                      </Grid>
                    </Grid>
                    {bindData.deliveryType != "Carry" ? (
                      <Grid
                        item
                        sm={12}
                        md={12}
                        lg={9}
                        className={deliveryViewStyle.DetailsPadding}
                      >
                        <Typography
                          variant="h5"
                          className={`${classes.mt2} ${classes.subTitle}`}
                        >
                          Delivery Address
                        </Typography>
                        <Grid container>
                          <Grid
                            className={`${deliveryViewStyle.widthadjust16} ${classes1.widthadjust16}`}>
                            <Typography
                              className={`${classes.w100} ${deliveryViewStyle.HeaderPadding}`}
                            >
                              Address Line 1
                            </Typography>
                            <Typography
                              className={deliveryViewStyle.appointmentstyles}
                            >
                              {bindData.addressLine1}
                            </Typography>
                          </Grid>
                          <Grid className={`${deliveryViewStyle.widthadjust16} ${classes1.widthadjust16}`}>
                            <Typography
                              className={`${classes.w100} ${deliveryViewStyle.HeaderPadding}`}
                            >
                              Address Line 2
                            </Typography>
                            <Typography
                              className={deliveryViewStyle.appointmentstyles}
                            >
                              {bindData.addressLine2 !== ""
                                ? bindData.addressLine2
                                : "-"}
                            </Typography>
                          </Grid>
                          <Grid className={`${deliveryViewStyle.widthadjust16} ${classes1.widthadjust16}`}>
                            <Typography
                              className={`${classes.w100} ${deliveryViewStyle.HeaderPadding}`}
                            >
                              Zip
                            </Typography>
                            <Typography
                              className={deliveryViewStyle.appointmentstyles}
                            >
                              {bindData.zip}
                            </Typography>
                          </Grid>
                          <Grid className={`${deliveryViewStyle.widthadjust16} ${classes1.widthadjust16}`}>
                            <Typography
                              className={`${classes.w100} ${deliveryViewStyle.HeaderPadding}`}
                            >
                              City
                            </Typography>
                            <Typography
                              className={deliveryViewStyle.appointmentstyles}
                            >
                              {bindData.city}
                            </Typography>
                          </Grid>
                          <Grid className={`${deliveryViewStyle.widthadjust16} ${classes1.widthadjust16}`}>
                            <Typography
                              className={`${classes.w100} ${deliveryViewStyle.HeaderPadding}`}
                            >
                              State
                            </Typography>
                            <Typography
                              className={deliveryViewStyle.appointmentstyles}
                            >
                              {bindData.state}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid container className={classes.mb1}>
                    <Grid item md={12}>
                      <Typography
                        variant="h5"
                        className={`${classes.mt2} ${classes.subTitle} ${classes1.tabFont16}`}
                      >
                        Delivery Details
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={`${classes.mb1} ${classes.mt2} ${classes.subTitle}`}
                  >
                    <Grid item md={3} sm={6} className={`${classes1.mbMd3}`}>
                      <Typography
                        variant="caption"
                        className={deliveryViewStyle.LabelPadding}
                      >
                        <Typography
                          variant="caption"
                          className={deliveryViewStyle.deliveryLabelStyle}
                        >
                          Date :{" "}
                        </Typography>

                        <Typography
                          variant="caption"
                          className={deliveryViewStyle.appointmentstyles}
                        >
                          {bindData.appointmentDate}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={8} sm={6}>
                      <Typography
                        variant="caption"
                        className={`${classes.w100} ${deliveryViewStyle.LabelPadding}`}
                      >
                        <Typography
                          variant="caption"
                          className={deliveryViewStyle.deliveryLabelStyle}
                        >
                          Time :{" "}
                        </Typography>

                        <Typography
                          variant="caption"
                          className={deliveryViewStyle.appointmentstyles}
                        >
                          {moment(
                            bindData.appointmentStartTime,
                            "HH:mm"
                          ).format("hh:mm A") +
                            " - " +
                            moment(bindData.appointmentEndTime, "HH:mm").format(
                              "hh:mm A"
                            )}
                          {/* {bindData.appointmentStartTime}
                          {' - '}
                          {bindData.appointmentEndTime} */}
                        </Typography>
                      </Typography>
                    </Grid>
                    {bindData.deliveryType !== "CarryOut" && !installerDetailsData?.inTireBuyerEnabled ? (
                      <Grid item md={3} sm={6}>
                        <Typography
                          variant="caption"
                          className={`${classes.w100} ${deliveryViewStyle.LabelPadding}`}
                        >
                          <Typography
                            variant="caption"
                            className={deliveryViewStyle.deliveryLabelStyle}
                          >
                            # Co-worker Required :{" "}
                          </Typography>

                          <Typography
                            variant="caption"
                            className={deliveryViewStyle.appointmentstyles}
                          >
                            {bindData.coWorkersRequired}
                          </Typography>
                        </Typography>
                      </Grid>
                    ) : null}
                    {!installerDetailsData?.inTireBuyerEnabled ?
                      <Grid item lg={3} md={8} sm={6}>
                        <Typography
                          variant="caption"
                          className={`${classes.w100} ${deliveryViewStyle.LabelPadding}`}
                        >
                          <Typography
                            variant="caption"
                            className={deliveryViewStyle.deliveryLabelStyle}
                          >
                            Instructions :{" "}
                          </Typography>

                          <Typography
                            variant="caption"
                            className={deliveryViewStyle.appointmentstyles}
                          >
                            {bindData.instructions == null
                              ? "-"
                              : bindData.instructions}
                          </Typography>
                        </Typography>
                      </Grid> : null}
                    {installerDetailsData?.inTireBuyerEnabled ?
                      <Grid item lg={3} md={8} sm={6}>
                        <Typography
                          variant="caption"
                          className={`${classes.w100} ${deliveryViewStyle.LabelPadding}`}
                        >
                          <Typography
                            variant="caption"
                            className={deliveryViewStyle.deliveryLabelStyle}
                          >
                            Installation Status :{" "}
                          </Typography>

                          <Typography
                            variant="caption"
                            className={deliveryViewStyle.appointmentstyles}
                          >
                            {installerDetailsData?.installationStatus}
                          </Typography>
                        </Typography>
                      </Grid> : null}

                  </Grid>
                  {installerDetailsData?.inTireBuyerEnabled ?
                    <InstallerDetails
                      installerName={installerDetailsData?.installerName}
                      phoneNumber={installerDetailsData?.phoneNumber}
                      emailId={installerDetailsData?.emailId}
                      address={installerDetailsData?.address}
                    /> : null}

                </CardContent>
              </Card>
            </>
          ) : null}
        </div>
      ) : (
        <Grid className={`${classes.textCenter} ${classes.mt5}`}>
          <Card className={classes.card}>
            <CardContent className={`${classes.mt5} ${classes.mb5}`}>
              <NoRecordsFound className={`${classes.mb3}`} />
              <Typography className={`${classes.title} ${classes.mb3}`}>
                This agreement does not have any scheduled appointments
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Footer
        currentPage="DeliveryMethod"
        voidedAgreementfooter={agreementIsvoided}
        showDeliveryfooter={DeliveryMethodFooterFlag}
        displayFooter={footerFlag}
        triggeropenPopup={openPopup}
        deliveryDetails={{
          DeliveryDate: DeliveryDate,
          DeliveryTime: DeliveryTime,
        }}
        func={pull_data}
        reschedulefunc={data_Reschedule}
        triggerpageViewController={pageViewController}
        returnpageViewController={returnViewController}
        throwButtonController={buttonController}
        throwReturnController={returnSubmit}
      // receiveIsVoid={isAgrVoided}
      />
      {openRePrint ? <RePrintReport closeMethod={closePopup} /> : null}
      {openPolicyPopup ? (
        <Modal
          isOpen={true}
          className="modal-dialog"
          overlayClassName="Overlay"
        >
          <div
            id="reprint"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="reprint"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <h5 className="modal-title">Policy Option</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closePoliPopup}
                  />
                </div>
                <div className="modal-body">
                  <div className="container-fluid">
                    {isNotAccept ? (
                      <div className="modal-body text-center">
                        <p className="popup-text">
                          Would you like to remove{" "}
                          <strong>RAC Tire Plus</strong> from the agreement?
                        </p>
                        <p className="popup-text">
                          By selecting Yes the schedule payment amount will
                          decrease by <strong>$3.25</strong>
                        </p>
                      </div>
                    ) : (
                      <div className="modal-body text-center">
                        <p className="popup-text">
                          Would you like to add <strong>RAC Tire Plus</strong>{" "}
                          from the agreement?
                        </p>
                        <p className="popup-text">
                          By selecting Yes the schedule payment amount will
                          increase by <strong>$3.25</strong>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-footer border-0">
                  <button
                    type="button"
                    className="btn btn-secondary mx-1"
                    data-bs-dismiss="modal"
                    onClick={closePoliPopup}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mx-1"
                    onClick={() => accept()}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default DeliveryMethod