/* eslint-disable */
import React, { useState, useEffect, CSSProperties, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Link as CustomLink } from '../../../router/Link';
import { Grid, List, ListItem } from '@rentacenter/racstrap';
import ViewAgrapprovalamntheader from '../View Agreement/ViewAgrapprovalamntheader';
import ViewAgreementBreadcrumbs from '../View Agreement/Viewagrheaderbreadcrumbs';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { fieldDeviceStyles } from '../../../JSstyles/fieldDeviceStyles';

//import { Getglobalcustomerid } from '../../../api/user';
interface Params {
  agreementId: string;
  customerId: string;
}
export type CoCustomerDetails = {
  coCustomerId: string;
  coCustomerName: string;
};
export type AgreementHeaderProps = {
  CoCustomerDetails: CoCustomerDetails;
};

const ViewAgreementHeader = (props: AgreementHeaderProps) => {

  const { CoCustomerDetails } = props;
  const history = useHistory();

  const [CocustomerDetailsValue, setCoCustomerDetails] =
    useState<CoCustomerDetails>(CoCustomerDetails);

  const [pageHolder, setpageHolder] = useState('AgrInfo');
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      'Inside view agreement details outside',
      CoCustomerDetails,
      CocustomerDetailsValue
    );
    if (
      CoCustomerDetails !== undefined &&
      CoCustomerDetails.coCustomerId !== undefined &&
      CoCustomerDetails.coCustomerId !== null &&
      CoCustomerDetails.coCustomerId !== '' &&
      CoCustomerDetails.coCustomerName !== undefined &&
      CoCustomerDetails.coCustomerName !== null &&
      CoCustomerDetails.coCustomerName !== ''
    ) {
      // eslint-disable-next-line no-console
      console.log('Inside wizard view agreement', CoCustomerDetails);
      setCoCustomerDetails(CoCustomerDetails);
    }

    const getUrl = window.location.href;
    if (getUrl.includes('info/details')) {
      openAgrInfoPAge();
    } else if (getUrl.includes('info/delivery')) {
      openDeliveryMethod();
    } else if (getUrl.includes('info/activity')) {
      openAgrActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CoCustomerDetails]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  // const [cocuslastName, setcocuslastName] = useState('');
  // const [cocusfirstName, setcocusfirstName] = useState('');
  const [fullName, setfullName] = useState('');
  const [customerIDBreadCrumnsValue, setcustomerIDBreadCrumnsValue] =
    useState('');

  // const pull_data = (data) => {
  //   setcocustomerId(data.coCustomerId);
  //   setcoCustomerName(data.coCustomerName);
  //   console.log('Footer contex', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  // };
  const classes = agreementGlobalStyles();
  const classes1 = fieldDeviceStyles();
  const { agreementId, customerId } = useParams<Params>();

  const [firstCSS, setfirstCSS] = useState<CSSProperties>({});
  const [secondCSS, setsecondCSS] = useState<CSSProperties>({});
  const [thirdCSS, setthirdCSS] = useState<CSSProperties>({});

  // const [customerId, setcustoemrId] = useState('');
  // const [agreementId, setagreementId] = useState('');
  // const [pathParamvalue, setParamPathValue] = useState('');
  const ActiveCSS: CSSProperties = {
    textDecoration: 'none',
    fontSize: '15px',
    color: '#2179FE',
    borderBottom: '5px solid #2179FE',
    fontFamily: 'OpenSans-semibold',
    padding: '15px',
    position: 'relative',
  };

  const InActiveCSS: CSSProperties = {
    textDecoration: 'none',
    fontSize: '15px',
    color: '#000',
    borderBottom: '5px solid transparent',
    fontFamily: 'OpenSans-semibold',
    padding: '15px',
  };
  const openAgrInfoPAge = () => {
    setfirstCSS(ActiveCSS);
    setsecondCSS(InActiveCSS);
    setthirdCSS(InActiveCSS);
    setpageHolder('AgrInfo');
  };
  const openDeliveryMethod = () => {
    setfirstCSS(InActiveCSS);
    setsecondCSS(ActiveCSS);
    setthirdCSS(InActiveCSS);
    setpageHolder('DeliMethod');
  };

  const openAgrActivity = () => {
    setfirstCSS(InActiveCSS);
    setsecondCSS(InActiveCSS);
    setthirdCSS(ActiveCSS);
    setpageHolder('AgrAct');
  };

  return (
    <div>
      <Grid className={`${classes.parentclass} ${classes.breadcrumbsbgcolor}`}>
        <Grid
          item
          md={12}
          className={`${classes.flexratio} ${classes1.flexMdCol} ${classes1.mbMd4} ${classes1.breadCrumbContainer}`}
        >
          <List className={`${classes.breadcrumb} ${classes.py1}`}>
            <ListItem
              className={`${classes.breadcrumbItemActive} ${classes1.breadcrumbItemActive} ${classes.breadCrumbMarg}  ${classes1.breadCrumbMarg}`}
            >
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/dashboard/homepage`)}
              >
                Dashboard
              </a>
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}`}
            >
              <a
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  history.push(`/customer/update/${customerId}/customer`)
                }
              >
                {fullName} - {customerIDBreadCrumnsValue}
              </a>
            </ListItem>
          </List>


          <Grid className={classes.floatRight}>
            <ViewAgrapprovalamntheader
              receiveCustomerName={(e) => setfullName(e)}
              receiveCustomerID={(e) => setcustomerIDBreadCrumnsValue(e)}
              CoCustomerDetails={CocustomerDetailsValue}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        md={12}
        className={`${classes.mb2} ${classes.racViewAgreementTab} ${classes1.mbMd3}`}
      >
        <List
          className={`${classes.p0} ${classes.racViewAgreementTabPanel} ${classes.ms3}`}
        >
          <ListItem className={`${classes.widthAuto} ${classes.p0}`}>
            <CustomLink
              href={`/agreement/info/details/${customerId}/${agreementId}`}
              style={firstCSS}
              id="Agreementinfo-tab"
              onClick={openAgrInfoPAge}
              className={pageHolder == 'AgrInfo' ? classes.tabUI : ''}
            >
              Agreement Information
            </CustomLink>
          </ListItem>
          <ListItem className={`${classes.widthAuto} ${classes.p0}`}>
            <CustomLink
              href={`/agreement/info/delivery/${customerId}/${agreementId}`}
              style={secondCSS}
              id="deliverymethod-tab"
              onClick={openDeliveryMethod}
              className={pageHolder == 'DeliMethod' ? classes.tabUI : ''}
            >
              Delivery Method
            </CustomLink>
          </ListItem>
          <ListItem className={`${classes.widthAuto} ${classes.p0}`}>
            <CustomLink
              href={`/agreement/info/activity/${customerId}/${agreementId}`}
              style={thirdCSS}
              id="agreementactivity-tab"
              onClick={openAgrActivity}
              className={pageHolder == 'AgrAct' ? classes.tabUI : ''}
            >
              Agreement Activity
            </CustomLink>
          </ListItem>
        </List>
        <ViewAgreementBreadcrumbs CoCustomerDetails={CocustomerDetailsValue} />
      </Grid>
    </div>
  );
};
export default ViewAgreementHeader;
