/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { createContext, useState, useEffect } from 'react';
export const SwitchOutAgreementContext = createContext<any>('');
export function SwitchOutAgreementContextProvider(props: any) {
  const [CustomerInfo, setCustomerInfo] = useState();
  const [selectedInvNumber, setselectedInvNumber] = useState();
  const [isSOEnabledbyconfig, setisSOEnabledbyconfig] = useState('0');
  const [isChoosePriceConfig, setisChoosePriceConfig] = useState('0');
  const [gotSOConfigResponse, setgotSOConfigResponse] = useState(false);

  const [searchCriteria, setsearchCriteria] = useState({
    itemNo: '',
    serialNo: '',
    bracket: '',
    brand: '',
    model: '',
    conditionId: null,
    departments: '',
    subDepartments: '',
    packageTypeval: '',
  });
  const [itemArrayDataSwitchOut, setitemArrayData] = useState([]);
  const [entireAgrInfoResponse, setentireAgrInfoResponse] = useState();
  const [numberOfInventory, setnumberOfInventory] = useState();
  const [switchOutData, setSwitchOutData] = useState();
  const [oldInvRV, setoldInvRV] = useState();
  const [agreementCreateStatus, setagreementCreateStatus] = useState({
    agreementCreatedStatus: false,
  });
  const [agreementDetailsData, setAgreementDetailsData] = useState();
  const [searchResult, setsearchResult] = useState();
  const [newlyAddedItem, setnewlyAddedItem] = useState();
  const [addedItem, setaddedItem] = useState();
  const [itemArrayData, setitemArray] = useState();
  const [copyArrayData, setSwitchoutcopyArrayData] = useState();
  const [depPercentage, setdepPercentage] = useState('0.00');
  const [containDepartment, setcontainDepartment] = useState();
  const [containSubDepartment, setcontainSubDepartment] = useState();
  const [containCondition, setcontainCondition] = useState();
  const [pendingSOAgr, setpendingSOAgr] = useState(false);
  const [pendingSOpreInvData, setpendingSOpreInvData] = useState();
  const [deliveryMethodData, setdeliveryMethodData] = useState();
  const [customerSignData, setcustomerSignData] = useState();
  const [switchoutagreementnum, setswitchoutagreementnum] = useState();
  const [isswitchoutCancelled, setisswitchoutCancelled] = useState(false);
  const [autoPayStatus, setautoPayStatus] = useState('0');
  const [retentionActivityCall, setretentionActivityCall] = useState('0');

  useEffect(() => {
    console.log('oldInvRV in direct context file', oldInvRV);
    console.log('selectedInvNumber in direct context file', selectedInvNumber);
    console.log(
      'entireAgrInfoResponse in direct context value',
      entireAgrInfoResponse
    );
  }, [oldInvRV, selectedInvNumber, entireAgrInfoResponse, switchOutData]);

  return (
    <div>
      <SwitchOutAgreementContext.Provider
        value={{
          CustomerInfo,
          setCustomerInfo,
          selectedInvNumber,
          setselectedInvNumber,
          searchCriteria,
          itemArrayDataSwitchOut,
          numberOfInventory,
          setsearchCriteria,
          setitemArrayData,
          setnumberOfInventory,
          switchOutData,
          setSwitchOutData,
          agreementCreateStatus,
          setagreementCreateStatus,
          agreementDetailsData,
          setAgreementDetailsData,
          newlyAddedItem,
          setnewlyAddedItem,
          setsearchResult,
          searchResult,
          setaddedItem,
          addedItem,
          setitemArray,
          itemArrayData,
          setSwitchoutcopyArrayData,
          copyArrayData,
          setoldInvRV,
          oldInvRV,
          setentireAgrInfoResponse,
          entireAgrInfoResponse,
          depPercentage,
          setdepPercentage,
          containDepartment,
          setcontainDepartment,
          containSubDepartment,
          setcontainSubDepartment,
          containCondition,
          setcontainCondition,
          pendingSOAgr,
          setpendingSOAgr,
          pendingSOpreInvData,
          setpendingSOpreInvData,
          deliveryMethodData,
          setdeliveryMethodData,
          customerSignData,
          setcustomerSignData,
          setswitchoutagreementnum,
          switchoutagreementnum,
          isswitchoutCancelled,
          setisswitchoutCancelled,
          autoPayStatus,
          setautoPayStatus,
          setisSOEnabledbyconfig,
          isSOEnabledbyconfig,
          gotSOConfigResponse,
          setgotSOConfigResponse,
          setisChoosePriceConfig,
          isChoosePriceConfig,
          retentionActivityCall,
          setretentionActivityCall,
        }}
      >
        {props.children}
      </SwitchOutAgreementContext.Provider>
    </div>
  );
}
