/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  RACTextbox,
  RACModalCard,
  RACButton,
  RACCOLOR,
} from '@rentacenter/racstrap';
import PaymentCostProps from './PaymentCostInterface';
import Modal from 'react-modal';
import { ReactComponent as RacLogo } from '../../assets/images/RAC-Logo.svg';
import SecondFactor from '../../Rental/TwoFactorPopup/TwoFactorPopup';
import CurrencyInput from 'react-currency-input-field';
import { paymentCost } from '../../../JSstyles/paymentCost';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReinstateAgreementContext } from '../../../context/ReinstatementAgreementContext';

export default function PaymentCost(props: any) {
  const { isSacDaysCompleted, epoAmount } = useContext(
    ReinstateAgreementContext
  );
  const classes = props.throwStyle;
  const paymentCostClassName = paymentCost();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [OtherTest, setOtherTest]: any = useState('0.00');
  //PS_AD-1 creating object to store total payment values
  const TotalpaymemtObj = {
    totalInitialPayment: '',
    regularPaymentsCount: '',
    regularPaymentsAmount: '',
    finalPayment: '',
    totalPaymentsCount: '',
    sacTotal: '',
    AgreementTotal: '',
  };
  //PS_AD-1 creating object to store initial payment values
  const InitialPaymentObj = {
    OtherAmt: '0.00',
    ProcessingFeeAmt: '',
    rate: '',
    Discount: '0.00',
    extensionAmount: '',
    policy: '',
    ldw: '',
    tax: '',
  };
  //PS_AD-1 creating state variable to hold TotalpaymemtObj
  const [TotalPayment, setTotalPayment] = useState(TotalpaymemtObj);
  //PS_AD-1 creating state variable to hold InitialPaymentObj
  const [IntPayment, setIntPayment] = useState(InitialPaymentObj);
  //PS_AD-1 creating state variable to store total Initial payment
  const [TotalIniPayment, setTotalIniPayment] = useState('');
  //PS_AD-1 creating state variable to store other input enable disable
  const [OtherDisable, setOtherDisable] =
    useState<PaymentCostProps['OtherDisable']>(false);
  const [SacDisable, setSacDisable] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ProcessingFeeLimit, setProcessingFeeLimit] = useState('0.00');
  const [ProcessingFee, setProcessingFee] = useState('0.00');

  const [ProcessingFeePopupOpen, setProcessingFeePopupOpen] = useState(false);
  const [AuthenticationPopupOpen, setAuthenticationPopupOpen] = useState(false);
  const [ProcessingFeeErrorLbl, setProcessingFeeErrorLbl] = useState(false);
  const [CurrentRole, setCurrentRole] = useState(props.CurrentRole);
  const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState(false);
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState(false);
  const [moduleName, setmoduleName] = useState('Edit SAC Fee');
  const [SecondFactorOpen, setSecondFactorOpen] = useState(false);
  const [SecondFactorUsingField, setSecondFactorUsingField] = useState('');
  const [SACoriginalAmount, setSACoriginalAmount] = useState(0);
  const [SACallowedAmt, setSACallowedAmt] = useState('');
  const [SAClowerlimit, setSAClowerlimit] = useState(0);
  const [ExceedPopupOpen, setExceedPopupOpen] = useState(false);
  const [OverRidePopupOpen, setOverRidePopupOpen] = useState(false);
  const [OtherAmtWOproFee, setOtherAmtWOproFee]: any = useState();
  const [FeeArrWOProFee, setFeeArrWOProFee] = useState([]);
  useEffect(() => {
    const InitialPaymentProps = props.InitialPaymentValues;
    const TotalPaymentProps = props.TotalPaymentValues;
    const OtherFeeArray = props.OtherFeeArray;
    const AgrementTotalProps = props.AgreementTotal;
    // eslint-disable-next-line no-console
    console.log('Initial payment props------>', InitialPaymentProps);
    // eslint-disable-next-line no-console
    console.log('Total payment props------>', TotalPaymentProps);
    // eslint-disable-next-line no-console
    console.log('Other Fee Array------>', OtherFeeArray);
    // eslint-disable-next-line no-console
    console.log('AgrementTotalProps from parent comp', AgrementTotalProps);

    if (InitialPaymentProps !== undefined) {
      props.setPaymentCostLoading(false);
      setIntPayment({
        ...IntPayment,
        OtherAmt: InitialPaymentProps.otherFees,
        rate: InitialPaymentProps.rate,
        extensionAmount: InitialPaymentProps.extensionAmount,
        ldw: InitialPaymentProps.ldw,
        tax: InitialPaymentProps.totalTax,
        policy: InitialPaymentProps.policy,
      });
      setTotalIniPayment(InitialPaymentProps.initialPayment);
    }
    if (TotalPaymentProps !== undefined && AgrementTotalProps !== undefined) {
      setTotalPayment({
        ...TotalPayment,
        totalInitialPayment: TotalPaymentProps.initialPaymentTotal,
        regularPaymentsCount: TotalPaymentProps.regularPaymentsCount,
        regularPaymentsAmount: TotalPaymentProps.regularPaymentsAmount,
        finalPayment: TotalPaymentProps.finalPayment,
        totalPaymentsCount: TotalPaymentProps.totalPaymentsCount,
        sacTotal: TotalPaymentProps.sacTotal,
        AgreementTotal: AgrementTotalProps,
      });
      setOtherDisable(true);
      setSACoriginalAmount(parseFloat(TotalPaymentProps.sacTotal));
      setSACallowedAmt(TotalPaymentProps.sacTotal);
      setSAClowerlimit(parseFloat(TotalPaymentProps.sacTotal) * 0.6);
    }
    if (OtherFeeArray !== undefined) {
      if (OtherFeeArray.length > 0) {
        const TempOtherArr = OtherFeeArray.filter((e) => e.feeType === 'PROC');
        if (TempOtherArr.length > 0) {
          setOtherDisable(false);
          const FeeLimit = TempOtherArr[0].feeAmount;
          setProcessingFeeLimit(FeeLimit);
        }
        const TempOtherFilterArr = OtherFeeArray.filter(
          (e) => e.feeType !== 'PROC'
        );
        setFeeArrWOProFee(TempOtherFilterArr);
        let TempAmt = 0;
        for (let i = 0; i <= TempOtherFilterArr.length - 1; i++) {
          TempAmt = TempAmt + TempOtherFilterArr[i].feeAmount;
        }
        setOtherAmtWOproFee(TempAmt);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.ExtAmt,
    ProcessingFee,
    props.InitialPaymentValues,
    props.TotalPaymentValues,
    props.OtherFeeArray,
    props.AgreementTotal,
  ]);
  useEffect(() => {
    if (props.EditDisablemode === true) {
      setSacDisable(true);
      setOtherDisable(true);
    } else if (props.EditDisablemode === false) {
      setSacDisable(false);
      // setOtherDisable(false);
    }
  }, [props.EditDisablemode]);
  useEffect(() => {
    if (TwoFactorCancelClick === true && SecondFactorUsingField === 'SAC') {
      console.log('Inside completed false', SACallowedAmt);
      setSecondFactorOpen(false);
      setTotalPayment({
        ...TotalPayment,
        sacTotal: SACallowedAmt,
      });
    } else if (
      TwoFactorCompleted === true &&
      SecondFactorUsingField === 'SAC'
    ) {
      console.log('Inside complete sac set', TotalPayment.sacTotal);
      setSecondFactorOpen(false);
      const tempvalue = parseFloat(TotalPayment.sacTotal);
      const tempstrvalue = tempvalue.toFixed(2);
      setTotalPayment({
        ...TotalPayment,
        sacTotal: tempstrvalue,
      });
      setSACallowedAmt(tempstrvalue);
      const obj = {
        sacPrice: parseFloat(TotalPayment.sacTotal),
      };
      props.SacChanged(obj);
    }
    if (
      TwoFactorCancelClick === true &&
      SecondFactorUsingField === 'OtherAmt'
    ) {
      setSecondFactorOpen(false);
      setProcessingFeePopupOpen(false);
    } else if (
      TwoFactorCompleted === true &&
      SecondFactorUsingField === 'OtherAmt'
    ) {
      setSecondFactorOpen(false);
      setProcessingFeePopupOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TwoFactorCancelClick, TwoFactorCompleted]);
  useEffect(() => {
    setCurrentRole(props.CurrentRole);
  }, [props.CurrentRole]);

  const OtherAmountOnclick = () => {
    setSecondFactorUsingField('OtherAmt');
    setTwoFactorCompleted(false);
    setSecondFactorOpen(true);
  };
  const OtherAmtOnblurClick = (e: any) => {
    try {
      const InputTxt = e.target.value;
      let InputTxtArr = InputTxt.split('');
      if (InputTxtArr.includes(',') === true) {
        InputTxtArr = InputTxtArr.filter((element: any) => element != ',');
        let TempString = '';
        for (let i = 0; i <= InputTxtArr.length - 1; i++) {
          TempString = TempString + InputTxtArr[i];
        }
        const FloatInputTxt = parseFloat(TempString);
        const ResultInputTxt = FloatInputTxt.toFixed(2);
        setOtherTest(ResultInputTxt);
        setIntPayment({ ...IntPayment, OtherAmt: ResultInputTxt });
      } else if (InputTxt === '.' || InputTxt === '') {
        setOtherTest('0.00');
        setIntPayment({ ...IntPayment, OtherAmt: '0.00' });
      } else {
        const FloatInputTxt = parseFloat(InputTxt);
        const ResultInputTxt = FloatInputTxt.toFixed(2);
        setOtherTest(ResultInputTxt);
        setIntPayment({ ...IntPayment, OtherAmt: ResultInputTxt });
      }
    } catch {
      throw Error;
    }
  };
  const AuthentivcationLoginClcik = () => {
    try {
      setAuthenticationPopupOpen(false);
      setProcessingFeePopupOpen(true);
    } catch {
      throw Error;
    }
  };
  const ProcessingFeeOnClick = (e: any) => {
    try {
      const InputTxt = e.target;
      InputTxt.select();
    } catch {
      throw Error;
    }
  };
  const ProcessingFeeOnchange = (value: any) => {
    try {
      const TempTxt = parseFloat(value);
      if (TempTxt <= 999.0) {
        setIntPayment({ ...IntPayment, ProcessingFeeAmt: value });
      }
      if (value === undefined || value === '') {
        setIntPayment({ ...IntPayment, ProcessingFeeAmt: '' });
      }
    } catch {
      throw Error;
    }
  };
  const ProcessingFeeOnBlurClick = (e: any) => {
    try {
      const InputTxt = e.target.value;
      let InputTxtArr = InputTxt.split('');
      if (InputTxtArr.includes(',') === true) {
        InputTxtArr = InputTxtArr.filter((element: any) => element !== ',');
        let TempString = '';
        for (let i = 0; i <= InputTxtArr.length - 1; i++) {
          TempString = TempString + InputTxtArr[i];
        }
        const FloatInputTxt = parseFloat(TempString);
        const ResultInputTxt = FloatInputTxt.toFixed(2);

        setIntPayment({ ...IntPayment, ProcessingFeeAmt: ResultInputTxt });
      } else if (InputTxt === '.' || InputTxt === '') {
        setOtherTest('0.00');
        setIntPayment({ ...IntPayment, OtherAmt: '0.00' });
      } else {
        const FloatInputTxt = parseFloat(InputTxt);
        const ResultInputTxt = FloatInputTxt.toFixed(2);

        setIntPayment({ ...IntPayment, ProcessingFeeAmt: ResultInputTxt });
      }
    } catch {
      throw Error;
    }
  };
  const ProcessingFeeCloseClick = () => {
    try {
      setProcessingFeePopupOpen(false);
      setIntPayment({ ...IntPayment, ProcessingFeeAmt: '' });
      setProcessingFeeErrorLbl(false);
    } catch {
      throw Error;
    }
  };

  const OverRideAmoutClick = () => {
    try {
      const InputProcessingFeeAmt = parseFloat(IntPayment.ProcessingFeeAmt);
      const ProcessingFeeScale = parseFloat(ProcessingFeeLimit);
      if (InputProcessingFeeAmt <= ProcessingFeeScale) {
        // setProcessingFee(IntPayment.ProcessingFeeAmt);
        // setIntPayment({ ...IntPayment, ProcessingFeeAmt: '' });
        const TempReq: any = FeeArrWOProFee;
        const Tempval =
          parseFloat(IntPayment.ProcessingFeeAmt) +
          parseFloat(OtherAmtWOproFee);
        const ConvTempval = Tempval.toString();
        setIntPayment({ ...IntPayment, OtherAmt: ConvTempval });
        const TempTotalIniAmt =
          Tempval +
          parseFloat(IntPayment.Discount) +
          parseFloat(IntPayment.rate) +
          parseFloat(IntPayment.extensionAmount) +
          parseFloat(IntPayment.ldw) +
          parseFloat(IntPayment.tax) +
          parseFloat(IntPayment.policy);
        setTotalIniPayment(TempTotalIniAmt.toFixed(2));
        setProcessingFeeErrorLbl(false);
        setProcessingFeePopupOpen(false);
        const Obj = {
          feeType: 'PROC',
          feeAmount: parseFloat(IntPayment.ProcessingFeeAmt),
        };
        if (TempReq.filter((e) => e.feeType === 'PROC').length > 0) {
          const indexOfDuplicate = TempReq.findIndex(
            (arr) => arr.feeType === Obj.feeType
          );
          TempReq[indexOfDuplicate] = Obj;
        } else {
          TempReq.push(Obj);
        }
        console.log('Regenerated fee arr', TempReq);
        props.OtherAmtChanged(TempReq);
      } else {
        setProcessingFeeErrorLbl(true);
      }
    } catch {
      throw Error;
    }
  };

  const SacAmountChange = (value: any) => {
    setSecondFactorOpen(false);
    setTotalPayment({ ...TotalPayment, sacTotal: value });
  };
  const SAConblurClick = (): any => {
    console.log('sactotal in sac onblur', TotalPayment.sacTotal);
    console.log('SACallowedAmt in sac onblur', SAClowerlimit);
    console.log('SACoriginalAmount in sac onblur', SACoriginalAmount);
    if (
      SACoriginalAmount >= parseFloat(TotalPayment.sacTotal) &&
      TotalPayment.sacTotal !== undefined &&
      SACallowedAmt !== TotalPayment.sacTotal
    ) {
      const value = parseFloat(TotalPayment.sacTotal);
      if (SAClowerlimit > value) {
        setOverRidePopupOpen(true);
        setTotalPayment({
          ...TotalPayment,
          sacTotal: SACallowedAmt,
        });
      } else if (SACoriginalAmount < value) {
        setExceedPopupOpen(true);
        setTotalPayment({
          ...TotalPayment,
          sacTotal: SACallowedAmt,
        });
      } else if (SAClowerlimit <= value && SACoriginalAmount >= value) {
        console.log('Inside sac crt loop');
        setSecondFactorOpen(true);
        setSecondFactorUsingField('SAC');
        setTwoFactorCancelClick(false);
        setTwoFactorCompleted(false);
      }
    } else if (SACoriginalAmount < parseFloat(TotalPayment.sacTotal)) {
      setOverRidePopupOpen(true);
      setTotalPayment({
        ...TotalPayment,
        sacTotal: SACallowedAmt,
      });
    } else {
      setTotalPayment({
        ...TotalPayment,
        sacTotal: SACallowedAmt,
      });
    }
  };
  const MoneyConvertion = (value) => {
    let convertedtext = '';
    const InputTxt = value;
    let InputTxtArr = InputTxt.split('');
    if (InputTxtArr.includes(',') === true) {
      InputTxtArr = InputTxtArr.filter((element: any) => element != ',');
      let TempString = '';
      for (let i = 0; i <= InputTxtArr.length - 1; i++) {
        TempString = TempString + InputTxtArr[i];
      }
      const FloatInputTxt = parseFloat(TempString);
      convertedtext = FloatInputTxt.toFixed(2);
    } else if (InputTxt === '.' || InputTxt === '') {
      convertedtext = '0.00';
    } else {
      const FloatInputTxt = parseFloat(InputTxt);
      convertedtext = FloatInputTxt.toFixed(2);
    }
    return convertedtext;
  };
  function formatMoney(Money: any) {
    console.log('Money', Money);
    if (Money !== undefined && typeof Money === 'string') {
      let MoneyFormat = Money.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (MoneyFormat === '0') {
        MoneyFormat = `${MoneyFormat}.00`;
      }
      return MoneyFormat;
    }
  }
  const ExceedPopup = () => {
    return (
      <div
        className=""
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography
            className={`${classes.semiBold} ${paymentCostClassName.formLabel}`}
          >
            {' '}
            Cash price cannot exceed original price
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter} `}
        >
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
            onClick={() => setExceedPopupOpen(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const OverRidePopup = () => {
    return (
      <div
        className=""
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography
            className={`${classes.semiBold} ${paymentCostClassName.formLabel}`}
          >
            {' '}
            Amount override cannot exceed the limit allowed
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter} `}
        >
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
            onClick={() => setOverRidePopupOpen(false)}
          >
            ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const ProcessingFeePopup = () => {
    return (
      <div
        id="ProcessingFee"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>
            {' '}
            Processing Fee Amount
          </Typography>
          <CurrencyInput
            id="input-example"
            name="OtherAmt"
            style={{ zIndex: 0, textAlign: 'right' }}
            className="form-control"
            decimalsLimit={2}
            data-testid="ProcessingFeeTxtBoxTest"
            value={IntPayment.ProcessingFeeAmt}
            onValueChange={(value) => ProcessingFeeOnchange(value)}
            onBlur={(e) => ProcessingFeeOnBlurClick(e)}
            onClick={(e) => ProcessingFeeOnClick(e)}
          />
        </Grid>
        <label
          style={{
            color: 'red',
            fontSize: 'small',
            marginLeft: '5%',

            visibility: ProcessingFeeErrorLbl === false ? 'hidden' : 'visible',
          }}
          data-testid="OverRideErrorMsgTest"
        >
          Please enter a valid processing fee amount less than $
          {`${ProcessingFeeLimit}`}
        </label>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => OverRideAmoutClick()}
          >
            Override Amount
          </RACButton>
        </Grid>
      </div>
    );
  };
  return (
    <div>
      {/* second level authentication popup starts here */}
      {SecondFactorOpen === true ? (
        <SecondFactor
          setTwoFactorCancelClick={setTwoFactorCancelClick}
          setTwoFactorCompleted={setTwoFactorCompleted}
          moduleName={moduleName}
          currentRole={CurrentRole}
        />
      ) : null}

      {/* Processing fee popup ends here */}
      <RACModalCard
        isOpen={ProcessingFeePopupOpen}
        closeIcon={false}
        maxWidth="xs"
        borderRadius={'25px !important'}
      >
        {ProcessingFeePopup()}
      </RACModalCard>

      <RACModalCard
        isOpen={ExceedPopupOpen}
        maxWidth="xs"
        borderRadius={'25px !important'}
      >
        {ExceedPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={OverRidePopupOpen}
        maxWidth="xs"
        borderRadius={'25px !important'}
      >
        {OverRidePopup()}
      </RACModalCard>

      <Grid container className={classes.mb6}>
        <Grid item className={paymentCostClassName.paymentWidget}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Typography className={classes.title}>Initial Payment</Typography>

              <Card className={classes.card}>
                <CardContent className={classes.p32}>
                  <Grid container className={`${classes.mb2} ${classes.mt2}`}>
                    <Grid item md={6} className={classes.floatLeft}>
                      <Typography
                        variant="subtitle1"
                        className={paymentCostClassName.cardLabel}
                      >
                        Other
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Grid className={classes.floatRight}>
                        <Typography
                          className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
                        >
                          $
                        </Typography>
                        <CurrencyInput
                          id="input-example"
                          name=""
                          style={{ zIndex: 0, textAlign: 'right' }}
                          className={
                            OtherDisable
                              ? `${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`
                              : `${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput}`
                          }
                          decimalsLimit={2}
                          data-testid="ProcessingFeeTxtBoxTest"
                          value={IntPayment.OtherAmt}
                          onBlur={(e) => OtherAmtOnblurClick(e)}
                          onClick={() => OtherAmountOnclick()}
                          disabled={OtherDisable}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                    <Grid item md={7}>
                      <Typography
                        variant="subtitle1"
                        className={paymentCostClassName.cardLabel}
                      >
                        Rate
                      </Typography>
                    </Grid>
                    <Grid item md={5}>
                      {!props.PaymentCostLoading ? (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.widgetAlign}
                        >
                          $ {formatMoney(IntPayment.rate)}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.widgetAlign}
                        >
                          <Skeleton
                            count={1}
                            baseColor="#FFFFFF"
                            highlightColor="#e7e7e7"
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                    <Grid item md={7}>
                      <Typography
                        variant="subtitle1"
                        className={paymentCostClassName.cardLabel}
                      >
                        Discount
                      </Typography>
                    </Grid>
                    <Grid item md={5}>
                      {!props.PaymentCostLoading ? (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.widgetAlign}
                        >
                          $ {formatMoney(IntPayment.Discount)}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.widgetAlign}
                        >
                          <Skeleton
                            count={1}
                            baseColor="#FFFFFF"
                            highlightColor="#e7e7e7"
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                    <Grid item md={7}>
                      <Typography
                        variant="subtitle1"
                        className={paymentCostClassName.cardLabel}
                      >
                        Extension Amount
                      </Typography>
                    </Grid>
                    <Grid item md={5}>
                      {!props.PaymentCostLoading ? (
                        <Typography>
                          {IntPayment.extensionAmount === '0.00' ? (
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ {formatMoney(IntPayment.extensionAmount)}
                            </Typography>
                          ) : (
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              ($ {formatMoney(IntPayment.extensionAmount)})
                            </Typography>
                          )}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.widgetAlign}
                        >
                          <Skeleton
                            count={1}
                            baseColor="#FFFFFF"
                            highlightColor="#e7e7e7"
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {props.PolicyEnabled === true ? (
                    <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                      <Grid item md={7}>
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.cardLabel}
                        >
                          Policy
                        </Typography>
                      </Grid>
                      <Grid item md={5}>
                        {!props.PaymentCostLoading ? (
                          <Typography
                            variant="subtitle1"
                            className={paymentCostClassName.widgetAlign}
                          >
                            $ {formatMoney(IntPayment.policy)}
                          </Typography>
                        ) : (
                          <Typography
                            variant="subtitle1"
                            className={paymentCostClassName.widgetAlign}
                          >
                            <Skeleton
                              count={1}
                              baseColor="#FFFFFF"
                              highlightColor="#e7e7e7"
                            />
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  ) : null}

                  <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                    <Grid item md={7}>
                      <Typography
                        variant="subtitle1"
                        className={paymentCostClassName.cardLabel}
                      >
                        LDW
                      </Typography>
                    </Grid>
                    <Grid item md={5}>
                      {!props.PaymentCostLoading ? (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.widgetAlign}
                        >
                          $ {formatMoney(IntPayment.ldw)}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.widgetAlign}
                        >
                          <Skeleton
                            count={1}
                            baseColor="#FFFFFF"
                            highlightColor="#e7e7e7"
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                    <Grid item md={7}>
                      <Typography
                        variant="subtitle1"
                        className={paymentCostClassName.cardLabel}
                      >
                        Tax
                      </Typography>
                    </Grid>
                    <Grid item md={5}>
                      {!props.PaymentCostLoading ? (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.widgetAlign}
                        >
                          $ {formatMoney(IntPayment.tax)}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.widgetAlign}
                        >
                          <Skeleton
                            count={1}
                            baseColor="#FFFFFF"
                            highlightColor="#e7e7e7"
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    className={`${classes.mb2} ${classes.pt2} ${paymentCostClassName.payscheduleBorder} ${paymentCostClassName.alignCenter} ${classes.mt4}`}
                  >
                    <Grid item md={7}>
                      <Typography
                        variant="subtitle1"
                        className={`${classes.font18} ${classes.semiBold}`}
                      >
                        Initial Payment{' '}
                      </Typography>
                    </Grid>
                    <Grid item md={5}>
                      {!props.PaymentCostLoading ? (
                        <Typography
                          variant="subtitle1"
                          className={`${classes.font24} ${classes.bold} ${classes.textRight}`}
                        >
                          $ {formatMoney(TotalIniPayment)}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          className={`${classes.font24} ${classes.bold} ${classes.textRight}`}
                        >
                          <Skeleton
                            count={1}
                            baseColor="#FFFFFF"
                            highlightColor="#e7e7e7"
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={6}>
              <Typography className={classes.title}>
                Total Payment/Total Cost
              </Typography>

              <Card className={paymentCostClassName.bluecard}>
                <CardContent className={classes.p4}>
                  <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                    <Grid item md={7}>
                      <Typography
                        variant="subtitle1"
                        className={paymentCostClassName.blueCardLabel}
                      >
                        Initial Payment
                      </Typography>
                    </Grid>
                    <Grid item md={5}>
                      {!props.PaymentCostLoading ? (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.blueWidgetAlign}
                        >
                          $ {formatMoney(TotalPayment.totalInitialPayment)}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.blueWidgetAlign}
                        >
                          <Skeleton
                            count={1}
                            baseColor={RACCOLOR.NORMAL_BLUE}
                            highlightColor="#FFFFFF"
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                    <Grid item md={7}>
                      {!props.PaymentCostLoading ? (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.blueCardLabel}
                        >
                          Regular Payments ({TotalPayment.regularPaymentsCount})
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.blueCardLabel}
                        >
                          Regular Payments
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={5}>
                      {!props.PaymentCostLoading ? (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.blueWidgetAlign}
                        >
                          $ {formatMoney(TotalPayment.regularPaymentsAmount)}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.blueWidgetAlign}
                        >
                          <Skeleton
                            count={1}
                            baseColor={RACCOLOR.NORMAL_BLUE}
                            highlightColor="#FFFFFF"
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                    <Grid item md={7}>
                      <Typography
                        variant="subtitle1"
                        className={paymentCostClassName.blueCardLabel}
                      >
                        Final Payment
                      </Typography>
                    </Grid>
                    <Grid item md={5}>
                      {!props.PaymentCostLoading ? (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.blueWidgetAlign}
                        >
                          $ {formatMoney(TotalPayment.finalPayment)}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.blueWidgetAlign}
                        >
                          <Skeleton
                            count={1}
                            baseColor={RACCOLOR.NORMAL_BLUE}
                            highlightColor="#FFFFFF"
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container className={`${classes.mb2} ${classes.mt4}`}>
                    <Grid item md={12}>
                      {!props.PaymentCostLoading ? (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.blueCardLabel}
                        >
                          {' '}
                          Total Payments ({TotalPayment.totalPaymentsCount})
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.blueCardLabel}
                        >
                          {' '}
                          Total Payments
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Card
                    className={`${classes.card} ${classes.p3} ${paymentCostClassName.mtcustom} ${paymentCostClassName.totalPaymentShade}`}
                  >
                    <Grid container className={classes.mb2}>
                      <Grid item md={7} className={classes.floatLeft}>
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.totalValue}
                        >
                          {!isSacDaysCompleted
                            ? 'Same As Cash Total'
                            : 'EPO Amount'}
                        </Typography>
                      </Grid>

                      <Grid item md={5}>
                        <Grid className={classes.floatRight}>
                          <Typography
                            className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
                          >
                            $
                          </Typography>
                          <CurrencyInput
                            id="input-example"
                            name=""
                            style={{ zIndex: 0, textAlign: 'right' }}
                            className={`${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`}
                            decimalsLimit={2}
                            data-testid="ProcessingFeeTxtBoxTest"
                            value={
                              !isSacDaysCompleted
                                ? TotalPayment.sacTotal
                                : epoAmount
                            }
                            onValueChange={(value) => SacAmountChange(value)}
                            onBlur={() => SAConblurClick()}
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className={paymentCostClassName.alignCenter}
                    >
                      <Grid item md={7}>
                        <Typography
                          variant="subtitle1"
                          className={paymentCostClassName.totalValue}
                        >
                          Agreement Total
                        </Typography>
                      </Grid>
                      <Grid item md={5}>
                        {!props.PaymentCostLoading ? (
                          <Typography
                            variant="subtitle1"
                            className={paymentCostClassName.agreementTotal}
                          >
                            $ {formatMoney(TotalPayment.AgreementTotal)}
                          </Typography>
                        ) : (
                          <Typography
                            variant="subtitle1"
                            className={paymentCostClassName.agreementTotal}
                          >
                            <Skeleton
                              count={1}
                              baseColor="#FFFFFF"
                              highlightColor="#e7e7e7"
                            />
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Card>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
