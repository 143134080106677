/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';

import BlueCard from './blueCard';
import WhiteCard from './whiteCard';
import { Usercontext } from './searchResult';
import CurrencyInput from 'react-currency-input-field';
import { addedItemStyles } from '../../../JSstyles/addedItemStyles';
import { ReinstateAgreementContext } from '../../../context/ReinstatementAgreementContext';
import {
  RACTextbox,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@rentacenter/racstrap';

const AddedItem = (props: any) => {
  const classes = props.throwStyle; //This will hold the global styles
  const addedItemClassName = addedItemStyles(); //This will hold the component specific styles
  const shareData: any = useContext(Usercontext); //This will hold the API result
  const [agrRate, setagrRate]: any = useState('0.00');
  const [agrTerm, setagrTerm] = useState('0.00');
  const [agrTotal, setAgrTotal] = useState('0.00');
  const [cashPrice, setCashPrice] = useState('0.00');
  const { isSacDaysCompleted } = useContext(ReinstateAgreementContext);
  useEffect(() => {
    if (shareData !== undefined) {
      console.log('Added Item Shared Data', shareData);
      setagrRate(shareData.agreementPricing.agreementRate);
      setagrTerm(shareData.agreementPricing.agreementTerm);
      setAgrTotal(shareData.agreementPricing.agreementTotal);
      setCashPrice(shareData.agreementPricing.cashPrice);
    } else {
      setagrRate('');
      setagrTerm('');
      setAgrTotal('');
      setCashPrice('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareData]); //PS - 24 This method wil check the value of API response if it's undefined then the values are stored in state variable to get populate it

  const temp = (itemID: any) => {
    props.triggeroneDelete(itemID);
  }; //This method will be throwing the deleted item id from blueCard to searchResult component

  return (
    <Grid item md={3}>
      <Typography className={`${classes.title} ${classes.mt2}`} variant="h6">
        Added Items
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.p1}>
          <Card
            className={`${classes.racBlue} ${classes.card} ${classes.p3} ${classes.pb0}`}
          >
            <BlueCard
              // throwPackage={throwPackageValue}
              triggerTemp={temp}
              throwStyle={props.throwStyle}
            />
          </Card>

          <div className={`${classes.mt3}`}>
            <div className={classes.row}>
              <Typography className={classes.title} variant="h6">
                Agreement Total
              </Typography>
              <WhiteCard throwStyle={props.throwStyle} />
              <Grid md={12} className={`${classes.mb3} ${classes.flexCenter}`}>
                <Typography variant="caption" className={classes.formLabel}>
                  Agreement Rate
                </Typography>

                <Grid
                  className={`${addedItemClassName.inputGroup} ${classes.w50} ${classes.floatRight}`}
                >
                  <Typography className={addedItemClassName.inputGroupText}>
                    $
                  </Typography>

                  <CurrencyInput
                    id="AgrRate"
                    name="AgrRate"
                    value={agrRate}
                    className={`${classes.formControl} ${addedItemClassName.currencyAlign} ${addedItemClassName.disabledColour} ${classes.semiBold}`}
                    decimalScale={2}
                    decimalsLimit={2}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid md={12} className={`${classes.mb3} ${classes.flexCenter}`}>
                <Typography variant="caption" className={classes.formLabel}>
                  Agreement Term
                </Typography>

                <Grid
                  className={`${addedItemClassName.inputGroup} ${classes.w50} ${classes.floatRight}`}
                >
                  <RACTextbox
                    id="AgrTerm"
                    type="number"
                    name="itemNo"
                    isCurrency={false}
                    digitFormat="normal"
                    value={agrTerm}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <Card
            className={`${classes.card} ${classes.racTotalBlue} ${classes.p3}`}
          >
            <Grid
              md={12}
              className={`${addedItemClassName.agrAddedTotal} ${classes.mb2}`}
            >
              {isSacDaysCompleted === false ? (
                <Typography
                  variant="caption"
                  className={`${classes.textBlue} ${classes.font14} ${classes.semiBold}`}
                >
                  Same As Cash Total
                </Typography>
              ) : (
                <Typography
                  variant="caption"
                  className={`${classes.textBlue} ${classes.font14} ${classes.semiBold}`}
                >
                  EPO Amount
                </Typography>
              )}

              <Typography
                variant="caption"
                className={`${classes.textBlue} ${classes.font18} ${classes.bold}`}
              >
                ${cashPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
            </Grid>
            <Grid
              md={12}
              className={`${addedItemClassName.agrAddedTotal} ${classes.mb2}`}
            >
              <Typography
                variant="caption"
                className={`${classes.textBlue} ${classes.font14} ${classes.semiBold}`}
              >
                Agreement Total
              </Typography>

              <Typography
                variant="caption"
                className={`${classes.textBlue} ${classes.font18} ${classes.bold}`}
              >
                $
                {agrTotal.length > 6
                  ? agrTotal.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : agrTotal}
              </Typography>
            </Grid>
          </Card>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AddedItem;
