/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-identical-expressions */
/* eslint-disable no-constant-condition */
/* eslint-disable prettier/prettier */
/* eslint-disable */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
//import Modal from 'react-modal';
import { FindCoCustomer, GetCustomer } from '../../../api/user';
import {
  InsertCocustomer,
  GetCustomerId,
  getAgreementCustomerInfo,
  GetDeReason,
} from '../../../api/user';
import clsx from 'clsx';

import { ReactComponent as RACLogo } from '../../../assets/images/RACUpdatedLogoNew.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as AlertIcon } from '../../../assets/images/no-records-found.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Successimg } from '../../../assets/images/success-icon.svg';
import { ReactComponent as Loadalert } from '../../../assets/images/No-records.svg';
import { ReactComponent as RacLogo } from '../../../assets/images/RACUpdatedLogoNew.svg';
import { ReactComponent as SearchImage } from '../../../assets/images/search-btn.svg';
// import AnimLoading from '../../../assets/images/AnimLoading.gif';
// import FadeLoader from 'react-spinners/FadeLoader';

import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';

import { addCoCustomerStyles } from '../../../JSstyles/addCoCustomerStyles';
import { twoFactorStyles } from '../../../JSstyles/twoFactorStyles';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import PinField from 'react-pin-input';
import { ContainerContext } from '../../../app/App';

import { CustomPropInterface } from '../../../index';
// import CircularProgress from '@mui/material/CircularProgress';
import {
  RACTextbox,
  RACModalCard,
  RACDatePicker,
  RACTableCell,
  RACTable,
  RACTableRow,
  RACButton,
  CircularProgress,
  Grid,
  Typography,
  RACRadio,
  RACSelect,
  makeStyles,
  RACCOLOR,
} from '@rentacenter/racstrap';
import {
  getCurrentInfo,
  getPinDetails,
  getprofileaccess,
  getsecondfactor,
  GetRole,
  RunDE,
  updatecustomer,
  GetRemoteLoginStatus,
  storeException
} from '../../../api/user';
// import { Grid } from '@mui/material';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
// import clsx from 'clsx';
// import { makeStyles } from '@material-ui/core/styles';

// import '../../styles/agreement.css';
import { AgreementContext } from '../../../context/AgreementContext';
import { SwitchOutAgreementContext } from '../../../context/SwitchOutAgreementContext';
import { getPrimaryPhoneNumber } from '../../../constants/constants';
import SecondFactor from '../TwoFactorPopup/TwoFactorPopup';
import { FeatureFlagContext } from '../../../context/FeatureFlagContext';

interface ParamType {
  agreementId: string;
  customerId: string;
}
const useClasses = makeStyles((theme: any) => ({
  textCenter: {
    textAlign: 'center',
  },
  logoResponsive: {},
  spacerMargin: {
    margin: theme.typography.pxToRem(8),
  },
  spacerMT1: {
    marginTop: theme.typography.pxToRem(4),
  },
  racLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  formLabel: {
    marginBottom: '0.2rem',
    color: '#111111',
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  floatRight: {
    float: 'right',
  },
  spacerMT5: {
    marginTop: theme.typography.pxToRem(48),
  },
  spacerMB3: {
    marginBottom: theme.typography.pxToRem(24),
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  spacerME2: {
    marginRight: theme.typography.pxToRem(16),
  },
  racErrorIcon: {
    width: '50px',
  },
  racErrorFunction: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '20px',
    marginTop: '10px',
  },
  racPinContainer: {
    margin: '0 auto',
    width: '85%',
    paddingLeft: '15px',
  },
  racPinLabel: {
    fontFamily: 'OpenSans-semibold',
    color: `${RACCOLOR.RICH_BLACK}`,
    marginLeft: '0.5rem',
    marginBottom: '3px',
  },
  racPinError: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    marginTop: '5px',
    marginLeft: '0.5rem',
    fontFamily: 'OpenSans-semibold',
  },
  racLoader: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, 85%)',
  },
  paddingButtons: {
    paddingRight: '45px',
  },
  mx1: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
  },
  racRemoteLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    marginTop: '15px',
  },
  racRemoteLogin: {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    textAlign: 'center',
    margin: '25px 15px',
    fontSize: theme.typography.pxToRem(14),
  },
  buttonMargin: {
    marginRight: '50px',
  },
}));
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function AddCocustomer(props: any) {
  const Remoteclasses = useClasses();
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const [IsAgreementVoided, setIsAgreementVoided] = useState(false);
  const [twofactorpopup, settwofactorpopup] = useState(false);
  useEffect(() => {
    /* eslint-disable no-console */
    console.log('Props Voidagreement', props);
    if (props !== undefined && props.agreementvoided !== undefined) {
      if (props.agreementvoided === false) {
        setIsAgreementVoided(false);
      } else {
        setIsAgreementVoided(true);
      }
      if (props !== undefined && props.coCustomerData !== undefined) {
        if (props.coCustomerData === true) {
          settwofactorpopup(true);
        } else {
          settwofactorpopup(false);
        }
      }
    }
  }, [props]);
  const location = useLocation();
  const classes = agreementGlobalStyles();
  const addCOCustomerClassName = addCoCustomerStyles();
  const twoFactorClassName = twoFactorStyles();
  const {
    CustomerInfoContext,
    contextAgrId,
    entireGetCustomerData,
    storeExpiryDate,
    blockDE,
    setblockDE,
    agreementCreateStatus,
    setapprovalAmountHolder,
    GlobalcusId,
    setentireGetCustomerData,
    reasonDropDownValue,
    setreasonDropDownValue,
    setIsAllDone,
    coworkerCheck,
    setshowProgress,
    agreementInfoData,
    currentEmpRole,
    getCustomerResponse,
    decliedApproved, 
    setDecliedApproved,
    hardCustomerCompleted, 
    setHardCustomerCompleted,
    deDeclinedApprovalCompleted, 
    setDeDeclinedApprovalCompleted
  } = useContext(AgreementContext);
  const { selectedInvNumber } = useContext(SwitchOutAgreementContext);
  // eslint-disable-next-line no-console
  console.log('coCustomerprops', props);

  const { agreementId, customerId } = useParams<ParamType>();
  const history = useHistory();
  const CustomerInfo = {
    CustomerName: '',
    CustomerID: '',
    PhoneNumber: '',
    DEstatus: '',
    ExpiryDate: '',
    CoCustomerName: '',
    CoCustomerId: '',
  };

  //declaring the varaibles to initially null in Initialsearch
  const Initialsearch = {
    FirstName: '',
    LastName: '',
    dob: '',
  };
  const InitialSSN = {
    //declaring all the threeinput textbox variable to null values in InitialSSN
    ThreeDigitSSN: '',
    TwoDigitSSN: '',
    FourDigitSSN: '',
  }; //Initializing all the necessary state variables and array
  //set contest value
  const runDeSSN = {
    //declaring all the threeinput textbox variable to null values in InitialSSN
    ThreeDigitssn: '',
    TwoDigitssn: '',
    FourDigitssn: '',
  };
  //AC_05
  const customerobj = {
    CoCustomerName: '',
    CoCustomerId: '',
  };
  const [cocustomerinfo, setcocustomerinfo] = useState(customerobj);
  const [CustomerValue, setCustomerValue] = CustomerInfoContext;
  const [SSN, setSSN] = useState(InitialSSN);
  const [ssn, setssn] = useState(runDeSSN);
  const [search, Setsearch] = useState(Initialsearch);
  const [isCoworker, setisCoworker] = useState('');
  const [searchData, SetsearchData] = useState([]); //to fetch the search data from the api
  const [showcocustomerpopup, Setshowcocustomerpopup] = useState(false); //to make the popup open and close
  const [hidesearch, SetHideSearch] = useState<boolean>(false); //to make the search visible only after clicking on search
  const [btnstate, setbtnstate] = useState(true); // to make the search button disabled initially
  const [CoCustommersucesspopup, setCoCustommersucesspopup] = useState(false);
  // const [activecustomer, Setactivecustomer] = useState([]);
  const [addCoCustomerrror, setaddCoCustomerrror] = useState(false);
  const [hideaddcocustomerbtn, sethideaddcocustomerbtn] = useState(false);
  const [phonenumber, Setphonenumber]: any = useState();
  const [maxdate, setmaxdate] = useState('');
  const [agreementcustomerpopup, Setagreementcustomerpopup] = useState(false);
  const [searchedUser, setSearchedUser]: any = useState();
  const [searchedlastname, setsearchedlastname]: any = useState();
  //const [datepicker, setdatepicker] = useState('');
  const [enableloader, setenableloader] = useState(false);
  const [clearData, setclearData] = useState(false);
  const [enableSubmitButton, setenableSubmitButton] = useState(true);
  const [globalcustomerId, setglobalcustomerId] = useState('');
  // const [cocustomerId, setcocustomerId] = useState('');
  const [CocustomerName, setCoCustomerName] = useState('');
  const [classificationpopup, setclassificationpopup] = useState(false);
  const [addCoCustomerErrorPopupEnable, setaddCoCustomerErrorPopupEnable] =
    useState(false);
  const [newcocustomerpopup, setnewcocustomerpopup] = useState(false);
  const [radioIndex, setRadioIndex] = useState(undefined);
  const [redirectionerrorpopup, setredirectionerrorpopup] = useState(false);
  const [CustomerInformation, setCustomerInformation] = useState(CustomerInfo);
  const [coCustomerId, setCoCustomerId] = useState('');
  const [employeeRestrict, setemployeeRestrict] = useState(false);
  const [globalcustomername, setglobalcustomername] = useState('');
  const [IsCustomerhasCCNoAgr, setIsCustomerhasCCNoAgr] = useState(false);
  const [masterloader, setmasterloader]: any = React.useState(false);
  const [continuecheck, setcontinuecheck]: any = React.useState(false);
  const [confirmationpopup, setconfirmationpopup] = React.useState(false);
  const [logincheck, setlogincheck]: any = React.useState(false);
  const [activecustomersuccesspopup, setactivecustomersuccesspopup] =
    useState(false);
  const { setTwoFactorCancelClick, setTwoFactorCompleted, setEmployeeDetails } =
    props;
  const [openModal, setOpenModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [errorline, setErrorLine] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);
  const [role, setRole] = useState('');
  const [openPinModal, setOpenPinModal] = useState(false);
  const [isOpenedPopup, setisOpenedPopup] = useState(false);
  const [isOpenedSkipPopup, setisOpenedSkipPopup] = useState(false);
  const [isskiped, setiskiped] = useState(false);
  const [needDE, setneedDE] = useState(false);
  const [DEException, setDEException] = useState(false);
  const [isopeneddepopup, setisopeneddepopup] = useState(false);
  const [requireSSN, setRequireSSN] = useState(false);
  const [enterSSN, setenterSSN] = useState(false);
  const [Deloader, setDeloader] = useState(false);
  const [afterDEsuccess, setafterDEsuccess] = useState(false);
  const [DEsuccessmessage, setDEsuccessmessage] = useState('');
  const [reasonDEopen, setreasonDEopen] = useState(false);
  const [restrictionerror, setrestrictionerror] = useState(false);
  const [conditionLoader, setconditionLoader] = useState(true);
  const [dropdownreason, setdropdownreason]: any = useState();
  const [dropdownvalue, Setdropdownvalue]: any = useState('SEL');
  const [storeExceptionReason, setStoreExceptionReason] = useState<any>('')
  const [reasonsubmit, Setreasonsubmit]: any = useState(false);
  const [reasoncancel, Setreasoncancel]: any = useState(false);
  const [ssnloader, setssnloader] = useState(false);
  const [hidesaveandrunde, sethidesaveandrunde] = useState(true);
  const [isCoCustomerOpened, setisCoCustomerOpened] = useState(false);
  const [openRemotePopup, setopenRemotePopup] = useState(false);
  const [remoteId, setremoteId] = useState('');
  const [securityCode, setsecurityCode] = useState('');
  const [errorMessage, seterrorMessage] = useState('');
  // eslint-disable-next-line prefer-const
  let [otp, setotp] = useState('');
  // const [loader, setloader] = useState(false);
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  console.log('featureFlagDetails', featureFlagDetails, hardCustomerCompleted);
  const [somethingone, setsomethingone] = React.useState(
    'modal fade show expandPopup'
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [somethingtwo, setsomethingtwo] = React.useState('modal fade');

  const [InvalidPin, setInvalidPin] = React.useState<any>();
  //   const [InvalidPin1, setInvalidPin1] = React.useState(false);
  const [Continue, setContinue] = React.useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentUserRole, setCurrentUserRole] = React.useState('');
  const [reinstatemode, setreinstatemode] = React.useState(false);
  const [openDEDeclinedAuth, setDEDeclinedAuth] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //const [iscocustomer, Setiscocustomer] = useState(false);

  //to make the search button initailly disabled and to enable after entering in any one of the fields
  //AC_11

  // const getUrl = window.location.href;
  // // eslint-disable-next-line sonarjs/no-duplicate-string
  // if (getUrl.includes('/reinstate/itemsearch')) {
  //   setreinstatemode(true);
  // }

  useEffect(() => {
    console.log("Entire Customer Resp Data", getCustomerResponse);
    console.log("Entire Customer Resp Data 2", isopeneddepopup, blockDE, afterDEsuccess, getCustomerResponse?.GetApproval?.value?.customerApprovalDetails
      ?.approvalExpirationDate,  moment().format("YYYY-MM-DD"));
    const getUrl = window.location.href;
      if (
        getCustomerResponse &&
        getCustomerResponse?.GetApproval?.value?.customerApprovalDetails &&
        getCustomerResponse?.GetApproval?.value?.customerApprovalDetails
          ?.approvalResponse == 2 &&
        getCustomerResponse?.GetApproval?.value?.customerApprovalDetails
          ?.approvalExpirationDate > moment().format("YYYY-MM-DD") &&
        decliedApproved == false &&
        getUrl.includes('/itemsearch') && featureFlagDetails.EnableDEDeclineApproval == '1' &&
        deDeclinedApprovalCompleted == false
      ) {
        if (isopeneddepopup === false && blockDE === false && !afterDEsuccess) {
          setDEsuccessmessage("declined");
          setDecliedApproved(true);
          setafterDEsuccess(true);
          setisOpenedPopup(true);
          setblockDE(true);
        }
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCustomerResponse]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Add co customer Context', CustomerInfoContext, CustomerValue);
    if (
      CustomerInfoContext !== undefined &&
      CustomerInfoContext.CoCustomerId !== undefined &&
      CustomerInfoContext.CoCustomerId !== null &&
      CustomerInfoContext.CoCustomerId !== ''
    ) {
      // eslint-disable-next-line no-console
      console.log('setted values in context', CustomerInfoContext);
      // eslint-disable-next-line no-console
      console.log('cocustomerid is there', CustomerInfoContext.CoCustomerId);
      sethideaddcocustomerbtn(true);
    } else if (
      agreementId !== undefined &&
      agreementId !== null &&
      agreementId !== ''
    ) {
      setcontinuecheck(true);
      setlogincheck(true);

      sethideaddcocustomerbtn(false);
    } else {
      console.log('no agreementId');
      // getagreementcustomer(customerId);
      //Setagreementcustomerpopup(true);
      // setIsCustomerhasCCNoAgr(true);
    }

    const triggerReasonPopup = async () => {
      const decisionengineresponse = await GetDeReason();
      setconditionLoader(false);
      if (decisionengineresponse.status == 200) {
        // eslint-disable-next-line no-console
        console.log('svcgscvhscv');
        const ReasonValue = decisionengineresponse.data.referenceDetails;
        const finalreasonvalue: any = [
          { description: 'Select', referenceCode: 'SEL' },
          ...ReasonValue,
        ];
        // eslint-disable-next-line no-console
        console.log('hdbfhdfbjsfbhjsj');
        setdropdownreason(finalreasonvalue);
        setreasonDropDownValue(finalreasonvalue);
      } else {
        const ReasonValue = [{ description: 'Select', referenceCode: 'SEL' }];
        setdropdownreason(ReasonValue);
        setreasonDropDownValue(ReasonValue);
      }
    };
    const getUrl = window.location.href;
    if (reasonDropDownValue == undefined && getUrl.includes('itemsearch')) {
      triggerReasonPopup();
    } else {
      setdropdownreason(reasonDropDownValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('entireGetCustomerData', entireGetCustomerData);

    if (entireGetCustomerData?.coworker == 'Y') {
      setrestrictionerror(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entireGetCustomerData]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Add co customer Context', CustomerInfoContext, CustomerValue);
    if (
      CustomerInfoContext !== undefined &&
      CustomerInfoContext.CoCustomerId !== undefined &&
      CustomerInfoContext.CoCustomerId !== null &&
      CustomerInfoContext.CoCustomerId !== ''
    ) {
      // eslint-disable-next-line no-console
      console.log('setted values in context', CustomerInfoContext);
      // eslint-disable-next-line no-console
      console.log('cocustomerid is there', CustomerInfoContext.CoCustomerId);
      sethideaddcocustomerbtn(true);
    } else if (
      agreementId !== undefined &&
      agreementId !== null &&
      agreementId !== ''
    ) {
      //getAgreementCustomerResponse(agreementId);
    } else {
      console.log('no agreementId');
      // getagreementcustomer(customerId);
      //Setagreementcustomerpopup(true);
      // setIsCustomerhasCCNoAgr(true);
    }
    if(!currentEmpRole){
      GettingRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const coCustomerIdParams = urlParams.get('coCustomerId');
    const coCustomerIdParamValue =
      coCustomerIdParams !== undefined &&
        coCustomerIdParams !== null &&
        coCustomerIdParams !== '' &&
        coCustomerIdParams !== 'N'
        ? coCustomerIdParams
        : '';
    console.log(
      'Inside addcocustomer component outside context change',
      CustomerInfoContext,
      CustomerValue
    );
    //setmasterloader(true);
    if (
      CustomerValue !== undefined &&
      CustomerValue.CustomerId !== undefined &&
      CustomerValue.CustomerId !== null &&
      CustomerValue.CustomerId !== ''
    ) {
      // eslint-disable-next-line no-console
      console.log(
        'Inside addcocustomer component inside',
        CustomerInfoContext,
        CustomerValue
      );
      //setmasterloader(true);
      if (
        CustomerValue !== undefined &&
        CustomerValue.CoCustomerId !== undefined &&
        CustomerValue.CoCustomerId !== null &&
        CustomerValue.CoCustomerId !== ''
      ) {
        // eslint-disable-next-line no-console
        console.log(
          'disable submit button',
          CustomerInfoContext,
          CustomerValue,
          CustomerValue.CoCustomerId !== undefined
            ? CustomerValue.CoCustomerId
            : '',
          CustomerValue.CoCustomerName !== undefined
            ? CustomerValue.CoCustomerName
            : ''
        );

        sethideaddcocustomerbtn(true);
        setmasterloader(false);
        // const currentData = new Date();
        // console.log('currentDataa checking blkj', currentData);

        // const mydate = new Date(storeExpiryDate);
        // // eslint-disable-next-line sonarjs/no-duplicate-string
        // console.log('mydate logging', mydate);
        // console.log('ynhesagbs', currentData.getTime());
        // // eslint-disable-next-line sonarjs/no-duplicate-string
        // console.log('urydthnfgbx dvzs', mydate.getTime());

        // const expiredStatus = currentData.getTime() > mydate.getTime();
        // // eslint-disable-next-line no-console
        // console.log('expiredStatus', expiredStatus);

        // if (expiredStatus) {
        //   console.log('reached thhe if of expiry date');
        //   if (
        //     props !== undefined &&
        //     props.newCoCustomer !== undefined &&
        //     props.newCoCustomer === true &&
        //     isopeneddepopup === false
        //   ) {
        //     setisopeneddepopup(true);
        //     setneedDE(true);
        //   }
        // }
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (
          (CustomerValue.Skip !== undefined && CustomerValue.Skip !== '') ||
          (CustomerValue.Stolen !== undefined && CustomerValue.Stolen !== '') ||
          (CustomerValue.Hard !== undefined && CustomerValue.Hard !== '')
        ) {
          if (
            isOpenedSkipPopup === false &&
            props !== undefined &&
            props.newCoCustomer !== undefined &&
            props.newCoCustomer === true
          ) {
            setisOpenedSkipPopup(true);
            setHardCustomerCompleted(false);
            setclassificationpopup(true);
          }
          setIsAllDone(true);
          setshowProgress(true);
          // else {
          //   console.log(
          //     'checking props.newCoCustomer value',
          //     props.newCoCustomer
          //   );
          //   console.log('CustomerValue check deep', CustomerValue);
          //   console.log('isOpenedSkipPopup check deep', isOpenedSkipPopup);

          //   const currentData = new Date();
          //   console.log('currentDataa checking blkj', currentData);

          //   const mydate = new Date(storeExpiryDate);
          //   // eslint-disable-next-line sonarjs/no-duplicate-string
          //   console.log('mydate logging', mydate);
          //   console.log('ynhesagbs', currentData.getTime());
          //   // eslint-disable-next-line sonarjs/no-duplicate-string
          //   console.log('urydthnfgbx dvzs', mydate.getTime());

          //   const expiredStatus = currentData.getTime() > mydate.getTime();
          //   // eslint-disable-next-line no-console
          //   console.log('expiredStatus', expiredStatus);

          //   if (expiredStatus) {
          //     console.log('reached thhe if of expiry date');

          //     if (
          //       props !== undefined &&
          //       props.newCoCustomer !== undefined &&
          //       props.newCoCustomer === true &&
          //       isopeneddepopup === false
          //     ) {
          //       setisopeneddepopup(true);
          //       setneedDE(true);
          //     }
          //   }
          // }
          console.log('ssh with cocustomer');
        } else {
          console.log(
            'checking props.newCoCustomer value',
            props.newCoCustomer
          );
          console.log('CustomerValue check deep', CustomerValue);
          console.log('isOpenedSkipPopup check deep', isOpenedSkipPopup);
          console.log('check addcocustomer popup', isOpenedPopup);
          console.log('check openedde popup', isopeneddepopup);
          console.log('check cocustomerid params', coCustomerIdParams);
          const currentData = new Date();
          console.log('currentDataa checking blkj', currentData);

          const mydate = new Date(storeExpiryDate);
          console.log('mydate logging', mydate);
          console.log('ynhesagbs', currentData.getTime());
          console.log('urydthnfgbx dvzs', mydate.getTime());

          const expiredStatus = currentData.getTime() > mydate.getTime();
          // eslint-disable-next-line no-console
          console.log('expiredStatus', expiredStatus);
          setHardCustomerCompleted(true);
          if (expiredStatus || storeExpiryDate == undefined) {
            console.log('reached thhe if of expiry date');
            console.log('123', props);
            console.log('123456', props.newCoCustomer);
            console.log('12345678', isopeneddepopup);
            console.log('9597045', blockDE);
            if (
              props !== undefined &&
              props.newCoCustomer !== undefined &&
              props.newCoCustomer === true &&
              isopeneddepopup === false &&
              blockDE === false &&
              agreementCreateStatus.agreementCreatedStatus === false
              // coCustomerIdParams !== 'N'
            ) {
              setisopeneddepopup(true);
              // setneedDE(true);
              //setDeloader(true);
              acceptRunDE();
              setblockDE(true);
            }
          } else {
            setIsAllDone(true);
            setshowProgress(true);
          }
        }
      } else {
        // eslint-disable-next-line no-console
        console.log(
          'Inside addcocustomer component inside main else',
          CustomerInfoContext,
          CustomerValue,
          props
        );
        console.log('CustomerValue chbebj', CustomerValue);

        console.log('view the props---->', props);
        console.log('props newcostomer', props.newCoCustomer);
        console.log('custoemrvalue,cocustomerid', CustomerValue.CoCustomerId);
        console.log('cocustoemrisparasm', coCustomerIdParams);
        console.log('cocustoemridparamvakue', coCustomerIdParamValue);
        console.log('check isopeneddepopup', isopeneddepopup);
        console.log(' check isopenedpopup', isOpenedPopup);
        if (
          (CustomerValue.Skip !== undefined && CustomerValue.Skip !== '') ||
          (CustomerValue.Stolen !== undefined && CustomerValue.Stolen !== '') ||
          (CustomerValue.Hard !== undefined && CustomerValue.Hard !== '')
        ) {
          if (
            isOpenedSkipPopup === false &&
            props !== undefined &&
            props.newCoCustomer !== undefined &&
            props.newCoCustomer === true
          ) {
            setclassificationpopup(true);
            setHardCustomerCompleted(false);
            setisOpenedSkipPopup(true);
          }
          setIsAllDone(true);
          setshowProgress(true);
        } else if (
          props !== undefined &&
          props.newCoCustomer !== undefined &&
          props.newCoCustomer === true &&
          CustomerValue !== undefined &&
          CustomerValue.CoCustomerId !== undefined &&
          CustomerValue.CoCustomerId == '' &&
          isOpenedPopup === false &&
          coCustomerIdParamValue === '' &&
          coCustomerIdParams !== 'N'
        ) {
          console.log('');
          const currentData = new Date();
          console.log('currentDaaata checking 1544nbv', currentData);
          console.log('check storeExpiryDate value in vfsd', storeExpiryDate);

          const mydate = new Date(storeExpiryDate);
          console.log('mydatee logging', mydate);
          console.log('ynhesadgbs', currentData.getTime());
          console.log('urydthvnfgbx dvzs', mydate.getTime());

          const expiredStatus = currentData.getTime() > mydate.getTime();
          // eslint-disable-next-line no-console
          console.log('expiredStatus', expiredStatus);
          setHardCustomerCompleted(true);
          if (expiredStatus || storeExpiryDate == undefined) {
            console.log('reacheed the if of expiry date');
            console.log('733889929292001', props.newCoCustomer);
            console.log('647378', isopeneddepopup);
            if (
              props !== undefined &&
              props.newCoCustomer !== undefined &&
              props.newCoCustomer === true &&
              isopeneddepopup === false &&
              blockDE === false &&
              agreementCreateStatus.agreementCreatedStatus === false
            ) {
              console.log('reached the setneedde for de undefined');
              // setneedDE(true);
              //setDeloader(true);
              acceptRunDE();
              setisopeneddepopup(true);
              setblockDE(true);
            }
          } else if (
            afterDEsuccess === false &&
            coCustomerIdParams !== 'N' &&
            isCoCustomerOpened === false
          ) {
            // eslint-disable-next-line no-console
            console.log(
              'Inside addcocustomer componenttt inside props if',
              CustomerInfoContext,
              CustomerValue,
              props
            );
            //Re Commit
            console.log('check isopened', isOpenedPopup);
            setmasterloader(false);
            setnewcocustomerpopup(true);
            setisCoCustomerOpened(true);
            setisOpenedPopup(true);
            setIsAllDone(true);
            setshowProgress(true);
          }
        } else if (
          props !== undefined &&
          props.newCoCustomer !== undefined &&
          props.newCoCustomer === true &&
          CustomerValue !== undefined &&
          CustomerValue.CoCustomerId !== undefined &&
          CustomerValue.CoCustomerId == '' &&
          isOpenedPopup === false &&
          coCustomerIdParamValue === '' &&
          coCustomerIdParams === 'N'
        ) {
          console.log('another elseif');
          const currentData = new Date();
          console.log('currentDaaata checking 1544nbv', currentData);
          console.log('check storeExpiryDate value in vfsd', storeExpiryDate);

          const mydate = new Date(storeExpiryDate);
          console.log('mydacdcstee logging', mydate);
          console.log('ynhessc dsdadgbs', currentData.getTime());
          console.log('urydtvdvsdhvnfgbx dvzs', mydate.getTime());
          setHardCustomerCompleted(true);
          const expiredStatus = currentData.getTime() > mydate.getTime();
          // eslint-disable-next-line no-console
          console.log('expiredStatus', expiredStatus);

          if (expiredStatus || storeExpiryDate == undefined) {
            console.log('reacheed the if of expiry date');
            console.log('8877668', props);
            console.log('098766', props.newCoCustomer);
            console.log('7377373', isopeneddepopup);
            if (
              props !== undefined &&
              props.newCoCustomer !== undefined &&
              props.newCoCustomer === true &&
              isopeneddepopup === false &&
              blockDE === false &&
              agreementCreateStatus.agreementCreatedStatus === false
            ) {
              // setneedDE(true);
              //setDeloader(true);
              acceptRunDE();
              setisopeneddepopup(true);
              setblockDE(true);
            }
          } else {
            setIsAllDone(true);
            setshowProgress(true);
          }
        }
        setmasterloader(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CustomerInfoContext]);

  useEffect(() => {
    if (
      ssn.FourDigitssn.length === 4 &&
      ssn.ThreeDigitssn.length === 3 &&
      ssn.TwoDigitssn.length === 2
    ) {
      sethidesaveandrunde(false);
    } else {
      sethidesaveandrunde(true);
    }
  }, [ssn]);
  // eslint-disable-next-line sonarjs/no-identical-functions
  // useEffect(() => {
  //   // eslint-disable-next-line no-console
  //   console.log(
  //     'Inside addcocustomer agreement',
  //     CustomerInfoContext,
  //     CustomerValue
  //   );
  //   if (
  //     CustomerValue !== undefined &&
  //     CustomerValue.CoCustomerId !== undefined &&
  //     CustomerValue.CoCustomerId !== null
  //   ) {
  //     // eslint-disable-next-line no-console
  //     console.log(
  //       'disable submit button',
  //       CustomerInfoContext,
  //       CustomerValue
  //       // CustomerValue.CoCustomerId !== undefined
  //       //   ? CustomerValue.CoCustomerId
  //       //   : '',
  //       // CustomerValue.CoCustomerName !== undefined
  //       //   ? CustomerValue.CoCustomerName
  //       //   : ''
  //     );

  //     sethideaddcocustomerbtn(true);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [AgreementContext]);

  const handleRemoteID = (event: any) => {
    setremoteId(event.target.value);
  };
  const handleSecurityCode = (event: any) => {
    setsecurityCode(event.target.value);
  };

  const remoteContinue = async (remoteId: any, securityCode: any) => {
    setopenRemotePopup(false);
    setmasterloader(true);
    const payload = {
      userName: remoteId,
      passCode: securityCode,
    };
    seterrorMessage('');
    const result = await GetRemoteLoginStatus(payload);
    setmasterloader(false);
    RemoteValidate(result);
  };

  const RemoteValidate = (result: any) => {
    if (result?.status == 200) {
      if (result?.data?.response?.result == 'allow') {
        selectAgreementPopup();
        setopenRemotePopup(false);
        setremoteId('');
        setsecurityCode('');
        setTwoFactorCompleted !== undefined
          ? setTwoFactorCompleted(true)
          : null;
      } else {
        seterrorMessage(result?.data?.response?.status_msg);
        setremoteId('');
        setsecurityCode('');
        setopenRemotePopup(true);
      }
    } else {
      console.log('Hi from remote validate', result);
      setremoteId('');
      setsecurityCode('');
      seterrorMessage(result?.data?.errors[0]?.error?.split('"')[7]);
      setopenRemotePopup(true);
    }
  };
  const remoteClick = () => {
    setOpenModal(false);
    setopenRemotePopup(true);
  };

  const remoteEnter = (event: any, remoteId: any, securityCode: any) => {
    if (event.charCode === 13 && remoteId !== '' && securityCode !== '') {
      event.preventDefault();
      remoteContinue(remoteId, securityCode);
    }
  };

  const remoteModelCancelClick = () => {
    seterrorMessage('');
    setopenRemotePopup(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };

  const newcustomercondition = () => {
    // eslint-disable-next-line no-console
    console.log(
      'new cocustomer condition',
      CustomerInfoContext,
      CustomerValue,
      props
    );
    setIsAllDone(true);
    setshowProgress(true);
    const urlParams = new URLSearchParams(window.location.search);
    const coCustomerIdParams = urlParams.get('coCustomerId');
    const coCustomerIdParamValue =
      coCustomerIdParams !== undefined &&
        coCustomerIdParams !== null &&
        coCustomerIdParams !== '' &&
        coCustomerIdParams !== 'N'
        ? coCustomerIdParams
        : '';
    if (
      props !== undefined &&
      props.newCoCustomer !== undefined &&
      props.newCoCustomer === true &&
      CustomerValue !== undefined &&
      CustomerValue.CoCustomerId !== undefined &&
      CustomerValue.CoCustomerId == '' &&
      isOpenedPopup === false &&
      coCustomerIdParamValue === '' &&
      coCustomerIdParams !== 'N' &&
      isCoCustomerOpened === false
    ) {
      // eslint-disable-next-line no-console
      console.log(
        'Inside addcocustomer componentt inside props if',
        CustomerInfoContext,
        CustomerValue,
        props
      );
      setmasterloader(false);
      setnewcocustomerpopup(true);
      setisCoCustomerOpened(true);
    } else {
      setmasterloader(false);
    }
  };

  const selectAgreementPopup = async () => {
    // eslint-disable-next-line no-console
    console.log('select agreeement popup');
    // setmasterloader(true);
    const currentStore = sessionStorage.getItem('storeNumber');
    const cocustomerresponse = await GetCustomer(customerId, currentStore);
    // eslint-disable-next-line no-console
    console.log('view cocustomerinfo', cocustomerresponse);
    if (
      cocustomerresponse.data.GetCustomer !== undefined &&
      cocustomerresponse.data.GetCustomer !== null &&
      cocustomerresponse.data.GetCustomer.value.coCustomerId !== null
    ) {
      const GetcocustomerResponse = await GetCustomerId(
        cocustomerresponse.data.GetCustomer.value.coCustomerId
      );
      // eslint-disable-next-line no-console
      console.log(
        'view whether cocustomer is there',
        cocustomerresponse.data.GetCustomer.value.coCustomerId
      );
      setCoCustomerId(cocustomerresponse.data.GetCustomer.value.coCustomerId);
      if (
        GetcocustomerResponse !== undefined &&
        GetcocustomerResponse.data !== undefined &&
        GetcocustomerResponse.data !== null &&
        GetcocustomerResponse !== null
      ) {
        const coCustomerFirstName =
          GetcocustomerResponse.data.firstName !== undefined &&
            GetcocustomerResponse.data.firstName !== null
            ? GetcocustomerResponse.data.firstName
            : '';
        const coCustomerLastName =
          GetcocustomerResponse.data.lastName !== undefined &&
            GetcocustomerResponse.data.lastName !== null
            ? GetcocustomerResponse.data.lastName
            : '';
        setCoCustomerName(coCustomerFirstName + ' ' + coCustomerLastName);
        setmasterloader(false);
        //sethideaddcocustomerbtn(true);
        Setagreementcustomerpopup(true);
        // props.func({
        //   coCustomerId: cocustomerresponse.data.GetCustomer.value.coCustomerId,
        //   coCustomerName: coCustomerFirstName + ' ' + coCustomerLastName,
        // });
      } else {
        // eslint-disable-next-line no-console
        console.log('Inside else');
        setclearData(false);
        SetsearchData([]);

        setbtnstate(true);
        Setsearch(Initialsearch);
        setSSN(InitialSSN);
        SetHideSearch(false);
        Setshowcocustomerpopup(true);
        setmasterloader(false);
        const closepopup = document.getElementById('addcocustomer');
        closepopup?.classList.add('hideaddcocustomer');
        //setIsCustomerhasCCNoAgr(true);
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('Inside else');
      setclearData(false);
      SetsearchData([]);

      setbtnstate(true);
      Setsearch(Initialsearch);
      setSSN(InitialSSN);
      SetHideSearch(false);
      Setshowcocustomerpopup(true);
      setmasterloader(false);
      const closepopup = document.getElementById('addcocustomer');
      closepopup?.classList.add('hideaddcocustomer');
      //setIsCustomerhasCCNoAgr(true);
    }
  };
  const authenticationOpen = async (payload: any) => {
    const result = await getprofileaccess(payload);
    setmasterloader(false);
    // eslint-disable-next-line no-console
    console.log('getprofileaccess result', result);
    setRole(result.data.role);
    popupSelect(result);
  };
  const GettingRole = async () => {
    const userResponse = await getCurrentInfo();
    // eslint-disable-next-line no-console
    // eslint-disable-next-line sonarjs/no-duplicate-string
    console.log('getCurrentUser Response', userResponse);
    const empId = userResponse.data.employeeId;
    const mailId = userResponse.data.email.toLowerCase();
    const id = userResponse.data.employeeId ? empId : mailId;
    setEmployeeDetails !== undefined
      ? setEmployeeDetails(userResponse.data)
      : null;
    // const coworkerPayload = {
    //   coworkerId: id
    // }
    let storesPayload: any;
    // eslint-disable-next-line prefer-const
    storesPayload = { coworkerId: id };
    // eslint-disable-next-line no-console
    console.log('coworkerId', storesPayload);
    const userRole = await GetRole(storesPayload);
    const coworkerRole = userRole.data.coworkerProfile.role;
    // eslint-disable-next-line no-console
    console.log('Logged In user Role : ', coworkerRole);

    setCurrentUserRole(coworkerRole);
  };
  const searchcustomer = async () => {
    if (twofactorpopup === true) {
      setmasterloader(true);
      if (containerData !== undefined) {
        console.log('containerData', containerData);

        console.log('containerDataRole', containerData.GetRole());

        setCurrentUserRole(containerData.GetRole());
      } else {
        GettingRole();
      }

      const payload = {
        module: 'Add coCustomer In View Agmt',
        storeNumber: window.sessionStorage.getItem('storeNumber'),
        currentRole:
          window.localStorage.getItem('currentRole') ||
          props.currentRole ||
          currentUserRole ||
          containerData.GetRole(),
      };
      //eslint-disable-next-line no-console
      console.log('getprofileaccess payload', payload);
      authenticationOpen(payload);
    } else {
      setmasterloader(true);
      selectAgreementPopup();
    }
  };
  // const getAgreementCustomerResponse = async (agreementId) => {
  //   const response = await getAgreementCustomerInfo(agreementId);
  //   // eslint-disable-next-line no-console
  //   console.log('getagreement customer response', response);
  //   /* eslint-disable no-console */
  //   console.log(
  //     'Lambda response : ',
  //     response.data !== undefined ? response.data : ''
  //   );

  //   if (
  //     response.data !== undefined &&
  //     response.data !== null &&
  //     response.data.coCustomer.length > 0
  //     // eslint-disable-next-line no-empty
  //   ) {
  //     const cocusId = response.data.coCustomer[0].customerId;
  //     const cocusname = response.data.coCustomer[0].customerName;
  //     setcocustomerinfo({
  //       ...cocustomerinfo,
  //       CoCustomerId: cocusId,
  //       CoCustomerName: cocusname,
  //     });
  //     const cocustomerobj = {
  //       CoCustomerId: cocusId,
  //       CoCustomerName: cocusname,
  //     };

  //     setCustomerValue(cocustomerobj);
  //     props.func({
  //       coCustomerId: cocusId,
  //       coCustomerName: cocusname,
  //     });
  //     // eslint-disable-next-line no-console
  //     console.log('set values in context', cocustomerobj);
  //   } else {
  //     getagreementcustomer(customerId);
  //   }
  // };
  const classifycustomer = async () => {
    setmasterloader(true);
    if (containerData !== undefined) {
      console.log('containerData', containerData);

      console.log('containerDataRole', containerData.GetRole());

      setCurrentUserRole(containerData.GetRole());
    } else {
      GettingRole();
    }

    const payload = {
      module: 'Assign Alert',
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      currentRole:
        window.localStorage.getItem('currentRole') ||
        props.currentRole ||
        containerData.GetRole(),
    };
    //eslint-disable-next-line no-console
    console.log('getprofileaccess payload', payload);
    authenticationOpen(payload);
  };

  const getagreementcustomer = async (customerId: any) => {
    const currentStore = sessionStorage.getItem('storeNumber');
    const cocustomerresponse = await GetCustomer(customerId, currentStore);
    // eslint-disable-next-line no-console
    console.log('view cocustomerinfo', cocustomerresponse.GetCustomer);
    if (
      cocustomerresponse.data.GetCustomer !== undefined &&
      cocustomerresponse.data.GetCustomer !== null &&
      cocustomerresponse.data.GetCustomer.value.coCustomerId !== null
    ) {
      const GetcocustomerResponse = await GetCustomerId(
        cocustomerresponse.data.GetCustomer.value.coCustomerId
      );
      // eslint-disable-next-line no-console
      console.log(
        'view whether cocustomer is there',
        cocustomerresponse.data.GetCustomer.value.coCustomerId
      );
      setCoCustomerId(cocustomerresponse.data.GetCustomer.value.coCustomerId);
      if (
        GetcocustomerResponse !== undefined &&
        GetcocustomerResponse.data !== undefined &&
        GetcocustomerResponse.data !== null &&
        GetcocustomerResponse !== null
      ) {
        const coCustomerFirstName =
          GetcocustomerResponse.data.firstName !== undefined &&
            GetcocustomerResponse.data.firstName !== null
            ? GetcocustomerResponse.data.firstName
            : '';
        const coCustomerLastName =
          GetcocustomerResponse.data.lastName !== undefined &&
            GetcocustomerResponse.data.lastName !== null
            ? GetcocustomerResponse.data.lastName
            : '';
        setCoCustomerName(coCustomerFirstName + ' ' + coCustomerLastName);

        //sethideaddcocustomerbtn(true);
        setIsCustomerhasCCNoAgr(true);
        // props.func({
        //   coCustomerId: cocustomerresponse.data.GetCustomer.value.coCustomerId,
        //   coCustomerName: coCustomerFirstName + ' ' + coCustomerLastName,
        // });
      } else {
        setIsCustomerhasCCNoAgr(false);
        //setIsCustomerhasCCNoAgr(true);
      }
    }
  };

  useEffect(() => {
    if (
      search.FirstName.length >= 3 ||
      search.LastName.length >= 3 ||
      search.FirstName.length + search.LastName.length >= 3 ||
      search.dob !== '' ||
      (SSN.ThreeDigitSSN.length == 3 &&
        SSN.TwoDigitSSN.length === 2 &&
        SSN.FourDigitSSN.length == 4)
    ) {
      setbtnstate(false);
    } else {
      setbtnstate(true);
    }
    if (phonenumber != undefined) {
      const PhoneNum = parseInt(phonenumber);
      const phoneinput = PhoneNum.toString();

      if (phoneinput.length === 11) {
        setbtnstate(false);
      } else if (
        (phoneinput.length < 11 && search.LastName.length >= 3) ||
        (phoneinput.length < 11 && search.FirstName.length >= 3) ||
        (SSN.ThreeDigitSSN.length == 3 &&
          SSN.TwoDigitSSN.length === 2 &&
          SSN.FourDigitSSN.length == 4)
      ) {
        setbtnstate(false);
      } else {
        console.log('view state', btnstate);
        setbtnstate(true);
      }
    }
    //setdatepicker(moment().subtract(18, 'years').format('YYYY-MM-DD'));
    setmaxdate(moment().subtract(18, 'years').format('YYYY-MM-DD'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, SSN, phonenumber]);

  const OnsearchChange = (e: any) => {
    let regexItem;

    if (e.target && e.target.name !== 'dob') {
      regexItem = new RegExp("^[a-zA-Z-,. ']*$");

      if (e.target.value.match(regexItem)) {
        //setSSN({...SSN,[e.target.name]:e.target.value})
        Setsearch({ ...search, [e.target.name]: e.target.value });
      }
    } else {
      Setsearch({ ...search, ['dob']: e });
    }
  };
  // clicking on the search button and making an api call to get the search data
  //AC_12
  const Search_Onclick = async () => {
    setenableloader(true);
    SetsearchData([]);
    setRadioIndex(undefined);
    setenableSubmitButton(true);

    if (
      search.FirstName !== '' ||
      search.LastName !== '' ||
      search.dob !== '' ||
      phonenumber != ''
    ) {
      //declaring the three variables initailly and convert them to tostring and concatenate all the variables to a variable Ssnnum
      let twodigit: any = SSN.ThreeDigitSSN;
      twodigit = twodigit.toString();
      let threedigit: any = SSN.TwoDigitSSN;
      threedigit = threedigit.toString();
      let fourdigit: any = SSN.FourDigitSSN;
      fourdigit = fourdigit.toString();
      let Ssnnum: any = twodigit + threedigit + fourdigit;
      Ssnnum = parseInt(Ssnnum);
      // eslint-disable-next-line no-console
      console.log(Ssnnum);
      setSearchedUser(search.FirstName);
      setsearchedlastname(search.LastName);

      const phones: any = [
        {
          phoneNumber: '',
        },
      ];
      const payloadReq: any = {};
      if (search.FirstName != '') {
        payloadReq.firstName = search.FirstName;
      }
      if (search.LastName != '') {
        payloadReq.lastName = search.LastName;
      }
      if (search.dob != '') {
        payloadReq.dateOfBirth = search.dob;
      }
      if (
        SSN.ThreeDigitSSN !== '' &&
        SSN.TwoDigitSSN !== '' &&
        SSN.FourDigitSSN !== ''
      ) {
        payloadReq.taxId =
          SSN.ThreeDigitSSN + SSN.TwoDigitSSN + SSN.FourDigitSSN;
      }

      if (
        phonenumber !== undefined &&
        phonenumber !== '' &&
        phonenumber.length >= 12
      ) {
        const PhoneNum = phonenumber.replace(/[-()+ ]/g, '');
        const phoneinput = PhoneNum.toString();
        phones[0].phoneNumber = phoneinput.substring(1, 11);
        payloadReq.phones = phones;
        // eslint-disable-next-line no-console
        console.log(payloadReq);
      }
      getsearchvalues(payloadReq);
    }
  };

  const getsearchvalues = async (payload: any) => {
    //getting the response form the client to the tsx component
    const SearchCoCustomerresponse = await FindCoCustomer(payload);
    setenableloader(false);
    setclearData(true);

    if (
      SearchCoCustomerresponse !== undefined &&
      SearchCoCustomerresponse.customers !== undefined
    ) {
      SetsearchData(SearchCoCustomerresponse.referenceDetails);
    }
    SetsearchData(SearchCoCustomerresponse.customers);
    // eslint-disable-next-line no-console
    console.log('searchciustomerresponse', SearchCoCustomerresponse.customers);
    SetHideSearch(true);
  };

  //when the coworker clicks on the radio button,the customerid of the selected customer is passed from the search response
  const Add_Cocustomer = async (customerId: any, index: any) => {
    // setenableSubmitButton(false);

    setglobalcustomerId('');
    setRadioIndex(undefined);
    setmasterloader(true);
    const getcustomerIdResponse = await GetCustomerId(customerId);
    console.log('get cutomer GCID Value', getcustomerIdResponse);
    setmasterloader(false);
    //passing the active cocustomer value to the insertdata to submit
    if (
      getcustomerIdResponse.data !== undefined &&
      getcustomerIdResponse.data !== null &&
      getcustomerIdResponse.data.customerId !== null &&
      getcustomerIdResponse.data.customerId !== undefined
    ) {
      console.log('Inside IF response');
      setglobalcustomerId(getcustomerIdResponse.data.customerId);
      setisCoworker(getcustomerIdResponse.data.coworker);
      const coCustomerFirstName =
        getcustomerIdResponse.data.firstName !== undefined &&
          getcustomerIdResponse.data.firstName !== null
          ? getcustomerIdResponse.data.firstName
          : '';
      const coCustomerLastName =
        getcustomerIdResponse.data.lastName !== undefined &&
          getcustomerIdResponse.data.lastName !== null
          ? getcustomerIdResponse.data.lastName
          : '';
      setglobalcustomername(coCustomerFirstName + ' ' + coCustomerLastName);
      setRadioIndex(index);
      setenableSubmitButton(false);
    } else {
      console.log('Inside else response');
      setaddCoCustomerErrorPopupEnable(true);
    }
  };

  const redirectToCustomerInfo = async (customerId: any) => {
    setmasterloader(true);
    const getcustomerIdResponse = await GetCustomerId(customerId);
    setmasterloader(false);
    //passing the active cocustomer value to the insertdata to submit
    if (
      getcustomerIdResponse.data !== undefined &&
      getcustomerIdResponse.data !== null &&
      getcustomerIdResponse.data.customerId !== null &&
      getcustomerIdResponse.data.customerId !== undefined
    ) {
      setglobalcustomerId(
        getcustomerIdResponse.data.customerId !== undefined &&
          getcustomerIdResponse.data.customerId !== null
          ? getcustomerIdResponse.data.customerId
          : ''
      );
      history.push({
        pathname: `/customer/update/${getcustomerIdResponse.data.customerId}/customer`,
      });
    } else {
      setredirectionerrorpopup(true);
    }
  };

  //popup onchange to open and close when required
  const disppopup = (val: boolean) => {
    Setsearch(Initialsearch);
    setSSN(InitialSSN);

    Setphonenumber('');
  };
  // passing the values and name to the ssn textboxes
  //AC_10
  const handlessn1 = (e: any) => {
    let regexItem;
    if (
      e.target.name == 'ThreeDigitSSN' ||
      e.target.name == 'TwoDigitSSN' ||
      e.target.name == 'FourDigitSSN'
    ) {
      regexItem = new RegExp('^[0-9]*$');
    }
    if (e.target.value.match(regexItem)) {
      setSSN({ ...SSN, [e.target.name]: e.target.value });

      if (e.target.name === 'ThreeDigitSSN' && e.target.value.length === 3) {
        (document.getElementById('ssn2') as HTMLInputElement).focus();
      } else if (
        e.target.name === 'TwoDigitSSN' &&
        e.target.value.length === 2
      ) {
        (document.getElementById('ssn4') as HTMLInputElement).focus();
      } else if (
        e.target.name === 'TwoDigitSSN' &&
        e.target.value.length === 0 &&
        SSN.ThreeDigitSSN.length > 0
      ) {
        (document.getElementById('ssn3') as HTMLInputElement).focus();
      } else if (
        e.target.name === 'FourDigitSSN' &&
        e.target.value.length === 0 &&
        SSN.ThreeDigitSSN.length > 0
      ) {
        (document.getElementById('ssn2') as HTMLInputElement).focus();
      }
    }
  };

  //for binding the search results
  //for binding the search results
  const RenderTableBody = () => (
    <>
      {searchData.map((col: any, index: any) => {
        //binding the grid with the result obtained from API
        return (
          //checking the count of active agreements ,greater than 0 or not
          <RACTableRow key={index}>
            <RACTableCell>
              {col.agreementCounts == undefined ||
                (col.agreementCounts !== null &&
                  col.agreementCounts !== undefined &&
                  col.agreementCounts.findIndex(
                    (type: { lob: string }) => type.lob === 'RTO'
                  ) !== -1 &&
                  col.agreementCounts[
                    col.agreementCounts.findIndex(
                      (type: { lob: string }) => type.lob === 'RTO'
                    )
                  ].active == 0) ? (
                <RACRadio
                  value=""
                  name={`inlineRadioOptions ${index}`}
                  onClick={() => Add_Cocustomer(col.globalCustomerId, index)}
                  checked={
                    globalcustomerId !== '' &&
                      globalcustomerId !== undefined &&
                      globalcustomerId !== null &&
                      radioIndex == index
                      ? true
                      : false
                  }

                // onChange={setradioBtnCheck}
                />
              ) : null}
            </RACTableCell>
            <RACTableCell>
              <a
                className={classes.racpadLink}
                style={{ cursor: 'pointer', textDecoration: 'none' }}
                onClick={() => redirectToCustomerInfo(col.globalCustomerId)}
              >
                {col.lastName}
              </a>
            </RACTableCell>
            <RACTableCell>
              <a
                className={classes.racpadLink}
                style={{ cursor: 'pointer', textDecoration: 'none' }}
                onClick={() => redirectToCustomerInfo(col.globalCustomerId)}
              >
                {col.firstName}
              </a>
            </RACTableCell>

            <RACTableCell>{truncString(col)}</RACTableCell>
            <RACTableCell>
              {col.addresses !== undefined &&
                col.addresses?.[0]?.addressLine1 !== undefined
                ? col.addresses?.[0]?.addressLine1
                : ''}
              {','}
              {AddressLine2Validation(col)}
              {col.addresses !== undefined &&
                col.addresses?.[0]?.city !== undefined &&
                col.addresses?.[0]?.postalCode !== undefined
                ? col.addresses?.[0]?.city + ',' + col.addresses?.[0]?.postalCode
                : ''}
            </RACTableCell>
            <RACTableCell>{normalizeinput(col)}</RACTableCell>
            <RACTableCell
              className={classes.textCenter}
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'blue',
                fontWeight: 'bolder',
              }}
            >
              {AgeementCounts(col)}
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  const AddressLine2Validation = (col: any) => {
    return col.addresses !== undefined &&
      col.addresses?.[0]?.addressLine2 !== undefined &&
      col.addresses?.[0]?.addressLine2 !== null
      ? col.addresses?.[0]?.addressLine2 + ','
      : '';
  };

  const AgeementCounts = (col: any) => {
    return col.agreementCounts !== undefined && col.agreementCounts !== null
      ? col.agreementCounts.findIndex(
        (type: { lob: string }) => type.lob === 'RTO'
      ) !== undefined &&
        col.agreementCounts.findIndex(
          (type: { lob: string }) => type.lob === 'RTO'
        ) !== -1
        ? col.agreementCounts[
          col.agreementCounts.findIndex(
            (type: { lob: string }) => type.lob === 'RTO'
          )
        ].active
        : 0
      : 0;
  };
  const submitvalidate = () => {
    const cocuscontext = CustomerValue;
    cocuscontext.CoCustomerId = globalcustomerId;
    cocuscontext.CoCustomerName = globalcustomername;
    setCustomerValue(cocuscontext);
    /* eslint-disable no-console */
    // eslint-disable-next-line sonarjs/no-duplicate-string
    console.log('set context values Submit Click', cocuscontext);

    props.func({
      coCustomerId: globalcustomerId,
      coCustomerName: globalcustomername,
    });
    sethideaddcocustomerbtn(true);

    const closepopup = document.getElementById('addcocustomer');

    closepopup?.classList.add('hideaddcocustomer');
  };
  //AC_26

  // to add a cocustomer when submit button is clicked by passing the CustomerId
  //AC-20
  const Submit_click = async () => {
    console.log('contextAgrId', contextAgrId);
    Setshowcocustomerpopup(false);
    if (isCoworker == 'Y') {
      setemployeeRestrict(true);
    } else {
      SetHideSearch(false);
      const closepopup = document.getElementById('addcocustomer');
      closepopup?.classList.remove('hideaddcocustomer');
      const getUrl = window.location.href;
      if (
        getUrl.includes('/reinstate/itemsearch') ||
        getUrl.includes('/reinstate/details')
      ) {
        console.log('addcocustomer from reinstate');
        const cocuscontext = CustomerValue;
        cocuscontext.CoCustomerId = globalcustomerId;
        cocuscontext.CoCustomerName = globalcustomername;
        setCustomerValue(cocuscontext);
        /* eslint-disable no-console */
        console.log('set context values Submit Click', cocuscontext);

        props.func({
          coCustomerId: globalcustomerId,
          coCustomerName: globalcustomername,
        });
        sethideaddcocustomerbtn(true);
      } else if (
        (agreementId !== undefined &&
          agreementId !== null &&
          agreementId !== '') ||
        (contextAgrId !== undefined && contextAgrId !== '')
      ) {
        //passing the active cocustomer value to the insertdata to submit
        setmasterloader(true);
        const insertdata = {
          customerId: parseInt(globalcustomerId),
          agreementId:
            contextAgrId === ''
              ? parseInt(agreementId)
              : parseInt(contextAgrId),
        };
        const insertCoCustomerResponse = await InsertCocustomer(insertdata);
        /* eslint-disable no-console */
        console.log('Insert Cocustomer Response', insertCoCustomerResponse);
        setmasterloader(false);
        /* eslint-enable no-console */
        if (
          insertCoCustomerResponse.data !== undefined &&
          insertCoCustomerResponse.data !== null &&
          insertCoCustomerResponse.data.agreementCustomerId !== undefined &&
          insertCoCustomerResponse.data.agreementCustomerId !== null
        ) {
          /* eslint-disable no-console */
          console.log('Inside Success');
          /* eslint-enable no-console */
          setCoCustommersucesspopup(true);

          // Sethidecustomer(true);
        } else {
          /* eslint-disable no-console */
          console.log('Inside failure');
          /* eslint-enable no-console */
          setaddCoCustomerrror(true);
        }
      } else {
        //need to set customerId and customername in context gowtham

        //need to set customerId and customername in contex
        // eslint-disable-next-line sonarjs/no-all-duplicated-branches
        if (
          CustomerValue.Skip === 'Y' ||
          CustomerValue.Stolen === 'Y' ||
          CustomerValue.Hard === 'Y'
        ) {
          /* eslint-disable no-console */
          console.log('set context values-----> ', CustomerValue);
          setconfirmationpopup(true);

          // eslint-disable-next-line sonarjs/no-duplicated-branches
        } else {
          const cocuscontext = CustomerValue;
          cocuscontext.CoCustomerId = globalcustomerId;
          cocuscontext.CoCustomerName = globalcustomername;
          setCustomerValue(cocuscontext);
          /* eslint-disable no-console */
          console.log('set context values Submit Click', cocuscontext);

          props.func({
            coCustomerId: globalcustomerId,
            coCustomerName: globalcustomername,
          });
          sethideaddcocustomerbtn(true);

          const closepopup = document.getElementById('addcocustomer');

          closepopup?.classList.add('hideaddcocustomer');
        }
      }
    }
  };

  //AC_29
  //To make the year in the dateofBirth as ****
  function truncString(value: any) {
    if (
      value.dateOfBirth !== undefined &&
      value.dateOfBirth !== null &&
      value.dateOfBirth !== ''
    ) {
      const change = value.dateOfBirth;
      const statelookup = change.replace(/-/g, '/');
      const myArray = statelookup.split('/');
      const formatedDate = myArray[1] + '/' + myArray[2] + '/' + '****';

      return formatedDate !== '' &&
        formatedDate !== undefined &&
        formatedDate !== null
        ? formatedDate.toString()
        : '';
    }
    return '';
  }
  //If the customer is new and first time agreement creation this popup will be generated
  const insertnewcocustomer = () => {
    if (IsCustomerhasCCNoAgr === true) {
      Setagreementcustomerpopup(true);
    } else {
      setclearData(false);
      SetsearchData([]);

      setbtnstate(true);
      Setsearch(Initialsearch);
      setSSN(InitialSSN);
      SetHideSearch(false);
      Setshowcocustomerpopup(true);
      const closepopup = document.getElementById('addcocustomer');
      closepopup?.classList.add('hideaddcocustomer');
    }
  };
  const showaddcocustomer = async () => {
    Setagreementcustomerpopup(false);
    if (
      agreementId !== undefined &&
      agreementId !== null &&
      agreementId !== ''
    ) {
      //passing the active cocustomer value to the insertdata to submit
      setmasterloader(true);
      const insertdata = {
        customerId: parseInt(coCustomerId),
        agreementId: parseInt(agreementId),
      };

      const insertCoCustomerResponse = await InsertCocustomer(insertdata);
      /* eslint-disable no-console */
      console.log('Insert Cocustomer Response', insertCoCustomerResponse);
      setmasterloader(false);
      /* eslint-enable no-console */
      if (
        insertCoCustomerResponse.data !== undefined &&
        insertCoCustomerResponse.data !== null &&
        insertCoCustomerResponse.data.agreementCustomerId !== undefined &&
        insertCoCustomerResponse.data.agreementCustomerId !== null
      ) {
        /* eslint-disable no-console */
        console.log('Inside Success');
        /* eslint-enable no-console */
        setactivecustomersuccesspopup(true);
        const cocuscontext = CustomerValue;
        cocuscontext.CoCustomerId = coCustomerId;
        cocuscontext.CoCustomerName = CocustomerName;
        setCustomerValue(cocuscontext);

        /* eslint-disable no-console */
        console.log(
          'set context values do you want to add no api',
          cocuscontext
        );
        props.func({
          coCustomerId: coCustomerId,
          coCustomerName: CocustomerName,
        });
        sethideaddcocustomerbtn(true);
      } else {
        /* eslint-disable no-console */
        console.log('Inside failure');
        /* eslint-enable no-console */
        setaddCoCustomerrror(true);
      }
    } else {
      //need to set customerId and customername in contex

      const tempContext = CustomerValue;

      tempContext.CoCustomerId = coCustomerId;

      tempContext.CoCustomerName = CocustomerName;

      setCustomerValue(tempContext);
      /* eslint-disable no-console */
      console.log('agreementcustome------>', tempContext);
      props.func({
        coCustomerId: coCustomerId,
        coCustomerName: CocustomerName,
      });
      sethideaddcocustomerbtn(true);
    }
  };
  //AC_26
  // after clicking the addcocustomer button if the customer has been active customer to any of the agreements,this popup will be opened
  //    const insertcocustomer=()=>{

  //      //Setagreementcustomerpopup(true)

  //      let show =document.getElementById("addcocustomer");
  //      show?.classList.add("hideaddcocustomer")
  // }
  //AC_27
  //for making the addcocustomer visible after clicking on the close and cancel buttton in the addcocustomer popup
  const closepopup = () => {
    Setshowcocustomerpopup(false);
    disppopup(false);
    setRadioIndex(undefined);
    SetHideSearch(false);

    const closepopup = document.getElementById('addcocustomer');
    closepopup?.classList.remove('hideaddcocustomer');
    setnewcocustomerpopup(false);
  };
  //AC_28
  //for making the addcocustomer button visible and after clicking on the cancel and close button in the success popup
  const createcocustomer = () => {
    setCoCustommersucesspopup(false);
    Setshowcocustomerpopup(false);
    const cocuscontext = CustomerValue;
    cocuscontext.CoCustomerId = globalcustomerId;
    cocuscontext.CoCustomerName = globalcustomername;

    setCustomerValue(cocuscontext);
    /* eslint-disable no-console */
    console.log('view-----> context', cocuscontext);
    props.func({
      coCustomerId: globalcustomerId,
      coCustomerName: globalcustomername,
    });

    sethideaddcocustomerbtn(true);
    const closepopup = document.getElementById('addcocustomer');
    closepopup?.classList.remove('hideaddcocustomer');
  };

  const AddcocustomerError = () => {
    setaddCoCustomerErrorPopupEnable(false);
  };
  const newcustomer = () => {
    setCoCustommersucesspopup(false);
    Setshowcocustomerpopup(false);
    setaddCoCustomerrror(false);
    const closepopup = document.getElementById('addcocustomer');
    closepopup?.classList.remove('hideaddcocustomer');
  };
  //AC_30
  //for making the phonenumber with extension and space after getting the response phonenumber
  const normalizeinput = (phonenum: any) => {
    phonenum =
      phonenum !== undefined &&
        phonenum.phones !== undefined &&
        phonenum.phones.length > 0 &&
        phonenum.phones[0].phoneNumber !== undefined
        ? phonenum.phones[0].phoneNumber
        : '';
    if (phonenum == '') return phonenum;
    if (!phonenum) return phonenum;
    const currentValue = phonenum.replace(/[^\d]/g, '');

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };
  const redirectionok = () => {
    setredirectionerrorpopup(false);
  };

  const enterKeyPressed = (event: any) => {
    // Number 13 is the "Enter" key on the keyboard
    /* eslint-disable no-console */

    console.log('key pressed');
    /* eslint-enable no-console */

    // eslint-disable-next-line sonarjs/no-identical-expressions
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (event.charCode != undefined && event.charCode != null) {
      if (event.charCode === 13 && !btnstate) {
        // Cancel the default action, if needed
        event.preventDefault();
        /* eslint-disable no-console */
        console.log('enter pressed');
        /* eslint-enable no-console */
        Search_Onclick();
        // Trigger the button element with a click
      }
    }
  };

  const secondfactorEnter = (event: any, username: any, Password: any) => {
    /* eslint-enable no-console */
    if (event.charCode === 13 && userID !== '' && password !== '') {
      event.preventDefault();
      loginClick(username, Password);
    }
  };
  const secondfactorEvent = (event: any, username: any, Password: any) => {
    /* eslint-enable no-console */
    if (event.charCode === 13 && userID !== '' && password !== '') {
      event.preventDefault();
      loginchecking(username, Password);
    }
  };

  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };

  const pinValue = (event: any) => {
    otp = event;
  };
  const handleUserID = (event: any) => {
    // setErrorLine(false);
    setUserID(event.target.value);
  };

  const handlePassword = (event: any) => {
    // setErrorLine(false);
    setPassword(event.target.value);
  };

  const loginClick = async (username: any, Password: any) => {
    // eslint-disable-next-line no-console
    console.log('loginclick');
    setOpenModal(false);
    setmasterloader(true);
    const payload = {
      userName: username,
      role: role,
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      password: Password,
    };
    setErrorLine(false);
    const result = await getsecondfactor(payload);

    UserValidate(result);
  };

  const UserValidate = (result: any) => {
    // eslint-disable-next-line no-console
    console.log('uservalidate');
    if (result.status == 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === '200' &&
        result.data.CoworkerAuthenticationResult.value.statusCode === 200
      ) {
        setOpenModal(false);

        selectAgreementPopup();

        setTwoFactorCompleted !== undefined
          ? setTwoFactorCompleted(true)
          : null;

        // setPopupOpen({ ...PopupOpen, PrintPopupOpen: true });
      } else {
        setOpenModal(false);
        setOpenSecondModal(true);
      }
    } else {
      setOpenModal(false);
      setOpenSecondModal(true);
    }
  };
  const loginchecking = async (username: any, Password: any) => {
    // eslint-disable-next-line no-console
    console.log('loginchecking');
    setOpenModal(false);
    setmasterloader(true);
    const payload = {
      userName: username,
      role: role,
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      password: Password,
    };
    setErrorLine(false);
    const result = await getsecondfactor(payload);
    setmasterloader(false);
    uservalidation(result);
  };
  const uservalidation = (result: any) => {
    // eslint-disable-next-line no-console
    console.log('uservalidation');
    if (result.status == 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === '200' &&
        result.data.CoworkerAuthenticationResult.value.statusCode === 200
      ) {
        // eslint-disable-next-line no-console
        console.log('newcustomercondition');
        setOpenModal(false);

        const currentData = new Date();
        // eslint-disable-next-line no-console
        console.log('currentData checking', currentData);

        const mydate = new Date(storeExpiryDate);
        // eslint-disable-next-line no-console
        console.log('mydate logging', mydate);
        // eslint-disable-next-line no-console
        console.log('ynhesagbs', currentData.getTime());
        // eslint-disable-next-line no-console
        console.log('urydthnfgbx dvzs', mydate.getTime());

        const expiredStatus = currentData.getTime() > mydate.getTime();
        // eslint-disable-next-line no-console
        console.log('expiredStatus', expiredStatus);

        if (expiredStatus || storeExpiryDate == undefined) {
          // eslint-disable-next-line no-console
          console.log('reached the if of expiry date');

          if (
            props !== undefined &&
            props.newCoCustomer !== undefined &&
            props.newCoCustomer === true &&
            isopeneddepopup === false &&
            blockDE === false &&
            agreementCreateStatus.agreementCreatedStatus === false
          ) {
            setisopeneddepopup(true);
            // setneedDE(true);
            //setDeloader(true);
            acceptRunDE();
            setblockDE(true);
          }
        } else {
          newcustomercondition();
        }

        setTwoFactorCompleted !== undefined
          ? setTwoFactorCompleted(true)
          : null;

        // setPopupOpen({ ...PopupOpen, PrintPopupOpen: true });
      } else {
        setOpenModal(false);
        setOpenSecondModal(true);
      }
    } else {
      setOpenModal(false);
      setOpenSecondModal(true);
    }
  };

  const okClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
    // setUserID('');
    // setPassword('');
  };

  const popupSelect = (result: any) => {
    if (result.data.pin === 1 && result.data.secondFactor === 1) {
      setOpenPinModal(true);
      setpopupFlag(true);
    } else if (result.data.pin === 1) {
      setOpenPinModal(true);
      setpopupFlag(false);
    } else if (result.data.secondFactor === 1) {
      setOpenModal(true);
      setpopupFlag(false);
    }
  };

  const openModelCancelClick = () => {
    setErrorLine(false);
    setOpenModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };

  useEffect(() => {
    if (otp.length == 4) {
      setContinue(false);
    } else {
      setContinue(true);
    }
  }, [otp]);

  const noClick = () => {
    setOpenPinModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };
  const continueClick = async () => {
    // eslint-disable-next-line no-console
    console.log('continueclick');
    setOpenPinModal(false);
    setmasterloader(true);
    const userResponse = await getCurrentInfo();
    // eslint-disable-next-line no-console
    console.log('getCurrentUser Response', userResponse);
    const id = userResponse.data.employeeId
      ? userResponse.data.employeeId
      : userResponse.data.email.toLowerCase();
    setEmployeeDetails !== undefined
      ? setEmployeeDetails(userResponse.data)
      : null;

    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };

    // eslint-disable-next-line no-console
    // eslint-disable-next-line sonarjs/no-duplicate-string
    // eslint-disable-next-line no-console
    console.log('get Payload', payload);
    const result = await getPinDetails(payload);
    // eslint-disable-next-line no-console
    console.log('Total Result', result);
    //setmasterloader(false);
    setOpenPinModal(true);
    setInvalidPin(result.status);
    if (result.data.statusCode == 200 && popupFlag) {
      setOpenPinModal(false);
      setOpenModal(true);
      setHardCustomerCompleted(true);
    }
    if (result.data.statusCode == 200 && !popupFlag) {
      setOpenPinModal(false);
      selectAgreementPopup();
      setHardCustomerCompleted(true);
      // await documentSign()
    }
  };

  const continuechecking = async () => {
    // eslint-disable-next-line no-console
    console.log('continuechecking');

    setOpenPinModal(false);
    setmasterloader(true);
    const userResponse = await getCurrentInfo();
    // eslint-disable-next-line no-console
    console.log('getCurrentUser Response', userResponse);
    const id = userResponse.data.employeeId
      ? userResponse.data.employeeId
      : userResponse.data.email.toLowerCase();
    setEmployeeDetails !== undefined
      ? setEmployeeDetails(userResponse.data)
      : null;

    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    // eslint-disable-next-line no-console
    console.log('getPin Payload', payload);
    // eslint-disable-next-line no-console
    console.log('getPin Payload', payload);
    const result = await getPinDetails(payload);
    // eslint-disable-next-line no-console
    console.log('Total Result', result);
    //setmasterloader(false);
    setOpenPinModal(true);
    setInvalidPin(result.status);
    if (result.data.statusCode == 200 && popupFlag) {
      setOpenPinModal(false);
      setOpenModal(true);
      setHardCustomerCompleted(true);
    }
    if (result.data.statusCode == 200 && !popupFlag) {
      setOpenPinModal(false);
      setmasterloader(false);
      setHardCustomerCompleted(true);
      const currentData = new Date();
      // eslint-disable-next-line no-console
      console.log('currentData checking', currentData);

      const mydate = new Date(storeExpiryDate);
      // eslint-disable-next-line no-console
      console.log('mydatee logging', mydate);
      // eslint-disable-next-line no-console
      console.log('ynhesadgbs', currentData.getTime());
      // eslint-disable-next-line no-console
      console.log('urydthvnfgbx dvzs', mydate.getTime());

      const expiredStatus = currentData.getTime() > mydate.getTime();
      // eslint-disable-next-line no-console
      console.log('expiredStatus', expiredStatus);

      if (expiredStatus || storeExpiryDate == undefined) {
        // eslint-disable-next-line no-console
        console.log('reached the if of expiry date');
        if (
          props !== undefined &&
          props.newCoCustomer !== undefined &&
          props.newCoCustomer === true &&
          isopeneddepopup === false &&
          blockDE === false &&
          agreementCreateStatus.agreementCreatedStatus === false
        ) {
          setisopeneddepopup(true);
          // setneedDE(true);
          //setDeloader(true);
          acceptRunDE();
          setblockDE(true);
        }
      } else {
        newcustomercondition();
      }

      // await documentSign()
    }
  };
  const pinEnter = (event: any) => {
    // eslint-disable-next-line no-console
    console.log('pinEnter');
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && Continue == false) {
      event.preventDefault();
      continueClick();
    }
  };
  const createcocustomerclick = () => {
    // history.push({
    //   pathname: `/customer/createcustomer`,
    //   search: `?customerId=${customerId}`,
    // });
    // eslint-disable-next-line no-console
    console.log('--->selected inventory from agreementinfo', selectedInvNumber);
    const getUrl = window.location.href;
    if (
      getUrl.includes('/switchout/itemsearch') ||
      getUrl.includes('/switchout/details') ||
      getUrl.includes('/switchout/delivery')
    ) {
      history.push({
        pathname: `/customer/createcustomer`,
        search: `?customerId=${customerId}&agrId=${agreementId}&src=SO`,
        state: {
          selectedInventoryNo: selectedInvNumber,
        },
      });
    } else if (
      getUrl.includes('/reinstate/itemsearch') ||
      getUrl.includes('/reinstate/details') ||
      getUrl.includes('/rental/delivery')
    ) {
      history.push({
        pathname: `/customer/createcustomer`,
        search: `?customerId=${customerId}&agrId=${agreementId}&src=RI`,
      });
    } else {
      history.push({
        pathname: `/customer/createcustomer`,
        search: `?customerId=${customerId}`,
      });
    }
  };
  const customersearch = () => {
    history.push({
      pathname: '/customer/searchcustomer',
    });
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const addnewcocustomer = () => (
    <Grid container className={`${classes.p3} ${classes.pt1}`}>
      {/* <Typography className={classes.title}>Co-Customer Information</Typography> */}

      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography
            className={` ${classes.subTitle} ${classes.fontHeading} ${classes.searchcustomer}`}
          >
            Search Co-Customer
          </Typography>
        </Grid>

        <Grid item md={2}>
          <RACTextbox
            inputlabel="Last Name"
            type="text"
            OnChange={(e) => OnsearchChange(e)}
            isCurrency={false}
            value={search.LastName}
            onKeyPress={(e) => enterKeyPressed(e)}
            maxlength={30}
            name="LastName"
          />
        </Grid>
        <Grid item md={2}>
          <RACTextbox
            type="text"
            OnChange={(e) => OnsearchChange(e)}
            isCurrency={false}
            value={search.FirstName}
            onKeyPress={(e) => enterKeyPressed(e)}
            maxlength={30}
            name="FirstName"
            inputlabel="First Name"
          />
        </Grid>

        <Grid item md={2}>
          {/* 
          <Typography variant="caption" className="racFormLabel">
            Date of Birth
          </Typography> */}
          {/* <input type="date" max={datepicker} data-testid="dateofbirth" className="form-control" id="a11y_dob" name="dob" value={search.dob} onChange={(e) => OnsearchChange(e)} onKeyPress={(e) => enterKeyPressed(e)} /> */}
          <RACDatePicker
            onChange={(e) => OnsearchChange(e)}
            inputProps={{ max: maxdate }}
            name="dob"
            label={'Date of Birth'}
            value={search.dob}
          />
        </Grid>

        {/* AC_08 */}
        <Grid item md={2} className={addCOCustomerClassName.ssnpadding}>
          <Typography variant="caption" className={classes.formLabel}>
            Social Security# / ITIN#
          </Typography>
          <Grid container spacing={1}>
            <Grid item md={4}>
              {/* <input type="password" maxLength={3} className="form-control" data-testid="ssn1textbox" name="ThreeDigitSSN" id="ssn3" value={SSN.ThreeDigitSSN} onChange={(e) => handlessn1(e)} /> */}
              <RACTextbox
                // inputlabel="Social Security# / ITIN#"
                placeHolder={'xxx'}
                type="password"
                isCurrency={false}
                value={SSN.ThreeDigitSSN}
                maxlength={3}
                name="ThreeDigitSSN"
                id="ssn3"
                OnChange={(e) => handlessn1(e)}
              />
            </Grid>
            <Grid item md={3}>
              {/* <input type="password" className="form-control" data-testid="ssn2textbox" name="TwoDigitSSN" value={SSN.TwoDigitSSN} pattern="\d*" maxLength={2} id="ssn2" onChange={(e) => handlessn1(e)} /> */}

              <RACTextbox
                type="password"
                placeHolder={'xx'}
                isCurrency={false}
                value={SSN.TwoDigitSSN}
                digitFormat="normal"
                maxlength={2}
                name="TwoDigitSSN"
                id="ssn2"
                OnChange={(e) => handlessn1(e)}
              />
            </Grid>
            <Grid item md={5}>
              {/* <input type="text" className="form-control" data-testid="ssn3textbox" name="FourDigitSSN" value={SSN.FourDigitSSN} id="ssn4" pattern="\d*" maxLength={4} onChange={(e) => handlessn1(e)} onKeyPress={(e) => enterKeyPressed(e)}/> */}

              <RACTextbox
                type="text"
                placeHolder={'xxxx'}
                isCurrency={false}
                value={SSN.FourDigitSSN}
                maxlength={4}
                name="FourDigitSSN"
                id="ssn4"
                OnChange={(e) => handlessn1(e)}
                onKeyPress={(e) => enterKeyPressed(e)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={2} className={addCOCustomerClassName.ssnpadding}>
          {/* <label htmlFor="a11y_phonenumber" className="form-label">
            Phone Number
          </label> */}
          <Typography variant="caption" className={classes.formLabel}>
            Phone Number
          </Typography>
          <PhoneInput
            type="text"
            data-testid="phoneNum"
            country="US"
            name="phone_number"
            className={addCOCustomerClassName.phoneinput}
            id="Phone"
            pattern="\d*"
            maxLength={14}
            value={phonenumber}
            onChange={Setphonenumber}
            onKeyPress={(e) => enterKeyPressed(e)}
          />
        </Grid>
        <Grid item md={2}>
          {/* {enableloader === true ? (
            <div className={classes.Norecords}>
              <CircularProgress />
            </div>
          ) : null} */}
          {/* <button
               type="button"
               data-testid="customersearch"
               className="btn btn-primary mt-1 search-btn"
               disabled={btnstate}
               onClick={() => Search_Onclick()}
             >
               Search
             </button> */}
          <RACButton
            size="small"
            disabled={btnstate}
            variant="contained"
            color="primary"
            isRounded={false}
            className={`${classes.mt3} ${classes.floatRight} `}
            onClick={() => Search_Onclick()}
          >
            <SearchImage
              className={addCOCustomerClassName.racsearchBtn}
            ></SearchImage>
            Search
          </RACButton>
        </Grid>
      </Grid>

      {enableloader === true ? (
        <Grid className={`${classes.w100} ${classes.Norecords}`}>
          <CircularProgress />
        </Grid>
      ) : null}
      {hidesearch ? (
        <Grid
          className={`${classes.w100} ${classes.floatLeft} ${classes.my3}`}
          data-testid="searchhide"
        >
          {searchData !== undefined &&
            searchData !== null &&
            searchData.length > 0 ? (
            <Grid className={classes.containerstyle} id="hidecreatecocustomer">
              <Typography
                className={`${classes.subTitle} ${classes.searchcustomer} ${classes.mt3} ${classes.floatLeft}`}
              >
                Search Results
              </Typography>
              <Grid className={classes.floatRight}>
                <RACButton
                  variant="contained"
                  color="primary"
                  isRounded={false}
                  onClick={() => createcocustomerclick()}
                >
                  Create Co-Customer
                </RACButton>
              </Grid>
            </Grid>
          ) : null}

          {clearData === true &&
            enableloader === false &&
            searchData !== undefined &&
            searchData !== null &&
            searchData.length <= 0 ? (
            <Grid className={classes.Norecords}>
              <Loadalert className={classes.alertIcon}></Loadalert>
              No Records Found
              <Typography className={addCOCustomerClassName.newCustomer}>
                Do you want to create{' '}
                <Typography
                  variant="caption"
                  className={addCOCustomerClassName.customerName}
                >
                  {searchedlastname} {searchedUser}
                </Typography>{' '}
                as a new customer?
              </Typography>
              <Grid className={classes.w100} id="showcocustomer">
                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                  <RACButton
                    variant="contained"
                    color="primary"
                    isRounded={false}
                    onClick={() => createcocustomerclick()}
                  >
                    Create Co-Customer
                  </RACButton>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <div>
              {masterloader ? (
                <Grid className={classes.masterLoader}>
                  <Grid className={classes.Loader}>
                    <CircularProgress />
                  </Grid>
                </Grid>
              ) : null}{' '}
              {enableloader === false ? (
                <Grid
                  id="scrollit"
                  className={`${classes.w100} ${classes.mt5} ${addCOCustomerClassName.fixTableHead}`}
                >
                  {/* /* eslint-disable-next-line sonarjs/cognitive-complexity * */}

                  {searchData !== undefined && searchData !== null ? (
                    <RACTable
                      renderTableHead={RenderTable}
                      renderTableContent={RenderTableBody}
                    />
                  ) : null}
                </Grid>
              ) : null}
              {hidesearch ? (
                <Grid
                  className={`${classes.w100} ${classes.mt3} ${classes.textRight} ${addCOCustomerClassName.footerFixedButtons}`}
                >
                  {/* <button
                     type="button"
                     data-testid="cancel"
                     className="btn btn-secondary mx-1"
                     data-bs-dismiss="modal"
                     onClick={() => closepopup()}
                   >
                     Cancel
                   </button> */}
                  <RACButton
                    isRounded={false}
                    variant="outlined"
                    color="primary"
                    className={classes.mx1}
                    onClick={() => closepopup()}
                  >
                    Cancel
                  </RACButton>

                  {/* <button
                     type="button"
                     data-testid="submit"
                     className="btn btn-primary mx-1"
                     disabled={enableSubmitButton}
                     onClick={() => Submit_click()}
                   >
                     Submit
                   </button> */}
                  <RACButton
                    disabled={enableSubmitButton}
                    variant="contained"
                    color="primary"
                    isRounded={false}
                    className={classes.mx1}
                    onClick={() => Submit_click()}
                  >
                    Submit
                  </RACButton>
                </Grid>
              ) : null}
            </div>
          )}
        </Grid>
      ) : null}
    </Grid>
  );

  /* eslint-disable-next-line sonarjs/cognitive-complexity */

  const successpopup = () => {
    return (
      <>
        <div
          id="success"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true"
        >
          <Grid className={`${classes.justifyCenter} ${classes.textCenter}`}>
            <Successimg></Successimg>
            <Typography
              className={`${classes.agrDeliveryReceiptText} ${classes.textCenter} ${classes.mt3}`}
            >
              Co-Customer has been added Successfully
            </Typography>
          </Grid>

          <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
            {/* <button type="button" className="btn btn-primary mx-1" data-testid="btnok" data-bs-dismiss="modal" onClick={(e) => Removevoid()} >OK</button> */}

            <RACButton
              size="small"
              isRounded={false}
              variant="contained"
              color="primary"
              onClick={() => createcocustomer()}
            >
              OK
            </RACButton>
          </Grid>
        </div>
      </>
    );
  };
  const secondcustomersuccesspopup = () => {
    return (
      <>
        <div
          id="success"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true"
        >
          <Grid className={`${classes.justifyCenter} ${classes.textCenter}`}>
            <Successimg></Successimg>
            <Typography
              className={`${classes.agrDeliveryReceiptText} ${classes.textCenter} ${classes.mt3}`}
            >
              Co-Customer has been added Successfully
            </Typography>
          </Grid>

          <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
            {/* <button type="button" className="btn btn-primary mx-1" data-testid="btnok" data-bs-dismiss="modal" onClick={(e) => Removevoid()} >OK</button> */}

            <RACButton
              size="small"
              isRounded={false}
              variant="contained"
              color="primary"
              onClick={() => setactivecustomersuccesspopup(false)}
            >
              OK
            </RACButton>
          </Grid>
        </div>
      </>
    );
  };

  const errorpopup = () => {
    return (
      <>
        {masterloader ? (
          <Grid className={classes.masterLoader}>
            <Grid className={classes.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Grid item md={12} className={classes.textCenter}>
          <Loadalert> </Loadalert>

          <Typography className={classes.my2}>
            Customer record is incomplete,
          </Typography>
          <Typography className={classes.my2}>
            please create new co-customer
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => AddcocustomerError()}
          >
            Ok
          </RACButton>
        </Grid>
      </>
    );
  };
  // eslint-disable-next-line sonarjs/no-identical-functions
  const nexterror = () => {
    return (
      <>
        <div
          id="success"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true"
        >
          <Grid item md={12} className={classes.textCenter}>
            <Loadalert> </Loadalert>

            <Typography className={classes.my2}>
              Something went wrong
            </Typography>
          </Grid>

          <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
            <RACButton
              isRounded={false}
              variant="contained"
              color="primary"
              onClick={() => newcustomer()}
            >
              Ok
            </RACButton>
          </Grid>
        </div>
      </>
    );
  };

  const secondcustomerpopup = () => {
    return (
      <div
        id="success"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={`${classes.justifyCenter} ${classes.textCenter}`}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.textCenter} ${classes.mt3}`}
          >
            You currently have a co-customer named{' '}
            <span style={{ fontWeight: 'bold' }}>{CocustomerName}</span> on an
            active agreement. Automatically adding them to your agreement as a
            co-customer.
          </Typography>
        </Grid>

        <Grid md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            size="small"
            isRounded={false}
            variant="outlined"
            color="primary"
            className={classes.mx1}
            onClick={() => Setagreementcustomerpopup(false)}
          >
            No
          </RACButton>
          <RACButton
            size="small"
            isRounded={false}
            variant="contained"
            color="primary"
            className={classes.mx1}
            onClick={() => showaddcocustomer()}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };
  const redirectionpopup = () => {
    return (
      <div
        id="success"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textCenter}>
          <Loadalert> </Loadalert>

          <Typography className={classes.my2}>
            Customer record is incomplete,
          </Typography>
          <Typography className={classes.my2}>
            {' '}
            please create new Co-Customer.
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => redirectionok()}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  const firstcustomerpopup = () => {
    return (
      <div
        id="success"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={`${classes.justifyCenter} ${classes.textCenter}`}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.textCenter} ${classes.mt3}`}
          >
            Would you like to add Co-Customer to this agreement?
          </Typography>
        </Grid>

        <Grid md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            size="small"
            isRounded={false}
            variant="contained"
            color="primary"
            className={classes.mx1}
            onClick={() => setnewcocustomerpopup(false)}
          >
            No
          </RACButton>
          <RACButton
            size="small"
            isRounded={false}
            variant="outlined"
            color="primary"
            className={classes.mx1}
            onClick={() => {
              searchcustomer();
              setnewcocustomerpopup(false);
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };

  const customerclassification = () => {
    return (
      <div
        id="success"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={`${classes.justifyCenter} ${classes.textCenter}`}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.textCenter} ${classes.mt3}`}
          >
            The customer has been marked with one or more of the following
            classifications: Skip/Stolen/Hard. Do you want to continue?
          </Typography>
        </Grid>

        <Grid md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            size="small"
            isRounded={false}
            variant="outlined"
            color="primary"
            className={classes.mx1}
            onClick={() => {
              setclassificationpopup(false);
              customersearch();
            }}
          >
            No
          </RACButton>
          <RACButton
            size="small"
            isRounded={false}
            variant="contained"
            color="primary"
            className={classes.mx1}
            onClick={() => {
              classifycustomer();
              setclassificationpopup(false);
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };

  const customerconfirmation = () => {
    return (
      <div
        id="success"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={`${classes.justifyCenter} ${classes.textCenter}`}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.textCenter} ${classes.mt3}`}
          >
            Primary has been marked with one or more of the following
            classifications: Skip/Stolen/Hard. This will affect the Co-Customer
            as well. Are you sure?
          </Typography>
        </Grid>

        <Grid md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            size="small"
            isRounded={false}
            variant="outlined"
            color="primary"
            className={classes.mx1}
            onClick={() => {
              setconfirmationpopup(false);
              searchcustomer();
            }}
          >
            No
          </RACButton>
          <RACButton
            size="small"
            isRounded={false}
            variant="contained"
            color="primary"
            className={classes.mx1}
            onClick={() => {
              setconfirmationpopup(false);
              submitvalidate();
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };
  const openAlertModalContent = () => {
    return (
      <div>
        <Grid className={twoFactorClassName.textCenter}>
          <Loadalert></Loadalert>
          <Typography className={twoFactorClassName.racErrorFunction}>
            The user you entered can not authorize the use of this function.
          </Typography>
        </Grid>
        <Grid className={twoFactorClassName.textCenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={twoFactorClassName.spacerME2}
            onClick={okClick}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const pinValidation = (event: any) => {
    // eslint-disable-next-line no-console
    console.log('pinvalidation');
    if (event.charCode === 13) {
      event.preventDefault();
      continuechecking();
    }
  };

  const openRemoteModal = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          <RACLogo className={Remoteclasses.logoResponsive}></RACLogo>
        </Grid>
        {errorMessage !== '' ? (
          <Grid className={Remoteclasses.spacerMargin}>
            <Typography className={Remoteclasses.racRemoteLoginAlert}>
              {errorMessage}
            </Typography>
          </Grid>
        ) : null}
        <Grid className={Remoteclasses.spacerMargin}>
          <Typography className={Remoteclasses.racRemoteLogin}>
            Please enter the DM in Training or above level security code to
            continue
          </Typography>
        </Grid>
        <Grid container spacing={2} className={Remoteclasses.spacerMT1}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Remote ID
            </Typography>
            <RACTextbox
              maxlength={30}
              isCurrency={false}
              type="text"
              value={remoteId}
              OnChange={(event) => handleRemoteID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Security Code
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event) => remoteEnter(event, remoteId, securityCode)}
              isCurrency={false}
              type="password"
              value={securityCode}
              OnChange={(event) => handleSecurityCode(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              Remoteclasses.spacerMT5,
              Remoteclasses.spacerMB3
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={Remoteclasses.spacerME2}
            onClick={remoteModelCancelClick}
          >
            Cancel
          </RACButton>
          {remoteId !== '' && securityCode !== '' ? (
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => remoteContinue(remoteId, securityCode)}
            >
              Continue
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              className={Remoteclasses.disabled}
            >
              Continue
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };

  const openPinModalContent = () => {
    return (
      <div>
        <Grid container className={twoFactorClassName.racPinContainer}>
          <Grid item md={12}>
            <Typography className={twoFactorClassName.racPinLabel}>
              PIN
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            onKeyPress={continuecheck ? pinEnter : pinValidation}
          >
            <PinField
              length={4}
              type="numeric"
              focus={true}
              secret={true}
              autoSelect={false}
              style={{ marginLeft: '0.3rem' }}
              inputStyle={{
                border: '1px solid #adb5bd',
                borderRadius: '0.6rem',
                marginRight: '2rem',
              }}
              onComplete={(event) => pinValue(event)}
              onChange={(event) => otpchange(event)}
            />
          </Grid>
          {InvalidPin == 400 ? (
            <Grid>
              <Typography
                id="InvalidPin"
                className={twoFactorClassName.racPinError}
              >
                Invalid PIN{' '}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          className={`${twoFactorClassName.floatRight} ${twoFactorClassName.spacerMT5} ${twoFactorClassName.spacerMB3} ${twoFactorClassName.paddingButtons}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={twoFactorClassName.spacerME2}
            onClick={noClick}
          >
            Cancel
          </RACButton>
          {continuecheck ? (
            <RACButton
              variant="contained"
              color="primary"
              disabled={Continue}
              onClick={() => continueClick()}
            >
              Continue
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              disabled={Continue}
              onClick={() => continuechecking()}
            >
              Continue
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };
  const openModalContent = () => {
    return (
      <div>
        <Grid className={twoFactorClassName.textCenter}>
          <RacLogo className={twoFactorClassName.logoResponsive}></RacLogo>
        </Grid>
        {errorline === true ? (
          <Grid className={twoFactorClassName.spacerMargin}>
            <Typography className={twoFactorClassName.racLoginAlert}>
              The user ID and/or password you entered are not valid. Please try
              again.
            </Typography>
          </Grid>
        ) : null}
        <Grid container spacing={2} className={twoFactorClassName.spacerMT1}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={twoFactorClassName.formLabel}
            >
              User ID
            </Typography>
            <RACTextbox
              maxlength={30}
              isCurrency={false}
              type="text"
              value={userID}
              OnChange={(event) => handleUserID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={twoFactorClassName.formLabel}
            >
              Password
            </Typography>

            {logincheck ? (
              <RACTextbox
                maxlength={15}
                onKeyPress={(event) =>
                  secondfactorEnter(event, userID, password)
                }
                isCurrency={false}
                type="password"
                value={password}
                OnChange={(event) => handlePassword(event)}
              ></RACTextbox>
            ) : (
              <RACTextbox
                maxlength={15}
                onKeyPress={(event) =>
                  secondfactorEvent(event, userID, password)
                }
                isCurrency={false}
                type="password"
                value={password}
                OnChange={(event) => handlePassword(event)}
              ></RACTextbox>
            )}
          </Grid>
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              Remoteclasses.spacerMT5,
              Remoteclasses.spacerMB3,
              Remoteclasses.buttonMargin
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={twoFactorClassName.spacerME2}
            onClick={openModelCancelClick}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={Remoteclasses.spacerME2}
            onClick={() => remoteClick()}
          >
            Remote
          </RACButton>
          {userID !== '' && password !== '' ? (
            logincheck ? (
              <RACButton
                variant="contained"
                color="primary"
                onClick={() => loginClick(userID, password)}
              >
                Login
              </RACButton>
            ) : (
              <RACButton
                variant="contained"
                color="primary"
                onClick={() => loginchecking(userID, password)}
              >
                Login
              </RACButton>
            )
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              className={twoFactorClassName.disabled}
            >
              Login
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };

  const SearchgridHead = [
    {
      displayName: '',
    },
    {
      displayName: 'LastName',
    },
    {
      displayName: 'FirstName',
    },
    {
      displayName: 'Date of Birth',
    },
    {
      displayName: 'Address',
    },
    {
      displayName: 'Phone#',
    },
    {
      displayName: '#Active Agreements',
    },
  ];

  const RenderTable = () => (
    <>
      {SearchgridHead.map((col, index) => {
        return <RACTableCell key={index}>{col.displayName}</RACTableCell>;
      })}
    </>
  );

  const declineRunDE = () => {
    setneedDE(false);
    newcustomercondition();
    // const urlParams = new URLSearchParams(window.location.search);
    // const coCustomerIdParams = urlParams.get('coCustomerId');
    // const coCustomerIdParamValue =
    //   coCustomerIdParams !== undefined &&
    //   coCustomerIdParams !== null &&
    //   coCustomerIdParams !== '' &&
    //   coCustomerIdParams !== 'N'
    //     ? coCustomerIdParams
    //     : '';
    // if (
    //   props !== undefined &&
    //   props.newCoCustomer !== undefined &&
    //   props.newCoCustomer === true &&
    //   CustomerValue !== undefined &&
    //   CustomerValue.CoCustomerId !== undefined &&
    //   CustomerValue.CoCustomerId == '' &&
    //   isOpenedPopup === false &&
    //   coCustomerIdParamValue === '' &&
    //   coCustomerIdParams !== 'N'
    // ) {
    //   // eslint-disable-next-line no-console
    //   console.log(
    //     'Inside addcocustomer componeent inside props if',
    //     CustomerInfoContext,
    //     CustomerValue,
    //     props
    //   );
    //   setmasterloader(false);
    //   setnewcocustomerpopup(true);
    //   setisOpenedPopup(true);
    // }
  };

  // const exceptionAccept = () => {
  //   setDEException(false);
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const coCustomerIdParams = urlParams.get('coCustomerId');
  //   const coCustomerIdParamValue =
  //     coCustomerIdParams !== undefined &&
  //     coCustomerIdParams !== null &&
  //     coCustomerIdParams !== '' &&
  //     coCustomerIdParams !== 'N'
  //       ? coCustomerIdParams
  //       : '';
  //   if (
  //     props !== undefined &&
  //     props.newCoCustomer !== undefined &&
  //     props.newCoCustomer === true &&
  //     CustomerValue !== undefined &&
  //     CustomerValue.CoCustomerId !== undefined &&
  //     CustomerValue.CoCustomerId == '' &&
  //     isOpenedPopup === false &&
  //     coCustomerIdParamValue === '' &&
  //     coCustomerIdParams !== 'N'
  //   ) {
  //     // eslint-disable-next-line no-console
  //     console.log(
  //       'Inside addcocustomer component insideee props if',
  //       CustomerInfoContext,
  //       CustomerValue,
  //       props
  //     );
  //     setmasterloader(false);
  //     setnewcocustomerpopup(true);
  //     setisOpenedPopup(true);
  //   }
  // };

  const acceptRunDE = async () => {
    //setneedDE(false);
    //setmasterloader(true);
    // eslint-disable-next-line no-console
    console.log(
      'check entireGetCustomerData payload formation',
      entireGetCustomerData
    );
    // eslint-disable-next-line no-console
    console.log(
      'entireGetCustomerData.addresses[0].state',
      entireGetCustomerData.addresses?.[0]?.state
    );
    const enteredssn = ssn.ThreeDigitssn + ssn.TwoDigitssn + ssn.FourDigitssn;
    if (
      entireGetCustomerData !== undefined &&
      entireGetCustomerData.taxId
    ) {
      const phoneDetails = getPrimaryPhoneNumber(entireGetCustomerData.phones)
      // eslint-disable-next-line no-console
      console.log('phoneDetails', phoneDetails)
      const DEapiPayload: any = {
        firstName: entireGetCustomerData.firstName,
        lastName: entireGetCustomerData.lastName,
        dateOfBirth: entireGetCustomerData.dateOfBirthEnc,
        storeNumber: window.sessionStorage.getItem('storeNumber'),
        taxId: enteredssn == '' ? entireGetCustomerData.taxIdEnc : enteredssn,
        // languagePreference: entireGetCustomerData.preferredLanguage,
        customerId: entireGetCustomerData.customerId,
        globalCustomerId: entireGetCustomerData?.globalCustomerId,
        // address: {
        //   addressType: entireGetCustomerData.addresses[0].addressType,
        //   addressLine1: entireGetCustomerData.addresses[0].addressLine1,
        //   addressLine2: entireGetCustomerData.addresses[0].addressLine2,
        //   city: entireGetCustomerData.addresses[0].city,
        //   state: entireGetCustomerData.addresses[0].state,
        //   postalCode: entireGetCustomerData.addresses[0].postalCode,
        // },
        phone: {
          phoneNumber: phoneDetails[0]?.phoneNumber,
          phoneType: phoneDetails[0]?.phoneType,
          primary: phoneDetails[0]?.primary,
          callTimeType: phoneDetails[0]?.callTimeType,
        },
      };

      const activeaddress: any = getCustomerAddress(entireGetCustomerData?.addresses)
      // eslint-disable-next-line no-console
      console.log("activeaddress", activeaddress)
      if (activeaddress) {
        DEapiPayload.address = {
          addressType: activeaddress?.addressType,
          addressLine1: activeaddress?.addressLine1,
          addressLine2: activeaddress?.addressLine2,
          city: activeaddress?.city,
          state: activeaddress?.state,
          postalCode: activeaddress?.postalCode,
        }
      }

      /* eslint-disable no-console */
      console.log('DEapiPayload formed data', DEapiPayload);
      setDeloader(true);
      const response = await RunDE(DEapiPayload);
      console.log('check the response logger after log', response);
      setDeloader(false);
      // setmasterloader(false);
      if (response === undefined) {
        setneedDE(false);
        setDEException(true);
      } else if (response.status == 200) {
        setneedDE(false);
        setIsAllDone(true);
        setshowProgress(true);
        const statusOfApproval = response.data.approvalResponse;

        if (statusOfApproval == '1') {
          setDEsuccessmessage('approved');
        } else if (statusOfApproval == '2') {
          setDEsuccessmessage('declined');
        } else if (statusOfApproval == '4' || statusOfApproval == '5') {
          setDEsuccessmessage('conditionally approved');
        }
        setafterDEsuccess(true);
        setapprovalAmountHolder(response);
        props.somethingTrigger();
      } else if (response.status == 500 || response.status == 400 || response.status == 504) {
        setneedDE(false);
        setDEException(true);
      }
    } else if (entireGetCustomerData.taxId == null || entireGetCustomerData.taxId == '') {
      setRequireSSN(true);
    }
    setmasterloader(false);
  };

  // const runDEModal = () => {
  //   return (
  //     <div>
  //       {/* <Grid item md={12} className={classes.textCenter}>
  //         <Typography
  //           variant="caption"
  //           className={`${classes.bold} ${classes.font17}`}
  //         >
  //           Do you want to run DE ?
  //         </Typography>
  //       </Grid> */}
  //       <Grid className={classes.textCenter}>
  //         <Typography>Do you want to run DE ?</Typography>
  //       </Grid>
  //       <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
  //         <RACButton
  //           className={classes.me3}
  //           variant="outlined"
  //           color="secondary"
  //           size="large"
  //           // onClick={() => {
  //           //   declineRunDE
  //           // }}
  //           onClick={declineRunDE}
  //         >
  //           No
  //         </RACButton>
  //         <RACButton
  //           className={classes.mx1}
  //           variant="contained"
  //           color="primary"
  //           size="large"
  //           onClick={acceptRunDE}
  //         >
  //           Yes
  //         </RACButton>
  //       </Grid>
  //     </div>
  //   );
  // };

  const buildServerException = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <AlertIcon />
          <Typography className={`${classes.textCenter} ${classes.pt2}`}>
            Error in approval process. Please proceed without approval.
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setDEException(false);
              setreasonDEopen(true);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // const declineSSNrunde = async () => {
  //   setRequireSSN(false);

  //   setconditionLoader(true);

  //   const decisionengineresponse = await GetDeReason();
  //   setconditionLoader(false);
  //   // eslint-disable-next-line no-console
  //   console.log('hebfeybfwybuwbsdubdwub', decisionengineresponse.data);
  //   if (
  //     decisionengineresponse.data !== undefined &&
  //     decisionengineresponse.data !== null &&
  //     decisionengineresponse.data.referenceDetails !== null
  //   ) {
  //     // eslint-disable-next-line no-console
  //     console.log('svcgscvhscv');
  //     const ReasonValue = decisionengineresponse.data.referenceDetails;
  //     const finalreasonvalue: any = [
  //       { description: 'Select', referenceCode: 'SEL' },
  //       ...ReasonValue,
  //     ];
  //     // eslint-disable-next-line no-console
  //     console.log('hdbfhdfbjsfbhjsj');
  //     setdropdownreason(finalreasonvalue);
  //     setreasonDEopen(true);
  //   }
  // };
  const buildRequireSSN = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          {/* <AlertIcon /> */}
          <Typography className={`${classes.subTitle}`}>
            If you want to run DE, Please enter the SSN
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => {
              setRequireSSN(false);
              setreasonDEopen(true);
            }}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setRequireSSN(false);
              setenterSSN(true);
            }}
          >
            Next
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const handlessn = (e: any) => {
    let regexItem;
    if (
      e.target.name == 'ThreeDigitssn' ||
      e.target.name == 'TwoDigitssn' ||
      e.target.name == 'FourDigitssn'
    ) {
      regexItem = new RegExp('^[0-9]*$');
    }
    if (e.target.value.match(regexItem)) {
      setssn({ ...ssn, [e.target.name]: e.target.value });

      if (e.target.name === 'ThreeDigitssn' && e.target.value.length === 3) {
        (document.getElementById('SSN2') as HTMLInputElement).focus();
      } else if (
        e.target.name === 'TwoDigitssn' &&
        e.target.value.length === 2
      ) {
        (document.getElementById('SSN4') as HTMLInputElement).focus();
      } else if (
        e.target.name === 'TwoDigitssn' &&
        e.target.value.length === 0 &&
        ssn.ThreeDigitssn.length > 0
      ) {
        (document.getElementById('SSN3') as HTMLInputElement).focus();
      } else if (
        e.target.name === 'FourDigitssn' &&
        e.target.value.length === 0 &&
        ssn.ThreeDigitssn.length > 0
      ) {
        (document.getElementById('SSN2') as HTMLInputElement).focus();
      }
      // else if (
      //   e.target.name === 'FourDigitssn' &&
      //   e.target.value.length === 4
      // ) {
      //   sethidesaveandrunde(false);
      // } else if (
      //   e.target.name === 'FourDigitssn' &&
      //   e.target.value.length <= 3
      // ) {
      //   sethidesaveandrunde(true);
      // }
    }
  };
  const acceptRunDEWithSSN = async () => {
    // setneedDE(false);
    // setmasterloader(true);
    // eslint-disable-next-line no-console
    console.log(
      'check entireGetCustomerData payload formation',
      entireGetCustomerData
    );
    // eslint-disable-next-line no-console
    // eslint-disable-next-line no-console
    console.log(
      'entireGetCustomerData.addresses[0].state',
      entireGetCustomerData.addresses?.[0]?.state
    );
    const enteredssn = ssn.ThreeDigitssn + ssn.TwoDigitssn + ssn.FourDigitssn;
    console.log('grbfvdcas', enteredssn);

    if (entireGetCustomerData !== undefined) {
      const phoneDetails = getPrimaryPhoneNumber(entireGetCustomerData.phones)
      console.log('phoneDetails', phoneDetails);

      const DEapiPayload: any = {
        firstName: entireGetCustomerData.firstName,
        lastName: entireGetCustomerData.lastName,
        dateOfBirth: entireGetCustomerData.dateOfBirthEnc,
        storeNumber: window.sessionStorage.getItem('storeNumber'),
        taxId: enteredssn == '' ? entireGetCustomerData.taxIdEnc : enteredssn,
        // languagePreference: entireGetCustomerData.preferredLanguage,
        customerId: entireGetCustomerData.customerId,
        globalCustomerId: entireGetCustomerData?.globalCustomerId,
        // address: {
        //   addressType: entireGetCustomerData.addresses[0].addressType,
        //   addressLine1: entireGetCustomerData.addresses[0].addressLine1,
        //   addressLine2: entireGetCustomerData.addresses[0].addressLine2,
        //   city: entireGetCustomerData.addresses[0].city,
        //   state: entireGetCustomerData.addresses[0].state,
        //   postalCode: entireGetCustomerData.addresses[0].postalCode,
        // },
        phone: {
          phoneNumber: phoneDetails[0]?.phoneNumber,
          phoneType: phoneDetails[0]?.phoneType,
          primary: phoneDetails[0]?.primary,
          callTimeType: phoneDetails[0]?.callTimeType,
        },
      };

      const activeaddress: any = getCustomerAddress(entireGetCustomerData?.addresses)
      // eslint-disable-next-line no-console
      console.log("activeaddress", activeaddress)
      if (activeaddress) {
        DEapiPayload.address = {
          addressType: activeaddress?.addressType,
          addressLine1: activeaddress?.addressLine1,
          addressLine2: activeaddress?.addressLine2,
          city: activeaddress?.city,
          state: activeaddress?.state,
          postalCode: activeaddress?.postalCode,
        }
      }

      /* eslint-disable no-console */
      console.log('DEapiPayload formed data', DEapiPayload);
      setDeloader(true);
      const response = await RunDE(DEapiPayload);
      setDeloader(false);
      console.log('check the response logger after log', response);

      // setmasterloader(false);
      if (response === undefined) {
        setneedDE(false);
        setDEException(true);
      } else if (response.status == 200) {
        setneedDE(false);
        setIsAllDone(true);
        setshowProgress(true);
        // const urlParams = new URLSearchParams(window.location.search);
        // const coCustomerIdParams = urlParams.get('coCustomerId');
        // const coCustomerIdParamValue =
        //   coCustomerIdParams !== undefined &&
        //   coCustomerIdParams !== null &&
        //   coCustomerIdParams !== '' &&
        //   coCustomerIdParams !== 'N'
        //     ? coCustomerIdParams
        //     : '';
        // if (
        //   props !== undefined &&
        //   props.newCoCustomer !== undefined &&
        //   props.newCoCustomer === true &&
        //   CustomerValue !== undefined &&
        //   CustomerValue.CoCustomerId !== undefined &&
        //   CustomerValue.CoCustomerId == '' &&
        //   isOpenedPopup === false &&
        //   coCustomerIdParamValue === '' &&
        //   coCustomerIdParams !== 'N'
        // ) {
        //   // eslint-disable-next-line no-console
        //   console.log(
        //     'Inside addcocustomer component inside props if',
        //     CustomerInfoContext,
        //     CustomerValue,
        //     props
        //   );
        //   // setmasterloader(false);
        //   setnewcocustomerpopup(true);
        //   setisOpenedPopup(true);
        // }
        setapprovalAmountHolder(response);
        const statusOfApproval = response.data.approvalResponse;

        if (statusOfApproval == '1') {
          setDEsuccessmessage('approved');
        } else if (statusOfApproval == '2') {
          setDEsuccessmessage('declined');
        } else if (statusOfApproval == '4' || statusOfApproval == '5') {
          setDEsuccessmessage('conditionally approved');
        }
        setafterDEsuccess(true);
        props.somethingTrigger();
      } else if (response.status == 500 || response.status == 400 || response.status == 504) {
        setDEException(true);
      }
    }

    // else {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   const coCustomerIdParams = urlParams.get('coCustomerId');
    //   const coCustomerIdParamValue =
    //     coCustomerIdParams !== undefined &&
    //     coCustomerIdParams !== null &&
    //     coCustomerIdParams !== '' &&
    //     coCustomerIdParams !== 'N'
    //       ? coCustomerIdParams
    //       : '';
    //  if (
    //     props !== undefined &&
    //     props.newCoCustomer !== undefined &&
    //     props.newCoCustomer === true &&
    //     CustomerValue !== undefined &&
    //     CustomerValue.CoCustomerId !== undefined &&
    //     CustomerValue.CoCustomerId == '' &&
    //     isOpenedPopup === false &&
    //     coCustomerIdParamValue === '' &&
    //     coCustomerIdParams !== 'N'
    //   ) {
    //     // eslint-disable-next-line no-console
    //     console.log(
    //       'Inside addcocustomer component inside props if',
    //       CustomerInfoContext,
    //       CustomerValue,
    //       props
    //     );
    //     setmasterloader(false);
    //     setnewcocustomerpopup(true);
    //     setisOpenedPopup(true);
    //   }
    // }
    // setmasterloader(false);
  };

  function getCustomerAddress(Address: any): any | null {
    const primaryAddress = Address?.length ? Address.find((add: any) => add?.addressType === 'PRIM' && add?.postalCode !== null): null;
    const mailingAddress = Address?.length ? Address.find((add: any) => add?.addressType === 'MAIL' && add?.postalCode !== null) : null;
    const deliveryAddress = Address?.length ? Address.find((add: any) => add?.addressType === 'DELIV' && add?.postalCode !== null) : null;
    const billingAddress = Address?.length ? Address.find((add: any) => add?.addressType === 'B' && add?.postalCode !== null) : null;
    const previousAddress = Address?.length ? Address.find((add: any) => add?.addressType === 'PREV' && add?.postalCode !== null) : null;
    // console.log( primaryAddress || mailingAddress || deliveryAddress || billingAddress || previousAddress)

    return primaryAddress || mailingAddress || deliveryAddress || billingAddress || previousAddress;
  };

  const saveandrunDEclick = async () => {
    setenterSSN(false);
    setssnloader(true);

    const payloadreq = ssn.ThreeDigitssn + ssn.TwoDigitssn + ssn.FourDigitssn;
    const postnoDE = {
      customerId: customerId,
      taxId: payloadreq,
    };
    const postnodecall = await updatecustomer(postnoDE);
    // eslint-disable-next-line no-console
    console.log('hbwsbwb', postnodecall);
    setssnloader(false);
    if (postnodecall.status === 200) {
      setentireGetCustomerData({ ...entireGetCustomerData, taxId: payloadreq });
      acceptRunDEWithSSN();
    } else {
      setIsAllDone(true);
      setshowProgress(true);
      newcustomercondition();
    }
  };
  const Getssn = () => {
    return (
      <Grid>
        <Grid>
          {/* <AlertIcon /> */}
          <Typography variant="caption" className={classes.formSSNLabel}>
            Social Security# / ITIN#
          </Typography>
          <Grid container spacing={1} className={classes.mt2}>
            <Grid item md={3}>
              <RACTextbox
                placeHolder={'***'}
                type="password"
                isCurrency={false}
                className={classes.ssnPadding}
                value={ssn.ThreeDigitssn}
                maxlength={3}
                name="ThreeDigitssn"
                id="SSN3"
                OnChange={(e) => handlessn(e)}
              />
            </Grid>
            <Grid item md={2}>
              <RACTextbox
                type="password"
                placeHolder={'**'}
                isCurrency={false}
                value={ssn.TwoDigitssn}
                className={classes.ssnPadding}
                digitFormat="normal"
                maxlength={2}
                name="TwoDigitssn"
                id="SSN2"
                OnChange={(e) => handlessn(e)}
              />
            </Grid>
            <Grid item md={3}>
              <RACTextbox
                type="text"
                placeHolder={'****'}
                isCurrency={false}
                value={ssn.FourDigitssn}
                className={classes.ssnPadding}
                maxlength={4}
                name="FourDigitssn"
                id="SSN4"
                OnChange={(e) => handlessn(e)}
                onKeyPress={(e) => enterKeyPressed(e)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={`${classes.w100} ${classes.mt15} ${classes.mt3} ${classes.textRight}`}
        >
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() =>
              history.push(`/customer/update/${customerId}/customer`)
            }
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            disabled={hidesaveandrunde}
            onClick={() => saveandrunDEclick()}
          >
            Save & Run DE
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const deloading = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          {/* <img src={AnimLoading} alt="Loading" style={{ width: '10%' }} /> */}
          {/* <GifPlayer
            gif="../../../assets/images/AnimLoading.gif"
            className={classes.width10}
            autoplay={true}
          /> */}
          {/* <Spinner style={{ width: '40%' }} /> */}
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            {/* <FadeLoader color="#4058f6" speedMultiplier={1} width={3} /> */}
            Decision Engine is Running
          </Typography>
          <Typography
            className={`${classes.greyFont} ${classes.font12} ${classes.mt2}`}
          >
            Please wait
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const Desucess = () => {
    return (
      <>
        <Grid className={`${classes.justifyCenter} ${classes.textCenter}`}>
          {featureFlagDetails.EnableDEDeclineApproval == '1' && DEsuccessmessage == "declined" ? <AlertIcon /> : null}
          <Typography
            className={`${classes.font17} ${classes.bold} ${classes.mt3}`}
          >
            {`Decision Engine has ${DEsuccessmessage} the Customer. ${featureFlagDetails.EnableDEDeclineApproval == '1' && DEsuccessmessage === "declined" ? "Second" : ""}`}
          </Typography>
          {featureFlagDetails.EnableDEDeclineApproval == '1' && DEsuccessmessage == "declined" ? (
            <>
              <Typography className={`${classes.font17} ${classes.bold}`}>
                Level approval is required to create agreement.
              </Typography>
              <Typography className={`${classes.font17} ${classes.bold}`}>
                Do you want to proceed?
              </Typography>
            </>
          ) : null}
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          {/* <button type="button" className="btn btn-primary mx-1" data-testid="btnok" data-bs-dismiss="modal" onClick={(e) => Removevoid()} >OK</button> */}
          {featureFlagDetails.EnableDEDeclineApproval == '1' && DEsuccessmessage == "declined" ? 
          <RACButton
          size="small"
          isRounded={false}
          variant="outlined"
          color="primary"
          className={`${classes.me4}`}
          onClick={() => {
            history.goBack();
          }}
        >
          Cancel
        </RACButton>
          : null}
          <RACButton
            size="small"
            isRounded={false}
            variant="contained"
            color="primary"
            onClick={() => {
              if(featureFlagDetails.EnableDEDeclineApproval == '1' && DEsuccessmessage == "declined"){
                setDEDeclinedAuth(true);
                setafterDEsuccess(false);
              } else {
                setafterDEsuccess(false);
                setDecliedApproved(false);
                setIsAllDone(true);
                setshowProgress(true);
                newcustomercondition();
              }
            }}
          >
            {featureFlagDetails.EnableDEDeclineApproval == '1' && DEsuccessmessage == "declined" ? 'Proceed' : 'Ok' }
          </RACButton>
        </Grid>
      </>
    );
  };
  const postNoDESubmitClick = async () => {
    setreasonDEopen(false);
    setssnloader(true);
    // eslint-disable-next-line no-console
    console.log('bhbfjebfjefbej', GlobalcusId);

    const postnoDeobj = {
      customerId: customerId,
      deExceptionReason: dropdownvalue,
      globalCustomerId: GlobalcusId,
    };
    const payload: any = {
      storeException: [
        {
          identifierId: Number(customerId),
          identifierType: 'CUSTOMER',
          storeNumber: window.sessionStorage.getItem('storeNumber'),
          exceptionTypeRefCode: "DEB",
          exceptionText: storeExceptionReason
        }
      ]
    }
    const postnoDEresponse = await updatecustomer(postnoDeobj);
    storeException(payload)

    setssnloader(false);
    setIsAllDone(true);
    setshowProgress(true);
    // eslint-disable-next-line no-console
    console.log('ubuef', postnoDEresponse.data);
    // if (postnoDEresponse.status == 200) {
    newcustomercondition();
    // }
  };
  const noReasonDEPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={classes.fontHeading}>
            Please specify the reason, why you are not running the decision
            engine?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACSelect
            inputLabel="Reason"
            options={noDEReasonDD()}
            name="postNODEReason"
            defaultValue={dropdownvalue !== '' ? dropdownvalue : 'SEL'}
            loading={conditionLoader}
            onChange={(e: any) => {
              Setdropdownvalue(e.target.value);
              setStoreExceptionReason(e?.currentTarget?.innerText)

            }}
          />
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            className={`${classes.me2}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            disabled={reasoncancel}
            onClick={() => {
              setreasonDEopen(false);
              history.push({
                pathname: '/customer/searchcustomer',
              });
              // setRequireSSN(true);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => postNoDESubmitClick()}
            disabled={dropdownvalue !== 'SEL' ? false : true}
          >
            Submit
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const ssnloading = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          {/* <Spinner style={{ width: '40%' }} /> */}
          {/* <FadeLoader color="#4058f6" speedMultiplier={1} width={3} /> */}
          {/* <GifPlayer
            gif="../../../assets/images/AnimLoading.gif"
            className={classes.width10}
            autoplay={true}
          /> */}
          {/* <img src={AnimLoading} alt="Loading" style={{ width: '10%' }} /> */}
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Updating customer's information
          </Typography>
          <Typography
            className={`${classes.greyFont} ${classes.font12} ${classes.mt2}`}
          >
            Please wait
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const noDEReasonDD = () => {
    console.log('gbfdcvfvfdcsdvc');
    console.log('dropdownreason ejfvbdj', dropdownreason);

    if (
      dropdownreason !== undefined &&
      dropdownreason !== null &&
      dropdownreason.length > 0
    ) {
      console.log('gbfvdscfvdscsfvdsvsv');

      return dropdownreason.map((value: any, index: any) => {
        // if (index === 0) {
        //   return { label: 'Select', value: '0' };
        // } else {
        // if (value.id !== null &&value.id !== undefined &&value.id <= 67 && value.id >= 60)
        return { label: value.description, value: value.referenceCode };
        // }
      });
    }
  };

  const EmployeRestrictOnClick = () => {
    setemployeeRestrict(false);
    Setshowcocustomerpopup(true);
    Setsearch({ ...search, LastName: '', FirstName: '', dob: '' });
    Setphonenumber(undefined);
    setSSN(InitialSSN);
    SetHideSearch(false);
  };
  const EmployeeRestrictPopupOpen = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            The selected customer is a RAC Employee and cannot be added as a
            corenter.
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={EmployeRestrictOnClick}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  
  const logStoreException = async (type: string, customerId: string) => {
    try {
      const payload = {
        storeException: [
          {
            identifierId: Number(customerId),
            identifierType: "CUSTOMER",
            storeNumber: window.sessionStorage.getItem("storeNumber"),
            exceptionTypeRefCode: type,
            exceptionText: "DE Declined During Agreement Creation Process",
          },
        ],
      };
      const storeExceptionResponse = await storeException(payload);
      console.log("storeExceptionResponse", storeExceptionResponse);
    } catch (e) {
      console.log("Error In DE Decline Store Exception", e);
    }
  };

  return (
    <>
      {masterloader ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}

      <>
        {location.pathname.includes("switchout") ? null : (
          <>
            {hideaddcocustomerbtn === false || !CustomerValue?.CoCustomerId ? (
              IsAgreementVoided === false ? (
                <RACButton
                  className={`${classes.floatLeft} ${classes.me2}`}
                  variant="contained"
                  color="primary"
                  //hidden={hideaddcocustomerbtn}
                  onClick={() => searchcustomer()}
                  disabled={
                    agreementInfoData?.agreementInformation
                        ?.isExchanged == 1 ? true : restrictionerror ? true
                      : agreementInfoData?.agreementWorkflow
                          ?.isTransferInitiated == 1 ||
                        agreementInfoData?.agreementInformation
                          ?.agreementStatusGroupCode == "CLOSED" ||
                        agreementInfoData?.agreementInformation
                          ?.agreementType == "Rent to Rent - Loaner"
                      ? true
                      : false
                  }
                  // disabled={

                  //   agreementInfoData?.agreementInformation
                  //     ?.agreementStatusGroupCode == 'CLOSED'
                  //     ? true
                  //     : false
                  // }
                >
                  Add Co-Customer
                </RACButton>
              ) : (
                <RACButton
                  className={`${classes.floatLeft} ${classes.me2} ${classes.hide}`}
                  variant="contained"
                  color="primary"
                  //hidden={hideaddcocustomerbtn}
                  onClick={() => searchcustomer()}
                  // disabled={
                  //   agreementInfoData?.agreementInformation
                  //     ?.agreementStatusGroupCode == 'CLOSED'
                  //     ? true
                  //     : false
                  // }
                  disabled={
                    agreementInfoData?.agreementWorkflow
                    ?.isExchanged == 1 ? true : restrictionerror
                      ? true
                      : agreementInfoData?.agreementWorkflow
                          ?.isTransferInitiated == 1
                      ? true
                      : agreementInfoData?.agreementInformation
                          ?.agreementStatusGroupCode == "CLOSED"
                      ? true
                      : false
                  }
                >
                  Add Co-Customer
                </RACButton>
              )
            ) : null}
          </>
        )}
      </>

      {showcocustomerpopup && !location.pathname.includes("switchout") ? (
        <RACModalCard
          isOpen={showcocustomerpopup}
          maxWidth="xl"
          closeIcon={true}
          title={"Co-Customer Information"}
          borderRadius="20px"
          onClose={() => {
            closepopup();
            Setshowcocustomerpopup(false);
            setbtnstate(true);
            setnewcocustomerpopup(false);
          }}
          TitleVariantClassName={classes.popuptitle}
          titleVariant="h4"
          // eslint-disable-next-line react/no-children-prop
          children={addnewcocustomer()}
        />
      ) : null}

      {CoCustommersucesspopup ? (
        <RACModalCard
          isOpen={CoCustommersucesspopup}
          maxWidth="xs"
          borderRadius="20px"
          closeIcon={true}
          // title="Modal title"
          onClose={() => setCoCustommersucesspopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={successpopup()}
        />
      ) : null}
      {activecustomersuccesspopup ? (
        <RACModalCard
          isOpen={activecustomersuccesspopup}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          // title="Modal title"
          onClose={() => setactivecustomersuccesspopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={secondcustomersuccesspopup()}
        />
      ) : null}
      {addCoCustomerErrorPopupEnable ? (
        <RACModalCard
          isOpen={addCoCustomerErrorPopupEnable}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          // title="Modal title"
          onClose={() => setaddCoCustomerErrorPopupEnable(false)}
          // eslint-disable-next-line react/no-children-prop
          children={errorpopup()}
        />
      ) : null}

      {agreementcustomerpopup ? (
        <RACModalCard
          isOpen={agreementcustomerpopup}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          // title="Modal title"
          onClose={() => Setagreementcustomerpopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={secondcustomerpopup()}
        />
      ) : null}
      {addCoCustomerrror ? (
        <RACModalCard
          isOpen={addCoCustomerrror}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          // title="Modal title"
          onClose={() => setaddCoCustomerrror(false)}
          // eslint-disable-next-line react/no-children-prop
          children={nexterror()}
        />
      ) : null}
      {redirectionerrorpopup ? (
        <RACModalCard
          isOpen={redirectionerrorpopup}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          // title="Modal title"
          onClose={() => setredirectionerrorpopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={redirectionpopup()}
        />
      ) : null}
      {newcocustomerpopup && !location.pathname.includes("switchout") ? (
        <RACModalCard
          isOpen={newcocustomerpopup}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          // title="Modal title"
          onClose={() => setnewcocustomerpopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={firstcustomerpopup()}
        />
      ) : null}

      {classificationpopup ? (
        <RACModalCard
          isOpen={classificationpopup}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          // title="Modal title"
          onClose={() => setclassificationpopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={customerclassification()}
        />
      ) : null}
      {confirmationpopup ? (
        <RACModalCard
          isOpen={confirmationpopup}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          // title="Modal title"
          onClose={() => setconfirmationpopup(false)}
          // eslint-disable-next-line react/no-children-prop
          children={customerconfirmation()}
        />
      ) : null}
      {openModal ? (
        <RACModalCard
          isOpen={openModal}
          borderRadius="20px"
          onClose={() => setOpenModal(false)}
          maxWidth={"xs"}
          className="racSecondFacPopup"
          title={""}
          //eslint-disable-next-line react/no-children-prop
          children={openModalContent()}
        ></RACModalCard>
      ) : null}
      {openSecondModal ? (
        <RACModalCard
          isOpen={openSecondModal}
          borderRadius="20px"
          closeIcon={true}
          onClose={() => setOpenSecondModal(false)}
          maxWidth={"sm"}
          title={""}
          //eslint-disable-next-line react/no-children-prop
          children={openAlertModalContent()}
        ></RACModalCard>
      ) : null}
      {openPinModal ? (
        <RACModalCard
          isOpen={openPinModal}
          borderRadius="20px"
          className="racPinPopup"
          closeIcon={true}
          onClose={() => setOpenPinModal(false)}
          maxWidth={"xs"}
          title={"Enter PIN to continue"}
          //eslint-disable-next-line react/no-children-prop
          children={openPinModalContent()}
        ></RACModalCard>
      ) : null}
      {/* {needDE ? (
        <RACModalCard
          isOpen={needDE}
          maxWidth="xs"
          // eslint-disable-next-line sonarjs/no-duplicate-string
          borderRadius={'25px !important'}
          // eslint-disable-next-line react/no-children-prop
          children={runDEModal()}
        />
      ) : null} */}
      {DEException ? (
        <RACModalCard
          isOpen={DEException}
          maxWidth="xs"
          borderRadius={"25px !important"}
          // className={classes.backclr}
          //eslint-disable-next-line react/no-children-prop
          children={buildServerException()}
        />
      ) : null}

      {requireSSN ? (
        <RACModalCard
          isOpen={requireSSN}
          maxWidth="xs"
          // className={classes.backclr}
          // closeIcon={true}
          // onClose={newcustomercondition}
          borderRadius={"25px !important"}
          //eslint-disable-next-line react/no-children-prop
          children={buildRequireSSN()}
        />
      ) : null}
      {enterSSN ? (
        <RACModalCard
          isOpen={enterSSN}
          maxWidth="xs"
          borderRadius={"25px !important"}
          className={`${classes.modalmaxWidth}`}
          contentClassName={classes.contentPadding}
          //eslint-disable-next-line react/no-children-prop
          children={Getssn()}
          TitleClassName={classes.titlepadding}
          title={"Please enter the SSN number"}
        />
      ) : null}
      {Deloader ? (
        <RACModalCard
          isOpen={Deloader}
          maxWidth="xs"
          borderRadius={"25px !important"}
          // className={classes.backclr}
          //eslint-disable-next-line react/no-children-prop
          children={deloading()}
        />
      ) : null}
      {!classificationpopup && hardCustomerCompleted && afterDEsuccess ? (
        <RACModalCard
          isOpen={afterDEsuccess}
          maxWidth="sm"
          borderRadius={"25px !important"}
          className={classes.customModal}
          //eslint-disable-next-line react/no-children-prop
          children={Desucess()}
        />
      ) : null}
      {reasonDEopen ? (
        <RACModalCard
          isOpen={reasonDEopen}
          maxWidth="xs"
          borderRadius={"25px !important"}
          // className={classes.backclr}
          //eslint-disable-next-line react/no-children-prop
          children={noReasonDEPopup()}
        />
      ) : null}
      <RACModalCard
        isOpen={employeeRestrict}
        onClose={() => EmployeRestrictOnClick()}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        // eslint-disable-next-line react/no-children-prop
        children={EmployeeRestrictPopupOpen()}
      />
      {ssnloader ? (
        <RACModalCard
          isOpen={ssnloader}
          maxWidth="xs"
          borderRadius={"25px !important"}
          // className={classes.backclr}
          //eslint-disable-next-line react/no-children-prop
          children={ssnloading()}
        />
      ) : null}
      {openRemotePopup ? (
        <RACModalCard
          isOpen={openRemotePopup}
          borderRadius="20px"
          maxWidth={"xs"}
          className="racSecondFacPopup"
          title={""}
          //eslint-disable-next-line react/no-children-prop
          children={openRemoteModal()}
        ></RACModalCard>
      ) : null}
      {openDEDeclinedAuth ? (
        <SecondFactor
          setTwoFactorCancelClick={(value) => {
            if(value) {
            setafterDEsuccess(true);
            setDEDeclinedAuth(false);
            }
          }}
          setTwoFactorCompleted={(value) => {
            if (value) {
              setafterDEsuccess(false);
              setIsAllDone(true);
              setshowProgress(true);
              setDEDeclinedAuth(false);
              logStoreException("CVO", String(customerId));
              newcustomercondition();
            }
          }}
          moduleName={"DEDeclineApproval"}
          currentRole={currentEmpRole || currentUserRole}
        ></SecondFactor>
      ) : null}
    </>
  );
}
